import React from 'react';
import { useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { authenticationTypes } from '../../enums/authenticationTypes';
import { billTypes } from '../../enums/billTypes';
import { buttonTypes } from '../../enums/buttonTypes';
import AuthenticationButton from '../authentication/authenticationButton';
import ProgramAbstractDialog from '../dialogs/programAbstractDialog';
import PrivacyPolicyDialog from '../dialogs/privacyPolicyDialog';
import FooterDisclaimers from '../disclaimers/footerDisclaimers';
import BrandScroller from '../common/brandScroller';

const Footer = () => {
  const { programName, hasArticles } = useSelector(state => state.config);
  const { authenticationType, chatbotEnabled } = useSelector(state => state.config.settings);
  const { footerHeading, footerTagline } = useSelector(state => state.content.general);

  return (
    <div className="sm-footer-wrap">
      {footerHeading && <div className="heading">{footerHeading}</div>}
      {footerTagline && <div className="tagline">{footerTagline}</div>}

      <BrandScroller serviceType={billTypes.NONE} scrollSpeed={6} />

      <div className={`sm-footer-content${chatbotEnabled ? ' has-chatbot' : ''}`}>
        <div className="sm-footer-navigation">
          {authenticationType === authenticationTypes.DEFAULT && (
            <AuthenticationButton buttonType={buttonTypes.LINK} additionalClass="sm-footer-navigation-item" />
          )}
          <Link className="sm-footer-navigation-item" to="/">
            Home
          </Link>
          <ProgramAbstractDialog buttonText="How it works" buttonClass="sm-footer-navigation-item" />
          <Link className="sm-footer-navigation-item" to="/faq">
            FAQs
          </Link>
          {hasArticles && (
            <Link className="sm-footer-navigation-item" to="/articles">
              Articles
            </Link>
          )}
          <PrivacyPolicyDialog buttonText="Privacy Policy" buttonClass="sm-footer-navigation-item" />
          <HashLink className="sm-footer-navigation-item" smooth to="/faq#optout">
            Opt out of {programName}
          </HashLink>
          <Link className="sm-footer-navigation-item" to="/preferences">
            Preferences
          </Link>
        </div>

        <FooterDisclaimers showAnnualSavingDisclaimer showCompanyInfo />
      </div>
    </div>
  );
};

export default Footer;
