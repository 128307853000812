import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

import { setConciergeTermsAndConditions, resetConciergeTermsAndConditions } from '../../actions/contentActions';

const ConciergeTermsAndConditionsDialog = () => {
  const [dialogOpen, setDialogOpen] = useState();
  const { document, title, versionNumber, loading } = useSelector(state => state.content.conciergeTermsAndConditions);
  const { supplierId } = useSelector(state => state.selectedOffer);
  const { pathname } = useSelector(state => state.router.location);
  const dispatch = useDispatch();

  const onDialogToggle = event => {
    event.preventDefault();

    if (!dialogOpen) {
      dispatch(resetConciergeTermsAndConditions());
      dispatch(setConciergeTermsAndConditions(pathname === '/switch' ? supplierId : null));
    }

    setDialogOpen(!dialogOpen);
  };

  return (
    <>
      <Button className="sm-link-button inline" onClick={onDialogToggle}>
        SmartMe Concierge User Terms
      </Button>

      <Modal toggle={onDialogToggle} isOpen={dialogOpen} size="lg" centered={true}>
        <ModalBody>
          {!loading && (
            <Container>
              <Row>
                <Col xs="10" className="qs-modal-heading">
                  <h1>{title}</h1>
                </Col>
                <Col xs="2">
                  <div onClick={onDialogToggle} className="qs-modal-close-icon">
                    <i className="material-icons">close</i>
                  </div>
                </Col>
              </Row>

              <Row className="qs-modal-body">
                <Col>
                  <div dangerouslySetInnerHTML={{ __html: document }} />
                  <div>v{versionNumber}</div>
                </Col>
              </Row>
            </Container>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onDialogToggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConciergeTermsAndConditionsDialog;
