import React, { useState } from 'react';
import { Row, Col, Button, Popover, PopoverHeader, PopoverBody, FormGroup } from 'reactstrap';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import YourDetails from './yourDetails';
import VerifyIdAu from './verifyIdAu';
import Premise from './premise';
import MarketingQuestions from '../OfferAcceptance/marketingQuestions';
import Business from './business';
import CreditCheckConsent from './creditCheckConsent';
import Concession from './concession';
import { submissionTypes, occupancyPurposes, billingTypes } from '../../enums/submissionTypes';
import QsButtonGroup from './qsButtonGroup';
import { occupancyTypes } from '../../enums/submissionTypes';
import { getSubmissionDisplayAddress } from '../../utilities/addressUtilities';
import BillDisplayAddress from './billDisplayAddress';
import AddressControl from './addressControl';
import { AvField, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import PoBoxAddress from './poBoxAddress';
import GeneralDialog from '../dialogs/generalDialog';
import OrganiserInfo from '../common/organiserInfo';

const OfferConfirmPersonalDetailsAu = ({ confirmDetail, offer, onChange, setConfirmDetail }) => {
  const { submissionType, submission } = useSelector(state => state.comparison);
  const { emailAddress, accountVerified } = useSelector(state => state.user);
  const { occupancyType, occupancyPurpose } = useSelector(state => state.comparison.submission);
  const { country } = useSelector(state => state.config);
  const { organiserAgentName } = useSelector(state => state.content.general);
  const { mobilePhoneNumberRegex } = useSelector(state => state.config.applicationSettings.country);

  const [organiserDialogOpen, setOrganiserDialogOpen] = useState(false);
  const [lifeSupportInfoOpen, setLifeSupportInfoOpen] = useState(false);
  const [yourDetailsEmailAddress, setYourDetailsEmailAddress] = useState(emailAddress);

  const showingAdditionalBillingAddress =
    confirmDetail.billingType === billingTypes.ADDRESS_OTHER.Code ||
    confirmDetail.billingType === billingTypes.ADDRESS_POBOX.Code;

  const productDetail = offer.productDetail;

  const allowPaperBill = offer.allowPaperBill && offer.productDetail && offer.productDetail.allowPaperBill;
  const allowOrganiser = offer.allowOrganiser;
  const showPremiseType = productDetail && productDetail.requirePremiseType;
  const showPrimaryResidence = productDetail && productDetail.requirePrimaryResidence;
  const showResidencePeriod = productDetail && productDetail.requireResidencePeriod;
  const showMarketingOptOut = productDetail && productDetail.requireMarketingOptOut;
  const showWorksInProgress = productDetail && productDetail.requireWorksInProgress;
  const showWorksInProgressDetail = showWorksInProgress && productDetail.requireWorksInProgressDetail;
  const showSiteAccessIssues = productDetail && productDetail.requireSiteAccessIssues;
  const showSiteAccessIssuesDetail = showSiteAccessIssues && productDetail.requireSiteAccessIssuesDetail;
  const showSiteHazardIssues = productDetail && productDetail.requireSiteHazardIssues;
  const showSiteHazardIssuesDetail = showSiteHazardIssues && productDetail.requireSiteHazardIssuesDetail;
  const showPowerOn = productDetail && productDetail.requirePowerOn;

  const onBillingAddressSelected = address => {
    onChange(null, { billingAddress: address });
  };

  const onSupplyAddressSelected = address => {
    onChange(null, { supplyAddress: address });
  };

  const onChangeWorksInProgress = event => {
    let option = event.target.getAttribute('data-value');
    onChange(null, { worksInProgress: option });
  };

  const onChangeSiteAccessIssues = event => {
    let option = event.target.getAttribute('data-value');
    onChange(null, { siteAccessIssues: option });
  };

  const onChangeSiteHazardIssues = event => {
    let option = event.target.getAttribute('data-value');
    onChange(null, { siteHazardIssues: option });
  };

  const onChangePowerOn = event => {
    let option = event.target.getAttribute('data-value');
    onChange(null, { powerOn: option });
  };

  const onChangeLifeSupport = event => {
    let option = event.target.getAttribute('data-value');
    onChange(null, { lifeSupport: option });
  };

  const validateHiddenLifeSupport = (value, ctx, input, cb) => {
    if (value === 'false') {
      cb('Unfortunately we can not process requests requiring life support equipment');
    } else cb(true);
  };

  const onBillingTypeChange = (event, target) => {
    onChange(null, { billingType: target, billingAddress: null });
  };

  // --------------------------------------------------------------------------
  // Update email address on page as we may have come in anonymously
  // --------------------------------------------------------------------------
  const onYourDetailsChange = (e, data) => {
    if (data && data.emailAddress !== undefined) {
      setYourDetailsEmailAddress(data.emailAddress);
    }

    if (onChange) {
      onChange(e, data);
    }
  };

  const validateWorksInProgress = (value, ctx, input, cb) => {
    if (value !== 'false' && value !== 'true') {
      cb('Please select yes or no');
    } else cb(true);
  };

  const validateSiteAccessIssues = (value, ctx, input, cb) => {
    if (value !== 'false' && value !== 'true') {
      cb('Please select yes or no');
    } else cb(true);
  };

  const validateSiteHazardIssues = (value, ctx, input, cb) => {
    if (value !== 'false' && value !== 'true') {
      cb('Please select yes or no');
    } else cb(true);
  };

  const displayAddress = getSubmissionDisplayAddress(submissionType, submission);
  const creditCheckConsent = offer.creditCheckConsentRequired;

  return (
    <>
      <Row className="qs-more-info-table-header">
        <Col>
          <div style={{ paddingTop: '3px' }}>Connection address</div>
        </Col>
      </Row>

      {displayAddress ? (
        <BillDisplayAddress displayAddress={displayAddress} />
      ) : (
        <AddressControl
          key="supplyAddress"
          keySuffix="supply"
          labelText="Supply address"
          country={country}
          onAddressSelected={onSupplyAddressSelected}
          autoPlaceholder="Property address..."
          autoPropertyAddress={null}
          autoAddressRequired={true}
          manualAddressVisible={true}
          manualSuburbSelectorEnabled={true}
          showAreasSupportedDisclaimer={false}
          large={!isMobile}
          showMinimalManualFields={false}
        />
      )}

      {showWorksInProgress && (
        <Row>
          <Col xs="12">
            <div className="qs-more-info-table-header">{productDetail.worksInProgressDescription}</div>
            <QsButtonGroup
              id="sorter"
              fieldName="Sort"
              selectedValue={confirmDetail.worksInProgress}
              onTouchButton={onChangeWorksInProgress}
              additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
              buttons={[
                {
                  label: 'Yes',
                  value: 'true'
                },
                {
                  label: 'No',
                  value: 'false'
                }
              ]}
            />

            <AvField
              bsSize="sm"
              type="text"
              name="hiddenWorksInProgress"
              value={confirmDetail.worksInProgress + ''}
              className="qs-tc-hidden"
              validate={{ validateWorksInProgress }}
            />

            {showWorksInProgressDetail && confirmDetail.worksInProgress === 'true' && (
              <>
                <FormGroup className="qs-mt-md">
                  <AvField
                    bsSize="sm"
                    name="worksInProgressDetail"
                    placeholder="Tell us more..."
                    type="textarea"
                    value={confirmDetail.worksInProgressDetail}
                    onChange={e => onChange(e, { worksInProgressDetail: e.target.value })}
                    validate={{
                      required: { value: true, errorMessage: 'Please provide details' },
                      minLength: { value: 3, errorMessage: 'Your details are not the required length' },
                      maxLength: { value: 250, errorMessage: 'Your details should be 250 characters or less' }
                    }}
                  />
                </FormGroup>
              </>
            )}
          </Col>
        </Row>
      )}

      {showSiteHazardIssues && (
        <>
          <div className="label">{productDetail.siteHazardIssuesDescription}</div>
          <QsButtonGroup
            id="sorter"
            fieldName="Sort"
            selectedValue={confirmDetail.siteHazardIssues}
            onTouchButton={onChangeSiteHazardIssues}
            additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
            buttons={[
              {
                label: 'Yes',
                value: 'true'
              },
              {
                label: 'No',
                value: 'false'
              }
            ]}
          />

          <AvField
            bsSize="sm"
            type="text"
            name="hiddenSiteHazardIssues"
            value={confirmDetail.siteHazardIssues + ''}
            className="qs-tc-hidden"
            validate={{ validateSiteHazardIssues }}
          />

          {showSiteHazardIssuesDetail && confirmDetail.siteHazardIssues === 'true' && (
            <>
              <FormGroup className="qs-mt-md">
                <AvField
                  bsSize="sm"
                  name="siteHazardIssuesDetail"
                  placeholder="Tell us more..."
                  type="textarea"
                  value={confirmDetail.siteHazardIssuesDetail}
                  onChange={e => onChange(e, { siteHazardIssuesDetail: e.target.value })}
                  validate={{
                    required: { value: true, errorMessage: 'Please provide details' },
                    minLength: { value: 3, errorMessage: 'Your details are not the required length' },
                    maxLength: { value: 250, errorMessage: 'Your details should be 250 characters or less' }
                  }}
                />
              </FormGroup>
            </>
          )}
        </>
      )}

      {showSiteAccessIssues && (
        <Row>
          <Col xs="12">
            <div className="qs-more-info-table-header">{productDetail.siteAccessIssuesDescription}</div>
            <QsButtonGroup
              id="sorter"
              fieldName="Sort"
              selectedValue={confirmDetail.siteAccessIssues}
              onTouchButton={onChangeSiteAccessIssues}
              additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
              buttons={[
                {
                  label: 'Yes',
                  value: 'false'
                },
                {
                  label: 'No',
                  value: 'true'
                }
              ]}
            />

            <AvField
              bsSize="sm"
              type="text"
              name="hiddenSiteAccessIssues"
              value={confirmDetail.siteAccessIssues + ''}
              className="qs-tc-hidden"
              validate={{ validateSiteAccessIssues }}
            />

            {showSiteAccessIssuesDetail && confirmDetail.siteAccessIssues === 'true' && (
              <>
                <FormGroup className="qs-mt-md">
                  <AvField
                    bsSize="sm"
                    name="siteAccessIssuesDetail"
                    placeholder="Tell us more..."
                    type="textarea"
                    value={confirmDetail.siteAccessIssuesDetail}
                    onChange={e => onChange(e, { siteAccessIssuesDetail: e.target.value })}
                    validate={{
                      required: { value: true, errorMessage: 'Please provide details' },
                      minLength: { value: 3, errorMessage: 'Your details are not the required length' },
                      maxLength: { value: 250, errorMessage: 'Your details should be 250 characters or less' }
                    }}
                  />
                </FormGroup>
              </>
            )}
          </Col>
        </Row>
      )}

      {showPowerOn && (
        <>
          <div className="label">Is the power on at this property?</div>
          <QsButtonGroup
            id="sorter"
            fieldName="Sort"
            selectedValue={confirmDetail.powerOn}
            onTouchButton={onChangePowerOn}
            additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
            buttons={[
              {
                label: 'Yes',
                value: 'true'
              },
              {
                label: 'No',
                value: 'false'
              }
            ]}
          />

          <div className="qs-mt-md">
            {confirmDetail.powerOn === 'true'
              ? productDetail.powerOnYesDescription
              : productDetail.powerOnNoDescription}
          </div>

          {confirmDetail.powerOn === 'false' && productDetail.vse && (
            <>
              <div className="label">{productDetail.vse.introduction}</div>
              <FormGroup>
                <AvField
                  bsSize="sm"
                  name="vseOption"
                  placeholder="VseOption"
                  type="select"
                  value={confirmDetail.vseOption}
                  onChange={e => onChange(e, { vseOption: e.target.value })}
                  validate={{
                    required: { value: true, errorMessage: 'Please select a timeslot' }
                  }}
                >
                  <option value="">Select a timeslot</option>
                  {productDetail.vse.options.map((ref, i) => (
                    <option key={`vseOption-${ref.id}`} value={ref.value}>
                      {ref.description}
                    </option>
                  ))}
                </AvField>
              </FormGroup>

              {}
            </>
          )}
        </>
      )}

      {submissionType === submissionTypes.ESTIMATE &&
        occupancyType === occupancyTypes.NEW.Id &&
        offer.productDetail.moveInDisclaimer && (
          <>
            <Row className="qs-more-info-table-header">
              <Col xs="12">Moving in</Col>
            </Row>
            <Row>
              <Col
                xs="12"
                dangerouslySetInnerHTML={{
                  __html: offer.productDetail.moveInDisclaimer
                }}
              />
            </Row>
          </>
        )}

      {occupancyPurpose === occupancyPurposes.BUSINESS.Id && <Business onChange={onChange} details={confirmDetail} />}

      <Row className="qs-more-info-table-header">
        <Col xs="12" md="6">
          Your details
        </Col>
        <Col className="d-none d-md-block" md="6">
          Verify ID
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="6">
          <YourDetails
            onChange={onYourDetailsChange}
            details={confirmDetail}
            phoneNumberRegex={mobilePhoneNumberRegex}
            emailAddress={emailAddress}
            showMembershipId={offer.membershipId}
            showAlternatePhoneNumber={false}
            showDateOfBirth={false}
            showTitle={true}
            showEmailAddress={true}
            showPhoneNumber={true}
            lockPhoneNumber={accountVerified}
          />
        </Col>
        <Col className="d-block d-md-none qs-more-info-table-header-col" xs="12">
          <div>Verify ID</div>
        </Col>
        <Col xs="12" md="6">
          <VerifyIdAu
            onChange={onChange}
            details={confirmDetail}
            acceptDriversLicenceRules={offer.acceptDriversLicence}
            acceptMedicare={offer.acceptMedicare}
            acceptPassport={offer.acceptPassport}
            acceptForeignPassport={offer.acceptForeignPassport}
            captureSecondary={false}
            acceptMedicareSecondary={false}
            acceptForeignPassportSecondary={false}
            acceptDateOfBirth={true}
          />
        </Col>
      </Row>

      {showMarketingOptOut && (
        <MarketingQuestions confirmDetail={confirmDetail} productDetail={offer.productDetail} onChange={onChange} />
      )}

      {(showPrimaryResidence || showPremiseType) && (
        <Premise
          onChange={onChange}
          showPrimaryResidence={showPrimaryResidence}
          showPremiseType={showPremiseType}
          showResidencePeriod={showResidencePeriod}
          details={confirmDetail}
        />
      )}

      {creditCheckConsent && (
        <CreditCheckConsent
          onChange={onChange}
          providerDisplayCode={offer.providerDisplayCode}
          creditCheckConsent={confirmDetail.creditCheckConsent}
        />
      )}

      {occupancyPurpose === occupancyPurposes.RESIDENTIAL.Id && (
        <Concession
          onChange={onChange}
          acceptConcession={offer.acceptConcession}
          concessionDisclaimer={offer.productDetail ? offer.productDetail.concessionDisclaimer : ''}
          details={confirmDetail}
          setConfirmDetail={setConfirmDetail}
          offer={offer}
        />
      )}

      <Row>
        <Col xs="12">
          <div className="qs-more-info-table-header qs-mt-md">
            Does anyone residing or intending to reside at your property/premises require life support equipment?
            <i
              id={'lifeSupportInfo'}
              className="material-icons qs-info"
              onClick={() => setLifeSupportInfoOpen(!lifeSupportInfoOpen)}
            >
              info
            </i>
          </div>
          <QsButtonGroup
            id="sorter"
            fieldName="Sort"
            selectedValue={confirmDetail.lifeSupport + ''}
            onTouchButton={onChangeLifeSupport}
            additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
            buttons={[
              {
                label: 'Yes',
                value: 'false'
              },
              {
                label: 'No',
                value: 'true'
              }
            ]}
          />
          <Popover
            placement="auto"
            isOpen={lifeSupportInfoOpen}
            target={'lifeSupportInfo'}
            onClick={() => setLifeSupportInfoOpen(!lifeSupportInfoOpen)}
          >
            <PopoverHeader>
              Why do we ask this?<i className="material-icons qs-close">clear</i>
            </PopoverHeader>
            <PopoverBody>
              <div>
                If you, or anyone at your home depends on life support, this needs to be registered on your account. If
                applicable, your energy retailer and local distributor can give you extra protections with your power
                supply. You are classified as being life support dependent if you depend on any of the following life
                support equipment:
                <br />
                <br />
                <ul>
                  <li>Oxygen concentrator</li>
                  <li>Intermittent peritoneal dialysis machine</li>
                  <li>Kidney dialysis machine</li>
                  <li>Chronic positive airways pressure respirator (CPAP)</li>
                  <li>Crigler najjar syndrome phototherapy equipment</li>
                  <li>Ventilator for life support</li>
                  <li>
                    Any other equipment (whether fuelled by electricity or gas) that a registered medical practitioner
                    certifies is required for a person residing at the customer's premises for life support or otherwise
                    where the customer provides a current medical certificate certifying that a person residing at the
                    customer's premises has a medical condition which requires continued supply of electricity or gas.
                  </li>
                </ul>
              </div>
            </PopoverBody>
          </Popover>
        </Col>
        <Col xs="12" className="qs-agree-terms-text">
          <AvField
            bsSize="sm"
            type="text"
            name="hiddenLifeSupport"
            value={confirmDetail.lifeSupport + ''}
            className="qs-tc-hidden"
            validate={{ validateHiddenLifeSupport }}
          />
        </Col>
      </Row>

      <Row className={`qs-more-info-table-header${showingAdditionalBillingAddress ? ' qs-mb-nlg' : ''}`}>
        <Col xs="12">
          <div style={{ paddingTop: '3px' }}>Send my bills to</div>
          <AvRadioGroup
            className="qs-form-radio"
            name="billingType"
            label=""
            errorMessage="Please select where to send your bills"
            required
            value={confirmDetail.billingType}
            onChange={onBillingTypeChange}
          >
            {allowOrganiser && (
              <AvRadio
                label={
                  <>
                    My email address{' '}
                    {yourDetailsEmailAddress && (
                      <>
                        (<i>{yourDetailsEmailAddress}</i>){' '}
                      </>
                    )}
                    via my{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: organiserAgentName
                      }}
                    />{' '}
                    <Button className="sm-link-button" onClick={() => setOrganiserDialogOpen(true)}>
                      how it works
                    </Button>
                  </>
                }
                value={billingTypes.EMAIL_ORGANISER.Code}
              />
            )}
            <AvRadio
              label={
                <>
                  My email address{' '}
                  {yourDetailsEmailAddress && (
                    <>
                      (<i>{yourDetailsEmailAddress}</i>)
                    </>
                  )}
                </>
              }
              value={billingTypes.EMAIL_PERSONAL.Code}
            />
            {allowPaperBill && (
              <>
                <AvRadio label={billingTypes.ADDRESS_CONNECTION.Label} value={billingTypes.ADDRESS_CONNECTION.Code} />
                <AvRadio label={billingTypes.ADDRESS_OTHER.Label} value={billingTypes.ADDRESS_OTHER.Code} />
                <AvRadio label={billingTypes.ADDRESS_POBOX.Label} value={billingTypes.ADDRESS_POBOX.Code} />
              </>
            )}
          </AvRadioGroup>
        </Col>
      </Row>

      {confirmDetail.billingType === billingTypes.ADDRESS_OTHER.Code && (
        <div className="sm-offer-billing-address">
          <AddressControl
            key="billingAddress"
            keySuffix="billing"
            labelText="Billing address (Other)"
            country={country}
            onAddressSelected={onBillingAddressSelected}
            autoPlaceholder="Property address..."
            autoPropertyAddress={null}
            autoAddressRequired={true}
            manualAddressVisible={true}
            manualSuburbSelectorEnabled={true}
            showAreasSupportedDisclaimer={false}
            large={!isMobile}
            showMinimalManualFields={false}
          />
        </div>
      )}

      {confirmDetail.billingType === billingTypes.ADDRESS_POBOX.Code && (
        <>
          <Row className="qs-more-info-table-header">
            <Col xs="12">
              <div style={{ paddingTop: '3px' }}>Billing address (PO Box)</div>
            </Col>
          </Row>

          <PoBoxAddress key="poBoxAddress" namePrefix="poBoxAddress" onAddressSelected={onBillingAddressSelected} />
        </>
      )}

      {offer.productDetail &&
        offer.productDetail.billingContentEmail &&
        (!confirmDetail.billingType ||
          confirmDetail.billingType === billingTypes.NONE.Code ||
          confirmDetail.billingType === billingTypes.EMAIL_ORGANISER.Code ||
          confirmDetail.billingType === billingTypes.EMAIL_PERSONAL.Code) && (
          <div
            className="qs-mt-sm"
            dangerouslySetInnerHTML={{
              __html: offer.productDetail.billingContentEmail
            }}
          />
        )}

      {offer.productDetail &&
        offer.productDetail.billingContentPaper &&
        (confirmDetail.billingType === billingTypes.ADDRESS_CONNECTION.Code ||
          confirmDetail.billingType === billingTypes.ADDRESS_POBOX.Code ||
          confirmDetail.billingType === billingTypes.ADDRESS_OTHER.Code) && (
          <div
            className="qs-mt-sm"
            dangerouslySetInnerHTML={{
              __html: offer.productDetail.billingContentPaper
            }}
          />
        )}

      <GeneralDialog
        dialogOpen={organiserDialogOpen}
        bodyClass="no-padding"
        showFooter={false}
        showCloseIcon={false}
        onClose={() => setOrganiserDialogOpen(false)}
      >
        <OrganiserInfo onClose={() => setOrganiserDialogOpen(false)} />
      </GeneralDialog>
    </>
  );
};

export default OfferConfirmPersonalDetailsAu;
