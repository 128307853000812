import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { uploadBillTypes, uploadSubTypes } from '../../enums/uploadBillTypes';

import { ReactComponent as UploadIcon } from '../icons/util/upload.svg';
import { ReactComponent as MailIcon } from '../icons/util/mail.svg';
import { ReactComponent as ManualIcon } from '../icons/util/manual.svg';
import { ReactComponent as HomeIcon } from '../icons/util/home.svg';
import { ReactComponent as MoneyIcon } from '../icons/util/money.svg';
import { ReactComponent as CdrIcon } from '../icons/util/cdr.svg';
import { ReactComponent as VehicleInsuranceIcon } from '../icons/services/outline/home-insurance.svg';

const UploadBillTypeButton = ({ onClick, uploadBillType, uploadSubType, isPrimary, enabled }) => {
  const getIcon = () => {
    switch (uploadBillType.Id) {
      case uploadBillTypes.WEBSITE.Id:
        return <UploadIcon className="icon" width="40" />;

      case uploadBillTypes.EMAIL.Id:
        return <MailIcon className="icon" width="40" />;

      case uploadBillTypes.MANUAL.Id:
        switch (uploadSubType) {
          case uploadSubTypes.REFINANCE:
            return <MoneyIcon className="icon" width="40" />;
          case uploadSubTypes.NEW_LOAN:
            return <HomeIcon className="icon" width="40" />;
          case uploadSubTypes.DEFAULT_COMPARISON:
            return null;
          default:
            return <ManualIcon className="icon" width="40" />;
        }

      case uploadBillTypes.MOVING_HOUSE.Id:
        return <VehicleInsuranceIcon className="icon" width="40" />;

      case uploadBillTypes.CDR.Id:
        return <CdrIcon className="icon" width="40" />;

      default:
        return null;
    }
  };

  const onSelect = () => {
    if (onClick) {
      onClick(uploadBillType, uploadSubType);
    }
  };

  const uploadType = uploadSubType === uploadSubTypes.NONE ? uploadBillType : uploadSubType;

  const text = <span className="text">{uploadType.Description}</span>;

  const button = (
    <Button
      disabled={!enabled}
      className={`${isPrimary ? 'sm-button-primary' : 'sm-button-secondary'} right-arrow-small`}
      onClick={onSelect}
    >
      {uploadType.ButtonText}
    </Button>
  );

  return (
    <Row className="sm-upload-option">
      <Col xs="7" className="d-none d-md-block">
        {getIcon()}
        {text}
      </Col>
      <Col xs="12" md="5">
        {button}
      </Col>
    </Row>
  );
};

export default UploadBillTypeButton;
