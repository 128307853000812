import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '../auth/auth0-Provider.js';
import { getPreferencesWithKey } from '../actions/userActions';
import { getComparisonWithKey, goHome } from '../actions/comparisonActions';
import { pollComparisonWithToken } from '../actions/energyBillActions';
import { initLandingSwitch } from '../actions/energyEstimateActions';
import { getCustomerDashboardWithKey, openServiceCardWithKey } from '../actions/serviceCardActions.js';
import { setServerMessageClear } from '../actions/serverMessageActions';
import qs from 'query-string';
import { Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';

import { landingTypes } from '../enums/landingTypes';
import { billTypes } from '../enums/billTypes';
import { serviceCardLoadingTypes } from '../enums/serviceCardLoadingTypes';

import { getSessionActivity } from '../utilities/commonUtilities';
import { getSurveyWithKey } from '../actions/surveyActions.js';

const Landing = props => {
  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { session } = useSelector(state => state);
  const { sessionId } = useSelector(state => state.session);

  const [isLoading, setIsLoading] = useState(false);
  const [headingText, setHeadingText] = useState(false);

  useEffect(() => {
    if (sessionId) {
      const fn = async () => {
        dispatch(setServerMessageClear());

        if (isLoading) return;

        const locationSearch = qs.parse(props.location.search);
        const pathId = props.match.params.id;
        const pathTag = props.match.params.tag;

        const queryKey = locationSearch.key;
        const queryTarget = locationSearch.target;
        const launchDefaultComparisons = locationSearch.ldc;
        const landingType = Number(queryTarget);

        const sessionActivity = getSessionActivity(session);

        if (queryKey && pathId) {
          const accessKey = { key: queryKey, id: pathId };

          switch (landingType) {
            case landingTypes.PREFERENCES:
              setHeadingText('Getting your preference data');
              dispatch(getPreferencesWithKey({ key: queryKey, id: pathId }));
              break;

            case landingTypes.EXTERNAL_COMPARISON:
              setHeadingText('Applying your data');

              if (isUserAuthenticated()) {
                const accessToken = await getAccessToken();

                dispatch(
                  pollComparisonWithToken(
                    accessToken,
                    queryKey,
                    () => {}, // onComplete
                    () => {}, // onError
                    () => {} //onVerify
                  )
                );
              } else {
                // user is not authenticated
              }
              break;

            case landingTypes.DASHBOARD:
              dispatch(getCustomerDashboardWithKey(accessKey, launchDefaultComparisons));
              break;

            case landingTypes.SURVEY:
              const surveyId = locationSearch.sid;
              dispatch(getSurveyWithKey(accessKey, surveyId));
              break;

            case landingTypes.SERVICE_CARD:
              dispatch(openServiceCardWithKey(accessKey));
              break;

            case landingTypes.SERVICE_CARDS:
              dispatch(openServiceCardWithKey(accessKey));
              break;

            default:
              setHeadingText('Applying your data');

              const options = { openEstimateDialog: true };
              const submissionType = null;
              const serviceCardLoading = serviceCardLoadingTypes.TRUSTED;
              const autoEstimatesArgs = { agentBillTypes, googleAnalyticsClientCode };

              dispatch(
                getComparisonWithKey(
                  accessKey,
                  options,
                  submissionType,
                  billTypes.ELECTRICITY,
                  serviceCardLoading,
                  autoEstimatesArgs,
                  sessionActivity
                )
              );
              break;
          }

          setIsLoading(true);
        } else if (pathId) {
          switch (pathId.toLowerCase()) {
            case 'switch':
              const autoEstimatesArgs = { agentBillTypes, googleAnalyticsClientCode };
              const directSwitchArgs = {
                tag: pathTag,
                area: { suburb: locationSearch.sub, state: locationSearch.sta, postcode: locationSearch.pos },
                serviceType: locationSearch.st,
                providerCode: locationSearch.rc,
                providerName: locationSearch.rn,
                productName: locationSearch.pn,
                productType: locationSearch.pt
              };

              dispatch(initLandingSwitch(sessionActivity, locationSearch.agent, directSwitchArgs, autoEstimatesArgs));
              break;

            default:
              dispatch(goHome());
          }

          setIsLoading(true);
        } else {
          dispatch(goHome());
        }
      };
      fn();
    }
  }, [sessionId]);

  return (
    <Row>
      <Helmet>
        <title>Loading...</title>
      </Helmet>
      <Col>
        <h1>{headingText}</h1>
        <h2 className="qs-loading-heading">Loading...</h2>
      </Col>
    </Row>
  );
};

export default Landing;
