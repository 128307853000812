import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '../../auth/auth0-Provider';
import OfflineApplicationDialog from '../dialogs/offlineApplicationDialog';
import Offer from './offer';
import OfferSorterEnergyAu from './offerSorterEnergyAu';
import ServerMessage from './serverMessage';
import IdleTimerDialog from '../dialogs/idleTimerDialog';
import IdleOfferTimeout from '../common/idleOfferTimeout';
import { implementationCodes } from '../../enums/implementationCodes';
import { submissionTypes } from '../../enums/submissionTypes';
import { comparisonSorting } from '../../enums/comparisonSorting';
import { serviceCardLoadingTypes } from '../../enums/serviceCardLoadingTypes';
import { directSwitchSessionStatus } from '../../enums/directSwitchSessionStatus';
import { onComparisonTimeout } from '../../utilities/compareUtilities';
import { getFilteredInternetOffers, isSummaryOffersOnly } from '../../utilities/internetUtilities';
import { getSessionActivity } from '../../utilities/commonUtilities';
import { offerEvents, comparisonEvents } from '../../utilities/googleAnalyticsEvents';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import { setServerMessageClear, sessionExpiredServerMessage } from '../../actions/serverMessageActions';
import { setSelectedOffer, setOfflineApplication, setMoreInfo } from '../../actions/selectedOfferActions';
import { setDirectSwitch } from '../../actions/directSwitchActions';
import { addSessionActivity } from '../../actions/sessionActivityActions';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes';

const Offers = ({ onTimeout, showSorter, filterSorter }) => {
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { saveButtonText } = useSelector(state => state.content.general);
  const { comparison, user, directSwitch, serverMessage, session } = useSelector(state => state);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { loaded: userBillsLoaded } = useSelector(state => state.userBills);
  const { openMoreInfo } = useSelector(state => state.landing.comparison);
  const { sessionStatus, selectOfferId, product } = useSelector(state => state.directSwitch);

  const serviceCardLoading = !userBillsLoaded ? serviceCardLoadingTypes.DEFAULT : serviceCardLoadingTypes.NONE;
  const serviceType = implementationCodes.getServiceType(comparison.implementationCode);

  const dispatch = useDispatch();

  const allowOpenCloseInfo =
    comparison.implementationCode === implementationCodes.INTERNET.AU_DEFAULT
      ? !isSummaryOffersOnly(comparison.offers)
      : true;

  const sessionActivity = getSessionActivity(session);

  const defaultSort = () => {
    var sort, filter;
    switch (comparison.implementationCode) {
      case implementationCodes.ELECTRICITY.AU_DEFAULT:
      case implementationCodes.GAS.AU_DEFAULT:
        sort = (comparison.sorting || comparisonSorting.GUARANTEED.Id).toString();
        filter = null;
        break;
      case implementationCodes.INTERNET.AU_DEFAULT:
        sort = (comparison.sorting || comparisonSorting.CONDITIONAL.Id).toString();
        filter = { downloadSpeed: comparison.downloadSpeed, dataUnlimited: comparison.dataUnlimited };
        break;
      default:
        sort = '';
        filter = null;
    }

    return { sort, filter };
  };

  const [selectedFilterSorter, setSelectedFilterSorter] = useState(filterSorter || defaultSort());

  const [offlineApplicationOpen, setOfflineApplicationOpen] = useState(false);
  const [offlineApplicationOffer, setOfflineApplicationOffer] = useState(null);
  const [offlineApplicationResponse, setOfflineApplicationResponse] = useState(null);

  const isDirectSwitch = sessionStatus === directSwitchSessionStatus.READY;

  const getButtonText = offer => {
    switch (comparison.implementationCode) {
      case implementationCodes.HOME_LOAN.AU_DEFAULT:
        return 'Speak to an expert';

      default:
        return offer.allowOnlineApplication ? saveButtonText : 'Call to apply...';
    }
  };

  const renderSorter = () => {
    if (!showSorter) return null;

    switch (comparison.implementationCode) {
      case implementationCodes.ELECTRICITY.AU_DEFAULT:
      case implementationCodes.GAS.AU_DEFAULT:
        return <OfferSorterEnergyAu offers={comparison.offers} onSort={onSort} sort={selectedFilterSorter.sort} />;
      default:
        return null;
    }
  };

  const applySortAndFilter = () => {
    let sortedOffers = null;

    switch (comparison.implementationCode) {
      case implementationCodes.ELECTRICITY.AU_DEFAULT:
      case implementationCodes.GAS.AU_DEFAULT:
        sortedOffers = [...comparison.offers].sort((a, b) => {
          if (a.submissionType === submissionTypes.BILL)
            // eslint-disable-next-line eqeqeq
            return selectedFilterSorter.sort == '1' || selectedFilterSorter.sort === 'guaranteed'
              ? b.savingAmountGuaranteed - a.savingAmountGuaranteed
              : b.savingAmountLowest - a.savingAmountLowest;
          else {
            // eslint-disable-next-line eqeqeq
            return selectedFilterSorter.sort == '1' || selectedFilterSorter.sort === 'guaranteed'
              ? a.billAmountGuaranteed - b.billAmountGuaranteed
              : a.billAmountLowest - b.billAmountLowest;
          }
        });
        break;

      case implementationCodes.INTERNET.AU_DEFAULT:
        sortedOffers = getFilteredInternetOffers(selectedFilterSorter.filter, [...comparison.offers]).sort((a, b) => {
          // eslint-disable-next-line eqeqeq
          return selectedFilterSorter.sort == '1' || selectedFilterSorter.sort === 'guaranteed'
            ? b.savingAmountGuaranteed - a.savingAmountGuaranteed
            : b.savingAmountLowest - a.savingAmountLowest;
        });
        break;

      case implementationCodes.HOME_LOAN.AU_DEFAULT:
        sortedOffers = [...comparison.offers].filter(
          // eslint-disable-next-line eqeqeq
          e => selectedFilterSorter.sort == '1' || selectedFilterSorter.sort === 'guaranteed' || e.isCashBackBonusOffer
        );
        break;

      default:
        sortedOffers = [...comparison.offers];
        break;
    }

    if (comparison.offerMax && sortedOffers.length > 0) {
      sortedOffers = sortedOffers.slice(0, comparison.offerMax);
    }

    let sponsoredOffers = [...sortedOffers.filter(offer => offer.isSponsored)];

    if (sponsoredOffers && sponsoredOffers.length > 0) {
      let newSponsoredOffers = [];

      sponsoredOffers.forEach((offer, index, allOffers) => {
        let providerCount = 0;
        for (var i = 0; i < index; i++) if (offer.providerCode === allOffers[i].providerCode) providerCount++;

        // Only allow 2 sponsor offers per provider
        if (providerCount < 2) newSponsoredOffers.push({ ...offer, indicator: 'sponsor' });
      });

      // Remove the first item in the offers if it's also the best offer to prevent a double up of the same offer directly on top of each other
      if (sortedOffers[0].providerCode === newSponsoredOffers[0].providerCode) sortedOffers.shift();

      sortedOffers = [...newSponsoredOffers, ...sortedOffers];
    }

    return sortedOffers;
  };
  const sortedFilteredOffers = applySortAndFilter();

  const onOfflineApplication = (offlineApplicationResponse, offer) => {
    if (!offlineApplicationResponse) return;

    setOfflineApplicationOffer(offer);
    setOfflineApplicationResponse(offlineApplicationResponse);
    setOfflineApplicationOpen(true);
  };

  const onOfflineApplicationCancel = () => {
    setOfflineApplicationOpen(false);
  };

  const onSort = (option, optionName) => {
    var newFilterSorter = { ...selectedFilterSorter, sort: option };
    setSelectedFilterSorter(newFilterSorter);

    comparisonEvents(`sort-${optionName}`, comparison, googleAnalyticsClientCode);
  };

  // ----------------------------------------------------------------------------
  // Auto-select an offer from a direct switch request.
  // ----------------------------------------------------------------------------
  useEffect(() => {
    if (sessionStatus === directSwitchSessionStatus.READY) {
      if (product && product.distributor) {
        const selectOffer = comparison.offers.find(
          e => e.offerId === selectOfferId && e.distributorId === product.distributor.id
        );

        const submissionId = comparison.submissionId;

        if (selectOffer) {
          onSelectOffer(selectOffer, submissionId);
        } else {
          completeDirectSwitch();
        }
      } else {
        completeDirectSwitch();
      }
    }
  }, [selectOfferId]);

  const completeDirectSwitch = () => {
    // Set session status to COMPLETED_COMPARISON.
    const model = {
      ...directSwitch,
      sessionStatus: directSwitchSessionStatus.COMPLETED_COMPARISON
    };

    dispatch(setDirectSwitch(model));
  };

  // ----------------------------------------------------------------------------
  // Update filter sorter
  // ----------------------------------------------------------------------------
  useEffect(() => {
    if (filterSorter) {
      setSelectedFilterSorter(filterSorter);
    }
  }, [filterSorter]);

  const onMoreInfo = (event, offer, openState) => {
    if (openState) getMoreInfoData(offer, comparison.implementationCode);

    offerEvents('more-info', offer, googleAnalyticsClientCode, openState);
  };

  const getMoreInfoData = (offer, implementationCode) => {
    if (!offer.moreInfo) {
      switch (implementationCode) {
        case implementationCodes.INTERNET.AU_DEFAULT:
          dispatch(setMoreInfo(serviceType, comparison, offer));
          break;
        default:
          break;
      }
    }
  };

  const target = serverMessageTargets.COMPARE;

  const onSelectOffer = async (offer, submissionId) => {
    dispatch(setServerMessageClear(target, serverMessage));

    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          submissionType: submissionTypes.ESTIMATE,
          submissionId,
          provider: offer.providerDisplayCode,
          distributorId: offer.distributorId,
          offerId: offer.offerId,
          offerName: offer.name
        },
        activityType: sessionActivityTypes.OFFER_SELECTED
      })
    );

    if (comparison.accessKey) {
      if (offer.allowOnlineApplication) dispatch(setSelectedOffer(serviceType, comparison, offer));
      else dispatch(setOfflineApplication(onOfflineApplication, comparison, offer));
    } else if (isUserAuthenticated()) {
      const fn = async () => {
        const accessToken = await getAccessToken();
        if (offer.allowOnlineApplication)
          dispatch(setSelectedOffer(serviceType, comparison, offer, accessToken, submissionId));
        else dispatch(setOfflineApplication(onOfflineApplication, comparison, offer, accessToken, submissionId));
      };
      fn();
    } else {
      dispatch(sessionExpiredServerMessage(target));
    }

    offerEvents('select-offer', offer, googleAnalyticsClientCode);
  };

  const onTimeoutClosed = () => {
    const accessToken = isUserAuthenticated() ? getAccessToken : null;
    dispatch(onComparisonTimeout(serviceCardLoading, accessToken, comparison));
  };

  const onTimeoutIdle = () => {
    if (onTimeout) {
      onTimeout();
    }
  };

  return (
    <>
      <ServerMessage serverMessage={serverMessage} target={target} />

      {comparison.offers.length > 0 && !isDirectSwitch && (
        <>
          {renderSorter()}

          {sortedFilteredOffers.length > 0 ? (
            sortedFilteredOffers.map((offer, i) => (
              <Offer
                mode={2}
                key={`${offer.id}${offer.indicator === 'sponsor' ? '_sponsor' : null}`}
                offer={offer}
                counter={i}
                onSelectOffer={onSelectOffer}
                moreInfoOpen={openMoreInfo && i === 0}
                allowToggle={true}
                allowOpenCloseInfo={allowOpenCloseInfo}
                onMoreInfo={onMoreInfo}
                moreInfoOpenText="More info ..."
                moreInfoCloseText="Close"
                primaryButtonText={getButtonText(offer)}
                moreInfoHeading={`${offer.providerDisplayCode} - ${offer.name}`}
                emailAddress={user.emailAddress}
                initialDelay={2}
              />
            ))
          ) : (
            <>
              <div className="qs-no-offers-message">
                <p>We currently have no offers that match your selected criteria.</p>
                <p>Try refining the filter within 'View Details'.</p>
              </div>
            </>
          )}
        </>
      )}

      <IdleTimerDialog timeout={3600000} closeButtonText="Start again" onClose={onTimeoutClosed} onIdle={onTimeoutIdle}>
        <IdleOfferTimeout message="Your session has expired. As plans can change and you have been idle for more than 1 hour, we need to refresh your comparison." />
      </IdleTimerDialog>

      <OfflineApplicationDialog
        isOpen={offlineApplicationOpen}
        onCancel={onOfflineApplicationCancel}
        offer={offlineApplicationOffer}
        offlineApplication={offlineApplicationResponse}
      />
    </>
  );
};

export default Offers;
