export const partnerFrameTypes = {
  NONE: { id: 0 },
  ENERGY_ESTIMATE: { id: 1, name: 'Energy Estimate' },
  MOVING_HOME: { id: 2, name: 'Moving Home' },
  getById: id => {
    switch (id) {
      case partnerFrameTypes.ENERGY_ESTIMATE.id:
        return partnerFrameTypes.ENERGY_ESTIMATE;
      case partnerFrameTypes.MOVING_HOME.id:
        return partnerFrameTypes.MOVING_HOME;
      default:
        return partnerFrameTypes.NONE;
    }
  }
};

export const restartSources = {
  NONE: { id: 0, tag: '' },
  OFFER: { id: 1, tag: 'offer' },
  SWITCH: { id: 2, tag: 'switch' },
  CLOSE_BUTTON: { id: 3, tag: 'close-button' },
  START_NEW_BUTTON: { id: 4, tag: 'start-new-button' }
};

export const interactionSources = {
  NONE: { id: 0, tag: '' },
  INTRODUCTION: { id: 1, tag: 'introduction' },
  SERVICE_TYPE: { id: 2, tag: 'service-type' },
  ADDRESS: { id: 3, tag: 'address' },
  POSTCODE: { id: 4, tag: 'postcode' }
};

export const eventTypes = {
  NONE: { id: 0, tag: '' },
  SERVICE_TYPE_SELECTED: { id: 1, tag: 'service-type-selected' },
  INTERACTION_STARTED: { id: 2, tag: 'interaction-started' },
  DISTRIBUTOR_APPLIED: { id: 3, tag: 'distributor-applied' },
  DISTRIBUTOR_SELECTED: { id: 4, tag: 'distributor-selected' },
  START_AGAIN: { id: 5, tag: 'start-again' },
  PROPERTY_POSTCODE_ENTERED: { id: 6, tag: 'property-postcode-entered' },
  PROPERTY_ADDRESS_SELECTED: { id: 7, tag: 'property-address-selected' },
  PROPERTY_ADDRESS_ENTERED: { id: 8, tag: 'property-address-entered' }
};
