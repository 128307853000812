import React from 'react';
import Voucher from '../components/common/voucher';
import * as comparisonActions from '../actions/comparisonActions';
import { implementationCodes } from '../enums/implementationCodes';
import { submissionTypes } from '../enums/submissionTypes';

const sleep = (start, context, delay) => {
  const date = start || Date.now();

  const spinnerMinimumDelay = delay || 3000; // sets the minium time in millseconds for the spinner-dialog to remain on screen, even if the async request has completed earlier.
  const elapsed = Date.now() - date;
  const remaining = spinnerMinimumDelay - elapsed;
  const waiting = remaining > 0 ? remaining : 0;

  console.log(
    `${context}: Min Delay = ${spinnerMinimumDelay}, ${elapsed} milliseconds elapsed, waiting another ${waiting} milliseconds`
  );

  return new Promise(resolve => setTimeout(resolve, waiting));
};

const onComparisonTimeout = (serviceCardLoading, accessToken, comparison) => {
  return dispatch => {
    const serviceType = implementationCodes.getServiceType(comparison.implementationCode);
    const submissionType = comparison.isBill ? submissionTypes.BILL : submissionTypes.ESTIMATE;

    if (comparison.accessKey && comparison.accessId) {
      const accessKey = { key: comparison.accessKey, id: comparison.accessId };

      dispatch(
        comparisonActions.getComparisonWithKey(accessKey, null, submissionType, serviceType, serviceCardLoading)
      );
    } else if (comparison.submissionId && accessToken) {
      dispatch(
        comparisonActions.getComparisonWithToken(
          accessToken,
          comparison.submissionId,
          comparison.submissionType,
          serviceType
        )
      );
    } else {
      dispatch(comparisonActions.goHome());
    }
  };
};

const renderDemoVouchers = (config, user, mode) => {
  if (mode === 0 && config.code === 'mcgdemo') {
    return (
      <Voucher
        heading="Plus your National Sports Museum voucher"
        detail={`Your $25 voucher will be emailed to ${user.emailAddress}.`}
        image={`${config.applicationSettings.staticContentUrl}/images/causes/NationalSportsMuseum.png`}
        alt="National Sports Museum"
        agentCode={config.code}
      />
    );
  }

  if (mode === 0 && config.code === 'qantasdemo') {
    return (
      <Voucher
        heading="Earn QFF points"
        detail={`Your 5,000 points will be automatically added to you account.`}
        image={`${config.applicationSettings.staticContentUrl}/images/causes/FrequentFlyer.png`}
        alt="Qantas Frequent FLyer"
        agentCode={config.code}
      />
    );
  }

  if (mode === 0 && config.code === 'mrvdemo') {
    return (
      <Voucher
        heading="Donate to Maddie Riewoldt's Vision"
        detail="Switch and a $50 donation will be made."
        image={`${config.applicationSettings.staticContentUrl}/images/causes/mrv.png`}
        alt="Maddie Riewoldt's Vision"
        agentCode={config.code}
      />
    );
  }

  if (mode === 0 && config.code === 'worldvisiondemo') {
    return (
      <Voucher
        heading="Donate to World Vision causes"
        detail="Switch and a $50 donation will be made to World Vision."
        image={`${config.applicationSettings.staticContentUrl}/images/logos/WorldVision.png`}
        alt="World Vision"
        agentCode={config.code}
      />
    );
  }
};

export { onComparisonTimeout, renderDemoVouchers, sleep };
