import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isIOS, isAndroid } from 'react-device-detect';
import { Row, Col, Modal, ModalBody, ModalFooter, Button, Container } from 'reactstrap';

import LinkServiceDialogProviders from './linkServiceDialogProviders';
import AgreeTerms from '../common/agreeTerms';

const EmailForwardDialog = ({ receiveBillEmailAddress }) => {
  const [dialogOpen, setDialogOpen] = useState();

  const onDialogToggle = () => {
    setDialogOpen(!dialogOpen);
  };

  const { submitBillForwardYourBillStepDescription } = useSelector(state => state.content.general);

  var apps = [];
  apps.push({ appName: 'Gmail', urlIos: 'googlegmail://', urlAndroid: null });
  apps.push({ appName: 'Outlook', urlIos: 'ms-outlook://', urlAndroid: 'ms-outlook://' });
  apps.push({ appName: 'Apple Mail', urlIos: 'message://', urlAndroid: null });
  apps.push({ appName: 'Yahoo', urlIos: 'ymail://', urlAndroid: null });
  apps.push({ appName: 'Spark', urlIos: 'readdle-spark://', urlAndroid: 'readdle-spark://' });
  apps.push({ appName: 'Protonmail', urlIos: 'protonmail://', urlAndroid: null });

  return (
    <>
      <Button className="qs-button-submit" onClick={onDialogToggle}>
        See providers...
      </Button>

      <Modal toggle={onDialogToggle} isOpen={dialogOpen} size="lg" centered={false} backdrop={'static'}>
        <ModalBody className="qs-modal-body">
          <Container>
            <Row>
              <Col xs="10" className="qs-modal-heading">
                <h1>Forward your e-bill</h1>
              </Col>
              <Col xs="2">
                <div onClick={onDialogToggle} className="qs-modal-close-icon">
                  <i className="material-icons">close</i>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  1. Open your mail client to which your e-bills are sent
                  {(isIOS || isAndroid) && (
                    <>
                      , links to common clients apps are below
                      <ul className="qs-mail-clients">
                        {apps.map(a => {
                          return (
                            <>
                              {isIOS && a.urlIos && (
                                <li>
                                  <a href={a.urlIos} key={a.appName}>
                                    {a.appName}
                                  </a>
                                </li>
                              )}

                              {isAndroid && a.urlAndroid && (
                                <li>
                                  <a href={a.urlAndroid} key={a.appName}>
                                    {a.appName}
                                  </a>
                                </li>
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </p>
                <p>2. Search for your current retailers name to find your latest e-bill</p>
                <p>
                  3. <AgreeTerms />
                </p>
                <p>
                  4. {submitBillForwardYourBillStepDescription} <strong>{receiveBillEmailAddress}</strong>
                </p>
                <p>
                  <strong>Or</strong>
                </p>
                <p>If you don't currently receive an e-bill you can request a one-off e-bill to be sent to you.</p>

                <LinkServiceDialogProviders />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onDialogToggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EmailForwardDialog;
