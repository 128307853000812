import { navigationTypes } from './navigationTypes';

export const pageModes = {
  NONE: { id: 0, name: 'None' },
  DEFAULT: { id: 1, name: 'Default' },
  FRAMED: { id: 2, name: 'Framed' }
};

export const launchDialogModes = {
  DEFAULT: { id: 0, tag: 'default' },
  RESTRICTED: { id: 1, tag: 'restricted' },
  getById: id => {
    switch (id) {
      case launchDialogModes.RESTRICTED.id:
        return launchDialogModes.RESTRICTED;

      default:
        return launchDialogModes.DEFAULT;
    }
  }
};

export const operatorModes = {
  NONE: { id: 0, tag: '' },
  DEFAULT: { id: 1, tag: 'operator' },
  getByTag: tag => {
    const operatorTag = tag && tag.toLowerCase();

    switch (operatorTag) {
      case operatorModes.DEFAULT.tag:
        return operatorModes.DEFAULT;

      default:
        return operatorModes.NONE;
    }
  }
};

export const homePageTypes = {
  DEFAULT: { id: 0, path: navigationTypes.HOME.path, navigationType: navigationTypes.HOME },
  DASHBOARD: { id: 1, path: navigationTypes.DASHBOARD.path, navigationType: navigationTypes.DASHBOARD },
  START: { id: 2, path: navigationTypes.START.path, navigationType: navigationTypes.START },
  getById: id => {
    switch (id) {
      case homePageTypes.DASHBOARD.id:
        return homePageTypes.DASHBOARD;
      case homePageTypes.START.id:
        return homePageTypes.START;
      default:
        return homePageTypes.DEFAULT;
    }
  }
};

export const agentOperatingModes = {
  NONE: { id: 0, tag: 'none' },
  ACTIVE: { id: 1, tag: 'active' },
  INACTIVE: { id: 2, tag: 'inactive' },
  getById: id => {
    switch (id) {
      case agentOperatingModes.ACTIVE.id:
        return agentOperatingModes.ACTIVE;

      case agentOperatingModes.INACTIVE.id:
        return agentOperatingModes.INACTIVE;

      default:
        return agentOperatingModes.NONE;
    }
  }
};
