import React from 'react';
import { useSelector } from 'react-redux';

import Sheet from '../Sheet/Sheet';
import CustomerCommissionLogo from '../common/customerCommissionLogo';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { billTypes } from '../../enums/billTypes';
import { billingTypes, submissionStatuses } from '../../enums/submissionTypes';

import { ReactComponent as Estimate } from '../icons/util/service-card-estimate.svg';

const ServiceCardDefault = props => {
  const {
    serviceType,
    isLinked,
    isAwaitingLink,
    status,
    billingType,
    nextCompareDate,
    isSample,
    isBill,
    isEstimate,
    reductionLoaded,
    convertedProviderDisplayCode,
    providerLogo,
    providerCode,
    reductionDescription,
    reductionPercentage,
    billAmountCurrent,
    errorMessages,
    bestOffer,
    isDefaultComparison,
    onBillSheetClick
  } = props;

  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const isHomeLoan = serviceType === billTypes.HOME_LOAN;
  const isInternet = serviceType === billTypes.INTERNET;
  const isSolarFinance = serviceType === billTypes.SOLAR_FINANCE;

  const pdfFileMissing = (errorMessages && errorMessages[0].field === 'PdfFileMissing') || !providerLogo;
  const switched =
    status === submissionStatuses.CONVERTED.Id &&
    billingType !== billingTypes.NONE.Id &&
    billingType !== billingTypes.EMAIL_ORGANISER.Id;

  const getBodyText = () => {
    if (isAwaitingLink) return `Awaiting first bill from ${convertedProviderDisplayCode}`;
    if (isLinked) return `We're receiving your latest bills from ${convertedProviderDisplayCode}`;
    if (!reductionLoaded && !switched) return 'Please wait...';
    if (isSolarFinance && switched && errorMessages && errorMessages[0].field === 'NoOffersAvailable')
      return "You're organised";
    if (errorMessages) return errorMessages[0].message;

    if (reductionPercentage > 0) return `Pay ${reductionPercentage}% less for this bill`;

    if (isBill) {
      if (isLinked || isAwaitingLink || nextCompareDate || status === submissionStatuses.CONVERTED.Id)
        return "You're organised";
      return "You're on our best matched plan";
    }

    if (switched) return "You're organised";

    return reductionDescription;
  };

  const getSheetImage = () => {
    if (isBill && !pdfFileMissing)
      return (
        <>
          <img src={`${staticContentUrl}/images/providers/${providerLogo}`} alt={`${providerCode} logo`} />
          {serviceType.ComparisonImplemented && (
            <>
              <div className="title">Your spend</div>
              <div className="amount">{billAmountCurrent}</div>
            </>
          )}
        </>
      );

    if (isHomeLoan && bestOffer)
      return (
        <>
          <img src={bestOffer.providerLogoUrl} alt={`${bestOffer.providerDisplayCode} logo`} />
          <div className="title-small">Comparison Rate</div>
          <div className="amount">{`${bestOffer.comparisonRate}% pa`}</div>
        </>
      );

    if (isInternet && !isDefaultComparison)
      return (
        <>
          {serviceType.ComparisonImplemented && (
            <>
              <div className="title">Your spend</div>
              <div className="amount">{billAmountCurrent}</div>
            </>
          )}
        </>
      );

    if (isEstimate) {
      return <Estimate className="icon" width="80" height="80" />;
    }

    return null;
  };

  const getCustomerCommissionLogo = () => {
    const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);

    if (
      isAwaitingLink ||
      !reductionLoaded ||
      errorMessages ||
      (isEstimate && !isInternet && !isHomeLoan) ||
      reductionPercentage <= 0 ||
      !agentServiceType.hasCommission
    )
      return null;

    return <CustomerCommissionLogo serviceType={serviceType} />;
  };

  return (
    <>
      <div className="service-card-inner">
        <div className="body-text-wrap">
          <div className="text">{getBodyText()}</div>
          {getCustomerCommissionLogo()}
        </div>

        <div onClick={pdfFileMissing ? undefined : onBillSheetClick}>
          <Sheet className={`body-sheet${pdfFileMissing || isSample ? ' disabled' : ''}`}>{getSheetImage()}</Sheet>
        </div>
      </div>
    </>
  );
};

export default ServiceCardDefault;
