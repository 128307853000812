import { pageModes } from '../enums/launchTypes';
import * as types from './actions';

export function setApplicationSuccess(data) {
  return { type: types.SET_APPLICATION, data };
}

export function googleLoaded(pageMode) {
  return dispatch => {
    if (pageMode === pageModes.NONE) {
      // Need to set the page mode here after page is ready, if it's not already in been set.
      dispatch(setPageMode(pageModes.DEFAULT, null));
    }

    dispatch(
      setApplicationSuccess({
        googleLoaded: true
      })
    );
  };
}

export function setPageMode(pageMode, frameHost) {
  const layout = {
    pageMode,
    frameHost
  };

  return dispatch => {
    dispatch(setApplicationSuccess(layout));
  };
}

export function setFrameHeight(height) {
  const layout = {
    frameHeight: height
  };

  return dispatch => {
    dispatch(setApplicationSuccess(layout));
  };
}

export function setFrameDialog(visible) {
  const layout = {
    frameDialog: visible
  };

  return dispatch => {
    dispatch(setApplicationSuccess(layout));
  };
}
