import React from 'react';
import { useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { billTypes } from '../../enums/billTypes';
import { pageModes } from '../../enums/launchTypes';

const FooterDisclaimers = ({
  showComparisonResultsDisclaimer,
  showAnnualSavingDisclaimer,
  showCompanyInfo,
  pageMode
}) => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const {
    partnerDisclaimer,
    productsDisclaimer,
    complaintsDisclaimer,
    additionalDisclaimer,
    annualSavingDisclaimer,
    comparisonResultsDisclaimer,
    footerCompany
  } = useSelector(state => state.content.general);

  const hasHomeLoan = billTypes.hasServiceType(agentBillTypes, billTypes.HOME_LOAN);

  const getAnnualSavingDisclaimer = () => {
    return `Annual Saving ${hasHomeLoan ? 'and Repayment Saving ' : ''}${annualSavingDisclaimer}`;
  };

  return (
    <div className={`sm-footer-text-disclaimers${pageMode === pageModes.FRAMED ? ' framed' : ''}`}>
      <div className="footer-text-disclaimer">
        {'* '}
        <span
          dangerouslySetInnerHTML={{
            __html: partnerDisclaimer
          }}
        />{' '}
        <span
          dangerouslySetInnerHTML={{
            __html: productsDisclaimer
          }}
        />
        {', '}
        <HashLink smooth to="/faq#retailers">
          view more details
        </HashLink>
        {'. '}
        <span
          dangerouslySetInnerHTML={{
            __html: complaintsDisclaimer
          }}
        />{' '}
        {showComparisonResultsDisclaimer && (
          <span
            dangerouslySetInnerHTML={{
              __html: comparisonResultsDisclaimer
            }}
          />
        )}
      </div>

      {showAnnualSavingDisclaimer && (
        <div className="footer-text-disclaimer">
          {'† '}
          <span
            dangerouslySetInnerHTML={{
              __html: getAnnualSavingDisclaimer()
            }}
          />
        </div>
      )}

      {additionalDisclaimer && <div className="footer-text-disclaimer">{additionalDisclaimer}</div>}

      {showCompanyInfo && (
        <div className="sm-footer-text-company">
          &copy; {new Date().getFullYear()}{' '}
          <span
            dangerouslySetInnerHTML={{
              __html: footerCompany
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FooterDisclaimers;
