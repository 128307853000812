import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import { Row, Col, Container, Button, Collapse } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import * as landingActions from '../../actions/landingActions';
import * as partnerActions from '../../actions/partnerActions';
import OfferMoreInfoEnergyAu from './offerMoreInfoEnergyAu';
import OfferMoreInfoEnergyUs from './offerMoreInfoEnergyUs';
import OfferMoreInfoHomeLoanAu from './offerMoreInfoHomeLoanAu';
import OfferMoreInfoInternetAu from './offerMoreInfoInternetAu';
import OfferEnergyAu from './offerEnergyAu';
import OfferEnergyUs from './offerEnergyUs';
import OfferInternetAu from './offerInternetAu';
import OfferHomeLoanAu from './offerHomeLoanAu';
import OfferConfirm from './offerConfirm';
import GeneralDialog from '../dialogs/generalDialog';
import { Auth0Context } from '../../auth/auth0-Provider';
import { renderDemoVouchers } from '../../utilities/compareUtilities';

import { implementationCodes } from '../../enums/implementationCodes';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { billTypes } from '../../enums/billTypes';
import { restartSources } from '../../enums/partnerFrameTypes';

import CommissionTermsDialog from '../dialogs/commissionTermsDialog';
import { offerEvents } from '../../utilities/googleAnalyticsEvents';

export class Offer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moreInfoOpen: this.props.moreInfoOpen,
      moreInfoDialogOpen: false,
      allowOpenCloseInfo: this.props.allowOpenCloseInfo,
      emailAddress: this.props.emailAddress,
      moreInfoOpenText: this.props.moreInfoOpenText,
      moreInfoCloseText: this.props.moreInfoCloseText,
      mode: this.props.mode,
      billReductionPlanInfoOpen: false,
      referencePriceInfoOpen: false
    };

    this.submitButton = React.createRef();
    this.submitClick = this.submitClick.bind(this);
    this.resetMoreInfo = this.resetMoreInfo.bind(this);
    this.primaryButtonText = this.props.comparison.isPostcodeComparison
      ? 'Check my address'
      : this.props.primaryButtonText;
  }

  submitClick() {
    this.submitButton.current.click();
  }

  resetMoreInfo() {
    if (this.props.landing.comparison.openMoreInfo) {
      this.props.landingActions.setLanding({
        ...this.props.landing,
        comparison: { ...this.props.landing.comparison, openMoreInfo: false }
      });
    }
  }

  componentDidMount = () => {
    this.resetMoreInfo();
  };

  onDisplayCustomerCommissionCardTerms = () => {
    offerEvents('customer-commission', this.props.offer, this.props.config.settings.googleAnalyticsClientCode);
  };

  onMoreInfo = (event, offer, newState) => {
    this.resetMoreInfo();

    var moreInfoOpen = this.state.moreInfoOpen;
    var moreInfoState = newState ? newState : !moreInfoOpen;

    if (this.props.allowToggle) {
      this.setState({ moreInfoOpen: moreInfoState });
    }
    if (this.props && this.props.onMoreInfo) this.props.onMoreInfo(event, offer, moreInfoState);
  };

  handleInvalidSubmit = (event, errors, values) => {
    if (errors) {
      scroller.scrollTo(errors[0], { duration: 800, delay: 0, smooth: 'easeInOutQuart', offset: -20 });
    }
  };

  getSponsorOfferDescription = () => {
    if (!this.props.offer.isSponsored) return null;

    var serviceType = billTypes.getByCode(this.props.comparison.submission.billType);
    var agentServiceType = getAgentServiceType(serviceType, this.props.config.billTypes);

    var provider = agentServiceType.providers.find(e => (e.providerCode = this.props.offer.providerCode));
    if (!provider.customerCommissionDescription) return null;

    return (
      <Row>
        <Col xs="12">
          <div className="sm-sponsor-commission">
            {provider.customerCommissionDescription}
            <div>
              Sponsored offer{' - '}
              {this.props.offer.isSponsored && this.getSponsorOfferDialog()}
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  getSponsorOfferDialog = () => {
    const serviceType = billTypes.getByCode(this.props.comparison.submission.billType);

    return (
      <CommissionTermsDialog
        buttonText="Terms"
        style={{ color: 'white', textDecoration: 'underline', marginBottom: '2px' }}
        dialogKey={`commissionTerms_${this.props.counter}`}
        serviceType={serviceType}
        providerCode={this.props.offer.providerCode}
        onOpen={this.onDisplayCustomerCommissionCardTerms}
      />
    );
  };

  moreInfo = preload => {
    switch (this.props.comparison.implementationCode) {
      case implementationCodes.ELECTRICITY.US_DEFAULT:
        return <OfferMoreInfoEnergyUs offer={this.props.offer} comparison={this.props.comparison} />;

      case implementationCodes.HOME_LOAN.AU_DEFAULT:
        return <OfferMoreInfoHomeLoanAu offer={this.props.offer} comparison={this.props.comparison} />;

      case implementationCodes.INTERNET.AU_DEFAULT:
        return (
          <OfferMoreInfoInternetAu offer={this.props.offer} comparison={this.props.comparison} preload={preload} />
        );

      default:
        return (
          <OfferMoreInfoEnergyAu
            offer={this.props.offer}
            comparison={this.props.comparison}
            pricingDocumentName={this.props.comparison.pricingDocumentName}
          />
        );
    }
  };

  renderModeComponent = (isAuthenticated, getTokenSilently) => {
    switch (this.props.mode) {
      case 0:
        return (
          <OfferConfirm
            offer={this.props.offer}
            step="terms"
            submitForm={this.submitClick}
            primaryButtonText={this.primaryButtonText}
            isAuthenticated={isAuthenticated}
            getTokenSilently={getTokenSilently}
          />
        );

      case 1:
        return (
          <OfferConfirm
            offer={this.props.offer}
            step="details"
            submitForm={this.submitClick}
            primaryButtonText={this.primaryButtonText}
            isAuthenticated={isAuthenticated}
            getTokenSilently={getTokenSilently}
          />
        );

      default:
        return this.moreInfo();
    }
  };

  renderOfferComponent = () => {
    switch (this.props.comparison.implementationCode) {
      case implementationCodes.ELECTRICITY.US_DEFAULT:
        return (
          <OfferEnergyUs
            offerIndex={this.props.counter}
            offer={this.props.offer}
            comparison={this.props.comparison}
            mode={this.props.mode}
          />
        );

      case implementationCodes.HOME_LOAN.AU_DEFAULT:
        return (
          <OfferHomeLoanAu
            offerIndex={this.props.counter}
            offer={this.props.offer}
            comparison={this.props.comparison}
          />
        );

      case implementationCodes.INTERNET.AU_DEFAULT:
        return (
          <OfferInternetAu
            offerIndex={this.props.counter}
            offer={this.props.offer}
            comparison={this.props.comparison}
            mode={this.props.mode}
          />
        );

      default:
        return (
          <OfferEnergyAu
            offerIndex={this.props.counter}
            offer={this.props.offer}
            comparison={this.props.comparison}
            mode={this.props.mode}
            onMoreInfo={this.onMoreInfo}
          />
        );
    }
  };

  onRef = ref => {
    if (ref) {
      let inputs = {
        hiddenLifeSupport: ref._inputs['hiddenLifeSupport']
      };
      ref.setTouched(Object.keys(inputs));
    }
  };

  render() {
    var delay = 'delay-' + (this.props.counter + this.props.initialDelay) * 50 + 'ms';

    var containerClass =
      'animated fadeInUpSmall fast qs-offer ' +
      delay +
      ' qs-offer-' +
      this.props.offer.indicator +
      (this.props.mode === 1 ? ' qs-offer-accepted' : '');

    return (
      <Auth0Context.Consumer>
        {props => (
          <Container className={containerClass} id={`offer${this.props.offer.id}`}>
            <AvForm
              onValidSubmit={() => {
                if (this.props.comparison.isPostcodeComparison) {
                  this.props.partnerActions.resetFramePartner(restartSources.OFFER);
                } else if (this.props.onSelectOffer) {
                  this.props.onSelectOffer(this.props.offer, this.props.comparison.submissionId);
                }
              }}
              onInvalidSubmit={this.handleInvalidSubmit}
              ref={this.onRef}
            >
              {this.getSponsorOfferDescription()}

              {this.renderOfferComponent()}
              {this.props.mode === 0 && !this.props.offer.isSummaryOfferOnly && (
                <div className="qs-offer-more-info-button">
                  <Button
                    className="sm-link-button"
                    onClick={() => this.setState({ moreInfoDialogOpen: !this.state.moreInfoDialogOpen })}
                  >
                    More info
                  </Button>
                </div>
              )}
              <Row noGutters={true} className="qs-more-info">
                <Col xs="12">
                  <Collapse isOpen={this.state.moreInfoOpen}>
                    <div className="qs-more-info-open">
                      <Container>
                        {this.renderModeComponent(props.isUserAuthenticated(), props.getTokenSilently)}
                      </Container>
                    </div>
                  </Collapse>
                </Col>
              </Row>
              {renderDemoVouchers(this.props.config, this.props.user, this.props.mode)}
              <Row className="qs-offer-buttons">
                <Col xs={this.props.isDirectSwitch ? 12 : 7} md={this.props.isDirectSwitch ? 12 : 8}>
                  <Button
                    className={`sm-offer-button ${
                      this.props.offer.indicator === 'best-overall' && !this.props.comparison.isPostcodeComparison
                        ? 'animated pulse slower infinite'
                        : ''
                    }`}
                  >
                    {this.primaryButtonText}
                  </Button>
                  <input className="qs-tc-hidden" type="submit" ref={this.submitButton} />
                </Col>
                {this.state.allowOpenCloseInfo && !this.props.isDirectSwitch && (
                  <Col xs="5" md="4">
                    <Button
                      outline
                      className="qs-button-info"
                      onClick={event => this.onMoreInfo(event, this.props.offer)}
                    >
                      {this.state.moreInfoOpen ? this.state.moreInfoCloseText : this.state.moreInfoOpenText}
                    </Button>
                  </Col>
                )}
              </Row>
            </AvForm>
            {this.props.mode === 0 && (
              <GeneralDialog
                key={`moreInfo_${this.props.counter}`}
                dialogSize="lg"
                closeButtonText="Close"
                bodyClass="min-padding"
                dialogOpen={this.state.moreInfoDialogOpen}
                onClose={() => this.setState({ moreInfoDialogOpen: false })}
              >
                <AvForm className={`${containerClass} sm-more-info-wrapper`}>{this.moreInfo(true)}</AvForm>
              </GeneralDialog>
            )}
          </Container>
        )}
      </Auth0Context.Consumer>
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.moreInfoOpen && nextProps.moreInfoOpen) return { moreInfoOpen: nextProps.moreInfoOpen };

    return null;
  }
}

const mapStateToProps = state => {
  return {
    config: state.config,
    content: state.content,
    user: state.user,
    comparison: state.comparison,
    registration: state.registration,
    landing: state.landing
  };
};

function mapDispatchToProps(dispatch) {
  return {
    landingActions: bindActionCreators(landingActions, dispatch),
    partnerActions: bindActionCreators(partnerActions, dispatch)
  };
}

Offer.propTypes = {
  offer: PropTypes.object.isRequired,
  counter: PropTypes.number.isRequired,
  allowOpenCloseInfo: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
