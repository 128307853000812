import React from 'react';
import { Row, Col } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';

const BillDisplayAddress = props => {
  const { displayAddress } = props;

  return (
    <Row>
      <Col xs="12">
        <AvField disabled={true} bsSize="sm" name="displayAddress" type="text" value={displayAddress} />
      </Col>
    </Row>
  );
};

export default BillDisplayAddress;
