const getAddressComponent = (addressComponents, componentToGet, getLongName) => {
  var component = null;

  for (var i = 0; i < addressComponents.length; i++) {
    var foundType = false;
    for (var j = 0; j < addressComponents[i].types.length; j++) {
      if (addressComponents[i].types[j] === componentToGet) foundType = true;
    }

    if (foundType) {
      if (getLongName) component = addressComponents[i].long_name;
      if (!getLongName || !component) component = addressComponents[i].short_name;
      break;
    }
  }

  return component;
};
export { getAddressComponent };
