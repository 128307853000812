export const concessionTypesEnum = {
  NONE: { Id: 0, Validation: null },
  CENTRELINK_HEALTH_CARE: {
    Id: 1,
    Validation: '^[0-9]{9}[a-zA-Z]$',
    ValidationError: 'Your concession card number should be 9 numbers and one letter at the end.'
  },
  CENTRELINK_PENSIONER: { Id: 2, Validation: null },
  VETERAN_AFFAIRS_PENSIONER: { Id: 3, Validation: null },
  VETERAN_AFFAIRS_GOLD: { Id: 4, Validation: null },
  VETERAN_AFFAIRS_WAR_WIDOW: { Id: 5, Validation: null },
  VETERAN_AFFAIRS_GOLD_TPI: { Id: 6, Validation: null },
  QLD_GOVERNMENT_SENIOR: { Id: 7, Validation: null },

  getById: concessionTypeId => {
    switch (concessionTypeId) {
      case concessionTypesEnum.CENTRELINK_HEALTH_CARE.Id:
        return concessionTypesEnum.CENTRELINK_HEALTH_CARE;
      case concessionTypesEnum.CENTRELINK_PENSIONER.Id:
        return concessionTypesEnum.CENTRELINK_PENSIONER;
      case concessionTypesEnum.VETERAN_AFFAIRS_PENSIONER.Id:
        return concessionTypesEnum.VETERAN_AFFAIRS_PENSIONER;
      case concessionTypesEnum.VETERAN_AFFAIRS_GOLD.Id:
        return concessionTypesEnum.VETERAN_AFFAIRS_GOLD;
      case concessionTypesEnum.VETERAN_AFFAIRS_WAR_WIDOW.Id:
        return concessionTypesEnum.VETERAN_AFFAIRS_WAR_WIDOW;
      case concessionTypesEnum.VETERAN_AFFAIRS_GOLD_TPI.Id:
        return concessionTypesEnum.VETERAN_AFFAIRS_GOLD_TPI;
      case concessionTypesEnum.QLD_GOVERNMENT_SENIOR.Id:
        return concessionTypesEnum.QLD_GOVERNMENT_SENIOR;
      default:
        return null;
    }
  }
};
