import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';

import * as serverMessageTargets from '../../actions/serverMessageTargets';
import { postFrameDialogMessage } from '../../utilities/domUtilities';

import { implementationCodes } from '../../enums/implementationCodes';
import { billingTypes } from '../../enums/submissionTypes';
import { buttonIcons, buttonTypes } from '../../enums/buttonTypes';
import { pageModes } from '../../enums/launchTypes';

import ThrottleButton from '../common/throttleButton';
import ServerMessage from '../../components/common/serverMessage';
import OfferConfirmTermsAndConditionsAu from '../common/offerConfirmTermsAndConditionsAu';
import PoweredBySmartMe from '../common/poweredBySmartMe';
import OrganiserDescription from '../common/organiserDescription';
import OrganiserName from '../common/organiserName';

const OtpDialog = props => {
  const {
    onOtpCancel,
    isOtpOpen,
    onChange,
    onSubmit,
    buttonText,
    serverMessage,
    offer,
    confirmDetail,
    onSendOtp,
    implementationCode,
    setConfirmDetail
  } = props;

  const ref = useRef(null);
  const dispatch = useDispatch();

  const { OTP_DIALOG: target } = serverMessageTargets;

  const { organiserAgentName } = useSelector(state => state.content.general);
  const { showPoweredBy } = useSelector(state => state.config.settings);
  const { pageMode, frameHeight, frameHost } = useSelector(state => state.application);

  const personalData = offer && offer.saleData && offer.saleData.sale_data ? offer.saleData.sale_data : null;
  const planData =
    offer && offer.saleData && offer.saleData.plan_data && offer.saleData.plan_data['0']
      ? offer.saleData.plan_data['0']
      : null;

  const isOrganiser = confirmDetail.billingType === billingTypes.EMAIL_ORGANISER.Code;

  const billingAddress = personalData
    ? isOrganiser
      ? `${confirmDetail.emailAddress} (via ${organiserAgentName})`
      : personalData.billing_address
    : null;

  const providerDisplayCode = offer.providerDisplayCode;

  const handleModal = () => {
    if (pageMode === pageModes.FRAMED) {
      dispatch(postFrameDialogMessage(frameHost, 'Idle Timeout', isOtpOpen, ref.current, frameHeight));
    }
  };

  return (
    <>
      <Modal
        onOpened={handleModal}
        onClosed={handleModal}
        toggle={onOtpCancel}
        isOpen={isOtpOpen}
        size="md"
        centered={pageMode === pageModes.DEFAULT}
        backdrop={'static'}
      >
        <div ref={ref}>
          <ModalBody className="qs-modal-body">
            <div>
              {implementationCode === implementationCodes.INTERNET.AU_DEFAULT && personalData && planData && (
                <>
                  <h2>Review your details</h2>
                  <h5>Personal details</h5>
                  <table className="table table-striped table-sm">
                    <tbody>
                      <tr>
                        <td style={{ width: '25%' }}>Name</td>
                        <td>{personalData.complete_name}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{confirmDetail.emailAddress}</td>
                      </tr>
                      <tr>
                        <td>Mobile number</td>
                        <td>{personalData.phone}</td>
                      </tr>
                      {personalData.alternate_phone && (
                        <tr>
                          <td>Alternate phone</td>
                          <td>{personalData.alternate_phone}</td>
                        </tr>
                      )}
                      {personalData.dob && personalData.dob !== '0001-01-01' && (
                        <tr>
                          <td>Date of birth</td>
                          <td>{moment(personalData.dob).format('DD/MM/YYYY')}</td>
                        </tr>
                      )}

                      <tr>
                        <td>Connection address</td>
                        <td>{personalData.connection_address}</td>
                      </tr>
                      <tr>
                        <td>Delivery address</td>
                        <td>{personalData.delivery_address}</td>
                      </tr>
                      <tr>
                        <td>Billing address</td>
                        <td dangerouslySetInnerHTML={{ __html: billingAddress }} />
                      </tr>
                      {personalData.customer_id_no && (
                        <tr>
                          <td>ID card number</td>
                          <td>{personalData.customer_id_no}</td>
                        </tr>
                      )}
                      {personalData.customer_id_expiry_date && (
                        <tr>
                          <td>ID expiry date</td>
                          <td>{personalData.customer_id_expiry_date}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <h5>Plan details</h5>
                  <table className="table table-striped table-sm">
                    <tbody>
                      <tr>
                        <td style={{ width: '25%' }}>Plan name</td>
                        <td>{planData.plan_name}</td>
                      </tr>
                      <tr>
                        <td>Plan provider</td>
                        <td>{planData.provider_name}</td>
                      </tr>
                      {planData.contract_length && (
                        <tr>
                          <td>Contract length</td>
                          <td>{planData.contract_length} months</td>
                        </tr>
                      )}
                      <tr>
                        <td>Download speed</td>
                        <td>{planData.download_speed} mpbs</td>
                      </tr>
                      <tr>
                        <td>Upload speed</td>
                        <td>{planData.upload_speed} mpbs</td>
                      </tr>
                      <tr>
                        <td>Connection type</td>
                        <td>{planData.connection_name}</td>
                      </tr>
                      <tr>
                        <td>Data</td>
                        <td>{planData.plan_data}</td>
                      </tr>
                      {personalData.calling_plan_name && (
                        <tr>
                          <td>Phone line</td>
                          <td>{personalData.calling_plan_name}</td>
                        </tr>
                      )}
                      {personalData.modem_name && (
                        <tr>
                          <td>Modem</td>
                          <td>{personalData.modem_name}</td>
                        </tr>
                      )}
                      {personalData.addon_name && (
                        <tr>
                          <td>Selected addons</td>
                          <td>{personalData.addon_name}</td>
                        </tr>
                      )}
                      {personalData.conn_del_date && (
                        <tr>
                          <td>Connection date</td>
                          <td>{personalData.conn_del_date}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <h5>Terms and conditions</h5>
                  {offer.productDetail.eicContent.map((eic, index) => (
                    <>
                      <p>
                        <b>{eic.title}</b>
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: eic.description }} />
                      </p>
                    </>
                  ))}

                  <OfferConfirmTermsAndConditionsAu
                    title="Your acknowledgment and acceptance"
                    confirmDetail={confirmDetail}
                    setConfirmDetail={setConfirmDetail}
                    offer={offer}
                    onChange={onChange}
                  />
                </>
              )}

              <h5>Verification</h5>
              <ServerMessage serverMessage={serverMessage} target={target} />
              <p>
                A text message with a verification code was just sent to <strong>{confirmDetail.phoneNumber}</strong>.
              </p>

              {isOrganiser && (
                <div className="sm-organiser-verification">
                  <p style={{ fontWeight: 'bold' }}>
                    Important information about how your <OrganiserName /> works
                  </p>
                  <OrganiserDescription
                    providerDisplayCode={providerDisplayCode}
                    preText={
                      <>
                        You've requested that your {providerDisplayCode} bills be sent to your <OrganiserName />
                      </>
                    }
                    postText="  This is:"
                    emailAddress={confirmDetail.emailAddress}
                  />
                  <div className="sm-organiser-verification-account-address">{offer.autoCheckEmailAddress}</div>
                  <p>
                    We'll send you a confirmation email that contains all of these details for your future reference.
                  </p>
                  <p>
                    If you've changed your mind about using your <OrganiserName />, please select 'Cancel' below and
                    update your bill delivery preferences before proceeding.
                  </p>
                </div>
              )}
              <p>
                By submitting this verification code, you acknowledge that you have read and agree to the terms and
                conditions of this offer.
              </p>

              <h5>Please enter verification code</h5>

              <div className="sm-otp-verification">
                <div>
                  <AvField
                    bsSize="sm"
                    placeholder="Verification code"
                    name="otp"
                    type="text"
                    value={confirmDetail.otp}
                    onChange={e => onChange(e, { otp: e.target.value })}
                    validate={{
                      required: {
                        value: offer.requireOtp,
                        errorMessage: 'Please enter the verification code sent to your mobile phone'
                      },
                      pattern: {
                        value: '^[0-9]{4,6}',
                        errorMessage: 'Please enter the verification code sent to your mobile phone'
                      }
                    }}
                  />
                </div>
                <div className="resend">
                  <ThrottleButton
                    limit={30}
                    buttonText="Resend code"
                    throttledButtonText="Resend unavailable for {seconds} secs"
                    onClick={async () => {
                      await onSendOtp();
                    }}
                    buttonType={buttonTypes.ICON}
                    buttonIcon={buttonIcons.REFRESH}
                    buttonKey="otp-resend"
                    enabled
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onOtpCancel} className="medium">
              Cancel
            </Button>
            <Button onClick={onSubmit} className="sm-button-primary medium">
              {buttonText}
            </Button>
          </ModalFooter>
          {showPoweredBy && (
            <div className="sm-otp-dialog-powered-by-container">
              <PoweredBySmartMe />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default OtpDialog;
