import { getAgentServiceType } from './customerCommissionTypes';

export const billTypes = {
  NONE: {
    Id: 0,
    Code: '',
    Name: '',
    Unit: null,
    Tag: 'none',
    AddText: 'Please upload e-bill or confirm property address',
    ComparisonImplemented: false,
    HasSorting: false,
    SortOrder: 0
  },
  ELECTRICITY: {
    Id: 1,
    Code: 'Electricity',
    Name: 'Electricity',
    Tag: 'electricity',
    Unit: 'kWh',
    AddText: 'Compare electricity',
    ApiPath: 'energy',
    ComparisonImplemented: true,
    HasSorting: true,
    HasFilter: false,
    SortOrder: 1
  },
  GAS: {
    Id: 2,
    Code: 'Gas',
    Name: 'Gas',
    Tag: 'gas',
    Unit: 'MJ',
    AddText: 'Compare gas',
    ApiPath: 'energy',
    ComparisonImplemented: true,
    HasSorting: true,
    HasFilter: false,
    SortOrder: 2
  },
  INTERNET: {
    Id: 3,
    Code: 'Internet',
    Name: 'Broadband',
    Tag: 'internet',
    Unit: null,
    AddText: 'Compare broadband',
    ApiPath: 'internet',
    ComparisonImplemented: true,
    HasSorting: true,
    HasFilter: true,
    SortOrder: 3
  },
  HOME_LOAN: {
    Id: 4,
    Code: 'HomeLoan',
    Name: 'Home Loan',
    Tag: 'home-loan',
    Unit: null,
    AddText: 'Compare home loan',
    ApiPath: 'home-loan',
    ComparisonImplemented: true,
    HasSorting: true,
    HasFilter: true,
    SortOrder: 4
  },
  VECHICLE_INSURANCE: {
    Id: 5,
    Code: 'CarInsurance',
    Name: 'Car Insurance',
    Tag: 'vehicle-insurance',
    Unit: null,
    AddText: 'Compare car insurance',
    ComparisonImplemented: false,
    HasSorting: false,
    HasFilter: false,
    SortOrder: 5
  },
  HOME_AND_CONTENTS_INSURANCE: {
    Id: 6,
    Code: 'HomeAndContentsInsurance',
    Name: 'Home Insurance',
    Tag: 'home-insurance',
    Unit: null,
    AddText: 'Compare home insurance',
    ComparisonImplemented: false,
    HasSorting: false,
    HasFilter: false,
    SortOrder: 6
  },
  HEALTH_INSURANCE: {
    Id: 7,
    Code: 'HealthInsurance',
    Name: 'Health Insurance',
    Tag: 'health',
    Unit: null,
    AddText: 'Compare health insurance',
    ComparisonImplemented: false,
    HasSorting: false,
    HasFilter: false,
    SortOrder: 7
  },
  MOBILE_PHONE: {
    Id: 8,
    Code: 'MobilePhone',
    Name: 'Mobile Phone',
    Tag: 'mobile-phone',
    Unit: null,
    AddText: 'Compare mobile phone plans',
    ComparisonImplemented: false,
    HasSorting: false,
    HasFilter: false,
    SortOrder: 8
  },
  SOLAR_INSTALLATION: {
    Id: 9,
    Code: 'SolarInstallation',
    Name: 'Solar Installation',
    Tag: 'solar-installation',
    Unit: null,
    AddText: 'Find a solar installer',
    ComparisonImplemented: false,
    HasSorting: false,
    HasFilter: false,
    SortOrder: 9
  },
  SOLAR_FINANCE: {
    Id: 10,
    Code: 'SolarFinance',
    Name: 'Solar Finance',
    Tag: 'solar-finance',
    Unit: null,
    AddText: 'Finance your solar installation',
    ApiPath: 'solar-finance',
    ComparisonImplemented: true,
    HasSorting: false,
    HasFilter: false,
    SortOrder: 10
  },
  BUSINESS_LOAN: {
    Id: 11,
    Code: 'BusinessLoan',
    Name: 'Business Loan',
    Tag: 'business-loan',
    Unit: null,
    AddText: 'Find a business loan',
    ComparisonImplemented: false,
    HasSorting: false,
    HasFilter: false,
    SortOrder: 11
  },
  ENERGY: {
    Id: 1000,
    Code: 'Energy',
    Name: 'Electricity + Gas',
    Tag: 'energy',
    Unit: null,
    AddText: 'Compare energy',
    ComparisonImplemented: false,
    HasSorting: false,
    HasFilter: false,
    SortOrder: 1000
  },

  getById: (id, defaultValue) => {
    switch (id) {
      case billTypes.NONE.Id:
        return billTypes.NONE;
      case billTypes.ELECTRICITY.Id:
        return billTypes.ELECTRICITY;
      case billTypes.GAS.Id:
        return billTypes.GAS;
      case billTypes.INTERNET.Id:
        return billTypes.INTERNET;
      case billTypes.HOME_LOAN.Id:
        return billTypes.HOME_LOAN;
      case billTypes.VECHICLE_INSURANCE.Id:
        return billTypes.VECHICLE_INSURANCE;
      case billTypes.HOME_AND_CONTENTS_INSURANCE.Id:
        return billTypes.HOME_AND_CONTENTS_INSURANCE;
      case billTypes.HEALTH_INSURANCE.Id:
        return billTypes.HEALTH_INSURANCE;
      case billTypes.MOBILE_PHONE.Id:
        return billTypes.MOBILE_PHONE;
      case billTypes.SOLAR_INSTALLATION.Id:
        return billTypes.SOLAR_INSTALLATION;
      case billTypes.SOLAR_FINANCE.Id:
        return billTypes.SOLAR_FINANCE;
      case billTypes.BUSINESS_LOAN.Id:
        return billTypes.BUSINESS_LOAN;

      default:
        return defaultValue ? defaultValue : null;
    }
  },

  getByCode: (code, defaultValue) => {
    switch (code && code.toLowerCase ? code.toLowerCase() : null) {
      case billTypes.ELECTRICITY.Code.toLowerCase():
        return billTypes.ELECTRICITY;
      case billTypes.GAS.Code.toLowerCase():
        return billTypes.GAS;
      case billTypes.INTERNET.Code.toLowerCase():
        return billTypes.INTERNET;
      case billTypes.HOME_LOAN.Code.toLowerCase():
        return billTypes.HOME_LOAN;
      case billTypes.VECHICLE_INSURANCE.Code.toLowerCase():
        return billTypes.VECHICLE_INSURANCE;
      case billTypes.HOME_AND_CONTENTS_INSURANCE.Code.toLowerCase():
        return billTypes.HOME_AND_CONTENTS_INSURANCE;
      case billTypes.HEALTH_INSURANCE.Code.toLowerCase():
        return billTypes.HEALTH_INSURANCE;
      case billTypes.MOBILE_PHONE.Code.toLowerCase():
        return billTypes.MOBILE_PHONE;
      case billTypes.SOLAR_INSTALLATION.Code.toLowerCase():
        return billTypes.SOLAR_INSTALLATION;
      case billTypes.SOLAR_FINANCE.Code.toLowerCase():
        return billTypes.SOLAR_FINANCE;
      case billTypes.BUSINESS_LOAN.Code.toLowerCase():
        return billTypes.BUSINESS_LOAN;
      default:
        return defaultValue ? defaultValue : null;
    }
  },

  getSavingIncentive: (staticContentUrl, savingAmount, agentBillType, placeholders) => {
    if (!agentBillType) return null;

    const sortDescending = (a, b) => {
      const min1 = a.minSaving === null ? -1 : a.minSaving;
      const min2 = b.minSaving === null ? -1 : b.minSaving;

      if (min1 > min2) return -1;
      if (min1 < min2) return 1;

      return 0;
    };

    const savingIncentives = [...agentBillType.savingIncentives];

    let incentive;

    if (savingAmount > 0 || savingAmount === 0) {
      const sorted = savingIncentives.sort(sortDescending);

      incentive = { ...sorted.find(e => savingAmount >= e.minSaving) };
    } else {
      incentive = { ...savingIncentives.find(e => e.minSaving === null) };
    }

    incentive.backgroundUrl = incentive.imagePath ? `${staticContentUrl}${incentive.imagePath}` : null;
    incentive.logoUrl = incentive.logoPath ? `${staticContentUrl}${incentive.logoPath}` : null;
    incentive.description = incentive.description || '';

    if (placeholders) {
      incentive.description = incentive.description
        .replace('{savingAmount}', placeholders.savingAmountDisplay)
        .replace('{programDisplayName}', placeholders.programDisplayName)
        .replace(
          '{customerCommission}',
          placeholders.totalCommission ? placeholders.totalCommission.toLocaleString() : null
        );
    }

    return incentive;
  },

  hasServiceType: (services, serviceType, options) => {
    if (!services || !serviceType || (Array.isArray(serviceType) && services.length === 0)) {
      return false;
    }

    if (!serviceType || (Array.isArray(serviceType) && serviceType.length === 0)) {
      return false;
    }

    const includeExternal = options && options.includeExternal === true;
    const includeAffiliates = options && options.includeAffiliates === true;
    const includePartners = options && options.includePartners === true;

    if (Array.isArray(serviceType)) {
      return services.some(e =>
        serviceType.some(
          s =>
            s.Id === e.id &&
            e.enabled &&
            (includeExternal ||
              (!e.affiliate && !e.partner) ||
              (includeAffiliates && e.affiliate) ||
              (includePartners && e.partner))
        )
      );
    } else {
      return services.some(
        e =>
          e.id === serviceType.Id &&
          e.enabled &&
          (includeExternal ||
            (!e.affiliate && !e.partner) ||
            (includeAffiliates && e.affiliate) ||
            (includePartners && e.partner))
      );
    }
  },

  getOtherServices: (agentBillTypes, serviceType, options) => {
    const services = [];

    const addService = billType => {
      if (billType === serviceType || !billTypes.hasServiceType(agentBillTypes, billType, options)) return;
      services.push(billType);
    };

    addService(billTypes.ELECTRICITY);
    addService(billTypes.GAS);
    addService(billTypes.INTERNET);
    addService(billTypes.HOME_LOAN);

    return services;
  },

  getServices: (agentBillTypes, limitToServiceTypes, options) => {
    const services = [];

    const addService = billType => {
      if (
        (limitToServiceTypes && !limitToServiceTypes.find(e => e === billType)) ||
        !billTypes.hasServiceType(agentBillTypes, billType, options)
      )
        return;
      services.push(billType);
    };

    // Order is important here as some callers (e.g. moving house) expect an ordered set.
    addService(billTypes.ELECTRICITY);
    addService(billTypes.GAS);
    addService(billTypes.INTERNET);
    addService(billTypes.HOME_LOAN);
    addService(billTypes.VECHICLE_INSURANCE);
    addService(billTypes.HOME_AND_CONTENTS_INSURANCE);
    addService(billTypes.HEALTH_INSURANCE);
    addService(billTypes.MOBILE_PHONE);
    addService(billTypes.SOLAR_INSTALLATION);
    addService(billTypes.SOLAR_FINANCE);
    addService(billTypes.BUSINESS_LOAN);

    return services;
  },

  getAgentServices: (agentBillTypes, limitToServiceTypes, options) => {
    const services = [];

    const addService = billType => {
      if (
        (limitToServiceTypes && !limitToServiceTypes.find(e => e === billType)) ||
        !billTypes.hasServiceType(agentBillTypes, billType, options)
      )
        return;
      services.push(getAgentServiceType(billType, agentBillTypes));
    };

    // Order is important here as some callers (e.g. moving house) expect an ordered set.
    addService(billTypes.ELECTRICITY);
    addService(billTypes.GAS);
    addService(billTypes.INTERNET);
    addService(billTypes.HOME_LOAN);
    addService(billTypes.VECHICLE_INSURANCE);
    addService(billTypes.HOME_AND_CONTENTS_INSURANCE);
    addService(billTypes.HEALTH_INSURANCE);
    addService(billTypes.MOBILE_PHONE);
    addService(billTypes.SOLAR_INSTALLATION);
    addService(billTypes.SOLAR_FINANCE);
    addService(billTypes.BUSINESS_LOAN);

    return services;
  },

  getServicesText: (agentBillTypes, limitTo, asLowerCase, serviceTypeOptions) => {
    const array = [];
    const lowerCase = asLowerCase !== false;

    const addService = serviceType => {
      const hasService = billTypes.hasServiceType(agentBillTypes, serviceType, serviceTypeOptions);
      const agentBillType = agentBillTypes.find(e => e.id === serviceType.Id);
      const allowService = serviceType.ComparisonImplemented || (agentBillType && agentBillType.affiliate);

      if (allowService && hasService && (!limitTo || limitTo.includes(serviceType)))
        array.push(lowerCase ? serviceType.Name.toLowerCase() : serviceType.Name);
    };

    addService(billTypes.ELECTRICITY);
    addService(billTypes.GAS);
    addService(billTypes.INTERNET);
    addService(billTypes.HOME_LOAN);
    addService(billTypes.HOME_AND_CONTENTS_INSURANCE);
    addService(billTypes.VECHICLE_INSURANCE);
    addService(billTypes.HEALTH_INSURANCE);
    addService(billTypes.MOBILE_PHONE);
    addService(billTypes.SOLAR_INSTALLATION);
    addService(billTypes.SOLAR_FINANCE);
    addService(billTypes.BUSINESS_LOAN);

    if (array.length === 0) return '';
    if (array.length === 1) return array;

    return `${array.slice(0, -1).join(', ')} and ${array.slice(-1)[0]}`;
  }
};
