import React from 'react';
import { useSelector } from 'react-redux';

import InfoPopover from './infoPopover';
import CustomerCommissionTerms from './customerCommissionTerms';

import { billTypes } from '../../enums/billTypes';
import { buttonTypes } from '../../enums/buttonTypes';

const CustomerCommissionInfo = ({ commissionType, serviceType }) => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const getCommissionServices = () => {
    const serviceTypeOptions = { includeExternal: true };
    const limitTo = serviceType && serviceType !== billTypes.NONE ? [serviceType] : null;
    const agentServices = billTypes.getAgentServices(agentBillTypes, limitTo, serviceTypeOptions);

    const commissionServices =
      agentServices &&
      agentServices.filter(
        e =>
          e.enabled && e.hasCommission && (e.serviceType === billTypes.ELECTRICITY || e.serviceType === billTypes.GAS)
      );

    return commissionServices || [];
  };

  return (
    <InfoPopover
      idType="customer-commission-terms"
      heading="Offer terms"
      body={
        <div className="commission-terms">
          <div className="mb-2">
            {getCommissionServices().map((e, i) => (
              <span key={`commission_${i}`}>
                {`For each eligible ${e.serviceType.Name.toLowerCase()} application, ${e.commissionType.Leadin.toLowerCase()} ${
                  e.commissionType.Denomination
                }${e.agentBillType.customerCommission.toLocaleString()} ${e.commissionType.Name}. `}
              </span>
            ))}
          </div>
          <CustomerCommissionTerms serviceType={serviceType} commissionType={commissionType} />
        </div>
      }
      buttonType={buttonTypes.LINK}
      buttonText="Offer terms"
    />
  );
};

export default CustomerCommissionInfo;
