import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '../../auth/auth0-Provider.js';

import AuthenticationButton from '../authentication/authenticationButton';
import RegistrationControl from '../registration/registrationControl';
import { buttonTypes } from '../../enums/buttonTypes.js';

const LoginDialog = ({ onClose }) => {
  const { organiserAgentName } = useSelector(state => state.content.general);
  const { isUserAuthenticated, loading } = useAuth0();

  const [registrationComplete, setRegistrationComplete] = useState(false);

  const onRegistrationComplete = () => {
    setRegistrationComplete(true);
  };

  return (
    <div className="sm-login-dialog">
      {!loading && !registrationComplete && !isUserAuthenticated() && (
        <>
          <div
            className="heading"
            dangerouslySetInnerHTML={{
              __html: `Login to view your ${organiserAgentName}`
            }}
          />
          <AuthenticationButton buttonType={buttonTypes.BUTTON} showLogout={false} />
        </>
      )}

      {!registrationComplete && <div className="heading">Not yet registered?</div>}
      <RegistrationControl onClose={onClose} onComplete={onRegistrationComplete} />
    </div>
  );
};

export default LoginDialog;
