import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import EmailForwardInfoCard from './emailForwardSummaryCard';

import EmailForwardDialog from '../dialogs/emailForwardDialog';
import TermsAndConditionsDialog from '../dialogs/termsAndConditionsDialog';

const ForwardBill = ({ showDialog, onBackButtonClick }) => {
  const { receiveBillEmailAddress: agentMemberEmailAddress } = useSelector(state => state.selectedAgent);
  const { receiveBillEmailAddress: agentDefaultEmailAddress } = useSelector(state => state.config);
  const { submitBillForwardYourBillStepDescription } = useSelector(state => state.content.general);
  const { programName } = useSelector(state => state.config);

  const receiveBillEmailAddress = agentMemberEmailAddress ? agentMemberEmailAddress : agentDefaultEmailAddress;

  return (
    <>
      <div className="sm-submit-bill-selector-forward">
        <div className="sub-heading">Email us your latest e-bill</div>

        <nobr>
          <ol>
            <li>
              Read the {programName}{' '}
              <TermsAndConditionsDialog style={{ marginTop: -2 }} buttonText="terms and conditions" />.
            </li>
            <li>Locate your latest e-bill in your inbox</li>

            <li>{submitBillForwardYourBillStepDescription}</li>
          </ol>

          <EmailForwardInfoCard receiveBillEmailAddress={receiveBillEmailAddress} />
          <div className="sm-modal-footer">
            <Button onClick={onBackButtonClick} className="sm-button-secondary">
              Go back
            </Button>
          </div>
        </nobr>

        {showDialog && <EmailForwardDialog receiveBillEmailAddress={receiveBillEmailAddress} />}
      </div>
    </>
  );
};

export default ForwardBill;
