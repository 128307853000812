import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import ProviderTermsAndConditions from './providerTermsAndConditions';
import Conditions from './conditions';
import OutboundLink from './outboundLink';
import QsCheckBox from './qsCheckBox';
import SmartMeTermsAndConditions from './smartMeTermsAndConditions';
import { billTypes } from '../../enums/billTypes';
import { conditionTypes } from '../../enums/conditionTypes';
import { submissionTypes } from '../../enums/submissionTypes';
import { orientationTypes } from '../../enums/scaleTypes';

const OfferConfirmTermsAndConditionsAu = ({ confirmDetail, offer, onChange, setConfirmDetail, title }) => {
  const { pricingDocumentName, pricingDocumentNameShort, submissionType } = useSelector(state => state.comparison);
  const { billType } = useSelector(state => state.comparison.submission);

  const serviceType = billTypes.getByCode(billType);

  const [documentsOpen, setDocumentsOpen] = useState(
    submissionType === submissionTypes.BILL || serviceType !== billTypes.ELECTRICITY
  );

  const displayTitle = title ? title : 'Terms and conditions';

  const hasPricingDocuments = () => {
    switch (submissionType) {
      case submissionTypes.BILL:
        return offer.pricingDocuments && offer.pricingDocuments.length > 0;

      case submissionTypes.ESTIMATE:
        return offer.allRates && offer.allRates.filter(e => e.pricingDocument).length > 0;

      default:
        return false;
    }
  };

  const getPricingDocumentText = () => {
    switch (submissionType) {
      case submissionTypes.BILL:
        return null;

      case submissionTypes.ESTIMATE:
        if (serviceType === billTypes.ELECTRICITY) {
          return (
            <div className="sm-pricing-documents-intro">
              The rates for your address are dependent on your distribution area and the meter type. The{' '}
              {pricingDocumentNameShort} listed below are for all meter types that may apply to you. A current
              electricity bill for that property will show the meter/tariff type.
              <span className="sm-pricing-documents-toggle" onClick={() => setDocumentsOpen(!documentsOpen)}>
                {documentsOpen ? 'Hide documents' : 'Show documents'}
              </span>
            </div>
          );
        }
        break;

      default:
        return null;
    }
  };

  const getPricingDocuments = () => {
    switch (submissionType) {
      case submissionTypes.BILL:
        return offer.pricingDocuments.map((pricingDocument, i) => (
          <div key={i}>
            <OutboundLink
              eventLabel={`pricing-document-${pricingDocument.name}`}
              to={pricingDocument.linkUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {pricingDocument.name}
            </OutboundLink>
          </div>
        ));

      case submissionTypes.ESTIMATE:
        return offer.allRates
          .filter(e => e.pricingDocument)
          .map((e, i) => (
            <div key={i}>
              <OutboundLink
                eventLabel={`pricing-document-${e.pricingDocument.name}`}
                to={e.pricingDocument.linkUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {e.pricingDocument.name}
              </OutboundLink>
            </div>
          ));

      default:
        return null;
    }
  };

  return (
    <>
      <ProviderTermsAndConditions offer={offer} title={displayTitle} />

      {!offer.isBestRetailerMarketOffer && offer.rankingDisclaimer && (
        <div className="qs-not-best-retailer-market-offer mt-3">{offer.rankingDisclaimer}</div>
      )}

      {hasPricingDocuments() && (
        <div className="mt-3">
          <div className="qs-more-info-heading">{pricingDocumentName}</div>

          {getPricingDocumentText()}

          <Collapse isOpen={documentsOpen}>
            <div className="qs-agree-terms-text sm-pricing-documents">{getPricingDocuments()}</div>
          </Collapse>
        </div>
      )}

      {offer.productDetail.requireMultipleSclerosis && (
        <div className="mt-3 mb-2">
          <QsCheckBox
            isChecked={confirmDetail.multipleSclerosis}
            onCheck={e => onChange(e, { multipleSclerosis: !confirmDetail.multipleSclerosis })}
            label="Does anyone living at this property have multiple sclerosis?"
          />
        </div>
      )}

      {(offer.medicalCooling || offer.productDetail.requireMedicalCooling) && (
        <div className="mt-3 mb-2">
          <QsCheckBox
            isChecked={confirmDetail.medicalCooling}
            onCheck={e => onChange(e, { medicalCooling: !confirmDetail.medicalCooling })}
            label="Does anyone living at this property require medical cooling?"
          />
        </div>
      )}

      <Conditions
        offer={offer}
        confirmDetail={confirmDetail}
        setConfirmDetail={setConfirmDetail}
        type={conditionTypes.GENERAL}
        orientation={orientationTypes.left}
      />

      <SmartMeTermsAndConditions
        onChange={onChange}
        offer={offer}
        termsAndConditions={confirmDetail.termsAndConditions}
        serviceType={serviceType}
      />
    </>
  );
};

export default OfferConfirmTermsAndConditionsAu;
