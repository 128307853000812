import React from 'react';

// Note: these are written to work with react-helmet. It may look like these can be done as function components but that does not work within a <Helmet> tag

let getFacebookPixelScript = (isLoaded, facebookPixelId) => {
  var result =
    isLoaded && facebookPixelId ? (
      <script>
        {`        
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${facebookPixelId}'); 
            fbq('track', 'PageView');                                      
          `}
      </script>
    ) : null;

  return result;
};

let getFacebookPixelNoScript = (isLoaded, facebookPixelId) => {
  var result =
    isLoaded && facebookPixelId ? (
      <noscript>
        {`<img height="1" width="1" 
            src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView
            &noscript=1"/>`}
      </noscript>
    ) : null;

  return result;
};

let facebookPageChanged = (facebookPixelId, window, path) => {
  if (facebookPixelId && window.fbq) {
    if (path)
      window.fbq('track', 'PageView', {
        page: path
      });
    else window.fbq('track', 'PageView');
  }
};

export { getFacebookPixelScript, getFacebookPixelNoScript, facebookPageChanged };
