import React from 'react';
import { AvField } from 'availity-reactstrap-validation';
import { useSelector } from 'react-redux';
import { Row, Col, FormGroup } from 'reactstrap';
import QsCheckBox from './qsCheckBox';
import QsButtonGroup from './qsButtonGroup';
import AddressControl from './addressControl';
import { isMobile } from 'react-device-detect';

const Premise = props => {
  const {
    onChange,
    showPremiseType,
    showPrimaryResidence,
    showResidencePeriod,
    details: { premiseType, primaryResidence, previousAddressRequired }
  } = props;

  const { premiseTypes } = useSelector(state => state.config.referenceData);
  const { country } = useSelector(state => state.config);

  const onChangePreviousAddressRequired = event => {
    let option = event.target.getAttribute('data-value');
    onChange(null, { previousAddressRequired: option });
  };

  return (
    <>
      <div className="qs-more-info-table-header label">Premise</div>
      <Row>
        {showPrimaryResidence && (
          <Col xs="12">
            <div className="mb-3">
              <QsCheckBox
                isChecked={primaryResidence}
                onCheck={e => onChange(e, { primaryResidence: !primaryResidence })}
                label="Is this your primary residence?"
              />
            </div>
          </Col>
        )}

        {showPremiseType && (
          <Col xs="12" md="6">
            <FormGroup>
              <AvField
                bsSize="sm"
                type="select"
                name="premiseType"
                onChange={e => onChange(e, { premiseType: e.target.value })}
                value={premiseType}
                validate={{
                  required: { value: true, errorMessage: 'Select own or renting' }
                }}
              >
                <option value="">Own or renting?</option>
                {premiseTypes.map((ref, i) => (
                  <option key={`premisetype-${ref.id}`} value={ref.id}>
                    {ref.description}
                  </option>
                ))}
              </AvField>
            </FormGroup>
          </Col>
        )}

        {showResidencePeriod && (
          <>
            <Col xs="12">
              <FormGroup>
                <div className="qs-more-info-table-header qs-mt-md">
                  Have you lived at the current property for more than 12 months?
                </div>
                <QsButtonGroup
                  id="sorter"
                  fieldName="Sort"
                  selectedValue={previousAddressRequired + ''}
                  onTouchButton={onChangePreviousAddressRequired}
                  additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                  buttons={[
                    {
                      label: 'Yes',
                      value: 'false'
                    },
                    {
                      label: 'No',
                      value: 'true'
                    }
                  ]}
                />
              </FormGroup>
            </Col>

            {previousAddressRequired === 'true' && (
              <Col xs="12">
                <div className="sm-offer-previous-address">
                  <AddressControl
                    key="previousAddress"
                    keySuffix="previous"
                    labelText="Please enter your last residential address"
                    country={country}
                    onAddressSelected={address => onChange(null, { previousAddress: address })}
                    autoPlaceholder="Property address..."
                    autoPropertyAddress={null}
                    autoAddressRequired={true}
                    manualAddressVisible={true}
                    manualSuburbSelectorEnabled={true}
                    showAreasSupportedDisclaimer={false}
                    large={!isMobile}
                    showMinimalManualFields={false}
                  />
                </div>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default Premise;
