import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import Slider from '../common/slider';

import ServiceIcon from '../serviceCard/serviceIcon';
import { billTypes } from '../../enums/billTypes';
import { submitEstimateHomeLoanDefault } from '../../actions/energyEstimateActions';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getSessionActivity } from '../../utilities/commonUtilities';

const WizardHomeLoan = ({ onClose, data }) => {
  const [selectedLoanAmount, setSelectedLoanAmount] = useState(480000);
  const [selectedMonthlyRepayment, setSelectedMonthlyRepayment] = useState(1000);

  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { session } = useSelector(state => state);

  const sessionActivity = getSessionActivity(session);

  const dispatch = useDispatch();

  // --------------------------------------------------------------------------
  // Next button click handler.
  // --------------------------------------------------------------------------
  const onNextButtonClick = () => {
    dispatch(
      submitEstimateHomeLoanDefault(
        data.propertyAddress,
        data.selectedAgent,
        sessionActivity,
        {
          emailAddress: data.emailAddress,
          agentMemberName: data.agentMemberName,
          termsAndConditions: data.termsAndConditions,
          sendComparisonEmail: data.sendComparisonEmail,
          sendArticlesEmail: data.sendArticlesEmail,
          sendSolarInstallationEmail: data.sendSolarInstallationEmail
        },
        selectedLoanAmount,
        selectedMonthlyRepayment,
        { showSpinner: true }
      )
    );

    standardEvents('Setup-Wizard-Dialog', 'Home-Loan', 'Submit', googleAnalyticsClientCode);
    standardEvents('Setup-Wizard-Dialog', 'Finished', 'Click', googleAnalyticsClientCode);

    onClose(true);
  };

  const rowClass = 'sm-dialog-row';
  const fieldClass = 'sm-dialog-field-name';
  const loanAmountLabel = 'Current Loan Balance';
  const monthlyRepaymentsLabel = 'Current Monthly Repayments';

  return (
    <>
      <div className="start-heading mb-2">
        <div className="service-icons">
          <ServiceIcon serviceType={billTypes.HOME_LOAN} />
        </div>
        <div className="heading-text">{billTypes.HOME_LOAN.Name}</div>
      </div>
      <div className="intro-text">
        <div>Great, now confirm your current home loan balance and repayments.</div> We'll compare those against our
        best deals for your address.
      </div>

      <AvForm onValidSubmit={onNextButtonClick}>
        {/* --- LOAN AMOUNT ------------------------------------------------------- */}
        <div className={rowClass}>
          <div className={`${fieldClass} slider`}>{loanAmountLabel}</div>
          <div>
            <Slider
              required
              allowModeChange
              fieldName={loanAmountLabel}
              value={selectedLoanAmount}
              onFinalChange={value => setSelectedLoanAmount(value)}
              min={50000}
              max={5000000}
              step={25000}
              labelPrefix="$"
              maxValueSuffix="+"
            />
          </div>
        </div>

        {/* --- MONTHLY REPAYMENTS ------------------------------------------------ */}
        <div className={rowClass}>
          <div className={`${fieldClass} slider`}>{monthlyRepaymentsLabel}</div>
          <div>
            <Slider
              required
              allowModeChange
              fieldName={monthlyRepaymentsLabel}
              value={selectedMonthlyRepayment}
              onFinalChange={value => setSelectedMonthlyRepayment(value)}
              min={1}
              max={10000}
              step={100}
              labelPrefix="$"
              maxValueSuffix="+"
            />
          </div>
        </div>

        <div className="wizard-footer">
          <div className="dialog-button">
            <Button className="sm-button-secondary medium">Next</Button>
          </div>
        </div>
      </AvForm>
    </>
  );
};

export default WizardHomeLoan;
