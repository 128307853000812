import React from 'react';

const UploadBillAcceptedIcons = () => {
  const getIcon = (icon, label, accepted) => {
    return (
      <div className={`content ${accepted ? '' : 'not-'}accepted`}>
        <div className="icon-wrapper">
          <span className="material-icons accepted-icon">{icon}</span>
        </div>
        <div className="icon-label">{label}</div>
      </div>
    );
  };

  return (
    <div className="sm-bill-accepted-icons">
      {getIcon('picture_as_pdf', '.pdf e-bills only', true)}
      {getIcon('photo_camera', 'No photos', false)}
      {getIcon('scanner', 'No scans', false)}
    </div>
  );
};

export default UploadBillAcceptedIcons;
