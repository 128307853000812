import React, { useState, useEffect, useCallback } from 'react';
import _debounce from 'lodash.debounce';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '../../auth/auth0-Provider';
import {
  submitEstimateWithToken,
  submitEstimateWithKey,
  submitEstimateUnathenticated
} from '../../actions/energyEstimateActions';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import QsButtonGroup from '../common/qsButtonGroup';
import { Row, Col, Button } from 'reactstrap';
import AddressControl from '../common/addressControl';
import SubmitCommon from '../common/submitCommon';
import EnergyDistributor from '../common/energyDistributor';
import ExitLaunch from '../common/exitLaunch';
import ServicesAuto from '../serviceCard/servicesAuto';

import { operatorModes } from '../../enums/launchTypes';
import { occupancyTypes, occupancyPurposes, usageTypes, submissionTypes } from '../../enums/submissionTypes';
import { uploadBillTypes, uploadSubTypes } from '../../enums/uploadBillTypes';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import {
  setServerMessage,
  setServerMessageClear,
  displayNoOffersMessage,
  displayErrorMessage,
  comparisonErrorMessage
} from '../../actions/serverMessageActions';
import { setUser } from '../../actions/userActions';
import ServerMessage from '../common/serverMessage';

import 'react-google-places-autocomplete/dist/index.min.css';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getAddress } from '../../utilities/addressUtilities';
import { submitAutoEstimates } from '../serviceCard/serviceCardUtilities';
import { getSessionActivity } from '../../utilities/commonUtilities';
import { addSessionActivity } from '../../actions/sessionActivityActions';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes';
import { getDistributorDefaults, getDistributorItem, getRegionDistributors } from '../../actions/distributorActions';

const EnergyEstimateUs = props => {
  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { accessKey: comparisonAccessKey, accessId: comparisonAccessId, isLoaded: comparisonLoaded } = useSelector(
    state => state.comparison
  );
  const { accessKey: userAccessKey, accessId: userAccessId } = useSelector(state => state.user);
  const { serverMessage, selectedAgent, session } = useSelector(state => state);
  const { country, hasAgents, billTypes: agentBillTypes, defaultOccupancyPurpose } = useSelector(state => state.config);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { data: userBills } = useSelector(state => state.userBills);
  const { operatorMode } = useSelector(state => state.landing.home);

  const sessionActivity = getSessionActivity(session);

  const {
    serviceType,
    launchMode,
    closeButtonText,
    confirmButtonText,
    showBackButton,
    showCancelButton,
    showCloseButton,
    userInitiated,
    submissionId,
    submission,
    onBackButtonClick,
    onUploadBillTypeSelect,
    onCancel,
    onClose,
    onSuccess,
    isVerify
  } = props;

  const {
    billType,
    occupancyType = occupancyTypes.CURRENT.Id,
    occupancyPurpose = occupancyPurposes.RESIDENTIAL.Id,
    distributor,
    propertyAddress,
    recentBill,
    peakUsage,
    usageLevel,
    meterIdentifier,
    defaultComparison
  } = submission;

  const getInitialDistributorCode = () => {
    return distributor && !submission.defaultComparison ? distributor.selectionCode : null;
  };

  const getOccupancyPurpose = () => {
    return occupancyPurpose ? occupancyPurpose : defaultOccupancyPurpose;
  };

  const { ESTIMATE_DIALOG: target } = serverMessageTargets;

  const initialAddress = getAddress(propertyAddress);

  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(true);
  const [distributorSets, setDistributorSets] = useState({});
  const [distributorSetsLoaded, setDistributorSetsLoaded] = useState(false);
  const [selectedDistributorPrimary, setSelectedDistributorPrimary] = useState();
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedOccupancyType, setSelectedOccupancyType] = useState(occupancyType);
  const [selectedOccupancyPurpose, setSelectedOccupancyPurpose] = useState(occupancyPurpose);

  const initialUsageType = usageLevel ? usageLevel : usageTypes.MEDIUM.Id;
  const [selectedUsageType, setSelectedUsageType] = useState(initialUsageType);

  const [hasChanges, setHasChanges] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submissionCommon, setSubmissionCommon] = useState({});
  const [submissionCommonRefresh, setSubmissionCommonRefresh] = useState();
  const [initialComparisonEmailValue, setInitialComparisonEmailValue] = useState();
  const [initialEmailAddressValue, setInitialEmailAddressValue] = useState(null);
  const [selectedRecentBill, setSelectedRecentBill] = useState(recentBill === true ? true : false);

  const initialPeakUsage = peakUsage ? peakUsage : 0;
  const [selectedPeakUsage, setSelectedPeakUsage] = useState(initialPeakUsage);

  const showAgents = !selectedAgent.isLoaded && hasAgents;

  // --------------------------------------------------------------------------
  // Submit new estimate request.
  // --------------------------------------------------------------------------
  const onSubmitEstimate = () => {
    const estimate = {
      property: {
        address: { ...selectedAddress },
        occupancyType: selectedOccupancyType,
        occupancyPurpose: selectedOccupancyPurpose
      },
      ...submissionCommon,
      sessionActivity,
      agentMemberExternalId: selectedAgent.code,
      distributorCode: selectedDistributorPrimary ? selectedDistributorPrimary.selectionCode : null,
      serviceType: billType,
      usageLevel: selectedUsageType,
      peakUsage: selectedPeakUsage,
      recentBill: selectedRecentBill,
      meterIdentifier
    };

    // If the user checked the comparison email checkbox from an initial state of false (i.e. they want an email for this comparison),
    // then fire an API call to initiate the email send (even if nothing else on the form changed; i.e. hasChanges = false)
    const sendComparisonEmailInitiated = !initialComparisonEmailValue && submissionCommon.sendComparisonEmail;

    // If a the user is on the compare page (i.e. comparison is loaded) but the access details are missing, they were cleared because of an error with the last estimate submit.
    // so we need to force an API call (even if nothing else on the form changed; i.e. hasChanges = false)
    const comparisonError = comparisonLoaded && (!comparisonAccessId || !comparisonAccessKey);

    // Check if anything has changed before making a re-estimate request.
    // User initiated considered (i.e. hasChanges = false), as user may have triggered the dialog and accepted a complete set of defaults (if possible)
    if (hasChanges || userInitiated || defaultComparison || sendComparisonEmailInitiated || comparisonError) {
      const userBill = userBills.find(e => e.submissionId === submissionId);
      const userBillAccessKey = userBill && userBill.accessKey ? userBill.accessKey.accessKey : null;
      const userBillAccessId = userBill && userBill.accessKey ? userBill.accessKey.accessId : null;

      const accessKey = userBillAccessKey || comparisonAccessKey || userAccessKey;
      const accessId = userBillAccessId || comparisonAccessId || userAccessId;

      if (accessKey && accessId) {
        onSubmitWithKey(serviceType, { estimate, access: { key: accessKey, id: accessId } });
      } else if (isUserAuthenticated()) {
        onSubmitWithToken(serviceType, { estimate });
      } else {
        onSubmitUnathenticated(serviceType, { estimate });
      }

      standardEvents('energy-estimate-dialog', 'Click', 'Confirm', googleAnalyticsClientCode);
    } else {
      onSuccess({
        hasOffers: true,
        estimateWasRun: false,
        userBill: {}
      });
    }
  };

  const dispatchAutoEstimates = () => {
    dispatch(
      submitAutoEstimates(
        serviceType,
        selectedAddress,
        selectedAgent,
        sessionActivity,
        submissionCommon,
        agentBillTypes,
        userBills,
        googleAnalyticsClientCode
      )
    );
  };

  const onSubmitWithToken = useCallback(
    _debounce(
      (serviceType, data) => {
        const options = { showSpinner: true, applySleep: true };
        const fn = async () => {
          const accessToken = await getAccessToken();
          dispatch(submitEstimateWithToken(accessToken, serviceType, data, options, onComplete, onError));
        };
        fn();
      },
      2000,
      { leading: true }
    ),
    []
  );

  const onSubmitWithKey = useCallback(
    _debounce(
      (serviceType, data) => {
        const options = { showSpinner: true, applySleep: true };
        dispatch(submitEstimateWithKey(serviceType, data, options, onComplete, onError));
      },
      2000,
      { leading: true }
    ),
    []
  );

  const onSubmitUnathenticated = useCallback(
    _debounce(
      (serviceType, data) => {
        const options = { showSpinner: true, applySleep: true };

        dispatch(submitEstimateUnathenticated(serviceType, data, options, onComplete, onError));
      },
      2000,
      { leading: true }
    ),
    []
  );

  // --------------------------------------------------------------------------
  // Estimate comparison error handler.
  // --------------------------------------------------------------------------
  const onError = error => {
    dispatch(comparisonErrorMessage(error, target));
  };

  // --------------------------------------------------------------------------
  // Initialize the form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    initialize();
  }, []);

  // --------------------------------------------------------------------------
  // Invalid form submit handler.
  // --------------------------------------------------------------------------
  const handleInvalidSubmit = (event, errors, values) => {
    setHasSubmitted(true);
  };

  // --------------------------------------------------------------------------
  // Submission common handlers.
  // --------------------------------------------------------------------------
  const onSubmissionCommonLoad = value => {
    if (value) {
      setInitialComparisonEmailValue(value.sendComparisonEmail);
      setInitialEmailAddressValue(value.emailAddress);
      setSubmissionCommon(value);
    }
  };

  const onSubmissionCommonChange = value => {
    setSubmissionCommon(value);
  };

  // --------------------------------------------------------------------------
  // Detect changes in form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    const selectedFullAddress = getAddress(selectedAddress).fullAddress;
    const propertyFullAddress = getAddress(propertyAddress).fullAddress;

    let changed =
      selectedFullAddress !== propertyFullAddress ||
      occupancyType !== selectedOccupancyType ||
      initialUsageType !== selectedUsageType ||
      occupancyPurpose !== selectedOccupancyPurpose ||
      recentBill !== selectedRecentBill ||
      initialPeakUsage !== selectedPeakUsage ||
      (initialEmailAddressValue ? initialEmailAddressValue : null) !==
        (submissionCommon.emailAddress ? submissionCommon.emailAddress : null);

    if (defaultComparison) {
      const distributorItem = getDistributorItem(distributorSets, serviceType);

      if (selectedDistributorPrimary && distributorItem.length > 1) {
        changed = true;
      }
    } else {
      if (
        propertyFullAddress !== undefined &&
        (distributor ? distributor.selectionCode : null) !==
          (selectedDistributorPrimary ? selectedDistributorPrimary.selectionCode : null)
      ) {
        changed = true;
      }
    }

    setHasChanges(changed);
  }, [
    selectedUsageType,
    selectedOccupancyType,
    selectedOccupancyPurpose,
    selectedDistributorPrimary,
    selectedAddress,
    selectedRecentBill,
    selectedPeakUsage,
    submissionCommon.emailAddress
  ]);

  // --------------------------------------------------------------------------
  // Initialize or reset the form.
  // --------------------------------------------------------------------------
  const initialize = reset => {
    dispatch(setServerMessageClear(target, serverMessage));

    setSelectedAddress(initialAddress);
    applyDistributors(initialAddress, getInitialDistributorCode());

    if (reset) {
      dispatch(setUser({ emailAddress: initialEmailAddressValue }));
      setSubmissionCommonRefresh(Date.now());
    }

    setSelectedUsageType(initialUsageType);
    setSelectedOccupancyPurpose(getOccupancyPurpose());
    setSelectedOccupancyType(occupancyType);

    setSelectedRecentBill(recentBill);

    setSelectedPeakUsage(initialPeakUsage);

    setHasSubmitted(false);
    setHasChanges(false);
  };

  // --------------------------------------------------------------------------
  // Address selected handler.
  // --------------------------------------------------------------------------
  const onAddressSelected = address => {
    setSelectedAddress(address);

    dispatch(setServerMessageClear(target, serverMessage));

    applyDistributors(address, selectedDistributorPrimary ? selectedDistributorPrimary.selectionCode : null);
  };

  // --------------------------------------------------------------------------
  // Distributors: Apply region distributors
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (distributorSetsLoaded) applyDistributorDefaults(getInitialDistributorCode(), distributorSets);
  }, [distributorSetsLoaded, serviceType]);

  const applyDistributorDefaults = (distributorCode, items) => {
    const result = getDistributorDefaults(distributorCode, items, agentBillTypes, serviceType, false);

    if (!result.success) {
      dispatch(
        displayErrorMessage(
          target,
          `Unfortunately we currently do not provide ${serviceType.Name.toLowerCase()} plans for this address.`,
          true
        )
      );
    }

    setConfirmButtonEnabled(result.success);
    setSelectedDistributorPrimary(result.primaryDistributor);
  };

  const applyDistributors = address => {
    dispatch(setServerMessageClear(target, serverMessage));

    const onComplete = result => {
      setDistributorSets(result.items);
      setDistributorSetsLoaded(result.success);

      if (result.success) {
        applyDistributorDefaults(getInitialDistributorCode(), result.items);
      } else {
        setSelectedDistributorPrimary(null);
      }
    };

    const onError = error => {
      dispatch(
        setServerMessage({
          ...error.response.data,
          scrollTo: true,
          target,
          code: error.response.status
        })
      );
    };

    dispatch(getRegionDistributors(address, agentBillTypes, false, onComplete, onError));
  };

  const removeRecentBillData = () => {
    setSelectedRecentBill(false);
    setSelectedPeakUsage(0);
    setSelectedUsageType(usageTypes.MEDIUM.Id);
  };

  const setOccupancyTypeButtonGroup = event => {
    let option = event.target.getAttribute('data-value');
    setSelectedOccupancyType(parseInt(option));

    // Remove the bill data if they are moving in
    if (selectedOccupancyType === occupancyTypes.NEW.Id) {
      removeRecentBillData();
    }

    standardEvents('estimate-dialog', 'occupancy-type', option === '1' ? 'New' : 'Existing', googleAnalyticsClientCode);
  };

  const setUsageTypeButtonGroup = event => {
    let option = event.target.getAttribute('data-value');
    setSelectedUsageType(parseInt(option));

    standardEvents(
      'estimate-dialog',
      'usage-type',
      option === '1' ? 'Apartment' : option === '2' ? 'Small Home' : 'Large Home',
      googleAnalyticsClientCode
    );
  };

  const setOccupancyPurposeButtonGroup = event => {
    let option = event.target.getAttribute('data-value');
    setSelectedOccupancyPurpose(parseInt(option));

    standardEvents(
      'estimate-dialog',
      'occupancy-purpose',
      option === '1' ? 'Residential' : 'Business',
      googleAnalyticsClientCode
    );
  };

  // --------------------------------------------------------------------------
  // Recent bill change handler.
  // --------------------------------------------------------------------------
  const onRecentBillChange = event => {
    let option = event.target.getAttribute('data-value');
    let optionValue = option === 'true';
    setSelectedRecentBill(optionValue);

    if (optionValue) setSelectedPeakUsage(initialPeakUsage);
    else removeRecentBillData();

    standardEvents('estimate-dialog', 'recent-bill', optionValue ? 'Yes' : 'No', googleAnalyticsClientCode);
  };

  // --------------------------------------------------------------------------
  // Add session activity.
  // --------------------------------------------------------------------------
  const recordSessionActivity = (comparisonResult, userBill) => {
    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          submissionType: submissionTypes.ESTIMATE,
          submissionId: userBill.submissionId,
          serviceType: serviceType.Code,
          hasOffers: comparisonResult.hasOffers
        },
        activityType: sessionActivityTypes.COMPARISON_COMPLETED
      })
    );
  };

  // --------------------------------------------------------------------------
  // Estimate comparison complete handler.
  // --------------------------------------------------------------------------
  const onComplete = (comparisonResult, userBill) => {
    recordSessionActivity(comparisonResult, userBill);

    if (comparisonResult.hasOffers) {
      onSuccess({
        hasOffers: comparisonResult.hasOffers,
        estimateWasRun: true,
        userBill
      });
    } else {
      setHasChanges(false);
      dispatch(displayNoOffersMessage(target));
    }

    dispatchAutoEstimates();
  };

  const rowClass = 'sm-dialog-row';
  const fieldClass = 'sm-dialog-field-name';
  const columnSizeLg = 12;

  // --------------------------------------------------------------------------
  // Form reference.
  // --------------------------------------------------------------------------
  const formRef = ref => {
    if (ref) {
      // Pre-validate occupancy purpose so when user selects 'Business' they see the error.
      let inputs = {
        occupancyPurpose_validation: ref._inputs['occupancyPurpose_validation']
      };

      if (isVerify) {
        inputs.peakUsage_validation = ref._inputs['peakUsage_validation'];
        inputs.distributor_validation = ref._inputs[`distributor_validation_${serviceType.Code}`];
        inputs.address_validation = ref._inputs['address_validation'];
      }

      if (submission && submission.defaultComparison) {
        inputs.distributor_validation = ref._inputs[`distributor_validation_${serviceType.Code}`];
      }

      ref.setTouched(Object.keys(inputs));
    }
  };

  return (
    <>
      <AvForm onValidSubmit={event => onSubmitEstimate(event)} onInvalidSubmit={handleInvalidSubmit} ref={formRef}>
        <Row>
          <Col>
            <ServerMessage serverMessage={serverMessage} target={target} />
          </Col>
        </Row>
        {/* --- YOUR SUBURB ------------------------------------------------------- */}
        <Row className={`${rowClass} mb-2`}>
          <Col>
            <AddressControl
              key="energyEstimateAddress"
              keySuffix="energy"
              labelText="Your address"
              country={country}
              onAddressSelected={onAddressSelected}
              autoPlaceholder={selectedAddress.fullAddress || 'Property address...'}
              autoPropertyAddress={selectedAddress}
              autoAddressRequired={true}
              manualAddressVisible={true}
              manualSuburbSelectorEnabled={true}
              showAreasSupportedDisclaimer={true}
              showMinimalManualFields={false}
            />
          </Col>
        </Row>

        {/* --- PRIMARY SERVICE DISTRIBUTOR --------------------------------------- */}
        <EnergyDistributor
          key="primaryServiceDistributor"
          showLabel={true}
          serviceType={serviceType}
          distributorSets={distributorSets}
          onSelectedDistributorChanged={item => setSelectedDistributorPrimary(item)}
          distributor={selectedDistributorPrimary}
          occupancyType={selectedOccupancyType}
        />
        {/* --- ARE YOU MOVING ? -------------------------------------------------- */}
        <Row className={rowClass}>
          <Col className={fieldClass} style={{ marginTop: '5px' }} lg={columnSizeLg}>
            Is this a new connection / move-in?
          </Col>
          <Col xs="12">
            <QsButtonGroup
              required={true}
              id="occupancyType"
              fieldName="Occupancy Type"
              additionalClass="sm-button-group"
              selectedValue={selectedOccupancyType.toString()}
              onTouchButton={setOccupancyTypeButtonGroup}
              buttons={[
                {
                  label: 'No',
                  value: occupancyTypes.CURRENT.Id.toString()
                },
                {
                  label: 'Yes',
                  value: occupancyTypes.NEW.Id.toString()
                }
              ]}
            />
          </Col>
        </Row>

        {/* --- RECENT BILL ? ----------------------------------------------------- */}
        {operatorMode === operatorModes.NONE && selectedOccupancyType === occupancyTypes.CURRENT.Id && (
          <Row className={rowClass}>
            <Col className={fieldClass} lg={columnSizeLg}>
              Got a paper bill?
            </Col>
            <Col xs="12" md="6">
              <QsButtonGroup
                required={true}
                id="recentBill"
                fieldName="Recent Bill"
                additionalClass="sm-button-group"
                selectedValue={selectedRecentBill === true ? 'true' : 'false'}
                onTouchButton={e => onRecentBillChange(e)}
                buttons={[
                  {
                    label: 'No',
                    value: 'false'
                  },
                  {
                    label: 'Yes',
                    value: 'true'
                  }
                ]}
              />
            </Col>
            <Col xs="12" md="6" className="sm-estimate-upload-button">
              <Button
                disabled={!serviceType.ComparisonImplemented}
                className={`sm-button-primary medium right-arrow-small`}
                onClick={() => onUploadBillTypeSelect(uploadBillTypes.WEBSITE, uploadSubTypes.ESTIMATE_UPLOAD)}
              >
                {uploadBillTypes.WEBSITE.ButtonText}
              </Button>
            </Col>
          </Row>
        )}
        {selectedOccupancyType.toString() === occupancyTypes.CURRENT.Id.toString() && selectedRecentBill && (
          <>
            {/* --- Usage ------------------------------------------------------- */}
            <Row className={rowClass}>
              <Col className={fieldClass} lg={columnSizeLg}>
                Usage ({serviceType.Unit})
              </Col>

              <Col xs="4">
                <AvField
                  bsSize="sm"
                  type="text"
                  name="peakUsage"
                  placeholder={serviceType.Unit}
                  value={!selectedPeakUsage || selectedPeakUsage === 0 ? '' : selectedPeakUsage.toString()}
                  onChange={e =>
                    setSelectedPeakUsage(
                      !isNaN(e.target.value) && e.target.value !== '' ? parseFloat(e.target.value) : 0
                    )
                  }
                  validate={{
                    pattern: {
                      value: '^-?[0-9]{0,5}(\\.[0-9]{1,4})?$',
                      errorMessage: `Please enter a valid ${serviceType.Unit} usage total from your bill`
                    },
                    required: {
                      value: true,
                      errorMessage: `Please enter the ${serviceType.Unit} usage total from your bill`
                    }
                  }}
                />
              </Col>
            </Row>
          </>
        )}

        {!selectedRecentBill && (
          <Row className="qs-estimate-dialog-row">
            <Col className={fieldClass} lg={columnSizeLg}>
              Home size
            </Col>
            <Col xs="12">
              <QsButtonGroup
                required={true}
                id="usageType"
                fieldName="Usage"
                additionalClass="sm-button-group"
                selectedValue={selectedUsageType.toString()}
                onTouchButton={setUsageTypeButtonGroup}
                buttons={[
                  {
                    label: 'Apartment',
                    value: usageTypes.LOW.Id.toString()
                  },
                  {
                    label: 'Small Home',
                    value: usageTypes.MEDIUM.Id.toString()
                  },
                  {
                    label: 'Large Home',
                    value: usageTypes.HIGH.Id.toString()
                  }
                ]}
              />
            </Col>
          </Row>
        )}

        {/* --- PROPERTY TYPE ----------------------------------------------------- */}
        <Row className={rowClass}>
          <Col className={fieldClass} lg={columnSizeLg}>
            Property type
          </Col>
          <Col xs="12">
            <QsButtonGroup
              required
              id="occupancyPurpose"
              fieldName="Occupancy Purpose"
              additionalClass="sm-button-group"
              selectedValue={selectedOccupancyPurpose.toString()}
              onTouchButton={setOccupancyPurposeButtonGroup}
              buttons={[
                {
                  label: 'Residential',
                  value: occupancyPurposes.RESIDENTIAL.Id.toString(),
                  default: true
                },
                {
                  label: 'Business',
                  value: occupancyPurposes.BUSINESS.Id.toString(),
                  default: false
                }
              ]}
            />
            <div className="qs-estimate-dialog-validation">
              <AvField
                type="text"
                name={`occupancyPurpose_validation`}
                value={selectedOccupancyPurpose.toString()}
                className="qs-tc-hidden"
                validate={{
                  hidden: (value, ctx, input, cb) => {
                    if (selectedOccupancyPurpose === occupancyPurposes.BUSINESS.Id) {
                      cb("Sorry, we currently don't support business comparisons");
                    } else cb(true);
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        {/* --- EMAIL ADDRESS / AGENT --------------------------------------------- */}
        <Row className={rowClass}>
          <Col className={fieldClass} lg={columnSizeLg}>
            Email address {showAgents && '/ Agent'}
          </Col>
          <Col xs="12">
            <SubmitCommon
              hasSubmitted={hasSubmitted}
              onLoad={onSubmissionCommonLoad}
              onChange={onSubmissionCommonChange}
              refresh={submissionCommonRefresh}
              showSendComparisonEmail={true}
              showSendArticlesEmail={true}
              showSendSolarInstallationEmail={false}
              serviceType={serviceType}
            >
              <ServicesAuto selectedAddress={selectedAddress} serviceType={serviceType} />
            </SubmitCommon>
          </Col>
        </Row>
        <div className="sm-modal-footer">
          {hasChanges && (
            <Button onClick={() => initialize(true)} className="sm-button-secondary">
              Reset
            </Button>
          )}
          {showBackButton && (
            <Button onClick={onBackButtonClick} className="sm-button-secondary">
              Go back
            </Button>
          )}
          {showCancelButton && (
            <Button onClick={onCancel} className=" sm-button-secondary">
              Cancel
            </Button>
          )}
          {showCloseButton && (
            <Button onClick={onClose} className="sm-button-secondary">
              {closeButtonText}
            </Button>
          )}
          <Button className="sm-button-primary right-arrow-small wide" disabled={!confirmButtonEnabled}>
            {operatorMode === operatorModes.DEFAULT ? 'Send Email' : confirmButtonText}
          </Button>

          <ExitLaunch launchMode={launchMode} serviceType={serviceType} source="energy-estimate-dialog" />
        </div>
      </AvForm>
    </>
  );
};

export default EnergyEstimateUs;
