export const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
    return { success: true };
  } catch (e) {
    let reason = '';

    if (e instanceof DOMException) {
      if (e.name === 'QuotaExceededError') {
        reason = 'Quota exceeded';
      } else if (e.name === 'SecurityError') {
        reason = 'Local storage is not allowed';
      } else {
        reason = 'Unknown error';
      }
    } else {
      reason = 'Non-DOMException error';
    }

    return { success: false, reason };
  }
};

export const getLocalStorage = key => {
  try {
    let value = localStorage.getItem(key);

    if (value === null) {
      return { success: false, reason: 'Key not found' };
    }

    return { success: true, value: value };
  } catch (e) {
    let reason = '';

    if (e.name === 'SecurityError') {
      reason = 'Local storage is not allowed';
    } else {
      reason = 'Unknown error';
    }

    return { success: false, reason };
  }
};
