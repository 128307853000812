import React from 'react';
import { useSelector } from 'react-redux';
import { ModalHeader } from 'reactstrap';
import { billTypes } from '../../enums/billTypes';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import ServiceIcon from '../serviceCard/serviceIcon';

const GeneralDialogHeader = ({ serviceType, heading, headingIcon, headingCenter, showCloseIcon, onDialogClosing }) => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const getHeading = () => {
    if (heading) return heading;
    if (serviceType) {
      const agentServiceType = serviceType ? getAgentServiceType(serviceType, agentBillTypes) : null;
      const subHeading =
        serviceType === billTypes.NONE ||
        serviceType.ComparisonImplemented ||
        agentServiceType.affiliate ||
        agentServiceType.partner
          ? null
          : ' (coming soon)';

      return (
        <>
          {serviceType.Name}
          <span className="sub-heading">{subHeading}</span>
        </>
      );
    }
    return null;
  };
  return (
    <ModalHeader
      className={`sm-modal-header${headingCenter ? ' centered' : ''}`}
      toggle={onDialogClosing}
      tag={'h4'}
      close={
        showCloseIcon === false ? (
          <span></span>
        ) : (
          <button className="close" onClick={onDialogClosing}>
            ×
          </button>
        )
      }
    >
      <span className="type-icon-wrap">
        {headingIcon ? headingIcon : serviceType ? <ServiceIcon isAdd={false} serviceType={serviceType} /> : null}
      </span>
      <span className="heading">{getHeading()}</span>
    </ModalHeader>
  );
};

export default GeneralDialogHeader;
