import React from 'react';

const CardImageSplit = ({ imageUrl, iconUrl, title, text, imageMinHeight }) => {
  const canvasStyle = { backgroundImage: `url("${imageUrl}")` };
  if (imageMinHeight) canvasStyle.minHeight = imageMinHeight;

  return (
    <div className={`card-image-split`}>
      <div className="bg-canvas" style={canvasStyle} />
      <div className="inner-content text-center">
        {iconUrl && (
          <div className="image-wrap">
            <img src={iconUrl} alt="card icon" />
          </div>
        )}
        {title && <h5 className="title">{title}</h5>}
        {text && <p className="text">{text}</p>}
      </div>
    </div>
  );
};

export default CardImageSplit;
