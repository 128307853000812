import { isEmptyObject } from '../utilities/commonUtilities';

export const customerCommissionTypes = {
  NONE: { Id: 0, Name: 'None' },
  WOOLWORTHS_GIFT_CARD: {
    Id: 1,
    Provider: 'Woolworths',
    Name: 'Woolworths Gift Card',
    NamePlural: 'Woolworths Gift Cards',
    Tag: 'gift card',
    TagPlural: 'gift cards',
    Denomination: '$',
    Leadin: 'Earn a',
    Logo: '/images/commission/woolworths/gift-card-generic.png'
  },
  COLES_GIFT_CARD: {
    Id: 2,
    Provider: 'Coles',
    Name: 'Coles Gift Card',
    NamePlural: 'Coles Gift Cards',
    Tag: 'gift card',
    TagPlural: 'gift cards',
    Denomination: '$',
    Leadin: 'Earn a',
    Logo: '/images/commission/coles/gift-card-generic.png'
  },
  FLYBUYS: {
    Id: 3,
    Provider: 'Flybuys',
    Name: 'Flybuys points',
    NamePlural: 'Flybuys points',
    Tag: 'points',
    TagPlural: 'points',
    Denomination: '',
    Leadin: 'Earn',
    Logo: '/images/commission/flybuys/points-logo.png'
  },
  VELOCITY: {
    Id: 4,
    Provider: 'Velocity',
    Name: 'Velocity points',
    NamePlural: 'Velocity points',
    Tag: 'points',
    TagPlural: 'points',
    Denomination: '',
    Leadin: 'Earn',
    Logo: '/images/commission/velocity/points-logo.png'
  },
  getById: id => {
    switch (id) {
      case customerCommissionTypes.WOOLWORTHS_GIFT_CARD.Id:
        return customerCommissionTypes.WOOLWORTHS_GIFT_CARD;
      case customerCommissionTypes.COLES_GIFT_CARD.Id:
        return customerCommissionTypes.COLES_GIFT_CARD;
      case customerCommissionTypes.FLYBUYS.Id:
        return customerCommissionTypes.FLYBUYS;
      case customerCommissionTypes.VELOCITY.Id:
        return customerCommissionTypes.VELOCITY;
      default:
        return customerCommissionTypes.NONE;
    }
  }
};

export function getAgentServiceType(serviceType, agentServiceTypes) {
  const agentServiceType = agentServiceTypes.find(e => e.id === serviceType.Id);
  const commissionType = agentServiceType
    ? customerCommissionTypes.getById(agentServiceType.customerCommissionType)
    : null;
  const hasCommission =
    commissionType &&
    agentServiceType &&
    commissionType !== customerCommissionTypes.NONE &&
    agentServiceType.customerCommissionLogo
      ? true
      : false;

  const hasAffiliate = agentServiceType && agentServiceType.affiliate ? true : false;
  const affiliate = agentServiceType ? agentServiceType.affiliate : null;

  const hasPartner = agentServiceType && agentServiceType.partner ? true : false;
  const partner = agentServiceType ? agentServiceType.partner : null;

  const hasSettings =
    agentServiceType && agentServiceType.settings && !isEmptyObject(agentServiceType.settings) ? true : false;
  const settings = agentServiceType && agentServiceType.settings;
  const affiliateSettings = hasSettings && settings.affiliate ? settings.affiliate : {};
  const hasAffiliateSettings = !isEmptyObject(affiliateSettings);

  const externalExitUrl = agentServiceType && agentServiceType.externalExitUrl;

  const providers = agentServiceType && agentServiceType.providers ? agentServiceType.providers : null;

  return {
    serviceType,
    enabled: agentServiceType ? agentServiceType.enabled : false,
    agentBillType: agentServiceType,
    hasCommission,
    commissionType,
    hasAffiliate,
    affiliate,
    hasPartner,
    partner,
    hasSettings,
    settings,
    hasAffiliateSettings,
    affiliateSettings,
    externalExitUrl,
    providers
  };
}

export function getCommonCommissionType(agentServiceTypes) {
  const items = [];

  agentServiceTypes.forEach(ast => {
    if (ast.enabled && ast.customerCommissionType > 0 && ast.customerCommission > 0) {
      const cct = customerCommissionTypes.getById(ast.customerCommissionType);

      let item = items.find(e => e.customerCommissionType === cct);

      if (item === undefined) {
        item = { customerCommissionType: cct, count: 0 };
        items.push(item);
      }

      item.count = item.count + 1;
    }
  });

  items.sort((a, b) => {
    if (b.count !== a.count) {
      return b.count - a.count; // Sort by count in descending order
    } else {
      return a.customerCommissionType.Id - b.customerCommissionType.Id; // Sort by customerCommissionType.Id in ascending order
    }
  });

  return items.length > 0 ? items[0].customerCommissionType : customerCommissionTypes.NONE;
}
