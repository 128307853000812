import React from 'react';

import { Container, Row, Col } from 'reactstrap';

const IdleOfferTimeout = ({ heading, message }) => {
  return (
    <Container>
      <Row>
        <Col className="qs-modal-heading">
          <h1>{heading || 'Session Timeout'}</h1>
        </Col>
      </Row>

      <Row>
        <Col className="qs-idle-timer-message">{message}</Col>
      </Row>
    </Container>
  );
};
export default IdleOfferTimeout;
