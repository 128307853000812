import React from 'react';

const HeroBanner = () => {
  return (
    <div className="sm-hero-banner">
      <div>
        <div className="start-content">
          <ul>
            <li>Certified solar finance experts</li>
            <li>1,000's of finance products</li>
            <li>Low rates from the big banks</li>
            <li>Bundle with your home loan</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default HeroBanner;
