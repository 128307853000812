import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers/index';
import initialState from '../reducers/initialState';

export const history = createBrowserHistory();

function configureStoreProd(initialState) {
  const middlewares = [routerMiddleware(history), thunk];

  return createStore(createRootReducer(history), initialState, compose(applyMiddleware(...middlewares)));
}

function configureStoreDev(initialState) {
  const middlewares = [reduxImmutableStateInvariant(), routerMiddleware(history), thunk];

  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  const enhancers = [];

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }

  const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers
  );

  const devStore = createStore(createRootReducer(history), initialState, composedEnhancers);

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      devStore.replaceReducer(createRootReducer(history));
    });
  }

  return devStore;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

const store = configureStore(initialState);

export default store;
