import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useAuth0 } from '../../auth/auth0-Provider.js';
import { Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import CustomerCommissionLogo from '../common/customerCommissionLogo';
import AgreeTerms from '../common/agreeTerms';
import QsCheckBox from '../common/qsCheckBox';
import ServerMessage from '../common/serverMessage';

import { getSessionActivity } from '../../utilities/commonUtilities';
import { setServerMessageClear } from '../../actions/serverMessageActions';
import {
  submitPartnerComparisonUnauthenticated,
  submitPartnerComparisonWithKey,
  submitPartnerComparisonWithToken
} from '../../actions/comparisonActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import { urlTypes } from '../../enums/urlTypes.js';
import { partnerTrackingTypes } from '../../enums/trackingTypes.js';

const PartnerComparison = ({ agentServiceType, onClose }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { serverMessage, selectedAgent, session } = useSelector(state => state);
  const { emailAddress } = useSelector(state => state.user);

  const { getAccessToken, getAccessKey, isUserAuthenticated } = useAuth0();

  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const partner = agentServiceType.partner;
  const partnerLogo = partner ? partner.partnerLogo : null;
  const customerCommission = agentServiceType.agentBillType.customerCommission;
  const serviceType = agentServiceType.serviceType;
  const commissionType = agentServiceType.commissionType;

  const target = serverMessageTargets.PARTNER_COMPARISON;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setServerMessageClear(target, serverMessage));
  }, []);

  const onComplete = data => {
    if (data) {
      switch (data.urlType) {
        case urlTypes.LOCAL:
          dispatch(push(`partner/local/${data.url}`));
          break;

        default:
          dispatch(push(`/partner/${serviceType.Code.toLowerCase()}`));
      }
    } else {
      onClose();
    }
  };

  const viewOffers = async () => {
    dispatch(setServerMessageClear(target, serverMessage));

    const sessionActivity = getSessionActivity(session);

    const model = {
      agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
      serviceType: serviceType.Id,
      sessionActivity,
      emailAddress,
      termsAndConditions,
      trackingType: partnerTrackingTypes.INIT
    };

    const accessKey = getAccessKey();

    // Prioritize accessKey first as even an authenticated user may have come in on a link, possibly for a different customer.
    if (accessKey) {
      dispatch(submitPartnerComparisonWithKey(accessKey, model, serviceType, onComplete));
    } else if (isUserAuthenticated()) {
      dispatch(submitPartnerComparisonWithToken(await getAccessToken(), model, serviceType, onComplete));
    } else {
      dispatch(submitPartnerComparisonUnauthenticated(model, serviceType, onComplete));
    }
  };

  return (
    <div className="sm-service-card-external">
      <ServerMessage serverMessage={serverMessage} target={target} />

      {partnerLogo && (
        <div className="logo">
          <img alt="Logo" src={`${staticContentUrl}${partnerLogo}`} />
        </div>
      )}

      {partner && (
        <div className="description">
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: `${partner.description}`
              }}
            />
          </div>
        </div>
      )}

      <CustomerCommissionLogo serviceType={serviceType} />

      <hr className="summary-divider" />

      <AvForm onValidSubmit={viewOffers}>
        <div className="terms">
          <QsCheckBox
            isChecked={termsAndConditions}
            onCheck={() => setTermsAndConditions(!termsAndConditions)}
            label={<AgreeTerms />}
            validatorName="parter-comparison-terms"
            validationMessage="Please accept the terms and conditions"
          />
        </div>
        <div className="button-container">
          <Button className="sm-button-primary medium right-arrow-small">View Offers</Button>
        </div>
      </AvForm>
    </div>
  );
};

export default PartnerComparison;
