import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { Button, Collapse, Spinner } from 'reactstrap';

import Slider from '../common/slider';
import SubmitCommon from '../common/submitCommon';
import QsButtonGroup from '../common/qsButtonGroup';
import ExitLaunch from '../common/exitLaunch';

import { uploadSubTypes } from '../../enums/uploadBillTypes';

import { loanTypes, repaymentTypes, loanPropertyTypes } from '../../enums/submissionTypes';
import { setUser } from '../../actions/userActions';

import 'react-google-places-autocomplete/dist/index.min.css';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { billTypes } from '../../enums/billTypes';

const HomeLoanNewAu = ({
  uploadSubType,
  launchMode,
  newHomeLoanData,
  userBill,
  onPrevious,
  onRecalculate,
  onViewOffers,
  completedNotification
}) => {
  const dispatch = useDispatch();

  const { selectedAgent } = useSelector(state => state);
  const { hasAgents } = useSelector(state => state.config);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);

  const rowClass = 'sm-dialog-row';
  const fieldClass = 'sm-dialog-field-name';

  const propertyTypeLabel = 'Property Type';
  const loanAmountLabel = 'New Loan Amount';
  const loanPeriodLabel = 'New Loan Period';
  const loanTypeLabel = 'New Loan Type';
  const repaymentTypeLabel = 'New Repayment Type';

  const showAgents = !selectedAgent.isLoaded && hasAgents;
  const isRefinance = uploadSubType === uploadSubTypes.REFINANCE;

  const { propertyType, loanType, repaymentType, newLoanAmount, loanPeriod, splitValue } = newHomeLoanData;

  const [selectedPropertyType, setSelectedPropertyType] = useState(propertyType);
  const [selectedLoanType, setSelectedLoanType] = useState(loanType);
  const [selectedRepaymentType, setSelectedRepaymentType] = useState(repaymentType);
  const [selectedLoanAmount, setSelectedLoanAmount] = useState(newLoanAmount);
  const [selectedLoanPeriod, setSelectedLoanPeriod] = useState(loanPeriod);
  const [selectedSplitValue, setSelectedSplitValue] = useState(splitValue);

  // If uploadSubType is REFINANCE, user accepts terms on previous screen
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(isRefinance);

  const [hasChanges, setHasChanges] = useState(false);
  const [submissionCommon, setSubmissionCommon] = useState({});
  const [submissionCommonRefresh, setSubmissionCommonRefresh] = useState();
  const [initialComparisonEmailValue, setInitialComparisonEmailValue] = useState();
  const [initialEmailAddressValue, setInitialEmailAddressValue] = useState(null);

  const ratesLoaded = userBill && userBill.reductionLoaded;
  const hasOffers = ratesLoaded && userBill.bestOffer;
  const hasSavings = hasOffers && userBill.reductionAmountAnnual > 0;
  const defaultComparison = userBill && userBill.estimate.defaultComparison;

  // Only show back button if we are showing an empty card or we need to be able to go back to 'current' home loan UI.
  const showBackButton = !userBill || isRefinance;

  // --------------------------------------------------------------------------
  // Get new-loan data.
  // --------------------------------------------------------------------------
  const getData = () => {
    return {
      propertyType: selectedPropertyType,
      loanType: selectedLoanType,
      repaymentType: selectedRepaymentType,
      newLoanAmount: selectedLoanAmount,
      loanPeriod: selectedLoanPeriod,
      splitValue: selectedSplitValue
    };
  };

  // --------------------------------------------------------------------------
  // View Offers.
  // --------------------------------------------------------------------------
  const viewOffersButtonClick = () => {
    onViewOffers(termsAndConditionsAccepted);
  };

  // --------------------------------------------------------------------------
  // Previous.
  // --------------------------------------------------------------------------
  const previousButtonClick = () => {
    onPrevious(hasChanges ? null : getData());
  };

  // --------------------------------------------------------------------------
  // Initialize the form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    initialize();
  }, []);

  // --------------------------------------------------------------------------
  // Estimate completed notification.
  // --------------------------------------------------------------------------
  useEffect(() => {
    setHasChanges(false);
  }, [completedNotification]);

  // --------------------------------------------------------------------------
  // Detect changes in form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    let changed =
      selectedPropertyType !== propertyType ||
      selectedLoanType !== loanType ||
      selectedRepaymentType !== repaymentType ||
      selectedLoanAmount !== newLoanAmount ||
      selectedLoanPeriod !== loanPeriod ||
      (selectedLoanType === loanTypes.SPLIT.value && selectedSplitValue !== splitValue) ||
      (initialEmailAddressValue ? initialEmailAddressValue : null) !==
        (submissionCommon.emailAddress ? submissionCommon.emailAddress : null);

    setHasChanges(changed);
  }, [
    selectedPropertyType,
    selectedLoanType,
    selectedRepaymentType,
    selectedLoanAmount,
    selectedLoanPeriod,
    selectedSplitValue,
    submissionCommon.emailAddress
  ]);

  // --------------------------------------------------------------------------
  // Detect changes in terms and conditions checkbox.
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (!isRefinance) {
      setTermsAndConditionsAccepted(submissionCommon.termsAndConditions);
    }
  }, [submissionCommon.termsAndConditions]);

  // --------------------------------------------------------------------------
  // Initialize or reset the form.
  // --------------------------------------------------------------------------
  const initialize = reset => {
    setSelectedPropertyType(propertyType);
    setSelectedLoanType(loanType);
    setSelectedRepaymentType(repaymentType);
    setSelectedLoanAmount(newLoanAmount);
    setSelectedLoanPeriod(loanPeriod);
    setSelectedSplitValue(splitValue);

    if (reset && uploadSubType === uploadSubTypes.NEW_LOAN) {
      dispatch(setUser({ emailAddress: initialEmailAddressValue }));
      setSubmissionCommonRefresh(Date.now());
    }

    setHasChanges(false);
  };

  // --------------------------------------------------------------------------
  // Set Button Group
  // --------------------------------------------------------------------------
  const setButtonGroup = (event, setter) => {
    const value = event.target.getAttribute('data-value');
    const fieldName = event.target.getAttribute('data-fieldname');
    const label = event.target.innerText;

    setter(value);

    standardEvents('home-loan-dialog', fieldName, label, googleAnalyticsClientCode);
  };

  // --------------------------------------------------------------------------
  // Recalculate
  // --------------------------------------------------------------------------
  const recalculateClick = () => {
    onRecalculate({
      ...submissionCommon,
      ...getData()
    });
  };

  // --------------------------------------------------------------------------
  // Submission common handlers.
  // --------------------------------------------------------------------------
  const onSubmissionCommonLoad = value => {
    if (value) {
      setInitialComparisonEmailValue(value.sendComparisonEmail);
      setInitialEmailAddressValue(value.emailAddress);
      setSubmissionCommon(value);
    }
  };

  const onSubmissionCommonChange = value => {
    setSubmissionCommon(value);
  };

  return (
    <>
      <div className="sm-home-loan-dialog-heading">Tell us what you're looking for...</div>
      {/* --- PROPERTY TYPE ----------------------------------------------------- */}
      <div className={rowClass}>
        <div className={fieldClass}>{propertyTypeLabel}</div>
        <div>
          <QsButtonGroup
            required={true}
            id="propertyType"
            fieldName={propertyTypeLabel}
            additionalClass="sm-button-group"
            selectedValue={selectedPropertyType}
            onTouchButton={e => setButtonGroup(e, setSelectedPropertyType, '')}
            buttons={[loanPropertyTypes.OWNER_OCCUPIED, loanPropertyTypes.INVESTMENT]}
          />
        </div>
      </div>

      {/* --- LOAN AMOUNT ------------------------------------------------------- */}
      <div className={rowClass}>
        <div className={`${fieldClass} slider`}>{loanAmountLabel}</div>
        <div>
          <Slider
            required
            allowModeChange
            fieldName={loanAmountLabel}
            value={selectedLoanAmount}
            onFinalChange={value => setSelectedLoanAmount(value)}
            min={50000}
            max={5000000}
            step={25000}
            labelPrefix="$"
            maxValueSuffix="+"
          />
        </div>
      </div>

      {/* --- LOAN PERIOD ------------------------------------------------------- */}
      <div className={rowClass}>
        <div className={`${fieldClass} slider`}>{loanPeriodLabel}</div>
        <div>
          <Slider
            required
            fieldName={loanPeriodLabel}
            value={selectedLoanPeriod}
            onFinalChange={value => setSelectedLoanPeriod(value)}
            min={5}
            max={30}
            labelSuffix=" years"
          />
        </div>
      </div>

      {/* --- LOAN TYPE --------------------------------------------------------- */}
      <div className={rowClass}>
        <div className={fieldClass}>{loanTypeLabel}</div>
        <div>
          <QsButtonGroup
            required={true}
            id="loanType"
            fieldName={loanTypeLabel}
            additionalClass="sm-button-group"
            selectedValue={selectedLoanType}
            onTouchButton={e => setButtonGroup(e, setSelectedLoanType)}
            buttons={[loanTypes.FIXED, loanTypes.VARIABLE, loanTypes.SPLIT]}
          />
        </div>
      </div>

      {/* --- SPLIT RATE -------------------------------------------------------- */}
      <Collapse isOpen={selectedLoanType === '3'}>
        {selectedLoanType && (
          <div className={rowClass}>
            <div>
              <Slider
                required={selectedLoanType === '3'}
                allowModeChange={selectedLoanType === '3'}
                fieldName="Interest Rate Split"
                value={selectedSplitValue}
                onFinalChange={value => setSelectedSplitValue(value)}
                min={1}
                max={100}
                step={1}
                labelSuffix="% fixed"
              />
            </div>
          </div>
        )}
      </Collapse>

      {/* --- REPAYMENT TYPE ---------------------------------------------------- */}
      <div className={rowClass}>
        <div className={fieldClass}>{repaymentTypeLabel}</div>
        <div>
          <QsButtonGroup
            required
            id="repaymentType"
            fieldName={repaymentTypeLabel}
            additionalClass="sm-button-group"
            selectedValue={selectedRepaymentType}
            onTouchButton={e => setButtonGroup(e, setSelectedRepaymentType)}
            buttons={[repaymentTypes.PRINCIPAL_INTEREST, repaymentTypes.INTEREST_ONLY]}
          />
        </div>
      </div>

      {(hasChanges || !userBill) && (
        <div className="sm-home-loan-dialog-recalculate">
          <Button onClick={recalculateClick} className="sm-button-primary right-arrow-small">
            {userBill ? 'Recalculate' : 'Calculate'}
          </Button>
        </div>
      )}

      {ratesLoaded ? (
        <>
          <div className={`sm-home-loan-dialog-best-offer-wrapper${hasChanges ? ' with-changes' : ''}`}>
            {hasOffers ? (
              <>
                <div className="heading">Loan alternatives from</div>
                <div className={`rate-wrapper${isMobile ? ' mobile' : ''}`}>
                  <div className="rate">
                    <div className="rate-value tight">{userBill.bestOffer.actualRate}%</div>
                    <div className="rate-label">
                      RATE P.A.{userBill.bestOffer.productPeriod && <div>{userBill.bestOffer.productPeriod}</div>}
                    </div>
                  </div>
                  <div className="rate">
                    <div className="rate-value tight">{userBill.bestOffer.comparisonRate}%</div>
                    <div className="rate-label">
                      RATE P.A.<div>COMPARISON</div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              'NO OFFERS FOUND'
            )}

            {hasSavings && (
              <>
                <div className="annual-savings-heading">
                  Annual saving<span className="disclaimer-symbol">†</span>
                </div>

                <div className="annual-savings-value">${userBill.reductionAmountAnnual.toLocaleString()}</div>
              </>
            )}
          </div>
        </>
      ) : userBill ? (
        <div className="sm-home-loan-dialog-best-offer-loading">
          <Spinner size="md" color="dark" />
        </div>
      ) : null}

      {/* --- EMAIL ADDRESS / AGENT --------------------------------------------- */}
      {uploadSubType === uploadSubTypes.NEW_LOAN && (
        <div className={`${rowClass} mt-4`}>
          <div className={fieldClass}>Email address {showAgents && '/ Agent'}</div>
          <div>
            <SubmitCommon
              //hasSubmitted={false}
              onLoad={onSubmissionCommonLoad}
              onChange={onSubmissionCommonChange}
              refresh={submissionCommonRefresh}
              showSendComparisonEmail={false}
              showSendArticlesEmail={false}
              showSendSolarInstallationEmail={false}
              serviceType={billTypes.HOME_LOAN}
            />
          </div>
        </div>
      )}
      <div className="sm-modal-footer">
        {hasChanges && (
          <Button onClick={() => initialize(true)} className="sm-button-secondary">
            Reset
          </Button>
        )}

        {showBackButton && (
          <Button onClick={previousButtonClick} className="sm-button-secondary">
            Go back
          </Button>
        )}

        <Button
          disabled={!hasOffers || hasChanges}
          onClick={defaultComparison ? recalculateClick : viewOffersButtonClick}
          className="sm-button-primary right-arrow-small wide"
        >
          View offers
        </Button>

        <ExitLaunch launchMode={launchMode} serviceType={billTypes.HOME_LOAN} source="home-loan-dialog" />
      </div>
    </>
  );
};

export default HomeLoanNewAu;
