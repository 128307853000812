import React from 'react';
import { useSelector } from 'react-redux';

import {
  customerCommissionTypes,
  getAgentServiceType,
  getCommonCommissionType
} from '../../enums/customerCommissionTypes';

import CustomerCommissionLogo from '../common/customerCommissionLogo';
import CustomerCommissionInfo from '../common/customerCommissionInfo';
import { billTypes } from '../../enums/billTypes';

const SubmitDialogBanner = ({ serviceType, providerLogo, providerName }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  let commissionText = null;
  let commissionType = customerCommissionTypes.NONE;

  if (serviceType === billTypes.NONE) {
    commissionType = getCommonCommissionType(agentBillTypes);
    commissionText = `Earn ${commissionType.NamePlural} when you switch.`;
  } else {
    const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);

    if (agentServiceType.hasCommission) {
      const serviceTypeName = serviceType.Name.toLowerCase();
      const commissionValue = agentServiceType.agentBillType.customerCommission.toLocaleString();

      commissionType = agentServiceType.commissionType;
      commissionText = `${commissionType.Leadin} ${commissionType.Denomination}${commissionValue} ${commissionType.Name} when you switch your ${serviceTypeName} plan.`;
    }
  }

  const hasCommission = commissionType !== customerCommissionTypes.NONE;

  if (!providerLogo && !hasCommission) return null;

  return (
    <div className="sm-submit-dialog-banner">
      <div className="logo-container">
        {providerLogo && (
          <img src={`${staticContentUrl}/images/providers/${providerLogo}`} alt={providerName || 'provider-logo'} />
        )}
        {hasCommission && <CustomerCommissionLogo serviceType={serviceType} />}
      </div>

      {hasCommission && (
        <div className="intro-text">
          {commissionText} <CustomerCommissionInfo commissionType={commissionType} serviceType={serviceType} />
        </div>
      )}
    </div>
  );
};

export default SubmitDialogBanner;
