import React from 'react';
import ReactPlayer from 'react-player';
import { Button } from 'reactstrap';
import { billTypes } from '../../enums/billTypes';
import { useSelector } from 'react-redux';

const Introduction = ({ startService }) => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const serviceTypeOptions = { includeExternal: false };
  const hasSolarFinance = billTypes.hasServiceType(agentBillTypes, billTypes.SOLAR_FINANCE, serviceTypeOptions);

  return (
    <>
      <div className="sm-start-introduction">
        <div className="content">
          <p>
            Arranging quotes for your solar installation? If you are, it's the right time to consider your solar finance
            options.
          </p>
          <p>Speak to one of our certified solar finance experts today.</p>
          {hasSolarFinance && (
            <div className="buttons">
              <Button onClick={startService} className="sm-button-primary medium">
                Continue
              </Button>
            </div>
          )}
        </div>
        <div className="media">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://player.vimeo.com/video/932262974?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              controls={true}
              muted={true}
              playing={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Introduction;
