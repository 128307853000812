import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import { implementationCodes } from '../../enums/implementationCodes';
import { occupancyTypes } from '../../enums/submissionTypes';

const EnergyEstimateDetails = ({ userBill, onEdit }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  const submission = userBill.estimate;
  const bestOffer = userBill.bestOffer;

  const { implementationCode } = userBill;

  const {
    propertyAddress,
    occupancyType,
    occupancyPurposeDisplay,
    occupancyDateDisplay,
    distributor,
    usageLevelDisplay,
    solarDisplay,
    provider
  } = submission;

  const { estimateDailyUsageDisplay, estimateDays, estimateMonthlyDisclaimer } = bestOffer || {};

  const { logo: providerLogo, displayCode: providerName } = provider || {};

  const showMovingRow = implementationCode === implementationCodes.ELECTRICITY.US_DEFAULT || occupancyDateDisplay;

  const address = propertyAddress
    ? `${propertyAddress.suburb}, ${propertyAddress.state} ${propertyAddress.postcode}`
    : '';

  return (
    <>
      <div className="sm-estimate-details">
        <div className="intro-summary">{address}</div>

        <div className="detail-summary">
          <div className="detail-content">
            {(() => {
              switch (implementationCode) {
                case implementationCodes.ELECTRICITY.AU_DEFAULT:
                case implementationCodes.GAS.AU_DEFAULT:
                  return (
                    <div className="detail-row">
                      <div className="detail-label">Type:</div>
                      <div className="detail-value">{occupancyPurposeDisplay}</div>
                    </div>
                  );
                case implementationCodes.ELECTRICITY.US_DEFAULT:
                  return (
                    <div className="detail-row">
                      <div className="detail-label">ZIP:</div>
                      <div className="detail-value">{propertyAddress.postcode}</div>
                    </div>
                  );
                default:
                  return '';
              }
            })()}

            {showMovingRow && (
              <div className="detail-row">
                <div className="detail-label">Moving:</div>
                <div className="detail-value">
                  {occupancyType === occupancyTypes.NEW.Id
                    ? occupancyDateDisplay
                      ? occupancyDateDisplay
                      : 'Yes'
                    : 'No'}
                </div>
              </div>
            )}

            {implementationCode === implementationCodes.ELECTRICITY.AU_DEFAULT && (
              <div className="detail-row">
                <div className="detail-label">Solar:</div>
                <div className="detail-value">{solarDisplay}</div>
              </div>
            )}

            <div className="detail-row">
              <div className="detail-label">
                {implementationCode === implementationCodes.ELECTRICITY.US_DEFAULT ? 'Utility:' : 'Distributor:'}
              </div>
              <div className="detail-value">{distributor ? distributor.name : null}</div>
            </div>

            {implementationCode === implementationCodes.ELECTRICITY.US_DEFAULT && (
              <>
                <div className="detail-row">
                  <div className="detail-label">Usage:</div>
                  <div className="detail-value">{usageLevelDisplay}</div>
                </div>
              </>
            )}
          </div>

          {providerLogo && (
            <div>
              <img
                className="provider-logo"
                src={`${staticContentUrl}/images/providers/${providerLogo}`}
                alt={providerName}
              />
            </div>
          )}
        </div>

        <div className="drawer-buttons mt-3">
          <Button className="sm-button-secondary" onClick={onEdit}>
            Edit
          </Button>
        </div>

        <hr className="summary-divider" />

        {bestOffer && (
          <>
            <div className="summary-row">
              <div>Your possible spend</div>
              <div>{bestOffer.billAmountGuaranteedDisplay}</div>
            </div>

            {(() => {
              switch (implementationCode) {
                case implementationCodes.ELECTRICITY.AU_DEFAULT:
                case implementationCodes.GAS.AU_DEFAULT:
                  return <div className="disclaimer-summary">{estimateMonthlyDisclaimer}</div>;
                case implementationCodes.ELECTRICITY.US_DEFAULT:
                  return (
                    <div className="disclaimer-summary">{`Monthly bill estimate for ${bestOffer.customerEnergyChargeAverage.usage} ${submission.energyTypeDisplay}`}</div>
                  );
                default:
                  return '';
              }
            })()}
          </>
        )}
      </div>
    </>
  );
};

export default EnergyEstimateDetails;
