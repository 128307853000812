export const preferredCallbackPeriods = {
  ANYTIME: { Id: 1, Name: 'Anytime' },
  MORNING: { Id: 2, Name: 'Morning' },
  AFTERNOON: { Id: 3, Name: 'Afternoon' },
  EVENING: { Id: 4, Name: 'Evening' },
  DO_NOT_CALL: { Id: 5, Name: 'Do not call' },
  getDefault: () => {
    return [
      preferredCallbackPeriods.ANYTIME,
      preferredCallbackPeriods.MORNING,
      preferredCallbackPeriods.AFTERNOON,
      preferredCallbackPeriods.EVENING
    ];
  }
};
