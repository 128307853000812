import { billTypes } from '../enums/billTypes';
import { usageTypes, occupancyPurposes, occupancyTypes, solarTypes, boolTypes } from '../enums/submissionTypes';

import { ReactComponent as DistributorAIcon } from '../components/icons/estimate-wizard/distributorA.svg';
import { ReactComponent as DistributorBIcon } from '../components/icons/estimate-wizard/distributorB.svg';
import { ReactComponent as ElectricityIcon } from '../components/icons/estimate-wizard/electricity.svg';
import { ReactComponent as GasIcon } from '../components/icons/estimate-wizard/gas.svg';
import { ReactComponent as ElectricityAndGasIcon } from '../components/icons/estimate-wizard/electricity-and-gas.svg';
import { ReactComponent as ResidentialIcon } from '../components/icons/estimate-wizard/residential.svg';
import { ReactComponent as BusinessIcon } from '../components/icons/estimate-wizard/business.svg';
import { ReactComponent as MovingHouseIcon } from '../components/icons/estimate-wizard/moving-house.svg';
import { ReactComponent as NewConnectionIcon } from '../components/icons/estimate-wizard/new-connection.svg';
import { ReactComponent as YesIcon } from '../components/icons/estimate-wizard/yes.svg';
import { ReactComponent as NoIcon } from '../components/icons/estimate-wizard/no.svg';
import { ReactComponent as NoneIcon } from '../components/icons/estimate-wizard/no.svg';
import { ReactComponent as StandardIcon } from '../components/icons/estimate-wizard/standard-solar.svg';
import { ReactComponent as PremiumIcon } from '../components/icons/estimate-wizard/premium-solar.svg';
import { ReactComponent as UploadIcon } from '../components/icons/estimate-wizard/upload-bill.svg';
import { ReactComponent as ManualIcon } from '../components/icons/estimate-wizard/enter-bill.svg';
import { uploadBillTypes } from '../enums/uploadBillTypes';

const getUsageTypeName = usageType => {
  switch (usageType) {
    case usageTypes.LOW.Id:
      return usageTypes.LOW.Name;
    case usageTypes.MEDIUM.Id:
      return usageTypes.MEDIUM.Name;
    case usageTypes.HIGH.Id:
      return usageTypes.HIGH.Name;
    default:
      return null;
  }
};

const getOccupancyPurposeName = occupancyPurpose => {
  switch (occupancyPurpose) {
    case occupancyPurposes.RESIDENTIAL.Id:
      return occupancyPurposes.RESIDENTIAL.Name;
    case occupancyPurposes.BUSINESS.Id:
      return occupancyPurposes.BUSINESS.Name;
    default:
      return null;
  }
};

const getIcon = (type, alternate) => {
  if (type === undefined) return null;
  if (type === billTypes.ELECTRICITY) return <ElectricityIcon />;
  if (type === billTypes.GAS) return <GasIcon />;
  if (type === billTypes.ENERGY) return <ElectricityAndGasIcon />;
  if (type === occupancyPurposes.RESIDENTIAL) return <ResidentialIcon />;
  if (type === occupancyPurposes.BUSINESS) return <BusinessIcon />;
  if (type === occupancyTypes.CURRENT) return <YesIcon />;
  if (type === occupancyTypes.NEW) return <NoIcon />;
  if (type === solarTypes.NONE) return <NoneIcon />;
  if (type === solarTypes.STANDARD) return <StandardIcon />;
  if (type === solarTypes.PREMIUM) return <PremiumIcon />;
  if (type === boolTypes.YES) return <YesIcon />;
  if (type === boolTypes.NO) return <NoIcon />;
  if (type === uploadBillTypes.WEBSITE) return <UploadIcon />;
  if (type === uploadBillTypes.MANUAL) return <ManualIcon />;
  if (type.selectionCode !== undefined && !alternate) return <DistributorAIcon />;
  if (type.selectionCode !== undefined && alternate) return <DistributorBIcon />;
};

export { getUsageTypeName, getOccupancyPurposeName, getIcon };
