import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { billTypes } from '../enums/billTypes';
import QsButtonGroup from '../components/common/qsButtonGroup';
import { Button, Collapse, FormGroup, Input } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Rating from '../components/common/rating';
import { submitSurvey } from '../actions/surveyActions';
import ServerMessage from '../components/common/serverMessage';
import * as serverMessageTargets from '../actions/serverMessageTargets';
import { displayErrorMessage, displayInfoMessage } from '../actions/serverMessageActions';
import Autocomplete from 'react-autocomplete';
import SubmitAgentMember from '../components/common/submitAgentMember';
import SurveyBroker from '../components/common/surveyBroker';

const YesNoQuestion = ({ id, name, label, value, onChange }) => {
  return (
    <div className="question">
      <div className="label">{label}</div>
      <div>
        <QsButtonGroup
          required={true}
          id={id}
          fieldName={name}
          additionalClass="sm-button-group"
          selectedValue={value === true ? 'true' : 'false'}
          onTouchButton={e => onChange(e)}
          buttons={[
            {
              label: 'No',
              value: 'false'
            },
            {
              label: 'Yes',
              value: 'true'
            }
          ]}
        />
      </div>
    </div>
  );
};

const RatingQuestion = ({ rating, setRating, label }) => {
  return (
    <div className="question">
      <div className="label">{label}</div>
      <Rating rating={rating} onClick={value => setRating(value)} />
    </div>
  );
};

const BrokerNameQuestion = ({ brokerName, setBrokerName }) => {
  return (
    <div className="question">
      <div className="label mb-3">What was the name of the broker who called you?</div>
      <FormGroup>
        <SurveyBroker brokerName={brokerName} onChange={value => setBrokerName(value)} />
      </FormGroup>
    </div>
  );
};

const CommentsQuestion = ({ setComments }) => {
  return (
    <div className="question">
      <FormGroup>
        <div className="label">Do you have any feedback you'd like to share?</div>
        <Input className="comments" type="textarea" name="text" onChange={e => setComments(e.target.value)} />
      </FormGroup>
    </div>
  );
};

const SurveyScreen = () => {
  const dispatch = useDispatch();

  const { serverMessage } = useSelector(state => state);
  const { accessKey, serviceTypeId, hasError } = useSelector(state => state.survey);
  const { level, version, id, title, introduction } = useSelector(state => state.survey.settings);

  const { SURVEY: target } = serverMessageTargets;

  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [customerContacted, setCustomerContacted] = useState(true);
  const [decisionInfo, setDecisionInfo] = useState(true);
  const [movingForward, setMovingForward] = useState(true);
  const [comments, setComments] = useState();
  const [brokerName, setBrokerName] = useState();
  const [rating, setRating] = useState();

  const serviceType = billTypes.getById(serviceTypeId);

  const onYesNoChange = (event, setFunction) => {
    let option = event.target.getAttribute('data-value');
    let optionValue = option === 'true';
    setFunction(optionValue);
  };

  useEffect(() => {
    if (hasError) {
      dispatch(displayErrorMessage(target, 'Sorry, this survey has expired.'));
    }
  }, [dispatch, hasError, target]);

  const onComplete = () => {
    setSurveySubmitted(true);
    dispatch(displayInfoMessage(target, 'Thank you for your feedback', true));
  };

  const onSubmitSurvey = () => {
    let model = { surveyId: id, surveyLevel: level, surveyVersion: version, serviceTypeId, data: { comments } };
    switch (serviceType) {
      case billTypes.HOME_LOAN:
        if (level === 1) {
          model.data.customerContacted = customerContacted;
          model.data.brokerName = customerContacted ? brokerName : null;
          model.data.rating = customerContacted ? rating : null;
        }

        if (level === 2) {
          model.data.decisionInfo = decisionInfo;
          model.data.movingForward = movingForward;
          model.data.rating = rating;
        }
        break;

      default:
        break;
    }

    dispatch(submitSurvey(model, serviceType, accessKey, onComplete));
  };

  const getQuestions = () => {
    switch (serviceType) {
      case billTypes.HOME_LOAN:
        if (level === 1)
          return (
            <>
              <YesNoQuestion
                id="customerContacted"
                name="Customer Contacted"
                value={customerContacted}
                label="Did you receive a call from the broker we referred you to?"
                onChange={e => onYesNoChange(e, setCustomerContacted)}
              />
              <Collapse isOpen={customerContacted}>
                <BrokerNameQuestion brokerName={brokerName} setBrokerName={setBrokerName} />
                <RatingQuestion rating={rating} setRating={setRating} label="How satisfied were you with the call?" />
              </Collapse>
              <CommentsQuestion setComments={setComments} />
            </>
          );
        if (level === 2)
          return (
            <>
              <YesNoQuestion
                id="decisionInfo"
                name="Decision Info"
                value={decisionInfo}
                label="Did the broker we referred you to provide you with all the information you need to make a decision?"
                onChange={e => onYesNoChange(e, setDecisionInfo)}
              />
              <YesNoQuestion
                id="movingForward"
                name="Moving Forward"
                value={movingForward}
                label="Have you already, or do you intend to move forward with your home loan application?"
                onChange={e => onYesNoChange(e, setMovingForward)}
              />
              <RatingQuestion
                rating={rating}
                setRating={setRating}
                label="How satisfied were you with the broker we referred you to?"
              />
              <CommentsQuestion setComments={setComments} />{' '}
            </>
          );
        return null;

      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>Customer Survey</title>
      </Helmet>

      <div className="sm-container">
        <ServerMessage serverMessage={serverMessage} target={target} />

        {!hasError && !surveySubmitted && (
          <div className="sm-survey">
            <AvForm onValidSubmit={onSubmitSurvey}>
              <div className="heading">{title}</div>
              <div className="intro">{introduction}</div>
              <div className="questions">{getQuestions()}</div>
              {!surveySubmitted && (
                <div className="button-container">
                  <Button className="sm-button-primary medium">Submit</Button>
                </div>
              )}
            </AvForm>
          </div>
        )}
      </div>
    </>
  );
};

export default SurveyScreen;
