import React, { useState } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { AvField, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment-timezone';

import { billingTypes } from '../../enums/submissionTypes';
import InternetAddon from '../common/internetAddon';
import QsButtonGroup from '../common/qsButtonGroup';
import AddressControl from '../common/addressControl';
import QsCheckBox from '../common/qsCheckBox';
import YourOrderInternet from '../common/yourOrderInternet';

const InternetQuestions = ({ confirmDetail, offer, onChange }) => {
  const { country } = useSelector(state => state.config);
  const { homePhoneNumberRegex } = useSelector(state => state.config.applicationSettings.country);

  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(null);

  const productDetail = offer.productDetail;

  const onPreviousAddressSelected = (index, address) => {
    var newOtherPreviousAddresses = [...confirmDetail.otherPreviousAddresses];

    var newOtherPreviousAddress = { ...newOtherPreviousAddresses[index] };
    newOtherPreviousAddress.address = address;

    newOtherPreviousAddresses = [
      ...newOtherPreviousAddresses.slice(0, index),
      newOtherPreviousAddress,
      ...newOtherPreviousAddresses.slice(index + 1)
    ];

    onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });
  };

  const setPreviousAddressesResidingYears = (index, residingYears) => {
    var newOtherPreviousAddresses = [...confirmDetail.otherPreviousAddresses];

    var newOtherPreviousAddress = { ...newOtherPreviousAddresses[index] };
    newOtherPreviousAddress.residingYears = residingYears;

    newOtherPreviousAddresses = [
      ...newOtherPreviousAddresses.slice(0, index),
      newOtherPreviousAddress,
      ...newOtherPreviousAddresses.slice(index + 1)
    ];

    onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });

    adjustPreviousAddresses(newOtherPreviousAddresses, confirmDetail.residingYears, confirmDetail.residingMonths);
  };

  const setPreviousAddressesResidentialStatus = (index, residentialStatus) => {
    var newOtherPreviousAddresses = [...confirmDetail.otherPreviousAddresses];

    var newOtherPreviousAddress = { ...newOtherPreviousAddresses[index] };
    newOtherPreviousAddress.residentialStatus = residentialStatus;

    newOtherPreviousAddresses = [
      ...newOtherPreviousAddresses.slice(0, index),
      newOtherPreviousAddress,
      ...newOtherPreviousAddresses.slice(index + 1)
    ];

    onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });
  };

  const setPreviousAddressesResidingMonths = (index, residingMonths) => {
    var newOtherPreviousAddresses = [...confirmDetail.otherPreviousAddresses];

    var newOtherPreviousAddress = { ...newOtherPreviousAddresses[index] };
    newOtherPreviousAddress.residingMonths = residingMonths;

    newOtherPreviousAddresses = [
      ...newOtherPreviousAddresses.slice(0, index),
      newOtherPreviousAddress,
      ...newOtherPreviousAddresses.slice(index + 1)
    ];

    onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });

    adjustPreviousAddresses(newOtherPreviousAddresses, confirmDetail.residingYears, confirmDetail.residingMonths);
  };

  const adjustPreviousAddresses = (otherPreviousAddresses, residingYears, residingMonths) => {
    setTimeout(() => {
      var newOtherPreviousAddresses;
      var totalYears;

      if (otherPreviousAddresses) {
        totalYears = totalResidingYears(null, otherPreviousAddresses, residingYears, residingMonths);
        if (totalYears >= 2) {
          onChange(null, { otherPreviousAddresses: null });
          return;
        }

        var lastIndex = otherPreviousAddresses.length - 1;

        for (var i = 0; i < otherPreviousAddresses.length; i++) {
          totalYears = totalResidingYears(i, otherPreviousAddresses, residingYears, residingMonths);
          if (totalYears >= 2) {
            newOtherPreviousAddresses = [...otherPreviousAddresses.slice(0, i + 1)];
            onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });
            return;
          }
        }

        if (otherPreviousAddresses[lastIndex].residingMonths && otherPreviousAddresses[lastIndex].residingYears) {
          newOtherPreviousAddresses = [
            ...otherPreviousAddresses,
            { address: null, residingMonths: null, residingYears: null }
          ];
          onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });
        }
      } else if (
        residingYears &&
        residingMonths &&
        totalResidingYears(null, otherPreviousAddresses, residingYears, residingMonths) < 2
      ) {
        onChange(null, { otherPreviousAddresses: [{ address: null, residingMonths: null, residingYears: null }] });
      }
    }, 1000);
  };

  const onChangeKeepExistingNumber = event => {
    const option = event.target.getAttribute('data-value');
    onChange(null, { keepExistingNumber: option });
  };

  const onChangeExistingAccount = event => {
    const option = event.target.getAttribute('data-value');
    onChange(null, { existingAccount: option });
  };

  const onChangeSpecificDeliveryDate = event => {
    const option = event.target.getAttribute('data-value');

    if (option === 'false') {
      setSelectedDeliveryDate(null);
      onChange(null, { specificDeliveryDate: option, deliveryDate: null });
    } else {
      onChange(null, { specificDeliveryDate: option });
    }
  };

  const onDeliveryDateChange = date => {
    setSelectedDeliveryDate(date);

    const utcDate = moment(date).format('DD/MM/YYYY');
    onChange(null, { deliveryDate: utcDate });
  };

  const totalResidingYears = (maxIndex, otherPreviousAddresses, residingYears, residingMonths) => {
    if (!residingYears || !residingMonths) return 0;

    let totalResidingYears = residingYears ? parseInt(residingYears) : 0;

    if (!otherPreviousAddresses || maxIndex === null) return totalResidingYears;

    let currentMonthCount = parseInt(residingMonths);

    for (var index = 0; index <= maxIndex; index++) {
      totalResidingYears += otherPreviousAddresses[index].residingYears
        ? parseInt(otherPreviousAddresses[index].residingYears)
        : 0;

      currentMonthCount += otherPreviousAddresses[index].residingMonths
        ? parseInt(otherPreviousAddresses[index].residingMonths)
        : 0;

      if (currentMonthCount >= 12) {
        currentMonthCount -= 12;
        totalResidingYears++;
      }
    }

    return totalResidingYears;
  };

  const getOtherPreviousAddress = () => {
    if (!confirmDetail.residingYears || !confirmDetail.residingMonths || !confirmDetail.otherPreviousAddresses)
      return null;

    return confirmDetail.otherPreviousAddresses.map((item, index) => (
      <>
        <Col xs="12">
          <div className="sm-offer-billing-address">
            <AddressControl
              key="previousAddress"
              keySuffix="previous"
              labelText="Previous address"
              country={country}
              onAddressSelected={address => onPreviousAddressSelected(index, address)}
              autoPlaceholder="Previous address..."
              autoPropertyAddress={null}
              autoAddressRequired={true}
              manualAddressVisible={true}
              manualSuburbSelectorEnabled={true}
              showAreasSupportedDisclaimer={false}
              large={!isMobile}
              showMinimalManualFields={true}
            />
          </div>
        </Col>

        <Col xs="12">
          <div className="qs-more-info-table-header">I was living at this address for</div>
        </Col>
        <Col xs="12" md="2">
          <FormGroup>
            <AvField
              bsSize="sm"
              name={`residingYears-${index}`}
              placeholder="Years"
              type="select"
              value={item.residingYears}
              onChange={e => setPreviousAddressesResidingYears(index, e.target.value)}
              validate={{
                required: { value: true, errorMessage: 'Years is required' }
              }}
            >
              <option value="">Years</option>
              {Array.from(Array(20).keys()).map((value, i) => (
                <option key={`resyears-${index}-${i}`} value={value}>
                  {value}
                </option>
              ))}

              <option value="20+">20+</option>
            </AvField>
          </FormGroup>
        </Col>
        <Col xs="12" md="2">
          <FormGroup>
            <AvField
              bsSize="sm"
              name={`residingMonths-${index}`}
              placeholder="Months"
              type="select"
              value={item.residingMonths}
              onChange={e => setPreviousAddressesResidingMonths(index, e.target.value)}
              validate={{
                required: { value: true, errorMessage: 'Months is required' }
              }}
            >
              <option value="">Months</option>
              {Array.from(Array(11).keys()).map((value, i) => (
                <option key={`residingmonths-${index}-${i}`} value={value + 1}>
                  {value + 1}
                </option>
              ))}
            </AvField>
          </FormGroup>
        </Col>

        {hasItems(productDetail.residentialStatuses) && (
          <>
            <Col xs="12">
              <div className="qs-more-info-table-header">Residential status</div>
            </Col>
            <Col xs="12" md="6">
              <FormGroup>
                <AvField
                  bsSize="sm"
                  type="select"
                  name={`residentialStatus-${index}`}
                  onChange={e => setPreviousAddressesResidentialStatus(index, e.target.value)}
                  value={item.residentialStatus}
                  validate={{
                    required: { value: true, errorMessage: 'Residential status is required' }
                  }}
                >
                  <option value="">Select residential status</option>
                  {productDetail.residentialStatuses.map((ref, i) => (
                    <option key={`residentialStatus-${ref.id}`} value={ref.id}>
                      {ref.name}
                    </option>
                  ))}
                </AvField>
              </FormGroup>
            </Col>
          </>
        )}
      </>
    ));
  };

  const getInstallDates = () => {
    if (!productDetail.showDeliveryDate) return null;

    let currentDateMoment = moment.tz(productDetail.minSelectableDate, 'YYYY-MM-DD', 'GMT').add(-1, 'days');
    const holidays = productDetail.holidays
      ? productDetail.holidays.map(date => moment.tz(date, 'YYYY-MM-DD', 'GMT').toDate())
      : [];

    let installDates = [];
    for (var i = 0; i < 181; i++) {
      currentDateMoment.add(1, 'days');
      let isoWeekday = currentDateMoment.isoWeekday();

      let currentDate = currentDateMoment.toDate();

      let isHoliday = holidays.find(item => {
        return item.getTime() === currentDate.getTime();
      });

      if (isoWeekday <= 5 && !isHoliday) installDates.push(currentDate);
    }

    return installDates;
  };

  let installDates = getInstallDates();

  const onDeliveryTypeChange = (event, target) => {
    onChange(null, { deliveryType: target, deliveryAddress: null });
  };

  const requireEmployerRegex = /(retire|pension|unemploy)/i;
  const requireEmployer =
    productDetail.showEmploymentDetails &&
    ((confirmDetail.industry && !requireEmployerRegex.test(confirmDetail.industry)) ||
      (confirmDetail.employmentStatus && !requireEmployerRegex.test(confirmDetail.employmentStatus)) ||
      (confirmDetail.occupationType && !requireEmployerRegex.test(confirmDetail.occupationType)));

  const hasItems = array => {
    return array && array.length > 0;
  };

  return (
    <>
      <Row className="qs-more-info-table-header">
        <Col xs="12" md="8">
          {hasItems(productDetail.homeLine) && (
            <InternetAddon
              title="Home line connection"
              addons={productDetail.homeLine}
              offer={offer}
              action="home-line-details-popover"
              onChange={selections => onChange(null, { homeLine: selections })}
              allowMultipleSelections={false}
            />
          )}
          {hasItems(productDetail.modems) && (
            <InternetAddon
              title="Modem"
              addons={productDetail.modems}
              offer={offer}
              action="modem-details-popover"
              onChange={selections => onChange(null, { modem: selections })}
              allowMultipleSelections={false}
            />
          )}
          {hasItems(productDetail.otherAddons) && (
            <InternetAddon
              title="Other addons"
              addons={productDetail.otherAddons}
              offer={offer}
              action="other-addons-details-popover"
              onChange={selections => onChange(null, { otherAddons: selections })}
              allowMultipleSelections={true}
            />
          )}
        </Col>

        {productDetail.showYourOrderSummary && (
          <Col xs="12" md="4">
            <YourOrderInternet productDetail={productDetail} confirmDetail={confirmDetail} />
          </Col>
        )}
      </Row>

      {productDetail.showEmploymentDetails && (
        <>
          <Row className="qs-more-info-table-header">
            <Col>Employment details</Col>
          </Row>

          <Row>
            {hasItems(productDetail.industries) && (
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="industry"
                    onChange={e => onChange(e, { industry: e.target.value })}
                    value={confirmDetail.industry}
                    validate={{
                      required: { value: true, errorMessage: 'Industry is required' }
                    }}
                  >
                    <option value="">Select industry</option>
                    {productDetail.industries.map((ref, i) => (
                      <option key={`industry-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            )}

            {hasItems(productDetail.occupations) && (
              <>
                <Col xs="12" md="6">
                  <FormGroup>
                    <AvField
                      bsSize="sm"
                      type="select"
                      name="occupationType"
                      onChange={e => onChange(e, { occupationType: e.target.value })}
                      value={confirmDetail.occupationType}
                      validate={{
                        required: { value: true, errorMessage: 'Occupation type is required' }
                      }}
                    >
                      <option value="">Select occupation type</option>
                      {productDetail.occupations.map((ref, i) => (
                        <option key={`occupation-${ref.id}`} value={ref.id}>
                          {ref.name}
                        </option>
                      ))}
                    </AvField>
                  </FormGroup>
                </Col>

                <Col xs="12" md="6">
                  <FormGroup>
                    <AvField
                      bsSize="sm"
                      name="occupation"
                      placeholder="Occupation"
                      type="text"
                      value={confirmDetail.occupation}
                      onChange={e => onChange(e, { occupation: e.target.value })}
                      validate={{
                        required: { value: true, errorMessage: 'Occupation is required' }
                      }}
                    />
                  </FormGroup>
                </Col>
              </>
            )}

            {hasItems(productDetail.employmentStatuses) && (
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="employmentStatus"
                    onChange={e => onChange(e, { employmentStatus: e.target.value })}
                    value={confirmDetail.employmentStatus}
                    validate={{
                      required: { value: true, errorMessage: 'Employment status is required' }
                    }}
                  >
                    <option value="">Select employment status</option>
                    {productDetail.employmentStatuses.map((ref, i) => (
                      <option key={`employmentStatus-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            )}

            {requireEmployer && (
              <>
                <Col xs="12" md="6">
                  <FormGroup>
                    <AvField
                      bsSize="sm"
                      name="employer"
                      placeholder="Employer"
                      type="text"
                      value={confirmDetail.employer}
                      onChange={e => onChange(e, { employer: e.target.value })}
                      validate={{
                        required: { value: true, errorMessage: 'Employer is required' }
                      }}
                    />
                  </FormGroup>
                </Col>
                {productDetail.showEmploymentTime && (
                  <>
                    <Col xs="12" md="2">
                      <div className="qs-more-info-table-header">Time at current employer</div>
                    </Col>
                    <Col xs="12" md="2">
                      <FormGroup>
                        <AvField
                          bsSize="sm"
                          name="employmentYears"
                          placeholder="Years"
                          type="select"
                          value={confirmDetail.employmentYears}
                          onChange={e => onChange(e, { employmentYears: e.target.value })}
                          validate={{
                            required: { value: true, errorMessage: 'Years is required' }
                          }}
                        >
                          <option value="">Years</option>
                          {Array.from(Array(20).keys()).map((value, index) => (
                            <option key={`years-${index}`} value={value}>
                              {value}
                            </option>
                          ))}

                          <option value="20+">20+</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="2">
                      <FormGroup>
                        <AvField
                          bsSize="sm"
                          name="employmentMonths"
                          placeholder="Months"
                          type="select"
                          value={confirmDetail.employmentMonths}
                          onChange={e => onChange(e, { employmentMonths: e.target.value })}
                          validate={{
                            required: { value: true, errorMessage: 'Months is required' }
                          }}
                        >
                          <option value="">Months</option>
                          {Array.from(Array(11).keys()).map((value, index) => (
                            <option key={`months-${index}`} value={value + 1}>
                              {value + 1}
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  </>
                )}
              </>
            )}

            {productDetail.showEmployeeHasCreditCard && (
              <Col xs="12" md="6">
                <QsCheckBox
                  isChecked={confirmDetail.employeeHasCreditCard}
                  label="Do you have a credit Card?"
                  onCheck={e => onChange(e, { employeeHasCreditCard: !confirmDetail.employeeHasCreditCard })}
                />
              </Col>
            )}
          </Row>
        </>
      )}

      {(productDetail.showExistingAccount || productDetail.showKeepExistingNumber) && (
        <Row>
          {productDetail.showExistingAccount && (
            <Col xs="12">
              <div className="qs-more-info-table-header">
                Do you already have an account with {offer.providerDisplayCode}?
              </div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.existingAccount}
                onTouchButton={onChangeExistingAccount}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ]}
              />
            </Col>
          )}

          {confirmDetail.existingAccount === 'true' && (
            <Col xs="12" md="6" className="qs-mt-md">
              <FormGroup>
                <AvField
                  bsSize="sm"
                  name="accountNumber"
                  placeholder="Account number"
                  type="text"
                  value={confirmDetail.accountNumber}
                  onChange={e => onChange(e, { accountNumber: e.target.value })}
                  validate={{
                    required: { value: true, errorMessage: 'Existing account number is required' },
                    pattern: {
                      value: '^[0-9a-zA-Z]{3,20}$',
                      errorMessage: `Please enter a valid account number`
                    }
                  }}
                />
              </FormGroup>
            </Col>
          )}

          {productDetail.showKeepExistingNumber && (
            <Col xs="12">
              <div className="qs-more-info-table-header">Do you want to keep your existing phone number?</div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.keepExistingNumber}
                onTouchButton={onChangeKeepExistingNumber}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ]}
              />
            </Col>
          )}

          {confirmDetail.keepExistingNumber === 'true' && (
            <>
              <Col xs="12" md="6" className="qs-mt-md">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    name="existingNumber"
                    placeholder="Home phone number"
                    type="text"
                    value={confirmDetail.existingNumber}
                    onChange={e => onChange(e, { existingNumber: e.target.value })}
                    validate={{
                      required: { value: true, errorMessage: 'Home phone number is required' },
                      pattern: {
                        value: homePhoneNumberRegex,
                        errorMessage: `Please enter a valid home phone number for ${country.name} including area code (no spaces or hyphens)`
                      }
                    }}
                  />
                </FormGroup>
              </Col>

              <Col xs="12" md="6" className="qs-mt-md">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    name="existingNumberAccountNumber"
                    placeholder="Provider account number"
                    type="text"
                    value={confirmDetail.existingNumberAccountNumber}
                    onChange={e => onChange(e, { existingNumberAccountNumber: e.target.value })}
                    validate={{
                      required: { value: true, errorMessage: 'Provider account is required' },
                      pattern: {
                        value: '^[0-9a-zA-Z]{3,20}$',
                        errorMessage: `Please enter a valid account number`
                      }
                    }}
                  />
                </FormGroup>
              </Col>

              <Col xs="12" md="6">
                <QsCheckBox
                  isChecked={confirmDetail.hasHomePhoneTransferAuthority}
                  label="I have authority to transfer this service."
                  onCheck={e =>
                    onChange(e, { hasHomePhoneTransferAuthority: !confirmDetail.hasHomePhoneTransferAuthority })
                  }
                  validatorName="home-phone-transfer-authority"
                  validationMessage="You must have authority to transfer this service."
                />
              </Col>
            </>
          )}
        </Row>
      )}

      {(productDetail.showDeliveryDate ||
        productDetail.residentialStatuses ||
        productDetail.showTimeAtCurrentAddress) && (
        <div>
          {hasItems(productDetail.residentialStatuses) && (
            <>
              <Col xs="12">
                <div className="qs-more-info-table-header">Residential status</div>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="residentialStatus"
                    onChange={e => onChange(e, { residentialStatus: e.target.value })}
                    value={confirmDetail.residentialStatus}
                    validate={{
                      required: { value: true, errorMessage: 'Residential status is required' }
                    }}
                  >
                    <option value="">Select residential status</option>
                    {productDetail.residentialStatuses.map((ref, i) => (
                      <option key={`residentialStatus-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            </>
          )}

          {productDetail.showTimeAtCurrentAddress && (
            <>
              <Col xs="12">
                <div className="qs-more-info-table-header">I have been living at this address for the past</div>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    name="residingYears"
                    placeholder="Years"
                    type="select"
                    value={confirmDetail.residingYears}
                    onChange={e => {
                      onChange(e, { residingYears: e.target.value });
                      adjustPreviousAddresses(
                        confirmDetail.otherPreviousAddresses,
                        e.target.value,
                        confirmDetail.residingMonths
                      );
                    }}
                    validate={{
                      required: { value: true, errorMessage: 'Years is required' }
                    }}
                  >
                    <option value="">Years</option>
                    {Array.from(Array(20).keys()).map((value, i) => (
                      <option key={`resyears-${i}`} value={value}>
                        {value}
                      </option>
                    ))}

                    <option value="20+">20+</option>
                  </AvField>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    name="residingMonths"
                    placeholder="Months"
                    type="select"
                    value={confirmDetail.residingMonths}
                    onChange={e => {
                      onChange(e, { residingMonths: e.target.value });
                      adjustPreviousAddresses(
                        confirmDetail.otherPreviousAddresses,
                        confirmDetail.residingYears,
                        e.target.value
                      );
                    }}
                    validate={{
                      required: { value: true, errorMessage: 'Months is required' }
                    }}
                  >
                    <option value="">Months</option>
                    {Array.from(Array(11).keys()).map((value, i) => (
                      <option key={`residingmonths-${i}`} value={value + 1}>
                        {value + 1}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>

              {getOtherPreviousAddress()}

              {/* {totalResidingYears(0) < 2 && (
                <>
                  <Col xs="12">
                    <div className="sm-offer-billing-address">
                      <AddressControl
                        key="previousAddress"
                        keySuffix="previous"
                        labelText="Previous address"
                        country={country}
                        onAddressSelected={onPreviousAddressSelected}
                        autoPlaceholder="Previous address..."
                        autoPropertyAddress={null}
                        autoAddressRequired={true}
                        manualAddressVisible={true}
                        manualSuburbSelectorEnabled={true}
                        showAreasSupportedDisclaimer={false}
                        large={!isMobile}
                        showMinimalManualFields={true}
                      />
                    </div>
                  </Col>
                </>
              )} */}
            </>
          )}

          {productDetail.showDeliveryDate && (
            <>
              <div className="label">How soon you want your connection to be delivered at your address?</div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.specificDeliveryDate}
                onTouchButton={onChangeSpecificDeliveryDate}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'ASAP',
                    value: 'false'
                  },
                  {
                    label: 'Select date',
                    value: 'true'
                  }
                ]}
              />
            </>
          )}

          {confirmDetail.specificDeliveryDate === 'true' && (
            <Col xs="12" md="6" className="qs-mt-md">
              <DatePicker
                selected={selectedDeliveryDate}
                onChange={onDeliveryDateChange}
                useWeekdaysShort={true}
                dateFormat="dd/MM/yyyy"
                disabled={!installDates || installDates.length === 0}
                includeDates={installDates}
                placeholderText="dd/mm/yyyy"
              />
            </Col>
          )}
        </div>
      )}

      {(hasItems(productDetail.powerSourceTypes) ||
        hasItems(productDetail.buildingHeights) ||
        hasItems(productDetail.roofTypes) ||
        hasItems(productDetail.buildingExternalWallMaterials)) && (
        <>
          <Row className="qs-more-info-table-header">
            <Col>Satellite installation premises</Col>
          </Row>

          <Row>
            {hasItems(productDetail.powerSourceTypes) && (
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="powerSourceType"
                    onChange={e => onChange(e, { powerSourceType: e.target.value })}
                    value={confirmDetail.powerSourceType}
                    validate={{
                      required: { value: true, errorMessage: 'Power source type is required' }
                    }}
                  >
                    <option value="">Select power source type</option>
                    {productDetail.powerSourceTypes.map(ref => (
                      <option key={`powerSourceTypes-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            )}

            {hasItems(productDetail.buildingHeights) && (
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="buildingHeight"
                    onChange={e => onChange(e, { buildingHeight: e.target.value })}
                    value={confirmDetail.buildingHeight}
                    validate={{
                      required: { value: true, errorMessage: 'Building height is required' }
                    }}
                  >
                    <option value="">Select building height</option>
                    {productDetail.buildingHeights.map(ref => (
                      <option key={`buildingHeight-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            )}

            {hasItems(productDetail.roofTypes) && (
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="roofType"
                    onChange={e => onChange(e, { roofType: e.target.value })}
                    value={confirmDetail.roofType}
                    validate={{
                      required: { value: true, errorMessage: 'Roof type is required' }
                    }}
                  >
                    <option value="">Select roof type</option>
                    {productDetail.roofTypes.map(ref => (
                      <option key={`roofTypes-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            )}

            {hasItems(productDetail.buildingExternalWallMaterials) && (
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="buildingExternalWallMaterial"
                    onChange={e => onChange(e, { buildingExternalWallMaterial: e.target.value })}
                    value={confirmDetail.buildingExternalWallMaterial}
                    validate={{
                      required: { value: true, errorMessage: 'Building external wall material is required' }
                    }}
                  >
                    <option value="">Select building external wall material</option>
                    {productDetail.buildingExternalWallMaterials.map(ref => (
                      <option key={`buildingExternalWallMaterials-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            )}
          </Row>
        </>
      )}

      {productDetail.allowDeliveryAddress && (
        <div className="mt-3">
          <div className="label">Where would you like us to deliver your equipment?</div>
          <AvRadioGroup
            className="qs-form-radio"
            name="deliveryType"
            label=""
            required
            value={confirmDetail.deliveryType}
            onChange={onDeliveryTypeChange}
          >
            <AvRadio label="My connection address" value={billingTypes.ADDRESS_CONNECTION.Code} />
            {productDetail.allowDeliveryAddressOtherAddress && (
              <AvRadio
                label="Other address (can't be a Post Box, Locked Bag or RMB)"
                value={billingTypes.ADDRESS_OTHER.Code}
              />
            )}
          </AvRadioGroup>
        </div>
      )}

      {confirmDetail.deliveryType === billingTypes.ADDRESS_OTHER.Code && (
        <div className="sm-offer-billing-address">
          <AddressControl
            key="deliveryAddress"
            keySuffix="delivery"
            labelText="Delivery address (Other)"
            country={country}
            onAddressSelected={address => onChange(null, { deliveryAddress: address })}
            autoPlaceholder="Delivery address..."
            autoPropertyAddress={null}
            autoAddressRequired={true}
            manualAddressVisible={true}
            manualSuburbSelectorEnabled={true}
            showAreasSupportedDisclaimer={false}
            large={!isMobile}
            showMinimalManualFields={true}
          />
        </div>
      )}

      {productDetail.billingContent && (
        <div
          className="mt-3"
          dangerouslySetInnerHTML={{
            __html: productDetail.billingContent
          }}
        />
      )}
    </>
  );
};

export default InternetQuestions;
