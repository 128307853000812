import React from 'react';
import { useSelector } from 'react-redux';

const OrganiserName = ({ nameOnly }) => {
  const { organiserAgentName, organiserName } = useSelector(state => state.content.general);

  const name = nameOnly ? organiserName : organiserAgentName;

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: name
      }}
    />
  );
};
export default OrganiserName;
