import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import { getSessionActivity } from '../../utilities/commonUtilities';
import { exitExternal } from '../../actions/sessionActivityActions';
import ExitLink from '../common/exitLink';
import PoweredBySmartMe from '../common/poweredBySmartMe';
import { getExit } from '../../utilities/billTypeUtilities';

const RedirectDialog = ({ serviceType, hasOffers, supportedBill, onShowOffers, onClose }) => {
  const { session } = useSelector(state => state);
  const { exitCode } = useSelector(state => state.landing.home);
  const { exits } = useSelector(state => state.config.settings);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { showPoweredBy } = useSelector(state => state.config.settings);

  const [seconds, setSeconds] = useState(20);
  const [redirectingText, setRedirectingText] = useState();

  const serviceTypeName = serviceType.Name.toLowerCase();

  const exit = getExit(exitCode, exits, serviceType, agentBillTypes);

  const dispatch = useDispatch();

  useEffect(() => {
    setRedirectingText(`Redirecting you in ${seconds} seconds`);

    if (seconds === 1) {
      const sessionActivity = getSessionActivity(session);
      dispatch(exitExternal(exit.url, { ...sessionActivity, data: { source: 'redirect-dialog-auto' } }));
      return;
    }

    const interval = setInterval(() => {
      setSeconds(seconds => seconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div className="sm-redirect-launch">
      <div className="content">
        {supportedBill ? (
          <>
            <p>We've analysed your {serviceTypeName} bill against plans from our available retailers.</p>
            <p>
              Based on your bill, there are currently no {hasOffers ? 'cheaper' : null} plans from our available
              retailers that we're able to switch you to.
            </p>
          </>
        ) : (
          <>
            <p>We've tried to analyse your {serviceTypeName} bill against plans from our available retailers.</p>
            <p>Unfortunately, we could not extract any information from the file you uploaded.</p>
          </>
        )}
        {exit && (
          <p>
            You may find a better plan with our larger range of retailers at{' '}
            <ExitLink url={exit.url} label={exit.url.replace(/^https?:\/\//, '')} source="redirect-dialog-manual" />
          </p>
        )}
      </div>

      <div className="redirecting-text">{redirectingText}</div>

      <div className="redirect-button">
        {hasOffers && supportedBill ? (
          <Button className="sm-button-primary medium" onClick={onShowOffers}>
            Show deals anyway
          </Button>
        ) : (
          <Button className="sm-button-primary medium" onClick={onClose}>
            Don't redirect
          </Button>
        )}
      </div>
      {showPoweredBy && <PoweredBySmartMe />}
    </div>
  );
};

export default RedirectDialog;
