import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function globalOptionsReducer(state = initialState.globalOptions, action) {
  switch (action.type) {
    case types.SET_GLOBAL_OPTIONS:
      return objectAssign({}, state, action.data);

    default:
      return state;
  }
}
