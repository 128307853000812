import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import { getIcon } from '../../utilities/submissionUtilities';

import { occupancyPurposes, occupancyTypes, solarTypes } from '../../enums/submissionTypes';
import { billTypes } from '../../enums/billTypes';
import { navigationTypes } from '../../enums/navigationTypes';

const SummaryFooter = ({ onButtonClick, onBack, showButton, showBackButton, buttonText, postcode }) => {
  const { submission } = useSelector(state => state.comparison);
  const { pageMode } = useSelector(state => state.application);

  const currentPage = navigationTypes.getCurrentPage();
  const serviceType = submission ? billTypes.getByCode(submission.billType) : billTypes.NONE;
  const supplyAddress = submission && submission.supplyAddress ? submission.supplyAddress : {};
  const propertyAddress = submission && submission.propertyAddress ? submission.propertyAddress : {};
  const distributorName = submission && submission.distributor ? submission.distributor.name : '';
  const occupancyType = submission ? occupancyTypes.getById(submission.occupancyType) : occupancyTypes.NONE;
  const occupancyPurpose = submission ? occupancyPurposes.getById(submission.occupancyPurpose) : occupancyPurposes.NONE;

  const showOccupancyType = false;
  const showOccupancyPurpose = false;

  const solarType =
    submission && submission.hasPremiumSolar
      ? solarTypes.PREMIUM
      : submission && submission.hasSolar
      ? solarTypes.STANDARD
      : solarTypes.NONE;

  const SummaryItem = ({ type, label }) => {
    return (
      <div className="summary-item">
        <div className="icon">{getIcon(type)}</div>
        <div className="label">{label}</div>
      </div>
    );
  };

  const getHeading = () => {
    if (currentPage === navigationTypes.COMPARE)
      return `Showing plans for: ${supplyAddress.displayAddressSimple || postcode || propertyAddress.suburb}`;
    if (currentPage === navigationTypes.SWITCH)
      return `Your application for: ${supplyAddress.displayAddressSimple || propertyAddress.suburb}`;
  };

  return (
    <div className={`sm-estimate-wizard-footer ${pageMode.name.toLowerCase()}`}>
      <div className="heading">{getHeading()}</div>
      <div className="content">
        <div className="items">
          {serviceType && <SummaryItem type={serviceType} label={serviceType.Name} />}
          {showOccupancyType && <SummaryItem type={occupancyType} label={occupancyType.Description} />}
          {serviceType === billTypes.ELECTRICITY && (
            <SummaryItem type={solarType} label={`${solarType !== solarTypes.NONE ? 'Has' : 'No'} solar`} />
          )}
          {showOccupancyPurpose && <SummaryItem type={occupancyPurpose} label={occupancyPurpose.Name} />}
          {distributorName && <SummaryItem type={serviceType} label={distributorName} />}
        </div>
        {(showButton || showBackButton) && (
          <div className="sm-estimate-wizard-button-container rounded">
            {showButton && (
              <Button className="sm-button-primary medium" onClick={onButtonClick}>
                {buttonText}
              </Button>
            )}

            {showBackButton && (
              <Button className="sm-button-primary medium" onClick={onBack}>
                Back to plans
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryFooter;
