import * as types from './actions';
import { setServerMessageClear } from './serverMessageActions';
import { chatBotOptions } from './globalOptionsActions';
import * as serverMessageTargets from '../actions/serverMessageTargets.js';

export function setDialogOptionsSuccess(data) {
  return { type: types.SET_DIALOG_OPTIONS, data };
}

export function setDialogOptions(dialog) {
  return dispatch => {
    dispatch(setDialogOptionsSuccess({ ...dialog }));
  };
}

// --------------------------------------------------------------------------
// Close dialogs / chat action
// --------------------------------------------------------------------------
export function closeDialogs(source) {
  return (dispatch, getState) => {
    const currentState = getState();

    if (currentState && currentState.globalOptions) {
      const { isPinned } = currentState.globalOptions.chatBot;

      if (!isPinned) {
        dispatch(chatBotOptions(false, false));
      }
    }

    dispatch(
      setDialogOptions({
        closeSource: source,
        submit: {
          isOpen: false
        },
        directSwitch: {
          isOpen: false
        },
        getStarted: {
          isOpen: false
        },
        howItWorks: {
          isOpen: false
        },
        switched: {
          isOpen: false
        }
      })
    );
  };
}

// --------------------------------------------------------------------------
// Submit dialog options action
// --------------------------------------------------------------------------
export function openSubmitDialog(serviceType, uploadBillType, uploadSubType, data, serverMessage) {
  return dispatch => {
    dispatch(
      setDialogOptions({
        submit: {
          isOpen: true,
          serviceType,
          uploadBillType,
          uploadSubType,
          data
        }
      })
    );

    if (serverMessage) {
      dispatch(setServerMessageClear(serverMessageTargets.SUBMIT_BILL, serverMessage));
      dispatch(setServerMessageClear(serverMessageTargets.ESTIMATE_DIALOG, serverMessage));
    }
  };
}

// --------------------------------------------------------------------------
// Direct switch dialog options action
// --------------------------------------------------------------------------
export function directSwitchDialogOptions(visible, uploadSubType) {
  return dispatch => {
    dispatch(
      setDialogOptions({
        directSwitch: {
          isOpen: visible ? true : false,
          uploadSubType
        }
      })
    );
  };
}

// --------------------------------------------------------------------------
// Switched dialog options action
// --------------------------------------------------------------------------
export function switchedDialogOptions(visible, serviceType) {
  return dispatch => {
    dispatch(
      setDialogOptions({
        switched: {
          isOpen: visible ? true : false,
          serviceType
        }
      })
    );
  };
}

// --------------------------------------------------------------------------
// Idle timer dialog options action
// --------------------------------------------------------------------------
export function idleTimerDialogVisible(visible) {
  return dispatch => {
    dispatch(
      setDialogOptions({
        idleTimer: { isOpen: visible ? true : false }
      })
    );
  };
}

// --------------------------------------------------------------------------
// How it works dialog options action
// --------------------------------------------------------------------------
export function howItWorksDialogOptions(visible) {
  return dispatch => {
    dispatch(
      setDialogOptions({
        howItWorks: { isOpen: visible ? true : false }
      })
    );
  };
}

// --------------------------------------------------------------------------
// Get started dialog options action
// --------------------------------------------------------------------------
export function getStartedDialogOptions(visible) {
  return dispatch => {
    dispatch(
      setDialogOptions({
        getStarted: { isOpen: visible ? true : false }
      })
    );
  };
}
