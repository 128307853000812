import React from 'react';
import { Modal, ModalBody, ModalFooter, Button, Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

const OfflineApplicationDialog = props => {
  const { onCancel, isOpen, offer, offlineApplication } = props;

  const { general } = useSelector(state => state.content);

  const phoneNumber = offlineApplication ? general[offlineApplication.phoneNumberKey] : null;
  const callCentreHours = offlineApplication ? general[offlineApplication.callCentreHoursKey] : null;

  return (
    <>
      {offer && offlineApplication && (
        <Modal toggle={onCancel} isOpen={isOpen} size="lg" centered={true} backdrop={'static'}>
          <ModalBody className="qs-modal-body">
            <Container>
              <Row>
                <Col xs="5" lg="3">
                  <img
                    className="qs-offline-provider-logo"
                    src={offer.providerLogoUrl}
                    alt={offer.providerDisplayCode}
                  />
                </Col>
                <Col xs="7" lg="5" className="qs-offline-product">
                  {offer.name}
                </Col>
                <Col xs="12" lg="4" className="qs-offline-reference">
                  <div className="qs-offline-reference-heading">Quote your reference number</div>
                  <div className="qs-offline-reference-number">{offlineApplication.quoteId}</div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="qs-mt-md">
                  Please call us on <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>, and quote your reference number
                  above. Our energy experts will be able to complete your application and assist you with any questions
                  you may have.
                </Col>
              </Row>
              {callCentreHours && (
                <Row>
                  <Col xs="12" className="qs-mt-md">
                    <div>Call centre hours</div>
                    <div dangerouslySetInnerHTML={{ __html: callCentreHours }} />
                  </Col>
                </Row>
              )}
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onCancel}>Close</Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default OfflineApplicationDialog;
