import React from 'react';

const GeneralDialogCloseIcon = ({ onClick, disabled }) => {
  return (
    <div className="sm-modal-close">
      <span onClick={onClick} className={`close-button${disabled ? ' disabled' : ''}`}>
        ×
      </span>
    </div>
  );
};

export default GeneralDialogCloseIcon;
