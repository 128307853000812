import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

import { launchDialogModes, pageModes } from '../../enums/launchTypes';
import PoweredBySmartMe from '../common/poweredBySmartMe';
import GeneralDialogHeader from './generalDialogHeader';
import { postFrameDialogMessage } from '../../utilities/domUtilities';
import GeneralDialogCloseIcon from './generalDialogCloseIcon';

const GeneralDialog = ({
  onClose,
  dialogOpen,
  dialogSize = 'md',
  heading,
  headingIcon,
  headingCenter,
  showCloseIcon: userShowCloseIcon,
  confirmButtonText,
  closeButtonText,
  bodyClass,
  footerClass,
  confirmButtonClass,
  closeButtonClass,
  showFooter,
  serviceType,
  launchMode,
  showPoweredBy,
  children
}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const { pageMode, frameHeight, frameHost } = useSelector(state => state.application);

  const onDialogClose = result => {
    if (dialogOpen) {
      if (onClose) onClose(result);
    }
  };

  const handleModal = () => {
    if (pageMode === pageModes.FRAMED) {
      dispatch(postFrameDialogMessage(frameHost, 'General', dialogOpen, ref.current, frameHeight));
    }
  };

  const footer = showFooter === undefined || showFooter === true;
  const showCloseIcon = userShowCloseIcon === undefined || userShowCloseIcon === true;
  const defaultLaunchMode = launchMode === launchDialogModes.DEFAULT;

  return (
    <Modal
      onOpened={handleModal}
      onClosed={handleModal}
      toggle={() => onDialogClose(false)}
      isOpen={dialogOpen}
      size={dialogSize}
      backdrop={'static'}
      centered={pageMode === pageModes.DEFAULT}
      keyboard={defaultLaunchMode}
    >
      <div ref={ref}>
        {heading || headingIcon ? (
          <GeneralDialogHeader
            heading={heading}
            headingIcon={headingIcon}
            headingCenter={headingCenter}
            showCloseIcon={showCloseIcon}
            onDialogClosing={onDialogClose}
            serviceType={serviceType}
          />
        ) : showCloseIcon === false ? (
          <span></span>
        ) : (
          <GeneralDialogCloseIcon onClick={() => onDialogClose(false)} />
        )}

        <ModalBody className={`sm-modal-body ${bodyClass}`}>{children}</ModalBody>
        {footer && (
          <ModalFooter className={`sm-modal-footer ${footerClass}`}>
            {closeButtonText && (
              <Button className={closeButtonClass} onClick={() => onDialogClose(false)}>
                {closeButtonText}
              </Button>
            )}
            {confirmButtonText && (
              <Button className={confirmButtonClass} onClick={() => onDialogClose(true)}>
                {confirmButtonText}
              </Button>
            )}
          </ModalFooter>
        )}
        {showPoweredBy && (
          <div className="sm-general-dialog-powered-by-container">
            <PoweredBySmartMe />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default GeneralDialog;
