export const comparisonSorting = {
  GUARANTEED: { Id: 1, Name: 'Guaranteed' },
  CONDITIONAL: { Id: 2, Name: 'Conditional' }
};

export function getComparisonSortingById(id) {
  var intId = id;
  if (typeof id === 'string') intId = parseInt(id);

  switch (intId) {
    case comparisonSorting.GUARANTEED.Id:
      return comparisonSorting.GUARANTEED;
    case comparisonSorting.CONDITIONAL.Id:
      return comparisonSorting.CONDITIONAL;
    default:
      return null;
  }
}
