import { internetDownloadSpeedTypes } from '../enums/submissionTypes';

const getFilteredInternetOffers = (filter, offers) => {
  if (isSummaryOffersOnly(offers)) return offers;

  var filteredOffers = offers.filter(
    e =>
      (!filter.dataUnlimited || (e.dataLimit && e.dataLimit.toLowerCase() === 'unlimited')) &&
      (filter.downloadSpeed === internetDownloadSpeedTypes.ALL.Id ||
        (filter.downloadSpeed === internetDownloadSpeedTypes.LOW.Id && e.downloadSpeed <= 25) ||
        (filter.downloadSpeed === internetDownloadSpeedTypes.MEDIUM.Id &&
          e.downloadSpeed > 25 &&
          e.downloadSpeed <= 60) ||
        (filter.downloadSpeed === internetDownloadSpeedTypes.HIGH.Id && e.downloadSpeed > 60))
  );

  return filteredOffers;
};

const isSummaryOffersOnly = offers => {
  return offers.every(offer => offer.isSummaryOfferOnly);
};

export { getFilteredInternetOffers, isSummaryOffersOnly };
