import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, FormGroup } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { useSelector, useDispatch } from 'react-redux';
import YourDetails from './yourDetails';
import { validateSsn } from '../../utilities/ssn';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment-timezone';
import QsCheckBox from './qsCheckBox';
import QsButtonGroup from './qsButtonGroup';
import { implementationCodes } from '../../enums/implementationCodes';
import Autocomplete from 'react-autocomplete';
import { submissionTypes, occupancyTypes } from '../../enums/submissionTypes';
import { setSelectedEnergyOfferDetail } from '../../actions/selectedOfferActions';
import axios from 'axios';
import _debounce from 'lodash.debounce';
import BillDisplayAddress from './billDisplayAddress';
import SwitchHoldDialog from '../dialogs/switchHoldDialog';
import OutboundLink from './outboundLink';

const OfferConfirmUs = props => {
  const { confirmDetail, offer, implementationCode, onChange } = props;
  const { submissionType } = useSelector(state => state.comparison);
  const { emailAddress, accountVerified } = useSelector(state => state.user);
  const { comparison } = useSelector(state => state);
  const { occupancyType } = useSelector(state => state.comparison.submission);
  const { submission } = useSelector(state => state.comparison);
  const { mobilePhoneNumberRegex } = useSelector(state => state.config.applicationSettings.country);
  const [selectedInstallDate, setSelectedInstallDate] = useState(null);
  const [addressInputValue, setaddressInputValue] = useState();
  const [addresses, setAddresses] = useState([]);
  const [switchHoldDialogOpen, setSwitchHoldDialogOpen] = useState(false);
  const [switchHold, setSwitchHold] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (confirmDetail.installDate && !selectedInstallDate) {
      var storedDate = moment(confirmDetail.installDate, 'YYYY-MM-DD').toDate();
      setSelectedInstallDate(storedDate);
    }
  }, []);

  const validateSsnInput = (value, ctx, input, cb) => {
    if (validateSsn(value)) {
      cb(true);
    } else {
      cb('Enter a valid social security number.');
    }
  };

  const validateHiddeninstallStandard = (value, ctx, input, cb) => {
    let noInstallDateMessage = `Please select ${
      occupancyType === occupancyTypes.CURRENT.Id ? 'standard switch or select ' : ''
    }a preferred install date.`;

    if (value === 'false' && !confirmDetail.installDate) {
      cb(noInstallDateMessage);
    } else if (value === 'true' && confirmDetail.installDate) {
      cb('Please select either a standard switch or a preferred install date not both.');
    } else cb(true);
  };

  const validateHiddenAddress = (value, ctx, input, cb) => {
    if (switchHold) {
      cb('This address currently has a "Switch Hold"');
    } else cb(true);
  };

  const onInstallDateChange = date => {
    setSelectedInstallDate(date);

    var utcDate = moment(date).format('YYYY-MM-DD');
    onChange(null, { installDate: utcDate });
  };

  const onChangePreferredLanguage = event => {
    let option = event.target.getAttribute('data-value');
    onChange(null, { preferredLanguage: option });
  };

  const onAddressSelected = (inputValue, item) => {
    setaddressInputValue(inputValue);
    onChange(null, { meterIdentifier: item.meterIdentifier, supplyAddressDisplay: item.addressDisplay });

    if (item.switchHold) {
      setSwitchHold(true);
      setSwitchHoldDialogOpen(true);
    } else {
      setSwitchHold(false);
      dispatch(setSelectedEnergyOfferDetail(comparison, offer, item.meterIdentifier));
    }
  };

  const onChangeAddressInput = inputValue => {
    setaddressInputValue(inputValue);
    onChange(null, { meterIdentifier: null, supplyAddressDisplay: null });

    if (inputValue) {
      getAddresses(inputValue);
    }
  };

  const getAddresses = useCallback(
    _debounce(
      searchTerm => {
        axios
          .post(
            `/api/convert/address/accesskey/${comparison.accessId}/?key=${comparison.accessKey}&search=${searchTerm}`,
            {
              searchTerm,
              postcode: submission.propertyAddress.postcode,
              offerId: offer.offerId
            }
          )
          .then(response => {
            if (response.data) {
              setAddresses(response.data);
            }
          });
      },
      150,
      { leading: false }
    ),
    []
  );

  let useInstallDates =
    offer.installDates && offer.installDates.length > 0
      ? offer.installDates
      : offer.productDetail
      ? offer.productDetail.installDates
      : [];
  let installDates = useInstallDates.map((date, i) => moment.tz(date, 'YYYY-MM-DD', 'GMT').toDate());

  return (
    <>
      <Row className="qs-more-info-table-header">
        <Col xs="12">Connection address</Col>
      </Row>

      {submissionType === submissionTypes.BILL && (
        <BillDisplayAddress displayAddress={submission.supplyAddress.displayAddress} />
      )}

      {submissionType === submissionTypes.ESTIMATE && (
        <Row>
          <Col xs="12">
            <Autocomplete
              itemsData={[]}
              getItemValue={item => item.addressDisplay}
              items={addresses}
              renderMenu={(items, value, style) => <div className="qs-suggestions-container" children={items} />}
              renderItem={(item, isHighlighted) => (
                <div
                  key={`qs-address-item${item.meterIdentifier}`}
                  className={isHighlighted ? 'qs-suggestion-active' : 'qs-suggestion'}
                >
                  {item.addressDisplay}
                </div>
              )}
              renderInput={props => <input {...props} className="form-control form-control-sm" />}
              wrapperProps={{ className: 'qs-address-auto-complete' }}
              value={addressInputValue}
              onChange={e => onChangeAddressInput(e.target.value)}
              onSelect={onAddressSelected}
            />
            <AvField
              bsSize="sm"
              type="text"
              name="hiddenAddress"
              value={switchHold}
              className="qs-tc-hidden"
              validate={{ hiddenAddress: validateHiddenAddress }}
            />
            <SwitchHoldDialog
              isOpen={switchHoldDialogOpen}
              onCancel={() => {
                setSwitchHoldDialogOpen(false);
              }}
            />
          </Col>
        </Row>
      )}

      <Row className="qs-more-info-table-header">
        <Col xs="12" md="6">
          Your details
        </Col>
        <Col className="d-none d-md-block" md="6">
          Verify ID
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <YourDetails
            onChange={onChange}
            details={confirmDetail}
            phoneNumberRegex={mobilePhoneNumberRegex}
            emailAddress={emailAddress}
            showMembshipId={offer.membershipId}
            showAlternatePhoneNumber={false}
            showDateOfBirth={false}
            showTitle={true}
            showEmailAddress={true}
            showPhoneNumber={true}
            lockPhoneNumber={accountVerified}
          />
        </Col>
        <Col className="d-block d-md-none qs-more-info-table-header-col" xs="12">
          <div>Social Security Number</div>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <AvField
              bsSize="sm"
              name="ssn"
              placeholder="Social Security Number"
              type="text"
              value={confirmDetail.ssn}
              onChange={e => onChange(e, { ssn: e.target.value })}
              validate={{ ssn: validateSsnInput }}
            />
          </FormGroup>

          <FormGroup>
            <div className="qs-more-info-table-header">Date of birth</div>
            <AvField
              bsSize="sm"
              placeholder="mm/dd/yyyy"
              name="dateOfBirth"
              type="text"
              value={confirmDetail.dateOfBirth}
              onChange={e => onChange(e, { dateOfBirth: e.target.value })}
              validate={{
                required: { value: true, errorMessage: 'Date of birth is required' },
                pattern: {
                  value:
                    '^((((0[13578])|(1[02]))[\\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|11)[\\/](([1-9])|([0-2][0-9])|30))|((2|02)[\\/](([1-9])|([0-2][0-9]))))[/]\\d{4}$',
                  errorMessage: 'Please enter in the format mm/dd/yyyy e.g. 12/31/1986'
                }
              }}
            />
          </FormGroup>

          <div className="qs-more-info-table-header">Preferred language</div>
          <QsButtonGroup
            id="sorter"
            fieldName="Sort"
            selectedValue={confirmDetail.preferredLanguage}
            onTouchButton={onChangePreferredLanguage}
            additionalClass="qs-button-group-field-entry"
            buttons={[
              {
                label: 'English',
                value: 'English'
              },
              {
                label: 'Spanish',
                value: 'Spanish'
              }
            ]}
          />

          {implementationCode === implementationCodes.ELECTRICITY.US_DEFAULT && (
            <FormGroup>
              <div className="qs-more-info-table-header">Service start</div>
              {(occupancyType === occupancyTypes.CURRENT.Id || submissionType === submissionTypes.BILL) && (
                <>
                  <Row>
                    <Col xs="1">
                      <QsCheckBox
                        isChecked={confirmDetail.installStandard}
                        onCheck={e => onChange(e, { installStandard: !confirmDetail.installStandard })}
                      />
                    </Col>
                    <Col xs="10" className="qs-agree-terms-text">
                      <AvField
                        bsSize="sm"
                        type="text"
                        name="hiddeninstallStandard"
                        value={confirmDetail.installStandard}
                        className="qs-tc-hidden"
                        validate={{ validateHiddeninstallStandard }}
                      />
                      Standard switch (1-7 days)
                    </Col>
                  </Row>
                  <div className="qs-install-date-sub-heading">or select a date (more than 7 days)</div>
                </>
              )}

              <DatePicker
                selected={selectedInstallDate}
                onChange={onInstallDateChange}
                useWeekdaysShort={true}
                dateFormat="MM/dd/yyyy"
                includeDates={installDates}
                placeholderText="mm/dd/yyyy"
              />

              <p className="qs-utility-connection-fee-disclaimer qs-mt-sm">
                {occupancyType === occupancyTypes.NEW.Id && (
                  <div>
                    <AvField
                      bsSize="sm"
                      type="text"
                      name="hiddeninstallStandard"
                      value={confirmDetail.installStandard + ''}
                      className="qs-tc-hidden"
                      validate={{ validateHiddeninstallStandard }}
                    />
                  </div>
                )}
                <strong>Note:</strong> Your local utility may charge a one-time{' '}
                <OutboundLink
                  eventLabel="utility-connection-fees"
                  to={offer.connectionFeeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  connection fee
                </OutboundLink>
                .
              </p>
            </FormGroup>
          )}
        </Col>
      </Row>
    </>
  );
};

export default OfferConfirmUs;
