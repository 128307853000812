import * as types from './actions';
import axios from 'axios';
import timeAxios from '../utilities/timeAxios';
import { apiTiming } from '../utilities/googleAnalyticsEvents';
import { getAttemptCount } from '../utilities/commonUtilities';
import { sleep } from '../utilities/compareUtilities';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import { setServerMessageClear } from './serverMessageActions';
import { setUserBill } from './comparisonActions';
import { contextTypes } from '../enums/submissionTypes';
import { comparisonResponseStatus } from '../enums/comparisonResponseStatus';
import { billTypes } from '../enums/billTypes';

export function setComparisonSuccess(comparison) {
  return { type: types.SET_COMPARISON, comparison };
}

// --------------------------------------------------------------------------
// Submit bill (un-authenticated) action
// --------------------------------------------------------------------------
export function submitBillUnauthenticated(data, onComplete, onError, onVerify, serviceType) {
  const start = Date.now();

  const polling = (dispatch, responseData, start) => {
    dispatch(getComparisonUnauthenticated({ ...data, ...responseData }, start, onComplete, onError, onVerify));
  };

  return submitBill('', data.bill, start, polling, onError, onVerify, serviceType);
}

// ----------------------------------------------------------------------------
// Submit bill (with token) action
// ----------------------------------------------------------------------------
export function submitBillWithToken(accessToken, data, onComplete, onError, onVerify, serviceType) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  const path = `/token/submit`;
  const start = Date.now();

  const polling = (dispatch, responseData, start, onVerify) => {
    dispatch(getComparisonWithToken({ ...data, ...responseData }, start, onComplete, onError, onVerify, config));
  };

  return submitBill(path, data.bill, start, polling, onError, onVerify, serviceType, config);
}

// --------------------------------------------------------------------------
// Submit bill (with key) action
// --------------------------------------------------------------------------
export function submitBillWithKey(data, onComplete, onError, onVerify, serviceType) {
  const path = `/${data.access.id}/?key=${data.access.key}`;
  const start = Date.now();

  const polling = (dispatch, responseData, start, onVerify) => {
    // if the access key has been cleared in the response, we need to treat as unauthenticated (customer has changed from original access key)
    if (responseData.accessKey) {
      dispatch(getComparisonWithKey({ ...data, ...responseData }, start, onComplete, onError, onVerify));
    } else {
      dispatch(getComparisonUnauthenticated({ ...data, ...responseData }, start, onComplete, onError, onVerify));
    }
  };

  return submitBill(path, data.bill, start, polling, onError, onVerify, serviceType);
}

// --------------------------------------------------------------------------
// Submit bill action
// --------------------------------------------------------------------------
function submitBill(path, bill, start, polling, onError, onVerify, serviceType, config) {
  return dispatch => {
    dispatch(setServerMessageClear());

    dispatch(setAjaxCallStart(contextTypes.BILL_COMPARISON, serviceType));

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'submit', 'bill');
    });

    axios
      .post(`/api/compare/bill${path}`, bill, config)
      .then(response => {
        if (response.data) {
          dispatch(setComparisonAccessKey(response.data));

          polling(dispatch, response.data, start, onVerify);
        }
      })
      .catch(error => {
        if (onError) onError(error);
        dispatch(setAjaxCallEnd());
      });
  };
}

// --------------------------------------------------------------------------
// Get bill comparison with an access token issued by an external authentication provider.
// --------------------------------------------------------------------------
export function pollComparisonWithToken(accessToken, requestKey, onComplete, onError, onVerify) {
  return dispatch => {
    dispatch(setAjaxCallStart(contextTypes.BILL_COMPARISON));

    const path = `/token/?token=${encodeURIComponent(requestKey)}`;
    const start = Date.now();

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    dispatch(getComparison(path, start, onComplete, onError, onVerify, config));
  };
}

// --------------------------------------------------------------------------
// Get bill comparison (with token) action
// --------------------------------------------------------------------------
function getComparisonWithToken(data, start, onComplete, onError, onVerify, config) {
  const path = `/token?token=${data.requestKey}`;

  return getComparison(path, start, onComplete, onError, onVerify, config);
}

// --------------------------------------------------------------------------
// Get bill comparison (with key) action
// --------------------------------------------------------------------------
function getComparisonWithKey(data, start, onComplete, onError, onVerify) {
  const path = `/${data.access.id}/${data.requestKey}?key=${data.access.key}`;

  return getComparison(path, start, onComplete, onError, onVerify);
}

// --------------------------------------------------------------------------
// Get bill comparison (un-authenticated) action
// --------------------------------------------------------------------------
function getComparisonUnauthenticated(data, start, onComplete, onError, onVerify) {
  const path = `?token=${data.requestKey}`;

  return getComparison(path, start, onComplete, onError, onVerify);
}

// --------------------------------------------------------------------------
// Get bill comparison action
// --------------------------------------------------------------------------
function getComparison(path, start, onComplete, onError, onVerify, config) {
  return dispatch => {
    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'get', 'comparison');
    });

    let timer = 0;
    let attempts = 0;
    const attemptIntervals = [3000, 1000];
    const attemptLimit = getAttemptCount(attemptIntervals, 120);

    let fn = () => {
      axios
        .get(`/api/compare/bill${path}`, config)
        .then(response => {
          const data = response.data;

          if (data) {
            clearTimeout(timer);
            timer = 0;

            sleep(start, 'Bill Polling').then(() => {
              dispatch(setComparisonAccessKey(data));

              if (data.comparisonResponseStatus === comparisonResponseStatus.SUCCESS) {
                dispatch(setUserBill(data));
                dispatch(setComparison(data));
              } else if (data.comparisonResponseStatus === comparisonResponseStatus.VERIFY) {
                const serviceType =
                  data.verifyData && data.verifyData.billType
                    ? billTypes.getByCode(data.verifyData.billType)
                    : billTypes.NONE;

                onVerify(serviceType, data.verifyData);
              }

              dispatch(setAjaxCallEnd());

              if (onComplete) {
                if (data.comparisonResponseStatus === comparisonResponseStatus.SUCCESS) {
                  onComplete(
                    { hasOffers: data.comparison.hasOffers, hasBetterOffers: data.comparison.hasBetterOffers },
                    data.userBill
                  );
                }
              }
            });
          }
        })
        .catch(error => {
          const notReady = error.response.status === 404;

          if (notReady && attempts < attemptLimit) {
            const maxIntervals = attemptIntervals.length - 1;
            const interval = attempts <= maxIntervals ? attemptIntervals[attempts] : attemptIntervals[maxIntervals];
            attempts++;
            clearTimeout(timer);
            timer = setTimeout(fn, interval);
          } else {
            clearTimeout(timer);
            timer = 0;
            if (onError) onError(error);
            dispatch(setAjaxCallEnd());
          }
        });
    };

    fn();
  };
}

// --------------------------------------------------------------------------
// Set comparison access key action
// --------------------------------------------------------------------------
function setComparisonAccessKey(data) {
  const accessId = data.accessKey ? data.accessKey.accessId : null;
  const accessKey = data.accessKey ? data.accessKey.accessKey : null;
  const accessTrustLevel = data.accessKey ? data.accessKey.accessTrustLevel : null;

  return dispatch =>
    dispatch(
      setComparisonSuccess({
        accessId,
        accessKey,
        accessTrustLevel
      })
    );
}

// --------------------------------------------------------------------------
// Set comparison action
// --------------------------------------------------------------------------
function setComparison(data) {
  return dispatch => {
    const userBill = data.userBill;
    const comparison = data.comparison;
    const hasOffers = comparison.hasOffers;

    // Leave previous comparison offers there if there are no offers on the current comparison; so that we don't have a empty screen on the compare page...
    if (!hasOffers) {
      delete comparison.offers;
    }

    dispatch(
      setComparisonSuccess({
        ...comparison,
        submissionId: userBill.submissionId,
        sorting: data.sorting,
        isLoaded: true
      })
    );
  };
}
