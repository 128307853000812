const validateAbn = abn => {
  var isValid = true;

  //remove all spaces
  abn = abn.replace(/\s/g, '');

  //0. ABN must be 11 digits long
  isValid &= abn && /^\d{11}$/.test(abn);

  if (isValid) {
    var weightedSum = 0;
    var weight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

    //Rules: 1,2,3
    for (var i = 0; i < weight.length; i++) {
      weightedSum += (parseInt(abn[i]) - (i === 0 ? 1 : 0)) * weight[i];
    }

    //Rules: 4,5
    isValid &= weightedSum % 89 === 0;
  }

  return isValid;
};

export { validateAbn };
