import environmentConfiguration from '../store/environmentConfig';
import { environmentNames } from '../enums/environmentNames';

let validateSsn = function(ssn) {
  // find area number (1st 3 digits, no longer actually signifies area)
  var area = parseInt(ssn.substring(0, 3));

  return (
    // 9 characters
    ssn.length === 9 &&
    // basic regex, note: doesn't allow 900-999 numbers, structured so it looks like an SSN
    ssn.match(/^[0-8]{1}[0-9]{2}[0-9]{2}[0-9]{4}/) &&
    // disallow Satan's minions from becoming residents of the US
    (area !== 666 || environmentConfiguration.environmentName !== environmentNames.PRODUCTION) &&
    // it's not triple nil
    area !== 0 &&
    // fun fact: some idiot boss put his secretary's ssn in wallets
    // he sold, now it "belongs" to 40000 people
    ssn !== '078051120' &&
    // was used in an ad by the Social Security Administration
    ssn !== '219099999'
  );
};

export { validateSsn };
