import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import * as actions from '../../actions/registrationActions';
import * as serverMessageActions from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import ServerMessage from '../common/serverMessage';
import { useAuth0 } from '../../auth/auth0-Provider.js';
import EmailAddress from '../common/emailAddress';
import { buttonTypes } from '../../enums/buttonTypes';

const RegistrationDialog = props => {
  const [dialogOpen, setDialogOpen] = useState(props.dialogOpenState);
  const [emailAddress, setEmailAddress] = useState(props.registration.emailAddress);
  const [mobilePhoneNumber, setmobilePhoneNumber] = useState(props.registration.mobilePhoneNumber);

  const { isUserAuthenticated, loading } = useAuth0();
  const { REGISTRATION_INIT: target } = serverMessageTargets;
  const { initComplete } = props.registration;
  const { showRegisterButton, config } = props;

  const onDialogToggle = event => {
    event.preventDefault();

    if (!dialogOpen) {
      if (target === props.serverMessage.target) {
        props.serverMessageActions.setServerMessageClear(target);
      }
      props.actions.resetRegistration();
      setEmailAddress(null);
      setmobilePhoneNumber(null);
    }

    setDialogOpen(!dialogOpen);
  };

  const onRegisterSubmit = () => {
    props.actions.initRegistration(emailAddress, mobilePhoneNumber);
  };

  return (
    !loading &&
    !isUserAuthenticated() && (
      <>
        {showRegisterButton && (
          <>
            {props.buttonType === buttonTypes.LINK ? (
              <Button className={props.buttonType.cssClass} color="link" onClick={onDialogToggle}>
                {props.buttonText || 'Register'}
              </Button>
            ) : (
              <Button className="qs-button-submit-secondary" onClick={onDialogToggle}>
                {props.buttonText || 'Register'}
              </Button>
            )}
          </>
        )}

        <Modal toggle={onDialogToggle} isOpen={dialogOpen} size="lg" centered={true} backdrop={'static'}>
          <AvForm onValidSubmit={onRegisterSubmit}>
            <ModalBody className="qs-modal-body">
              <Container>
                <Row>
                  <Col xs="10" className="qs-modal-heading">
                    <h3>
                      {props.mode === 'changeNumber'
                        ? `Update ${props.config.programName} Registration`
                        : `${props.config.programName} Registration`}
                    </h3>
                  </Col>
                  <Col xs="2">
                    <div onClick={onDialogToggle} className="qs-modal-close-icon">
                      <i className="material-icons">close</i>
                    </div>
                  </Col>
                </Row>

                {!initComplete && (
                  <Row>
                    <Col xs="12">
                      {props.mode === 'changeNumber' ? (
                        <>
                          <p>
                            So you don't remember or have access to your old phone number. Simply provide your email
                            address and new mobile phone number.
                          </p>
                          <p>A secure link will be sent to this email address so you can update your registration.</p>
                        </>
                      ) : (
                        <>
                          <p>
                            OK, let's get you registered. All we need is your email address and mobile phone number.
                          </p>
                          <p>A secure link will be sent to this email address so you can complete your registration.</p>
                        </>
                      )}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <ServerMessage serverMessage={props.serverMessage} target={target} />
                  </Col>
                </Row>
                {!initComplete && (
                  <>
                    <Row className="qs-modal-body qs-mt-lg">
                      <Col md="6">
                        <EmailAddress
                          value={emailAddress}
                          onChange={value => setEmailAddress(value)}
                          forceRequireEmailAddress={true}
                        />
                      </Col>
                    </Row>
                    <Row className="qs-modal-body">
                      <Col md="6">
                        <AvField
                          bsSize="sm"
                          name="phoneNumber"
                          placeholder="Mobile phone number"
                          type="text"
                          value={mobilePhoneNumber}
                          onChange={e => setmobilePhoneNumber(e.target.value)}
                          validate={{
                            required: { value: true, errorMessage: 'Mobile phone number is required' },
                            pattern: {
                              value: config.applicationSettings.country.mobilePhoneNumberRegex,
                              errorMessage: `Please enter a valid  mobile phone number for ${config.country.name} (no spaces or hyphens)`
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </ModalBody>
            <ModalFooter>
              {!initComplete && (
                <Button className="qs-button-submit" style={{ width: 'auto' }}>
                  {props.mode === 'changeNumber' ? 'Update' : 'Register'}
                </Button>
              )}
              <Button onClick={onDialogToggle}>Close</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </>
    )
  );
};

const mapStateToProps = state => {
  return {
    registration: state.registration,
    config: state.config,
    serverMessage: state.serverMessage
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    serverMessageActions: bindActionCreators(serverMessageActions, dispatch)
  };
}

RegistrationDialog.propTypes = {
  registration: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationDialog);
