import React from 'react';
import { AvField } from 'availity-reactstrap-validation';
import { useSelector } from 'react-redux';
import { Container, Row, Col, FormGroup } from 'reactstrap';
import { validateMedicareNumber } from '../../utilities/medicare';
import { idTypesEnum } from '../../enums/idTypesEnum';

const VerifyIdAuSingle = props => {
  const {
    onChange,
    onShowQuickTip,
    idKey,
    fieldSuffix,
    acceptDriversLicence,
    acceptDriversLicenceRules,
    acceptPassport,
    acceptForeignPassport,
    acceptMedicare,
    acceptDateOfBirth,
    details: {
      verifyMethod,
      verifyId,
      medicareIrn,
      passportCountry,
      medicareCardType,
      verifyIdExpiry,
      dateOfBirth,
      driversLicenceStateOfIssue,
      driversLicenceCardNumber
    }
  } = props;
  const { medicareCardTypes, countries, states, idTypes } = useSelector(state => state.config.referenceData);

  const validateMedicareNumberInput = (value, ctx, input, cb) => {
    if (validateMedicareNumber(value, false)) {
      cb(true);
    } else {
      cb('Enter a valid medicare number.');
    }
  };

  const getAvailableIdTypes = idTypes => {
    return idTypes.filter(
      item =>
        (item.id === idTypesEnum.MEDICARE.Id && acceptMedicare) ||
        (item.id === idTypesEnum.PASSPORT.Id && acceptPassport) ||
        (item.id === idTypesEnum.FOREIGN_PASSPORT.Id && acceptForeignPassport) ||
        (item.id === idTypesEnum.DRIVERS_LICENCE.Id && acceptDriversLicence)
    );
  };

  var verifyIdPlaceholder, verifyIdValidate;
  var verifyIdXsColSize = '12';
  var verifyAdditionalXsColSize = '12';
  var medicareIrnVisible = false;
  var driversLicenceStateOfIssueVisible = false;
  var driversLicenceCardNumberVisible = false;
  var driversLicenceCardNumberValidate = null;
  var passportCountryVisible = false;
  var idExpiryDisplayFormat = 'dd/mm/yyyy';
  var idExpiryValidation =
    '^(?:(?:(?:0[1-9]|1\\d|2[0-8])\\/(?:0[1-9]|1[0-2])|(?:29|30)/(?:0[13-9]|1[0-2])|31\\/(?:0[13578]|1[02]))\\/[1-9]\\d{3}|29\\/02(?:\\/[1-9]\\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00))$';

  switch (verifyMethod) {
    case idTypesEnum.DRIVERS_LICENCE.Id.toString():
      verifyIdPlaceholder = 'Licence no.';
      verifyIdValidate = {
        required: { value: true, errorMessage: "Driver's licence number is required" },
        minLength: { value: 6, errorMessage: "Driver's licence number is not the required length" },
        maxLength: { value: 9, errorMessage: 'Drivers licence number should be 9 characters or less' },
        pattern: {
          value: '^[0-9a-zA-Z]{6,9}$',
          errorMessage: "Driver's licence number should contain only letters and numbers"
        }
      };

      verifyIdXsColSize = '7';
      verifyAdditionalXsColSize = '5';
      driversLicenceStateOfIssueVisible = true;

      if (!acceptDriversLicenceRules) driversLicenceCardNumberVisible = false;
      else if (driversLicenceStateOfIssue) {
        var stateRule = acceptDriversLicenceRules.find(w => {
          return w.stateId === driversLicenceStateOfIssue;
        });

        if (stateRule) {
          driversLicenceCardNumberVisible = stateRule.acceptDriversLicenceCardNumber;

          var errorCommon =
            'Note: this is not your licence number, it is the card issue number. See our quick tips for help on the location on your licence';

          driversLicenceCardNumberValidate = {
            required: {
              value: true,
              errorMessage: `Card number is required. ${errorCommon}`
            },
            pattern: {
              value: stateRule.driversLicenceCardNumberRegex,
              errorMessage: `Please enter a valid card number. ${errorCommon}`
            }
          };
        }
      }

      break;
    case idTypesEnum.MEDICARE.Id.toString():
      verifyIdPlaceholder = 'Medicare number';
      verifyIdValidate = { verifyId: validateMedicareNumberInput };

      verifyIdXsColSize = '9';
      verifyAdditionalXsColSize = '3';

      if (medicareCardType === '1') {
        idExpiryDisplayFormat = 'mm/yy';
        idExpiryValidation = '^(0?[1-9]|1[0-2])/(([0-9]{2}))$';
      }

      medicareIrnVisible = true;
      break;
    case idTypesEnum.PASSPORT.Id.toString():
    case idTypesEnum.FOREIGN_PASSPORT.Id.toString():
      verifyIdPlaceholder = 'Passport no.';
      verifyIdValidate = {
        required: { value: true, errorMessage: 'Passport number is required' },
        minLength: { value: 6, errorMessage: 'Passport number is not the required length' },
        maxLength: { value: 20, errorMessage: 'Passport number should be 20 characters or less' }
      };

      verifyIdXsColSize = '6';
      verifyAdditionalXsColSize = '6';
      passportCountryVisible = verifyMethod === idTypesEnum.FOREIGN_PASSPORT.Id.toString();

      break;

    default:
      verifyIdPlaceholder = 'ID number';
      verifyIdValidate = {
        required: { value: true, errorMessage: 'ID number is required' },
        minLength: { value: 6, errorMessage: 'ID number is not the required length' },
        maxLength: { value: 20, errorMessage: 'ID number should be 20 characters or less' }
      };
      break;
  }

  return (
    <div key={idKey}>
      <FormGroup>
        <AvField
          bsSize="sm"
          type="select"
          name={`verifyMethod${fieldSuffix}`}
          onChange={e => onChange(e, { [`verifyMethod${fieldSuffix}`]: e.target.value })}
          value={verifyMethod}
          validate={{
            required: { value: true, errorMessage: 'Verify method is required' }
          }}
        >
          <option value="">Select verify method</option>
          {getAvailableIdTypes(idTypes).map((ref, i) => (
            <option key={`idtype-${ref.id}`} value={ref.id}>
              {ref.description}
            </option>
          ))}
        </AvField>
      </FormGroup>

      <Container className="qs-container-nopadormargin">
        <Row>
          <Col xs={verifyIdXsColSize}>
            <AvField
              bsSize="sm"
              placeholder={verifyIdPlaceholder}
              name={`verifyId${fieldSuffix}`}
              type="text"
              value={verifyId}
              onChange={e => onChange(e, { [`verifyId${fieldSuffix}`]: e.target.value })}
              validate={verifyIdValidate}
            />
          </Col>

          {medicareIrnVisible ? (
            <Col xs={verifyAdditionalXsColSize}>
              <AvField
                bsSize="sm"
                placeholder="IRN"
                name={`medicareIrn${fieldSuffix}`}
                type="text"
                value={medicareIrn}
                onChange={e => onChange(e, { [`medicareIrn${fieldSuffix}`]: e.target.value })}
                validate={{
                  required: { value: true, errorMessage: 'Medicare IRN is required' },
                  pattern: {
                    value: '^[1-9]$',
                    errorMessage: 'Medicare IRN must be a value from 1 to 9'
                  }
                }}
              />
            </Col>
          ) : (
            ''
          )}

          {driversLicenceStateOfIssueVisible ? (
            <Col xs={verifyAdditionalXsColSize}>
              <AvField
                bsSize="sm"
                type="select"
                name={`driversLicenceStateOfIssue${fieldSuffix}`}
                onChange={e =>
                  onChange(e, {
                    [`driversLicenceStateOfIssue${fieldSuffix}`]: e.target.value ? parseInt(e.target.value) : null
                  })
                }
                value={driversLicenceStateOfIssue}
                validate={{
                  required: { value: true, errorMessage: 'State of issue is required' }
                }}
              >
                <option value="">State</option>
                {states.map((ref, i) => (
                  <option key={`state-${ref.id}`} value={ref.id}>
                    {ref.description}
                  </option>
                ))}
              </AvField>
            </Col>
          ) : (
            ''
          )}

          {driversLicenceCardNumberVisible && (
            <Col xs={verifyIdXsColSize}>
              <div style={{ display: 'flex', alignItems: 'left', width: '100%' }}>
                <div style={{ flexGrow: '1' }}>
                  <AvField
                    bsSize="sm"
                    placeholder="Card number"
                    name={`driversLicenceCardNumber${fieldSuffix}`}
                    type="text"
                    value={driversLicenceCardNumber}
                    onChange={e => onChange(e, { [`driversLicenceCardNumber${fieldSuffix}`]: e.target.value })}
                    validate={driversLicenceCardNumberValidate}
                  />
                </div>
                <i
                  id={`licenceCardNumberInfo-${idKey}`}
                  className={`material-icons qs-info animated heartBeat delay-1s qs-ml-sm`}
                  onClick={onShowQuickTip}
                >
                  info
                </i>
              </div>
            </Col>
          )}

          {passportCountryVisible ? (
            <Col xs={verifyAdditionalXsColSize}>
              <AvField
                bsSize="sm"
                type="select"
                name={`passportCountry${fieldSuffix}`}
                onChange={e => onChange(e, { [`passportCountry${fieldSuffix}`]: e.target.value })}
                value={passportCountry}
                validate={{
                  required: { value: true, errorMessage: 'Passport country is required' }
                }}
              >
                <option value="">Country</option>
                {countries
                  .filter(item => item.alpha3 !== 'AUS')
                  .map((ref, i) => (
                    <option key={`country-${ref.alpha3}`} value={ref.alpha3}>
                      {ref.name}
                    </option>
                  ))}
              </AvField>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Container>

      {verifyMethod === '2' ? (
        <FormGroup>
          <AvField
            bsSize="sm"
            name={`medicareCardType${fieldSuffix}`}
            placeholder="Medicare card colour"
            type="select"
            value={medicareCardType}
            onChange={e => onChange(e, { [`medicareCardType${fieldSuffix}`]: e.target.value })}
            validate={{
              required: { value: true, errorMessage: 'Medicare card colour is required' }
            }}
          >
            <option value="">Medicare card colour</option>
            {medicareCardTypes.map((ref, i) => (
              <option key={`medicareCardType-${ref.id}`} value={ref.id}>
                {ref.description}
              </option>
            ))}
          </AvField>
        </FormGroup>
      ) : (
        ''
      )}
      <FormGroup>
        <AvField
          bsSize="sm"
          placeholder={`Expires ${idExpiryDisplayFormat}`}
          name={`verifyIdExpiry${fieldSuffix}`}
          type="text"
          value={verifyIdExpiry}
          onChange={e => onChange(e, { [`verifyIdExpiry${fieldSuffix}`]: e.target.value })}
          validate={{
            required: { value: true, errorMessage: 'Verify ID expiry date is required' },
            pattern: {
              value: idExpiryValidation,
              errorMessage: `Verify ID expiry date must be in the format ${idExpiryDisplayFormat}`
            }
          }}
        />
      </FormGroup>

      {acceptDateOfBirth && (
        <FormGroup>
          <div className="qs-more-info-table-header">Date of birth</div>
          <AvField
            bsSize="sm"
            placeholder="dd/mm/yyyy"
            name={`dateOfBirth${fieldSuffix}`}
            type="text"
            value={dateOfBirth}
            onChange={e => onChange(e, { [`dateOfBirth${fieldSuffix}`]: e.target.value })}
            validate={{
              required: { value: true, errorMessage: 'Date of birth is required' },
              pattern: {
                value:
                  '^(?:(?:(?:0[1-9]|1\\d|2[0-8])\\/(?:0[1-9]|1[0-2])|(?:29|30)/(?:0[13-9]|1[0-2])|31\\/(?:0[13578]|1[02]))\\/[1-9]\\d{3}|29\\/02(?:\\/[1-9]\\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00))$',
                errorMessage: 'Please enter in the format dd/mm/yyyy e.g. 09/04/1986'
              }
            }}
          />
        </FormGroup>
      )}
    </div>
  );
};

export default VerifyIdAuSingle;
