import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function serverMessageReducer(state = initialState.serverMessage, action) {
  switch (action.type) {
    case types.SET_SERVER_MESSAGE:
      return objectAssign({}, state, action.serverMessage);

    default:
      return state;
  }
}
