import React from 'react';
import VerifyIdAuSingle from './verifyIdAuSingle';
import { idTypesEnum } from '../../enums/idTypesEnum';

const VerifyIdAu = props => {
  const {
    onChange,
    onShowQuickTip,
    acceptPassport,
    acceptForeignPassport,
    acceptMedicare,
    acceptDriversLicenceRules,
    captureSecondary,
    acceptPassportSecondary,
    acceptForeignPassportSecondary,
    acceptMedicareSecondary,
    acceptDateOfBirth,
    details: {
      verifyMethod,
      verifyId,
      medicareIrn,
      passportCountry,
      medicareCardType,
      verifyIdExpiry,
      dateOfBirth,
      driversLicenceStateOfIssue,
      driversLicenceCardNumber,
      verifyMethodSecondary,
      verifyIdSecondary,
      medicareIrnSecondary,
      passportCountrySecondary,
      medicareCardTypeSecondary,
      verifyIdExpirySecondary,
      driversLicenceStateOfIssueSecondary,
      driversLicenceCardNumberSecondary
    }
  } = props;

  const acceptDateOfBirthPrimary = acceptDateOfBirth;
  const acceptDateOfBirthSecondary = false;

  const acceptDriversLicencePrimaryCurrent = captureSecondary
    ? // eslint-disable-next-line eqeqeq
      verifyMethodSecondary != idTypesEnum.DRIVERS_LICENCE.Id
    : true;

  const acceptPassportPrimaryCurrent =
    acceptPassport &&
    (captureSecondary
      ? !verifyMethodSecondary ||
        // eslint-disable-next-line eqeqeq
        verifyMethodSecondary != idTypesEnum.PASSPORT.Id
      : true);

  const acceptForeignPassportPrimaryCurrent =
    acceptForeignPassport &&
    (captureSecondary
      ? !verifyMethodSecondary ||
        // eslint-disable-next-line eqeqeq
        verifyMethodSecondary != idTypesEnum.FOREIGN_PASSPORT.Id
      : true);

  const acceptMedicarePrimaryCurrent =
    // eslint-disable-next-line eqeqeq
    acceptMedicare && (captureSecondary ? verifyMethodSecondary != idTypesEnum.MEDICARE.Id : true);

  const acceptDriversLicenceSecondaryCurrent = captureSecondary
    ? // eslint-disable-next-line eqeqeq
      verifyMethod != idTypesEnum.DRIVERS_LICENCE.Id
    : false;
  const acceptPassportSecondaryCurrent =
    acceptPassportSecondary &&
    (captureSecondary
      ? !verifyMethod ||
        // eslint-disable-next-line eqeqeq
        verifyMethod != idTypesEnum.PASSPORT.Id
      : false);

  const acceptForeignPassportSecondaryCurrent =
    acceptForeignPassportSecondary &&
    (captureSecondary
      ? !verifyMethod ||
        // eslint-disable-next-line eqeqeq
        verifyMethod != idTypesEnum.FOREIGN_PASSPORT.Id
      : false);
  const acceptMedicareSecondaryCurrent = captureSecondary
    ? // eslint-disable-next-line eqeqeq
      acceptMedicareSecondary && verifyMethod != idTypesEnum.MEDICARE.Id
    : false;

  return (
    <>
      <VerifyIdAuSingle
        onChange={onChange}
        onShowQuickTip={onShowQuickTip}
        key="primary"
        fieldSuffix=""
        acceptDriversLicence={acceptDriversLicencePrimaryCurrent}
        acceptDriversLicenceRules={acceptDriversLicenceRules}
        acceptPassport={acceptPassportPrimaryCurrent}
        acceptForeignPassport={acceptForeignPassportPrimaryCurrent}
        acceptMedicare={acceptMedicarePrimaryCurrent}
        acceptDateOfBirth={acceptDateOfBirthPrimary}
        details={{
          verifyMethod,
          verifyId,
          medicareIrn,
          passportCountry,
          medicareCardType,
          verifyIdExpiry,
          dateOfBirth,
          driversLicenceStateOfIssue,
          driversLicenceCardNumber
        }}
      />

      {captureSecondary && (
        <>
          <div className="qs-more-info-table-header-col">Secondary ID</div>
          <VerifyIdAuSingle
            onChange={onChange}
            onShowQuickTip={onShowQuickTip}
            key="secondary"
            fieldSuffix="Secondary"
            acceptDriversLicence={acceptDriversLicenceSecondaryCurrent}
            acceptDriversLicenceRules={acceptDriversLicenceRules}
            acceptPassport={acceptPassportSecondaryCurrent}
            acceptForeignPassport={acceptForeignPassportSecondaryCurrent}
            acceptMedicare={acceptMedicareSecondaryCurrent}
            acceptDateOfBirth={acceptDateOfBirthSecondary}
            details={{
              verifyMethod: verifyMethodSecondary,
              verifyId: verifyIdSecondary,
              medicareIrn: medicareIrnSecondary,
              passportCountry: passportCountrySecondary,
              medicareCardType: medicareCardTypeSecondary,
              verifyIdExpiry: verifyIdExpirySecondary,
              driversLicenceStateOfIssue: driversLicenceStateOfIssueSecondary,
              driversLicenceCardNumber: driversLicenceCardNumberSecondary
            }}
          />
        </>
      )}
    </>
  );
};

export default VerifyIdAu;
