import React, { useRef } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';

import { hasValidPostcodeAddress } from '../../utilities/addressUtilities';
import PostcodeSelector from '../common/postcodeSelector';
import SuburbSelector from '../common/suburbSelector';

const AddressPostcodeSelector = ({ selectedAddress, onChange, onNext }) => {
  const formRef = useRef(null);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const hasSelectedpostcode = hasValidPostcodeAddress(selectedAddress);

  return (
    <AvForm ref={formRef} onValidSubmit={onNext}>
      <div className="address-selector postcode">
        <div className="address">
          <PostcodeSelector
            propertyAddress={selectedAddress}
            onChange={onChange}
            validate={false}
            placeholder="Enter postcode"
          />
          {/* <SuburbSelector propertyAddress={selectedAddress} onChange={onChange} /> */}
        </div>

        <div className="sm-estimate-wizard-button-container">
          <Button
            className="sm-button-primary go-button static medium"
            disabled={!hasSelectedpostcode}
            onClick={hasSelectedpostcode ? handleSubmit : undefined}
          >
            Next
          </Button>
        </div>
      </div>
    </AvForm>
  );
};
export default AddressPostcodeSelector;
