const serverMessageHasField = (response, field) => {
  return (
    response &&
    response.data &&
    response.data.messages &&
    response.data.messages.some(value => {
      return value.field === field;
    })
  );
};

const getServerMessageFirstError = response => {
  return response && response.data && response.data.messages && response.data.messages[0]
    ? response.data.messages[0]
    : { field: 'Application Error', message: 'Unexpected application error' };
};

export { serverMessageHasField, getServerMessageFirstError };
