import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AvForm } from 'availity-reactstrap-validation';
import QsCheckBox from '../common/qsCheckBox';
import { Row, Col, Modal, ModalBody, ModalFooter, Button, Badge } from 'reactstrap';

const ConciergeProvidersDialog = props => {
  const { enabled: userEnabled, billTypeId, providerCodes, updateExcludedProviders } = props;

  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { panelProviders } = useSelector(state => state.config);

  const [dialogOpen, setDialogOpen] = useState(props.dialogOpenState);
  const [userProviders, setUserProviders] = useState([]);

  const enabled = userEnabled === undefined || userEnabled === true;

  // --------------------------------------------------------------------------
  // Initialize provider codes.
  // --------------------------------------------------------------------------
  useEffect(() => {
    // Provider codes do not initialize properly from the useState() arg, so update local state when the source data changes.
    setUserProviders(providerCodes ? [...providerCodes] : []);
  }, [providerCodes]);

  const onDialogToggle = () => {
    setDialogOpen(!dialogOpen);
  };

  const onSave = () => {
    updateExcludedProviders(userProviders);
    onDialogToggle();
  };

  const onCancel = () => {
    setUserProviders(providerCodes); // Restore provider codes from initial data.
    onDialogToggle();
  };

  const providers = panelProviders.filter(p => p.provider.billTypes.filter(bt => bt.id === billTypeId).length > 0);

  const userProviderSelected = provider => {
    return userProviders.length > 0 && userProviders.find(p => p === provider.code) ? true : false;
  };

  const onChangeProvider = provider => {
    const array = [...userProviders];

    const index = array.findIndex(i => i === provider.code);

    if (index >= 0) {
      array.splice(index, 1);
    } else {
      array.push(provider.code);
    }

    setUserProviders(array);
  };

  return (
    <>
      <Button outline disabled={!enabled} onClick={enabled ? onDialogToggle : () => {}}>
        Exclusions <Badge color="secondary">{providerCodes ? providerCodes.length : 0}</Badge>
      </Button>

      <Modal toggle={onDialogToggle} isOpen={dialogOpen} size="md" centered={true} backdrop={'static'}>
        <AvForm>
          <ModalBody className="qs-modal-body">
            <Row>
              <Col xs="10" className="qs-modal-heading">
                <h1>Exclude these providers</h1>
              </Col>
              <Col xs="2">
                <div onClick={onCancel} className="qs-modal-close-icon">
                  <i className="material-icons">close</i>
                </div>
              </Col>
            </Row>

            {providers &&
              providers.length > 0 &&
              providers.map((item, index) => (
                <>
                  <Row className="qs-concierge-providers-dialog-row">
                    <Col xs={{ size: 4, offset: 2 }}>
                      {item.provider.logo && (
                        <img
                          key={`provider-logo-${index}`}
                          height="50px"
                          src={`${staticContentUrl}/images/providers/${item.provider.logo}`}
                          alt={item.provider.displayCode}
                        />
                      )}
                    </Col>
                    <Col className="qs-concierge-providers-dialog-checkbox">
                      <QsCheckBox
                        key={`provider-checkBox-${index}`}
                        label={item.provider.displayCode}
                        isChecked={userProviderSelected(item.provider)}
                        onCheck={() => onChangeProvider(item.provider)}
                      />
                    </Col>
                  </Row>
                </>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onCancel}>Cancel</Button>
            <Button className="qs-concierge-providers-dialog-button-save" onClick={onSave}>
              Confirm
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </>
  );
};

export default ConciergeProvidersDialog;
