import ReactGA from 'react-ga';
import qs from 'query-string';

let getTrackerNames = function(googleAnalyticsClientCode) {
  return googleAnalyticsClientCode ? ['agent'] : undefined;
};

let pageChangedFromCurrent = function(settings, gtmInitialised) {
  pageChanged(window.location.pathname, window.location.search, document.title, settings, gtmInitialised);
};

let pageChanged = function(pathname, search, title, settings, gtmInitialised) {
  let locationSearch = qs.parse(search, { decode: true });
  let queryKey = locationSearch.key;
  let searchToUse = queryKey ? search.replace(queryKey, 'redacted') : search;

  locationSearch = qs.parse(search, { decode: false });
  queryKey = locationSearch.key;
  searchToUse = queryKey ? search.replace(queryKey, 'redacted') : search;

  ReactGA.pageview(pathname + searchToUse, getTrackerNames(settings.googleAnalyticsClientCode));

  if (gtmInitialised && settings && settings.tagManagerPush && settings.tagManagerPushVariables) {
    let dataLayerName = settings.tagManagerDataLayerName ? settings.tagManagerDataLayerName : 'dataLayer';

    let pushObj = {};
    let entries = Object.entries(settings.tagManagerPushVariables);
    for (var [prop, value] of entries) {
      value = value.replace('{{title}}', title.toLowerCase());
      pushObj[prop] = value;
    }

    window[dataLayerName].push(pushObj);
  }
};

let apiTiming = function(timeInMs, variable, label) {
  ReactGA.timing({
    category: 'scanner-api',
    variable: variable,
    label: label,
    value: timeInMs
  });
};

let providerPlan = offer => {
  return `${offer.providerDisplayCode}-${offer.name}`;
};

let openOrClosed = openState => {
  return openState ? 'open' : 'close';
};

let openCloseAction = (action, openState) => {
  return `${action}-${openOrClosed(openState)}`;
};

let getCategory = () => {
  let category = window.location.pathname.substring(1);
  return category === '' ? 'home' : category;
};

let offerEvents = (action, offer, googleAnalyticsClientCode, openState) => {
  ReactGA.event(
    {
      category: getCategory(),
      action: openState === undefined ? action : openCloseAction(action, openState),
      label: providerPlan(offer)
    },
    getTrackerNames(googleAnalyticsClientCode)
  );
};

let comparisonEvents = (action, comparison, googleAnalyticsClientCode, openState) => {
  const label = `${comparison.submissionType}-${comparison.submission.billType}`;

  ReactGA.event(
    {
      category: getCategory(),
      action: openState === undefined ? action : openCloseAction(action, openState),
      label: label
    },
    getTrackerNames(googleAnalyticsClientCode)
  );
};

let standardEvents = (category, action, label, googleAnalyticsClientCode) => {
  ReactGA.event(
    {
      category: category ? category : getCategory(),
      action: action,
      label: label
    },
    getTrackerNames(googleAnalyticsClientCode)
  );
};

export {
  getCategory,
  getTrackerNames,
  pageChangedFromCurrent,
  pageChanged,
  apiTiming,
  offerEvents,
  comparisonEvents,
  standardEvents
};
