import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

const SwitchHoldDialog = props => {
  const { onCancel, isOpen } = props;

  return (
    <>
      <Modal toggle={onCancel} isOpen={isOpen} size="lg" centered={true} backdrop={'static'}>
        <ModalBody className="qs-modal-body">
          <div>
            <h3>Switch hold</h3>

            <p>
              Unfortunately we are currently unable to provide our services for this address as a "Switch Hold" is
              preventing us from being able to change your provider.
            </p>

            <p>
              A Switch Hold can be placed on a premise by either a Retail Energy Provider (REP) (with customer's
              consent) for an outstanding bill or by the Transmission and Distribution Service Provider (TDSP/TDU, or
              utility company) due to meter tampering.
            </p>

            <p>
              A Switch Hold is placed on a residential service address to ensure that the person responsible for the
              billing at that location pays their electricity bill to their current Retail Electricity Provider (REP, or
              electricity company) before switching to a new REP.
            </p>

            <p>
              In general, a Switch Hold is a response to a customer who doesn't pay a past-due bill for a given service
              address to the old Retail Electricity Provider (REP, or electricity company) as a way to prevent that
              customer from switching to another electricity provider.
            </p>

            <p>
              If you are a new resident moving in and have never lived at the premise where a switch hold exists and are
              not associated with the customer for which the switch hold was placed then you will need to provide at
              least one of the following documents to a provider to have them request that the Switch Hold be removed on
              your behalf.
              <ul>
                <li>Copy of Signed Lease</li>
                <li>Affidavit of the landlord</li>
                <li>Closing documents</li>
                <li>Certificate of occupancy</li>
                <li>Utility bill in Customer’s name dated within the last two months from a different premise</li>
              </ul>
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SwitchHoldDialog;
