import React from 'react';
import { useSelector } from 'react-redux';
import QsCheckBox from './qsCheckBox';
import { offerEvents } from '../../utilities/googleAnalyticsEvents';
import TermsAndConditionsDialog from '../dialogs/termsAndConditionsDialog';
import PrivacyPolicyDialog from '../dialogs/privacyPolicyDialog';
import ProgramAbstractDialog from '../dialogs/programAbstractDialog';
import CommissionTermsDialog from '../dialogs/commissionTermsDialog';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';

const SmartMeTermsAndConditions = ({ onChange, termsAndConditions, offer, serviceType }) => {
  const { programName, billTypes: agentBillTypes } = useSelector(state => state.config);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { conversionAcceptTermsAndConditionsPreamble } = useSelector(state => state.content.general);

  const onDisplayTCs = () => {
    offerEvents('tandc', offer, googleAnalyticsClientCode);
  };

  const onDisplayPrivacyPolicy = () => {
    offerEvents('privacy', offer, googleAnalyticsClientCode);
  };

  const onDisplayCustomerCommissionCardTerms = () => {
    offerEvents('customer-commission', offer, googleAnalyticsClientCode);
  };

  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
  const showCustomerCommissionTerms = offer.isSponsored || agentServiceType.hasCommission;

  const label = (
    <>
      {conversionAcceptTermsAndConditionsPreamble}{' '}
      <TermsAndConditionsDialog buttonText="terms and conditions" onOpen={onDisplayTCs} />
      {showCustomerCommissionTerms && (
        <>
          ,{' '}
          <CommissionTermsDialog
            dialogKey="offer_switch"
            serviceType={serviceType}
            providerCode={offer.providerCode}
            onOpen={onDisplayCustomerCommissionCardTerms}
          />
        </>
      )}{' '}
      and <PrivacyPolicyDialog buttonText="privacy policy" onOpen={onDisplayPrivacyPolicy} /> including the use of a{' '}
      {programName} <ProgramAbstractDialog buttonText="account." />
      {offer.additionalSmartMeTerms && (
        <>
          {' '}
          <span
            dangerouslySetInnerHTML={{
              __html: offer.additionalSmartMeTerms
            }}
          ></span>
        </>
      )}
    </>
  );

  return (
    <div>
      <QsCheckBox
        isChecked={termsAndConditions}
        onCheck={e => onChange(e, { termsAndConditions: !termsAndConditions })}
        label={label}
        validatorName="smartme-terms"
        validationMessage="Please accept the terms and conditions"
      />
    </div>
  );
};

export default SmartMeTermsAndConditions;
