import { useSelector } from 'react-redux';
import { AccordionItem as Item } from '@szhsin/react-accordion';
import chevronDown from './chevron-down.svg';
import './styles.scss';

const AccordionItem = ({ section, onShowQuickTips, onToggle, ...rest }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const hasQuickTips = onShowQuickTips !== undefined;

  const getValidationStateClassName = () => {
    const validationState = section.validationState;
    const validationOptions = section.validationOptions;

    if (
      validationState.isValid === true &&
      (!validationOptions.requireOpened || validationState.hasOpened === true) &&
      (!validationOptions.requireVerification || validationState.hasVerified === true)
    )
      return '-valid';
    if (validationState.isValid === false) return '-invalid';

    if (validationOptions.requireOpened && validationState.hasOpened === false) return '-unopened';
    if (validationOptions.requireVerification && validationState.hasVerified === false) return '-unverified';

    return '';
  };

  return (
    <Item
      {...rest}
      id={`section_${section.id}`}
      itemKey={section.key}
      className={`section${getValidationStateClassName()}`}
      buttonProps={{ onClick: onToggle ? () => onToggle(section) : () => {} }}
      header={
        <>
          <div className="item-header">{section.label}</div>

          <div className="quick-tips-icon">
            {hasQuickTips && (
              <img
                src={`${staticContentUrl}/images/misc/quick-tips.png`}
                alt="quick-tips"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onShowQuickTips(section);
                }}
              />
            )}
          </div>

          <img className={`chevron-down${hasQuickTips ? ' with-tips' : ''}`} src={chevronDown} alt="Chevron Down" />
        </>
      }
    />
  );
};

export default AccordionItem;
