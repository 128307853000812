import React from 'react';
import DatePicker from 'react-datepicker';
import { Button } from 'reactstrap';

const NewConnectionSelector = ({ selectedOccupancyDate, installDates, onOccupancyDateSelected, onNext }) => {
  return (
    <div className="new-connection-selector">
      <div className="date-picker">
        <DatePicker
          selected={selectedOccupancyDate}
          onChange={date => onOccupancyDateSelected(date)}
          disabled={!installDates || installDates.length === 0}
          useWeekdaysShort={true}
          dateFormat="dd/MM/yyyy"
          includeDates={installDates || []}
          placeholderText="dd/mm/yyyy"
          popperPlacement="left"
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [-30, 0]
              }
            },
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: false,
                altAxis: true
              }
            }
          ]}
        />
      </div>

      <div className="sm-estimate-wizard-button-container">
        <Button
          className="sm-button-primary go-button static medium"
          disabled={!selectedOccupancyDate}
          onClick={selectedOccupancyDate ? onNext : undefined}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default NewConnectionSelector;
