import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import { useAuth0 } from '../../auth/auth0-Provider.js';

import { uploadBillTypes, uploadSubTypes } from '../../enums/uploadBillTypes';
import { implementationCodes } from '../../enums/implementationCodes';
import {
  occupancyTypes,
  internetConnectionTypeTypes,
  usageTypes,
  solarInstallTypes
} from '../../enums/submissionTypes';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { billTypes } from '../../enums/billTypes';
import { launchDialogModes } from '../../enums/launchTypes';

import * as serverMessageTargets from '../../actions/serverMessageTargets';
import {
  setServerMessageClear,
  sessionExpiredServerMessage,
  comparisonUnsupportedMessage,
  verifyMessage
} from '../../actions/serverMessageActions';
import { deleteUserBillSuccess, goDashboard } from '../../actions/comparisonActions';
import { closeDialogs, switchedDialogOptions } from '../../actions/dialogOptionsActions';
import { deleteServiceCard } from '../../actions/serviceCardActions';

import ServerMessage from './serverMessage';
import SubmitBillDefaultUx2 from './submitBillDefaultUx2';
import ForwardBill from './forwardBill';
import CdrSelector from './cdrSelector.js';

import CardImageSplit from '../CardImageSplit/CardImageSplit';
import UploadBillTypeButton from '../UploadOptions/uploadBillTypeButton';
import EnergyEstimateAu from '../dialogs/energyEstimateAu';
import EnergyEstimateUs from '../dialogs/energyEstimateUs';
import EnergyBill from '../dialogs/energyBill';
import HomeLoanDetailsAu from '../dialogs/homeLoanDetailsAu';
import AffiliateReferral from '../dialogs/affiliateReferral';
import PartnerComparison from '../dialogs/partnerComparison.js';
import HomeLoanAu from '../dialogs/homeLoanAu';
import SolarFinanceAu from '../dialogs/solarFinanceAu.js';
import InternetAu from '../dialogs/internetAu';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getSessionActivity } from '../../utilities/commonUtilities.js';
import { exitExternal } from '../../actions/sessionActivityActions.js';
import { agentHasEnergy } from '../../utilities/billTypeUtilities.js';

const SubmitBillSelectorUx2 = ({
  onSuccess,
  dialogUploadBillType,
  dialogUploadSubType,
  dialogLaunchMode,
  serviceType,
  submissionId,
  submission,
  showBackButton,
  showCancelButton,
  confirmButtonText,
  onVerify,
  onUploadBillTypeChanged
}) => {
  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { session, serverMessage } = useSelector(state => state);
  const { billTypes: agentBillTypes, programDisplayName, defaultOccupancyPurpose, country } = useSelector(
    state => state.config
  );

  const { hasEstimates, movingHouseEnabled, cdrEnabled, googleAnalyticsClientCode } = useSelector(
    state => state.config.settings
  );
  const { data: userBills } = useSelector(state => state.userBills);
  const { occupancyType } = useSelector(state => state.landing.comparison);

  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
  const agentBillType = agentServiceType.agentBillType;

  const savingIncentive = billTypes.getSavingIncentive(staticContentUrl, null, agentBillType, { programDisplayName });

  const uploadBillType = dialogUploadBillType ? dialogUploadBillType : uploadBillTypes.NONE;
  const uploadSubType = dialogUploadSubType ? dialogUploadSubType : uploadSubTypes.NONE;
  const launchMode = dialogLaunchMode || launchDialogModes.DEFAULT;
  const isDefaultComparison = submission ? submission.defaultComparison : false;
  const cardSplitBackground =
    isDefaultComparison || !agentBillType
      ? `${staticContentUrl}/images/misc/default-comparison-selector-default-background.jpg`
      : savingIncentive.backgroundUrl;
  const isEnergy = serviceType === billTypes.ELECTRICITY || serviceType === billTypes.GAS;

  const [selectedUploadBillType, setSelectedUploadBillType] = useState(uploadBillType);
  const [selectedUploadSubType, setSelectedUploadSubType] = useState(uploadSubType);
  const [selectorVisible, setSelectorVisible] = useState(uploadBillType === uploadBillTypes.NONE);

  const [isVerify, setIsVerify] = useState(false);
  const [verifyData, setVerifyData] = useState();

  const allowMovingHouse =
    movingHouseEnabled &&
    agentHasEnergy(agentBillTypes) &&
    billTypes
      .getServices(agentBillTypes, [billTypes.ELECTRICITY, billTypes.GAS, billTypes.INTERNET])
      .find(e => e === serviceType);

  const target = serverMessageTargets.SUBMIT_BILL;

  // ----------------------------------------------------------------------------
  // UploadBillType Select
  // ----------------------------------------------------------------------------
  const onUploadBillTypeSelect = (uploadBillType, uploadSubType) => {
    let hasChanges = false;

    if (selectedUploadBillType !== uploadBillType) {
      setSelectedUploadBillType(uploadBillType);
      hasChanges = true;
    }
    if (selectedUploadSubType !== uploadSubType) {
      setSelectedUploadSubType(uploadSubType);
      hasChanges = true;
    }

    if (hasChanges && onUploadBillTypeChanged) {
      onUploadBillTypeChanged(uploadBillType, uploadSubType);
    }

    setSelectorVisible(false);

    standardEvents('Service-Card-Selector', serviceType.Name, uploadBillType.GaName, googleAnalyticsClientCode);
  };

  // ----------------------------------------------------------------------------
  // Get User Bill
  // ----------------------------------------------------------------------------
  const getUserBill = () => {
    return userBills.find(e => e.submissionId === submissionId);
  };

  // ----------------------------------------------------------------------------
  // Back button click
  // ----------------------------------------------------------------------------
  const onBackButtonClick = () => {
    if (selectedUploadSubType === uploadSubTypes.BILL_UPLOAD_ESTIMATE) {
      onUploadBillTypeSelect(uploadBillTypes.WEBSITE, uploadSubTypes.NONE);
    } else if (selectedUploadSubType === uploadSubTypes.ESTIMATE_UPLOAD) {
      onUploadBillTypeSelect(uploadBillTypes.MANUAL, uploadSubTypes.NONE);
    } else {
      onUploadBillTypeSelect(uploadBillTypes.NONE, uploadSubTypes.NONE);
      setSelectorVisible(true);
    }
    dispatch(setServerMessageClear(target, serverMessage));

    standardEvents(
      'Service-Card-Selector',
      serviceType.Name,
      `${selectedUploadBillType.GaName}-Back`,
      googleAnalyticsClientCode
    );
  };

  // ----------------------------------------------------------------------------
  // Estimate button click
  // ----------------------------------------------------------------------------
  const onEstimateButtonClick = () => {
    onUploadBillTypeSelect(uploadBillTypes.MANUAL, uploadSubTypes.BILL_UPLOAD_ESTIMATE);
    dispatch(setServerMessageClear(target, serverMessage));

    standardEvents(
      'Upload-Bill-Dialog',
      serviceType.Name,
      `${selectedUploadBillType.GaName}-Estimate`,
      googleAnalyticsClientCode
    );
  };

  // ----------------------------------------------------------------------------
  // Redirect button click
  // ----------------------------------------------------------------------------
  const onRedirectButtonClick = (e, redirectUrl) => {
    const sessionActivity = getSessionActivity(session);
    e.preventDefault();
    dispatch(exitExternal(redirectUrl, { ...sessionActivity, data: { source: 'exit-bill-dialog' } }));

    standardEvents(
      'Upload-Bill-Dialog',
      serviceType.Name,
      `${selectedUploadBillType.GaName}-Exit`,
      googleAnalyticsClientCode
    );
  };

  // --------------------------------------------------------------------------
  // Cancel Estimate button click
  // --------------------------------------------------------------------------
  const onCancelButtonClick = event => {
    event.preventDefault();

    dispatch(closeDialogs('submitBillSelector:onCancelButtonClick'));
    dispatch(goDashboard());
  };

  // ----------------------------------------------------------------------------
  // Delete complete
  // ----------------------------------------------------------------------------
  const onDeleteComplete = (data, comparisonReady) => {
    dispatch(
      deleteUserBillSuccess({
        ...data
      })
    );

    if (comparisonReady) {
      dispatch(push('/compare'));
      onSuccess();
    }
  };

  // ----------------------------------------------------------------------------
  // Estimate complete
  // ----------------------------------------------------------------------------
  const onEstimateComplete = args => {
    // There will not be a submissionId if this is a new estimate (e.g. via 'Add Another' or 'Add New')
    // Ensure that this is a new estimate before deleting. It may be an anonymous estimate that is just being
    // run under a real email address now; we do not want to delete that. It will have the same submissionId in that case.
    if (args.estimateWasRun) {
      if (submissionId && (!args.userBill || args.userBill.submissionId !== submissionId)) {
        // A new estimate has completed - delete the current one.
        const userBill = getUserBill();

        const comparisonReady = args.hasOffers;

        dispatch(deleteServiceCard(comparisonReady, userBill, isUserAuthenticated(), getAccessToken, onDeleteComplete));
      }

      onSuccess();

      if (args.referenceNumber) {
        dispatch(switchedDialogOptions(true, serviceType));
      } else if (args.hasOffers) {
        dispatch(push('/compare'));
      }
    } else {
      onSuccess();
    }
  };

  // ----------------------------------------------------------------------------
  // Bill complete
  // ----------------------------------------------------------------------------
  const onBillComplete = () => {
    if (!isDefaultComparison || !submissionId) {
      return;
    }

    // A bill comparison has completed - delete the default estimate it was invoked from.
    const userBill = getUserBill();
    const target = serverMessageTargets.SUBMIT_BILL;

    if (!userBill) {
      dispatch(sessionExpiredServerMessage(target));
      return;
    }

    // Determines if the comparison results are displayed and the dialog closes at the end; in this case it always does - onBillComplete is only called when hasOffers = true.
    const comparisonReady = true;

    dispatch(deleteServiceCard(comparisonReady, userBill, isUserAuthenticated(), getAccessToken, onDeleteComplete));
  };

  // ----------------------------------------------------------------------------
  // Get service options
  // ----------------------------------------------------------------------------
  const getOptions = () => {
    if (serviceType.Id === billTypes.HOME_LOAN.Id) {
      return (
        <>
          <UploadBillTypeButton
            enabled={serviceType.ComparisonImplemented}
            uploadBillType={uploadBillTypes.MANUAL}
            uploadSubType={uploadSubTypes.REFINANCE}
            onClick={onUploadBillTypeSelect}
            isPrimary={true}
          />
          <UploadBillTypeButton
            enabled={serviceType.ComparisonImplemented}
            uploadBillType={uploadBillTypes.MANUAL}
            uploadSubType={uploadSubTypes.NEW_LOAN}
            onClick={onUploadBillTypeSelect}
          />
        </>
      );
    }

    return (
      <>
        {!isDefaultComparison && (
          <>
            <UploadBillTypeButton
              enabled={serviceType.ComparisonImplemented}
              uploadBillType={uploadBillTypes.WEBSITE}
              uploadSubType={uploadSubTypes.NONE}
              onClick={onUploadBillTypeSelect}
              isPrimary={true}
            />
            {cdrEnabled && isEnergy && (
              <UploadBillTypeButton
                enabled={true}
                uploadBillType={uploadBillTypes.CDR}
                uploadSubType={uploadSubTypes.NONE}
                onClick={onUploadBillTypeSelect}
              />
            )}
            <UploadBillTypeButton
              enabled={serviceType.ComparisonImplemented}
              uploadBillType={uploadBillTypes.EMAIL}
              uploadSubType={uploadSubTypes.NONE}
              onClick={onUploadBillTypeSelect}
            />
            {hasEstimates && (
              <UploadBillTypeButton
                enabled={serviceType.ComparisonImplemented}
                uploadBillType={uploadBillTypes.MANUAL}
                uploadSubType={uploadSubTypes.NONE}
                onClick={onUploadBillTypeSelect}
              />
            )}
            {allowMovingHouse && (
              <UploadBillTypeButton
                enabled={true}
                uploadBillType={uploadBillTypes.MOVING_HOUSE}
                uploadSubType={uploadSubTypes.NONE}
                onClick={onUploadBillTypeSelect}
              />
            )}
          </>
        )}

        {isDefaultComparison && (
          <div className="sm-upload-option-default">
            <div>
              <Button
                disabled={!serviceType.ComparisonImplemented}
                className={`sm-button-primary right-arrow-small`}
                onClick={() => onUploadBillTypeSelect(uploadBillTypes.WEBSITE, uploadSubTypes.NONE)}
              >
                {uploadBillTypes.WEBSITE.ButtonText}
              </Button>
            </div>
            <div>
              <Button
                className="sm-link-button dim"
                onClick={() => onUploadBillTypeSelect(uploadBillTypes.MANUAL, uploadSubTypes.DEFAULT_COMPARISON)}
              >
                I don't have an e-bill
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };

  // --------------------------------------------------------------------------
  // Handle verify response.
  // --------------------------------------------------------------------------
  const onVerifyResponse = (verifyServiceType, verifyData) => {
    setVerifyData(verifyData);
    setIsVerify(true);

    // We don't have a starting service type nor do we know the service type after verify as no data was extracted.
    if (serviceType === billTypes.NONE && verifyServiceType === billTypes.NONE && !verifyData) {
      dispatch(comparisonUnsupportedMessage(target));
    } else {
      dispatch(verifyMessage(serverMessageTargets.ESTIMATE_DIALOG));
      onUploadBillTypeSelect(uploadBillTypes.MANUAL);

      if (verifyServiceType !== billTypes.NONE) {
        onVerify(verifyServiceType, verifyData);
      }
    }
  };

  // ----------------------------------------------------------------------------
  // Get service uploader
  // ----------------------------------------------------------------------------
  const getUploader = () => {
    let serviceTypeId = serviceType.Id;

    // If we are moving house and the service type is not energy, override the implementationCode to ELECTRICITY / GAS,
    // so we are operatin with an energy estimate dialog; the initial service type is still maintained.
    if (
      selectedUploadBillType === uploadBillTypes.MOVING_HOUSE &&
      serviceType !== billTypes.ELECTRICITY &&
      serviceType !== billTypes.GAS
    ) {
      const hasElectricity = billTypes.hasServiceType(agentBillTypes, billTypes.ELECTRICITY);
      const hasGas = billTypes.hasServiceType(agentBillTypes, billTypes.GAS);

      serviceTypeId = hasElectricity ? billTypes.ELECTRICITY.Id : hasGas ? billTypes.GAS.Id : serviceType.Id;
    }

    const implementationCode = implementationCodes.get(country.alpha3, serviceTypeId);

    // Ignore showBackButton prop and always show back button (if we are coming from Edit estimate where openDialog() showBackButton is set to false);
    // unless we have no service type (possible from a /launch/bill entry that's missing serviceType query string arg)
    const _showBackButton =
      showBackButton ||
      (serviceType !== billTypes.NONE &&
        (selectedUploadSubType === uploadSubTypes.ESTIMATE_UPLOAD ||
          selectedUploadSubType === uploadSubTypes.BILL_UPLOAD_ESTIMATE));

    switch (selectedUploadBillType.Id) {
      case uploadBillTypes.WEBSITE.Id:
        return (
          <SubmitBillDefaultUx2
            onSuccess={onBillComplete}
            onBackButtonClick={onBackButtonClick}
            onEstimateButtonClick={onEstimateButtonClick}
            onRedirectButtonClick={onRedirectButtonClick}
            showBackButton={_showBackButton}
            confirmButtonText={confirmButtonText}
            serviceType={serviceType}
            launchMode={launchMode}
            onVerify={onVerifyResponse}
          />
        );

      case uploadBillTypes.CDR.Id:
        return <CdrSelector onBackButtonClick={onBackButtonClick} launchMode={launchMode} />;

      case uploadBillTypes.EMAIL.Id:
        return <ForwardBill onBackButtonClick={onBackButtonClick} launchMode={launchMode} />;

      case uploadBillTypes.MANUAL.Id:
      case uploadBillTypes.MOVING_HOUSE.Id:
        let newSubmission;

        switch (implementationCode) {
          case implementationCodes.ELECTRICITY.US_DEFAULT:
            if (verifyData) {
              newSubmission = {
                billType: verifyData.billType ? verifyData.billType : serviceType.Code,
                occupancyPurpose: verifyData.occupancyPurpose ? verifyData.occupancyPurpose : defaultOccupancyPurpose,
                occupancyType: occupancyTypes.CURRENT.Id,
                propertyAddress: verifyData.supplyAddress ? verifyData.supplyAddress : null,
                distributor: verifyData.distributor,
                recentBill: true,
                peakUsage: verifyData.peakUsage,
                meterIdentifier: verifyData.meterIdentifier
              };
              // No data extracted from bill at all
            } else if (isVerify) {
              newSubmission = {
                billType: billTypes.ELECTRICITY.Code,
                recentBill: true
              };
            } else {
              newSubmission = {
                billType: serviceType.Code,
                occupancyPurpose: defaultOccupancyPurpose,
                occupancyType: occupancyType ? occupancyType : occupancyTypes.CURRENT.Id,
                propertyAddress: null,
                distributor: null,
                recentBill: false,
                peakUsage: null,
                usageLevel: usageTypes.MEDIUM.Id,
                meterIdentifier: null
              };
            }
            break;

          case implementationCodes.ELECTRICITY.AU_DEFAULT:
          case implementationCodes.GAS.AU_DEFAULT:
            if (verifyData) {
              newSubmission = {
                billType: verifyData.billType ? verifyData.billType : serviceType.Code,
                occupancyPurpose: verifyData.occupancyPurpose ? verifyData.occupancyPurpose : defaultOccupancyPurpose,
                occupancyType: occupancyTypes.CURRENT.Id,
                occupancyDate: null,
                hasSolar: verifyData.hasSolar ? verifyData.hasSolar : false,
                hasPremiumSolar: verifyData.hasPremiumSolar ? verifyData.hasPremiumSolar : false,
                provider: verifyData.provider ? verifyData.provider : null,
                propertyAddress: verifyData.supplyAddress ? verifyData.supplyAddress : null,
                distributor: verifyData.distributor,
                recentBill: true,
                billStartDateStandardised: verifyData.billStartDateStandardised,
                billStartDate: verifyData.billStartDate,
                billEndDateStandardised: verifyData.billEndDateStandardised,
                billEndDate: verifyData.billEndDate,
                usage: verifyData.usage,
                hasDemand: verifyData.hasDemand ? verifyData.hasDemand : false,
                solarFeedIn: verifyData.solarFeedIn,
                meterIdentifier: verifyData.meterIdentifier
              };
              // No data extracted from bill at all
            } else if (isVerify) {
              newSubmission = {
                billType:
                  implementationCode === implementationCodes.ELECTRICITY.AU_DEFAULT
                    ? billTypes.ELECTRICITY.Code
                    : billTypes.GAS.Code,
                recentBill: true,
                hasDemand: false
              };
            } else {
              newSubmission = {
                billType: serviceType.Code,
                occupancyPurpose: defaultOccupancyPurpose,
                occupancyType: occupancyType ? occupancyType : occupancyTypes.CURRENT.Id,
                occupancyDate: null,
                hasSolar: false,
                hasPremiumSolar: false,
                provider: null,
                propertyAddress: null,
                distributor: null,
                recentBill: isVerify ? true : false,
                billStartDateStandardised: null,
                billStartDate: null,
                billEndDateStandardised: null,
                billEndDate: null,
                usage: null,
                hasDemand: false,
                solarFeedIn: null,
                meterIdentifier: null
              };
            }
            break;
          case implementationCodes.INTERNET.AU_DEFAULT:
            if (verifyData) {
              newSubmission = {
                billType: verifyData.billType ? verifyData.billType : serviceType.Code,
                occupancyType: occupancyTypes.CURRENT.Id,
                occupancyDate: null,
                techType: verifyData.techType,
                connectionType: verifyData.connectionType
                  ? verifyData.connectionType
                  : internetConnectionTypeTypes.UNKNOWN.Id,
                developmentCharges: verifyData.developmentCharges,
                currentSpend: verifyData.currentSpend ? verifyData.currentSpend : null,
                dataUnlimited: verifyData.dataUnlimited ? verifyData.dataUnlimited : false,
                downloadSpeed: verifyData.downloadSpeed ? verifyData.downloadSpeed : null,
                propertyAddress: verifyData.supplyAddress ? verifyData.supplyAddress : null,
                provider: verifyData.provider ? verifyData.provider : null
              };
            } else {
              newSubmission = {
                billType: isVerify ? billTypes.INTERNET.Code : serviceType.Code,
                occupancyPurpose: defaultOccupancyPurpose,
                occupancyType: occupancyType ? occupancyType : occupancyTypes.CURRENT.Id,
                occupancyDate: null,
                techType: null,
                connectionType: internetConnectionTypeTypes.UNKNOWN.Id,
                developmentCharges: false,
                currentSpend: null,
                dataUnlimited: false,
                downloadSpeed: null,
                propertyAddress: null,
                provider: null
              };
            }
            break;
          case implementationCodes.SOLAR_FINANCE.AU_DEFAULT:
            newSubmission = {
              billType: billTypes.SOLAR_FINANCE.Code,
              solarInstallType: solarInstallTypes.SOLAR.Id,
              homeLoan: null,
              estimatedCost: null,
              propertyAddress: null
            };

            break;
          default:
            newSubmission = null;
        }

        const currentSubmission = submission || newSubmission;

        switch (implementationCode) {
          case implementationCodes.ELECTRICITY.US_DEFAULT:
            return (
              <EnergyEstimateUs
                showCancelButton={showCancelButton}
                confirmButtonText={confirmButtonText}
                onBackButtonClick={onBackButtonClick}
                onUploadBillTypeSelect={onUploadBillTypeSelect}
                onCancel={onCancelButtonClick}
                onSuccess={onEstimateComplete}
                serviceType={serviceType}
                launchMode={launchMode}
                submission={currentSubmission}
                submissionId={submissionId}
                showBackButton={_showBackButton}
                userInitiated={isVerify ? true : undefined}
                isVerify={isVerify ? true : false}
                implementationCode={implementationCode}
              />
            );

          case implementationCodes.ELECTRICITY.AU_DEFAULT:
          case implementationCodes.GAS.AU_DEFAULT:
            return (
              <EnergyEstimateAu
                showCancelButton={showCancelButton}
                confirmButtonText={confirmButtonText}
                onBackButtonClick={onBackButtonClick}
                uploadBillType={selectedUploadBillType}
                onUploadBillTypeSelect={onUploadBillTypeSelect}
                onCancel={onCancelButtonClick}
                onSuccess={onEstimateComplete}
                serviceType={serviceType}
                launchMode={launchMode}
                submission={currentSubmission}
                submissionId={submissionId}
                showBackButton={_showBackButton}
                userInitiated={isVerify ? true : undefined}
                isVerify={isVerify ? true : false}
                implementationCode={implementationCode}
              />
            );

          case implementationCodes.HOME_LOAN.AU_DEFAULT:
            return (
              <HomeLoanAu
                onSuccess={onSuccess}
                selectedUploadSubType={selectedUploadSubType}
                launchMode={launchMode}
                submissionId={submissionId}
                onBack={onBackButtonClick}
              />
            );

          case implementationCodes.INTERNET.AU_DEFAULT:
            return (
              <InternetAu
                showCancelButton={showCancelButton}
                showBackButton={_showBackButton}
                confirmButtonText={confirmButtonText}
                onBackButtonClick={onBackButtonClick}
                onUploadBillTypeSelect={onUploadBillTypeSelect}
                onCancel={onCancelButtonClick}
                onSuccess={onEstimateComplete}
                serviceType={serviceType}
                launchMode={launchMode}
                submission={currentSubmission}
                submissionId={submissionId}
              />
            );

          case implementationCodes.SOLAR_FINANCE.AU_DEFAULT:
            return (
              <SolarFinanceAu
                showCancelButton={showCancelButton}
                showBackButton={_showBackButton}
                confirmButtonText={confirmButtonText}
                onBackButtonClick={onBackButtonClick}
                onUploadBillTypeSelect={onUploadBillTypeSelect}
                onCancel={onCancelButtonClick}
                onSuccess={onEstimateComplete}
                serviceType={serviceType}
                launchMode={launchMode}
                submission={currentSubmission}
                submissionId={submissionId}
                addressLocked={getUserBill() && getUserBill().addressLocked}
              />
            );

          default:
            return (
              <SubmitBillDefaultUx2
                onSuccess={onBillComplete}
                onBackButtonClick={onBackButtonClick}
                onEstimateButtonClick={onEstimateButtonClick}
                onRedirectButtonClick={onRedirectButtonClick}
                showBackButton={_showBackButton}
                serviceType={serviceType}
                launchMode={launchMode}
                onVerify={onVerifyResponse}
              />
            );
        }

      case uploadBillTypes.DETAILS.Id:
        switch (implementationCode) {
          case implementationCodes.ELECTRICITY.US_DEFAULT:
          case implementationCodes.ELECTRICITY.AU_DEFAULT:
          case implementationCodes.GAS.AU_DEFAULT:
            return <EnergyBill userBill={getUserBill()} serviceType={serviceType} onClose={onSuccess} />;

          case implementationCodes.HOME_LOAN.AU_DEFAULT:
            return <HomeLoanDetailsAu userBill={getUserBill()} onClose={onSuccess} />;

          case implementationCodes.INTERNET.AU_DEFAULT:
            return null;

          default:
            return null;
        }

      case uploadBillTypes.AFFILIATE.Id:
        return <AffiliateReferral agentServiceType={agentServiceType} onClose={onSuccess} />;

      case uploadBillTypes.PARTNER.Id:
        return <PartnerComparison agentServiceType={agentServiceType} onClose={onSuccess} />;

      default:
        return null;
    }
  };

  // --------------------------------------------------------------------------
  // Initialize component
  // --------------------------------------------------------------------------
  useEffect(() => {
    dispatch(setServerMessageClear(target, serverMessage));
  }, []);

  return (
    <>
      <ServerMessage serverMessage={serverMessage} target={target} />

      {selectorVisible ? (
        <>
          <div className="sm-upload-option-banner">
            <CardImageSplit
              imageUrl={cardSplitBackground}
              iconUrl={savingIncentive ? savingIncentive.logoUrl : null}
              title={savingIncentive ? savingIncentive.description : null}
              imageMinHeight="150px"
            />
          </div>
          <div className="sm-upload-option-heading">{serviceType.AddText}</div>
          {getOptions()}
        </>
      ) : (
        getUploader()
      )}
    </>
  );
};

export default SubmitBillSelectorUx2;
