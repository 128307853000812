import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import QsCheckBox from './qsCheckBox';
import AddressAutoComplete from './addressAutoComplete';
import AddressManual from './addressManual';
import { orientationTypes } from '../../enums/scaleTypes';

const AddressControl = ({
  readOnly,
  onAddressSelected,
  manualAddressVisible,
  manualSuburbSelectorEnabled,
  autoPlaceholder,
  autoPropertyAddress,
  autoAddressRequired,
  labelText,
  showAreasSupportedDisclaimer,
  showMinimalManualFields,
  keySuffix,
  country,
  large
}) => {
  const [manualAddress, setManualAddress] = useState(false);

  const { areasSupportedDisclaimer } = useSelector(state => state.content.general);

  const manualAddressClick = () => {
    setManualAddress(!manualAddress);

    // Clear out address on parent when moving from auto to manual address
    if (onAddressSelected) {
      onAddressSelected({});
    }
  };

  return (
    <div className="sm-address">
      {(labelText || manualAddressVisible) && (
        <div className="address-header">
          {labelText && (
            <div className="address-label">
              <div style={{ paddingTop: '3px' }}>{labelText}</div>
            </div>
          )}

          {manualAddressVisible && !readOnly && (
            <div className="manual-check-box">
              <QsCheckBox
                isChecked={manualAddress}
                label="Can't find your address"
                onCheck={manualAddressClick}
                orientation={orientationTypes.right}
              />
            </div>
          )}
        </div>
      )}

      {manualAddress ? (
        <AddressManual
          key={`addressManual_${keySuffix}`}
          namePrefix={keySuffix}
          onAddressSelected={onAddressSelected}
          enableSuburbSelector={manualSuburbSelectorEnabled}
          propertyAddress={null}
          large={large}
          showMinimalManualFields={showMinimalManualFields}
        />
      ) : (
        <AddressAutoComplete
          key={`addressAuto_${keySuffix}`}
          placeholder={autoPlaceholder}
          country={country}
          onAddressSelected={onAddressSelected}
          selectedAddress={autoPropertyAddress}
          required={autoAddressRequired}
          readOnly={readOnly}
        />
      )}
      {showAreasSupportedDisclaimer && <div className="disclaimer">{areasSupportedDisclaimer}</div>}
    </div>
  );
};

export default AddressControl;
