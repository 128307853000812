import * as types from '../actions/actions';
import initialState from './initialState';

export default function selectedOfferReducer(state = initialState.selectedOffer, action) {
  switch (action.type) {
    case types.SET_SELECTED_OFFER:
      return { ...action.offer };

    default:
      return state;
  }
}
