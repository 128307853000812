import React from 'react';
import PropTypes from 'prop-types';
import { AvField } from 'availity-reactstrap-validation';
import { orientationTypes } from '../../enums/scaleTypes';

const QsCheckBox = ({
  isChecked,
  enabled: userEnabled,
  allowLabelCheck,
  onCheck,
  validationMessage,
  validatorName,
  label,
  orientation: userOrientation
}) => {
  const labelCheck = allowLabelCheck !== false;
  const hasLabel = label ? true : false;

  const orientation = hasLabel ? userOrientation || orientationTypes.left : orientationTypes.none;
  const orientationClass = hasLabel ? ` ${orientation.name}` : '';

  const enabled = userEnabled === undefined || userEnabled === true;
  const checkClassName = `sm-check-box${orientationClass}${!enabled ? ' disabled' : ''}`;
  const labelClassName = `sm-check-box-label${orientationClass}${labelCheck ? ' label-check' : ''}${
    !enabled ? ' disabled' : ''
  }`;

  return (
    <>
      <div className={`sm-check-box-container${orientationClass}`}>
        {label && orientation === orientationTypes.right && (
          <div className={labelClassName} onClick={enabled && labelCheck ? onCheck : undefined}>
            {label}
          </div>
        )}

        <div className={checkClassName} onClick={enabled ? onCheck : undefined}>
          {isChecked ? (
            <i className="material-icons">done</i>
          ) : enabled ? (
            <i className="material-icons" style={{ color: 'white' }}>
              check_box_outline_blank
            </i>
          ) : null}
        </div>

        {label && (orientation === orientationTypes.left || orientation === orientationTypes.top) && (
          <div className={labelClassName} onClick={enabled && labelCheck ? onCheck : undefined}>
            {label}
          </div>
        )}
      </div>

      {validationMessage && validatorName ? (
        <AvField
          type="text"
          name={`validation-${validatorName}`}
          className="sm-validator-hidden sm-validator-tc-checkbox"
          value={isChecked ? true : false}
          validate={{ validate: (value, ctx, input, cb) => cb(!value ? validationMessage : true) }}
        />
      ) : (
        <div className="qs-mb-md" />
      )}
    </>
  );
};

QsCheckBox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired
};

export default QsCheckBox;
