import axios from 'axios';
import { push } from 'connected-react-router';
import timeAxios from '../utilities/timeAxios';
import { apiTiming } from '../utilities/googleAnalyticsEvents';

import * as types from './actions';
import { setPageMode } from './applicationActions';
import { goHome, setComparison } from './comparisonActions';
import { setAjaxCallEnd, setAjaxCallStart } from './ajaxCallActions';
import { setServerMessageClear } from './serverMessageActions';

import { billTypes } from '../enums/billTypes';
import { occupancyPurposes, occupancyTypes } from '../enums/submissionTypes';
import { pageModes } from '../enums/launchTypes';
import { propertyAddressTypes } from '../enums/propertyAddressTypes';
import { partnerFrameTypes } from '../enums/partnerFrameTypes';
import { frameMessageTypes, postFrameMessage } from '../utilities/domUtilities';

export function setFramePartnerSuccess(framePartner) {
  return { type: types.SET_FRAME_PARTNER, data: { ...framePartner } };
}

export function setFramePartner(framePartner) {
  return dispatch => {
    dispatch(setFramePartnerSuccess(framePartner));
  };
}

export function resetFramePartner(source) {
  return dispatch => {
    dispatch(setFramePartnerSuccess({ restartSource: source }));
  };
}

// --------------------------------------------------------------------------
// Initializes a framePartner request.
// --------------------------------------------------------------------------
export function initFramePartner(host, referrer, agentCode, args) {
  return dispatch => {
    // Need to preload pageMode early so it is not overwritten when google loads.
    dispatch(setPageMode(pageModes.FRAMED, null));
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'initialize', 'iframe-launch`');
    });

    const model = {
      ...args,
      hostEncoded: host,
      referrerEncoded: referrer,
      agentCode
    };

    axios
      .post(`/api/partner/iframe`, model)
      .then(response => dispatch(onPartnerResponse(response, pageModes.FRAMED)))
      .catch(error => {
        console.log(error);
      })
      .then(() => {
        dispatch(setAjaxCallEnd());
      });
  };
}

// --------------------------------------------------------------------------
// Initializes an estimate wizaed request.
// --------------------------------------------------------------------------
export function initEstimateWizard(agentCode, args) {
  return dispatch => {
    // Need to preload pageMode early so it is not overwritten when google loads.
    dispatch(setPageMode(pageModes.FRAMED, null));
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'initialize', 'estimate-wizard`');
    });

    const model = {
      ...args,
      agentCode
    };

    axios
      .post(`/api/partner/estimate-wizard`, model)
      .then(response => dispatch(onPartnerResponse(response, pageModes.DEFAULT)))
      .then(dispatch(goHome()))
      .catch(error => {
        console.log(error);
      })
      .then(() => {
        dispatch(setAjaxCallEnd());
      });
  };
}

const onPartnerResponse = (response, pageMode) => {
  return dispatch => {
    const data = response.data;

    if (data) {
      const serviceType = billTypes.getById(data.billTypeId);
      const occupancyType = occupancyTypes.getById(data.occupancyType);
      const occupancyPurpose = occupancyPurposes.getById(data.occupancyPurpose);
      const skipIntroduction = data.skipIntroduction;
      const frameHost = data.frameHost;
      const frameType = partnerFrameTypes.getById(data.frameType);
      const postcodeInitial = data.postcodeInitial;
      const postcodeOfferMax = data.postcodeOfferMax;
      const propertyAddressType = propertyAddressTypes.getById(data.propertyAddressType);
      const hidden = data.hidden;

      if (pageMode === pageModes.FRAMED) {
        dispatch(setPageMode(pageModes.FRAMED, frameHost));
      }

      dispatch(
        setFramePartner({
          serviceType,
          occupancyType,
          occupancyPurpose,
          postcodeInitial,
          postcodeOfferMax,
          propertyAddressType,
          frameType,
          skipIntroduction,
          hidden,
          pageMode
        })
      );

      if (pageMode === pageModes.FRAMED && hidden) {
        postFrameMessage(frameHost, frameMessageTypes.HIDE, {}, true);
      }
    }
  };
};

// --------------------------------------------------------------------------
// Get postcode comparison
// --------------------------------------------------------------------------
export function getPostcodeComparison(data, agentCode, onComplete, onError) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'postcode-estimate', 'iframe`');
    });

    const model = {
      ...data,
      agentCode
    };

    axios
      .post(`/api/partner/comparison/postcode`, model)
      .then(response => {
        const data = response.data;
        const comparison = data.comparison;
        const sorting = data.sorting;
        const offerMax = data.offerMax;

        if (data) {
          dispatch(
            setComparison({
              ...comparison,
              sorting,
              offerMax,
              isLoaded: true
            })
          );

          dispatch(push('/compare'));

          onComplete();
        }
      })
      .catch(error => {
        onError(error);
      })
      .then(() => {
        dispatch(setAjaxCallEnd());
      });
  };
}

// --------------------------------------------------------------------------
// Send solar installation email.
// --------------------------------------------------------------------------
export function sendSolarInstallationEmail(data, onComplete, onError) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'send-email', 'partner-solar-installation`');
    });

    const model = {
      emailAddress: data.emailAddress,
      agentCode: data.selectedAgent ? data.selectedAgent.code : null
    };

    axios
      .post(`/api/partner/solarInstallationEmail`, model)
      .then(() => {
        onComplete();
      })
      .catch(() => {
        onError();
      })
      .then(() => {
        dispatch(setAjaxCallEnd());
      });
  };
}
