import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';

import { useAuth0 } from '../auth/auth0-Provider.js';

import { getServiceCards } from '../actions/serviceCardActions.js';
import { goDashboard, goHome } from '../actions/comparisonActions.js';
import { openSubmitDialog } from '../actions/dialogOptionsActions.js';

import { getAgentServiceType } from '../enums/customerCommissionTypes.js';
import { uploadSubTypes } from '../enums/uploadBillTypes.js';
import { billTypes } from '../enums/billTypes.js';
import { homePageTypes, launchDialogModes, pageModes } from '../enums/launchTypes.js';

import { standardEvents } from '../utilities/googleAnalyticsEvents.js';
import { isFramed } from '../utilities/domUtilities.js';

import GetStarted from '../components/GetStartedWizard/getStarted.js';
import ServiceBarButton from '../components/serviceCard/serviceBarButton.js';
import HeroInfo from '../components/home/heroInfo.js';
import HeroBanner from '../components/home/heroBanner.js';
import Introduction from '../components/home/introduction.js';
import Differences from '../components/home/differences.js';
import Testimonial from '../components/home/testimonial.js';
import ArticleSummary from '../components/home/articleSummary.js';
import DialogManager from '../components/layout/dialogManager.js';
import { determineUploadBillType } from '../components/serviceCard/serviceCardUtilities';
import ServerMessage from '../components/common/serverMessage.js';
import * as serverMessageTargets from '../actions/serverMessageTargets.js';

const HomeScreen = () => {
  const dispatch = useDispatch();
  const { loading: auth0Loading, isAuthenticated, isUserAuthenticated, getAccessToken } = useAuth0();

  const { serverMessage } = useSelector(state => state);
  const {
    googleAnalyticsClientCode,
    homePageHeroSectionEnabled,
    homePageIntroductionSectionEnabled,
    homePageDifferencesSectionEnabled,
    articlesEnabled,
    homePageType
  } = useSelector(state => state.config.settings);
  const { loaded: userBillsLoaded } = useSelector(state => state.userBills);
  const { billTypes: agentBillTypes, hasArticles, isLoaded: configLoaded } = useSelector(state => state.config);
  const { pageMode } = useSelector(state => state.application);
  const { launchDialogMode } = useSelector(state => state.landing.home);
  const { principles, testimonials } = useSelector(state => state.content.home);

  const principleItems = principles ? JSON.parse(principles) : [];
  const testimonialItems = testimonials ? JSON.parse(testimonials) : [];

  const pageModeDefault = pageMode === pageModes.DEFAULT && !isFramed();
  const showBanner = pageModeDefault;
  const showHero = pageModeDefault && homePageHeroSectionEnabled;
  const showIntroduction = pageModeDefault && homePageIntroductionSectionEnabled;
  const showDifferences = pageModeDefault && homePageDifferencesSectionEnabled;
  const showTestimonials = pageModeDefault && testimonialItems && testimonialItems.length > 0;
  const showArticles = pageModeDefault && articlesEnabled;

  const serviceTypeOptions = { includeExternal: true };
  const services = billTypes.getServices(agentBillTypes, null, serviceTypeOptions);

  const agentServiceType = getAgentServiceType(billTypes.ELECTRICITY, agentBillTypes);
  const hasCommission = agentServiceType.hasCommission;
  const isDefaultLaunchMode = launchDialogMode === launchDialogModes.DEFAULT;
  const isHomePageDefault = homePageType === homePageTypes.DEFAULT.id;
  const isHomePageDashboard = homePageType === homePageTypes.DASHBOARD.id;
  const isHomePageStart = homePageType === homePageTypes.START.id;

  useEffect(() => {
    const fn = async () => {
      if (!userBillsLoaded && !auth0Loading) {
        const accessToken = isUserAuthenticated() ? await getAccessToken() : null;

        // Service cards are also loaded by dashboard (to handle 'Dashboard' home page).
        if (isHomePageDefault) {
          dispatch(getServiceCards(accessToken));
        }
      }
    };
    fn();
  }, [isAuthenticated, auth0Loading]);

  // --------------------------------------------------------------------------
  // Start service from service bar.
  // --------------------------------------------------------------------------
  const startService = serviceType => {
    standardEvents('Service-Bar', serviceType.Name, 'Open-Dialog', googleAnalyticsClientCode);

    const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
    const affiliate = agentServiceType ? agentServiceType.affiliate : null;
    const partner = agentServiceType ? agentServiceType.partner : null;

    const submissionId = null;
    const submission = null;
    const backButtonVisible = serviceType !== billTypes.SOLAR_FINANCE;
    const confirmButtonText = serviceType === billTypes.SOLAR_FINANCE ? 'Submit' : 'Next';
    const uploadBillType = determineUploadBillType(partner, affiliate, agentServiceType);

    const uploadSubType = uploadSubTypes.getSubType(serviceType, null);

    dispatch(
      openSubmitDialog(
        serviceType,
        uploadBillType,
        uploadSubType,
        {
          submissionId,
          submission,
          backButtonVisible,
          buttonText: confirmButtonText
        },
        serverMessage
      )
    );
  };

  // --------------------------------------------------------------------------
  // Page Initialization
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (configLoaded) {
      if (isHomePageDashboard) {
        dispatch(goDashboard());
      } else if (isHomePageStart) {
        dispatch(goHome());
      }
    }
  }, [configLoaded, dispatch, isHomePageDashboard, isHomePageStart]);

  const getHeading = () => {
    let heading = '';
    const headingItems = principleItems.filter(e => e.requiresCommission === false || hasCommission);

    if (!headingItems || headingItems.length === 0) return heading;

    headingItems.forEach((item, index) => {
      const title = index > 0 ? item.title.toLowerCase() : item.title;
      const sep = index === headingItems.length - 1 ? '' : ', ';
      heading += title + sep;
    });

    return heading;
  };

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>

      <Container>
        <ServerMessage serverMessage={serverMessage} target={serverMessageTargets.HOME} />
      </Container>

      {configLoaded && isDefaultLaunchMode && isHomePageDefault && (
        <div className="sm-main">
          {showBanner && (
            <div className="sm-home-banner">
              <div className="title">{getHeading()}</div>
              <div className={'sm-service-card-list compact'}>
                {services.map(s => (
                  <ServiceBarButton
                    key={s.Id}
                    service={{ serviceType: { ...s }, id: s.Id, isEmpty: true }}
                    onServiceStart={() => startService(s)}
                  />
                ))}
              </div>
            </div>
          )}

          <Container className="sm-container">
            {showHero && (
              <div className="sm-home-hero">
                <HeroBanner />
                <HeroInfo />
              </div>
            )}

            {showIntroduction && <Introduction />}

            {showDifferences && (
              <>
                <Differences agentServiceType={agentServiceType} />
                <div className="sm-home-get-started">
                  <GetStarted key="homeOtherGetStartedDialog" />
                </div>
              </>
            )}
            {showTestimonials && <Testimonial item={testimonialItems[0]} />}
            {showArticles && hasArticles && <ArticleSummary />}
          </Container>
        </div>
      )}

      <DialogManager />
    </>
  );
};

export default HomeScreen;
