import * as types from './actions';
import axios from 'axios';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import { setServerMessage, setServerMessageClear } from './serverMessageActions';
import { goHome } from './comparisonActions';
import * as serverMessageTargets from './serverMessageTargets';
import { push } from 'connected-react-router';
import timeAxios from '../utilities/timeAxios';
import { apiTiming } from '../utilities/googleAnalyticsEvents';

export function login(accessToken) {
  return makeRequest(accessToken, 'login');
}

export function verify(accessToken) {
  return makeRequest(accessToken, 'verify');
}

export function setUser(user) {
  return dispatch => {
    if (!user || user.isAnonymous) return;

    dispatch(
      setUserSuccess({
        ...user
      })
    );
  };
}

function setUserSuccess(data) {
  return { type: types.SET_USER, data };
}

function makeRequest(accessToken, path) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'user', path);
    });

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios
      .get(`/api/user/${path}`, config)
      .then(response => {
        if (response.data) {
          var data = response.data;
          dispatch(
            setUserSuccess({
              ...data,
              loaded: true
            })
          );
        } else {
          dispatch(goHome());
        }
      })
      .catch(error => {
        dispatch(
          setServerMessage({
            ...error.response.data,
            scrollTo: true,
            target: serverMessageTargets.LOGIN_ERROR
          })
        );
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function authenticate(token) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    axios
      .get(`/api/user/auth/?token=${encodeURIComponent(token)}`)
      .then(response => {
        if (response.data) {
          var data = response.data;
          dispatch(
            setUserSuccess({
              ...data,
              loaded: true
            })
          );
          dispatch(push(data.redirectUrl));
        } else {
          dispatch(goHome());
        }
      })
      .catch(error => {
        dispatch(
          setServerMessage({
            ...error.response.data,
            scrollTo: true
          })
        );
        dispatch(goHome());
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function getPreferencesWithKey(access) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', 'preferences');
    });

    axios
      .get(`/api/user/preferences/${access.id}/?key=${access.key}`)
      .then(response => {
        if (response.data) {
          var data = response.data;

          dispatch(
            setUserSuccess({
              ...data,
              loaded: true,
              accessId: access.id,
              accessKey: access.key
            })
          );

          dispatch(push('/preferences'));
        } else {
          dispatch(goHome());
        }
      })
      .catch(error => {
        dispatch(push('/preferences'));
        dispatch(
          setServerMessage({ ...error.response.data, scrollTo: true, target: serverMessageTargets.PREFERENCES })
        );
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function updatePreferencesWithKey(access, preferences, onComplete) {
  return dispatch => {
    updatePreferences(
      dispatch,
      `/api/user/preferences/${access.id}/?key=${access.key}`,
      null,
      access,
      preferences,
      onComplete
    );
  };
}

// ----------------------------------------------------------------------------
// Updates customer preferences with an access token issued by a known authentication provider.
// ----------------------------------------------------------------------------
export function updatePreferencesWithToken(accessToken, preferences, onComplete) {
  return dispatch => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    updatePreferences(dispatch, '/api/user/preferences', config, null, preferences, onComplete);
  };
}

function updatePreferences(dispatch, path, config, access, preferences, onComplete) {
  dispatch(setServerMessageClear());
  dispatch(setAjaxCallStart());

  timeAxios(axios, timeInMs => {
    apiTiming(timeInMs, 'user', 'preferences');
  });

  axios
    .post(path, preferences, config)
    .then(response => {
      var data = response.data;

      var communicationPreferences = preferences.communication;

      var anyCommunicationsEnabled =
        communicationPreferences.betterOffersEnabledEmail ||
        communicationPreferences.betterOffersEnabledSms ||
        communicationPreferences.marketingEnabledEmail ||
        communicationPreferences.marketingEnabledSms ||
        communicationPreferences.newsEnabledEmail ||
        communicationPreferences.newsEnabledSms ||
        communicationPreferences.surveyEnabledEmail ||
        communicationPreferences.surveyEnabledSms;

      dispatch(
        setUserSuccess({
          ...data,
          loaded: true,
          accessId: access ? access.id : null,
          accessKey: access ? access.key : null
        })
      );
      dispatch(
        setServerMessage({
          code: response.status,
          messages: [
            {
              field: '',
              message: anyCommunicationsEnabled
                ? 'Your communication preferences have been updated.'
                : 'You have unsubscribed from all communications.'
            }
          ],
          scrollTo: false,
          target: serverMessageTargets.PREFERENCES
        })
      );
      if (onComplete) {
        onComplete();
      }
    })
    .catch(error => {
      dispatch(setServerMessage({ ...error.response.data, scrollTo: true, target: serverMessageTargets.PREFERENCES }));
    })
    .then(function() {
      dispatch(setAjaxCallEnd());
    });
}
