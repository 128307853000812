import React from 'react';
import ServiceCardEmpty from './serviceCardEmpty';
import ServiceCardAdd from './serviceCardAdd';
import ServiceCardDefault from './serviceCardDefault';

export default function ServiceCardContent(props) {
  const { serviceType, isSample, isEmpty, isAdd, onStart } = props;

  if (isAdd) return <ServiceCardAdd />;
  if (isEmpty) return <ServiceCardEmpty onStart={onStart} serviceType={serviceType} isSample={isSample} />;

  return <ServiceCardDefault {...props} />;
}
