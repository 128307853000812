import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function surveyReducer(state = initialState.survey, action) {
  switch (action.type) {
    case types.SET_SURVEY:
      return objectAssign({}, state, action.survey);

    default:
      return state;
  }
}
