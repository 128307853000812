import React from 'react';

import { billTypes } from '../../enums/billTypes';

import { ReactComponent as ElectricityIcon } from '../icons/services/outline/electricity.svg';
import { ReactComponent as GasIcon } from '../icons/services/outline/gas.svg';
import { ReactComponent as HealthIcon } from '../icons/services/outline/health.svg';
import { ReactComponent as HomeInsuranceIcon } from '../icons/services/outline/home-insurance.svg';
import { ReactComponent as HomeLoanIcon } from '../icons/services/outline/home-loan.svg';
import { ReactComponent as InternetIcon } from '../icons/services/outline/internet.svg';
import { ReactComponent as VehicleInsuranceIcon } from '../icons/services/outline/vehicle-insurance.svg';
import { ReactComponent as MobilePhoneIcon } from '../icons/services/outline/mobile-phone.svg';
import { ReactComponent as SolarInstallationIcon } from '../icons/services/outline/solar-installation.svg';
import { ReactComponent as SolarFinanceIcon } from '../icons/services/outline/solar-finance.svg';
import { ReactComponent as BusinessLoanIcon } from '../icons/services/outline/business-loan.svg';

const ServiceOutline = ({ serviceType }) => {
  const getIcon = () => {
    const className = 'icon';

    switch (serviceType.Id) {
      case billTypes.ELECTRICITY.Id:
        return <ElectricityIcon className={className} />;

      case billTypes.GAS.Id:
        return <GasIcon className={className} />;

      case billTypes.HEALTH_INSURANCE.Id:
        return <HealthIcon className={className} />;

      case billTypes.HOME_AND_CONTENTS_INSURANCE.Id:
        return <HomeInsuranceIcon className={className} />;

      case billTypes.HOME_LOAN.Id:
        return <HomeLoanIcon className={className} />;

      case billTypes.INTERNET.Id:
        return <InternetIcon className={className} />;

      case billTypes.VECHICLE_INSURANCE.Id:
        return <VehicleInsuranceIcon className={className} />;

      case billTypes.MOBILE_PHONE.Id:
        return <MobilePhoneIcon className={className} />;

      case billTypes.SOLAR_INSTALLATION.Id:
        return <SolarInstallationIcon className={className} />;

      case billTypes.SOLAR_FINANCE.Id:
        return <SolarFinanceIcon className={className} />;

      case billTypes.BUSINESS_LOAN.Id:
        return <BusinessLoanIcon className={className} />;

      default:
        return null;
    }
  };

  return getIcon();
};

export default ServiceOutline;
