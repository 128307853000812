import React from 'react';
import { useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import EmailAddress from './emailAddress';

const YourDetails = ({
  onChange,
  details: { title, firstName, surname, emailAddress, phoneNumber, membershipId, alternatePhoneNumber, dateOfBirth },
  phoneNumberRegex,
  alternatePhoneNumberRegex,
  showMembershipId,
  showAlternatePhoneNumber,
  showDateOfBirth,
  showTitle,
  showEmailAddress,
  showPhoneNumber,
  lockPhoneNumber
}) => {
  const { titles } = useSelector(state => state.config.referenceData);
  const { country } = useSelector(state => state.config);
  const { emailAddress: userEmailAddress } = useSelector(state => state.user);

  return (
    <>
      {showTitle && (
        <FormGroup>
          <AvField
            bsSize="sm"
            name="title"
            placeholder="Title"
            type="select"
            value={title}
            onChange={e => onChange(e, { title: e.target.value ? parseInt(e.target.value) : null })}
            validate={{
              required: { value: true, errorMessage: 'Title is required' }
            }}
          >
            <option value="">Select a title</option>
            {titles.map((ref, i) => (
              <option key={`title-${ref.id}`} value={ref.id}>
                {ref.description}
              </option>
            ))}
          </AvField>
        </FormGroup>
      )}

      <FormGroup>
        <AvField
          bsSize="sm"
          name="firstName"
          placeholder="First Name"
          type="text"
          value={firstName}
          onChange={e => onChange(e, { firstName: e.target.value })}
          validate={{
            required: { value: true, errorMessage: 'First name is required' },
            minLength: { value: 2, errorMessage: 'First name is not the required length' },
            maxLength: { value: 50, errorMessage: 'First name should be 50 characters or less' },
            pattern: {
              value: '^[a-zA-Z -]+$',
              errorMessage: `Invalid characters in first name`
            }
          }}
        />
      </FormGroup>
      <FormGroup>
        <AvField
          bsSize="sm"
          name="surname"
          placeholder="Surname"
          type="text"
          value={surname}
          onChange={e => onChange(e, { surname: e.target.value })}
          validate={{
            required: { value: true, errorMessage: 'Surname is required' },
            minLength: { value: 2, errorMessage: 'Surname is not the required length' },
            maxLength: { value: 50, errorMessage: 'Surname should be 50 characters or less' },
            pattern: {
              value: '^[a-zA-Z -]+$',
              errorMessage: `Invalid characters in surname`
            }
          }}
        />
      </FormGroup>

      {showEmailAddress && (
        <FormGroup>
          <EmailAddress
            disabled={userEmailAddress ? true : false}
            value={emailAddress}
            onChange={value => {
              onChange(null, { emailAddress: value });
            }}
            forceRequireEmailAddress={true}
          />
        </FormGroup>
      )}

      {showPhoneNumber && (
        <FormGroup>
          <AvField
            disabled={lockPhoneNumber}
            bsSize="sm"
            name="phoneNumber"
            placeholder="Mobile phone number"
            type="text"
            value={phoneNumber}
            onChange={e => onChange(e, { phoneNumber: e.target.value })}
            validate={{
              required: { value: true, errorMessage: 'Mobile phone number is required' },
              pattern: {
                value: phoneNumberRegex,
                errorMessage: `Please enter a valid  mobile phone number for ${country.name} (no spaces or hyphens)`
              }
            }}
          />
        </FormGroup>
      )}

      {showAlternatePhoneNumber && (
        <FormGroup>
          <AvField
            disabled={lockPhoneNumber}
            bsSize="sm"
            name="alternatePhoneNumber"
            placeholder="Alternate phone number"
            type="text"
            value={alternatePhoneNumber}
            onChange={e => onChange(e, { alternatePhoneNumber: e.target.value })}
            validate={{
              pattern: {
                value: alternatePhoneNumberRegex,
                errorMessage: `Please enter a valid alternate phone number for ${country.name} (no spaces or hyphens)`
              }
            }}
          />
        </FormGroup>
      )}

      {showDateOfBirth && (
        <FormGroup>
          <div className="qs-more-info-table-header">Date of birth</div>
          <AvField
            bsSize="sm"
            placeholder="dd/mm/yyyy"
            name="dateOfBirth"
            type="text"
            value={dateOfBirth}
            onChange={e => onChange(e, { dateOfBirth: e.target.value })}
            validate={{
              required: { value: true, errorMessage: 'Date of birth is required' },
              pattern: {
                value:
                  '^(?:(?:(?:0[1-9]|1\\d|2[0-8])\\/(?:0[1-9]|1[0-2])|(?:29|30)/(?:0[13-9]|1[0-2])|31\\/(?:0[13578]|1[02]))\\/[1-9]\\d{3}|29\\/02(?:\\/[1-9]\\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00))$',
                errorMessage: 'Please enter in the format dd/mm/yyyy e.g. 09/04/1986'
              }
            }}
          />
        </FormGroup>
      )}

      {showMembershipId && (
        <div>
          <div>
            <AvField
              bsSize="sm"
              name="membershipId"
              placeholder="Member number"
              type="text"
              value={membershipId}
              onChange={e => onChange(e, { membershipId: e.target.value })}
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Please enter your membership identification required by the selected plan'
                },
                minLength: { value: 3, errorMessage: 'Membership identification is not the required length' },
                maxLength: {
                  value: 20,
                  errorMessage: 'Membership identification should be 20 characters or less'
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default YourDetails;
