import React from 'react';

import Sheet from '../Sheet/Sheet';
import ServiceOutline from './serviceOutline';

const ServiceCardEmpty = ({ serviceType, onStart, isSample }) => {
  return (
    <div className={`status-empty${isSample ? ' is-sample' : ''}`} onClick={isSample ? undefined : onStart}>
      <Sheet className={`status-empty${isSample ? ' is-sample' : ''}`}>
        <ServiceOutline serviceType={serviceType} />
      </Sheet>
    </div>
  );
};

export default ServiceCardEmpty;
