import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { chatBotOptions } from '../../actions/globalOptionsActions';
import { occupancyPurposes, occupancyTypes } from '../../enums/submissionTypes';
import { navigationTypes } from '../../enums/navigationTypes';
import { billTypes } from '../../enums/billTypes';
import Ellipsis from './ellipsis';
import Menu from './menu';
import OrganiserDescription from '../common/organiserDescription';

const ChatBot = () => {
  const dispatch = useDispatch();

  const { isOpen: chatBotOpen, isPinned: chatBotPinned } = useSelector(state => state.globalOptions.chatBot);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { organiserName } = useSelector(state => state.content.general);
  const { isBill } = useSelector(state => state.comparison);
  const { occupancyType: occupancyTypeId, occupancyPurpose: occupancyPurposeId, billType } = useSelector(
    state => state.comparison.submission
  );

  const occupancyPurpose = occupancyPurposes.getById(occupancyPurposeId);
  const occupancyType = isBill ? occupancyTypes.CURRENT : occupancyTypes.getById(occupancyTypeId);
  const serviceType = billTypes.getByCode(billType);

  const [isOpen, setIsOpen] = useState(chatBotOpen);
  const [closing, setClosing] = useState(false);
  const [isPinned, setIsPinned] = useState(chatBotPinned);
  const [backEnabled, setBackEnabled] = useState(false);
  const [animateEllipsis, setAnimateEllipsis] = useState(false);
  const [messages, setMessages] = useState([]);

  const contentRef = useRef(null);
  const currentPage = navigationTypes.getCurrentPage();
  const ellipsisSeconds = 1250;

  const data = [
    {
      level: 1,
      messageId: '0.0',
      message: <p>TBD: We don't have a comparsion in context. What is the chatBot supposed do?</p>
    },
    {
      level: 1,
      messageId: '1.0',
      message:
        "I see you're moving house or setting up a new connection.  Do you have any questions about your plan options?",
      answers: [
        { type: 'button', text: 'Yes, regarding the connection/move-in date', messageId: '1.1' },
        { type: 'button', text: 'Yes, regarding the connection process', messageId: '1.2' },
        { type: 'button', text: 'Yes, regarding provider contracts', messageId: '1.3' }
      ]
    },
    {
      messageId: '1.1',
      message: 'What would you like to know about about the connection date?',
      answers: [
        { type: 'button', text: 'What if my connection date is unknown?', messageId: '1.1.1' },
        { type: 'button', text: 'What if my connection date changes?', messageId: '1.1.2' },
        { type: 'button', text: 'Can you reminder me closer to the date?', messageId: '1.1.3' }
      ]
    },
    {
      messageId: '1.1.1',
      message: (
        <>
          <p>If you don't yet know your connection date, that's no problem.</p>
          <p>
            You can set a reminder by clicking on the Filters & Details tab on the top right of this screen, select
            EDIT, then the 'Not Sure' button for the move in date.
          </p>
          <p>You can then select a date you'd like to automatically receive an email with our cheapest offers.</p>
          <p>We recommend this to be around 2 weeks before you think you might need the new connection completed.</p>
        </>
      )
    },
    {
      messageId: '1.1.2',
      message: (
        <>
          <p>
            When you submit your energy application, you'll need to specify a connection date which we'll share with
            your new provider.
          </p>
          <p>If your preferred connection date changes, you can contact the new energy provider to let them know.</p>
          <p>
            Typically energy providers can accommodate those changes up to two weeks before the connection date, however
            some retailers may not be able to accommodate the change if you've requested a date less than a week away.
          </p>
        </>
      )
    },
    {
      messageId: '1.1.3',
      message: (
        <>
          <p>
            Yes. You can set a reminder by clicking on the Filters & Details tab on the top right of this screen, select
            EDIT, then the 'Not Sure' button for the move in date.
          </p>
          <p>You can then select a date you'd like to automatically receive an email with our cheapest offers.</p>
          <p>We recommend this to be around 2 weeks before you think you might need the new connection completed.</p>
        </>
      )
    },
    {
      messageId: '1.2',
      message: 'What would you like to know about the connection process?',
      answers: [
        { type: 'button', text: 'Do I need to contact anyone?', messageId: '1.2.1' },
        { type: 'button', text: 'What is my meter ID?', messageId: '1.2.2' },
        { type: 'button', text: 'Do I need to be at the property?', messageId: '1.2.3' }
      ]
    },
    {
      messageId: '1.2.1',
      message: (
        <>
          <p>You don't need to contact anyone to process the switch. We'll organise everything for you.</p>
          <p>You'll receive a Welcome Pack from your new provider with all the details of the plan you've selected.</p>
          <p>
            If you want to set up a direct debit with your new provider, you will need to contact them after you receive
            the Welcome Pack and provide your bank details.
          </p>
        </>
      )
    },
    {
      messageId: '1.2.2',
      message: <p>The Meter ID can be found on the sticker attached to your electricity or gas meter.</p>
    },
    {
      messageId: '1.2.3',
      message: (
        <p>Typically no, but your new provider will let you know what they require as part of your Welcome Pack.</p>
      )
    },

    {
      messageId: '1.3',
      message: 'What would you like to know about the connection process?',
      answers: [
        { type: 'button', text: 'Will I be locked into a new contract?', messageId: '1.3.1' },
        { type: 'button', text: 'Are there any exit fees?', messageId: '1.3.2' }
      ]
    },
    {
      messageId: '1.3.1',
      message: (
        <>
          <p>
            Some plans have contracts, but these can often be beneficial to the customer as it commits the energy
            provider to maintaining the rates you've secured with them for a contracted period of time.
          </p>
          <p>
            You can check underneath the providers logo on this comparison page to see the type of contracts that may
            apply to each plan.
          </p>
        </>
      )
    },
    {
      messageId: '1.3.2',
      message: (
        <p>
          You can check underneath the providers logo on this comparison page to see if Exit Fees apply to any given
          plan.
        </p>
      )
    },
    {
      level: 1,
      messageId: '2.0',
      message:
        "I see you're comparing an existing service at your property. Do you have any questions about your plan options?",
      answers: [
        { type: 'button', text: 'How is the savings amount calculated?', messageId: '2.1' },
        { type: 'button', text: "What's the process of switching providers?", messageId: '2.2' },
        { type: 'button', text: 'What about provider contracts?', messageId: '2.3' }
      ]
    },
    {
      messageId: '2.1',
      message: "What is it you'd like to know about the savings calculation?",
      answers: [
        { type: 'button', text: "What's included in the savings calculation?", messageId: '2.1.1' },
        { type: 'button', text: 'Are my discounts included?', messageId: '2.1.2' },
        { type: 'button', text: 'What does the VDO/DMO % mean?', messageId: '2.1.3' }
      ]
    },
    {
      messageId: '2.1.1',
      message: (
        <>
          <p>Your saving calculation takes into account:</p>
          <ul>
            <li>The number of days of energy usage</li>
            <li>The amount of kw or MJ you used</li>
            <li>Whether your usage was at a peak or off peak times</li>
            <li>Usage pricing steps/tiers</li>
            <li>Solar feed in rates and amounts (credits)</li>
            <li>Discounts you may recieve from your provider</li>
          </ul>
          <p>
            The saving amount quoted assumes you received your discounts, so if you don't typically receive your
            discount, the saving % could be even higher.
          </p>
        </>
      )
    },
    {
      messageId: '2.1.2',
      message: (
        <p>
          Yes. The saving amount quoted assumes you've received your discounts, so if you don't typically receive your
          discount, the saving % could be even higher.
        </p>
      )
    },
    {
      messageId: '2.1.3',
      message: (
        <>
          <p>The VDO/DMO % is an industry wide estimate of what a house in your area might save.</p>
          <p>If you look at the fine print under the VDO/DMO % numer, you'll see it assumes a certain usage amount.</p>
          <p>
            Of course, not all houses in your area use the same amount of energy, so your unique bill saving % is going
            to be far more accurate way of determining the cheapest deal for your home.
          </p>
          <p>
            If you haven't yet uploaded an e-bill, you can click the Filters & Details tab on the top right of this
            screen, then EDIT, then Upload bill.
          </p>
        </>
      )
    },
    {
      messageId: '2.2',
      message: 'Specifically, what would you like to know about the switching process?',
      answers: [
        { type: 'button', text: 'Who do I contact?', messageId: '2.2.1' },
        { type: 'button', text: 'When does it occur?', messageId: '2.2.2' },
        { type: 'button', text: 'What about my direct debits?', messageId: '2.2.3' }
      ]
    },
    {
      messageId: '2.2.1',
      message: (
        <>
          <p>You don't need to contact anyone to process the switch. We'll organise everything for you.</p>
          <p>You'll receive a Welcome Pack from your new provider with all the details of the plan you've selected.</p>
          <p>
            If you want to set up a direct debit with your new provider, you will need to contact them after you receive
            the Welcome Pack and provide your bank details.
          </p>
        </>
      )
    },
    {
      messageId: '2.2.2',
      message: (
        <>
          <p>
            You will recieve one last bill from your current provider, then your new provider will take over your energy
            service.
          </p>
          <p>Timing for the change over will depend on when your meter was last read, and the regularity it's read.</p>
          <p>
            For example, if it's read every 8 weeks, and the last reading was a 6 weeks ago, your current provider will
            continue to provide you energy for 2 more weeks (until the next meter reading occurs).
          </p>
        </>
      )
    },
    {
      messageId: '2.2.3',
      message: (
        <>
          <p>
            If you want to set up a direct debit with your new provider, you will need to contact them after you switch.
          </p>
          <p>
            You will receive the Welcome Pack from your new provider with details on how to confirm your bank details
            with your new energy provider.
          </p>
        </>
      )
    },
    {
      messageId: '2.3',
      message: 'Specifically, what would you like to know about the switching process?',
      answers: [
        { type: 'button', text: 'Am I locked into my current contract?', messageId: '2.3.1' },
        { type: 'button', text: 'Will I be locked into a new contract?', messageId: '2.3.2' },
        { type: 'button', text: 'Are there any exit fees?', messageId: '2.3.3' }
      ]
    },
    {
      messageId: '2.3.1',
      message: (
        <>
          <p>You'll need to contact your current provider to discuss your contract terms.</p>
          <p>
            If you are in a contract, make sure you ask them whether there are exit fees, as this may allow you to move
            to a cheaper plan anyway.
          </p>
        </>
      )
    },
    {
      messageId: '2.3.2',
      message: (
        <>
          <p>
            Some plans have contracts, but these can often be beneficial to the customer as it commits the retailer to
            maintaining the rates you've secured with them for a contracted period of time.
          </p>
          <p>
            You can check underneath the providers logo on this comparison page to see the type of contracts that may
            apply to each plan.
          </p>
        </>
      )
    },
    {
      messageId: '2.3.3',
      message: (
        <p>
          You can check underneath the providers logo on this comparison page to see if Exit Fees apply to any given
          plan.
        </p>
      )
    },
    {
      level: 1,
      messageId: '3.0',
      message:
        "I'm glad you've found a suitable plan! Do you have any questions about this application page or the switch process?",
      answers: [
        { type: 'button', text: 'What happens when I switch?', messageId: '3.1' },
        { type: 'button', text: 'How is my personal information used?', messageId: '3.2' },
        { type: 'button', text: `What's the ${organiserName}?`, allowHtml: true, messageId: '3.3' }
      ]
    },
    {
      messageId: '3.1',
      message: (
        <>
          <p>
            When you switch, we'll send the information you provide on this form to the energy provider you've selected.
          </p>
          <p>
            That provider may check your credit history to determine whether you're eligable for the product you've
            selected.
          </p>
          <p>
            Once that's occurred, they'll either send you a Welcome Pack or let you know you're not eligable for that
            product.
          </p>
          <p>
            You will recieve one last bill from your current provider, then your new provider will take over your energy
            service.
          </p>
          <p>
            Timing for the change over from your old provider to your new provider will depend on when your meter was
            last read, and the regularity it's read.
          </p>
          <p>
            For example, if it's read every 8 weeks, and the last reading was a 6 weeks ago, your current provider will
            continue to provide you energy for 2 more weeks (until the next meter reading occurs).
          </p>
        </>
      )
    },
    {
      messageId: '3.2',
      message: (
        <>
          <p>Your new provider requires the information on this page to be able to process your application. </p>
          <p>We will send this information to the new provider through a secure network.</p>
          <p>Your information is ONLY used for this purpose. It is not shared with any other third parties.</p>
          <p>You can find more information in our Privacy Policy at the bottom of this page.</p>
          <hr />
          <p>Your personal information is secure.</p>
          <p>
            Once we process your application, we don't store your date of birth, drivers licence, concession number,
            medicare number or passport information (if provided) on our platform.
          </p>
          <p>
            Our systems use a very high level of security technology to both process and transfer your sensitive
            personal data to your new provider.
          </p>
          <p>
            You will not receive any third party offers because we don't share your personal information with other
            party, other than your new provider.
          </p>
          <p>You can find more information in our Privacy Policy at the bottom of this page.</p>
        </>
      )
    },
    {
      messageId: '3.3',
      message: (
        <div>
          <OrganiserDescription providerDisplayCode="your new energy provider" shortVersion />
        </div>
      )
    }
  ];

  //---------------------------------------------------------------------------
  // Chat Window: Open / Close
  //---------------------------------------------------------------------------
  const onOpen = () => {
    standardEvents('chat-bot', 'Click', 'Open', googleAnalyticsClientCode);
    setClosing(false);
    scrollToBottom('instant');

    setTimeout(function() {
      setIsOpen(true);
      dispatch(chatBotOptions(true, isPinned));
    }, 300);
  };

  const onClose = () => {
    standardEvents('chat-bot', 'Click', 'Close', googleAnalyticsClientCode);
    setClosing(true);

    setTimeout(function() {
      setIsOpen(false);
      dispatch(chatBotOptions(false, isPinned));
    }, 300);
  };

  const onPinToggle = value => {
    setIsPinned(value);
    dispatch(chatBotOptions(isOpen, value));
  };

  useEffect(() => {
    chatBotOpen ? onOpen() : onClose();
  }, [chatBotOpen]);

  const handleMouseDown = event => {
    if (!event.target.closest('#chatBot')) {
      onClose();
    }
  };

  useEffect(() => {
    if (chatBotPinned) {
      window.removeEventListener('mousedown', handleMouseDown);
    } else {
      window.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, [chatBotPinned]);

  //---------------------------------------------------------------------------
  // Page change
  //---------------------------------------------------------------------------
  const getRootMessage = () => {
    if (currentPage === navigationTypes.SWITCH) return getMessage('3.0');
    if (occupancyType === occupancyTypes.NEW) return getMessage('1.0');
    if (occupancyType === occupancyTypes.CURRENT) return getMessage('2.0');

    return getMessage('0.0');
  };

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    const lastRootMessage =
      messages[
        messages.slice().findLastIndex(e => {
          return e.level === 1;
        })
      ];

    const rootMessage = getRootMessage();

    if (lastMessage) {
      // If last root message is not the same as the new root message remove it to restart the message context.
      if (lastRootMessage.messageId !== rootMessage.messageId) {
        // If last message is a message with answers that have not been asked, delete it and add the root level message for the current context.
        if (lastMessage.answers && lastMessage.answers.every(a => !a.selected)) removeMessages(1);

        // If last message is the default message, delete it to start again.
        if (lastMessage.messageId === '0.0') removeMessages(1);

        addMessage(rootMessage);
      }
    } else {
      addMessage(rootMessage);
    }

    scrollToBottom();
  }, [currentPage]);

  //---------------------------------------------------------------------------
  // Message Control
  //---------------------------------------------------------------------------
  const scrollToBottom = behavior => {
    if (!contentRef || !contentRef.current) return;

    const chatWindow = contentRef.current;
    chatWindow.scrollTo({
      top: chatWindow.scrollHeight,
      behavior: behavior || 'smooth'
    });
  };

  const getMessage = id => {
    const message = data.find(e => {
      return e.messageId === id;
    });

    return message ? { ...message } : { ...getMessage('0.0') };
  };

  const addMessage = message => {
    setMessages(prev => [...prev, message]);
    setAnimateEllipsis(false);
  };

  const onAnswerClick = answerButton => {
    standardEvents('chat-bot', 'Click', 'Answer', googleAnalyticsClientCode);
    setAnimateEllipsis(true);

    setTimeout(() => {
      const currentMessage = removeMessages(1);
      const message = getMessage(currentMessage.messageId);

      message.answers.forEach(a => {
        a.selected = a.messageId === answerButton.messageId ? true : false;
      });

      addMessage(message);
      addMessage(getMessage(answerButton.messageId));
    }, ellipsisSeconds);
  };

  const getAnswerButton = a => {
    const text = a.allowHtml ? (
      <span
        dangerouslySetInnerHTML={{
          __html: a.text
        }}
      />
    ) : (
      a.text
    );

    if (a.selected === true) return <div className="selected-answer">{text}</div>;
    if (a.selected === false) return null;
    return (
      <Button
        disabled={animateEllipsis}
        className="sm-button-primary right-arrow-small medium"
        onClick={() => onAnswerClick(a)}
      >
        {text}
      </Button>
    );
  };

  const removeMessages = count => {
    const lastMessage = messages[messages.length - 1];

    if (messages.length > 0) {
      setMessages(prevChats => [...prevChats.slice(0, -count)]);
    }

    return lastMessage;
  };

  const onBack = () => {
    const previousMessage = messages[messages.length - 2];
    if (previousMessage) {
      removeMessages(2);

      addMessage(getMessage(previousMessage.messageId));
    }
  };

  const onRestart = () => {
    standardEvents('chat-bot', 'Click', 'Restart', googleAnalyticsClientCode);
    setAnimateEllipsis(true);

    setTimeout(function() {
      setMessages([]);
      addMessage(getRootMessage());
    }, ellipsisSeconds);
  };

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    setBackEnabled(messages.length > 1 && lastMessage.level !== 1);
    scrollToBottom();
  }, [messages, animateEllipsis]);

  return (
    <div id="chatBot" className={`sm-chatbot ${isOpen ? 'open' : 'closed'}`}>
      {!isOpen && (
        <div className={`chat-start animated ${closing ? 'zoomIn' : 'zoomOut'}`} onClick={onOpen}>
          <span className="material-symbols-outlined bubble">chat</span>
        </div>
      )}
      {isOpen && (
        <div className={`chat-main animated ${closing ? 'fadeOutDownBig' : 'fadeInUpSmall'}`}>
          <div className="header">
            <div className="header-buttons">
              <div className={`header-button ${isPinned ? 'pinned' : 'unpinned'}`}>
                <span
                  className={`${isPinned ? 'material-icons' : 'material-symbols-outlined'}`}
                  onClick={() => onPinToggle(!isPinned)}
                >
                  push_pin
                </span>
              </div>
              <Menu onRestart={onRestart} />
              <div className="header-button close-button">
                <span className="material-symbols-outlined" onClick={onClose}>
                  close
                </span>
              </div>
            </div>

            <div className="heading">Chatbot</div>
            <div className={`header-button back ${backEnabled ? 'enabled' : 'disabled'}`}>
              <span className="material-symbols-outlined" onClick={backEnabled ? onBack : undefined}>
                arrow_back
              </span>
            </div>
          </div>

          <div id="chatContent" ref={contentRef} className="content">
            {messages.map((e, i) => (
              <div key={i} className="message-container">
                {e.level === 1 && i > 0 && <hr />}
                <div className="message">
                  <div className="material-icons bot">chat</div>
                  <div>{e.message}</div>
                </div>
                {e.answers && e.answers.length > 0 && (
                  <div className="buttons">
                    {e.answers
                      .filter(a => a.type === 'button')
                      .map((button, j) => (
                        <div className="answer-button" key={`answer_${e.messageId}_${j}`}>
                          {getAnswerButton(button)}
                        </div>
                      ))}
                  </div>
                )}
                <div className="back-button-container">
                  <Button className="link-button back-button" onClick={onBack} disabled={animateEllipsis}>
                    {backEnabled && i === messages.length - 1 ? 'Back' : <span>&nbsp;</span>}
                  </Button>
                </div>
              </div>
            ))}
            {animateEllipsis && <Ellipsis />}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
