import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import { customerCommissionTypes, getCommonCommissionType } from '../../enums/customerCommissionTypes';

import CustomerCommissionLogo from '../common/customerCommissionLogo';
import { billTypes } from '../../enums/billTypes';

const Introduction = ({ onNext, serviceType }) => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const { estimateWizardIntroHeading } = useSelector(state => state.content.general);

  const commissionType = getCommonCommissionType(agentBillTypes);
  const hasCommission = commissionType !== customerCommissionTypes.NONE;

  return (
    <div className="introduction">
      {hasCommission && (
        <div>
          <CustomerCommissionLogo serviceType={billTypes.NONE} />
        </div>
      )}
      <div className="intro-text">{estimateWizardIntroHeading}</div>
      <Button className="sm-button-primary static medium" onClick={onNext}>
        <span className="button-text">Let's go</span>
      </Button>
    </div>
  );
};
export default Introduction;
