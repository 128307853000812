import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import { loanPropertyTypes, loanTypes, repaymentTypes } from '../../enums/submissionTypes';
import { uploadSubTypes } from '../../enums/uploadBillTypes';

const HomeLoanEstimateDetails = ({ userBill, onEdit }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  const submission = userBill.estimate;
  const bestOffer = userBill.bestOffer;

  const {
    refinance,
    provider,
    propertyType,
    loanType,
    repaymentType,
    currentLoanAmount,
    newLoanAmount,
    loanPeriod,
    monthlyRepayment
  } = submission;

  const { logo: providerLogo, displayCode: providerName } = provider || {};

  return (
    <>
      <div className="sm-estimate-details for-home-loan">
        <div className="intro-summary">{refinance ? userBill.displayAddress : ''}</div>

        <div className="detail-summary">
          <div className="detail-content">
            <div className="detail-row">
              <div className="detail-label">Loan Purpose:</div>
              <div className="detail-value">
                {refinance ? uploadSubTypes.REFINANCE.Name : uploadSubTypes.NEW_LOAN.Name}
              </div>
            </div>

            <div className="detail-row">
              <div className="detail-label">Property Type:</div>
              <div className="detail-value">{loanPropertyTypes.getById(propertyType).label}</div>
            </div>

            {refinance && (
              <div className="detail-row">
                <div className="detail-label">Current Loan Balance:</div>
                <div className="detail-value">${currentLoanAmount.toLocaleString()}</div>
              </div>
            )}

            <div className="detail-row">
              <div className="detail-label">New Loan Amount:</div>
              <div className="detail-value">${newLoanAmount.toLocaleString()}</div>
            </div>

            <div className="detail-row">
              <div className="detail-label">Loan Period:</div>
              <div className="detail-value">{loanPeriod} years</div>
            </div>

            <div className="detail-row">
              <div className="detail-label">Loan Type:</div>
              <div className="detail-value">{loanTypes.getById(loanType).label}</div>
            </div>

            <div className="detail-row">
              <div className="detail-label">Repayment:</div>
              <div className="detail-value">{repaymentTypes.getById(repaymentType).label}</div>
            </div>
          </div>

          {providerLogo && (
            <div>
              <img
                className="provider-logo"
                src={`${staticContentUrl}/images/providers/${providerLogo}`}
                alt={providerName}
              />
            </div>
          )}
        </div>

        <div className="drawer-buttons mt-3">
          <Button className="sm-button-secondary" onClick={onEdit}>
            Edit
          </Button>
        </div>
        <hr className="summary-divider" />

        {bestOffer && (
          <>
            {refinance && (
              <div className="summary-row">
                <div>Your current monthly repayment</div>
                <div>${monthlyRepayment.toLocaleString()}</div>
              </div>
            )}
            <div className="summary-row">
              <div>Your possible monthly repayment</div>
              <div>${bestOffer.monthlyRepayment.toLocaleString()}</div>
            </div>
            <div className="disclaimer-summary">{`Approx. charges for per day usage for days`}</div>
          </>
        )}
      </div>
    </>
  );
};

export default HomeLoanEstimateDetails;
