import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function sessionReducer(state = initialState.session, action) {
  switch (action.type) {
    case types.SET_SESSION:
      return objectAssign({}, state, action.data);

    default:
      return state;
  }
}
