import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Carousel, CarouselItem } from 'reactstrap';

import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { billTypes } from '../../enums/billTypes';
import { buttonDirection } from '../../enums/buttonTypes';

import { getItemContent } from '../../actions/contentActions';

const HeroInfo = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const { organiserAgentName } = useSelector(state => state.content.general);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const agentServiceType = getAgentServiceType(billTypes.ELECTRICITY, agentBillTypes);
  const commissionType = agentServiceType.commissionType;

  const carouselAnimationClasses = 'animated delay-2s pulse slow repeat-10';

  const [nextCarouselAnimateClass, setNextCarouselAnimateClass] = useState(carouselAnimationClasses);

  const { heroInfoItems } = useSelector(state => state.content.start);
  const items = heroInfoItems ? JSON.parse(heroInfoItems) : [];

  const onNext = () => {
    if (animating === true) return;

    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setIndex(nextIndex, buttonDirection.Next);
  };

  const onPrevious = () => {
    if (animating === true) return;

    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setIndex(nextIndex, buttonDirection.Previous);
  };

  const setIndex = (index, direction) => {
    setActiveIndex(index);
    resetNextCarouselAnimation();
  };

  const resetNextCarouselAnimation = () => {
    setNextCarouselAnimateClass('');

    setTimeout(() => {
      setNextCarouselAnimateClass(carouselAnimationClasses);
    }, 500);
  };

  const slides = items.map((item, index) => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={`c-item-${index}`}>
        <div
          className="heading"
          dangerouslySetInnerHTML={{
            __html: item.title
          }}
        />
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: getItemContent(item.content, organiserAgentName, commissionType)
          }}
        />
      </CarouselItem>
    );
  });

  const firstSlide = activeIndex === 0;
  const finalSlide = activeIndex === items.length - 1;

  useEffect(() => {
    const timer = setTimeout(() => {
      onNext();
    }, 8000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="sm-hero-info">
      <div className="content">
        <Carousel interval={7000} wrap={true} activeIndex={activeIndex} next={onNext} previous={onPrevious}>
          {slides}
        </Carousel>
      </div>
      <div className="footer">
        <div>
          {!firstSlide && (
            <span
              className={`arrow ${nextCarouselAnimateClass} material-icons`}
              aria-hidden="true"
              onClick={onPrevious}
            >
              arrow_back
            </span>
          )}
          {!finalSlide && (
            <span className={`arrow ${nextCarouselAnimateClass} material-icons`} aria-hidden="true" onClick={onNext}>
              arrow_forward
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default HeroInfo;
