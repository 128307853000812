import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _debounce from 'lodash.debounce';

import QsCheckBox from './qsCheckBox';
import { setUser } from '../../actions/userActions';
import { setComparison } from '../../actions/comparisonActions';
import { anonymousModes } from '../../enums/authenticationTypes';
import AgreeTerms from './agreeTerms';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { billTypes } from '../../enums/billTypes';
import EmailAddress from './emailAddress';
import SubmitAgentMember from './submitAgentMember';
import { operatorModes } from '../../enums/launchTypes';

const SubmitCommon = ({
  hasSubmitted,
  onLoad,
  onChange,
  children,
  refresh,
  hideEmailAddress,
  showSendComparisonEmail,
  showSendArticlesEmail,
  showSendSolarInstallationEmail,
  serviceType,
  forceRequireEmailAddress,
  forceRequireSendEmail,
  showNote: userShowNote
}) => {
  const { loaded: userLoaded, emailAddress: userEmailAddress } = useSelector(state => state.user);
  const { preferences } = useSelector(state => state.user);
  const { termsAndConditionsAccepted, sendComparisonEmail } = useSelector(state => state.comparison);
  const { hasAgents, displayName: agentDisplayName, billTypes: agentBillTypes } = useSelector(state => state.config);
  const { operatorMode } = useSelector(state => state.landing.home);
  const { operatorId } = useSelector(state => state.landing.comparison);
  const { anonymousMode, articlesEnabled } = useSelector(state => state.config.settings);
  const { selectedAgent } = useSelector(state => state);

  const [emailAddress, setEmailAddress] = useState(userEmailAddress || null);
  const [hasValidEmail, setHasValidEmail] = useState();
  const [agentMemberName, setAgentMemberName] = useState(selectedAgent.name ? selectedAgent.name : '');
  const [termsAndConditions, setTermsAndConditions] = useState(termsAndConditionsAccepted);
  const [comparisonEmail, setComparisonEmail] = useState(forceRequireSendEmail || sendComparisonEmail ? true : false);
  const [articlesEmail, setArticlesEmail] = useState();
  const [solarInstallationEmail, setSolarInstallationEmail] = useState(false);

  const dispatch = useDispatch();

  const showEmailAddress =
    hideEmailAddress !== true &&
    (forceRequireEmailAddress || anonymousMode === anonymousModes.NONE || anonymousMode === anonymousModes.OPTIONAL);
  const newsEnabled = preferences && preferences.communication && preferences.communication.newsEnabledEmail;
  const showArticleEmail = articlesEnabled && showSendArticlesEmail && showEmailAddress && !newsEnabled;
  const showNote = userShowNote !== false;

  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
  const agentSolarServiceType = getAgentServiceType(billTypes.SOLAR_INSTALLATION, agentBillTypes);

  const customerCommissionNamePlural =
    agentServiceType && agentServiceType.hasCommission ? agentServiceType.commissionType.Name : null;

  const energyServiceType = [billTypes.ELECTRICITY, billTypes.GAS].includes(serviceType);
  const hasSolar = billTypes.hasServiceType(agentBillTypes, billTypes.SOLAR_INSTALLATION, {
    includeAffiliates: true
  });
  const showSolarInstallationEmail =
    showSendSolarInstallationEmail && energyServiceType && hasSolar && agentSolarServiceType.hasAffiliateSettings;

  // --------------------------------------------------------------------------
  // Email address change event handler.
  // --------------------------------------------------------------------------
  const onEmailAddressChange = value => {
    setEmailAddress(value || null);
    emailAddressChanged(value || null);
  };

  const emailAddressChanged = useCallback(
    _debounce(value => {
      dispatch(setUser({ emailAddress: value }));
    }, 250),
    []
  );

  const onTermsAndConditionsChange = value => {
    setTermsAndConditions(value);
    dispatch(setComparison({ termsAndConditionsAccepted: value }));
  };

  const onComparisonEmailChange = value => {
    setComparisonEmail(value);
    dispatch(setComparison({ sendComparisonEmail: value }));
  };

  const onArticlesEmailChange = value => {
    setArticlesEmail(value);
    dispatch(setComparison({ sendArticlesEmail: value }));
  };

  const onSolarInstallationEmailChange = value => {
    setSolarInstallationEmail(value);
    dispatch(setComparison({ sendSolarInstallationEmail: value }));
  };

  // --------------------------------------------------------------------------
  // Detect changes in component and fire parent onChange event.
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (onChange) {
      onChange(
        {
          emailAddress,
          agentMemberName,
          termsAndConditions,
          sendComparisonEmail: hasValidEmail && (forceRequireSendEmail || comparisonEmail) ? true : false,
          sendArticlesEmail: hasValidEmail && articlesEmail ? true : false,
          sendSolarInstallationEmail: hasValidEmail && solarInstallationEmail ? true : false,
          operatorId
        },
        hasValidEmail
      );
    }
  }, [
    emailAddress,
    agentMemberName,
    termsAndConditions,
    comparisonEmail,
    articlesEmail,
    solarInstallationEmail,
    forceRequireSendEmail,
    forceRequireEmailAddress,
    hasValidEmail
  ]);

  useEffect(() => {
    if (onLoad) {
      onLoad(
        {
          emailAddress,
          agentMemberName,
          termsAndConditions,
          sendComparisonEmail: forceRequireSendEmail || comparisonEmail ? true : false,
          sendArticlesEmail: articlesEmail ? true : false,
          sendSolarInstallationEmail: solarInstallationEmail ? true : false,
          operatorId
        },
        hasValidEmail
      );
    }
  }, []);

  // --------------------------------------------------------------------------
  // Force component refresh.
  // --------------------------------------------------------------------------
  useEffect(() => {
    setEmailAddress(userEmailAddress || null);
  }, [refresh]);

  return (
    <>
      {showEmailAddress && (
        <>
          <EmailAddress
            value={emailAddress}
            disabled={userLoaded && userEmailAddress ? true : false}
            onChange={value => onEmailAddressChange(value)}
            onValidate={isValid => setHasValidEmail(isValid)}
            forceRequireEmailAddress={forceRequireEmailAddress}
          />
          {showNote ? (
            <div className="qs-submit-email-address-note">
              {!forceRequireEmailAddress && anonymousMode === anonymousModes.OPTIONAL
                ? 'Enter your email address to get your comparison results in your inbox.'
                : 'A copy of your comparison results will be sent to this email address.'}
            </div>
          ) : (
            <div className="qs-submit-email-address-no-note" />
          )}
        </>
      )}

      {!selectedAgent.isLoaded && hasAgents && (
        <SubmitAgentMember
          hasSubmitted={hasSubmitted}
          agentMemberName={agentMemberName}
          onChange={value => setAgentMemberName(value)}
        />
      )}

      {children}

      {operatorMode === operatorModes.NONE && (
        <>
          {showSendComparisonEmail && showEmailAddress && (
            <QsCheckBox
              enabled={hasValidEmail && !forceRequireSendEmail}
              isChecked={forceRequireSendEmail || (hasValidEmail && sendComparisonEmail) ? true : false}
              onCheck={() => onComparisonEmailChange(!sendComparisonEmail)}
              label="Email me a copy of my comparison results"
            />
          )}

          {showArticleEmail && (
            <div className="mt-2">
              <QsCheckBox
                enabled={hasValidEmail}
                isChecked={hasValidEmail && articlesEmail ? true : false}
                onCheck={() => onArticlesEmailChange(!articlesEmail)}
                label={`Send me ${agentDisplayName} bill saving ideas${
                  customerCommissionNamePlural ? ` and ${customerCommissionNamePlural} offers` : ''
                }.`}
              />
            </div>
          )}

          {showSolarInstallationEmail && (
            <>
              <div className="mt-2">
                <QsCheckBox
                  enabled={hasValidEmail}
                  isChecked={hasValidEmail && solarInstallationEmail ? true : false}
                  onCheck={() => onSolarInstallationEmailChange(!solarInstallationEmail)}
                  label={
                    agentSolarServiceType.affiliateSettings.checkboxLabel ||
                    'Email me information about quality solar installers in my area.'
                  }
                />
              </div>
            </>
          )}

          <div className="mt-2">
            <QsCheckBox
              isChecked={termsAndConditions}
              onCheck={() => onTermsAndConditionsChange(!termsAndConditions)}
              label={<AgreeTerms serviceType={serviceType} />}
              validatorName="submit-common-terms"
              validationMessage="Please accept the terms and conditions"
            />
          </div>
        </>
      )}
    </>
  );
};

export default SubmitCommon;
