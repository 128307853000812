import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { contextTypes } from '../../enums/submissionTypes';
import { billTypes } from '../../enums/billTypes';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { pageModes } from '../../enums/launchTypes';
import { postFrameScrollTopMessage } from '../../utilities/domUtilities';

const ContextSpinner = () => {
  const { count, context, serviceType } = useSelector(state => state.ajaxCall);
  const { logo } = useSelector(state => state.content.images);
  const { panelProviders, billTypes: agentBillTypes } = useSelector(state => state.config);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { pageMode, frameHost } = useSelector(state => state.application);

  const [isActiveState, setIsActiveState] = useState(false);
  const [contextState, setContextState] = useState(null);
  const [currentText, setCurrentText] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [animateClass, setAnimateClass] = useState(null);

  const currentSeconds = useRef(0);
  const timer = useRef();

  const advanceText = useCallback(() => {
    currentSeconds.current++;

    const contextItem =
      contexts.find(item => pageMode === pageModes.FRAMED && item.context === contextTypes.FRAME_PARTNER_COMPARISON) ||
      contexts.find(item => item.context === context && item.serviceType === serviceType) || // specific service type and context if exists;
      contexts.find(item => context === contextTypes.PARTNER_COMPARISON && item.context === context) || // otherwise prioritize partner referral context over service type context in case implemeted comparison exists;
      contexts.find(item => context === contextTypes.AFFILIATE_REFERRAL && item.context === context) || // otherwise prioritize affiliate referral context over service type context in case implemeted comparison exists;
      contexts.find(item => item.serviceType === serviceType) || // otherwise fallback to specific service type;
      contexts.find(item => item.context === context); // finally use high-level context

    if (!contextItem) return;

    const contextItemText = contextItem.texts.find(text => text.startAt === currentSeconds.current);

    if (!contextItemText) {
      setAnimateClass(null);
      return;
    }

    setCurrentText(contextItemText.text);
    setShowDetail(contextItem.showDetail);
    setAnimateClass(contextItemText.animateIn);
  }, [context]);

  const startTimer = () => {
    if (timer.current) clearInterval(timer.current);

    currentSeconds.current = 0;
    timer.current = setInterval(advanceText, 1000);
  };

  useEffect(() => {
    var isActive = count > 0;

    if (isActive) {
      if (pageMode === pageModes.FRAMED) {
        //postFrameScrollTopMessage(frameHost);
      }

      if (isActive && !isActiveState) {
        // Moved into active state
        setIsActiveState(true);

        if (context) {
          setContextState(context);
          startTimer();
        }
      } else if (context && !contextState) {
        // Context was empty now has value
        setContextState(context);
        startTimer();
      } else if (context && contextState && context !== contextState) {
        // Context has changed
        setContextState(context);
        startTimer();
      }
    } else {
      if (timer.current) clearInterval(timer.current);
      if (currentText) setCurrentText(null);
      if (contextState) setContextState(null);
      if (showDetail) setShowDetail(false);
      if (!isActiveState) setIsActiveState(false);
    }

    return () => {};
  }, [count, context, isActiveState, contextState, currentText, animateClass]);

  const spinnerContainerClass = `sm-spinner-active${showDetail ? ' with-detail' : ''}`;
  const spinnerClass = `spinner${showDetail ? ' with-detail' : ''}${pageMode === pageModes.FRAMED ? ' framed' : ''}`;

  const contexts = [
    {
      showDetail: true,
      context: contextTypes.BILL_COMPARISON,
      texts: [
        { text: 'Comparing your bill data with plans from these providers...', startAt: 1, animateIn: 'fadeInUpSmall' },
        { text: 'Calculating your possible spend...', startAt: 4, animateIn: 'fadeInUpSmall' },
        { text: 'Compiling comparison results...', startAt: 9, animateIn: 'fadeInUpSmall' },
        { text: 'Nearly done ...', startAt: 15, animateIn: 'fadeInUpSmall' },
        { text: 'Thanks for your patience...', startAt: 25, animateIn: 'fadeInUpSmall' }
      ]
    },
    {
      showDetail: true,
      context: contextTypes.ESTIMATE_COMPARISON,
      texts: [
        { text: 'Comparing plans for your suburb from these providers...', startAt: 1, animateIn: 'fadeInUpSmall' },
        { text: 'Applying your preferences...', startAt: 4, animateIn: 'fadeInUpSmall' },
        { text: 'Compiling comparison results...', startAt: 9, animateIn: 'fadeInUpSmall' },
        { text: 'Nearly done...', startAt: 15, animateIn: 'fadeInUpSmall' },
        { text: 'Thanks for your patience...', startAt: 25, animateIn: 'fadeInUpSmall' }
      ]
    },
    {
      showDetail: true,
      context: contextTypes.EMAIL_COMPARISON,
      texts: [
        { text: 'Retrieving latest plans from market...', startAt: 1, animateIn: 'fadeInUpSmall' },
        { text: 'Finding plans for your suburb...', startAt: 4, animateIn: 'fadeInUpSmall' },
        { text: 'Compiling comparison result...', startAt: 9, animateIn: 'fadeInUpSmall' },
        { text: 'Nearly done...', startAt: 15, animateIn: 'fadeInUpSmall' },
        { text: 'Thanks for your patience...', startAt: 25, animateIn: 'fadeInUpSmall' }
      ]
    },
    {
      showDetail: true,
      context: contextTypes.DIRECT_SWITCH,
      texts: [
        { text: 'Preparing your switch form...', startAt: 1, animateIn: 'fadeInUpSmall' },
        {
          text: 'Please have your drivers license or Medicare card ready to use as ID...',
          startAt: 4,
          animateIn: 'fadeInUpSmall'
        },
        { text: 'This switch should take around a minute to complete...', startAt: 9, animateIn: 'fadeInUpSmall' },
        { text: 'Nearly done...', startAt: 15, animateIn: 'fadeInUpSmall' },
        { text: 'Thanks for your patience...', startAt: 25, animateIn: 'fadeInUpSmall' }
      ]
    },
    {
      showDetail: true,
      context: contextTypes.CUSTOMER_DASHBOARD,
      texts: [
        { text: 'Retreiving your details...', startAt: 1, animateIn: 'fadeInUpSmall' },
        { text: 'Nearly done...', startAt: 15, animateIn: 'fadeInUpSmall' },
        { text: 'Thanks for your patience...', startAt: 25, animateIn: 'fadeInUpSmall' }
      ]
    },
    {
      showDetail: true,
      context: contextTypes.CUSTOMER_SURVEY,
      texts: [
        { text: 'Retreiving your survey...', startAt: 1, animateIn: 'fadeInUpSmall' },
        { text: 'Nearly done...', startAt: 15, animateIn: 'fadeInUpSmall' },
        { text: 'Thanks for your patience...', startAt: 25, animateIn: 'fadeInUpSmall' }
      ]
    },
    {
      showDetail: false,
      context: contextTypes.FRAME_PARTNER_COMPARISON,
      texts: []
    },
    {
      showDetail: true,
      context: contextTypes.PARTNER_COMPARISON,
      texts: [
        {
          text: `Initiating ${serviceType ? serviceType.Name.toLowerCase() : ''} comparison...`,
          startAt: 1,
          animateIn: 'fadeInUpSmall'
        },
        { text: 'Nearly done...', startAt: 15, animateIn: 'fadeInUpSmall' },
        { text: 'Thanks for your patience...', startAt: 25, animateIn: 'fadeInUpSmall' }
      ]
    },
    {
      showDetail: true,
      context: contextTypes.AFFILIATE_REFERRAL,
      texts: [
        {
          text: `Initiating ${serviceType ? serviceType.Name.toLowerCase() : ''} comparison...`,
          startAt: 1,
          animateIn: 'fadeInUpSmall'
        },
        { text: 'Nearly done...', startAt: 15, animateIn: 'fadeInUpSmall' },
        { text: 'Thanks for your patience...', startAt: 25, animateIn: 'fadeInUpSmall' }
      ]
    },
    {
      showDetail: true,
      serviceType: billTypes.INTERNET,
      texts: [
        { text: 'Finding plans for your address...', startAt: 1, animateIn: 'fadeInUpSmall' },
        { text: 'Applying your preferences...', startAt: 4, animateIn: 'fadeInUpSmall' },
        { text: 'Compiling comparison results...', startAt: 9, animateIn: 'fadeInUpSmall' },
        { text: 'Nearly done...', startAt: 15, animateIn: 'fadeInUpSmall' },
        { text: 'Thanks for your patience...', startAt: 25, animateIn: 'fadeInUpSmall' }
      ]
    },
    {
      showDetail: true,
      serviceType: billTypes.HOME_LOAN,
      texts: [
        { text: 'Finding loans for your property...', startAt: 1, animateIn: 'fadeInUpSmall' },
        { text: 'Applying your preferences...', startAt: 4, animateIn: 'fadeInUpSmall' },
        { text: 'Compiling comparison results...', startAt: 9, animateIn: 'fadeInUpSmall' },
        { text: 'Nearly done...', startAt: 15, animateIn: 'fadeInUpSmall' },
        { text: 'Thanks for your patience...', startAt: 25, animateIn: 'fadeInUpSmall' }
      ]
    }
  ];

  const getPanelProviders = () => {
    if (serviceType === billTypes.NONE) {
      return panelProviders.filter(
        p =>
          p.provider.billTypes.filter(bt => bt.id === billTypes.ELECTRICITY.Id || bt.id === billTypes.GAS.Id).length > 0
      );
    } else {
      return panelProviders.filter(p => p.provider.billTypes.filter(bt => bt.id === serviceType.Id).length > 0);
    }
  };

  const getAffiliateProviders = () => {
    const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);

    return agentServiceType.affiliate ? agentServiceType.affiliate.providers : [];
  };

  const getDetail = () => {
    switch (context) {
      case contextTypes.DIRECT_SWITCH:
      case contextTypes.CUSTOMER_DASHBOARD:
      case contextTypes.CUSTOMER_SURVEY:
        return (
          <div className="logo">
            <img src={`${staticContentUrl}${logo}`} alt="logo" />
          </div>
        );

      case contextTypes.PARTNER_COMPARISON:
        return null;

      case contextTypes.AFFILIATE_REFERRAL:
        return getAffiliateProviders().map((item, index) => {
          return (
            item.provider.logo && (
              <div className="logo-container" key={item.provider.code}>
                <img
                  key={`provider-logo-${index}`}
                  height="50px"
                  src={`${staticContentUrl}/images/providers/${item.provider.logo}`}
                  alt={item.provider.displayCode}
                />
              </div>
            )
          );
        });

      default:
        const providers = getPanelProviders();

        // Don't show small provider sets (< 5) unless we are targeting a single provider.
        if (!providers || (providers.length > 1 && providers.length < 5)) return null;

        return getPanelProviders().map((item, index) => {
          return (
            item.provider.logo && (
              <div className="logo-container" key={item.provider.code}>
                <img
                  key={`provider-logo-${index}`}
                  height="50px"
                  src={`${staticContentUrl}/images/providers/${item.provider.logo}`}
                  alt={item.provider.displayCode}
                />
              </div>
            )
          );
        });
    }
  };

  return (
    count > 0 && (
      <>
        <div className={spinnerContainerClass}>
          <div className={spinnerClass}>
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>

        {showDetail ? (
          <div className="spinner-detail-container">
            <div className="detail-spinner">
              <div className={spinnerClass}>
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
              </div>
            </div>
            {currentText && <div className={`spinner-text animated ${animateClass}`}>{currentText}</div>}

            <div className="spinner-providers">{getDetail()}</div>
          </div>
        ) : (
          currentText && <div className={`spinner-text animated ${animateClass}`}>{currentText}</div>
        )}
      </>
    )
  );
};

export default ContextSpinner;
