import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Button } from 'reactstrap';

import { getAgentServiceType } from '../../enums/customerCommissionTypes';

import GeneralDialog from './generalDialog';
import CustomerCommissionTerms from '../common/customerCommissionTerms';

const CommissionTermsDialog = ({ serviceType, providerCode, dialogKey, onOpen, buttonText, style }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState();

  const onDialogToggle = event => {
    // Prevent checkbox from being toggled in cases where dialog button is being used in the label
    event.stopPropagation();

    if (!isOpen && onOpen) {
      onOpen();
    }

    setIsOpen(!isOpen);
  };

  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { logo } = useSelector(state => state.content.images);

  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
  const provider = providerCode ? agentServiceType.providers.find(e => e.providerCode === providerCode) : null;
  const commissionName = agentServiceType.commissionType.NamePlural;
  const commissionTag = agentServiceType.commissionType.Tag;

  const providerDisplayCode = provider ? provider.providerDisplayCode : null;
  const providerCommmissionText = provider ? provider.customerCommissionFormatted : null;
  const providerCommmissionLogo = provider ? (
    provider.customerCommissionLogo ? (
      <img src={`${staticContentUrl}${provider.customerCommissionLogo}`} alt={providerCommmissionText} />
    ) : (
      providerCommmissionText
    )
  ) : null;

  const serviceTypeCommmissionText = agentServiceType.agentBillType.customerCommissionFormatted;
  const serviceTypeCommmissionLogo = agentServiceType.agentBillType.customerCommissionLogo ? (
    <img
      src={`${staticContentUrl}${agentServiceType.agentBillType.customerCommissionLogo}`}
      alt={serviceTypeCommmissionText}
    />
  ) : (
    serviceTypeCommmissionText
  );

  const dualCommission = serviceTypeCommmissionLogo && providerCommmissionLogo ? true : false;

  return (
    <>
      <Button style={style} className="sm-link-button" onClick={onDialogToggle}>
        {buttonText || `${commissionTag} terms`}
      </Button>

      <GeneralDialog
        key={dialogKey}
        dialogSize="md"
        closeButtonText="Close"
        showCloseIcon={false}
        headingIcon={<img src={`${staticContentUrl}${logo}`} alt="dialog-heading" />}
        headingCenter={true}
        dialogOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
      >
        <div className="sm-sponsor-commission-dialog">
          <div className="heading">{commissionName} offer</div>

          <div className="logo-container">
            {serviceTypeCommmissionLogo && (
              <div className="logo">
                {serviceTypeCommmissionLogo}
                {dualCommission && <span className="plus"> + </span>}
              </div>
            )}

            {providerCommmissionLogo && (
              <div className="logo">
                {providerCommmissionLogo}
                <div className="caption">
                  {providerDisplayCode} {dualCommission ? <div className="caption">BONUS OFFER</div> : 'BONUS OFFER'}
                </div>
              </div>
            )}
          </div>

          <div className="description">
            <b>Terms:</b>{' '}
            <CustomerCommissionTerms serviceType={serviceType} commissionType={agentServiceType.commissionType} /> For
            more information, please see our FAQs{' '}
            <Button
              className="sm-link-button inline"
              onClick={() => {
                setIsOpen(false);
                dispatch(push('/faq#faq_offer'));
              }}
            >
              here
            </Button>
            .
          </div>
        </div>
      </GeneralDialog>
    </>
  );
};

export default CommissionTermsDialog;
