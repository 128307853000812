import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import AddressControl from '../common/addressControl';
import SubmitCommon from '../common/submitCommon';
import Slider from '../common/slider';
import ExitLaunch from '../common/exitLaunch';
import ServicesAuto from '../serviceCard/servicesAuto';
import { billTypes } from '../../enums/billTypes';

import { getAddress } from '../../utilities/addressUtilities';
import { setUser } from '../../actions/userActions';

import 'react-google-places-autocomplete/dist/index.min.css';

const HomeLoanCurrentAu = ({ onBack, onNext, currentHomeLoanData, userBill, launchMode }) => {
  const dispatch = useDispatch();

  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { country, hasAgents } = useSelector(state => state.config);
  const { selectedAgent } = useSelector(state => state);

  const { panelProviders } = useSelector(state => state.config);

  const rowClass = 'sm-dialog-row';
  const fieldClass = 'sm-dialog-field-name';

  const estimatedPropertyValueLabel = 'Current Property Value Estimate';
  const loanAmountLabel = 'Current Loan Balance';
  const monthlyRepaymentsLabel = 'Current Monthly Repayments';

  const showAgents = !selectedAgent.isLoaded && hasAgents;
  const otherFinancial = { code: 'other', displayCode: 'Other', logo: null };
  const noneFinancial = { code: null, displayCode: null, logo: null };

  const showBackButton = !userBill; // Only show back button if we are showing an empty card.

  const {
    propertyAddress,
    propertyValue,
    currentLoanAmount,
    monthlyRepayment,
    providerCode,
    completedNotification
  } = currentHomeLoanData;

  // --------------------------------------------------------------------------
  // Get Financial Provider
  // --------------------------------------------------------------------------
  const getFinancialProvider = code => {
    if (code === null) return noneFinancial;
    if (code === otherFinancial.code) return otherFinancial;

    var item = panelProviders.find(item => item.provider.code === code);
    return item ? item.provider : otherFinancial;
  };

  const initialFinancial = getFinancialProvider(providerCode);

  const [selectedAddress, setSelectedAddress] = useState(propertyAddress);
  const [financialDropDownOpen, setFinancialDropdownOpen] = useState(false);

  const [selectedFinancialInstitution, setSelectedFinancialInstitution] = useState(initialFinancial);
  const [hasChanges, setHasChanges] = useState(false);

  //const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submissionCommon, setSubmissionCommon] = useState({});
  const [submissionCommonRefresh, setSubmissionCommonRefresh] = useState();
  const [initialComparisonEmailValue, setInitialComparisonEmailValue] = useState();
  const [initialEmailAddressValue, setInitialEmailAddressValue] = useState(null);

  const [selectedPropertyValue, setSelectedPropertyValue] = useState(propertyValue);
  const [selectedLoanAmount, setSelectedLoanAmount] = useState(currentLoanAmount);
  const [selectedMonthlyRepayment, setSelectedMonthlyRepayment] = useState(monthlyRepayment);

  // --------------------------------------------------------------------------
  // Initialize the form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    initialize();
  }, []);

  // --------------------------------------------------------------------------
  // Estimate completed notification.
  // --------------------------------------------------------------------------
  useEffect(() => {
    setHasChanges(false);
  }, [completedNotification]);

  // --------------------------------------------------------------------------
  // Detect changes in form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    const addressA = getAddress(selectedAddress);
    const addressB = getAddress(propertyAddress);

    let changed =
      addressA.fullAddress !== addressB.fullAddress ||
      addressA.unitNumber !== addressB.unitNumber ||
      selectedFinancialInstitution.code !== providerCode ||
      propertyValue !== selectedPropertyValue ||
      selectedMonthlyRepayment !== monthlyRepayment ||
      selectedLoanAmount !== currentLoanAmount ||
      (initialEmailAddressValue ? initialEmailAddressValue : null) !==
        (submissionCommon.emailAddress ? submissionCommon.emailAddress : null);

    setHasChanges(changed);
  }, [
    selectedPropertyValue,
    selectedLoanAmount,
    selectedMonthlyRepayment,
    selectedFinancialInstitution,
    selectedAddress,
    submissionCommon.emailAddress
  ]);

  // --------------------------------------------------------------------------
  // Initialize or reset the form.
  // --------------------------------------------------------------------------
  const initialize = reset => {
    const provider = getFinancialProvider(providerCode);

    setSelectedFinancialInstitution(provider);

    setSelectedPropertyValue(propertyValue);
    setSelectedLoanAmount(currentLoanAmount);
    setSelectedMonthlyRepayment(monthlyRepayment);

    if (reset) {
      setSelectedAddress(propertyAddress);
      dispatch(setUser({ emailAddress: initialEmailAddressValue }));
      setSubmissionCommonRefresh(Date.now());
    }

    setHasChanges(false);
  };

  // --------------------------------------------------------------------------
  // Financial Providers: Get DropdownItem element.
  // --------------------------------------------------------------------------
  let currentLetter;

  const dropdownItems = (() => {
    var items = panelProviders
      .filter(p => p.provider.billTypes.filter(bt => bt.id === billTypes.HOME_LOAN.Id).length > 0)
      .sort(function(a, b) {
        return a.provider.displayCode.toLowerCase().localeCompare(b.provider.displayCode.toLowerCase());
      })
      .map(item => {
        const firstLetter = item.provider.displayCode.charAt(0);
        const element = (
          <DropdownItem key={item.provider.code} id={item.provider.code} onClick={e => onFinancialInstitutionChange(e)}>
            {item.provider.displayCode}
          </DropdownItem>
        );

        if (!currentLetter || currentLetter !== firstLetter) {
          currentLetter = firstLetter;
          return (
            <React.Fragment key={firstLetter.toUpperCase()}>
              <DropdownItem header>{firstLetter.toUpperCase()}</DropdownItem>
              <DropdownItem divider /> {element}
            </React.Fragment>
          );
        } else {
          return element;
        }
      });
    items.push(
      <React.Fragment key={'__financialOther'}>
        <DropdownItem divider />
        <DropdownItem key={otherFinancial.code} id={otherFinancial.code} onClick={e => onFinancialInstitutionChange(e)}>
          {otherFinancial.name}
        </DropdownItem>
      </React.Fragment>
    );
    return items;
  })();

  // --------------------------------------------------------------------------
  // Financial Institution change handler.
  // --------------------------------------------------------------------------
  const onFinancialInstitutionChange = event => {
    const provider = getFinancialProvider(event.currentTarget.id);

    setSelectedFinancialInstitution(provider);
  };

  // --------------------------------------------------------------------------
  // Address selected handler.
  // --------------------------------------------------------------------------
  const onAddressSelected = address => {
    setSelectedAddress(address);
  };

  // --------------------------------------------------------------------------
  // Next button click handler.
  // --------------------------------------------------------------------------
  const onNextButtonClick = () => {
    const data = {
      ...submissionCommon,
      propertyAddress: selectedAddress,
      propertyValue: selectedPropertyValue,
      currentLoanAmount: selectedLoanAmount,
      monthlyRepayment: selectedMonthlyRepayment,
      providerCode: selectedFinancialInstitution === otherFinancial ? null : selectedFinancialInstitution.code
    };

    onNext(data, hasChanges);
  };

  // --------------------------------------------------------------------------
  // Submission common handlers.
  // --------------------------------------------------------------------------
  const onSubmissionCommonLoad = value => {
    if (value) {
      setInitialComparisonEmailValue(value.sendComparisonEmail);
      setInitialEmailAddressValue(value.emailAddress);
      setSubmissionCommon(value);
    }
  };

  const onSubmissionCommonChange = value => {
    setSubmissionCommon(value);
  };

  return (
    <>
      <div className="sm-home-loan-dialog-heading">Tell us about your current loan...</div>

      {/* --- ADDRESS ----------------------------------------------------------- */}
      <div className={`${rowClass} mb-3`}>
        <div className="sm-home-loan-dialog-address">
          <AddressControl
            key="homeLoanAddress"
            keySuffix="home-loan"
            labelText="Property Address"
            country={country}
            onAddressSelected={onAddressSelected}
            autoPlaceholder={selectedAddress.fullAddress || 'Property address...'}
            autoPropertyAddress={selectedAddress}
            autoAddressRequired={true}
            manualAddressVisible={true}
            manualSuburbSelectorEnabled={true}
            showAreasSupportedDisclaimer={false}
            showMinimalManualFields={false}
          />
        </div>
      </div>

      {/* --- ESTIMATED PROPERTY VALUE ------------------------------------------ */}
      <div className={rowClass}>
        <div className={`${fieldClass} slider`}>{estimatedPropertyValueLabel}</div>
        <div>
          <Slider
            required
            allowModeChange
            fieldName={estimatedPropertyValueLabel}
            value={selectedPropertyValue}
            onFinalChange={value => setSelectedPropertyValue(value)}
            min={50000}
            max={5000000}
            step={25000}
            labelPrefix="$"
            maxValueSuffix="+"
          />
        </div>
      </div>

      {/* --- LOAN AMOUNT ------------------------------------------------------- */}
      <div className={rowClass}>
        <div className={`${fieldClass} slider`}>{loanAmountLabel}</div>
        <div>
          <Slider
            required
            allowModeChange
            fieldName={loanAmountLabel}
            value={selectedLoanAmount}
            onFinalChange={value => setSelectedLoanAmount(value)}
            min={50000}
            max={5000000}
            step={25000}
            labelPrefix="$"
            maxValueSuffix="+"
          />
        </div>
      </div>

      {/* --- MONTHLY REPAYMENTS ------------------------------------------------ */}
      <div className={rowClass}>
        <div className={`${fieldClass} slider`}>{monthlyRepaymentsLabel}</div>
        <div>
          <Slider
            required
            allowModeChange
            fieldName={monthlyRepaymentsLabel}
            value={selectedMonthlyRepayment}
            onFinalChange={value => setSelectedMonthlyRepayment(value)}
            min={1}
            max={10000}
            step={100}
            labelPrefix="$"
            maxValueSuffix="+"
          />
        </div>
      </div>
      {/* --- CURRENT FINANCIAL INSTITUTION ------------------------------------- */}
      <div className={rowClass}>
        <div className={fieldClass}>Financial Institute</div>
        <div className="sm-financial-institution">
          <Dropdown
            className="sm-dropdown"
            isOpen={financialDropDownOpen}
            toggle={() => setFinancialDropdownOpen(prevState => !prevState)}
          >
            <DropdownToggle caret>{selectedFinancialInstitution.displayCode || 'Please Select'}</DropdownToggle>
            <DropdownMenu style={{ width: '100%' }}>{dropdownItems}</DropdownMenu>
          </Dropdown>
          <div className="financial-logo-wrapper">
            {selectedFinancialInstitution.logo && (
              <img
                className="qs-estimate-dialog-logo"
                src={`${staticContentUrl}/images/financial/${selectedFinancialInstitution.logo}`}
                alt={selectedFinancialInstitution.displayCode}
              />
            )}
          </div>
        </div>
      </div>
      {/* --- EMAIL ADDRESS / AGENT --------------------------------------------- */}
      <div className={`${rowClass} mt-4`}>
        <div className={fieldClass}>Email address {showAgents && '/ Agent'}</div>
        <div>
          <SubmitCommon
            //hasSubmitted={false}
            onLoad={onSubmissionCommonLoad}
            onChange={onSubmissionCommonChange}
            refresh={submissionCommonRefresh}
            showSendComparisonEmail={false}
            showSendArticlesEmail={false}
            showSendSolarInstallationEmail={false}
            serviceType={billTypes.HOME_LOAN}
          >
            <ServicesAuto selectedAddress={selectedAddress} serviceType={billTypes.HOME_LOAN} />
          </SubmitCommon>
        </div>
      </div>
      <div className="sm-modal-footer">
        {hasChanges && (
          <Button onClick={() => initialize(true)} className="sm-button-secondary">
            Reset
          </Button>
        )}

        {showBackButton && (
          <Button onClick={onBack} className="sm-button-secondary">
            Go back
          </Button>
        )}

        <Button onClick={onNextButtonClick} className="sm-button-primary wide right-arrow-small">
          Next
        </Button>

        <ExitLaunch launchMode={launchMode} serviceType={billTypes.HOME_LOAN} source="home-loan-dialog" />
      </div>
    </>
  );
};

export default HomeLoanCurrentAu;
