import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import HomeScreen from '../../screens/homeScreen';
import DashboardScreen from '../../screens/dashboardScreen';
import StartScreen from '../../screens/startScreen';
import Compare from '../../screens/compareScreen';
import Landing from '../landing';
import Launch from '../launch';
import Init from '../init';
import PartnerScreen from '../../screens/partnerScreen';
import SwitchPage from '../../screens/switchScreen';
import Faq from '../faq';
import NotFoundPage from '../notFoundPage';
import SecureRoute from '../SecureRoute';
import Profile from '../Profile';
import PrivacyPolicy from '../privacyPolicy';
import Register from '../registration/register';
import Registration from '../registration/registration';
import Preferences from '../../screens/preferencesScreen';
import Authentication from '../authentication/authentication';
import TermsAndConditions from '../termsAndConditions';
import AllConnect from '../partner/all-connect';
import ArticlesScreen from '../../screens/articlesScreen';
import ArticleScreen from '../../screens/articleScreen';
import { closeDialogs } from '../../actions/dialogOptionsActions';
import SurveyScreen from '../../screens/surveyScreen';

const Main = () => {
  const dispatch = useDispatch();

  const handleNavigation = () => {
    dispatch(closeDialogs('main:handleNavigation')); // Ensure dialogs don't auto-open on browser back/forward navigation
  };

  useEffect(() => {
    window.addEventListener('popstate', handleNavigation);

    return () => {
      window.removeEventListener('popstate', handleNavigation);
    };
  }, []);

  return (
    <main>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route exact path="/dashboard" component={DashboardScreen} />
        <Route exact path="/start" component={StartScreen} />
        <Route path="/landing/:id?/:tag?" component={Landing} />
        <Route path="/init/:module/" component={Init} />
        <Route path="/launch/:module/:tag?" component={Launch} />
        <Route path="/register/:id?" component={Register} />
        <Route exact path="/registration" component={Registration} />
        <Route exact path="/authentication" component={Authentication} />
        <Route exact path="/partner/:serviceType" component={PartnerScreen} />
        <Route exact path="/partner/local/allconnect" component={AllConnect} />
        <Route exact path="/compare" component={Compare} />
        <Route exact path="/switch" component={SwitchPage} scrollStrategy="top" />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/articles/:tag?" component={ArticlesScreen} />
        <Route exact path="/article/:slug" component={ArticleScreen} />
        <Route exact path="/survey" component={SurveyScreen} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/termsandconditions" component={TermsAndConditions} />
        <SecureRoute exact path="/preferences" component={Preferences} allowAccessKey={true} />
        {/* <SeureRoute exact path="/profile" component={Profile} roles={['member']} /> */}
        <Route component={NotFoundPage} />
      </Switch>
    </main>
  );
};

export default Main;
