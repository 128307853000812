import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col, FormGroup } from 'reactstrap';
import OutboundLink from './outboundLink';
import { AvField } from 'availity-reactstrap-validation';
import { billTypes } from '../../enums/billTypes';

const OfferMoreInfoEnergyAu = ({ offer, comparison, pricingDocumentName }) => {
  const [rateType, setRateType] = useState('loading');

  const { taxIndicator } = useSelector(state => state.content.general);

  useEffect(() => {
    if (rateType === 'loading') {
      var firstSingleRateType =
        offer && offer.allRates && offer.allRates.length > 1
          ? comparison.submission.billType === billTypes.GAS.Name
            ? offer.allRates[0]
            : offer.allRates.find(rate => rate.name.startsWith('Single'))
          : null;

      if (firstSingleRateType) setRateType(firstSingleRateType.name);
    }
  }, []);

  var pricingDescriptionSize = { xs: 8, md: 9 };
  var pricingRateSize = { xs: 4, md: 3 };

  var isElectricity = comparison.submission.billType === billTypes.ELECTRICITY.Name;

  const moreInfoHeading = `${offer.providerDisplayCode} - ${offer.name}`;

  const onSelectRateType = e => {
    var rateType = e.target.value;
    setRateType(rateType);
  };

  const feesHaveAmount =
    offer && offer.fees && offer.fees.length > 0 && offer.fees.find(fee => fee.amountDisplay) ? true : false;

  const rateTypePrice =
    offer && offer.allRates && offer.allRates.length > 0 ? offer.allRates.find(rate => rate.name === rateType) : null;

  return (
    <Container>
      <Row>
        <Col xs="12">
          <h2 className="qs-more-info-heading" dangerouslySetInnerHTML={{ __html: moreInfoHeading }} />
        </Col>
      </Row>
      {offer && (
        <Row className="qs-more-info-table-row" noGutters={true}>
          <Col xs="12" className="qs-more-info-plan-info">
            <div
              dangerouslySetInnerHTML={{
                __html: offer.info
              }}
            />
          </Col>
        </Row>
      )}

      {offer && (!offer.allRates || offer.allRates.length === 1) && (
        <>
          <Row className="qs-more-info-table-header" noGutters={true}>
            <Col xs={pricingDescriptionSize.xs} md={pricingDescriptionSize.md}>
              Rates
            </Col>

            <Col xs={pricingRateSize.xs} md={pricingRateSize.md}>
              {taxIndicator}
            </Col>
          </Row>

          {offer &&
            offer.usageCharges &&
            offer.usageCharges.length > 0 &&
            offer.usageCharges.map((usageCharge, i) => (
              <Row key={i} className="qs-more-info-table-row" noGutters={true}>
                <Col xs={pricingDescriptionSize.xs} md={pricingDescriptionSize.md}>
                  {usageCharge.description}
                </Col>

                <Col xs={pricingRateSize.xs} md={pricingRateSize.md}>
                  {usageCharge.incGstRateDisplay}
                </Col>
              </Row>
            ))}

          {offer &&
            offer.supplyCharges &&
            offer.supplyCharges.length > 0 &&
            offer.supplyCharges.map((supplyCharge, i) => (
              <Row key={i} className="qs-more-info-table-row" noGutters={true}>
                <Col xs={pricingDescriptionSize.xs} md={pricingDescriptionSize.md}>
                  {supplyCharge.description}
                </Col>

                <Col xs={pricingRateSize.xs} md={pricingRateSize.md}>
                  {supplyCharge.incGstRateDisplay}
                </Col>
              </Row>
            ))}

          {offer && offer.effectiveFromDisplay && (
            <Row>
              <Col xs="12" className="qs-mt-md">
                Effective from: {offer.effectiveFromDisplay}
              </Col>
            </Row>
          )}

          {comparison && comparison.submission && comparison.submission.billType === billTypes.GAS.Name && offer && (
            <Row>
              <Col xs="12" className="qs-more-info-distribution-zone qs-mt-sm">
                Distributor: {offer.zoneName}
              </Col>
            </Row>
          )}

          {offer.pricingDocuments && offer.pricingDocuments.length > 0 && (
            <div>
              <div id={`pricing-document${offer.id}`} className="qs-more-info-sub-heading">
                {pricingDocumentName}
              </div>
              <div className="qs-more-info-important-info">
                {offer.pricingDocuments.map((pricingDocument, i) => (
                  <div key={i}>
                    <OutboundLink
                      eventLabel={`pricing-document-${pricingDocument.name}`}
                      to={pricingDocument.linkUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {pricingDocument.name}
                    </OutboundLink>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      {offer && offer.allRates && offer.allRates.length > 1 && (
        <>
          <Row>
            <Col xs="12">
              <div className="qs-more-info-sub-heading">Rate type</div>
            </Col>
          </Row>

          <Row>
            <Col xs="12" lg="8" xl="7">
              <FormGroup>
                <AvField
                  bsSize="sm"
                  type="select"
                  name="verifyMethod"
                  onChange={e => onSelectRateType(e, { verifyMethod: e.target.value })}
                  value={rateType}
                >
                  <option value="">Select rate type</option>
                  {offer.allRates.map((rate, i) => (
                    <option key={`rateType-${i}`} value={rate.name}>
                      {rate.name}
                    </option>
                  ))}
                </AvField>
              </FormGroup>
            </Col>

            {isElectricity && (
              <Col xs="12">
                <div className="qs-more-info-rate-disclaimer">
                  The rates for your address are dependent on your distribution area and the meter type. The
                  distributors for your location are shown on the panel above and the {pricingDocumentName} shown below
                  is for the selected meter type from the drop-down above. The second page of a current electricity bill
                  for that property will show the meter/tariff type.
                </div>
              </Col>
            )}
          </Row>

          {rateTypePrice && (
            <>
              <Row className="qs-more-info-table-header qs-mt-nsm" noGutters={true}>
                <Col xs={pricingDescriptionSize.xs} md={pricingDescriptionSize.md}>
                  Rates
                </Col>

                <Col xs={pricingRateSize.xs} md={pricingRateSize.md}>
                  {taxIndicator}
                </Col>
              </Row>

              {rateTypePrice.usageCharges &&
                rateTypePrice.usageCharges.length > 0 &&
                rateTypePrice.usageCharges.map((usageCharge, i) => (
                  <Row key={i} className="qs-more-info-table-row" noGutters={true}>
                    <Col xs={pricingDescriptionSize.xs} md={pricingDescriptionSize.md}>
                      {usageCharge.description}
                    </Col>

                    <Col xs={pricingRateSize.xs} md={pricingRateSize.md}>
                      {usageCharge.incGstRateDisplay}
                    </Col>
                  </Row>
                ))}

              {rateTypePrice.supplyCharges &&
                rateTypePrice.supplyCharges.length > 0 &&
                rateTypePrice.supplyCharges.map((supplyCharge, i) => (
                  <Row key={i} className="qs-more-info-table-row" noGutters={true}>
                    <Col xs={pricingDescriptionSize.xs} md={pricingDescriptionSize.md}>
                      {supplyCharge.description}
                    </Col>

                    <Col xs={pricingRateSize.xs} md={pricingRateSize.md}>
                      {supplyCharge.incGstRateDisplay}
                    </Col>
                  </Row>
                ))}

              {rateTypePrice.effectiveFromDisplay && (
                <Row>
                  <Col xs="12" className="qs-mt-md">
                    Effective from: {offer.effectiveFromDisplay}
                  </Col>
                </Row>
              )}

              {comparison && comparison.submission && comparison.submission.billType === billTypes.GAS.Name && (
                <Row>
                  <Col xs="12" className="qs-more-info-distribution-zone qs-mt-sm">
                    Distributor: {offer.zoneName}
                  </Col>
                </Row>
              )}

              {rateTypePrice.pricingDocument && (
                <div>
                  <div id={`pricing-document${offer.id}`} className="qs-more-info-sub-heading">
                    {pricingDocumentName}
                  </div>
                  <div className="qs-more-info-important-info">
                    <div>
                      <OutboundLink
                        eventLabel={`pricing-document-${rateTypePrice.pricingDocument.name}`}
                        to={rateTypePrice.pricingDocument.linkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {rateTypePrice.pricingDocument.name}
                      </OutboundLink>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
      <Row>
        <Col xs="12">
          {offer.ratesPeriod && <div style={{ marginTop: '10px' }}>{offer.ratesPeriod}</div>}

          {offer && offer.providerAbout && (
            <div>
              <div className="qs-more-info-sub-heading">{offer.providerDisplayCode}</div>
              <div className="qs-more-info-important-info" dangerouslySetInnerHTML={{ __html: offer.providerAbout }} />
            </div>
          )}

          <div className="qs-more-info-sub-heading">Important information</div>
          <div className="qs-more-info-important-info">
            {offer.hasConditionalDiscounts && (
              <div>
                {offer.conditionalDiscounts.hasPayOnTimeDiscount && (
                  <div>{offer.conditionalDiscounts.payOnTimeDiscountDisplay}</div>
                )}

                {offer.conditionalDiscounts.hasDirectDebitDiscount && (
                  <div>{offer.conditionalDiscounts.directDebitDiscountDisplay}</div>
                )}

                {offer.conditionalDiscounts.hasEBillingDiscount && (
                  <div>{offer.conditionalDiscounts.eBillingDiscountDisplay}</div>
                )}

                {offer.conditionalDiscounts.hasMembershipDiscount && (
                  <div>{offer.conditionalDiscounts.membershipDiscountDisplay}</div>
                )}

                {offer.conditionalDiscounts.hasOtherDiscount && (
                  <div>{offer.conditionalDiscounts.otherDiscountDisplay}</div>
                )}

                {/* {offer.conditionalDiscounts.hasCreditDiscount && (
                  <div>{offer.conditionalDiscounts.creditDiscountDisplay}</div>
                )} */}
              </div>
            )}

            {offer.ratesTypeDisplay && <div>{offer.ratesTypeDisplay}</div>}

            {offer.hasContract && <div>Benefit term: {offer.contractDisplay}</div>}

            <div dangerouslySetInnerHTML={{ __html: offer.paymentOptionsDisplay }} />
            <div>{offer.paymentFrequenciesDisplay}</div>
            {offer.coolingOffPeriod && (
              <div dangerouslySetInnerHTML={{ __html: `Cooling off period: ${offer.coolingOffPeriod}` }} />
            )}

            {offer.creditCheckConsentRequired && <div>{offer.creditCheckConsentRequiredDisplay}</div>}

            {offer && offer.bonus && (
              <div>
                <div className="qs-more-info-sub-heading">Bonus</div>
                <div className="qs-more-info-important-info" dangerouslySetInnerHTML={{ __html: offer.bonus }} />
              </div>
            )}

            {offer.fees && offer.fees.length > 0 && (
              <table className="qs-fees-table">
                <thead>
                  <tr>
                    <th className="qs-fees-table-fees">Fee</th>
                    {feesHaveAmount && <th className="qs-fees-table-amount">Amount</th>}
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {offer.fees.map((fee, index) => (
                    <tr key={`fee-${index}`}>
                      <td>{fee.title}</td>
                      {feesHaveAmount && <td>{fee.amountDisplay}</td>}
                      <td dangerouslySetInnerHTML={{ __html: fee.description }} />
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {offer && offer.providerFeature && (
            <div>
              <div className="qs-more-info-sub-heading">About {offer.providerDisplayCode}</div>
              <div
                className="qs-more-info-important-info"
                dangerouslySetInnerHTML={{ __html: offer.providerFeature }}
              />
            </div>
          )}

          {offer && offer.greenOptions && (
            <div>
              <div className="qs-more-info-sub-heading">Green options</div>
              <div className="qs-more-info-important-info" dangerouslySetInnerHTML={{ __html: offer.greenOptions }} />
            </div>
          )}
        </Col>
      </Row>
      {offer.providerDocuments && offer.providerDocuments.length > 0 && (
        <Row>
          <Col xs="12">
            <div>
              <div className="qs-more-info-sub-heading">Documents</div>
              <div className="qs-more-info-important-info">
                {offer.providerDocuments.map((providerDocument, i) => (
                  <div key={i}>
                    <OutboundLink
                      eventLabel={`document-${providerDocument.name}`}
                      to={providerDocument.linkUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {providerDocument.name}
                    </OutboundLink>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      )}
      {offer.additionalInformation && (
        <Row>
          <Col xs="12">
            <div>
              <div className="qs-more-info-sub-heading">Additional information</div>
              <div
                className="qs-more-info-important-info"
                dangerouslySetInnerHTML={{ __html: offer.additionalInformation }}
              />
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

OfferMoreInfoEnergyAu.propTypes = {
  offer: PropTypes.object.isRequired,
  comparison: PropTypes.object.isRequired,
  pricingDocumentName: PropTypes.string.isRequired
};

export default OfferMoreInfoEnergyAu;
