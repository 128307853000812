import React, { useState } from 'react';

import { Container, Row, Col, Button } from 'reactstrap';
import InternetFeatures from './internetFeatures';
import InfoPopover from './infoPopover';

const InternetAddon = props => {
  const { onChange, offer, action, title, addons, allowMultipleSelections } = props;

  const initialSelections = addons.filter(addon => addon.isSelected).map(addon => addon.id);

  const [selections, setSelections] = useState(initialSelections);

  const onSelectAddon = id => {
    var newSelections;

    if (isSelected(id)) {
      newSelections = selections.filter(existingId => id !== existingId);
    } else if (allowMultipleSelections) {
      newSelections = [...selections, id];
    } else {
      newSelections = [id];
    }

    setSelections(newSelections);

    onChange(getResultSelections(newSelections));
  };

  const getResultSelections = selections => {
    if (selections && selections.length > 0) {
      return allowMultipleSelections ? selections : selections[0];
    } else return null;
  };

  const isSelected = addonId => {
    return selections.includes(addonId);
  };

  return (
    <>
      {addons && addons.length > 0 && (
        <>
          <div className="qs-offer-internet-addon-title qs-mb-md">{title}</div>

          {addons.map((addon, index) => (
            <Container key={`-${index}`} style={{ margin: 0, padding: 0 }}>
              <Row noGutters={true} className="qs-offer-internet-addon">
                <Col xs="12" lg="6" className="qs-offer-internet-addon-column">
                  {addon.isMandatory && (
                    <div className="qs-mb-sm">
                      <i className="material-icons qs-offer-star">star</i>Included
                    </div>
                  )}
                  <div className="qs-offer-internet-addon-title">{addon.name}</div>

                  <div className="qs-offer-internet-addon-price">
                    {addon.priceDisplay}&nbsp;{addon.priceType}{' '}
                    {((addon.detail && addon.detail.length > 0) || addon.detailHtml) && (
                      <InfoPopover
                        idType={`addon-${action}-${addon.id}`}
                        heading={`${addon.name} details`}
                        body={<InternetFeatures features={addon.detail} featuresHtml={addon.detailHtml} />}
                        action={action}
                        offer={offer}
                      />
                    )}
                  </div>

                  <Button
                    size="sm"
                    disabled={addon.isMandatory}
                    onClick={() => onSelectAddon(addon.id)}
                    className={isSelected(addon.id) && !addon.isMandatory ? 'sm-button-primary' : 'sm-button-secondary'}
                    style={{ marginTop: '10px', width: '120px' }}
                  >
                    {addon.isMandatory ? 'Added' : isSelected(addon.id) ? 'Remove' : 'Add'}
                  </Button>
                </Col>
                <Col xs="12" lg="6">
                  Features
                  <InternetFeatures features={addon.features} featuresHtml={addon.featuresHtml} />
                </Col>
              </Row>
            </Container>
          ))}
        </>
      )}
    </>
  );
};

export default InternetAddon;
