import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const OfferMoreInfoHomeLoanAu = ({ offer }) => {
  const moreInfoHeading = `${offer.providerDisplayCode} - ${offer.name}`;

  return (
    <Container>
      <Row>
        <Col xs="12">
          <h2 className="qs-more-info-heading" dangerouslySetInnerHTML={{ __html: moreInfoHeading }} />
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          {offer && offer.providerAbout && (
            <div>
              <div className="qs-more-info-sub-heading">{offer.providerDisplayCode}</div>
              <div className="qs-more-info-important-info" dangerouslySetInnerHTML={{ __html: offer.providerAbout }} />
            </div>
          )}

          <div className="qs-more-info-sub-heading">Important information</div>
          <div className="sm-home-loan-offer-disclaimer">
            <ul>
              <li>
                Annual saving figure shown is the difference between your declared current loan repayments and the loan
                we have compared it to
              </li>
              <li>
                Refinancing your current loan over a longer term could reduce the annual repayments but may result in
                more interest being paid over the life of the loan{' '}
              </li>
              <li>
                This comparison does not constitute a loan offer or a recommendation for your individual circumstances{' '}
              </li>
              <li>Fees, charges and Loan eligibility applies</li>
            </ul>
          </div>
          <div className="qs-more-info-sub-heading">Fees and Charges</div>
          <div className="qs-more-info-important-info">
            {offer && offer.bonus && (
              <div>
                <div className="qs-more-info-sub-heading">Bonus</div>
                <div className="qs-more-info-important-info" dangerouslySetInnerHTML={{ __html: offer.bonus }} />
              </div>
            )}

            {offer.fees && offer.fees.length > 0 && (
              <table className="qs-fees-table">
                <thead>
                  <tr>
                    <th className="qs-fees-table-fees">Description</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {offer.fees.map((fee, index) => (
                    <tr key={`fee-${index}`}>
                      <td>{fee.name}</td>
                      <td>${fee.value.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {offer && offer.providerFeature ? (
            <div>
              <div className="qs-more-info-sub-heading">Features</div>
              <div
                className="qs-more-info-important-info"
                dangerouslySetInnerHTML={{ __html: offer.providerFeature }}
              />
            </div>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default OfferMoreInfoHomeLoanAu;
