import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import AuthenticationButton from '../authentication/authenticationButton';
import RegistrationDialogUx2 from '../registration/registrationDialogUx2';
import { authenticationTypes } from '../../enums/authenticationTypes';
import { homePageTypes } from '../../enums/launchTypes';
import { useAuth0 } from '../../auth/auth0-Provider.js';
import { history } from '../../store/configureStore';

const RegistrationSummary = ({ programName, autoCheckEmailAddress, registrationComplete, registrationPresent }) => {
  const { isUserAuthenticated } = useAuth0();

  const { authenticationType, homePageType } = useSelector(state => state.config.settings);

  const homePage = homePageTypes.getById(homePageType);

  let actionButton;

  // If we are authenticated or using an external authentication type, show the Finish button rather than trying to get the user to regsister.
  if (isUserAuthenticated()) {
    actionButton = (
      <div className="sm-registration-footer">
        <Button className="sm-button-primary" onClick={() => history.push(homePage.path)}>
          Finish
        </Button>
      </div>
    );
  } else {
    switch (authenticationType) {
      case authenticationTypes.CTM:
        actionButton = null;
        break;

      default:
        if (registrationPresent) {
          actionButton = (
            <div className="sm-registration-footer">
              <AuthenticationButton showLogout={false} />
            </div>
          );
        } else {
          actionButton = <RegistrationDialogUx2 />;
        }
    }
  }

  return (
    <Container className="qs-centered-body">
      {(registrationComplete || registrationPresent) && (
        <Row>
          <Col>
            <h1>Welcome to {programName}</h1>
            <p className="qs-reg-complete-sub-heading">You've taken a big step towards removing bill pain forever.</p>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <p>
            You now have a {programName} email address that you can use with all your providers so that {programName}{' '}
            can receive and check your future bills.
          </p>
          <p className="qs-reg-complete-autocheck-email-address">{autoCheckEmailAddress}</p>
        </Col>
      </Row>

      <div className="qs-reg-complete-footer">
        <div className="action-button">{actionButton}</div>
      </div>
    </Container>
  );
};

export default RegistrationSummary;
