import React from 'react';
import { useSelector } from 'react-redux';
import { hasValidAddress } from '../../utilities/addressUtilities';
import { getAutoServices } from './serviceCardUtilities';
import ServiceIcon from './serviceIcon';

const ServicesAuto = ({
  selectedAddress,
  serviceType,
  services: userServices,
  supportedServices,
  isLoaded,
  heading,
  footer,
  noHeading
}) => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { data: userBills } = useSelector(state => state.userBills);

  const getServiceIcons = () => {
    const services = userServices || getAutoServices(serviceType, selectedAddress, agentBillTypes, userBills);

    if (services.length === 0) return null;

    const isServiceSupported = serviceType => {
      return !supportedServices || supportedServices.find(e => e.Id === serviceType.Id);
    };

    return (
      <div className="sm-default-services">
        {!noHeading && <div className="header">{heading || 'Automatic comparisons we will generate'}</div>}
        <div className="services">
          {services.map((item, index) => (
            <span
              key={`auto-service-icon_${index}`}
              className={`type-icon-wrap ${isServiceSupported(item) ? '' : ' disabled'}`}
            >
              <ServiceIcon serviceType={item} />
              <span className="alert-bubble">
                <span className="material-icons">
                  {isServiceSupported(item)
                    ? 'done'
                    : isLoaded && hasValidAddress(selectedAddress)
                    ? 'horizontal_rule'
                    : 'question_mark'}
                </span>
              </span>

              <div style={{ textAlign: 'center', fontSize: '0.6em', marginTop: '5px' }}>{item.Name}</div>
            </span>
          ))}
        </div>
        {footer && (
          <div className="footer">
            <span className="alert-bubble">
              <span className="material-icons">done</span>
            </span>
            {footer}
          </div>
        )}
      </div>
    );
  };

  return <div className="sm-service-default">{getServiceIcons()}</div>;
};

export default ServicesAuto;
