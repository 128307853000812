import React from 'react';
import { useSelector } from 'react-redux';
import Conditions from './conditions';
import SmartMeTermsAndConditions from './smartMeTermsAndConditions';
import ProviderTermsAndConditions from './providerTermsAndConditions';
import { billTypes } from '../../enums/billTypes';
import { orientationTypes } from '../../enums/scaleTypes';

const OfferConfirmTermsAndConditionsUs = ({ confirmDetail, offer, onChange, setConfirmDetail }) => {
  const { billType } = useSelector(state => state.comparison.submission);

  const serviceType = billTypes.getByCode(billType);

  return (
    <>
      <ProviderTermsAndConditions offer={offer} subTitle="Please read and accept all terms and conditions." />

      <Conditions
        offer={offer}
        confirmDetail={confirmDetail}
        setConfirmDetail={setConfirmDetail}
        orientation={orientationTypes.left}
      />

      <SmartMeTermsAndConditions
        onChange={onChange}
        offer={offer}
        termsAndConditions={confirmDetail.termsAndConditions}
        serviceType={serviceType}
      />
    </>
  );
};

export default OfferConfirmTermsAndConditionsUs;
