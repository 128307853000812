import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, FormGroup, Container } from 'reactstrap';
import { AvField, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import Conditions from './conditions';
import { getBankBranch } from '../../actions/financialInstitutionsActions';
import { getCardTypeByCode } from '../../utilities/paymentUtilities';
import { directDebitTypes } from '../../enums/directDebitTypes';
import { orientationTypes } from '../../enums/scaleTypes';
import { conditionTypes } from '../../enums/conditionTypes';
import YourOrderInternet from './yourOrderInternet';

const OfferConfirmDirectDebitDetailsAu = ({
  offer,
  confirmDetail,
  setConfirmDetail,
  showYourOrder,
  showEncryptedPaymentDisclaimer,
  onChange,
  confirmDetail: {
    directDebitType,
    bsbNumber,
    bankName,
    branchName,
    bankAccountNumber,
    bankAccountName,
    creditCardName,
    creditCardExpiry
  }
}) => {
  const creditCardExpiryDisplayFormat = 'mm/yyyy';
  const creditCardExpiryValidation = '^(0?[1-9]|1[0-2])/(([0-9]{4}))$';

  const { hasDirectDebitCreditCard, hasDirectDebitBankAccount } = offer.productDetail;

  const [cardType, setCardType] = useState(null);
  const [isCardValid, setIsCardValid] = useState(false);
  const [cardError, setCardError] = useState(null);
  const dispatch = useDispatch();

  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const productDetail = offer.productDetail;
  const directDebitContent = productDetail && productDetail.directDebitContent;

  const setupTokenex = useCallback(() => {
    if (hasDirectDebitCreditCard && directDebitType === directDebitTypes.CREDIT_CARD.Code) {
      setCardType(null);
      setIsCardValid(false);
      setCardError(null);

      // eslint-disable-next-line no-undef
      var tokenexIframe = new TokenEx.Iframe('tokenex-card-number', {
        tokenExID: productDetail.tokenexId,
        tokenScheme: productDetail.tokenexScheme,
        origin: productDetail.tokenexOrigin,
        authenticationKey: productDetail.tokenexAuthKey,
        timestamp: productDetail.tokenexTimestamp,
        pci: true,
        cvv: true,
        placeholder: 'Credit / debit card number',
        cvvContainerID: 'tokenex-card-cvv',
        cvvPlaceholder: 'CVV',
        enableValidateOnBlur: true,
        styles: {
          base:
            'width:85%; box-shadow: none; height: 20px; padding: .4rem .5rem; font-size: .875rem; line-height: 1.5; border-radius: .2rem; color: #495057; background-color: #fff; background-clip: padding-box; border: 1px solid #c6c6cc; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; margin: 0;',
          focus:
            'border-color: #007e1e;outline: 0;box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 0 0.2rem rgb(0 126 30 / 25%);',
          error: 'border-color: red;',
          placeholder: '',
          cvv: {
            base:
              'width:78%; box-shadow: none; height: 20px; padding: .4rem .5rem; font-size: .875rem; line-height: 1.5; border-radius: .2rem; color: #495057; background-color: #fff; background-clip: padding-box; border: 1px solid #c6c6cc; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; margin: 0;',
            focus:
              'border-color: #007e1e;outline: 0;box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 0 0.2rem rgb(0 126 30 / 25%);',
            error: 'border-color: red;',
            placeholder: ''
          }
        }
      });

      tokenexIframe.on('load', function() {});

      tokenexIframe.on('cvvBlur', function() {
        tokenexIframe.validate();
        tokenexIframe.tokenize();
      });

      tokenexIframe.on('focus', function() {});

      tokenexIframe.on('blur', function() {
        tokenexIframe.validate();
      });

      tokenexIframe.on('change', function() {});

      tokenexIframe.on('validate', function(data) {
        if (data.isValid) {
          setCardType(getCardTypeByCode(data.cardType));

          if (data.isCvvValid) {
            setIsCardValid(true);
            setCardError(null);
          } else {
            setIsCardValid(false);
            setCardError('Invalid CVV');
          }
        } else {
          setIsCardValid(false);
          setCardError('Invalid card details');
        }
      });

      tokenexIframe.on('cardTypeChange', function(data) {
        setCardType(getCardTypeByCode(data.possibleCardType));
      });

      tokenexIframe.on('tokenize', function(data) {
        setCardType(getCardTypeByCode(data.cardType));
        onChange(null, {
          tokenexCardDetail: {
            ...data,
            tokenexAuthKey: productDetail.tokenexAuthKey,
            tokenexTimestamp: productDetail.tokenexTimestamp
          }
        });

        setIsCardValid(true);
        setCardError(null);
      });

      tokenexIframe.on('error', function(data) {
        setIsCardValid(false);
        setCardError(data.error);
      });

      tokenexIframe.on('cvvFocus', function() {});

      tokenexIframe.on('notice', function() {});

      tokenexIframe.load();
    }
  });

  useEffect(() => {
    setupTokenex();
  }, [hasDirectDebitCreditCard, directDebitType]);

  const onDirectDebitTypeChange = (event, target) => {
    const data = {
      directDebitType: target
    };

    // Clear uneeded data.
    switch (target) {
      case directDebitTypes.CREDIT_CARD.Code:
        data.bsbNumber = null;
        data.bankName = null;
        data.branchName = null;
        data.bankAccountNumber = null;
        data.bankAccountName = null;
        break;

      case directDebitTypes.BANK_ACCOUNT.Code:
        data.creditCardNumber = null;
        data.creditCardName = null;
        data.creditCardExpiry = null;
        data.creditCardCvv = null;
        break;

      default:
        break;
    }

    onChange(null, data);
  };

  const validateCreditCardExpiry = (value, ctx, input, cb) => {
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();

    const dateParts = value.split('/');

    // eslint-disable-next-line eqeqeq
    if ((dateParts[0] < currentMonth && dateParts[1] == currentYear) || dateParts[1] < currentYear) {
      cb('Card expiry date has passed, please enter a valid expiry date');
    } else {
      cb(true);
    }
  };

  // --------------------------------------------------------------------------
  // BSB number change event handler.
  // --------------------------------------------------------------------------
  const onBsbNumberChange = e => {
    const value = e.target.value;
    onChange(e, { bsbNumber: value, bankName: null, branchName: null });

    if (value.length === 6) {
      dispatch(
        getBankBranch(value, result => {
          if (result.success) {
            onChange(null, { bsbNumber: value, bankName: result.bankName, branchName: result.branchName });
          } else {
            onChange(null, { bsbNumber: value, bankName: null, branchName: null });
          }
        })
      );
    }
  };

  return (
    <>
      <Row>
        <Col xs="12" md={showYourOrder ? '8' : '12'}>
          <div className="qs-more-info-table-header mt-1">Direct Debit Details</div>
          {directDebitContent && (
            <div
              className="qs-direct-debit-content"
              dangerouslySetInnerHTML={{
                __html: directDebitContent
              }}
            />
          )}
          <Container style={{ margin: 0, padding: 0 }}>
            <Row>
              <Col xs="12" md="6">
                <AvRadioGroup
                  className="qs-form-radio qs-direct-debit-options"
                  name="directDebitType"
                  label=""
                  required
                  value={directDebitType}
                  onChange={onDirectDebitTypeChange}
                >
                  {hasDirectDebitBankAccount && hasDirectDebitCreditCard && (
                    <>
                      <AvRadio label={directDebitTypes.CREDIT_CARD.Label} value={directDebitTypes.CREDIT_CARD.Code} />
                      <AvRadio label={directDebitTypes.BANK_ACCOUNT.Label} value={directDebitTypes.BANK_ACCOUNT.Code} />
                    </>
                  )}
                </AvRadioGroup>
              </Col>
            </Row>

            {directDebitType === directDebitTypes.BANK_ACCOUNT.Code && (
              <>
                <Row>
                  <Col xs="12">
                    <FormGroup className="qs-direct-debit-bsb-number">
                      <AvField
                        bsSize="sm"
                        name="bsbNumber"
                        placeholder="BSB"
                        type="text"
                        value={bsbNumber}
                        onChange={onBsbNumberChange}
                        validate={{
                          required: { value: true, errorMessage: 'BSB is required' },
                          maxLength: { value: 6 },
                          pattern: {
                            value: '^[0-9]{6}$',
                            errorMessage: 'BSB must be 6 digits'
                          },
                          validBranch: (value, ctx, input, cb) => {
                            if (!bankName || !branchName) {
                              cb('Please enter a valid BSB number');
                            } else {
                              cb(true);
                            }
                          }
                        }}
                      />
                      {bankName && branchName && <div className="branch-name">{`${bankName} (${branchName})`}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <AvField
                        bsSize="sm"
                        name="BankAccountNumber"
                        placeholder="Account number"
                        type="text"
                        value={bankAccountNumber}
                        onChange={e => onChange(e, { bankAccountNumber: e.target.value })}
                        validate={{
                          required: { value: true, errorMessage: 'Account number is required' },
                          minLength: { value: 6 },
                          maxLength: { value: 9 },
                          pattern: {
                            value: '^[0-9]{6,9}$',
                            errorMessage: 'Account number is invalid'
                          }
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <AvField
                        bsSize="sm"
                        name="BankAccountName"
                        placeholder="Account name"
                        type="text"
                        value={bankAccountName}
                        onChange={e => onChange(e, { bankAccountName: e.target.value })}
                        validate={{
                          required: { value: true, errorMessage: 'Account name is required' },
                          maxLength: { value: 100 }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}

            {directDebitType === directDebitTypes.CREDIT_CARD.Code && (
              <>
                <Row>
                  <Col xs="12" md="6">
                    <div className="qs-direct-debit-card-number">
                      <div id="tokenex-card-number" className="qs-token-field-card" />

                      {/* separate images to avoid flicker when switching between the two CSS classes */}
                      {cardType && cardType.logo && !cardType.logoHorizontal && (
                        <img className="logo" src={`${staticContentUrl}/${cardType.logo}`} alt={cardType.name} />
                      )}
                      {cardType && cardType.logo && cardType.logoHorizontal && (
                        <img
                          className="logo horizontal"
                          src={`${staticContentUrl}/${cardType.logo}`}
                          alt={cardType.name}
                        />
                      )}
                    </div>
                    <AvField
                      bsSize="sm"
                      type="text"
                      name={`hidden-isCardValid`}
                      value={isCardValid}
                      className="qs-tc-hidden qs-mt-nmd"
                      validate={{
                        hidden: (value, ctx, input, cb) => {
                          let message = true;
                          if (!isCardValid) {
                            message = cardError;
                          }

                          cb(message);
                        }
                      }}
                    />
                    <FormGroup>
                      <AvField
                        className="card-name"
                        bsSize="sm"
                        name="CreditCardName"
                        placeholder="Card name"
                        type="text"
                        value={creditCardName}
                        onChange={e => onChange(e, { creditCardName: e.target.value })}
                        validate={{
                          required: { value: true, errorMessage: 'Card name is required' },
                          minLength: { value: 2, errorMessage: 'Card name is invalid' },
                          maxLength: { value: 26 }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" md="3">
                    <FormGroup>
                      <AvField
                        bsSize="sm"
                        placeholder={`Expires ${creditCardExpiryDisplayFormat}`}
                        name="CreditCardExpiry"
                        type="text"
                        value={creditCardExpiry}
                        onChange={e => onChange(e, { creditCardExpiry: e.target.value })}
                        validate={{
                          required: { value: true, errorMessage: 'Card expiry date is required' },
                          pattern: {
                            value: creditCardExpiryValidation,
                            errorMessage: `Card expiry date must be in the format ${creditCardExpiryDisplayFormat}`
                          },
                          validateCardExpiry: validateCreditCardExpiry
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6" md="3">
                    <div id="tokenex-card-cvv" className="qs-token-field-cvv"></div>
                  </Col>
                </Row>
              </>
            )}

            <Conditions
              offer={offer}
              confirmDetail={confirmDetail}
              setConfirmDetail={setConfirmDetail}
              type={conditionTypes.DIRECT_DEBIT}
              orientation={orientationTypes.top}
            />

            {showEncryptedPaymentDisclaimer && (
              <Row>
                <Col xs="12">
                  <div className="qs-mt-nlg qs-mb-md">
                    <i className="material-icons qs-middle-icon">lock</i>
                    <span className="sm-offer-secure-payment">This is a secure 256-bit SSL encrypted payment.</span>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </Col>

        {showYourOrder && (
          <Col xs="12" md="4">
            <YourOrderInternet productDetail={productDetail} confirmDetail={confirmDetail} />
          </Col>
        )}
      </Row>
    </>
  );
};

export default OfferConfirmDirectDebitDetailsAu;
