import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.SET_USER:
      return objectAssign({}, state, action.data);

    default:
      return state;
  }
}
