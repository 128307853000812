import React from 'react';
import PropTypes from 'prop-types';
import { billTypes } from '../../enums/billTypes';

const BillReductionDisclaimer = ({
  billType,
  programDisplayName,
  percentagePeakDisplay,
  percentageOffPeakDisplay,
  billAdditionalDisclaimer
}) => {
  return (
    <>
      <li>
        Percentage reflects what you would have paid over the same period using actual usage from your bill
        {billType === billTypes.GAS.Name
          ? ` based on an estimated ${percentagePeakDisplay} peak and ${percentageOffPeakDisplay} off-peak usage`
          : ''}
        .
      </li>
      {billAdditionalDisclaimer && <li>{billAdditionalDisclaimer}</li>}
      <li>
        Percentages do not take into account concessions, payment processing fees, paper bill fees, green energy
        contributions, government rebates, welcome credits, guaranteed service level credits or any balances carried
        forward from previous bill, unless otherwise stated.
      </li>
      <li>
        Information considered by {programDisplayName} includes the number of days of energy usage, the amount of kWh or
        MJ usage, the metering type of the energy consumed e.g. peak / off-peak / controlled load, usage pricing steps,
        solar feed-in and any discounts applied.
      </li>
      <li>{programDisplayName} only considers offers from approved energy providers within the SmartMe marketplace.</li>
    </>
  );
};

BillReductionDisclaimer.propTypes = {
  programDisplayName: PropTypes.string.isRequired
};

export default BillReductionDisclaimer;
