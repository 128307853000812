import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spinner, FormGroup } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';
import { getAddressComponent } from '../../utilities/googleAddressUtilities';
import { getEmptyAddress } from '../../utilities/addressUtilities';

const AddressAutoComplete = ({ placeholder, onAddressSelected, selectedAddress, required, readOnly }) => {
  const { country } = useSelector(state => state.config);
  const { unitNumberName } = useSelector(state => state.content.general);

  const hasAddress = selectedAddress && selectedAddress.fullAddress;

  const [unitNumber, setUnitNumber] = useState(hasAddress ? selectedAddress.unitNumber : '');
  const [address, setAddress] = useState(hasAddress ? selectedAddress : getEmptyAddress());
  const [addressDisplay, setAddressDisplay] = useState(selectedAddress ? selectedAddress.fullAddress : '');

  useEffect(() => {
    setUnitNumber(hasAddress ? selectedAddress.unitNumber : unitNumber ? unitNumber : '');
    setAddress(hasAddress ? selectedAddress : getEmptyAddress());
    setAddressDisplay(hasAddress ? selectedAddress.fullAddress : '');
  }, [selectedAddress]);

  const applyAddress = (placeId, description) => {
    if (readOnly) return;

    geocodeByPlaceId(placeId)
      .then(results => {
        var place = results[0];
        var streetNumber = getAddressComponent(place.address_components, 'street_number', true);
        var route = getAddressComponent(place.address_components, 'route', true);
        var routeShort = getAddressComponent(place.address_components, 'route', false);
        var suburb = getAddressComponent(place.address_components, 'sub_locality', true);
        if (!suburb) suburb = getAddressComponent(place.address_components, 'locality', true);
        var suburbShort = getAddressComponent(place.address_components, 'sub_locality', false);
        if (!suburbShort) suburbShort = getAddressComponent(place.address_components, 'locality', false);
        var state = getAddressComponent(place.address_components, 'administrative_area_level_1', false);
        var postcode = getAddressComponent(place.address_components, 'postal_code', true);

        var newAddress = {
          unitNumber,
          streetNumber,
          route,
          routeShort,
          suburb,
          suburbShort,
          state,
          postcode,
          country: country.name,
          fullAddress: description,
          streetAddress: `${unitNumber ? `${unitNumber} / ` : ''}${streetNumber} ${route}`,
          isAddress: true
        };
        onAddressSelected(newAddress);
        setAddress(newAddress);
        setAddressDisplay(newAddress.fullAddress);
      })
      .catch(() => {
        setEmptyAddress(null);
      });
  };

  const setEmptyAddress = () => {
    var newAddress = getEmptyAddress(null);
    onAddressSelected(newAddress);
    setAddress(newAddress);
    setAddressDisplay('');
  };

  const onInputValueChange = e => {
    if (readOnly) return;

    // If we have a selected address and the input value has changed then clear out the address completely
    if (address.isAddress) {
      setEmptyAddress();
    } else {
      setAddressDisplay(e.target.value);
    }
  };

  const onUnitChange = value => {
    if (readOnly) return;

    var newUnitNumber = value || '';
    var newAddress = { ...address, unitNumber: newUnitNumber };

    setUnitNumber(newUnitNumber);
    setAddress(newAddress);
    onAddressSelected(newAddress);
  };

  return (
    <div>
      <div className="sm-address-auto">
        <div className="unit-number">
          <AvField
            disabled={readOnly}
            bsSize="sm"
            name="unitNumber"
            autoComplete="new-unitNumber"
            className="qs-mb-none"
            placeholder={unitNumberName}
            type="text"
            value={unitNumber || ''} // prevent controlled / uncontrolled switching
            onChange={e => onUnitChange(e.target.value)}
            validate={{
              maxLength: { value: 20, errorMessage: `${unitNumberName} should be 20 characters or less` }
            }}
          />
        </div>
        <div className="address">
          <GooglePlacesAutocomplete
            autocompletionRequest={{
              types: ['address'],
              componentRestrictions: {
                country: [country.alpha2.toLowerCase()]
              }
            }}
            // suggestionsClassNames={{
            //   container: 'google-places-autocomplete__suggestions-container form-control-sm form-control',
            //   suggestion: 'google-places-autocomplete__suggestion',
            //   suggestionActive:
            //     'google-places-autocomplete__suggestion google-places-autocomplete__suggestion--active'
            // }}
            loader={<Spinner size="sm" color="success" className="sm-address-loader" />}
            placeholder={placeholder}
            onSelect={({ place_id, description }) => applyAddress(place_id, description)}
            renderInput={props => (
              <FormGroup className="address-form-group">
                <input
                  {...props}
                  disabled={readOnly}
                  value={addressDisplay || ''} // prevent controlled / uncontrolled switching
                  autoComplete="new-address" // Turns off google autofill
                  className="form-control form-control-sm"
                  onChange={ev => {
                    props.onChange(ev);
                    onInputValueChange(ev);
                  }}
                />
              </FormGroup>
            )}
            renderSuggestions={(active, suggestions, onSelectSuggestion) => (
              <div className="qs-suggestions-container">
                {suggestions.map((suggestion, index) => (
                  <div
                    className={active === index ? 'qs-suggestion-active' : 'qs-suggestion'}
                    onClick={event => onSelectSuggestion(suggestion, event)}
                    key={`qs-address-${index}`}
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            )}
          />
        </div>
      </div>
      {required && (
        <div>
          <AvField
            type="text"
            name={`propertyAddressValidation`}
            className="qs-tc-hidden"
            validate={{
              hidden: (value, ctx, input, cb) => {
                let message = true;
                if (
                  !address ||
                  !address.streetNumber ||
                  !address.route ||
                  !address.suburb ||
                  !address.state ||
                  !address.postcode
                ) {
                  message = 'Please select a valid property address';
                }
                cb(message);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AddressAutoComplete;
