import axios from 'axios';
import { sessionActivityTypes } from '../enums/sessionActivityTypes';
import { setAjaxCallEnd, setAjaxCallStart } from './ajaxCallActions';

export function exitExternal(url, sessionActivity) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    dispatch(
      addSessionActivity(
        {
          ...sessionActivity,
          data: { ...sessionActivity.data, url },
          activityType: sessionActivityTypes.EXIT_EXTERNAL
        },
        () => {
          window.location.replace(url);
        },
        () => {
          dispatch(setAjaxCallEnd());
        }
      )
    );
  };
}

export function addSessionActivity(sessionActivity, onComplete, onError) {
  return () => {
    axios
      .post(`/api/sessionactivity`, sessionActivity)
      .then(() => {
        if (onComplete) onComplete();
      })
      .catch(error => {
        if (onError) onError(error);
      });
  };
}
