import React from 'react';
import { useSelector } from 'react-redux';

import CustomerCommissionLogo from '../common/customerCommissionLogo';
import CommissionTermsDialog from '../dialogs/commissionTermsDialog';

import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { billTypes } from '../../enums/billTypes';

const Summary = ({ serviceType, items, offer, onShowQuickTips, hasIdVerification }) => {
  const { providerLogoUrl, providerDisplayCode, name: offerName, membershipId: showMembershipId } = offer;
  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
  const hasCommission = agentServiceType.hasCommission;
  const showFooter = serviceType === billTypes.ELECTRICITY || serviceType === billTypes.GAS;

  const summary = [];

  const getItem = (label, value, allowHtml) => {
    return { label: `${label}:`, value, allowHtml };
  };

  summary.push(getItem('Plan', offerName));
  summary.push(getItem('Service', serviceType.Name));
  summary.push(getItem('Address', items.supplyAddress));
  summary.push(getItem('Name', items.name));
  summary.push(getItem('Email', items.emailAddress));
  summary.push(getItem('Mobile', items.phoneNumber));
  if (hasIdVerification) summary.push(getItem('ID provided', items.idType));
  summary.push(getItem('Send bills to', items.billDelivery, true));
  if (showMembershipId) summary.push(getItem('Member #', items.membershipId));
  if (hasCommission)
    summary.push(
      getItem(
        'Signup offer',
        <CommissionTermsDialog
          buttonText="See terms"
          dialogKey={`summary_terms`}
          serviceType={serviceType}
          style={{ textDecoration: 'underline' }}
        />
      )
    );

  return (
    <div className="summary-container sticky no-padding">
      <div className="heading">Your application summary</div>
      <div className="offer-container">
        <div className="product">
          <div className="provider-logo">
            <img src={providerLogoUrl} alt={providerDisplayCode} />
          </div>
        </div>
        {hasCommission && <CustomerCommissionLogo serviceType={serviceType} />}
      </div>
      <div className="detail">
        {summary.map((e, i) => (
          <div className="item" key={`summary-detail-${i}`}>
            <div className="label">{e.label}</div>
            {e.allowHtml ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: e.value || '-'
                }}
              />
            ) : (
              <div className="value">{e.value || '-'}</div>
            )}
          </div>
        ))}
      </div>
      {showFooter && (
        <div className="footer">
          <div className="label">What happens next?</div>
          <img
            src={`${staticContentUrl}/images/misc/quick-tips.png`}
            alt="quick-tips"
            onClick={() => {
              onShowQuickTips({
                tag: 'summary',
                quickTips: {
                  heading: 'What happens next?',
                  introduction: (
                    <>
                      <p>
                        We'll pass your application details to {providerDisplayCode} for approval. If approved, they'll
                        inform your current provider that you're moving to their plan, so you don't need to do this.
                      </p>
                      <p>
                        You'll continue billing with your current provider until your meter is next read. Once your
                        meter is read, you'll receive a final bill from your current provider.
                      </p>
                      <p>
                        If you want to set up direct debit with {providerDisplayCode}, the welcome pack you receive from
                        them will explain how to do this online or via phone is you prefer that option.
                      </p>
                      <div className="footer-icon">
                        <img src={`${staticContentUrl}/images/misc/welcome-envelope.png`} alt="welcome-envelope" />
                      </div>
                    </>
                  )
                }
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Summary;
