import React from 'react';
import ServiceOutline from './serviceOutline';
import ServiceCardMenu from './serviceCardMenu';

const ServiceCardHead = props => {
  const { title, subtitle, notificationCount, submissionId, serviceType, isAdd, savings, isCompact } = props;

  const showMenu = !isAdd;

  return (
    <div key={`serviceCardHead_${submissionId}`} className="service-card-head">
      <div className="service-titles">
        <div className="service-title">{title}</div>
        {subtitle && <div className="service-subtitle">{subtitle}</div>}
      </div>

      <span className="type-icon-wrap">
        {showMenu && <ServiceCardMenu {...props} />}
        {isCompact && <ServiceOutline serviceType={serviceType} />}
        {notificationCount > 0 && <span className="alert-bubble">{notificationCount}</span>}
      </span>
      {savings}
    </div>
  );
};

export default ServiceCardHead;
