import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as ComparePastIcon } from '../icons/home/compare-past.svg';
import { ReactComponent as SavePastIcon } from '../icons/home/save-past.svg';
import { ReactComponent as SavePresentIcon } from '../icons/home/save-present.svg';
import { ReactComponent as EarnPastIcon } from '../icons/home/earn-past.svg';
import { ReactComponent as RelaxPastIcon } from '../icons/home/relax-past.svg';
import { ReactComponent as RelaxPresentIcon } from '../icons/home/relax-present.svg';

import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { billTypes } from '../../enums/billTypes';
import { getItemContent } from '../../actions/contentActions';

const ComparePresentIcon = () => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { logo } = useSelector(state => state.content.images);

  return <img className="logo" src={`${staticContentUrl}${logo}`} alt="logo" />;
};

const EarnPresentIcon = () => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const agentServiceType = getAgentServiceType(billTypes.ELECTRICITY, agentBillTypes);

  return (
    <div className="earn-wrap">
      <div className="earn">Earn</div>
      <div className="provider">{agentServiceType.commissionType.Provider}</div>
      <div className="denomination">{agentServiceType.commissionType.TagPlural}</div>
    </div>
  );
};

const differenceTypes = {
  PAST: { id: 1, tag: 'past', caption: 'The past' },
  PRESENT: { id: 2, tag: 'present', caption: 'Today' }
};

const itemTypes = {
  COMPARE: { id: 1, pastIcon: <ComparePastIcon className="inactive" />, presentIcon: <ComparePresentIcon /> },
  SAVE: { id: 2, pastIcon: <SavePastIcon className="inactive" />, presentIcon: <SavePresentIcon className="active" /> },
  EARN: { id: 3, pastIcon: <EarnPastIcon className="inactive" />, presentIcon: <EarnPresentIcon /> },
  RELAX: {
    id: 4,
    pastIcon: <RelaxPastIcon className="inactive" />,
    presentIcon: <RelaxPresentIcon className="active" />
  }
};

const DifferenceItem = ({ children, differenceType, description }) => {
  const { organiserAgentName } = useSelector(state => state.content.general);
  const title = differenceType === differenceTypes.PAST ? 'Comparison websites' : organiserAgentName;

  return (
    <div className={`item${differenceType === differenceTypes.PAST ? ' inactive' : ''}`}>
      <div className="icon">{children}</div>
      <div className={`caption ${differenceType.tag}`}>{differenceType.caption}</div>
      <div
        className="title"
        dangerouslySetInnerHTML={{
          __html: title
        }}
      />
      <div
        className="description"
        dangerouslySetInnerHTML={{
          __html: description
        }}
      />
    </div>
  );
};

const DifferenceGroup = ({ content, itemType }) => {
  const { organiserAgentName } = useSelector(state => state.content.general);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const agentServiceType = getAgentServiceType(billTypes.ELECTRICITY, agentBillTypes);
  const commissionType = agentServiceType.commissionType;

  return (
    <div className="difference-item">
      <div className="heading">{content.title}</div>
      <div className="difference-group">
        <DifferenceItem
          differenceType={differenceTypes.PAST}
          description={getItemContent(content.pastContent, organiserAgentName, commissionType)}
        >
          {itemType.pastIcon}
        </DifferenceItem>
        <DifferenceItem
          differenceType={differenceTypes.PRESENT}
          description={getItemContent(content.presentContent, organiserAgentName, commissionType)}
        >
          {itemType.presentIcon}
        </DifferenceItem>
      </div>
    </div>
  );
};

const Differences = ({ agentServiceType }) => {
  const { principles } = useSelector(state => state.content.home);
  const hasCommission = agentServiceType.hasCommission;
  const principleItems = principles ? JSON.parse(principles) : [];

  return (
    <div className="sm-home-differences">
      {principleItems
        .filter(e => e.requiresCommission === false || hasCommission)
        .map(content => {
          switch (content.type) {
            case itemTypes.COMPARE.id:
              return (
                <DifferenceGroup
                  key={`differenceContent${itemTypes.COMPARE.id}`}
                  content={content}
                  itemType={itemTypes.COMPARE}
                />
              );
            case itemTypes.SAVE.id:
              return (
                <DifferenceGroup
                  key={`differenceContent${itemTypes.SAVE.id}`}
                  content={content}
                  itemType={itemTypes.SAVE}
                />
              );
            case itemTypes.EARN.id:
              return (
                <DifferenceGroup
                  key={`differenceContent${itemTypes.EARN.id}`}
                  content={content}
                  itemType={itemTypes.EARN}
                />
              );
            case itemTypes.RELAX.id:
              return (
                <DifferenceGroup
                  key={`differenceContent${itemTypes.RELAX.id}`}
                  content={content}
                  itemType={itemTypes.RELAX}
                />
              );
            default:
              return null;
          }
        })}
    </div>
  );
};
export default Differences;
