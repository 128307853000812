import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import GeneralDialogCloseIcon from '../dialogs/generalDialogCloseIcon';

const QuickTips = ({ tip, onRatingClick, onClose }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  const [rating, setRating] = useState();

  const onClick = value => {
    setRating(value);
    onRatingClick(value);

    if (tip.onRatingClick) tip.onRatingClick(value);
  };

  return (
    <div className="sm-quick-tips">
      <GeneralDialogCloseIcon onClick={() => onClose(tip.tag)} />

      <img className="icon" src={`${staticContentUrl}/images/misc/quick-tips.png`} alt="quick-tips" />
      {tip.heading && <div className="heading">{tip.heading}</div>}
      {tip.introduction && <div className="introduction">{tip.introduction}</div>}
      <div className="feedback">
        <div className="question">Was this tip helpful?</div>
        <span
          className={`${rating === true ? 'material-icons selected' : 'material-symbols-outlined'} thumb`}
          onClick={() => onClick(true)}
        >
          thumb_up
        </span>
        <span
          className={`${rating === false ? 'material-icons selected' : 'material-symbols-outlined'} thumb`}
          onClick={() => onClick(false)}
        >
          thumb_down
        </span>
      </div>
    </div>
  );
};

export default QuickTips;
