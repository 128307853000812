import * as types from '../actions/actions';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function userBillsReducer(state = initialState.userBills, action) {
  switch (action.type) {
    case types.SET_USER_BILLS:
      return objectAssign({}, state, action.data);

    case types.SET_USER_BILL:
      const {
        id,
        submissionId,
        errorMessages,
        accessKey,
        reductionLoaded,
        bestOffer,
        reductionPercentage,
        reductionAmountAnnual,
        nextCompareDateUtc,
        expirySeconds
      } = action.data;

      const key = id ? id : submissionId; // 'id' is used by reduction amount updates, 'submissionId' is used by full comparison responses.

      const data = state.data.map(item => {
        // Update the reduction amount if the user card has been loaded.
        if (item.submissionId === key) {
          return {
            ...item,
            errorMessages,
            accessKey: accessKey || item.accessKey, // accessKey may be updated when transferring an anonymous comparison to a real email address.
            reductionLoaded,
            bestOffer,
            reductionPercentage,
            reductionAmountAnnual,
            nextCompareDateUtc,
            reductionExpiry: new Date(new Date().getTime() + expirySeconds * 1000),
            deletedDateUtc: null // remove deleted date to restore potentially deleted bill.
          };
        }

        return item;
      });

      // Add a new card if we don't have an entry for it.
      if (submissionId) {
        const emptyItem = data.find(item => item.submissionId === submissionId);

        if (emptyItem === undefined) {
          data.push({
            ...getUserData(action.data),
            reductionExpiry: new Date(new Date().getTime() + expirySeconds * 1000)
          });
        }
      }

      return {
        ...state,
        data
      };

    case types.CONVERT_USER_BILL:
      const converted = state.data.map(item => {
        if (item.submissionId === action.data.submissionId) {
          const {
            providerCode,
            providerDisplayCode,
            providerLogo,
            convertedDateUtc,
            linked,
            awaitingLink,
            billingType
          } = action.data;

          return {
            ...item,
            status: 2,
            linked,
            awaitingLink,
            billingType,
            providerCode,
            providerDisplayCode,
            providerLogo,
            convertedDateUtc,
            reductionLoaded: false,
            bestOffer: null,
            reductionPercentage: 0,
            reductionAmountAnnual: 0,
            nextCompareDateUtc: null,
            expirySeconds: 0
          };
        }

        return item;
      });

      return {
        ...state,
        data: converted
      };

    case types.DELETE_USER_BILL:
      const deleted = state.data.map(item => {
        if (item.submissionId === action.data.submissionId) {
          return {
            ...item,
            deletedDateUtc: Date.now()
          };
        }

        return item;
      });

      return {
        ...state,
        data: deleted
      };

    case types.RESTORE_USER_BILL:
      const restored = state.data.map(item => {
        if (item.submissionId === action.data.submissionId) {
          return {
            ...item,
            deletedDateUtc: null
          };
        }

        return item;
      });

      return {
        ...state,
        data: restored
      };

    default:
      return state;
  }
}

const getUserData = data => {
  const userData = { ...data };

  delete userData.expirySeconds; // Don't persist expirySeconds, it's only used to calculate reductionExpiry.

  return userData;
};
