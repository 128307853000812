import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

import SubmitBillSelectorUx2 from '../common/submitBillSelectorUx2';
import GeneralDialogHeader from './generalDialogHeader';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { closeDialogs } from '../../actions/dialogOptionsActions';
import { billTypes } from '../../enums/billTypes';
import { launchDialogModes, pageModes } from '../../enums/launchTypes';
import { uploadBillTypes } from '../../enums/uploadBillTypes';

import { ReactComponent as HomeInsuranceIcon } from '../icons/services/outline/home-insurance.svg';
import { postFrameDialogMessage } from '../../utilities/domUtilities';

const SubmitBillDialogUx2 = props => {
  const {
    dialogOpen,
    onDialogClosing,
    serviceType,
    uploadBillType,
    uploadSubType,
    launchMode,
    submissionId,
    submission,
    showBackButton,
    showCancelButton,
    showCloseIcon,
    confirmButtonText
  } = props;

  const dispatch = useDispatch();
  const ref = useRef(null);

  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { pageMode, frameHeight, frameHost } = useSelector(state => state.application);

  const [currentServiceType, setCurrentServiceType] = useState(serviceType);
  const [dialogHeadingServiceType, setDialogHeadingServiceType] = useState();
  const [dialogHeadingIcon, setDialogHeadingIcon] = useState();
  const [dialogHeading, setDialogHeading] = useState();

  const hasServiceType = currentServiceType !== billTypes.NONE;
  const defaultMode = launchMode === launchDialogModes.DEFAULT;

  // --------------------------------------------------------------------------
  // Initialize component
  // --------------------------------------------------------------------------
  useEffect(() => {
    onUploadBillTypeChanged(uploadBillType, uploadSubType);
  }, []);

  // --------------------------------------------------------------------------
  // UploadBillType change handler.
  // --------------------------------------------------------------------------
  const onUploadBillTypeChanged = (uploadBillType, uploadSubType) => {
    setDialogHeadingServiceType(uploadBillType === uploadBillTypes.MOVING_HOUSE ? null : currentServiceType);

    setDialogHeadingIcon(uploadBillType === uploadBillTypes.MOVING_HOUSE ? <HomeInsuranceIcon /> : null);

    setDialogHeading(
      uploadBillType === uploadBillTypes.MOVING_HOUSE
        ? 'Moving House?'
        : hasServiceType
        ? null
        : 'Upload your latest bill'
    );
  };

  // --------------------------------------------------------------------------
  // Verify response handler.
  // --------------------------------------------------------------------------
  const onVerify = serviceType => {
    if (serviceType !== currentServiceType) {
      setCurrentServiceType(serviceType);
      setDialogHeadingServiceType(serviceType);

      standardEvents(
        'Service-Card-Selector',
        'Service-Type-Change',
        `${currentServiceType.Name}-To-${serviceType.Name}`,
        googleAnalyticsClientCode
      );
    }
  };

  const onClose = () => {
    dispatch(closeDialogs('submitBillDialog:onClose'));

    if (onDialogClosing) {
      onDialogClosing();
    }
  };

  const handleModal = () => {
    if (pageMode === pageModes.FRAMED) {
      dispatch(postFrameDialogMessage(frameHost, 'Idle Timeout', dialogOpen, ref.current, frameHeight));
    }
  };

  return (
    <>
      <Modal
        onOpened={handleModal}
        onClosed={handleModal}
        toggle={onClose}
        isOpen={dialogOpen}
        size="md"
        centered={pageMode === pageModes.DEFAULT}
        backdrop="static"
        keyboard={defaultMode}
      >
        <div ref={ref}>
          <GeneralDialogHeader
            showCloseIcon={defaultMode && showCloseIcon}
            serviceType={dialogHeadingServiceType}
            headingIcon={dialogHeadingIcon}
            heading={dialogHeading}
            onDialogClosing={onClose}
          />
          <ModalBody className="sm-modal-body">
            <SubmitBillSelectorUx2
              dialogUploadBillType={uploadBillType}
              dialogUploadSubType={uploadSubType}
              dialogLaunchMode={launchMode}
              showBackButton={showBackButton}
              showCancelButton={showCancelButton}
              confirmButtonText={confirmButtonText}
              submissionId={submissionId}
              submission={submission}
              serviceType={currentServiceType}
              onSuccess={onClose}
              onVerify={onVerify}
              onUploadBillTypeChanged={onUploadBillTypeChanged}
            />
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default SubmitBillDialogUx2;
