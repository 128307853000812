import * as types from './actions';

export function setConfirmDetailSuccess(confirmDetail, type) {
  return { type, confirmDetail };
}

export function setConfirmDetail(confirmDetail) {
  return dispatch => {
    dispatch(setConfirmDetailSuccess(confirmDetail, types.SET_CONFIRM_DETAIL));
  };
}

export function hardSetConfirmDetail(confirmDetail) {
  return dispatch => {
    dispatch(setConfirmDetailSuccess(confirmDetail, types.HARD_SET_CONFIRM_DETAIL));
  };
}
