import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import Drawer from 'rc-drawer';
import { isMobile } from 'react-device-detect';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import SubmitBillDialogUx2 from '../../components/dialogs/submitBillDialogUx2';
import { getServiceCardData } from '../../components/serviceCard/serviceCardUtilities';
import ServiceCard from '../../components/serviceCard/serviceCard';
import ServiceCardSample from '../samples/serviceCardSample';
import QsCheckBox from './qsCheckBox';
import OrganiserName from './organiserName';

import GeneralDialog from '../dialogs/generalDialog';
import EnergyBill from '../dialogs/energyBill';
import EnergyEstimateDetails from '../dialogs/energyEstimateDetails';
import HomeLoanEstimateDetails from '../dialogs/homeLoanEstimateDetails';
import InternetEstimateDetails from '../dialogs/internetEstimateDetails';
import GeneralDialogCloseIcon from '../dialogs/generalDialogCloseIcon';
import OfferMarketing from '../common/offerMarketing';

import { navigationTypes } from '../../enums/navigationTypes';
import { billTypes } from '../../enums/billTypes';
import { comparisonSorting } from '../../enums/comparisonSorting';
import { submissionTypes } from '../../enums/submissionTypes';
import { implementationCodes } from '../../enums/implementationCodes';
import { uploadBillTypes, uploadSubTypes } from '../../enums/uploadBillTypes';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { launchDialogModes } from '../../enums/launchTypes';

import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { navigationOptions } from '../../actions/globalOptionsActions';
import { setServerMessageClear } from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';

const CompareDrawer = ({
  animateHandle,
  showSortFilter,
  onFilterSorterChanged,
  filterSorter,
  submissionId,
  openDrawer,
  onMarketingDialogClosed
}) => {
  const { serverMessage } = useSelector(state => state);
  const { data: userBills } = useSelector(state => state.userBills);
  const { internetDownloadSpeeds } = useSelector(state => state.config.referenceData);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [marketingDialogOpen, setMarketingDialogOpen] = useState(false);
  const [drawerHandleAnimation, setDrawerHandleAnimation] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [selectedFilterSorter, setSelectedFilterSorter] = useState(filterSorter);

  const dispatch = useDispatch();

  const userBill = userBills.find(e => e.submissionId === submissionId);
  const serviceType = userBill ? billTypes.getById(userBill.billTypeId) : null;
  const showFilterSorterSection = showSortFilter && serviceType && serviceType.HasSorting;

  const selectedFilter = showFilterSorterSection ? selectedFilterSorter.filter : null;
  const allowEdit = userBill && userBill.submissionType === submissionTypes.ESTIMATE;
  const estimate = userBill ? userBill.estimate : null;

  const gaAction = serviceType && serviceType.Name ? serviceType.Name : 'Unknown';

  const sortByChanged = sort => {
    var newFilterSorter = { ...selectedFilterSorter, sort: sort };
    setSelectedFilterSorter(newFilterSorter);

    onFilterSorterChanged(newFilterSorter);

    standardEvents('Compare-Drawer', gaAction, `Sort-Change`, googleAnalyticsClientCode);
  };

  const filterByChanged = (property, value) => {
    var newFilterSorter = { ...selectedFilterSorter };
    newFilterSorter.filter[property] = value;
    setSelectedFilterSorter(newFilterSorter);

    onFilterSorterChanged(newFilterSorter);

    standardEvents('Compare-Drawer', gaAction, `Filter-Change`, googleAnalyticsClientCode);
  };

  const alwaysPulseDrawerHandleOnMarketingDialogClose = false;

  useEffect(() => {
    if (animateHandle) {
      setDrawerHandleAnimation('animated pulse-heavy');
    } else {
      setDrawerHandleAnimation(null);
    }
  }, [animateHandle]);

  useEffect(() => {
    setDrawerOpen(openDrawer);
  }, [openDrawer]);

  useEffect(() => {
    if (JSON.stringify(filterSorter) !== JSON.stringify(selectedFilterSorter)) setSelectedFilterSorter(filterSorter);
  }, [filterSorter]);

  const openMarketingDialog = () => {
    setMarketingDialogOpen(true);

    if (alwaysPulseDrawerHandleOnMarketingDialogClose && animateHandle !== undefined) {
      setDrawerHandleAnimation(null);
    }

    standardEvents('Compare-Drawer', gaAction, `Open-Marketing-Dialog`, googleAnalyticsClientCode);
  };

  const closeMarketingDialog = () => {
    setMarketingDialogOpen(false);

    standardEvents('Compare-Drawer', gaAction, `Close-Marketing-Dialog`, googleAnalyticsClientCode);

    if (animateHandle !== undefined) {
      setDrawerHandleAnimation('animated pulse-heavy repeat-2');
    }

    if (onMarketingDialogClosed) {
      onMarketingDialogClosed();
    }
  };

  const onOpenDrawer = () => {
    dispatch(navigationOptions(navigationTypes.NONE));
    setDrawerOpen(true);

    standardEvents('Compare-Drawer', gaAction, `Open`, googleAnalyticsClientCode);
  };

  const onCloseDrawer = () => {
    dispatch(navigationOptions(navigationTypes.NONE));
    setDrawerOpen(false);

    standardEvents('Compare-Drawer', gaAction, `Close`, googleAnalyticsClientCode);
  };

  const onEdit = () => {
    setDrawerOpen(false);
    setSubmitDialogOpen(true);

    dispatch(setServerMessageClear(serverMessageTargets.SUBMIT_BILL, serverMessage));
    dispatch(setServerMessageClear(serverMessageTargets.ESTIMATE_DIALOG, serverMessage));
  };

  const drawerHandle = (
    <div id="drawer_handle" className={`drawer-handle ${drawerHandleAnimation ? ` ${drawerHandleAnimation}` : ''}`}>
      <div className="material-icons icon">menu_open</div>
      <div className="caption">Your details</div>
    </div>
  );

  const getServiceCardSample = () => {
    if (!userBill || !serviceType) return <ServiceCardSample serviceType={billTypes.ELECTRICITY} />;

    const agentServiceType = getAgentServiceType(serviceType || billTypes.ELECTRICITY, agentBillTypes);
    const data = getServiceCardData(userBill, agentServiceType);

    return (
      <div className={`sm-service-card-sample large${isMobile ? ' mobile' : ''}`}>
        <ServiceCard service={{ ...data, isSample: true }} />
      </div>
    );
  };

  const getDetails = () => {
    if (!userBill) return null;

    switch (userBill.submissionType) {
      case submissionTypes.BILL:
        switch (userBill.implementationCode) {
          case implementationCodes.ELECTRICITY.US_DEFAULT:
          case implementationCodes.ELECTRICITY.AU_DEFAULT:
          case implementationCodes.GAS.AU_DEFAULT:
            return <EnergyBill isSample={true} serviceType={serviceType} userBill={userBill} />;
          case implementationCodes.INTERNET.AU_DEFAULT:
            return <InternetEstimateDetails userBill={userBill} submissionType={submissionTypes.BILL} />;
          default:
            return null;
        }

      case submissionTypes.ESTIMATE:
        switch (userBill.implementationCode) {
          case implementationCodes.ELECTRICITY.US_DEFAULT:
          case implementationCodes.ELECTRICITY.AU_DEFAULT:
          case implementationCodes.GAS.AU_DEFAULT:
            return <EnergyEstimateDetails userBill={userBill} onEdit={onEdit} />;

          case implementationCodes.HOME_LOAN.AU_DEFAULT:
            return <HomeLoanEstimateDetails userBill={userBill} onEdit={onEdit} />;

          case implementationCodes.INTERNET.AU_DEFAULT:
            return (
              <InternetEstimateDetails userBill={userBill} submissionType={submissionTypes.ESTIMATE} onEdit={onEdit} />
            );

          default:
            return null;
        }

      default:
        return null;
    }
  };

  const getFilterSorterSection = () => {
    if (!showFilterSorterSection || !userBill) return null;

    switch (userBill.implementationCode) {
      case implementationCodes.ELECTRICITY.AU_DEFAULT:
      case implementationCodes.GAS.AU_DEFAULT:
        return (
          <>
            <div className="heading">Sort deals by</div>
            <div className="drawer-buttons">
              {createSortButton(comparisonSorting.GUARANTEED.Id, 'Guaranteed pricing')}
              {createSortButton(comparisonSorting.CONDITIONAL.Id, 'Conditional discounts')}
            </div>
          </>
        );

      case implementationCodes.HOME_LOAN.AU_DEFAULT:
        return (
          <>
            <div className="heading">Filter deals by</div>
            <div className="drawer-buttons">
              {createSortButton(comparisonSorting.GUARANTEED.Id, 'Lowest rate')}
              {createSortButton(comparisonSorting.CONDITIONAL.Id, 'Lowest rate with offers')}
            </div>
          </>
        );

      case implementationCodes.INTERNET.AU_DEFAULT:
        return (
          <>
            {selectedFilter && (
              <>
                <div className="heading">Filter deals by</div>
                <div className="drawer-filter">
                  <div className="drawer-filter-heading">Download speed</div>
                  <AvForm>
                    <div>
                      <AvField
                        bsSize="sm"
                        type="select"
                        name="downloadSpeed"
                        placeholder=""
                        value={selectedFilter.downloadSpeed}
                        onChange={e => filterByChanged('downloadSpeed', parseInt(e.target.value))}
                      >
                        {internetDownloadSpeeds.map((ref, i) => (
                          <option key={`downloadSpeed-${ref.id}`} value={ref.id.toString()}>
                            {ref.description}
                          </option>
                        ))}
                      </AvField>
                    </div>
                    <div>
                      <QsCheckBox
                        isChecked={selectedFilter.dataUnlimited}
                        onCheck={() => filterByChanged('dataUnlimited', !selectedFilter.dataUnlimited)}
                        label="Unlimited data plans only"
                      />
                    </div>
                  </AvForm>
                </div>
              </>
            )}

            <div className="heading">Sort best price by</div>
            <div className="drawer-buttons">
              {createSortButton(comparisonSorting.CONDITIONAL.Id, 'Including special offers')}
              {createSortButton(comparisonSorting.GUARANTEED.Id, 'Ongoing price')}
            </div>
          </>
        );

      default:
        return null;
    }
  };

  const createSortButton = (comparisonSortingType, label) => {
    return (
      <Button
        className={`sm-button-secondary ${selectedFilterSorter.sort === comparisonSortingType ? ' selected' : ''}`}
        onClick={() => sortByChanged(comparisonSortingType)}
      >
        {selectedFilterSorter.sort === comparisonSortingType && <i className="material-icons">done</i>}
        <div className="text">{label}</div>
      </Button>
    );
  };

  const heading = `My ${serviceType ? serviceType.Name.toLowerCase() : ''} bill`;

  return (
    <>
      <Drawer
        placement="right"
        wrapperClassName={`sm-drawer-wrapper${drawerOpen ? ' open' : ''}`}
        level={null}
        open={drawerOpen}
        handler={drawerHandle}
        onClose={onCloseDrawer}
        onHandleClick={drawerOpen ? onCloseDrawer : onOpenDrawer}
      >
        <div className="heading first-heading">Your details</div>

        {getDetails()}

        {getFilterSorterSection()}

        <div className="drawer-buttons qs-mt-lg">
          <Button
            className="sm-button-primary"
            onClick={() => {
              onCloseDrawer();
              openMarketingDialog();
            }}
          >
            Why switch with us?
          </Button>
        </div>

        <div className="heading">{heading}</div>
        <div className="description">
          Access your <OrganiserName nameOnly /> to see total household savings.
        </div>

        <div className="service-card-wrapper">{getServiceCardSample()}</div>

        <div className="close-button">
          <GeneralDialogCloseIcon onClick={onCloseDrawer} />
        </div>
      </Drawer>

      {allowEdit && (
        <SubmitBillDialogUx2
          enabled={true}
          showBackButton={false}
          launchMode={launchDialogModes.DEFAULT}
          confirmButtonText="Update"
          serviceType={serviceType}
          uploadBillType={uploadBillTypes.MANUAL}
          uploadSubType={uploadSubTypes.getSubType(serviceType, estimate)}
          submissionId={submissionId}
          submission={estimate}
          dialogOpen={submitDialogOpen}
          onDialogClosing={() => {
            setSubmitDialogOpen(false);
          }}
        />
      )}

      <GeneralDialog
        key="marketingDialog"
        closeButtonText="Close"
        closeButtonClass="sm-link-button"
        showCloseIcon={false}
        bodyClass="no-padding"
        footerClass="link-buttons"
        dialogOpen={marketingDialogOpen}
        onClose={closeMarketingDialog}
      >
        <OfferMarketing onClose={closeMarketingDialog} startIndex={1} serviceType={serviceType} />
      </GeneralDialog>
    </>
  );
};

export default CompareDrawer;
