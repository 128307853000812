export const landingTypes = {
  NONE: 0,
  COMPARISON: 1,
  REGISTRATION: 2,
  PREFERENCES: 3,
  EXTERNAL_COMPARISON: 6,
  DASHBOARD: 7,
  SURVEY: 8,
  SERVICE_CARD: 9,
  SERVICE_CARDS: 10
};
