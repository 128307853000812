import React from 'react';
import VerifyIdAu from '../common/verifyIdAu';

const IdVerificationSection = ({ confirmDetail, offer, onChange, onShowQuickTip }) => {
  return (
    <>
      <div className="label">Identification</div>
      <div>
        <VerifyIdAu
          onChange={onChange}
          onShowQuickTip={onShowQuickTip}
          details={confirmDetail}
          acceptDriversLicenceRules={offer.acceptDriversLicence}
          acceptMedicare={offer.acceptMedicare}
          acceptPassport={offer.acceptPassport}
          acceptForeignPassport={offer.acceptForeignPassport}
          captureSecondary={false}
          acceptMedicareSecondary={false}
          acceptForeignPassportSecondary={false}
          acceptDateOfBirth={true}
        />
      </div>
    </>
  );
};

export default IdVerificationSection;
