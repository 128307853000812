import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import FaqAu from './common/faqAu';
import FaqUs from './common/faqUs';
import { homePageTypes, agentOperatingModes } from '../enums/launchTypes';

const Faq = props => {
  const { panelProviders, country } = useSelector(state => state.config);
  const { homePageType, operatingMode } = useSelector(state => state.config.settings);

  const homePage = homePageTypes.getById(homePageType);
  const agentMode = agentOperatingModes.getById(operatingMode);

  return (
    <>
      <Helmet>
        <title>FAQs</title>
      </Helmet>
      <Container>
        <Row>
          <Col xs="12">
            <h1>Frequently Asked Questions</h1>
            {
              {
                AUS: <FaqAu panelProviders={panelProviders} />,
                USA: <FaqUs panelProviders={panelProviders} />
              }[country.alpha3]
            }
          </Col>
        </Row>
        {agentMode === agentOperatingModes.ACTIVE && (
          <Row className="qs-mt-lg">
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <Button
                className="sm-button-primary full-width"
                onClick={() => (window.history.length > 2 ? props.history.goBack() : props.history.push(homePage.path))}
              >
                Back
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Faq;
