import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Range, getTrackBackground } from 'react-range';
import { InputGroup, InputGroupAddon, InputGroupText, Input, Button, Row, Col } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';

const Slider = ({
  value,
  precision,
  step,
  markers,
  labelPrefix,
  labelSuffix,
  minValueLabel,
  maxValueSuffix,
  fieldName,
  required,
  allowModeChange,
  mode,
  min: minx,
  max: maxx,
  onFinalChange: parentOnFinalChange
}) => {
  const { secondaryButtonBackgroundColor, primaryButtonBackgroundColor } = useSelector(
    state => state.content.cssVariables
  );
  let minValue = minx;
  let maxValue = maxx;

  const getSliderValue = target => {
    if (markers) {
      return markers.reduce((previous, current) => {
        return Number(
          Number(Math.abs(current - target) < Math.abs(previous - target) ? current : previous).toFixed(precision || 0)
        );
      });
    } else {
      return Number(
        Number(!target || target < minValue ? minValue : target > maxValue ? maxValue : target).toFixed(precision || 0)
      );
    }
  };

  const initialize = value => {
    let sliderValue = getSliderValue(value);

    if (parentOnFinalChange) {
      parentOnFinalChange(sliderValue);
    }

    return sliderValue;
  };

  const [outOfRange, setOutOfRange] = useState(false);
  const [values, setValues] = useState([initialize(value)]);
  const [userValue, setUserValue] = useState(initialize(value));
  const [userMode, setUserMode] = useState(mode || 'slider');

  const onSliderChange = values => {
    let sliderValue = getSliderValue(values[0]);
    setValues([sliderValue]);
    setUserValue(sliderValue);
  };

  const onTextChange = value => {
    if (value > maxValue || value < minValue) {
      setOutOfRange(true);
    } else {
      let sliderValue = getSliderValue(value);
      setValues([sliderValue]);
      setUserValue(sliderValue);
      setOutOfRange(false);
      setUserMode('slider');

      if (parentOnFinalChange) {
        parentOnFinalChange(sliderValue);
      }
    }
  };

  if (markers) {
    minValue = Math.min(...markers);
    maxValue = Math.max(...markers);
  }

  // --------------------------------------------------------------------------
  // Update slider value if changed on parent
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (value) {
      let sliderValue = getSliderValue(value);
      setValues([sliderValue]);
      setUserValue(sliderValue);
    }
  }, [value]);

  const isMinLabel = minValueLabel && values[0] === minValue;
  const isMaxValue = values[0] === maxValue;
  const prefix = isMinLabel ? '' : labelPrefix || '';
  const sliderValue = isMinLabel ? '' : values[0].toLocaleString();
  const suffix = isMinLabel || isMaxValue ? '' : labelSuffix || '';
  const minLabel = isMinLabel ? minValueLabel || '' : '';
  const maxLabel = isMaxValue ? maxValueSuffix || '' : '';

  const displayValue = `${minLabel}${prefix}${sliderValue}${suffix}${maxLabel}`;

  return (
    <>
      {userMode === 'text' && (
        <div className="qs-slider-text-group-container animated fadeIn slow">
          <InputGroup className="qs-slider-text-group">
            {labelPrefix && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{labelPrefix}</InputGroupText>
              </InputGroupAddon>
            )}
            <Input
              type="number"
              onChange={e => setUserValue(Number(e.target.value))}
              value={userValue.toString().replace(/^0+/, '')}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onTextChange(userValue);
                }
              }}
            />
            {labelSuffix && (
              <InputGroupAddon addonType="append">
                <InputGroupText>{labelSuffix}</InputGroupText>
              </InputGroupAddon>
            )}
            <InputGroupAddon addonType="append">
              <Button
                color="success"
                onMouseUpCapture={() => {
                  onTextChange(userValue);
                }}
              >
                OK
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}
      {userMode === 'slider' && (
        <Row>
          <Col>
            <div className="qs-slider animated fadeIn">
              <Range
                values={values}
                step={step}
                min={minValue}
                max={maxValue}
                onChange={onSliderChange}
                onFinalChange={() => {
                  if (parentOnFinalChange) parentOnFinalChange(values[0]);
                }}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    className="qs-slider-track-container"
                    style={{ ...props.style }}
                  >
                    <div
                      ref={props.ref}
                      className="qs-slider-track"
                      style={{
                        background: getTrackBackground({
                          values: values,
                          colors: [primaryButtonBackgroundColor, secondaryButtonBackgroundColor],
                          min: minValue,
                          max: maxValue
                        })
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div {...props} style={{ ...props.style }} className="qs-slider-thumb-container">
                    <div className="qs-slider-thumb">
                      <nobr>{displayValue}</nobr>
                    </div>
                    <div
                      style={{
                        height: '30px',
                        width: '30px',
                        backgroundColor: isDragged ? primaryButtonBackgroundColor : secondaryButtonBackgroundColor
                      }}
                    />
                  </div>
                )}
              />
            </div>
            {required && (
              <div className="qs-slider-slider-validation">
                <AvField
                  type="text"
                  name={`${fieldName}_slider`}
                  value={values[0]}
                  className="qs-tc-hidden"
                  validate={{
                    hidden: (value, ctx, input, cb) => {
                      if (!value || value === 0) {
                        cb(`${fieldName} is required`);
                      } else cb(true);
                    }
                  }}
                />
              </div>
            )}
          </Col>

          <Col xs="1" className="qs-slider-mode-button">
            {allowModeChange && (
              <i
                id={'keyboardIcon_slider'}
                className="material-icons qs-slider-textbox-icon"
                onClick={() => {
                  setUserMode(userMode === 'text' ? 'slider' : 'text');
                }}
              >
                keyboard
              </i>
            )}
          </Col>
        </Row>
      )}
      <div className="qs-slider-text-validation">
        <AvField
          type="text"
          name={`${fieldName}_text`}
          value={userValue}
          className="qs-tc-hidden"
          validate={{
            hidden: (value, ctx, input, cb) => {
              if (allowModeChange && userMode === 'text') {
                if (outOfRange) {
                  cb(`${fieldName} must be between ${minValue} and ${maxValue}`);
                } else {
                  cb(`Please click OK to apply the ${fieldName}`);
                }
              } else {
                cb(true);
              }
            }
          }}
        />
      </div>
    </>
  );
};

export default Slider;
