import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';
import { useSelector } from 'react-redux';

import { billTypes } from '../../enums/billTypes';

import FaqQuestion from './faqQuestion';
import CommissionTermsDialog from '../dialogs/commissionTermsDialog';

const FaqAu = ({ panelProviders }) => {
  useEffect(() => {
    const hash = window.location.hash;

    if (hash && hash.startsWith('#')) {
      var id = hash.substring(1);

      scroller.scrollTo(id, {
        duration: 800,
        delay: 500,
        smooth: 'easeInOutQuart'
      });
    }
  }, []);

  const { areasSupportedDisclaimer } = useSelector(state => state.content.general);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const hasInternet = billTypes.hasServiceType(agentBillTypes, billTypes.INTERNET);
  const hasHomeLoan = billTypes.hasServiceType(agentBillTypes, billTypes.HOME_LOAN);

  const energyPanel = panelProviders.filter(
    p => p.provider.billTypes.filter(bt => bt.id === billTypes.ELECTRICITY.Id || bt.id === billTypes.GAS.Id).length > 0
  );

  const internetPanel = panelProviders.filter(
    p => p.provider.billTypes.filter(bt => bt.id === billTypes.INTERNET.Id).length > 0
  );

  const homeLoanPanel = panelProviders.filter(
    p => p.provider.billTypes.filter(bt => bt.id === billTypes.HOME_LOAN.Id).length > 0
  );

  const getCustomerCommissionQuestions = () => {
    const serviceTypeOptions = { includeExternal: true };
    const agentServices = billTypes.getAgentServices(agentBillTypes, null, serviceTypeOptions);

    const commissionServices =
      agentServices &&
      agentServices.filter(e => e.enabled && (e.hasCommission || (e.providers && e.providers.length > 0)));

    if (!commissionServices || commissionServices.length === 0) return;

    const firstService = commissionServices[0];

    const commissionName = firstService.commissionType.Name;
    const commissionTag = firstService.commissionType.Tag;
    const question1 = `When will I get my ${commissionName} offer?`;

    const body1 = `After you've successfully switched a service with an associated ${commissionName} offer, we'll need a little time to check that the terms of the ${commissionTag} offer have been met. Your ${commissionName} will be sent to the details you used in your application, between 60 and 90 days after your application has been successfully approved by the retailer. To be eligible for the ${commissionName} offer, you'll need to be an active billing customer with the retailer on the day the ${commissionName} is due to be issued. If your agreement was cancelled or you decide to switch to a different product, you will not receive your ${commissionTag}.`;

    const answer1 = (
      <>
        <div>{body1}</div>
        <div className="sm-faq-customer-commissions">
          <table>
            <thead>
              <tr>
                <th>Service</th>
                <th>{commissionName}</th>
                <th>Terms</th>
              </tr>
            </thead>
            <tbody>
              {commissionServices.map((e, i) => (
                <React.Fragment key={`faq_commission_${i}`}>
                  {e.hasCommission && (
                    <tr key={`faq_commission_tr_${i}`}>
                      <td>{e.serviceType.Name}</td>
                      <td>{`${
                        e.commissionType.Denomination
                      }${e.agentBillType.customerCommission.toLocaleString()}`}</td>
                      <td>
                        <CommissionTermsDialog
                          buttonText="Terms"
                          dialogKey={`faq_commissionTerms_${i}`}
                          serviceType={e.serviceType}
                        />
                      </td>
                    </tr>
                  )}
                  {e.providers &&
                    e.providers.map((p, i) => (
                      <tr key={`faq_commission_provider_tr_${i}`}>
                        <td>{`${e.serviceType.Name} - ${p.providerDisplayCode} (sponsored offers)`}</td>
                        <td>{`${e.commissionType.Denomination}${p.customerCommission.toLocaleString()}`}</td>
                        <td>
                          <CommissionTermsDialog
                            buttonText="Terms"
                            dialogKey={`faq_commissionTerms_${i}_`}
                            serviceType={e.serviceType}
                            providerCode={p.providerCode}
                          />
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );

    const question2 = `My application was approved by the retailer, but I haven't received my ${commissionName}. What should I do?`;

    const answer2 = (
      <>
        <span>{`Once your application is successfully approved by the retailer, we need a little time to do some checks. This can take up to three months. To be eligible for the ${commissionName} offer, you'll need to be an active billing customer with the retailer on the day the ${commissionTag} are due to be issued. If you've met the terms of the offer and you'd like to know more, please email `}</span>
        <a href={`mailto:service@smartme.biz`}>service@smartme.biz</a> <span>and we'll get it sorted.</span>
      </>
    );

    return (
      <>
        <FaqQuestion id="faq_offer" question={question1} answer={answer1} />
        <FaqQuestion question={question2} answer={answer2} />
      </>
    );
  };

  return (
    <>
      {energyPanel && energyPanel.length > 0 && (
        <FaqQuestion
          id="retailers"
          question="Which energy retailers are considered in my comparison?"
          answer={
            <>
              We consider offers from Australia's leading energy retailers to find the product that best aligns to your
              needs. These retailers include:{' '}
              <ul className="qs-mt-md">
                {energyPanel.map(a => {
                  return <li key={a.provider.displayCode}>{a.provider.displayCode}</li>;
                })}
              </ul>
              {areasSupportedDisclaimer}
            </>
          }
        />
      )}

      {hasInternet && internetPanel && internetPanel.length > 0 && (
        <FaqQuestion
          id="retailers"
          question="Which broadband retailers are considered in my comparison?"
          answer={
            <>
              We consider offers from Australia's leading broadband retailers to find the product that best aligns to
              your needs. These retailers include:{' '}
              <ul className="qs-mt-md">
                {internetPanel.map(a => {
                  return <li key={a.provider.displayCode}>{a.provider.displayCode}</li>;
                })}
              </ul>
            </>
          }
        />
      )}

      {hasHomeLoan && homeLoanPanel && homeLoanPanel.length > 0 && (
        <FaqQuestion
          id="retailers"
          question="Which home loan providers are considered in my comparison?"
          answer={
            <>
              We consider offers from Australia's leading home loan providers to find the product that best aligns to
              your needs. These providers include:{' '}
              <ul className="qs-mt-md">
                {homeLoanPanel.map(a => {
                  return <li key={a.provider.displayCode}>{a.provider.displayCode}</li>;
                })}
              </ul>
            </>
          }
        />
      )}

      <FaqQuestion
        question="Are there any parts of my bill not considered in the comparison?"
        answer={
          <>
            Comparison <span className="qs-strong">does not</span> take into account concessions, payment processing
            fees, paper bill fees, green energy contributions, government rebates, guaranteed service level credits or
            any balances carried forward from previous bill, unless otherwise stated. Comparison{' '}
            <span className="qs-strong">does</span> take in to account the number of days of energy usage, the amount of
            kWh or MJ usage, the metering type of the energy consumed e.g. peak / off-peak / controlled load, usage
            pricing steps, solar feed-in and any discounts applied.
          </>
        }
      />

      <FaqQuestion
        question="Is this service free for consumers to use?"
        answer="Yes, this is a completely free service for consumers."
      />

      <FaqQuestion
        question="How do you make money?"
        answer="We’re paid a commission when you choose to switch your service. This is common place throughout the industry.
        The commission we are paid by retailers is an economical way for them to attract new customers, and in many
        cases costs less than their own advertising or sales activity."
      />

      <FaqQuestion
        question="What happens after I accept a deal?"
        answer="Once submitted, we will send your application to your chosen retailer. They will either send you a welcome pack
        or call you if they have any questions regarding your request."
      />

      <FaqQuestion
        question="Is my information private?"
        answer="We take your privacy seriously. We only use your bill and personal information to compare your bill usage with
        other offers in the market and for the purposes of providing our services. We don't share your personal data
        with any third parties outside of what is required to deliver our services. Bill information we save in our own
        systems is separated from personal identification information. For more information, see our privacy policy on
        this website."
      />

      <FaqQuestion
        question="Is my information secure?"
        answer="SmartMe uses secure encryption and security best practice to ensure our customer's information is kept secure."
      />

      <FaqQuestion
        id="optout"
        question="Can I opt-out of this service?"
        answer="Yes. You simply need to contact your current energy provider and change the email address your bills are sent
        to. But remember, if you do this, we will no longer be able to provide our services."
      />

      {getCustomerCommissionQuestions()}
    </>
  );
};
export default FaqAu;
