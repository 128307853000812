import React from 'react';

import { billTypes } from '../../enums/billTypes';

import { ReactComponent as ElectricityIcon } from '../icons/services/circle/electricity.svg';
import { ReactComponent as GasIcon } from '../icons/services/circle/gas.svg';
import { ReactComponent as HealthIcon } from '../icons/services/circle/health.svg';
import { ReactComponent as HomeInsuranceIcon } from '../icons/services/circle/home-insurance.svg';
import { ReactComponent as HomeLoanIcon } from '../icons/services/circle/home-loan.svg';
import { ReactComponent as InternetIcon } from '../icons/services/circle/internet.svg';
import { ReactComponent as VehicleInsuranceIcon } from '../icons/services/circle/vehicle-insurance.svg';
import { ReactComponent as MobilePhoneIcon } from '../icons/services/circle/mobile-phone.svg';
import { ReactComponent as SolarInstallationIcon } from '../icons/services/circle/solar-installation.svg';
import { ReactComponent as SolarFinanceIcon } from '../icons/services/circle/solar-finance.svg';
import { ReactComponent as BusinessLoanIcon } from '../icons/services/circle/business-loan.svg';
import { ReactComponent as PlusCircleIcon } from '../icons/util/plus-circle.svg';

const ServiceIcon = ({ serviceType, isAdd }) => {
  const getIcon = () => {
    if (isAdd) return <PlusCircleIcon />;
    if (!serviceType) return <PlusCircleIcon />;

    switch (serviceType.Id) {
      case billTypes.ELECTRICITY.Id:
        return <ElectricityIcon />;
      case billTypes.GAS.Id:
        return <GasIcon />;
      case billTypes.HEALTH_INSURANCE.Id:
        return <HealthIcon />;
      case billTypes.HOME_AND_CONTENTS_INSURANCE.Id:
        return <HomeInsuranceIcon />;
      case billTypes.HOME_LOAN.Id:
        return <HomeLoanIcon />;
      case billTypes.INTERNET.Id:
        return <InternetIcon />;
      case billTypes.VECHICLE_INSURANCE.Id:
        return <VehicleInsuranceIcon />;
      case billTypes.MOBILE_PHONE.Id:
        return <MobilePhoneIcon />;
      case billTypes.SOLAR_INSTALLATION.Id:
        return <SolarInstallationIcon />;
      case billTypes.SOLAR_FINANCE.Id:
        return <SolarFinanceIcon />;
      case billTypes.BUSINESS_LOAN.Id:
        return <BusinessLoanIcon />;
      default:
        return null;
    }
  };

  return getIcon();
};

export default ServiceIcon;
