import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function ajaxCallReducer(state = initialState.ajaxCall, action) {
  let count;
  let context;
  let serviceType;

  switch (action.type) {
    case types.SET_AJAX_CALL_START:
      count = state.count + action.increment;
      context = action.context ? action.context : state.context ? state.context : null;
      serviceType = action.serviceType ? action.serviceType : state.serviceType ? state.serviceType : null;

      return objectAssign({}, state, { count, context, serviceType });

    case types.SET_AJAX_CALL_END:
      count = state.count + action.increment;
      context = count > 0 ? state.context : null;
      serviceType = count > 0 ? state.serviceType : null;

      return objectAssign({}, state, {
        count,
        context,
        serviceType
      });

    default:
      return state;
  }
}
