import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AvField } from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';
import SuburbSelector from '../common/suburbSelector';

const AddressManual = props => {
  const {
    onAddressSelected,
    enableSuburbSelector,
    propertyAddress,
    namePrefix,
    large,
    showMinimalManualFields
  } = props;

  const { streetTypes } = useSelector(state => state.config.referenceData);
  const { unitNumberName } = useSelector(state => state.content.general);

  const [unitNumber, setUnitNumber] = useState(null);
  const [streetNumber, setStreetNumber] = useState(null);
  const [streetName, setStreetName] = useState(null);
  const [streetType, setStreetType] = useState(null);
  const [streetSuffix, setStreetSuffix] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(propertyAddress);
  const [streetNumberSuffix, setStreetNumberSuffix] = useState(null);
  const [houseNumber, setHouseNumber] = useState(null);
  const [houseNumberSuffix, setHouseNumberSuffix] = useState(null);
  const [floor, setFloor] = useState(null);
  const [floorType, setFloorType] = useState(null);
  const [lotNumber, setLotNumber] = useState(null);
  const [propertyName, setPropertyName] = useState(null);
  const [unitType, setUnitType] = useState(null);

  const [detailedAddressEntry, setDetailedAddressEntry] = useState(false);

  // --------------------------------------------------------------------------
  // Detect changes in component and fire parent onAddressSelected event.
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (onAddressSelected) {
      const selectedStreetType = streetTypes.find(o => {
        return o.displayNameLong === streetType;
      });

      const streetTypeShort = selectedStreetType ? selectedStreetType.displayNameShort : '';
      const streetTypeLong = selectedStreetType ? selectedStreetType.displayNameLong : '';

      const route = `${streetName} ${streetTypeLong}${streetSuffix ? ` ${streetSuffix}` : ''}`;
      const routeShort = `${streetName} ${streetTypeShort}${streetSuffix ? ` ${streetSuffix}` : ''}`;
      const suburb = selectedAddress ? selectedAddress.suburb : '';
      const suburbShort = selectedAddress ? selectedAddress.suburbShort : '';
      const state = selectedAddress ? selectedAddress.state : '';
      const postcode = selectedAddress ? selectedAddress.postcode : '';
      const country = selectedAddress ? selectedAddress.country : '';

      var newAddress = {
        unitNumber,
        streetNumber,
        route,
        routeShort,
        suburb,
        suburbShort,
        state,
        postcode,
        country,
        fullAddress: `${streetNumber} ${route}, ${suburb} ${state}, ${country}`,
        streetAddress: `${streetNumber} ${route}`,
        streetNumberSuffix,
        houseNumber,
        houseNumberSuffix,
        floor,
        floorType,
        lotNumber,
        propertyName,
        unitType
      };

      onAddressSelected(newAddress);
    }
  }, [
    unitNumber,
    streetNumber,
    streetName,
    streetType,
    selectedAddress,
    streetSuffix,
    streetNumberSuffix,
    houseNumber,
    houseNumberSuffix,
    floor,
    floorType,
    lotNumber,
    propertyName,
    unitType
  ]);

  // --------------------------------------------------------------------------
  // Suburb selector change handler.
  // --------------------------------------------------------------------------
  const onSuburbSelectorChange = address => {
    setSelectedAddress(address);
  };

  const onMoreAddressFields = () => {
    setDetailedAddressEntry(true);
  };

  const streetTypeField = (
    <div className="street-type">
      <AvField
        bsSize="sm"
        type="select"
        name={`${namePrefix}-streetType`}
        onChange={e => setStreetType(e.target.value)}
        value={streetType}
      >
        <option value="">Select street type</option>
        {streetTypes.map((ref, i) => (
          <option key={`streetType-${ref.id}`} value={ref.displayNameLong}>
            {ref.displayNameLong}
          </option>
        ))}
      </AvField>
    </div>
  );

  const streetSuffixField = (
    <div className="street-suffix">
      <AvField
        bsSize="sm"
        placeholder="Street suffix"
        name={`${namePrefix}-streetSuffix`}
        type="text"
        value={streetSuffix}
        onChange={e => setStreetSuffix(e.target.value)}
      />
    </div>
  );

  return (
    <div className={`sm-address-manual${large ? ' large' : ''}`}>
      <div className="address-row">
        <div className="unit-number">
          <AvField
            bsSize="sm"
            name={`${namePrefix}-unitNumber`}
            placeholder={unitNumberName}
            type="text"
            value={unitNumber}
            onChange={e => setUnitNumber(e.target.value === '' ? null : e.target.value)}
            validate={{
              maxLength: { value: 10 }
            }}
          />
        </div>
        <div className="street-number">
          <AvField
            bsSize="sm"
            name={`${namePrefix}-streetNumber`}
            placeholder="No."
            type="text"
            value={streetNumber}
            onChange={e => setStreetNumber(e.target.value === '' ? null : e.target.value)}
            validate={{
              maxLength: { value: 5 }
            }}
          />
        </div>
        <div className="street-name">
          <AvField
            bsSize="sm"
            placeholder="Street name"
            name={`${namePrefix}-streetName`}
            type="text"
            value={streetName}
            onChange={e => setStreetName(e.target.value)}
          />
        </div>
        {large && (
          <>
            {streetTypeField}
            {showMinimalManualFields ? null : streetSuffixField}
          </>
        )}
        <AvField
          name={`addressValidation`}
          className="qs-tc-hidden"
          validate={{
            hidden: (value, ctx, input, cb) => {
              const array = [];

              if (!streetNumber || streetNumber.trim() === '') array.push('Street number is required.');
              if (!streetName || streetName.trim() === '') array.push('Street name is required.');
              if (/^\d/.test(streetName ? streetName.trim() : '')) array.push('Please enter a valid street name.');

              if (array.length > 0) {
                cb(`${array.join(' ')}`);
              } else {
                cb(true);
              }
            }
          }}
        />
      </div>

      {!large && (
        <div className={`address-row${detailedAddressEntry ? '' : ' no-margin'}`}>
          {streetTypeField}
          {showMinimalManualFields ? null : streetSuffixField}
        </div>
      )}

      {detailedAddressEntry ? (
        <>
          <div className="address-row">
            <div className="lot-number">
              <AvField
                bsSize="sm"
                placeholder="Lot"
                name={`${namePrefix}-lot`}
                type="text"
                value={lotNumber}
                onChange={e => setLotNumber(e.target.value)}
              />
            </div>

            <div className="floor">
              <AvField
                bsSize="sm"
                placeholder="Floor"
                name={`${namePrefix}-floor`}
                type="text"
                value={floor}
                onChange={e => setFloor(e.target.value)}
              />
            </div>

            <div className="floor-level-type">
              <AvField
                bsSize="sm"
                placeholder="Floor level type"
                name={`${namePrefix}-floorLevelType`}
                type="text"
                value={floorType}
                onChange={e => setFloorType(e.target.value)}
              />
            </div>

            {!showMinimalManualFields && (
              <div className="house-number">
                <AvField
                  bsSize="sm"
                  placeholder="House"
                  name={`${namePrefix}-houseNumber`}
                  type="text"
                  value={houseNumber}
                  onChange={e => setHouseNumber(e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="address-row">
            {!showMinimalManualFields && (
              <>
                <div className="house-number-suffix">
                  <AvField
                    bsSize="sm"
                    placeholder="House suffix"
                    name={`${namePrefix}-houseNumberSuffix`}
                    type="text"
                    value={houseNumberSuffix}
                    onChange={e => setHouseNumberSuffix(e.target.value)}
                  />
                </div>

                <div className="street-number-suffix">
                  <AvField
                    bsSize="sm"
                    placeholder="Street No. Suffix"
                    name={`${namePrefix}-steetNumberSuffix`}
                    type="text"
                    value={streetNumberSuffix}
                    onChange={e => setStreetNumberSuffix(e.target.value)}
                  />
                </div>
              </>
            )}

            <div className="unit-type">
              <AvField
                bsSize="sm"
                placeholder="Unit type"
                type="select"
                name={`${namePrefix}-unitType`}
                value={unitType}
                onChange={e => setUnitType(e.target.value)}
              >
                <option value="">Select unit type</option>
                <option value="Antenna">Antenna</option>
                <option value="Apartment">Apartment</option>
                <option value="Automated teller machine">Automated teller machine</option>
                <option value="Barbeque">Barbeque</option>
                <option value="Block">Block</option>
                <option value="Boatshed">Boatshed</option>
                <option value="Building">Building</option>
                <option value="Bungalow">Bungalow</option>
                <option value="Cage">Cage</option>
                <option value="Carpark">Carpark</option>
                <option value="Carspace">Carspace</option>
                <option value="Club">Club</option>
                <option value="Coolroom">Coolroom</option>
                <option value="Cottage">Cottage</option>
                <option value="Duplex">Duplex</option>
                <option value="Factory">Factory</option>
                <option value="Flat">Flat</option>
                <option value="Garage">Garage</option>
                <option value="Hall">Hall</option>
                <option value="House">House</option>
                <option value="Kiosk">Kiosk</option>
                <option value="Lease">Lease</option>
                <option value="Lobby">Lobby</option>
                <option value="Loft">Loft</option>
                <option value="Lot">Lot</option>
                <option value="Maisonette">Maisonette</option>
                <option value="Marine berth">Marine berth</option>
                <option value="Office">Office</option>
                <option value="Penthouse">Penthouse</option>
                <option value="Rear">Rear</option>
                <option value="Reserve">Reserve</option>
                <option value="Room">Room</option>
                <option value="Section">Section</option>
                <option value="Shed">Shed</option>
                <option value="Shop">Shop</option>
                <option value="Showroom">Showroom</option>
                <option value="Sign">Sign</option>
                <option value="Site">Site</option>
                <option value="Stall">Stall</option>
                <option value="Store">Store</option>
                <option value="Strata unit">Strata unit</option>
                <option value="Studio">Studio</option>
                <option value="Substation">Substation</option>
                <option value="Suite">Suite</option>
                <option value="Tenancy">Tenancy</option>
                <option value="Tower">Tower</option>
                <option value="Townhouse">Townhouse</option>
                <option value="Unit">Unit</option>
                <option value="Vault">Vault</option>
                <option value="Villa">Villa</option>
                <option value="Ward">Ward</option>
                <option value="Warehouse">Warehouse</option>
                <option value="Workshop">Workshop</option>
              </AvField>
            </div>
          </div>
          {!showMinimalManualFields && (
            <div className="property-name">
              <AvField
                bsSize="sm"
                placeholder="Property name"
                name={`${namePrefix}-propertyName`}
                type="text"
                value={propertyName}
                onChange={e => setPropertyName(e.target.value)}
              />
            </div>
          )}
        </>
      ) : (
        <div className="more-address">
          <Button className="sm-link-button medium" onClick={onMoreAddressFields}>
            More address fields
          </Button>
        </div>
      )}
      <div className="suburb-selector">
        <SuburbSelector
          enabled={enableSuburbSelector}
          propertyAddress={selectedAddress}
          onChange={onSuburbSelectorChange}
          showManualPostcode={false}
          showAreasSupportedDisclaimer={false}
        />
      </div>
    </div>
  );
};

export default AddressManual;
