import React, { useState, useEffect } from 'react';
import { Row, Col, Button, FormGroup, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import YourDetails from './yourDetails';
import Premise from './premise';
import VerifyIdAu from './verifyIdAu';
import InternetAddon from './internetAddon';
import { submissionTypes, occupancyPurposes, billingTypes } from '../../enums/submissionTypes';
import QsButtonGroup from './qsButtonGroup';
import { billTypes } from '../../enums/billTypes';
import { occupancyTypes } from '../../enums/submissionTypes';
import { getAddress, getSubmissionDisplayAddress } from '../../utilities/addressUtilities';
import BillDisplayAddress from './billDisplayAddress';
import AddressControl from './addressControl';
import { AvField, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';
import GeneralDialog from '../dialogs/generalDialog';
import OrganiserInfo from '../common/organiserInfo';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment-timezone';
import QsCheckBox from './qsCheckBox';
import YourOrderInternet from './yourOrderInternet';

const OfferConfirmInternetDetailsAu = props => {
  const { confirmDetail, offer, onChange } = props;
  const { submissionType, submission } = useSelector(state => state.comparison);
  const { emailAddress, accountVerified } = useSelector(state => state.user);
  const { billType, occupancyType, occupancyPurpose } = useSelector(state => state.comparison.submission);
  const { country } = useSelector(state => state.config);
  const { organiserAgentName } = useSelector(state => state.content.general);
  const { mobilePhoneNumberRegex, homePhoneNumberRegex } = useSelector(
    state => state.config.applicationSettings.country
  );

  const [organiserDialogOpen, setOrganiserDialogOpen] = useState(false);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(null);
  const [yourDetailsEmailAddress, setYourDetailsEmailAddress] = useState(emailAddress);

  const productDetail = offer.productDetail;

  const showingAdditionalBillingAddress =
    confirmDetail.billingType === billingTypes.ADDRESS_OTHER.Code ||
    confirmDetail.billingType === billingTypes.ADDRESS_POBOX.Code;

  const showingAdditionalDeliveryAddress = confirmDetail.deliveryType === billingTypes.ADDRESS_OTHER.Code;

  const allowPaperBill = productDetail && productDetail.allowPaperBill;

  const onBillingAddressSelected = address => {
    onChange(null, { billingAddress: address });
  };

  const onPreviousAddressSelected = (index, address) => {
    var newOtherPreviousAddresses = [...confirmDetail.otherPreviousAddresses];

    var newOtherPreviousAddress = { ...newOtherPreviousAddresses[index] };
    newOtherPreviousAddress.address = address;

    newOtherPreviousAddresses = [
      ...newOtherPreviousAddresses.slice(0, index),
      newOtherPreviousAddress,
      ...newOtherPreviousAddresses.slice(index + 1)
    ];

    onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });
  };

  const setPreviousAddressesResidingYears = (index, residingYears) => {
    var newOtherPreviousAddresses = [...confirmDetail.otherPreviousAddresses];

    var newOtherPreviousAddress = { ...newOtherPreviousAddresses[index] };
    newOtherPreviousAddress.residingYears = residingYears;

    newOtherPreviousAddresses = [
      ...newOtherPreviousAddresses.slice(0, index),
      newOtherPreviousAddress,
      ...newOtherPreviousAddresses.slice(index + 1)
    ];

    onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });

    adjustPreviousAddresses(newOtherPreviousAddresses, confirmDetail.residingYears, confirmDetail.residingMonths);
  };

  const setPreviousAddressesResidentialStatus = (index, residentialStatus) => {
    var newOtherPreviousAddresses = [...confirmDetail.otherPreviousAddresses];

    var newOtherPreviousAddress = { ...newOtherPreviousAddresses[index] };
    newOtherPreviousAddress.residentialStatus = residentialStatus;

    newOtherPreviousAddresses = [
      ...newOtherPreviousAddresses.slice(0, index),
      newOtherPreviousAddress,
      ...newOtherPreviousAddresses.slice(index + 1)
    ];

    onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });
  };

  const setPreviousAddressesResidingMonths = (index, residingMonths) => {
    var newOtherPreviousAddresses = [...confirmDetail.otherPreviousAddresses];

    var newOtherPreviousAddress = { ...newOtherPreviousAddresses[index] };
    newOtherPreviousAddress.residingMonths = residingMonths;

    newOtherPreviousAddresses = [
      ...newOtherPreviousAddresses.slice(0, index),
      newOtherPreviousAddress,
      ...newOtherPreviousAddresses.slice(index + 1)
    ];

    onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });

    adjustPreviousAddresses(newOtherPreviousAddresses, confirmDetail.residingYears, confirmDetail.residingMonths);
  };

  const adjustPreviousAddresses = (otherPreviousAddresses, residingYears, residingMonths) => {
    setTimeout(() => {
      var newOtherPreviousAddresses;
      var totalYears;

      if (otherPreviousAddresses) {
        totalYears = totalResidingYears(null, otherPreviousAddresses, residingYears, residingMonths);
        if (totalYears >= 2) {
          onChange(null, { otherPreviousAddresses: null });
          return;
        }

        var lastIndex = otherPreviousAddresses.length - 1;

        for (var i = 0; i < otherPreviousAddresses.length; i++) {
          totalYears = totalResidingYears(i, otherPreviousAddresses, residingYears, residingMonths);
          if (totalYears >= 2) {
            newOtherPreviousAddresses = [...otherPreviousAddresses.slice(0, i + 1)];
            onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });
            return;
          }
        }

        if (otherPreviousAddresses[lastIndex].residingMonths && otherPreviousAddresses[lastIndex].residingYears) {
          newOtherPreviousAddresses = [
            ...otherPreviousAddresses,
            { address: null, residingMonths: null, residingYears: null }
          ];
          onChange(null, { otherPreviousAddresses: newOtherPreviousAddresses });
        }
      } else if (
        residingYears &&
        residingMonths &&
        totalResidingYears(null, otherPreviousAddresses, residingYears, residingMonths) < 2
      ) {
        onChange(null, { otherPreviousAddresses: [{ address: null, residingMonths: null, residingYears: null }] });
      }
    }, 1000);
  };

  const onDeliveryAddressSelected = address => {
    onChange(null, { deliveryAddress: address });
  };

  const onHomeLineChange = selections => {
    onChange(null, { homeLine: selections });
  };

  const onModemChange = selections => {
    onChange(null, { modem: selections });
  };

  const onOtherAddonChange = selections => {
    onChange(null, { otherAddons: selections });
  };

  const onChangeKeepExistingNumber = event => {
    let option = event.target.getAttribute('data-value');
    onChange(null, { keepExistingNumber: option });
  };

  const onChangeExistingAccount = event => {
    let option = event.target.getAttribute('data-value');
    onChange(null, { existingAccount: option });
  };

  const onChangeSpecificDeliveryDate = event => {
    let option = event.target.getAttribute('data-value');

    if (option === 'false') {
      setSelectedDeliveryDate(null);
      onChange(null, { specificDeliveryDate: option, deliveryDate: null });
    } else {
      onChange(null, { specificDeliveryDate: option });
    }
  };

  const onDeliveryDateChange = date => {
    setSelectedDeliveryDate(date);

    var utcDate = moment(date).format('DD/MM/YYYY');
    onChange(null, { deliveryDate: utcDate });
  };

  // --------------------------------------------------------------------------
  // Update email address on page as we may have come in anonymously
  // --------------------------------------------------------------------------
  const onYourDetailsChange = (e, data) => {
    if (data && data.emailAddress !== undefined) {
      setYourDetailsEmailAddress(data.emailAddress);
    }

    if (onChange) {
      onChange(e, data);
    }
  };

  const totalResidingYears = (maxIndex, otherPreviousAddresses, residingYears, residingMonths) => {
    if (!residingYears || !residingMonths) return 0;

    let totalResidingYears = residingYears ? parseInt(residingYears) : 0;

    if (!otherPreviousAddresses || maxIndex === null) return totalResidingYears;

    var currentMonthCount = parseInt(residingMonths);

    for (var index = 0; index <= maxIndex; index++) {
      totalResidingYears += otherPreviousAddresses[index].residingYears
        ? parseInt(otherPreviousAddresses[index].residingYears)
        : 0;

      currentMonthCount += otherPreviousAddresses[index].residingMonths
        ? parseInt(otherPreviousAddresses[index].residingMonths)
        : 0;

      if (currentMonthCount >= 12) {
        currentMonthCount -= 12;
        totalResidingYears++;
      }
    }

    return totalResidingYears;
  };

  const getOtherPreviousAddress = () => {
    if (!confirmDetail.residingYears || !confirmDetail.residingMonths || !confirmDetail.otherPreviousAddresses)
      return null;

    return confirmDetail.otherPreviousAddresses.map((item, index) => (
      <>
        <Col xs="12">
          <div className="sm-offer-billing-address">
            <AddressControl
              key="previousAddress"
              keySuffix="previous"
              labelText="Previous address"
              country={country}
              onAddressSelected={address => onPreviousAddressSelected(index, address)}
              autoPlaceholder="Previous address..."
              autoPropertyAddress={null}
              autoAddressRequired={true}
              manualAddressVisible={true}
              manualSuburbSelectorEnabled={true}
              showAreasSupportedDisclaimer={false}
              large={!isMobile}
              showMinimalManualFields={true}
            />
          </div>
        </Col>

        <Col xs="12">
          <div className="qs-more-info-table-header">I was living at this address for</div>
        </Col>
        <Col xs="12" md="2">
          <FormGroup>
            <AvField
              bsSize="sm"
              name={`residingYears-${index}`}
              placeholder="Years"
              type="select"
              value={item.residingYears}
              onChange={e => setPreviousAddressesResidingYears(index, e.target.value)}
              validate={{
                required: { value: true, errorMessage: 'Years is required' }
              }}
            >
              <option value="">Years</option>
              {Array.from(Array(20).keys()).map((value, i) => (
                <option key={`resyears-${index}-${i}`} value={value}>
                  {value}
                </option>
              ))}

              <option value="20+">20+</option>
            </AvField>
          </FormGroup>
        </Col>
        <Col xs="12" md="2">
          <FormGroup>
            <AvField
              bsSize="sm"
              name={`residingMonths-${index}`}
              placeholder="Months"
              type="select"
              value={item.residingMonths}
              onChange={e => setPreviousAddressesResidingMonths(index, e.target.value)}
              validate={{
                required: { value: true, errorMessage: 'Months is required' }
              }}
            >
              <option value="">Months</option>
              {Array.from(Array(11).keys()).map((value, i) => (
                <option key={`residingmonths-${index}-${i}`} value={value + 1}>
                  {value + 1}
                </option>
              ))}
            </AvField>
          </FormGroup>
        </Col>

        {productDetail.residentialStatuses && productDetail.residentialStatuses.length > 0 && (
          <>
            <Col xs="12">
              <div className="qs-more-info-table-header">Residential status</div>
            </Col>
            <Col xs="12" md="6">
              <FormGroup>
                <AvField
                  bsSize="sm"
                  type="select"
                  name={`residentialStatus-${index}`}
                  onChange={e => setPreviousAddressesResidentialStatus(index, e.target.value)}
                  value={item.residentialStatus}
                  validate={{
                    required: { value: true, errorMessage: 'Residential status is required' }
                  }}
                >
                  <option value="">Select residential status</option>
                  {productDetail.residentialStatuses.map((ref, i) => (
                    <option key={`residentialStatus-${ref.id}`} value={ref.id}>
                      {ref.name}
                    </option>
                  ))}
                </AvField>
              </FormGroup>
            </Col>
          </>
        )}
      </>
    ));
  };

  const getInstallDates = () => {
    if (!productDetail.showDeliveryDate) return null;

    let currentDateMoment = moment.tz(productDetail.minSelectableDate, 'YYYY-MM-DD', 'GMT').add(-1, 'days');
    const holidays = productDetail.holidays
      ? productDetail.holidays.map(date => moment.tz(date, 'YYYY-MM-DD', 'GMT').toDate())
      : [];

    let installDates = [];
    for (var i = 0; i < 181; i++) {
      currentDateMoment.add(1, 'days');
      let isoWeekday = currentDateMoment.isoWeekday();

      let currentDate = currentDateMoment.toDate();

      let isHoliday = holidays.find(item => {
        return item.getTime() === currentDate.getTime();
      });

      if (isoWeekday <= 5 && !isHoliday) installDates.push(currentDate);
    }

    return installDates;
  };

  let installDates = getInstallDates();

  const onBillingTypeChange = (event, target) => {
    onChange(null, { billingType: target, billingAddress: null });
  };

  const onDeliveryTypeChange = (event, target) => {
    onChange(null, { deliveryType: target, deliveryAddress: null });
  };

  const requireEmployerRegex = /(retire|pension|unemploy)/i;
  const requireEmployer =
    productDetail &&
    productDetail.showEmploymentDetails &&
    ((confirmDetail.industry && !requireEmployerRegex.test(confirmDetail.industry)) ||
      (confirmDetail.employmentStatus && !requireEmployerRegex.test(confirmDetail.employmentStatus)) ||
      (confirmDetail.occupationType && !requireEmployerRegex.test(confirmDetail.occupationType)));

  const displayAddress = getSubmissionDisplayAddress(submissionType, submission);

  return (
    <>
      <Row className="qs-more-info-table-header">
        <Col xs="12" md="8">
          {productDetail && productDetail.homeLine && productDetail.homeLine.length > 0 && (
            <InternetAddon
              title="Home line connection"
              addons={productDetail.homeLine}
              offer={offer}
              action="home-line-details-popover"
              onChange={onHomeLineChange}
              allowMultipleSelections={false}
            />
          )}

          {productDetail && productDetail.modems && productDetail.modems.length > 0 && (
            <InternetAddon
              title="Modem"
              addons={productDetail.modems}
              offer={offer}
              action="modem-details-popover"
              onChange={onModemChange}
              allowMultipleSelections={false}
            />
          )}

          {productDetail && productDetail.otherAddons && productDetail.otherAddons.length > 0 && (
            <InternetAddon
              title="Other addons"
              addons={productDetail.otherAddons}
              offer={offer}
              action="other-addons-details-popover"
              onChange={onOtherAddonChange}
              allowMultipleSelections={true}
            />
          )}
        </Col>

        {productDetail && productDetail.showYourOrderSummary && (
          <Col xs="12" md="4">
            <YourOrderInternet productDetail={productDetail} confirmDetail={confirmDetail} />
          </Col>
        )}
      </Row>

      <Row className="qs-more-info-table-header">
        <Col xs="12" md="6">
          Your details
        </Col>
        {productDetail.showVerifyId && (
          <Col className="d-none d-md-block" md="6">
            {productDetail.acceptSecondaryId ? 'Primary' : 'Verify'} ID
          </Col>
        )}
      </Row>

      <Row>
        <Col xs="12" md="6">
          <YourDetails
            onChange={onChange}
            details={confirmDetail}
            phoneNumberRegex={mobilePhoneNumberRegex}
            emailAddress={emailAddress}
            showMembershipId={offer.membershipId}
            showDateOfBirth={productDetail.showDateOfBirth}
            showAlternatePhoneNumber={productDetail.showAlternatePhoneNumber}
            alternatePhoneNumberRegex={`(${mobilePhoneNumberRegex}|${homePhoneNumberRegex})`}
            showTitle={true}
            showEmailAddress={true}
            showPhoneNumber={true}
            lockPhoneNumber={accountVerified}
          />

          <Premise
            onChange={onChange}
            showPrimaryResidence={false}
            showPremiseType={productDetail.showPremiseType}
            showResidencePeriod={false}
            details={confirmDetail}
          />
        </Col>
        {productDetail.showVerifyId ? (
          <>
            <Col className="d-block d-md-none qs-more-info-table-header-col" xs="12">
              <div>{productDetail.acceptSecondaryId ? 'Primary' : 'Verify'} ID</div>
            </Col>
            <Col xs="12" md="6">
              <VerifyIdAu
                onChange={onChange}
                details={confirmDetail}
                captureSecondary={productDetail.acceptSecondaryId}
                acceptMedicare={productDetail.acceptMedicare}
                acceptPassport={true}
                acceptForeignPassport={productDetail.acceptForeignPassport}
                acceptMedicareSecondary={productDetail.acceptMedicareSecondary}
                acceptPassportSecondary={true}
                acceptForeignPassportSecondary={productDetail.acceptForeignPassportSecondary}
                acceptDateOfBirth={false}
              />
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>

      {productDetail.showEmploymentDetails && (
        <>
          <Row className="qs-more-info-table-header">
            <Col>Employment details</Col>
          </Row>

          <Row>
            {productDetail.industries && productDetail.industries.length > 0 && (
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="industry"
                    onChange={e => onChange(e, { industry: e.target.value })}
                    value={confirmDetail.industry}
                    validate={{
                      required: { value: true, errorMessage: 'Industry is required' }
                    }}
                  >
                    <option value="">Select industry</option>
                    {productDetail.industries.map((ref, i) => (
                      <option key={`industry-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            )}

            {productDetail.occupations && productDetail.occupations.length > 0 && (
              <>
                <Col xs="12" md="6">
                  <FormGroup>
                    <AvField
                      bsSize="sm"
                      type="select"
                      name="occupationType"
                      onChange={e => onChange(e, { occupationType: e.target.value })}
                      value={confirmDetail.occupationType}
                      validate={{
                        required: { value: true, errorMessage: 'Occupation type is required' }
                      }}
                    >
                      <option value="">Select occupation type</option>
                      {productDetail.occupations.map((ref, i) => (
                        <option key={`occupation-${ref.id}`} value={ref.id}>
                          {ref.name}
                        </option>
                      ))}
                    </AvField>
                  </FormGroup>
                </Col>

                <Col xs="12" md="6">
                  <FormGroup>
                    <AvField
                      bsSize="sm"
                      name="occupation"
                      placeholder="Occupation"
                      type="text"
                      value={confirmDetail.occupation}
                      onChange={e => onChange(e, { occupation: e.target.value })}
                      validate={{
                        required: { value: true, errorMessage: 'Occupation is required' }
                      }}
                    />
                  </FormGroup>
                </Col>
              </>
            )}

            {productDetail.employmentStatuses && productDetail.employmentStatuses.length > 0 && (
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="employmentStatus"
                    onChange={e => onChange(e, { employmentStatus: e.target.value })}
                    value={confirmDetail.employmentStatus}
                    validate={{
                      required: { value: true, errorMessage: 'Employment status is required' }
                    }}
                  >
                    <option value="">Select employment status</option>
                    {productDetail.employmentStatuses.map((ref, i) => (
                      <option key={`employmentStatus-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            )}

            {requireEmployer && (
              <>
                <Col xs="12" md="6">
                  <FormGroup>
                    <AvField
                      bsSize="sm"
                      name="employer"
                      placeholder="Employer"
                      type="text"
                      value={confirmDetail.employer}
                      onChange={e => onChange(e, { employer: e.target.value })}
                      validate={{
                        required: { value: true, errorMessage: 'Employer is required' }
                      }}
                    />
                  </FormGroup>
                </Col>
                {productDetail.showEmploymentTime && (
                  <>
                    <Col xs="12" md="2">
                      <div className="qs-more-info-table-header">Time at current employer</div>
                    </Col>
                    <Col xs="12" md="2">
                      <FormGroup>
                        <AvField
                          bsSize="sm"
                          name="employmentYears"
                          placeholder="Years"
                          type="select"
                          value={confirmDetail.employmentYears}
                          onChange={e => onChange(e, { employmentYears: e.target.value })}
                          validate={{
                            required: { value: true, errorMessage: 'Years is required' }
                          }}
                        >
                          <option value="">Years</option>
                          {Array.from(Array(20).keys()).map((value, index) => (
                            <option key={`years-${index}`} value={value}>
                              {value}
                            </option>
                          ))}

                          <option value="20+">20+</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="2">
                      <FormGroup>
                        <AvField
                          bsSize="sm"
                          name="employmentMonths"
                          placeholder="Months"
                          type="select"
                          value={confirmDetail.employmentMonths}
                          onChange={e => onChange(e, { employmentMonths: e.target.value })}
                          validate={{
                            required: { value: true, errorMessage: 'Months is required' }
                          }}
                        >
                          <option value="">Months</option>
                          {Array.from(Array(11).keys()).map((value, index) => (
                            <option key={`months-${index}`} value={value + 1}>
                              {value + 1}
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  </>
                )}
              </>
            )}

            {productDetail.showEmployeeHasCreditCard && (
              <Col xs="12" md="6">
                <QsCheckBox
                  isChecked={confirmDetail.employeeHasCreditCard}
                  label="Do you have a credit Card?"
                  onCheck={e => onChange(e, { employeeHasCreditCard: !confirmDetail.employeeHasCreditCard })}
                />
              </Col>
            )}
          </Row>
        </>
      )}

      {(productDetail.showExistingAccount || productDetail.showKeepExistingNumber) && (
        <Row>
          {productDetail.showExistingAccount && (
            <Col xs="12">
              <div className="qs-more-info-table-header">
                Do you already have an account with {offer.providerDisplayCode}?
              </div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.existingAccount}
                onTouchButton={onChangeExistingAccount}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ]}
              />
            </Col>
          )}

          {confirmDetail.existingAccount === 'true' && (
            <Col xs="12" md="6" className="qs-mt-md">
              <FormGroup>
                <AvField
                  bsSize="sm"
                  name="accountNumber"
                  placeholder="Account number"
                  type="text"
                  value={confirmDetail.accountNumber}
                  onChange={e => onChange(e, { accountNumber: e.target.value })}
                  validate={{
                    required: { value: true, errorMessage: 'Existing account number is required' },
                    pattern: {
                      value: '^[0-9a-zA-Z]{3,20}$',
                      errorMessage: `Please enter a valid account number`
                    }
                  }}
                />
              </FormGroup>
            </Col>
          )}

          {productDetail.showKeepExistingNumber && (
            <Col xs="12">
              <div className="qs-more-info-table-header">Do you want to keep your existing phone number?</div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.keepExistingNumber}
                onTouchButton={onChangeKeepExistingNumber}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ]}
              />
            </Col>
          )}

          {confirmDetail.keepExistingNumber === 'true' && (
            <>
              <Col xs="12" md="6" className="qs-mt-md">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    name="existingNumber"
                    placeholder="Home phone number"
                    type="text"
                    value={confirmDetail.existingNumber}
                    onChange={e => onChange(e, { existingNumber: e.target.value })}
                    validate={{
                      required: { value: true, errorMessage: 'Home phone number is required' },
                      pattern: {
                        value: homePhoneNumberRegex,
                        errorMessage: `Please enter a valid home phone number for ${country.name} including area code (no spaces or hyphens)`
                      }
                    }}
                  />
                </FormGroup>
              </Col>

              <Col xs="12" md="6" className="qs-mt-md">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    name="existingNumberAccountNumber"
                    placeholder="Provider account number"
                    type="text"
                    value={confirmDetail.existingNumberAccountNumber}
                    onChange={e => onChange(e, { existingNumberAccountNumber: e.target.value })}
                    validate={{
                      required: { value: true, errorMessage: 'Provider account is required' },
                      pattern: {
                        value: '^[0-9a-zA-Z]{3,20}$',
                        errorMessage: `Please enter a valid account number`
                      }
                    }}
                  />
                </FormGroup>
              </Col>

              <Col xs="12" md="6">
                <QsCheckBox
                  isChecked={confirmDetail.hasHomePhoneTransferAuthority}
                  label="I have authority to transfer this service."
                  onCheck={e =>
                    onChange(e, { hasHomePhoneTransferAuthority: !confirmDetail.hasHomePhoneTransferAuthority })
                  }
                  validatorName="home-phone-transfer-authority"
                  validationMessage="You must have authority to transfer this service."
                />
              </Col>
            </>
          )}
        </Row>
      )}

      <Row className="qs-more-info-table-header">
        <Col>
          <div style={{ paddingTop: '3px' }}>Connection address</div>
        </Col>
      </Row>

      <BillDisplayAddress displayAddress={displayAddress} />

      {(productDetail.showDeliveryDate ||
        productDetail.residentialStatuses ||
        productDetail.showTimeAtCurrentAddress) && (
        <Row>
          {productDetail.residentialStatuses && productDetail.residentialStatuses.length > 0 && (
            <>
              <Col xs="12">
                <div className="qs-more-info-table-header">Residential status</div>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="residentialStatus"
                    onChange={e => onChange(e, { residentialStatus: e.target.value })}
                    value={confirmDetail.residentialStatus}
                    validate={{
                      required: { value: true, errorMessage: 'Residential status is required' }
                    }}
                  >
                    <option value="">Select residential status</option>
                    {productDetail.residentialStatuses.map((ref, i) => (
                      <option key={`residentialStatus-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            </>
          )}

          {productDetail.showTimeAtCurrentAddress && (
            <>
              <Col xs="12">
                <div className="qs-more-info-table-header">I have been living at this address for the past</div>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    name="residingYears"
                    placeholder="Years"
                    type="select"
                    value={confirmDetail.residingYears}
                    onChange={e => {
                      onChange(e, { residingYears: e.target.value });
                      adjustPreviousAddresses(
                        confirmDetail.otherPreviousAddresses,
                        e.target.value,
                        confirmDetail.residingMonths
                      );
                    }}
                    validate={{
                      required: { value: true, errorMessage: 'Years is required' }
                    }}
                  >
                    <option value="">Years</option>
                    {Array.from(Array(20).keys()).map((value, i) => (
                      <option key={`resyears-${i}`} value={value}>
                        {value}
                      </option>
                    ))}

                    <option value="20+">20+</option>
                  </AvField>
                </FormGroup>
              </Col>
              <Col xs="12" md="2">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    name="residingMonths"
                    placeholder="Months"
                    type="select"
                    value={confirmDetail.residingMonths}
                    onChange={e => {
                      onChange(e, { residingMonths: e.target.value });
                      adjustPreviousAddresses(
                        confirmDetail.otherPreviousAddresses,
                        confirmDetail.residingYears,
                        e.target.value
                      );
                    }}
                    validate={{
                      required: { value: true, errorMessage: 'Months is required' }
                    }}
                  >
                    <option value="">Months</option>
                    {Array.from(Array(11).keys()).map((value, i) => (
                      <option key={`residingmonths-${i}`} value={value + 1}>
                        {value + 1}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>

              {getOtherPreviousAddress()}

              {/* {totalResidingYears(0) < 2 && (
                <>
                  <Col xs="12">
                    <div className="sm-offer-billing-address">
                      <AddressControl
                        key="previousAddress"
                        keySuffix="previous"
                        labelText="Previous address"
                        country={country}
                        onAddressSelected={onPreviousAddressSelected}
                        autoPlaceholder="Previous address..."
                        autoPropertyAddress={null}
                        autoAddressRequired={true}
                        manualAddressVisible={true}
                        manualSuburbSelectorEnabled={true}
                        showAreasSupportedDisclaimer={false}
                        large={!isMobile}
                        showMinimalManualFields={true}
                      />
                    </div>
                  </Col>
                </>
              )} */}
            </>
          )}

          {productDetail.showDeliveryDate && (
            <Col xs="12">
              <div className="qs-more-info-table-header">
                How soon you want your connection to be delivered at your address?
              </div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.specificDeliveryDate}
                onTouchButton={onChangeSpecificDeliveryDate}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'ASAP',
                    value: 'false'
                  },
                  {
                    label: 'Select date',
                    value: 'true'
                  }
                ]}
              />
            </Col>
          )}

          {confirmDetail.specificDeliveryDate === 'true' && (
            <>
              <Col xs="12" md="6" className="qs-mt-md">
                <DatePicker
                  selected={selectedDeliveryDate}
                  onChange={onDeliveryDateChange}
                  useWeekdaysShort={true}
                  dateFormat="dd/MM/yyyy"
                  disabled={!installDates || installDates.length === 0}
                  includeDates={installDates}
                  placeholderText="dd/mm/yyyy"
                />
              </Col>
            </>
          )}
        </Row>
      )}

      {((productDetail.powerSourceTypes && productDetail.powerSourceTypes.length > 0) ||
        (productDetail.buildingHeights && productDetail.buildingHeights.length > 0) ||
        (productDetail.roofTypes && productDetail.roofTypes.length > 0) ||
        (productDetail.buildingExternalWallMaterials && productDetail.buildingExternalWallMaterials.length > 0)) && (
        <>
          <Row className="qs-more-info-table-header">
            <Col>Satellite installation premises</Col>
          </Row>

          <Row>
            {productDetail.powerSourceTypes && productDetail.powerSourceTypes.length > 0 && (
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="powerSourceType"
                    onChange={e => onChange(e, { powerSourceType: e.target.value })}
                    value={confirmDetail.powerSourceType}
                    validate={{
                      required: { value: true, errorMessage: 'Power source type is required' }
                    }}
                  >
                    <option value="">Select power source type</option>
                    {productDetail.powerSourceTypes.map((ref, i) => (
                      <option key={`powerSourceTypes-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            )}

            {productDetail.buildingHeights && productDetail.buildingHeights.length > 0 && (
              <>
                <Col xs="12" md="6">
                  <FormGroup>
                    <AvField
                      bsSize="sm"
                      type="select"
                      name="buildingHeight"
                      onChange={e => onChange(e, { buildingHeight: e.target.value })}
                      value={confirmDetail.buildingHeight}
                      validate={{
                        required: { value: true, errorMessage: 'Building height is required' }
                      }}
                    >
                      <option value="">Select building height</option>
                      {productDetail.buildingHeights.map((ref, i) => (
                        <option key={`buildingHeight-${ref.id}`} value={ref.id}>
                          {ref.name}
                        </option>
                      ))}
                    </AvField>
                  </FormGroup>
                </Col>
              </>
            )}

            {productDetail.roofTypes && productDetail.roofTypes.length > 0 && (
              <Col xs="12" md="6">
                <FormGroup>
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="roofType"
                    onChange={e => onChange(e, { roofType: e.target.value })}
                    value={confirmDetail.roofType}
                    validate={{
                      required: { value: true, errorMessage: 'Roof type is required' }
                    }}
                  >
                    <option value="">Select roof type</option>
                    {productDetail.roofTypes.map((ref, i) => (
                      <option key={`roofTypes-${ref.id}`} value={ref.id}>
                        {ref.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>
              </Col>
            )}

            {productDetail.buildingExternalWallMaterials && productDetail.buildingExternalWallMaterials.length > 0 && (
              <>
                <Col xs="12" md="6">
                  <FormGroup>
                    <AvField
                      bsSize="sm"
                      type="select"
                      name="buildingExternalWallMaterial"
                      onChange={e => onChange(e, { buildingExternalWallMaterial: e.target.value })}
                      value={confirmDetail.buildingExternalWallMaterial}
                      validate={{
                        required: { value: true, errorMessage: 'Building external wall material is required' }
                      }}
                    >
                      <option value="">Select building external wall material</option>
                      {productDetail.buildingExternalWallMaterials.map((ref, i) => (
                        <option key={`buildingExternalWallMaterials-${ref.id}`} value={ref.id}>
                          {ref.name}
                        </option>
                      ))}
                    </AvField>
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
        </>
      )}

      <Row className={`qs-more-info-table-header${showingAdditionalBillingAddress ? ' qs-mb-nlg' : ''}`}>
        <Col xs="12">
          <div style={{ paddingTop: '3px' }}>Send my bills to</div>
          <AvRadioGroup
            className="qs-form-radio"
            name="billingType"
            label=""
            errorMessage="Please select where to send your bills"
            required
            value={confirmDetail.billingType}
            onChange={onBillingTypeChange}
          >
            <AvRadio
              label={
                <>
                  My email address{' '}
                  {yourDetailsEmailAddress && (
                    <>
                      (<i>{yourDetailsEmailAddress}</i>){' '}
                    </>
                  )}
                  via my{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: organiserAgentName
                    }}
                  />{' '}
                  <Button className="sm-link-button" onClick={() => setOrganiserDialogOpen(true)}>
                    how it works
                  </Button>
                </>
              }
              value={billingTypes.EMAIL_ORGANISER.Code}
            />
            <AvRadio
              label={
                <>
                  My email address{' '}
                  {yourDetailsEmailAddress && (
                    <>
                      (<i>{yourDetailsEmailAddress}</i>)
                    </>
                  )}
                </>
              }
              value={billingTypes.EMAIL_PERSONAL.Code}
            />
            {allowPaperBill && (
              <>
                <AvRadio label={<>My connection address</>} value={billingTypes.ADDRESS_CONNECTION.Code} />
                {productDetail.allowPaperBillOtherAddress && (
                  <AvRadio
                    label={<>Other address (can't be a Post Box, Locked Bag or RMB)</>}
                    value={billingTypes.ADDRESS_OTHER.Code}
                  />
                )}
              </>
            )}
          </AvRadioGroup>
        </Col>
      </Row>

      {confirmDetail.billingType === billingTypes.ADDRESS_OTHER.Code && (
        <div className="sm-offer-billing-address">
          <AddressControl
            key="billingAddress"
            keySuffix="billing"
            labelText="Billing address (Other)"
            country={country}
            onAddressSelected={onBillingAddressSelected}
            autoPlaceholder="Property address..."
            autoPropertyAddress={null}
            autoAddressRequired={true}
            manualAddressVisible={true}
            manualSuburbSelectorEnabled={true}
            showAreasSupportedDisclaimer={false}
            large={!isMobile}
            showMinimalManualFields={true}
          />
        </div>
      )}

      {productDetail.allowDeliveryAddress && (
        <Row className={`qs-more-info-table-header${showingAdditionalDeliveryAddress ? ' qs-mb-nlg' : ''}`}>
          <Col xs="12">
            <div style={{ paddingTop: '3px' }}>Where would you like us to deliver your equipment?</div>
            <AvRadioGroup
              className="qs-form-radio"
              name="deliveryType"
              label=""
              required
              value={confirmDetail.deliveryType}
              onChange={onDeliveryTypeChange}
            >
              <AvRadio label={<>My connection address</>} value={billingTypes.ADDRESS_CONNECTION.Code} />
              {productDetail.allowDeliveryAddressOtherAddress && (
                <AvRadio
                  label={<>Other address (can't be a Post Box, Locked Bag or RMB)</>}
                  value={billingTypes.ADDRESS_OTHER.Code}
                />
              )}
            </AvRadioGroup>
          </Col>
        </Row>
      )}

      {confirmDetail.deliveryType === billingTypes.ADDRESS_OTHER.Code && (
        <>
          <div className="sm-offer-billing-address">
            <AddressControl
              key="deliveryAddress"
              keySuffix="delivery"
              labelText="Delivery address (Other)"
              country={country}
              onAddressSelected={onDeliveryAddressSelected}
              autoPlaceholder="Delivery address..."
              autoPropertyAddress={null}
              autoAddressRequired={true}
              manualAddressVisible={true}
              manualSuburbSelectorEnabled={true}
              showAreasSupportedDisclaimer={false}
              large={!isMobile}
              showMinimalManualFields={true}
            />
          </div>
        </>
      )}

      {productDetail && productDetail.billingContent && (
        <Row className="qs-mt-sm">
          <Col
            xs="12"
            dangerouslySetInnerHTML={{
              __html: productDetail.billingContent
            }}
          />
        </Row>
      )}

      <GeneralDialog
        dialogOpen={organiserDialogOpen}
        bodyClass="no-padding"
        showFooter={false}
        showCloseIcon={false}
        onClose={() => setOrganiserDialogOpen(false)}
      >
        <OrganiserInfo onClose={() => setOrganiserDialogOpen(false)} />
      </GeneralDialog>
    </>
  );
};

export default OfferConfirmInternetDetailsAu;
