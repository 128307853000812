import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function directSwitchReducer(state = initialState.directSwitch, action) {
  switch (action.type) {
    case types.SET_DIRECT_SWICTH:
      return objectAssign({}, state, action.data);

    default:
      return state;
  }
}
