import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import { Helmet } from 'react-helmet';
import { Button } from 'reactstrap';
import { getServiceCards } from '../actions/serviceCardActions';
import { setUserBillSuccess, getComparisonWithToken, getComparisonWithKey, goHome } from '../actions/comparisonActions';
import { sessionExpiredServerMessage } from '../actions/serverMessageActions';
import * as serverMessageTargets from '../actions/serverMessageTargets';
import Dashboard from '../components/dashboard';
import { useAuth0 } from '../auth/auth0-Provider.js';
import IdleTimerDialog from '../components/dialogs/idleTimerDialog';
import IdleOfferTimeout from '../components/common/idleOfferTimeout';
import ShowFeatureCheckbox from '../components/common/showFeatureCheckbox';
import TourGuide from '../components/common/tourGuide';
import CustomerCommissionLogo from '../components/common/customerCommissionLogo';
import { submissionTypes } from '../enums/submissionTypes';
import { getAgentServiceType } from '../enums/customerCommissionTypes';
import { serviceCardLoadingTypes } from '../enums/serviceCardLoadingTypes';
import { uploadBillTypes, uploadSubTypes } from '../enums/uploadBillTypes';
import { agentOperatingModes, pageModes } from '../enums/launchTypes';
import { billTypes } from '../enums/billTypes';
import { navigationTypes } from '../enums/navigationTypes';

import { getServiceCardData, getSubmission } from '../components/serviceCard/serviceCardUtilities';
import { userPreferenceTypes, getUserPreferences } from '../utilities/userPreferencesUtilities';
import { standardEvents } from '../utilities/googleAnalyticsEvents';
import { isFramed } from '../utilities/domUtilities';

import { ReactComponent as PartyIcon } from '../components/icons/util/party.svg';
import { ReactComponent as MailIcon } from '../components/icons/util/mail.svg';
import { openSubmitDialog } from '../actions/dialogOptionsActions';

const DashboardScreen = props => {
  const { googleAnalyticsClientCode, tourGuideEnabled } = useSelector(state => state.config.settings);
  const { loaded: userBillsLoaded, data: userBills } = useSelector(state => state.userBills);
  const { billTypes: agentBillTypes, isLoaded: configLoaded } = useSelector(state => state.config);
  const { event: navigationEvent } = useSelector(state => state.globalOptions.navigation);
  const { pageMode } = useSelector(state => state.application);
  const { operatingMode } = useSelector(state => state.config.settings);

  const [idleTimer, setIdleTimer] = useState(false);
  const [summaries, setSummaries] = useState([]);
  const [services, setServices] = useState([]);
  const [totalSavings, setTotalSavings] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [requestStartTour, setRequestStartTour] = useState(false);
  const [tourStarted, setTourStarted] = useState();
  const [tourClosed, setTourClosed] = useState();
  const [steps, setSteps] = useState([]);

  const { loading: auth0Loading, isAuthenticated, isUserAuthenticated, getAccessToken } = useAuth0();

  const dispatch = useDispatch();

  const pageModeDefault = pageMode === pageModes.DEFAULT;
  const agentMode = agentOperatingModes.getById(operatingMode);

  const getServiceBarLeadText = (isEmpty, reductionAmountAnnualTotal, hasNextCompareDate, hasEstimates) => {
    if (isEmpty) return { text: 'Add' };
    if (reductionAmountAnnualTotal > 0) return {}; //{ text: 'Annual saving', disclaimerSymbol: '†' };
    if (hasNextCompareDate) return { text: 'View' };
    if (hasEstimates) return { text: 'View' };
    return { text: 'View' };
  };

  const onTimeoutClosed = () => {
    if (userBillsLoaded) {
      userBills
        .filter(e => e.submissionId && e.reductionLoaded && !e.deletedDateUtc)
        .forEach(e => dispatch(setUserBillSuccess({ ...e, reductionLoaded: false })));
    }
  };

  const onTimeoutIdle = () => {
    closeTour();
  };

  const getStarted = (serviceType, submissionId, submission) => {
    closeTour();
    standardEvents('Service-Card', serviceType.Name, 'Get-Started', googleAnalyticsClientCode);
    dispatch(openSubmitDialog(serviceType, uploadBillTypes.NONE, uploadSubTypes.NONE, { submissionId, submission }));
  };

  const getComparison = (serviceType, submissionId, userBill) => {
    closeTour();
    standardEvents('Service-Card', serviceType.Name, 'Get-Comparison', googleAnalyticsClientCode);

    const serviceCardLoading = userBillsLoaded ? serviceCardLoadingTypes.NONE : serviceCardLoadingTypes.DEFAULT;

    const accessKey = userBill.accessKey;
    const submissionType = userBill.submissionType;

    if (accessKey) {
      dispatch(
        getComparisonWithKey(
          { key: accessKey.accessKey, id: accessKey.accessId },
          null,
          submissionType,
          serviceType,
          serviceCardLoading
        )
      );
    } else if (isUserAuthenticated()) {
      const fn = async () => {
        dispatch(getComparisonWithToken(await getAccessToken(), submissionId, submissionType, serviceType));
      };
      fn();
    } else {
      dispatch(sessionExpiredServerMessage(serverMessageTargets.DASHBOARD));
    }
  };

  useEffect(() => {
    const locationSearch = qs.parse(props.location.search);

    const start = locationSearch.start;
    if (start && userBillsLoaded) {
      // See if there are any comparisons that a customer has created ie. they should now make a selection not trigger a start process
      var billOrComparison = userBills.find(
        s => s.status !== 0 && !s.deletedDateUtc && (s.bill || (s.estimate && !s.estimate.defaultComparison))
      );

      if (!billOrComparison) {
        // If there are only default comparisons then launch them into the process of comparisons
        var defaultComparisons = userBills.filter(
          s => s.status !== 0 && !s.deletedDateUtc && s.estimate && s.estimate.defaultComparison
        );

        if (defaultComparisons) {
          var firstDefaultComparison = defaultComparisons.sort(function(a, b) {
            const bt1 = billTypes.getById(a.billTypeId).SortOrder;
            const bt2 = billTypes.getById(b.billTypeId).SortOrder;

            if (bt1 === bt2) {
              return a.displayAddress && b.displayAddress ? a.displayAddress.localeCompare(b.displayAddress) : false;
            } else {
              return bt1 < bt2 ? -1 : 1;
            }
          })[0];

          if (firstDefaultComparison) {
            var firstDefaultSubmission = getSubmission(firstDefaultComparison);
            var firstDefaultServiceType = billTypes.getById(firstDefaultComparison.billTypeId);

            closeTour();

            dispatch(
              openSubmitDialog(firstDefaultServiceType, uploadBillTypes.MANUAL, uploadSubTypes.NONE, {
                submissionId: firstDefaultComparison.submissionId,
                submission: firstDefaultSubmission
              })
            );
          }
        }
      }
    }
  }, [userBillsLoaded]);

  useEffect(() => {
    const completeCards = userBills
      .filter(s => s.status !== 0 && !s.deletedDateUtc)
      .sort(function(a, b) {
        const st1 = a.submissionType === submissionTypes.BILL ? 0 : 1;
        const st2 = b.submissionType === submissionTypes.BILL ? 0 : 1;
        const bt1 = billTypes.getById(a.billTypeId).SortOrder * 10;
        const bt2 = billTypes.getById(b.billTypeId).SortOrder * 10;

        const itemA = st1 + bt1;
        const itemB = st2 + bt2;

        if (itemA === itemB) {
          return a.displayAddress && b.displayAddress ? a.displayAddress.localeCompare(b.displayAddress) : false;
        } else {
          return itemA < itemB ? -1 : 1;
        }
      });

    const emptyCards = userBills
      .filter(bill => bill.status === 0 && !completeCards.some(e => e.billTypeId === bill.billTypeId && e.status !== 0))
      .sort(function(a, b) {
        const bt1 = billTypes.getById(a.billTypeId).SortOrder;
        const bt2 = billTypes.getById(b.billTypeId).SortOrder;

        return bt1 - bt2;
      });

    let latestBills = [];

    const _summaries = [];
    const _services = [];

    let _totalSavings = 0;
    let _totalCommission = 0;

    setIdleTimer(userBillsLoaded && completeCards.length > 0);

    if (userBillsLoaded) {
      completeCards.forEach(a => {
        const isBill = a.submissionType === submissionTypes.BILL;
        const isEstimate = a.submissionType === submissionTypes.ESTIMATE;

        // For each meter, only show a card for the latest bill.
        if (isBill) {
          const index = latestBills.findIndex(e => e.lastBillMeterId === a.lastBillMeterId);

          if (index === -1) {
            latestBills.push(a);
          } else if (Date.parse(a.lastBillIssueDate) > Date.parse(latestBills[index].lastBillIssueDate)) {
            latestBills.splice(index, 1);
            latestBills.push({ ...a, key: `serviceCard_${a.submissionId}` });
          }
        }

        // Set to true if we should only show a card for the latest estimate per service type; otherwise display all estimate cards.
        const limitLatest = false;

        if (isEstimate) {
          if (limitLatest) {
            const index = latestBills.findIndex(
              e => e.billTypeId === a.billTypeId && e.submissionType === submissionTypes.ESTIMATE
            );

            if (index === -1) {
              latestBills.push(a);
            } else {
              latestBills.splice(index, 1);
              latestBills.push({ ...a, key: `serviceCard_${a.billTypeId}` });
            }
          } else {
            latestBills.push({ ...a, key: `serviceCard_${a.submissionId}` });
          }
        }
      });

      // Add empty cards last.
      latestBills = [...latestBills, ...emptyCards];

      latestBills.forEach(e => {
        const isEmpty = e.status === 0;
        const serviceType = billTypes.getById(e.billTypeId);
        const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
        const serviceCardData = getServiceCardData(e, agentServiceType);

        _services.push(serviceCardData);

        // Add status summaries.
        if (isEmpty) {
          _summaries.push({
            id: `serviceBarButton_${e.billTypeId}`,
            serviceType,
            isEmpty
          });
        } else {
          const existing = _summaries.find(s => s.serviceType.Id === serviceType.Id);

          const current = existing
            ? existing
            : {
                id: `serviceSummary_${e.billTypeId}`,
                serviceType,
                isEmpty
              };

          if (existing === undefined) {
            _summaries.push(current);
          }
        }
      });

      _totalSavings = _services.reduce((previous, current) => {
        return (
          previous + (current.reductionLoaded && current.reductionAmountAnnual > 0 ? current.reductionAmountAnnual : 0)
        );
      }, 0);

      _totalCommission = _services.reduce((previous, current) => {
        if (current.reductionAmountAnnual <= 0) return previous;

        const agentServiceType = getAgentServiceType(current.serviceType, agentBillTypes);

        if (!agentServiceType.hasCommission) return previous;

        return previous + agentServiceType.agentBillType.customerCommission;
      }, 0);

      // Apply status summary content
      _summaries.forEach(e => {
        const serviceCards = _services.filter(s => s.serviceType === e.serviceType);

        const notificationTotal = serviceCards.reduce((previous, current) => {
          return previous + current.notificationCount;
        }, 0);

        const reductionAmountAnnualTotal = serviceCards.reduce((previous, current) => {
          return previous + current.reductionAmountAnnual;
        }, 0);

        const hasNextCompareDate = serviceCards.every(item => item.nextCompareDate);

        const hasEstimates = serviceCards.some(item => item.isEstimate);

        e.notificationCount = notificationTotal;
        e.reductionAmountAnnual = reductionAmountAnnualTotal;
        e.hasNextCompareDate = hasNextCompareDate;

        e.leadText = getServiceBarLeadText(e.isEmpty, reductionAmountAnnualTotal, hasNextCompareDate, hasEstimates);
      });

      // Include "Add another service" card if we have at least one completed card.
      if (completeCards.length > 0) {
        const serviceType = { ...billTypes.NONE, Name: 'Add another service' };

        _services.push({
          id: 'service-card-add',
          serviceType,
          isAdd: true,
          leadText: { text: 'Need to add another service?' },
          tagline: 'Add a new card for each separate property address, car or loan.',
          buttonText: 'Get Organised'
        });
      }
    }

    // ------------------------------------------------------------------------
    // Guided tour
    // ------------------------------------------------------------------------
    if (tourGuideEnabled) {
      const _steps = [];

      const savingsCards = completeCards.filter(e => e.reductionAmountAnnual > 0);
      const noSavingsCards = completeCards.filter(e => e.reductionAmountAnnual === 0);
      const content =
        _totalSavings > 0 ? (
          <>
            <div className="heading">Savings found</div>
            <p>Ready to improve your household's financial health?</p>
            <p>It takes just a few minutes...</p>
          </>
        ) : (
          <>
            <div className="heading">Your total savings</div>
            <p>This is where you'll find the total savings we've identified from all of your bills.</p>
          </>
        );

      if (_totalSavings > 0) {
        _steps.push({
          selector: `#banner_savings_value`,
          content: (
            <div className="content">
              <div className="show-tour">
                <ShowFeatureCheckbox label="Show tips" featureType={userPreferenceTypes.TOURS} />
              </div>
              {content}
            </div>
          )
        });
      }

      savingsCards.forEach(e => {
        const serviceType = billTypes.getById(e.billTypeId);
        const submission = getSubmission(e);
        const buttonText = submission ? (submission.defaultComparison ? 'Secure Savings' : 'Review & Switch') : '';
        const annualAmount = e.reductionAmountAnnual;
        let text;

        if (annualAmount > 1000)
          text = (
            <>
              <p>These savings are simply MEGA!</p>
              <p>Securing this saving will make a huge difference to your financial health.</p>
            </>
          );
        else if (annualAmount > 500) text = <p>Securing this saving will certainly improve your financial health!</p>;
        else text = <p>Every saving improves your financial health so let's secure this one.</p>;

        const action = submission.defaultComparison
          ? () => getStarted(serviceType, e.submissionId, submission)
          : () => getComparison(serviceType, e.submissionId, e);

        _steps.push({
          selector: `#serviceCard_${e.submissionId}`,
          content: (
            <>
              <div className="content hasLogo">
                <div className="icon-wrap">
                  <PartyIcon />
                </div>
                <p>{text}</p>
              </div>
              <div className="button-container">
                <Button className={`sm-button-primary medium`} onClick={action}>
                  {buttonText}
                </Button>
              </div>
            </>
          )
        });
      });

      noSavingsCards.forEach(e => {
        const serviceType = billTypes.getById(e.billTypeId);
        const submission = getSubmission(e);

        if (submission && !submission.defaultComparison) return;

        const action = () => getStarted(serviceType, e.submissionId, submission);

        let icon;
        let heading;
        let text;
        let buttonText;

        switch (serviceType) {
          case billTypes.HOME_LOAN:
            text = (
              <>
                <p>Confirm the value of your property and your basic loan details.</p>
                <p>We'll instantly check today's market rates and confirm possible savings.</p>
              </>
            );

            heading = "Are you on today's best rates?";
            buttonText = 'Confirm value';
            icon = null;
            break;

          default:
            icon = (
              <div className="icon-wrap">
                <MailIcon />
              </div>
            );

            text = (
              <>
                <p>This is the best plan for an average size home in your area.</p>
                <p>To find the best plan for your home's actual usage, upload your bill now.</p>
              </>
            );

            heading = "Let's get accurate";
            buttonText = `Let's do it`;
        }

        _steps.push({
          selector: `#serviceCard_${e.submissionId}`,
          content: (
            <>
              <div className="content">
                {icon}
                <div className="heading">{heading}</div>
                {text}
              </div>
              <div className="button-container">
                <Button className={`sm-button-primary medium`} onClick={action}>
                  {buttonText}
                </Button>
              </div>
            </>
          )
        });
      });

      if (emptyCards.length > 0) {
        emptyCards.forEach((e, index) => {
          const serviceType = billTypes.getById(e.billTypeId);
          const serviceTypeName = e.billType.toLowerCase();

          if (serviceType.ComparisonImplemented) {
            const allCardsEmpty = savingsCards.length === 0 && noSavingsCards.length === 0;
            let text;
            let buttonText;

            switch (serviceType) {
              case billTypes.HOME_LOAN:
                text = (
                  <>
                    <p>Now, simply confirm the value of your property and your basic loan details.</p>
                    <p>We'll show you your potential saving in less than a minute.</p>
                  </>
                );

                buttonText = 'Confirm value';

                break;

              default:
                text =
                  allCardsEmpty && index === 0 ? (
                    <>
                      <p>Let's start by trying to reduce your {serviceTypeName} bill.</p>
                      <p>This step takes about 90 seconds.</p>
                    </>
                  ) : (
                    <>
                      <p>OK, let's try to reduce your {serviceTypeName} bill.</p>
                      <p>This step takes about 70 seconds</p>
                    </>
                  );

                buttonText = `Let's do it`;
            }

            const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);

            const commissionLogo = agentServiceType.hasCommission ? (
              <CustomerCommissionLogo serviceType={serviceType} />
            ) : null;

            _steps.push({
              selector: `#serviceCard_${e.billTypeId}`,
              content: (
                <>
                  <div className={`content ${commissionLogo ? 'hasLogo' : ''}`}>
                    {commissionLogo}
                    {text}
                  </div>
                  <div className="button-container">
                    <Button className={`sm-button-primary medium`} onClick={() => getStarted(serviceType)}>
                      {buttonText}
                    </Button>
                  </div>
                </>
              )
            });
          }
        });
      }

      setSteps(_steps);
    }

    if (!userBillsLoaded || completeCards.length === 0) {
      _totalSavings = null; // All cards empty, set savings total to null so correct banner image is used.
    }

    setTotalSavings(_totalSavings);
    setTotalCommission(_totalCommission);
    setServices(_services);
    setSummaries(_summaries);
  }, [userBills]);

  useEffect(() => {
    const fn = async () => {
      if (!userBillsLoaded && !auth0Loading) {
        isUserAuthenticated() ? dispatch(getServiceCards(await getAccessToken())) : dispatch(getServiceCards());
      }
    };
    fn();
  }, [isAuthenticated, auth0Loading]);

  // --------------------------------------------------------------------------
  // Guided tour
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (navigationEvent === navigationTypes.TOUR) {
      startTour(true);
    }
  }, [navigationEvent]);

  // --------------------------------------------------------------------------
  // Page Initialization
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (configLoaded) {
      if (agentMode === agentOperatingModes.INACTIVE) {
        dispatch(goHome());
      }
    }
  }, [agentMode, configLoaded, dispatch]);

  const startTour = forceStart => {
    const userPreferences = getUserPreferences();
    const showTour =
      tourGuideEnabled &&
      pageMode === pageModes.DEFAULT &&
      (userPreferences[userPreferenceTypes.TOURS.code] === true || forceStart);

    if (showTour) {
      // Requesting to start will start the tour once all ajax spinner calls have completed.
      // Start after 1 second to ensure the get started dialog has closed
      setTimeout(
        () => {
          setRequestStartTour(Date.now());
          setTourStarted(Date.now());
        },
        forceStart ? 0 : 500
      );
    }
  };

  const closeTour = () => {
    setRequestStartTour(false);
    setTourClosed(Date.now());
  };

  return (
    pageModeDefault &&
    !isFramed() &&
    configLoaded && (
      <>
        <Helmet>
          <title>Home</title>
        </Helmet>
        {idleTimer && (
          <IdleTimerDialog
            timeout={3600000}
            closeButtonText="Start again"
            onClose={onTimeoutClosed}
            onIdle={onTimeoutIdle}
          >
            <IdleOfferTimeout message="Your session has expired. As plans can change and you have been idle for more than 1 hour, we need to refresh your dashboard." />
          </IdleTimerDialog>
        )}

        <TourGuide tourId="home" steps={steps} requestStart={requestStartTour} requestClose={closeTour} />

        <Dashboard
          serviceSummaries={summaries}
          serviceCards={services}
          totalSavings={totalSavings}
          totalCommission={totalCommission}
          userBillsLoaded={userBillsLoaded}
          onTourStarted={tourStarted}
          onTourClosed={tourClosed}
        />
      </>
    )
  );
};

export default DashboardScreen;
