import initialState from '../reducers/initialState';
import * as types from './actions';

export function setDirectSwitchSuccess(directSwitch) {
  return { type: types.SET_DIRECT_SWICTH, data: { ...directSwitch } };
}

export function setDirectSwitch(directSwitch) {
  return dispatch => {
    dispatch(setDirectSwitchSuccess(directSwitch));
  };
}

export function resetDirectSwitch() {
  return dispatch => {
    dispatch(setDirectSwitchSuccess(initialState.directSwitch));
  };
}
