import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Carousel, CarouselItem, Button } from 'reactstrap';

import ServiceCardStack from '../samples/serviceCardStack';
import OrganiserName from '../common/organiserName';

const QuickTipsOrganiser = ({ onClose, onInteraction, providerDisplayCode, providerLogoUrl }) => {
  const { organiserAgentName } = useSelector(state => state.content.general);
  const { autoCheckEmailDomain } = useSelector(state => state.config.applicationSettings);
  const { emailAddress, autoCheckEmailAddress } = useSelector(state => state.user);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  const [activeIndex, setActiveIndex] = useState(0);

  const description1 = `${providerDisplayCode} will send all email correspondence to a new ${organiserAgentName} email address that we create when you submit this application.`;
  const description2 = `Your e-bills will be automatically added to your ${organiserAgentName}.`;
  const description3 = `All ${providerDisplayCode} emails will be instantly relayed to your personal email address:`;

  const emailAddressDisplay = emailAddress || 'j.smith@gmail.com';
  const autocheckEmailAddressDisplay = autoCheckEmailAddress || `...@${autoCheckEmailDomain}`;

  const OrganiserFrame = ({ heading, image, description, children }) => {
    return (
      <div className="organiser-frame">
        <div className="frame-heading">{heading}</div>
        {image && <div className="frame-image">{image}</div>}
        {description && (
          <div
            className="frame-description"
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        )}
        {children}
      </div>
    );
  };

  const providerLogo = <img src={providerLogoUrl} alt={providerDisplayCode} />;

  const items = [
    {
      index: 0,
      content: (
        <OrganiserFrame
          heading={
            <span>
              How your <OrganiserName nameOnly /> works
            </span>
          }
        >
          <>
            <p>
              When you choose to have your bills linked to your <OrganiserName />, they'll automatically appear in your{' '}
              <OrganiserName nameOnly /> dashboard each time they're issued by {providerDisplayCode}.
            </p>

            <p>
              After 12 months, your <OrganiserName nameOnly /> will begin to automatically check your bills against the
              latest market rates whenever they're issued.
            </p>
            <p>
              You'll be automatically informed when a cheaper deal is available, based on your bill's actual energy
              usage and rates.
            </p>
          </>
        </OrganiserFrame>
      )
    },
    {
      index: 1,
      content: (
        <OrganiserFrame heading="Step 1 of 3" image={providerLogo} description={description1}>
          <div className="email-addres-display">{autocheckEmailAddressDisplay}</div>
        </OrganiserFrame>
      )
    },
    {
      index: 2,
      content: (
        <OrganiserFrame heading="Step 2 of 3">
          <div className="service-card-stacked">
            <ServiceCardStack cardSize="small" />

            <div
              className="frame-description"
              dangerouslySetInnerHTML={{
                __html: description2
              }}
            />
          </div>
        </OrganiserFrame>
      )
    },
    {
      index: 3,
      content: (
        <OrganiserFrame
          heading="Step 3 of 3"
          image={<img src={`${staticContentUrl}/images/misc/bill-envelope.png`} alt="bill-envelope" />}
          description={description3}
        >
          <div className="email-addres-display">{emailAddressDisplay}</div>
        </OrganiserFrame>
      )
    },
    {
      index: 4,
      content: (
        <OrganiserFrame heading="How your emails work">
          <p>
            There's nothing more you need to do. Your bills and all other correspondence from {providerDisplayCode} will
            be instantly relayed from your <OrganiserName nameOnly /> to your personal email address. Any emails you
            respond to from your personal email address will also be relayed back to {providerDisplayCode}.
          </p>
          <p>
            Importantly, when communicating with {providerDisplayCode}, make sure you use your new{' '}
            {autocheckEmailAddressDisplay} email address. We'll confirm that address as soon as you submit your
            application.
          </p>
        </OrganiserFrame>
      )
    }
  ];

  const firstSlide = activeIndex === 0;
  const finalSlide = activeIndex === items.length - 1;

  const onNext = () => {
    const nextIndex = activeIndex === items.length - 1 ? activeIndex : activeIndex + 1;
    setActiveIndex(nextIndex);
    onInteraction(true);
  };

  const onPrevious = () => {
    const nextIndex = activeIndex === 0 ? 0 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = items
    .sort((a, b) => {
      return a.index - b.index;
    })
    .map((item, index) => {
      return (
        <CarouselItem key={`c-item-${index}`}>
          <div className="content organiser-tip">{item.content}</div>
        </CarouselItem>
      );
    });

  const previous = onPrevious;

  return (
    <div className="sm-quick-tips-component">
      <Carousel interval={false} activeIndex={activeIndex} next={onNext} previous={previous}>
        {slides}
      </Carousel>
      <div className="footer">
        <div>
          <Button
            className="sm-button-primary medium wide right-arrow-small heavy"
            onClick={finalSlide ? onClose : onNext}
          >
            {firstSlide ? 'More detail' : finalSlide ? 'Close' : 'Next'}
          </Button>
        </div>

        {activeIndex > 0 ? (
          <div>
            <Button className="sm-link-button" onClick={previous}>
              Back
            </Button>
          </div>
        ) : (
          <div className="spacer" />
        )}
      </div>
    </div>
  );
};

export default QuickTipsOrganiser;
