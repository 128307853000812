import React from 'react';
import { useDispatch } from 'react-redux';

import { setServerMessageClear } from '../../actions/serverMessageActions';

const ServerMessage = ({ serverMessage, target, paddingLevel, showCloseIcon, onClose }) => {
  const dispatch = useDispatch();

  const showClose = showCloseIcon === undefined || showCloseIcon === true;
  const hasMessage =
    serverMessage &&
    serverMessage.messages &&
    serverMessage.messages.length > 0 &&
    serverMessage.code &&
    ((!serverMessage.target && !target) || serverMessage.target === target);

  const alertClass = `sm-server-message${paddingLevel ? ` ${paddingLevel.tag}-padding` : ''} alert ${
    serverMessage.code >= 200 && serverMessage.code <= 299 ? 'alert-success' : 'alert-danger'
  }`;

  const clearMessage = () => {
    dispatch(setServerMessageClear(target));
    if (onClose) onClose();
  };

  return hasMessage ? (
    <div name={`server-message${target ? `-${target}` : ''}`} className={alertClass} role="alert">
      {serverMessage.messages.length === 1 ? (
        serverMessage.messages[0].isHtml ? (
          <div
            className="server-message-text"
            dangerouslySetInnerHTML={{ __html: serverMessage.messages[0].message }}
          />
        ) : (
          <div className="server-message-text">{serverMessage.messages[0].message}</div>
        )
      ) : (
        <ul>
          {serverMessage.messages.map((message, i) =>
            message.isHtml ? (
              <li key={`server-message-${i}`} dangerouslySetInnerHTML={{ __html: message.message }} />
            ) : (
              <li key={`server-message-${i}`}>{message.message}</li>
            )
          )}
        </ul>
      )}
      {showClose && (
        <div>
          <span onClick={clearMessage} className="close-button">
            ×
          </span>
        </div>
      )}
    </div>
  ) : null;
};

export default ServerMessage;
