import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { howItWorksDialogOptions } from '../../actions/dialogOptionsActions';

const HeroBanner = () => {
  const dispatch = useDispatch();

  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { homeHero } = useSelector(state => state.content.images);
  const { heroBannerDescription } = useSelector(state => state.content.home);

  return (
    <div className="sm-hero-banner">
      <div className="image-wrap">
        <img src={`${staticContentUrl}/${homeHero}`} alt="hero" />
      </div>
      <div>
        <div className="footer">
          <div className="description">{heroBannerDescription}</div>
          <div className="dialog-button">
            <Button onClick={() => dispatch(howItWorksDialogOptions(true))} className="sm-button-primary medium">
              See how it works
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroBanner;
