import React, { useState, useCallback } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { scroller } from 'react-scroll';
import { offerEvents } from '../../utilities/googleAnalyticsEvents';
import { getElementOffset } from '../../utilities/viewPortUtilities';
import _debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { buttonTypes } from '../../enums/buttonTypes';

const InfoPopover = ({ idType, heading, body, offer, action, bodyIsHtml, additionalClass, buttonType, buttonText }) => {
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);

  const [infoPopoverOpen, setInfoPopoverOpen] = useState(false);

  const id = `${idType}${offer ? `-${offer.id}` : ''}`;

  const setInfoPopover = useCallback(
    _debounce(
      value => {
        if (value && window.innerWidth < 576) {
          adjustScrollPositionForPopover(id);

          setTimeout(() => setInfoPopoverOpen(value), 1000);
        } else setInfoPopoverOpen(value);

        trackPopover(value);
      },
      500,
      { leading: true }
    ),
    []
  );

  const trackPopover = newState => {
    // Only track popover opens
    if (newState && offer) offerEvents(action, offer, googleAnalyticsClientCode, newState);
  };

  const adjustScrollPositionForPopover = element => {
    if (getElementOffset(element).top + window.outerHeight + 25 < document.body.clientHeight) {
      scroller.scrollTo(element, {
        duration: 1200,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -25
      });
    } else {
      scroller.scrollTo(element, {
        duration: 1200,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: 0 - window.outerHeight + 45
      });
    }
  };

  const getButton = () => {
    switch (buttonType) {
      case buttonTypes.LINK:
        return (
          <div id={`${id}`} onClick={() => setInfoPopover(!infoPopoverOpen)} className="sm-info-popup link">
            {buttonText}
          </div>
        );

      default:
        return (
          <i
            id={`${id}`}
            className={`material-icons qs-info animated heartBeat delay-1s ${additionalClass}`}
            onClick={() => setInfoPopover(!infoPopoverOpen)}
          >
            info
          </i>
        );
    }
  };

  return (
    <>
      {getButton()}
      <Popover
        placement="auto"
        modifiers={{ flip: { behavior: ['auto'] } }}
        isOpen={infoPopoverOpen}
        target={`${id}`}
        onClick={() => setInfoPopover(false)}
      >
        <PopoverHeader>
          {heading} <i className="material-icons qs-close">clear</i>
        </PopoverHeader>
        <PopoverBody>
          {bodyIsHtml ? (
            <div
              dangerouslySetInnerHTML={{
                __html: body
              }}
            />
          ) : (
            body
          )}
        </PopoverBody>
      </Popover>
    </>
  );
};

export default InfoPopover;
