import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { directEnergySwitch, HandleDirectSwitchError } from '../../actions/energyEstimateActions';
import { AvForm } from 'availity-reactstrap-validation';
import QsButtonGroup from '../common/qsButtonGroup';
import { Row, Col, Button, Collapse } from 'reactstrap';
import moment from 'moment';
import AddressControl from '../common/addressControl';
import SubmitCommon from '../common/submitCommon';
import EnergyDistributor from '../common/energyDistributor';
import ExitLaunch from '../common/exitLaunch';
import PoweredBySmartMe from '../common/poweredBySmartMe';
import ServicesAuto from '../serviceCard/servicesAuto';

import { billTypes } from '../../enums/billTypes';
import { implementationCodes } from '../../enums/implementationCodes';

import { occupancyTypes, usageTypes, solarTypes, directSwitchTypes } from '../../enums/submissionTypes';

import * as serverMessageTargets from '../../actions/serverMessageTargets';
import {
  displayErrorMessage,
  displayInfoMessage,
  setServerMessageClear,
  displayNoOffersMessage
} from '../../actions/serverMessageActions';
import ServerMessage from '../common/serverMessage';

import 'react-google-places-autocomplete/dist/index.min.css';

import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { hasValidAddress } from '../../utilities/addressUtilities';
import { getSessionActivity } from '../../utilities/commonUtilities';

import { anonymousModes } from '../../enums/authenticationTypes';
import { launchDialogModes } from '../../enums/launchTypes';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes';
import { uploadSubTypes } from '../../enums/uploadBillTypes';

import { directSwitchDialogOptions } from '../../actions/dialogOptionsActions';
import { addSessionActivity } from '../../actions/sessionActivityActions';
import { getDistributorDefaults, getDistributorItem, getRegionDistributors } from '../../actions/distributorActions';

import OccupancyDate from '../common/occupancyDate';
import SubmitDialogBanner from './submitDialogBanner';
import BrandScroller from '../common/brandScroller';

const DirectSwitchAu = ({ launchMode, onClose }) => {
  const dispatch = useDispatch();

  const { serverMessage, selectedAgent, session } = useSelector(state => state);
  const { country, hasAgents, billTypes: agentBillTypes } = useSelector(state => state.config);
  const { googleAnalyticsClientCode, anonymousMode, showPoweredBy } = useSelector(state => state.config.settings);
  const { alpha3 } = useSelector(state => state.config.country);

  const {
    directSwitchType,
    serviceType,
    providerName: launchProviderName, // for directSwitchTypes.PROVIDER, this gets re-applied on the server from providerCode
    providerLogo,
    occupancyPurpose,
    product
  } = useSelector(state => state.directSwitch);

  const sessionActivity = getSessionActivity(session);
  const providerName =
    directSwitchType === directSwitchTypes.PROVIDER ? launchProviderName : product ? product.providerName : null;

  const defaultMode = launchMode === launchDialogModes.DEFAULT;
  const serviceTypeOptions = { includeExternal: false };
  const hasInternet = billTypes.hasServiceType(agentBillTypes, billTypes.INTERNET, serviceTypeOptions);
  const hasHomeLoan = billTypes.hasServiceType(agentBillTypes, billTypes.HOME_LOAN, serviceTypeOptions);

  const { DIRECT_SWITCH_DIALOG: target } = serverMessageTargets;

  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(true);

  const [distributorSets, setDistributorSets] = useState({});
  const [distributorSetsLoaded, setDistributorSetsLoaded] = useState(false);
  const [selectedDistributorPrimary, setSelectedDistributorPrimary] = useState();
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedOccupancyType, setSelectedOccupancyType] = useState(occupancyTypes.CURRENT.Id);
  const [selectedOccupancyDateUncertain, setSelectedOccupancyDateUncertain] = useState(false);
  const [selectedOccupancyDate, setSelectedOccupancyDate] = useState(null);

  const [selectedSolarType, setSelectedSolarType] = useState(solarTypes.NONE.Id);
  const [occupancyDates, setOccupancyDates] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submissionCommon, setSubmissionCommon] = useState({});

  const installDates = occupancyDates && occupancyDates.map(date => moment.tz(date, 'YYYY-MM-DD', 'GMT').toDate());
  const implementationCode = implementationCodes.get(alpha3, serviceType.Id);
  const showSolar = implementationCode === implementationCodes.ELECTRICITY.AU_DEFAULT;
  const showAgents = !selectedAgent.isLoaded && hasAgents;
  const showEmailAddress = anonymousMode !== anonymousModes.FIXED;

  const hasSelectedAddress = hasValidAddress(selectedAddress);
  const serviceTypeName = serviceType.Name.toLowerCase();

  // --------------------------------------------------------------------------
  // Add session activity.
  // --------------------------------------------------------------------------
  const recordSessionActivity = (comparisonResult, userBill) => {
    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          submissionType: 'Direct Switch',
          submissionSubType: 'Launch',
          submissionId: userBill.submissionId,
          serviceType: serviceType.Code,
          hasOffers: comparisonResult.hasOffers
        },
        activityType: sessionActivityTypes.COMPARISON_COMPLETED
      })
    );
  };

  // --------------------------------------------------------------------------
  // Submit new direct switch estimate.
  // --------------------------------------------------------------------------
  const onSubmitEstimate = () => {
    const energyEstimate = getEnergyEstimate(serviceType.Code);
    const autoEstimatesArgs = { agentBillTypes, googleAnalyticsClientCode };

    dispatch(
      directEnergySwitch(serviceType, energyEstimate, autoEstimatesArgs, onComplete, error => {
        dispatch(
          displayErrorMessage(target, 'We cannot not process your request at this time. Please try again later.')
        );
        dispatch(HandleDirectSwitchError(energyEstimate, error));
      })
    );

    standardEvents('direct-switch-dialog', 'Click', 'Confirm', googleAnalyticsClientCode);
  };

  // --------------------------------------------------------------------------
  // Direct swtch estimate complete.
  // --------------------------------------------------------------------------
  const onComplete = (comparisonResult, userBill) => {
    recordSessionActivity(comparisonResult, userBill);

    if (comparisonResult.hasOffers) {
      dispatch(directSwitchDialogOptions(false, uploadSubTypes.NONE));
      dispatch(push('/compare'));
    } else {
      dispatch(displayNoOffersMessage(target));
    }
  };

  // --------------------------------------------------------------------------
  // Get energy estimate model.
  // --------------------------------------------------------------------------
  const getEnergyEstimate = serviceTypeCode => {
    return {
      property: {
        address: { ...selectedAddress },
        occupancyDate: selectedOccupancyDate ? moment(selectedOccupancyDate).format('YYYY-MM-DD') : null,
        occupancyType: selectedOccupancyType,
        occupancyPurpose,
        occupancyDateUncertain: selectedOccupancyDateUncertain
      },
      ...submissionCommon,
      sessionActivity,
      agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
      distributorCode: selectedDistributorPrimary ? selectedDistributorPrimary.selectionCode : null,
      serviceType: serviceTypeCode,
      usageLevel: usageTypes.MEDIUM.Id,
      solarLevel: selectedSolarType,
      termsAndConditions: true
    };
  };

  // --------------------------------------------------------------------------
  // Invalid form submit handler.
  // --------------------------------------------------------------------------
  const handleInvalidSubmit = (event, errors, values) => {
    setHasSubmitted(true);
  };

  // --------------------------------------------------------------------------
  // Submission common handlers.
  // --------------------------------------------------------------------------
  const onSubmissionCommonLoad = value => {
    if (value) {
      setSubmissionCommon(value);
    }
  };

  const onSubmissionCommonChange = value => {
    setSubmissionCommon(value);
  };

  // --------------------------------------------------------------------------
  // Address selected handler.
  // --------------------------------------------------------------------------
  const onAddressSelected = address => {
    setSelectedAddress(address);
    applyDistributors(address);
  };

  // --------------------------------------------------------------------------
  // Get services that are supported for a selected address.
  // --------------------------------------------------------------------------
  const getSupportedServices = () => {
    const result = [];

    if (hasValidAddress(selectedAddress)) {
      if (getDistributorItem(distributorSets, billTypes.ELECTRICITY).length > 0) result.push(billTypes.ELECTRICITY);
      if (getDistributorItem(distributorSets, billTypes.GAS).length > 0) result.push(billTypes.GAS);
      if (hasInternet) result.push(billTypes.INTERNET);
      if (hasHomeLoan) result.push(billTypes.HOME_LOAN);
    }

    return result;
  };

  // --------------------------------------------------------------------------
  // Distributors: Apply region distributors
  // --------------------------------------------------------------------------
  const applyDistributorDefaults = items => {
    const result = getDistributorDefaults(null, items, agentBillTypes, serviceType, false);

    if (!result.success) {
      dispatch(
        displayErrorMessage(
          target,
          `Unfortunately we currently do not provide ${serviceType.Name.toLowerCase()} plans for this address.`,
          true
        )
      );
    }

    setConfirmButtonEnabled(result.success);
    setSelectedDistributorPrimary(result.primaryDistributor);
  };

  const applyDistributors = address => {
    dispatch(setServerMessageClear(target, serverMessage));

    const onComplete = result => {
      setDistributorSets(result.items);
      setOccupancyDates(result.occupancyDates);
      setDistributorSetsLoaded(result.success);

      if (result.success) {
        applyDistributorDefaults(result.items);
      } else {
        setSelectedDistributorPrimary(null);
      }
    };

    dispatch(getRegionDistributors(address, agentBillTypes, false, onComplete));
  };

  const onSelectedDistributorChanged = item => {
    setSelectedDistributorPrimary(item);

    if (item && product && product.distributor) {
      // Direct switch product is for a different distributor.
      if (item.selectionCode !== product.distributor.selectionCode) {
        dispatch(
          displayInfoMessage(
            target,
            `Your distributor has been updated to ${item.name}. The plans we have for your address will be displayed when you press 'Next'.`
          )
        );
      } else {
        dispatch(setServerMessageClear(target, serverMessage));
      }
    }
  };

  const setOccupancyDateUncertainButtonGroup = value => {
    setSelectedOccupancyDateUncertain(value);
    standardEvents('direct-switch-dialog', 'occupancy-date-uncertain', value ? 'Yes' : 'No', googleAnalyticsClientCode);
  };

  const setOccupancyTypeButtonGroup = e => {
    const option = e.target.getAttribute('data-value');
    setSelectedOccupancyType(parseInt(option));
    standardEvents(
      'direct-switch-dialog',
      'occupancy-type',
      option === '1' ? 'New' : 'Existing',
      googleAnalyticsClientCode
    );
  };

  const setSolarTypeButtonGroup = event => {
    let option = event.target.getAttribute('data-value');
    var optionValue = parseInt(option);
    setSelectedSolarType(optionValue);

    standardEvents(
      'direct-switch-dialog',
      'solar-type',
      optionValue === solarTypes.NONE.Id
        ? solarTypes.NONE.Name
        : optionValue === solarTypes.STANDARD.Id
        ? solarTypes.STANDARD.Name
        : solarTypes.PREMIUM.Name,
      googleAnalyticsClientCode
    );
  };

  const rowClass = 'sm-dialog-row';
  const fieldClass = 'sm-dialog-field-name';
  const columnSizeLg = 12;

  return (
    <AvForm
      onValidSubmit={event => onSubmitEstimate(event)}
      onInvalidSubmit={handleInvalidSubmit}
      className="sm-direct-switch-dialog"
    >
      <BrandScroller serviceType={serviceType} dialogMode />

      <Row>
        <Col>
          <ServerMessage serverMessage={serverMessage} target={target} />
        </Col>
      </Row>

      <SubmitDialogBanner serviceType={serviceType} providerLogo={providerLogo} providerName={providerName} />

      <div className="intro-text">
        {directSwitchType === directSwitchTypes.PROVIDER ? (
          <p>
            To see whether an applicable {providerName} plan is available for your property, please enter your address
            below.
          </p>
        ) : (
          <>
            <p>
              Let's get your {serviceTypeName} application {providerName ? ` with ${providerName}` : null}
              {product ? ` (${product.productName})` : null} underway.
            </p>
            Please start by confirming your address.
          </>
        )}
      </div>

      {/* --- YOUR ADDRESS ------------------------------------------------------- */}
      <Row className={`${rowClass} mb-2`}>
        <Col>
          <AddressControl
            key="energyEstimateAddress"
            keySuffix="energy"
            labelText="Your address"
            country={country}
            onAddressSelected={onAddressSelected}
            autoPlaceholder={selectedAddress.fullAddress || 'Property address...'}
            autoPropertyAddress={selectedAddress}
            autoAddressRequired={true}
            manualAddressVisible={true}
            manualSuburbSelectorEnabled={true}
            showAreasSupportedDisclaimer={!hasSelectedAddress}
            showMinimalManualFields={false}
          />
        </Col>
      </Row>
      <div className="mt-3">
        <ServicesAuto
          selectedAddress={selectedAddress}
          heading="Other services we can help you with"
          serviceType={serviceType}
          services={billTypes.getOtherServices(agentBillTypes, serviceType)}
          supportedServices={getSupportedServices()}
          isLoaded={distributorSetsLoaded}
        />
      </div>
      {/* --- PRIMARY SERVICE DISTRIBUTOR --------------------------------------- */}
      <EnergyDistributor
        key="primaryServiceDistributor"
        showLabel={true}
        serviceType={serviceType}
        distributorSets={distributorSets}
        onSelectedDistributorChanged={onSelectedDistributorChanged}
        distributor={selectedDistributorPrimary}
        occupancyType={selectedOccupancyType}
      />
      {/* --- ARE YOU MOVING ? -------------------------------------------------- */}
      <div className={rowClass}>
        <div className={fieldClass}>Is this a new connection / move-in?</div>
        <div>
          <QsButtonGroup
            required={true}
            id="occupancyType"
            fieldName="Occupancy Type"
            additionalClass="sm-button-group"
            selectedValue={selectedOccupancyType.toString()}
            onTouchButton={setOccupancyTypeButtonGroup}
            buttons={[
              {
                label: 'No',
                value: occupancyTypes.CURRENT.Id.toString()
              },
              {
                label: 'Yes',
                value: occupancyTypes.NEW.Id.toString()
              }
            ]}
          />
        </div>
      </div>

      {/* --- OCCUPANCY DATE ---------------------------------------------------- */}
      <Collapse isOpen={selectedOccupancyType === occupancyTypes.NEW.Id}>
        {selectedOccupancyType === occupancyTypes.NEW.Id && (
          <OccupancyDate
            rowClass={rowClass}
            fieldClass={fieldClass}
            serviceType={serviceType}
            selectedAddress={selectedAddress}
            distributorSets={distributorSets}
            selectedDistributorPrimary={selectedDistributorPrimary}
            installDates={installDates}
            selectedOccupancyDate={selectedOccupancyDate}
            onSelectedDateChanged={date => {
              setSelectedOccupancyDate(date);
            }}
            selectedOccupancyDateUncertain={selectedOccupancyDateUncertain}
            onOccupancyDateUncertain={setOccupancyDateUncertainButtonGroup}
          />
        )}
      </Collapse>

      {/* --- SOLAR ------------------------------------------------------------- */}
      {showSolar && (
        <Row className={rowClass}>
          <Col className={fieldClass} lg={columnSizeLg}>
            {selectedOccupancyType === occupancyTypes.CURRENT.Id
              ? 'Do you have solar?'
              : 'Does this new connection have solar?'}
          </Col>
          <Col xs="12">
            <QsButtonGroup
              required={true}
              id="solarType"
              fieldName="Solar"
              additionalClass="sm-button-group"
              selectedValue={selectedSolarType.toString()}
              onTouchButton={setSolarTypeButtonGroup}
              buttons={[
                {
                  label: 'None',
                  value: solarTypes.NONE.Id.toString()
                },
                {
                  label: 'Standard',
                  value: solarTypes.STANDARD.Id.toString()
                },
                {
                  label: 'Premium',
                  value: solarTypes.PREMIUM.Id.toString()
                }
              ]}
            />
          </Col>
        </Row>
      )}
      {/* --- EMAIL ADDRESS / AGENT --------------------------------------------- */}
      {(showEmailAddress || showAgents) && (
        <Row className={rowClass}>
          <Col className={fieldClass} lg={columnSizeLg}>
            Email address {showAgents && '/ Agent'}
          </Col>
          <Col xs="12">
            <SubmitCommon
              hasSubmitted={hasSubmitted}
              onLoad={onSubmissionCommonLoad}
              onChange={onSubmissionCommonChange}
              showSendComparisonEmail={false}
              showSendArticlesEmail={false}
              showSendSolarInstallationEmail={false}
              serviceType={serviceType}
              forceRequireEmailAddress={selectedOccupancyDateUncertain}
              forceRequireSendEmail={selectedOccupancyDateUncertain}
            ></SubmitCommon>
          </Col>
        </Row>
      )}
      <div className="sm-modal-footer">
        {defaultMode && (
          <Button onClick={onClose} className="sm-button-secondary">
            Close
          </Button>
        )}
        <Button className="sm-button-primary right-arrow-small wide" disabled={!confirmButtonEnabled}>
          See plans
        </Button>

        <ExitLaunch launchMode={launchMode} serviceType={serviceType} source="direct-switch-dialog" />
        {showPoweredBy && <PoweredBySmartMe />}
      </div>
    </AvForm>
  );
};

export default DirectSwitchAu;
