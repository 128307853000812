export const REGISTRATION_INIT = 'REGISTRATION_INIT';
export const REGISTRATION_LINK_ERROR = 'REGISTRATION_LINK_ERROR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const HOME = 'HOME';
export const DASHBOARD = 'DASHBOARD';
export const SURVEY = 'SURVEY';
export const PREFERENCES = 'PREFERENCES';
export const OTP_DIALOG = 'OTP_DIALOG';
export const SUBMIT_BILL = 'SUBMIT_BILL';
export const ESTIMATE_DIALOG = 'ESTIMATE_DIALOG';
export const ESTIMATE_WIZARD = 'ESTIMATE_WIZARD';
export const ESTIMATE_WIZARD_SOLAR_EMAIL = 'ESTIMATE_WIZARD_SOLAR_EMAIL';
export const ESTIMATE_WIZARD_BILL_UPLOAD = 'ESTIMATE_WIZARD_BILL_UPLOAD';
export const DIRECT_SWITCH_DIALOG = 'DIRECT_SWITCH_DIALOG';
export const DIRECT_SWITCH_EMAIL = 'DIRECT_SWITCH_EMAIL';
export const INTERNET_SPECIFIC = 'INTERNET_SPECIFIC';
export const COMPARE = 'COMPARE';
export const COMPARE_SCREEN = 'COMPARE_SCREEN';
export const SWITCH = 'SWITCH';
export const RECYLCE_BIN = 'RECYLCE_BIN';
export const AFFILIATE_COMPARISON = 'AFFILIATE_COMPARISON';
export const PARTNER_COMPARISON = 'PARTNER_COMPARISON';
export const MOVING_HOUSE = 'MOVING_HOUSE';
