export const scaleTypes = {
  all: { id: 0, className: 'scale-all' },
  xsUp: { id: 1, className: 'scale-xs-up' },
  xsDown: { id: 2, className: 'scale-xs-down' },
  smUp: { id: 3, className: 'scale-sm-up' },
  smDown: { id: 4, className: 'scale-sm-down' },
  mdUp: { id: 5, className: 'scale-md-up' },
  mdDown: { id: 6, className: 'scale-md-down' },
  lgUp: { id: 7, className: 'scale-lg-up' },
  lgDown: { id: 8, className: 'scale-lg-down' },
  xlUp: { id: 9, className: 'scale-xl-up' },
  xlDown: { id: 10, className: 'scale-xl-down' }
};

export const orientationTypes = {
  none: { id: 0, name: '' },
  left: { id: 1, name: 'left' },
  right: { id: 2, name: 'right' },
  top: { id: 3, name: 'top' }
};
