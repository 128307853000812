import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import IdleTimer from 'react-idle-timer';
import { pageModes } from '../../enums/launchTypes';
import { idleTimerDialogVisible } from '../../actions/dialogOptionsActions';
import { postFrameDialogMessage } from '../../utilities/domUtilities';

const IdleTimerDialog = ({ timeout, onClose, closeButtonText, bodyClass, closeButtonClass, children, onIdle }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const { dialogOptions } = useSelector(state => state);
  const { pageMode, frameHeight, frameHost } = useSelector(state => state.application);

  const [dialogOpen, setDialogOpen] = useState();
  const [idleTimer, setIdleTimer] = useState(null);
  const [lastActiveTime, setLastActiveTime] = useState();

  const modalBodyClassName = bodyClass || 'qs-modal-body';

  useEffect(() => {
    if (dialogOptions.idleTimer.isOpen && dialogOpen) onDialogClosed();
  }, [dialogOptions]);

  const onDialogToggle = () => {
    dispatch(idleTimerDialogVisible(!dialogOpen));

    setDialogOpen(!dialogOpen);
  };

  const handleModal = () => {
    if (pageMode === pageModes.FRAMED) {
      dispatch(postFrameDialogMessage(frameHost, 'Idle Timeout', dialogOpen, ref.current, frameHeight));
    }

    if (!dialogOpen) onDialogClosed();
  };

  const onDialogClosed = () => {
    dispatch(idleTimerDialogVisible(false));
    setLastActiveTime(null);

    idleTimer.reset();

    if (onClose) onClose();
  };

  const onIdleInternal = () => {
    if (!lastActiveTime) {
      setLastActiveTime(idleTimer.getLastActiveTime());
    }

    idleTimer.pause();

    dispatch(idleTimerDialogVisible(true));
    setDialogOpen(true);

    if (onIdle) onIdle();
  };

  return (
    <>
      <IdleTimer
        ref={ref => setIdleTimer(ref)}
        element={document}
        onIdle={onIdleInternal}
        debounce={250}
        timeout={timeout}
      />

      <Modal
        onOpened={handleModal}
        onClosed={handleModal}
        toggle={onDialogToggle}
        isOpen={dialogOpen}
        size="md"
        centered={pageMode === pageModes.DEFAULT}
      >
        <div ref={ref}>
          <ModalBody className={modalBodyClassName}>{children}</ModalBody>
          <ModalFooter>
            <Button className={closeButtonClass} onClick={onDialogToggle}>
              {closeButtonText || 'Close'}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default IdleTimerDialog;
