import React, { useState, useEffect, useCallback } from 'react';
import _debounce from 'lodash.debounce';
import { useSelector, useDispatch } from 'react-redux';
import { getDefaultAddressStatus, getNbnStatus } from '../../actions/internetActions';
import {
  submitEstimateWithToken,
  submitEstimateWithKey,
  submitEstimateUnathenticated
} from '../../actions/energyEstimateActions';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import QsButtonGroup from '../common/qsButtonGroup';
import { Row, Col, Button, Collapse } from 'reactstrap';
import moment from 'moment';
import { useAuth0 } from '../../auth/auth0-Provider';
import SubmitCommon from '../common/submitCommon';
import ExitLaunch from '../common/exitLaunch';
import ServicesAuto from '../serviceCard/servicesAuto';
import {
  occupancyTypes,
  internetDownloadSpeedTypes,
  internetConnectionTypeTypes,
  submissionTypes
} from '../../enums/submissionTypes';
import { uploadBillTypes, uploadSubTypes } from '../../enums/uploadBillTypes';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import {
  setServerMessageClear,
  displayNoOffersMessage,
  comparisonErrorMessage
} from '../../actions/serverMessageActions';
import { setUser } from '../../actions/userActions';
import ServerMessage from '../common/serverMessage';
import QsCheckBox from '../common/qsCheckBox';

import 'react-google-places-autocomplete/dist/index.min.css';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getAddress, hasValidAddress } from '../../utilities/addressUtilities';
import { submitAutoEstimates } from '../serviceCard/serviceCardUtilities';
import AddressControl from '../common/addressControl';
import { getSessionActivity } from '../../utilities/commonUtilities';
import { addSessionActivity } from '../../actions/sessionActivityActions';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes';
import PoweredBySmartMe from '../common/poweredBySmartMe';
import OccupancyDate from '../common/occupancyDate';
import BrandScroller from '../common/brandScroller';
import { billTypes } from '../../enums/billTypes';

const InternetAu = props => {
  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { accessKey: comparisonAccessKey, accessId: comparisonAccessId, isLoaded: comparisonLoaded } = useSelector(
    state => state.comparison
  );
  const { accessKey: userAccessKey, accessId: userAccessId } = useSelector(state => state.user);
  const { serverMessage, selectedAgent, session } = useSelector(state => state);
  const { hasAgents, billTypes: agentBillTypes, country } = useSelector(state => state.config);
  const { googleAnalyticsClientCode, showPoweredBy } = useSelector(state => state.config.settings);
  const { internetDownloadSpeeds, internetConnectionTypes } = useSelector(state => state.config.referenceData);
  const { data: userBills } = useSelector(state => state.userBills);

  const sessionActivity = getSessionActivity(session);

  const {
    serviceType,
    launchMode,
    closeButtonText,
    confirmButtonText,
    showBackButton,
    showCancelButton,
    showCloseButton,
    userInitiated,
    submissionId,
    submission,
    onBackButtonClick,
    onUploadBillTypeSelect,
    onCancel,
    onClose,
    onSuccess,
    isVerify
  } = props;

  const {
    billType,
    occupancyDateUncertain = false,
    occupancyType = occupancyTypes.CURRENT.Id,
    occupancyDate,
    propertyAddress,
    connectionType,
    techType,
    developmentCharges,
    currentSpend,
    dataUnlimited,
    downloadSpeed,
    defaultComparison
  } = submission;

  const getFullAddress = address => {
    return hasValidAddress(address)
      ? `${address.unitNumber} ${address.streetNumber} ${address.route} ${address.suburb} ${address.state}, ${address.country}`
      : '';
  };

  const getDate = date => {
    return !date ? null : moment(date).toDate();
  };

  const { ESTIMATE_DIALOG: target, INTERNET_SPECIFIC: internetTarget } = serverMessageTargets;

  const initialAddress = getAddress(propertyAddress);
  const initialOccupancyDate = occupancyDate && !defaultComparison ? getDate(occupancyDate) : null;
  const initialDownloadSpeed = downloadSpeed ? downloadSpeed.toString() : internetDownloadSpeedTypes.ALL.Id.toString();
  const inititalStatus =
    connectionType !== internetConnectionTypeTypes.UNKNOWN.Id
      ? { isAvailable: true, techType, connectionType, developmentCharges }
      : getDefaultAddressStatus();

  const [selectedAddress, setSelectedAddress] = useState(initialAddress);
  const [selectedOccupancyType, setSelectedOccupancyType] = useState(occupancyType);
  const [selectedOccupancyDateUncertain, setSelectedOccupancyDateUncertain] = useState(occupancyDateUncertain);
  const [selectedOccupancyDate, setSelectedOccupancyDate] = useState(initialOccupancyDate);
  const [hasChanges, setHasChanges] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submissionCommon, setSubmissionCommon] = useState({});
  const [reset, setReset] = useState();
  const [initialComparisonEmailValue, setInitialComparisonEmailValue] = useState();
  const [initialEmailAddressValue, setInitialEmailAddressValue] = useState(null);
  const [addressStatusLoading, setAddressStatusLoading] = useState(false);
  const [addressStatus, setAddressStatus] = useState(inititalStatus);
  const [selectedCurrentSpend, setSelectedCurrentSpend] = useState(currentSpend);
  const [selectedDataUnlimited, setSelectedDataUnlimited] = useState(dataUnlimited);
  const [selectedDownloadSpeed, setSelectedDownloadSpeed] = useState(initialDownloadSpeed);
  const [selectedConnectionType, setSelectedConnectionType] = useState(inititalStatus.connectionType);

  const showAgents = !selectedAgent.isLoaded && hasAgents;
  const hasSelectedAddress = selectedAddress && selectedAddress.postcode && hasValidAddress(selectedAddress);

  const getInstallDates = status => {
    if (!status.minSelectableDate) return null;

    let currentDateMoment = moment.tz(status.minSelectableDate, 'YYYY-MM-DD', 'GMT').add(-1, 'days');
    const holidays = status.holidays ? status.holidays.map(date => moment.tz(date, 'YYYY-MM-DD', 'GMT').toDate()) : [];

    let installDates = [];
    for (var i = 0; i < 181; i++) {
      currentDateMoment.add(1, 'days');
      let isoWeekday = currentDateMoment.isoWeekday();

      let currentDate = currentDateMoment.toDate();

      let isHoliday = holidays.find(item => {
        return item.getTime() === currentDate.getTime();
      });

      if (isoWeekday <= 5 && !isHoliday) installDates.push(currentDate);
    }

    return installDates;
  };

  let installDates = getInstallDates(addressStatus);

  // --------------------------------------------------------------------------
  // Submit new estimate request.
  // --------------------------------------------------------------------------
  const onSubmitEstimate = () => {
    const estimate = {
      address: {
        ...selectedAddress
      },
      occupancyDateUncertain: selectedOccupancyDateUncertain,
      occupancyDate: selectedOccupancyDate ? moment(selectedOccupancyDate).format('YYYY-MM-DD') : null,
      occupancyType: selectedOccupancyType,
      ...submissionCommon,
      sessionActivity,
      agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
      serviceType: billType,
      connectionType: selectedConnectionType,
      techType: addressStatus.techType,
      developmentCharges: addressStatus.developmentCharges,
      currentSpend: selectedCurrentSpend,
      dataUnlimited: selectedDataUnlimited,
      downloadSpeed: parseInt(selectedDownloadSpeed)
    };

    // If the user checked the comparison email checkbox from an initial state of false (i.e. they want an email for this comparison),
    // then fire an API call to initiate the email send (even if nothing else on the form changed; i.e. hasChanges = false)
    const sendComparisonEmailInitiated = !initialComparisonEmailValue && submissionCommon.sendComparisonEmail;

    // If a the user is on the compare page (i.e. comparison is loaded) but the access details are missing, they were cleared because of an error with the last estimate submit.
    // so we need to force an API call (even if nothing else on the form changed; i.e. hasChanges = false)
    const comparisonError = comparisonLoaded && (!comparisonAccessId || !comparisonAccessKey);

    // Check if anything has changed before making a re-estimate request.
    // User initiated considered (i.e. hasChanges = false), as user may have triggered the dialog and accepted a complete set of defaults (if possible)
    if (hasChanges || userInitiated || defaultComparison || sendComparisonEmailInitiated || comparisonError) {
      const userBill = userBills.find(e => e.submissionId === submissionId);
      const userBillAccessKey = userBill && userBill.accessKey ? userBill.accessKey.accessKey : null;
      const userBillAccessId = userBill && userBill.accessKey ? userBill.accessKey.accessId : null;

      const accessKey = userBillAccessKey || comparisonAccessKey || userAccessKey;
      const accessId = userBillAccessId || comparisonAccessId || userAccessId;

      if (accessKey && accessId) {
        onSubmitWithKey(serviceType, { estimate, access: { key: accessKey, id: accessId } });
      } else if (isUserAuthenticated()) {
        onSubmitWithToken(serviceType, { estimate });
      } else {
        onSubmitUnathenticated(serviceType, { estimate });
      }

      standardEvents('internet-dialog', 'Click', 'Confirm', googleAnalyticsClientCode);
    } else {
      onSuccess({
        hasOffers: true,
        estimateWasRun: false,
        userBill: {}
      });
    }
  };

  const dispatchAutoEstimates = () => {
    const address = { ...selectedAddress, isAddress: true };

    dispatch(
      submitAutoEstimates(
        serviceType,
        address,
        selectedAgent,
        sessionActivity,
        submissionCommon,
        agentBillTypes,
        userBills,
        googleAnalyticsClientCode
      )
    );
  };

  const onSubmitWithToken = (serviceType, data) => {
    const options = { showSpinner: true, applySleep: true };
    const fn = async () => {
      const accessToken = await getAccessToken();
      dispatch(submitEstimateWithToken(accessToken, serviceType, data, options, onComplete, onError));
    };
    fn();
  };

  const onSubmitWithKey = (serviceType, data) => {
    const options = { showSpinner: true, applySleep: true };
    dispatch(submitEstimateWithKey(serviceType, data, options, onComplete, onError));
  };

  const onSubmitUnathenticated = (serviceType, data) => {
    const options = { showSpinner: true, applySleep: true };
    dispatch(submitEstimateUnathenticated(serviceType, data, options, onComplete, onError));
  };

  // --------------------------------------------------------------------------
  // Estimate comparison error handler.
  // --------------------------------------------------------------------------
  const onError = error => {
    dispatch(comparisonErrorMessage(error, target));
  };

  // --------------------------------------------------------------------------
  // Initialize the form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    initialize();
  }, []);

  // --------------------------------------------------------------------------
  // Invalid form submit handler.
  // --------------------------------------------------------------------------
  const handleInvalidSubmit = (event, errors, values) => {
    setHasSubmitted(true);
  };

  // --------------------------------------------------------------------------
  // Submission common handlers.
  // --------------------------------------------------------------------------
  const onSubmissionCommonLoad = value => {
    if (value) {
      setInitialComparisonEmailValue(value.sendComparisonEmail);
      setInitialEmailAddressValue(value.emailAddress);
      setSubmissionCommon(value);
    }
  };

  const onSubmissionCommonChange = value => {
    setSubmissionCommon(value);
  };

  // --------------------------------------------------------------------------
  // Detect changes in form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    let date1 = initialOccupancyDate ? initialOccupancyDate.toString() : null;
    let date2 = selectedOccupancyDate ? getDate(selectedOccupancyDate).toString() : null;

    let changed =
      getFullAddress(selectedAddress) !== getFullAddress(propertyAddress) ||
      occupancyType !== selectedOccupancyType ||
      occupancyDateUncertain !== selectedOccupancyDateUncertain ||
      (selectedOccupancyType === occupancyTypes.NEW.Id && date1 !== date2) ||
      currentSpend !== selectedCurrentSpend ||
      initialDownloadSpeed !== selectedDownloadSpeed ||
      dataUnlimited !== selectedDataUnlimited ||
      inititalStatus.connectionType !== selectedConnectionType ||
      // this has to be a truthy != not strict !== comparison as submissionCommon.emailAddress may be undefined not null
      // eslint-disable-next-line eqeqeq
      initialEmailAddressValue != submissionCommon.emailAddress;

    setHasChanges(changed);
  }, [
    selectedOccupancyType,
    selectedOccupancyDate,
    selectedOccupancyDateUncertain,
    selectedAddress,
    selectedCurrentSpend,
    selectedDownloadSpeed,
    selectedDataUnlimited,
    selectedConnectionType,
    submissionCommon.emailAddress
  ]);

  // --------------------------------------------------------------------------
  // Initialize or reset the form
  // --------------------------------------------------------------------------
  const initialize = reset => {
    dispatch(setServerMessageClear(target, serverMessage));
    dispatch(setServerMessageClear(internetTarget, serverMessage));

    if (reset) {
      setSelectedAddress(initialAddress);
      dispatch(setUser({ emailAddress: initialEmailAddressValue }));
      setReset(Date.now());
    } else {
      checkNbnStatus(initialAddress);
    }

    setSelectedOccupancyDate(initialOccupancyDate);
    setSelectedOccupancyDateUncertain(occupancyDateUncertain);
    setSelectedOccupancyType(occupancyType);
    setSelectedCurrentSpend(currentSpend);
    setSelectedDataUnlimited(dataUnlimited);
    setSelectedDownloadSpeed(initialDownloadSpeed);
    setSelectedConnectionType(inititalStatus.connectionType);

    setHasSubmitted(false);
    setHasChanges(false);
  };

  // --------------------------------------------------------------------------
  // Address selector change handler.
  // --------------------------------------------------------------------------
  const onAddressSelected = address => {
    setSelectedAddress(address);

    dispatch(setServerMessageClear(target, serverMessage));

    checkNbnStatus(address);
  };

  const checkNbnStatus = address => {
    if (hasValidAddress(address)) {
      setAddressStatusLoading(true);

      dispatch(
        getNbnStatus(
          {
            address: { ...address }
          },
          result => {
            getInstallDates(result);
            setAddressStatus(result);
            setSelectedConnectionType(connectionType || result.connectionType);
            setAddressStatusLoading(false);
          }
        )
      );
    }
  };

  const setOccupancyDateUncertainButtonGroup = value => {
    setSelectedOccupancyDateUncertain(value);
    standardEvents('internet-dialog', 'occupancy-date-uncertain', value ? 'Yes' : 'No', googleAnalyticsClientCode);
  };

  const setOccupancyTypeButtonGroup = event => {
    let option = event.target.getAttribute('data-value');

    let newOccupancyType = parseInt(option);
    setSelectedOccupancyType(newOccupancyType);

    if (newOccupancyType === occupancyTypes.CURRENT.Id) setSelectedOccupancyDate(null);

    standardEvents(
      'internet-dialog',
      'occupancy-type',
      newOccupancyType === occupancyTypes.NEW.Id ? 'New' : 'Existing',
      googleAnalyticsClientCode
    );
  };

  // --------------------------------------------------------------------------
  // Add session activity.
  // --------------------------------------------------------------------------
  const recordSessionActivity = (comparisonResult, userBill) => {
    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          submissionType: submissionTypes.ESTIMATE,
          submissionId: userBill.submissionId,
          serviceType: serviceType.Code,
          hasOffers: comparisonResult.hasOffers
        },
        activityType: sessionActivityTypes.COMPARISON_COMPLETED
      })
    );
  };

  // --------------------------------------------------------------------------
  // Estimate comparison complete handler.
  // --------------------------------------------------------------------------
  const onComplete = (comparisonResult, userBill) => {
    recordSessionActivity(comparisonResult, userBill);

    onSuccess({
      hasOffers: comparisonResult.hasOffers,
      estimateWasRun: true,
      userBill
    });

    if (!comparisonResult.hasOffers) {
      setHasChanges(false);
      dispatch(displayNoOffersMessage(target));
    }

    dispatchAutoEstimates();
  };

  const rowClass = 'sm-dialog-row';
  const fieldClass = 'sm-dialog-field-name';
  const columnSizeLg = 12;

  // --------------------------------------------------------------------------
  // Form reference.
  // --------------------------------------------------------------------------
  const formRef = ref => {
    if (ref) {
      // Pre-validate occupancy purpose so when user selects 'Business' they see the error.
      let inputs = {
        //hasDemand_validation: ref._inputs['hasDemand_validation']
      };

      if (isVerify) {
        //inputs.billDate_validation = ref._inputs['billDate_validation'];
      }

      ref.setTouched(Object.keys(inputs));
    }
  };

  return (
    <>
      <AvForm onValidSubmit={event => onSubmitEstimate(event)} onInvalidSubmit={handleInvalidSubmit} ref={formRef}>
        <Row>
          <Col>
            <ServerMessage key={target} serverMessage={serverMessage} target={target} />
            <ServerMessage key={internetTarget} serverMessage={serverMessage} target={internetTarget} />
          </Col>
        </Row>

        <BrandScroller serviceType={billTypes.INTERNET} dialogMode />

        {/* --- YOUR ADDRERSS ------------------------------------------------------- */}
        <Row className={rowClass}>
          <Col>
            <AddressControl
              key="internetEstimateAddress"
              keySuffix="internet"
              labelText="Your address"
              country={country}
              onAddressSelected={onAddressSelected}
              autoPlaceholder={selectedAddress.fullAddress || 'Property address...'}
              autoPropertyAddress={selectedAddress}
              autoAddressRequired={true}
              manualAddressVisible={true}
              manualSuburbSelectorEnabled={true}
              showAreasSupportedDisclaimer={false}
              showMinimalManualFields={true}
            />
          </Col>
        </Row>
        {!addressStatus.isAvailable && !addressStatusLoading && hasSelectedAddress && (
          <Row>
            <Col className={fieldClass} xs="12">
              Connection type
            </Col>
            <Col xs="12" lg="6">
              <AvField
                bsSize="sm"
                type="select"
                name="connectionType"
                placeholder=""
                value={selectedConnectionType}
                onChange={e => setSelectedConnectionType(parseInt(e.target.value))}
              >
                {internetConnectionTypes.map((ref, i) => (
                  <option key={`connectionType-${ref.id}`} value={ref.id.toString()}>
                    {ref.description}
                  </option>
                ))}
              </AvField>
            </Col>
          </Row>
        )}
        {/* --- ARE YOU MOVING ? -------------------------------------------------- */}
        <Row className={rowClass}>
          <Col className={fieldClass} lg={columnSizeLg}>
            Is this a new connection / move-in?
          </Col>
          <Col xs="12">
            <QsButtonGroup
              required={true}
              id="occupancyType"
              fieldName="Occupancy Type"
              additionalClass="sm-button-group"
              selectedValue={selectedOccupancyType.toString()}
              onTouchButton={setOccupancyTypeButtonGroup}
              buttons={[
                {
                  label: 'No',
                  value: occupancyTypes.CURRENT.Id.toString()
                },
                {
                  label: 'Yes',
                  value: occupancyTypes.NEW.Id.toString()
                }
              ]}
            />
          </Col>
        </Row>
        {/* --- OCCUPANCY DATE ---------------------------------------------------- */}
        <Collapse isOpen={selectedOccupancyType === occupancyTypes.NEW.Id}>
          {selectedOccupancyType === occupancyTypes.NEW.Id && (
            <OccupancyDate
              rowClass={rowClass}
              fieldClass={fieldClass}
              serviceType={serviceType}
              selectedAddress={selectedAddress}
              distributorSets={null}
              selectedDistributorPrimary={null}
              installDates={installDates || []}
              selectedOccupancyDate={selectedOccupancyDate}
              onSelectedDateChanged={date => {
                setSelectedOccupancyDate(date);
              }}
              selectedOccupancyDateUncertain={selectedOccupancyDateUncertain}
              onOccupancyDateUncertain={setOccupancyDateUncertainButtonGroup}
            />
          )}
        </Collapse>
        {selectedOccupancyType === occupancyTypes.CURRENT.Id && (
          <Row>
            <Col className={fieldClass} lg={columnSizeLg}>
              Current monthly spend
            </Col>
            <Col xs="12" sm="6">
              <AvField
                bsSize="sm"
                type="text"
                name="currentSpend"
                placeholder=""
                value={selectedCurrentSpend}
                onChange={e =>
                  setSelectedCurrentSpend(
                    e.target.value && parseFloat(e.target.value) ? parseFloat(e.target.value) : null
                  )
                }
                validate={{
                  number: true,
                  min: { value: 0.01, errorMessage: 'Please enter a value above $0' },
                  max: { value: 10000, errorMessage: 'Pleae enert a value below $10000' },
                  pattern: {
                    value: '^[0-9]{1,5}(\\.[0-9]{1,2})?$',
                    errorMessage: `Please enter your current spend on broadband per month`
                  },
                  required: {
                    value: true,
                    errorMessage: `Please enter your current spend on broadband per month`
                  }
                }}
              />
            </Col>
            <Col xs="12" sm="6" className="sm-estimate-upload-button">
              <Button
                disabled={!serviceType.ComparisonImplemented}
                className={`sm-button-primary medium right-arrow-small`}
                onClick={() => onUploadBillTypeSelect(uploadBillTypes.WEBSITE, uploadSubTypes.ESTIMATE_UPLOAD)}
              >
                {uploadBillTypes.WEBSITE.ButtonText}
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col className={fieldClass} lg={columnSizeLg}>
            Download speed
          </Col>
          <Col xs="12" lg="6">
            <AvField
              bsSize="sm"
              type="select"
              name="downloadSpeed"
              placeholder=""
              value={selectedDownloadSpeed}
              onChange={e => setSelectedDownloadSpeed(e.target.value)}
            >
              {internetDownloadSpeeds.map((ref, i) => (
                <option key={`downloadSpeed-${ref.id}`} value={ref.id.toString()}>
                  {ref.description}
                </option>
              ))}
            </AvField>
          </Col>
          <Col xs="12" lg="6">
            <QsCheckBox
              isChecked={selectedDataUnlimited}
              onCheck={() => setSelectedDataUnlimited(!selectedDataUnlimited)}
              label="Unlimited data plans only"
            />
          </Col>
        </Row>
        {/* --- EMAIL ADDRESS / AGENT --------------------------------------------- */}
        <Row className={rowClass}>
          <Col className={fieldClass} lg={columnSizeLg}>
            Email address {showAgents && '/ Agent'}
          </Col>
          <Col xs="12">
            <SubmitCommon
              hasSubmitted={hasSubmitted}
              onLoad={onSubmissionCommonLoad}
              onChange={onSubmissionCommonChange}
              refresh={reset}
              showSendComparisonEmail={true}
              showSendArticlesEmail={true}
              showSendSolarInstallationEmail={false}
              serviceType={serviceType}
              forceRequireEmailAddress={selectedOccupancyDateUncertain}
              forceRequireSendEmail={selectedOccupancyDateUncertain}
            >
              <ServicesAuto selectedAddress={selectedAddress} serviceType={serviceType} />
            </SubmitCommon>
          </Col>
        </Row>
        <div className="sm-modal-footer">
          {hasChanges && (
            <Button onClick={() => initialize(true)} className="sm-button-secondary">
              Reset
            </Button>
          )}
          {showBackButton && (
            <Button onClick={onBackButtonClick} className="sm-button-secondary">
              Go back
            </Button>
          )}
          {showCancelButton && (
            <Button onClick={onCancel} className=" sm-button-secondary">
              Cancel
            </Button>
          )}
          {showCloseButton && (
            <Button onClick={onClose} className="sm-button-secondary">
              {closeButtonText}
            </Button>
          )}
          <Button className="sm-button-primary right-arrow-small wide">{confirmButtonText}</Button>

          <ExitLaunch launchMode={launchMode} serviceType={serviceType} source="internet-dialog" />
          {showPoweredBy && <PoweredBySmartMe />}
        </div>
      </AvForm>
    </>
  );
};

export default InternetAu;
