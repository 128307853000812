import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';

import { billingTypes } from '../../enums/submissionTypes';

import AddressControl from '../common/addressControl';
import PoBoxAddress from '../common/poBoxAddress';
import { Button } from 'reactstrap';

const BillDeliverySection = ({ confirmDetail, offer, onChange, onShowQuickTip, showMinimalManualFields }) => {
  const { country } = useSelector(state => state.config);
  const { organiserAgentName } = useSelector(state => state.content.general);

  const allowPaperBill = offer.allowPaperBill && offer.productDetail && offer.productDetail.allowPaperBill;
  const allowOrganiser = offer.allowOrganiser;

  const onBillingAddressSelected = address => {
    onChange(null, { billingAddress: address });
  };

  const onBillingTypeChange = (event, target) => {
    onChange(null, { billingType: target, billingAddress: null });
  };

  return (
    <>
      <div>
        <div className="label">Send my bills to</div>
        <AvRadioGroup
          className="qs-form-radio"
          name="billingType"
          label=""
          errorMessage="Please select where to send your bills"
          required
          value={confirmDetail.billingType}
          onChange={onBillingTypeChange}
        >
          {allowOrganiser && (
            <AvRadio
              label={
                <>
                  My{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: organiserAgentName
                    }}
                  />{' '}
                  {onShowQuickTip && (
                    <Button className="sm-link-button" onClick={onShowQuickTip}>
                      how it works
                    </Button>
                  )}
                </>
              }
              value={billingTypes.EMAIL_ORGANISER.Code}
            />
          )}
          <AvRadio label="My email address" value={billingTypes.EMAIL_PERSONAL.Code} />
          {allowPaperBill && (
            <>
              <AvRadio label={billingTypes.ADDRESS_CONNECTION.Label} value={billingTypes.ADDRESS_CONNECTION.Code} />
              <AvRadio label={billingTypes.ADDRESS_OTHER.Label} value={billingTypes.ADDRESS_OTHER.Code} />
              <AvRadio label={billingTypes.ADDRESS_POBOX.Label} value={billingTypes.ADDRESS_POBOX.Code} />
            </>
          )}
        </AvRadioGroup>
      </div>

      {confirmDetail.billingType === billingTypes.ADDRESS_OTHER.Code && (
        <AddressControl
          key="billingAddress"
          keySuffix="billing"
          labelText="Billing address (Other)"
          country={country}
          onAddressSelected={onBillingAddressSelected}
          autoPlaceholder="Property address..."
          autoPropertyAddress={null}
          autoAddressRequired={true}
          manualAddressVisible={true}
          manualSuburbSelectorEnabled={true}
          showAreasSupportedDisclaimer={false}
          large={!isMobile}
          showMinimalManualFields={showMinimalManualFields}
        />
      )}

      {confirmDetail.billingType === billingTypes.ADDRESS_POBOX.Code && (
        <>
          <div className="qs-more-info-table-header mt-3">Billing address (PO Box)</div>
          <PoBoxAddress key="poBoxAddress" namePrefix="poBoxAddress" onAddressSelected={onBillingAddressSelected} />
        </>
      )}

      {offer.productDetail &&
        offer.productDetail.billingContentEmail &&
        (!confirmDetail.billingType ||
          confirmDetail.billingType === billingTypes.NONE.Code ||
          confirmDetail.billingType === billingTypes.EMAIL_ORGANISER.Code ||
          confirmDetail.billingType === billingTypes.EMAIL_PERSONAL.Code) && (
          <div
            className="qs-mt-sm"
            dangerouslySetInnerHTML={{
              __html: offer.productDetail.billingContentEmail
            }}
          />
        )}

      {offer.productDetail &&
        offer.productDetail.billingContentPaper &&
        (confirmDetail.billingType === billingTypes.ADDRESS_CONNECTION.Code ||
          confirmDetail.billingType === billingTypes.ADDRESS_POBOX.Code ||
          confirmDetail.billingType === billingTypes.ADDRESS_OTHER.Code) && (
          <div
            className="qs-mt-sm"
            dangerouslySetInnerHTML={{
              __html: offer.productDetail.billingContentPaper
            }}
          />
        )}
    </>
  );
};

export default BillDeliverySection;
