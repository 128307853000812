import React from 'react';
import { AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { validateAbn } from '../../utilities/abnUtilities';

const validateAbnEntry = (value, ctx, input, cb) => {
  if (!validateAbn(value)) {
    cb('Please enter a valid ABN');
  } else cb(true);
};

const Business = props => {
  const {
    onChange,
    details: { abn, businessName, position }
  } = props;

  return (
    <div>
      <Row className="qs-more-info-table-header">
        <Col>Business</Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <FormGroup>
            <AvField
              bsSize="sm"
              name="businessName"
              placeholder="Business Name"
              type="text"
              value={businessName}
              onChange={e => onChange(e, { businessName: e.target.value })}
              validate={{
                required: { value: true, errorMessage: 'Business name is required' },
                minLength: { value: 2, errorMessage: 'Business name is not the required length' },
                maxLength: { value: 100, errorMessage: 'Business name should be 100 characters or less' }
              }}
            />
          </FormGroup>
          <FormGroup>
            <AvField
              bsSize="sm"
              name="abn"
              placeholder="ABN"
              type="text"
              value={abn}
              onChange={e => onChange(e, { abn: e.target.value })}
              validate={{
                required: { value: true, errorMessage: 'ABN is required' },
                abn: validateAbnEntry
              }}
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <AvField
              bsSize="sm"
              name="position"
              placeholder="Position Title"
              type="text"
              value={position}
              onChange={e => onChange(e, { position: e.target.value })}
              validate={{
                required: { value: true, errorMessage: 'Position is required' },
                minLength: { value: 2, errorMessage: 'Position is not the required length' },
                maxLength: { value: 50, errorMessage: 'Position should be 50 characters or less' },
                pattern: {
                  value: '^[a-zA-Z ]{2,20}$',
                  errorMessage: `Please enter a valid position (letters and spaces only)`
                }
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default Business;
