import { environmentNames } from '../enums/environmentNames';

const developmentEnvironmentConfiguration = {};

const stagingEnvironmentConfiguration = {};

const productionEnvironmentConfiguration = {};

var selectedEnvironmentName =
  process.env.NODE_ENV === environmentNames.PRODUCTION
    ? process.env.REACT_APP_ENV_CONFIG === environmentNames.STAGING
      ? environmentNames.STAGING
      : environmentNames.PRODUCTION
    : environmentNames.DEVELOPMENT;

var selectedEnvironmentConfiguration =
  process.env.NODE_ENV === environmentNames.PRODUCTION
    ? process.env.REACT_APP_ENV_CONFIG === environmentNames.STAGING
      ? stagingEnvironmentConfiguration
      : productionEnvironmentConfiguration
    : developmentEnvironmentConfiguration;

const environmentConfiguration = { ...selectedEnvironmentConfiguration, environmentName: selectedEnvironmentName };

export default environmentConfiguration;
