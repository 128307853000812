import React from 'react';
import { Button } from 'reactstrap';
import { internetConnectionTypeTypes } from '../../enums/submissionTypes';

import { occupancyTypes, submissionTypes } from '../../enums/submissionTypes';

const InternetEstimateDetails = ({ userBill, submissionType, onEdit }) => {
  const submission = userBill.estimate || userBill.bill;

  const { connectionType, techType, occupancyDateDisplay, occupancyType } = submission;

  const connection = internetConnectionTypeTypes.getById(connectionType);

  return (
    <>
      <div className="sm-estimate-details">
        <div className="intro-summary">{userBill.displayAddress}</div>

        <div className="detail-summary">
          <div className="detail-content">
            <div className="detail-row">
              <div className="detail-label">Connection:</div>
              <div className="detail-value">{`${connection.Name}${techType ? ` - ${techType}` : ''}`}</div>
            </div>

            {occupancyDateDisplay && (
              <div className="detail-row">
                <div className="detail-label">Moving:</div>
                <div className="detail-value">
                  {occupancyType === occupancyTypes.NEW.Id
                    ? occupancyDateDisplay
                      ? occupancyDateDisplay
                      : 'Yes'
                    : 'No'}
                </div>
              </div>
            )}
          </div>
        </div>

        {submissionType === submissionTypes.ESTIMATE && (
          <div className="drawer-buttons mt-3">
            <Button className="sm-button-secondary" onClick={onEdit}>
              Edit
            </Button>
          </div>
        )}

        <hr className="summary-divider" />
      </div>
    </>
  );
};

export default InternetEstimateDetails;
