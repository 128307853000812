import * as types from './actions';

export function setSignalrSuccess(data) {
  return { type: types.SET_SIGNALR, data };
}

export function setSignalr(data) {
  return dispatch => {
    dispatch(setSignalrSuccess({ ...data }));
  };
}

export function recieveMessage(data) {
  return dispatch => {
    dispatch(setSignalrSuccess({ ...data }));
  };
}
