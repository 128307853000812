import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { controlTypes } from './getStarted';

import ServiceIcon from '../serviceCard/serviceIcon';
import { hasValidAddress } from '../../utilities/addressUtilities';
import { getDefaultAddressStatus, getNbnStatus } from '../../actions/internetActions';
import { submitEstimateInternetDefault } from '../../actions/energyEstimateActions';
import { billTypes } from '../../enums/billTypes';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getSessionActivity } from '../../utilities/commonUtilities';

const WizardInternet = ({ onClose, onChangeControlType, data }) => {
  const defaultStatus = getDefaultAddressStatus();

  const [selectedCurrentSpend, setSelectedCurrentSpend] = useState();
  const [addressStatus, setAddressesStatus] = useState(defaultStatus);
  const [addressStatusLoading, setAddressStatusLoading] = useState(false);
  const [selectedConnectionType, setSelectedConnectionType] = useState(defaultStatus.connectionType);

  const displayNbnStatus = false;

  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { internetConnectionTypes } = useSelector(state => state.config.referenceData);
  const { session } = useSelector(state => state);

  const sessionActivity = getSessionActivity(session);

  const serviceTypeOptions = { includeExternal: false };
  const hasHomeLoan = billTypes.hasServiceType(agentBillTypes, billTypes.HOME_LOAN, serviceTypeOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const address = data.propertyAddress;

      setAddressStatusLoading(true);

      if (hasValidAddress(address)) {
        dispatch(
          getNbnStatus(
            {
              address: { ...address }
            },
            result => {
              setAddressesStatus(result);
              setSelectedConnectionType(result.connectionType);
              setAddressStatusLoading(false);
            }
          )
        );
      }
    }
  }, [data]);

  // --------------------------------------------------------------------------
  // Next step click handler.
  // --------------------------------------------------------------------------
  const onNextStep = isSkip => {
    if (isSkip) standardEvents('Setup-Wizard-Dialog', 'Internet', 'Skip', googleAnalyticsClientCode);

    if (hasHomeLoan) {
      onChangeControlType(controlTypes.WizardHomeLoan, data);
    } else {
      onClose(true);
    }
  };

  // --------------------------------------------------------------------------
  // Next button click handler.
  // --------------------------------------------------------------------------
  const onNextButtonClick = () => {
    dispatch(
      submitEstimateInternetDefault(
        data.propertyAddress,
        data.selectedAgent,
        sessionActivity,
        {
          emailAddress: data.emailAddress,
          agentMemberName: data.agentMemberName,
          termsAndConditions: data.termsAndConditions,
          sendComparisonEmail: data.sendComparisonEmail,
          sendArticlesEmail: data.sendArticlesEmail,
          sendSolarInstallationEmail: data.sendSolarInstallationEmail
        },
        selectedCurrentSpend,
        addressStatus.techType,
        selectedConnectionType,
        addressStatus.developmentCharges,
        { showSpinner: true },
        onNextStep
      )
    );

    standardEvents('Setup-Wizard-Dialog', 'Internet', 'Submit', googleAnalyticsClientCode);
  };

  const rowClass = 'sm-dialog-row';
  const fieldClass = 'sm-dialog-field-name';

  return (
    <>
      <div className="start-heading mb-2">
        <div className="service-icons">
          <ServiceIcon serviceType={billTypes.INTERNET} />
        </div>
        <div className="heading-text">{billTypes.INTERNET.Name}</div>
      </div>
      <div className="intro-text">Next, please confirm your monthly broadband cost.</div>

      <AvForm onValidSubmit={onNextButtonClick}>
        {addressStatusLoading ? (
          <div className="loading mb-2">Loading...</div>
        ) : (
          displayNbnStatus && (
            <div
              className="info-message mb-2"
              dangerouslySetInnerHTML={{
                __html: addressStatus.message
              }}
            />
          )
        )}

        {!addressStatus.isAvailable && !addressStatusLoading && hasValidAddress(data.propertyAddress) && (
          <div className={rowClass}>
            <div className={`${fieldClass}`}>Connection type</div>
            <div>
              <AvField
                bsSize="sm"
                type="select"
                name="connectionType"
                placeholder=""
                value={selectedConnectionType}
                onChange={e => setSelectedConnectionType(e.target.value)}
              >
                {internetConnectionTypes.map((ref, i) => (
                  <option key={`connectionType-${ref.id}`} value={ref.id.toString()}>
                    {ref.description}
                  </option>
                ))}
              </AvField>
            </div>
          </div>
        )}

        {/* --- CURRENT SPEND ----------------------------------------------- */}
        <div className={`${rowClass}`}>
          <div className={`${fieldClass}`}>Current monthly spend</div>
          <div>
            <AvField
              className="input-small"
              bsSize="sm"
              type="text"
              maxlength="6" // max value: 999.99
              name="currentSpend"
              placeholder=""
              value={selectedCurrentSpend}
              onChange={e => setSelectedCurrentSpend(e.target.value ? parseFloat(e.target.value) : null)}
              validate={{
                pattern: {
                  value: '^[0-9]{1,3}(\\.[0-9]{1,2})?$',
                  errorMessage: `Please enter your current spend on broadband per month`
                },
                required: {
                  value: true,
                  errorMessage: `Please enter your current spend on broadband per month`
                }
              }}
            />
          </div>
        </div>

        <div className="wizard-footer">
          <div className="dialog-button">
            <Button className="sm-button-secondary medium">Next</Button>
          </div>
        </div>
      </AvForm>
    </>
  );
};

export default WizardInternet;
