let validateMedicareNumber = function(input, validateWithIrn) {
  if (!input) {
    return false;
  }

  var medicareNumber;
  var pattern;
  var length;
  var matches;
  var base;
  var checkDigit;
  var total;
  var multipliers;
  var isValid;

  pattern = /^(\d{8})(\d)/;
  medicareNumber = input.toString().replace(/ /g, '');
  length = validateWithIrn ? 11 : 10;

  if (medicareNumber.length === length) {
    matches = pattern.exec(medicareNumber);
    if (matches) {
      base = matches[1];
      checkDigit = matches[2];
      total = 0;
      multipliers = [1, 3, 7, 9, 1, 3, 7, 9];

      for (var i = 0; i < multipliers.length; i++) {
        total += base[i] * multipliers[i];
      }

      isValid = total % 10 === Number(checkDigit);
    } else {
      isValid = false;
    }
  } else {
    isValid = false;
  }

  return isValid;
};

export { validateMedicareNumber };
