import React from 'react';
import { useSelector } from 'react-redux';
import TermsAndConditionsDialog from '../dialogs/termsAndConditionsDialog';
import PrivacyPolicyDialog from '../dialogs/privacyPolicyDialog';
import { billTypes } from '../../enums/billTypes';

const AgreeTerms = ({ serviceType }) => {
  const { programName } = useSelector(state => state.config);

  const getButtonText = () => {
    if (serviceType && serviceType === billTypes.SOLAR_FINANCE)
      return `${serviceType.Name.toLowerCase()} terms and conditions`;

    return 'terms and conditions';
  };

  return (
    <>
      I confirm that I have read and agree to the {programName}{' '}
      <TermsAndConditionsDialog buttonText={getButtonText()} serviceType={serviceType} /> and{' '}
      <PrivacyPolicyDialog buttonText="privacy policy" />.
    </>
  );
};

export default AgreeTerms;
