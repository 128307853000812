import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { detailStatuses } from '../../enums/detailStatuses';

const CreditCheckFailedDialog = ({ onCancel, isOpen }) => {
  const { name, providerDisplayCode, detailsStatus } = useSelector(state => state.selectedOffer);

  const failedCreditCheckPhoneNumber = '1-844-350-9951';

  return (
    <Modal toggle={onCancel} isOpen={isOpen} size="lg" centered={true} backdrop={'static'}>
      <ModalBody className="qs-modal-body">
        <div>
          {detailsStatus === detailStatuses.UPFRONT_PAYMENT_REQUIRED ? (
            <>
              <h3>Your switch is almost complete...</h3>
              <p>
                Please call us on <strong>{failedCreditCheckPhoneNumber}</strong> to provide your payment details.
              </p>
              <p>
                Refer to energy plan{' '}
                <strong>
                  {name} - {providerDisplayCode}
                </strong>{' '}
                when speaking with our friendly support team.
              </p>
            </>
          ) : (
            <>
              <h3>Payment details required</h3>
              <p>
                Please contact us on <strong>{failedCreditCheckPhoneNumber}</strong> to discuss provider and plan
                options with one of our friendly team members.
              </p>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreditCheckFailedDialog;
