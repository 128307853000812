import { getCategory } from '../utilities/googleAnalyticsEvents';

export const navigationTypes = {
  NONE: { id: 0 },
  HOME: { id: 1, name: 'Home', pageName: 'home', path: '/' },
  DASHBOARD: { id: 2, name: 'Dashboard', pageName: 'dashboard', path: '/dashboard', hasTour: true },
  COMPARE: { id: 3, name: 'Compare', pageName: 'compare', path: '/compare', hasTour: true },
  SWITCH: { id: 4, name: 'Switch', pageName: 'switch', path: '/switch' },
  PREFERENCES: { id: 5, name: 'Preferences', pageName: 'preferences', path: '/preferences' },
  PARTNER: { id: 6, name: 'Partner', pageName: 'partner', path: '/partner' },
  ARTICLES: { id: 7, name: 'Articles', pageName: 'articles', path: '/articles' },
  ARTICLE: { id: 8, name: 'Article', pageName: 'article', path: '/article' },
  FAQ: { id: 9, name: 'FAQs', pageName: 'faq', path: '/faq' },
  PRIVACY: { id: 10, name: 'Privacy', pageName: 'privacy', path: '/privacy' },
  REGISTRATION: { id: 11, name: 'Registration', pageName: 'registration', path: '/registration' },
  START: { id: 12, name: 'Start', pageName: 'start', path: '/start' },
  TOUR: { id: 101, name: 'Tour' },

  getCurrentPage: () => {
    const pageName = getCategory().toLowerCase();

    if (pageName.startsWith('partner/')) return navigationTypes.PARTNER;
    if (pageName.startsWith('article/')) return navigationTypes.ARTICLE;

    switch (pageName) {
      case navigationTypes.HOME.pageName:
        return navigationTypes.HOME;
      case navigationTypes.DASHBOARD.pageName:
        return navigationTypes.DASHBOARD;
      case navigationTypes.START.pageName:
        return navigationTypes.START;
      case navigationTypes.COMPARE.pageName:
        return navigationTypes.COMPARE;
      case navigationTypes.SWITCH.pageName:
        return navigationTypes.SWITCH;
      case navigationTypes.ARTICLES.pageName:
        return navigationTypes.ARTICLES;
      case navigationTypes.FAQ.pageName:
        return navigationTypes.FAQ;
      case navigationTypes.PRIVACY.pageName:
        return navigationTypes.PRIVACY;
      case navigationTypes.REGISTRATION.pageName:
        return navigationTypes.REGISTRATION;
      case navigationTypes.PREFERENCES.pageName:
        return navigationTypes.PREFERENCES;

      default:
        return navigationTypes.NONE;
    }
  }
};
