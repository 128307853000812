import React from 'react';
import { Row, Col, Container } from 'reactstrap';

const OfferHomeLoanAu = ({ offerIndex, offer }) => {
  return (
    <>
      <Row className="sm-offer-home-loan-header">
        <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }} lg={{ size: 8, order: 1 }}>
          <Container style={{ margin: 0, padding: 0 }}>
            <div className="details-wrapper">
              <div className="offer-name">{offer.name}</div>
              <div className="offer-heading">
                This is our lowest repayment option from {offer.totalProviderProductCount} {offer.providerDisplayCode}{' '}
                loans
              </div>
              {offer.isCashBackBonusOffer && (
                <div className="cashback-offer">
                  <ul>
                    {offer.bonusOffer.split('\n\n').map((e, index) => (
                      <li key={`cashback_offer_${index}`}>{e}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Container>
        </Col>
        <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 2 }} lg={{ size: 4, order: 2 }} className="logo-wrapper">
          <Container>
            <Row className="logo-wrapper">
              <Col>
                <img src={offer.providerLogoUrl} alt={offer.providerDisplayCode} />
                {/* {offer.indicator === 'sponsor' && <div className="qs-sponsor">Sponsored</div>} */}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row className="sm-offer-home-loan-details">
        <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }} lg={{ size: 8, order: 1 }}>
          <Container style={{ margin: 0, padding: 0 }}>
            <div className="details-wrapper">
              <div className="rate-wrapper">
                <div className="rate">
                  <div className="rate-value">{offer.actualRate}%</div>
                  <div className="rate-label">RATE P.A.{offer.productPeriod && <div>{offer.productPeriod}</div>}</div>
                </div>
                <div className="rate">
                  <div className="rate-value">{offer.comparisonRate}%</div>
                  <div className="rate-label">
                    RATE P.A.<div>COMPARISON</div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Col>
        <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 2 }} lg={{ size: 4, order: 2 }} className="logo-wrapper">
          <Container>
            <div id={`offer_pricing_${offerIndex}`} className="summary-wrapper">
              <div className="repayment-wrapper">
                <div className="repayment">
                  <div className="repayment-value">{`$${offer.monthlyRepayment.toLocaleString()}`}</div>
                  <div className="repayment-label">
                    PER MONTH<div>Repayments</div>
                  </div>
                </div>
                {offer.savingAmountLowestAnnual > 0 && (
                  <div className="repayment">
                    <div className="repayment-value with-saving">
                      {`$${offer.savingAmountLowest.toLocaleString()}`}
                      <span className="disclaimer-symbol">†</span>
                    </div>
                    <div className="repayment-label">
                      PER MONTH<div>Repayment saving</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default OfferHomeLoanAu;
