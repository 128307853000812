import { implementationCodes } from './implementationCodes';

export const energyUsageTypes = {
  PEAK: {
    Id: 0,
    Name: 'General usage (Peak)'
  },
  OFF_PEAK: {
    Id: 1,
    Name: 'Off-peak'
  },
  CONTROLLED_LOAD_1: {
    Id: 2,
    Name: 'Controlled Load 1'
  },
  SHOULDER: {
    Id: 3,
    Name: 'Shoulder'
  },
  SOLAR_FEED_IN: {
    Id: 4,
    Name: 'Solar Feed-in'
  },
  CONTROLLED_LOAD_2: {
    Id: 5,
    Name: 'Controlled Load 2'
  },
  CONTROLLED_LOAD_2_OFF_PEAK: {
    Id: 6,
    Name: 'Controlled Load 2 Off-peak'
  },
  CONTROLLED_LOAD_2_SHOULDER: {
    Id: 7,
    Name: 'Controlled Load 2 Shoulder'
  },
  CONTROLLED_LOAD_1_OFF_PEAK: {
    Id: 8,
    Name: 'Controlled Load 1 Off-peak'
  },
  CONTROLLED_LOAD_1_SHOULDER: {
    Id: 9,
    Name: 'Controlled Load 1 Shoulder'
  },
  DEMAND: {
    Id: 10,
    Name: 'Demand'
  }
};

export const getEnergyUsageTypeById = id => {
  switch (id) {
    case energyUsageTypes.PEAK.Id:
      return energyUsageTypes.PEAK;
    case energyUsageTypes.OFF_PEAK.Id:
      return energyUsageTypes.OFF_PEAK;
    case energyUsageTypes.CONTROLLED_LOAD_1.Id:
      return energyUsageTypes.CONTROLLED_LOAD_1;
    case energyUsageTypes.SHOULDER.Id:
      return energyUsageTypes.SHOULDER;
    case energyUsageTypes.SOLAR_FEED_IN.Id:
      return energyUsageTypes.SOLAR_FEED_IN;
    case energyUsageTypes.CONTROLLED_LOAD_2.Id:
      return energyUsageTypes.CONTROLLED_LOAD_2;
    case energyUsageTypes.CONTROLLED_LOAD_2_OFF_PEAK.Id:
      return energyUsageTypes.CONTROLLED_LOAD_2_OFF_PEAK;
    case energyUsageTypes.CONTROLLED_LOAD_2_SHOULDER.Id:
      return energyUsageTypes.CONTROLLED_LOAD_2_SHOULDER;
    case energyUsageTypes.CONTROLLED_LOAD_1_OFF_PEAK.Id:
      return energyUsageTypes.CONTROLLED_LOAD_1_OFF_PEAK;
    case energyUsageTypes.CONTROLLED_LOAD_1_SHOULDER.Id:
      return energyUsageTypes.CONTROLLED_LOAD_1_SHOULDER;
    case energyUsageTypes.DEMAND.Id:
      return energyUsageTypes.DEMAND;
    default:
      return null;
  }
};

export const getEnergyUsageTypes = implementationCode => {
  switch (implementationCode) {
    case implementationCodes.ELECTRICITY.AU_DEFAULT:
      return [
        getEnergyUsageTypeById(energyUsageTypes.PEAK.Id),
        getEnergyUsageTypeById(energyUsageTypes.OFF_PEAK.Id),
        getEnergyUsageTypeById(energyUsageTypes.SHOULDER.Id),
        getEnergyUsageTypeById(energyUsageTypes.CONTROLLED_LOAD_1.Id),
        getEnergyUsageTypeById(energyUsageTypes.CONTROLLED_LOAD_1_OFF_PEAK.Id),
        getEnergyUsageTypeById(energyUsageTypes.CONTROLLED_LOAD_1_SHOULDER.Id),
        getEnergyUsageTypeById(energyUsageTypes.CONTROLLED_LOAD_2.Id),
        getEnergyUsageTypeById(energyUsageTypes.CONTROLLED_LOAD_2_OFF_PEAK.Id),
        getEnergyUsageTypeById(energyUsageTypes.CONTROLLED_LOAD_2_SHOULDER.Id),
        getEnergyUsageTypeById(energyUsageTypes.SOLAR_FEED_IN.Id)
      ];
    case implementationCodes.GAS.AU_DEFAULT:
      return [getEnergyUsageTypeById(energyUsageTypes.PEAK.Id), getEnergyUsageTypeById(energyUsageTypes.OFF_PEAK.Id)];
    case implementationCodes.ELECTRICITY.US_DEFAULT:
      return [getEnergyUsageTypeById(energyUsageTypes.PEAK.Id)];
    default:
      return [];
  }
};
