import React from 'react';

import Sheet from '../Sheet/Sheet';
import { ReactComponent as AddIcon } from '../icons/util/service-card-add.svg';

const ServiceCardAdd = () => {
  return (
    <div className="status-add">
      <Sheet className="status-add">
        <AddIcon width="64" height="64" />
      </Sheet>
    </div>
  );
};

export default ServiceCardAdd;
