import React from 'react';
import VerifyIdAu from '../common/verifyIdAu';

const IdVerificationSectionInternet = ({ confirmDetail, offer, onChange }) => {
  const productDetail = offer.productDetail;

  return (
    <>
      <div className="label">{`${productDetail.acceptSecondaryId ? 'Primary' : 'Verify'} ID`}</div>
      <div>
        <VerifyIdAu
          onChange={onChange}
          details={confirmDetail}
          captureSecondary={productDetail.acceptSecondaryId}
          acceptMedicare={productDetail.acceptMedicare}
          acceptPassport={true}
          acceptForeignPassport={productDetail.acceptForeignPassport}
          acceptMedicareSecondary={productDetail.acceptMedicareSecondary}
          acceptPassportSecondary={true}
          acceptForeignPassportSecondary={productDetail.acceptForeignPassportSecondary}
          acceptDateOfBirth={false}
        />
      </div>
    </>
  );
};

export default IdVerificationSectionInternet;
