import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function landingReducer(state = initialState.landing, action) {
  switch (action.type) {
    case types.SET_LANDING:
      return objectAssign({}, state, action.data);

    default:
      return state;
  }
}
