export const buttonDirection = {
  Previous: 'Previous',
  Next: 'Next'
};

export const buttonTypes = {
  NONE: { id: 0, name: 'none' },
  BUTTON: { id: 1, name: 'button' },
  LINK: { id: 2, name: 'link', cssClass: 'link-button' },
  ICON: { id: 3, name: 'icon' },
  TEXT: { id: 4, name: 'text' }
};

export const buttonIcons = {
  NONE: { id: 0, code: '' },
  HOME: { id: 1, code: 'home' },
  CLOUD_UPLOAD: { id: 2, code: 'cloud_upload' },
  REFRESH: { id: 3, code: 'refresh' }
};
