import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import createAuth0Client from '@auth0/auth0-spa-js';
import { verify, login } from '../actions/userActions';
import { authenticationTypes, accessKeyTypes } from '../enums/authenticationTypes';
import { getLocalStorage, setLocalStorage } from '../utilities/localStorageUtilities';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

const DEFAULT_REQUESTED_SCOPES = 'openid profile email';

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0Client] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [userScopes, setUserScopes] = useState('');

  var config = {};
  var content = {};
  var mobilePhoneNumber;

  const dispatch = useDispatch();

  useSelector(state => {
    config = state.config;
    content = state.content;
    mobilePhoneNumber = state.registration.mobilePhoneNumber || state.user.mobilePhoneNumber || undefined;
  });

  const { authenticationType } = useSelector(state => state.config.settings);
  const { accessId: userAccessId, accessKey: userAccessKey, accessToken: userAccessToken } = useSelector(
    state => state.user
  );
  const {
    accessId: comparisonAccessId,
    accessKey: comparisonAccessKey,
    accessTrustLevel: comparisonTrustLevel
  } = useSelector(state => state.comparison);

  const getConfig = async () => {
    const localStorageResult = getLocalStorage('auth0Settings');
    let auth0Settings = localStorageResult.value;

    if (!auth0Settings) {
      await axios.get(`/api/config/auth0/settings`).then(response => {
        if (response.data) {
          let settings = { ...response.data };
          auth0Settings = JSON.stringify(settings);
          setLocalStorage('auth0Settings', auth0Settings);
        }
      });
    }
    return JSON.parse(auth0Settings);
  };

  useEffect(() => {
    const initAuth0 = async () => {
      let auth0Settings = await getConfig();

      const defaultOptions = {
        scope: DEFAULT_REQUESTED_SCOPES,
        domain: auth0Settings.domain,
        client_id: auth0Settings.clientId,
        audience: auth0Settings.audience
      };

      const auth0FromHook = await createAuth0Client({ ...defaultOptions, ...initOptions });
      let loginAction;

      setAuth0Client(auth0FromHook);

      let isAuthenticated = false;

      if (window.location.search.includes('?code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();

        isAuthenticated = await auth0FromHook.isAuthenticated();

        if (isAuthenticated) {
          loginAction = verify;
        }

        onRedirectCallback(appState, '/');
      }

      isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        setUserRoles(user[auth0Settings.rolesElement]);
        setUserScopes(user.scope);

        let accessToken = await auth0FromHook.getTokenSilently();

        if (!loginAction) {
          loginAction = login;
        }

        dispatch(loginAction(accessToken));
      }

      setLoading(false);
    };
    initAuth0();

    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    let auth0Settings = await getConfig();

    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    const user = await auth0Client.getUser();

    setUser(user);
    setUserRoles(user[auth0Settings.rolesElement]);
    setUserScopes(user.scope);
    setIsAuthenticated(true);
  };

  const loginWithRedirect = async (params = {}) => {
    if (!loading) {
      auth0Client.options.themeLogo = `${config.applicationSettings.staticContentUrl}${content.general.logo}`;
      auth0Client.options.themeColor = content.cssVariables.primaryButtonBackgroundColor;
      auth0Client.options.loginHint = params.mobilePhoneNumber || mobilePhoneNumber;
      auth0Client.options.programName = config.programName;
      auth0Client.loginWithRedirect(params);
    }
  };

  const logout = async () => {
    let auth0Settings = await getConfig();

    auth0Client.logout({
      client_id: auth0Settings.clientId,
      returnTo: window.location.origin
    });
  };

  const handleRedirectCallback = async () => {
    let auth0Settings = await getConfig();

    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setIsAuthenticated(true);
    setUser(user);
    setUserRoles(user[auth0Settings.rolesElement]);
    setUserScopes(user.scope);
    setLoading(false);
  };

  const userHasRole = requiredRoles => {
    const requiredRolesIsArray = Array.isArray(requiredRoles);
    const userRolesIsArray = Array.isArray(userRoles);

    return (
      !requiredRoles ||
      (requiredRolesIsArray &&
        (requiredRoles.length === 0 || (userRolesIsArray && requiredRoles.some(role => userRoles.includes(role)))))
    );
  };

  const userHasScopes = requiredScopes => {
    const grantedScopes = (userScopes || '').split(' ');
    return (
      !requiredScopes || requiredScopes.length === 0 || requiredScopes.every(scope => grantedScopes.includes(scope))
    );
  };

  const getAccessToken = async () => {
    switch (authenticationType) {
      case authenticationTypes.CTM:
        return userAccessToken;

      default:
        return await auth0Client.getTokenSilently();
    }
  };

  const getAccessKey = () => {
    switch (authenticationType) {
      case authenticationTypes.DEFAULT:
        const accessId = userAccessId || comparisonAccessId;
        const accessKey = userAccessId ? userAccessKey : comparisonAccessKey;

        const keyType =
          userAccessId && userAccessKey
            ? accessKeyTypes.USER
              ? comparisonAccessId && comparisonAccessKey
              : accessKeyTypes.COMPARISON
            : accessKeyTypes.NONE;

        const trustLevel = keyType === accessKeyTypes.COMPARISON ? comparisonTrustLevel : null; // Only COMPARISON access keys have trustLevel returned at the moment.

        return keyType !== accessKeyTypes.NONE ? { key: accessKey, id: accessId, trustLevel, keyType } : null;

      default:
        return null;
    }
  };

  const isUserAuthenticated = () => {
    switch (authenticationType) {
      case authenticationTypes.CTM:
        return getAccessToken() ? true : false;

      default:
        return isAuthenticated;
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        userHasRole,
        userHasScopes,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        getAccessToken,
        getAccessKey,
        isUserAuthenticated,
        logout
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
