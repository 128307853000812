import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';

import { goHome } from '../actions/comparisonActions.js';
import { openSubmitDialog } from '../actions/dialogOptionsActions.js';

import { getAgentServiceType } from '../enums/customerCommissionTypes.js';
import { uploadBillTypes, uploadSubTypes } from '../enums/uploadBillTypes.js';
import { billTypes } from '../enums/billTypes.js';
import { homePageTypes, launchDialogModes, pageModes } from '../enums/launchTypes.js';

import { standardEvents } from '../utilities/googleAnalyticsEvents.js';
import { isFramed } from '../utilities/domUtilities.js';

import HeroInfo from '../components/start/heroInfo.js';
import HeroBanner from '../components/start/heroBanner.js';
import Introduction from '../components/start/introduction.js';
import Testimonial from '../components/home/testimonial.js';
import DialogManager from '../components/layout/dialogManager.js';
import BrandScroller from '../components/common/brandScroller.js';
import Insights from '../components/start/insights.js';
import Next from '../components/start/next.js';

const StartScreen = () => {
  const dispatch = useDispatch();

  const { serverMessage } = useSelector(state => state);
  const { googleAnalyticsClientCode, homePageType } = useSelector(state => state.config.settings);
  const { billTypes: agentBillTypes, isLoaded: configLoaded } = useSelector(state => state.config);
  const { pageMode } = useSelector(state => state.application);
  const { launchDialogMode } = useSelector(state => state.landing.home);
  const { testimonials } = useSelector(state => state.content.start);

  const testimonialItems = testimonials ? JSON.parse(testimonials) : [];

  const pageModeDefault = pageMode === pageModes.DEFAULT && !isFramed();
  const showTestimonials = pageModeDefault && testimonialItems && testimonialItems.length > 0;

  const isDefaultLaunchMode = launchDialogMode === launchDialogModes.DEFAULT;
  const isHomePageStart = homePageType === homePageTypes.START.id;

  // --------------------------------------------------------------------------
  // Start service from start button.
  // --------------------------------------------------------------------------
  const startService = () => {
    const serviceType = billTypes.SOLAR_FINANCE;

    standardEvents('Start-Screen', serviceType.Name, 'Open-Dialog', googleAnalyticsClientCode);

    const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
    const affiliate = agentServiceType ? agentServiceType.affiliate : null;
    const partner = agentServiceType ? agentServiceType.partner : null;

    const submissionId = null;
    const submission = null;
    const backButtonVisible = serviceType === billTypes.SOLAR_FINANCE ? false : true;
    const confirmButtonText = 'Submit';
    const uploadBillType = partner
      ? uploadBillTypes.PARTNER
      : affiliate
      ? uploadBillTypes.AFFILIATE
      : serviceType === billTypes.SOLAR_FINANCE
      ? uploadBillTypes.MANUAL
      : uploadBillTypes.NONE;

    const uploadSubType = uploadSubTypes.getSubType(serviceType, null);

    dispatch(
      openSubmitDialog(
        serviceType,
        uploadBillType,
        uploadSubType,
        {
          submissionId,
          submission,
          backButtonVisible,
          buttonText: confirmButtonText
        },
        serverMessage
      )
    );
  };

  // --------------------------------------------------------------------------
  // Page Initialization
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (configLoaded) {
      if (!isHomePageStart) {
        dispatch(goHome());
      }
    }
  }, [configLoaded, dispatch, isHomePageStart]);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>

      {configLoaded && isDefaultLaunchMode && isHomePageStart && (
        <div className="sm-main">
          <div className="sm-home-banner">
            <div className="title">Discover smart solar finance</div>
          </div>

          <Container className="sm-container narrow">
            <Introduction startService={startService} />

            <BrandScroller serviceType={billTypes.HOME_LOAN} scrollSpeed={6} />

            <div className="sm-start-hero">
              <HeroBanner />
              <HeroInfo />
            </div>

            <Insights />
            <Next startService={startService} />

            {showTestimonials && <Testimonial item={testimonialItems[0]} />}
          </Container>
        </div>
      )}

      <DialogManager />
    </>
  );
};

export default StartScreen;
