import React from 'react';
import { useSelector } from 'react-redux';

const PoweredBySmartMe = ({ noMargin, textMode }) => {
  const { poweredByLogo, poweredByText } = useSelector(state => state.config.settings);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const path = `${staticContentUrl}/images/agent/${poweredByLogo}`;

  return (
    <div className={`sm-powered-by-smart-me${noMargin ? ' no-margin' : ''}`}>
      {poweredByLogo && !textMode ? (
        <img className="logo-mode" src={path} alt="Powered by" />
      ) : (
        <div className="text-mode">
          {poweredByText ? (
            poweredByText
          ) : (
            <>
              Powered by <span className="smart">SMART</span>
              <span className="me">ME</span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PoweredBySmartMe;
