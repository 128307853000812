import React from 'react';
import ServiceCardHead from './serviceCardHead';
import ServiceIcon from './serviceIcon';

const ServiceBarButton = ({
  service: {
    id,
    serviceType,
    hasNextCompareDate,
    isEmpty,
    isAdd,
    notificationCount,
    reductionLoaded,
    reductionAmountAnnual
  },
  iconMode,
  onServiceStart
}) => {
  const getStatusCode = () => {
    if (hasNextCompareDate) return 'linked';
    if (isEmpty) return 'empty';
    if (isAdd) return 'add';
    if (!reductionLoaded) return null;

    return 'unlinked';
  };

  const className = iconMode
    ? 'service-bar-button animated fadeInUp'
    : `service-card compact animated fadeIn status-${getStatusCode()} service-type-${serviceType.Tag} ${
        reductionAmountAnnual > 0 ? 'has-saving' : ''
      }`;

  const savings =
    reductionAmountAnnual > 0 ? (
      <div className="savings">{`$${reductionAmountAnnual.toLocaleString()}`}</div>
    ) : (
      <span>&nbsp;</span>
    );

  return (
    <div id={id} className={className} onClick={() => onServiceStart(serviceType, isEmpty)}>
      {iconMode ? (
        <div key={`serviceCardHead_${serviceType.submissionId}`} className="service-card-head icon-mode">
          <span className="type-icon-wrap">
            <ServiceIcon isAdd={isAdd} serviceType={serviceType} />
            {notificationCount > 0 && <span className="alert-bubble">{notificationCount}</span>}
          </span>
        </div>
      ) : (
        <ServiceCardHead
          isCompact={true}
          title={serviceType.Name}
          notificationCount={notificationCount}
          serviceType={serviceType}
          isAdd={isAdd}
          savings={savings}
        />
      )}
    </div>
  );
};

export default ServiceBarButton;
