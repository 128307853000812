import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Tour from 'reactour';
import { navigationTypes } from '../../enums/navigationTypes';
import { navigationOptions } from '../../actions/globalOptionsActions';
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorageUtilities';

const TourGuide = ({ tourId, steps, requestStart, requestClose, onClose }) => {
  const dispatch = useDispatch();

  const { primaryBackgroundColor } = useSelector(state => state.content.cssVariables);
  const { count: ajaxCount } = useSelector(state => state.ajaxCall);
  const { event: navigationEvent } = useSelector(state => state.globalOptions.navigation);

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (requestStart && steps.length > 0 && ajaxCount === 0) {
      let forceStart = false;

      if (navigationEvent === navigationTypes.TOUR) {
        forceStart = true;
        dispatch(navigationOptions(navigationTypes.NONE));
      }

      if (trackTour() || forceStart) {
        setIsTourOpen(true);
      }
    } else {
      setIsTourOpen(false);
    }
  }, [requestStart, ajaxCount]);

  const trackTour = () => {
    const localStorageResult = getLocalStorage('tourTracker');
    const item = localStorageResult.value;
    const tracker = item ? JSON.parse(item) : [];

    const index = tracker.findIndex(e => e.id === tourId);
    const trackedItem = { id: tourId, seen: true };
    let showTour = false;

    if (index === -1) {
      tracker.push(trackedItem);
      showTour = true;
    } else {
      tracker[index] = trackedItem;
    }

    setLocalStorage('tourTracker', JSON.stringify(tracker));

    return showTour;
  };

  return (
    <>
      <Tour
        className="sm-tour"
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={requestClose}
        badgeContent={(step, total) => `${step} of ${total}`}
        prevButton={
          <div className={`sm-button-secondary medium btn${currentIndex === 0 ? ' disabled static' : ''}`}>Back</div>
        }
        nextButton={<div className="sm-button-secondary medium btn">Next</div>}
        lastStepNextButton={<div className="sm-button-secondary medium btn">Done</div>}
        getCurrentStep={index => setCurrentIndex(index)}
        startAt={0}
        rounded={15}
        disableDotsNavigation={true}
        disableInteraction={true}
        showNavigation={false}
        showNavigationNumber={false}
        //disableFocusLock={true}
        maskSpace={10}
        accentColor={primaryBackgroundColor}
        children={<div className="mb-3"></div>} // adding space on step header
        scrollDuration={500} // need to set this always, even on first step, so page has finished transitioning; otherwise subsquent tour runs will not focus first step element correctly
        onAfterOpen={() => {
          document.body.style.overflowY = 'hidden';
          if (!isMobile) document.body.style.paddingRight = '17px';
        }}
        onBeforeClose={() => {
          document.body.style.overflowY = ''; // need to remove property altogether rather than set overflowY to 'auto' so modal dialogs opened later don't scroll parent
          if (!isMobile) document.body.style.paddingRight = '';
          if (onClose) onClose();
        }}
      />
    </>
  );
};

export default TourGuide;
