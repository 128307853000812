import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button, Carousel, CarouselItem } from 'reactstrap';

import { implementationCodes } from '../../enums/implementationCodes';
import { getAgentServiceType, customerCommissionTypes } from '../../enums/customerCommissionTypes';
import { billTypes } from '../../enums/billTypes';
import { countryCodes } from '../../enums/countryCodes';
import { buttonDirection } from '../../enums/buttonTypes';

import { standardEvents } from '../../utilities/googleAnalyticsEvents';

import OfferMarketingDisplay from './offerMarketingDisplay';

const OfferMarketing = ({ onClose, startIndex, isSample, serviceType }) => {
  const { name: countryName, alpha3: countryAlpha3 } = useSelector(state => state.config.country);

  const carouselAnimationClasses = isSample ? null : 'animated delay-2s headShake slow repeat-10';

  const [nextCarouselAnimateClass, setNextCarouselAnimateClass] = useState(carouselAnimationClasses);
  const [activeIndex, setActiveIndex] = useState(startIndex ? startIndex : 0);
  const [animating, setAnimating] = useState(false);

  const {
    offerMarketingSlide1HeadingBillSaving,
    offerMarketingSlide1HeadingBillNoSaving,
    offerMarketingSlide2HeadingBill,
    offerMarketingSlide2ContentBill,
    offerMarketingSlide2HeadingSwitch,
    offerMarketingSlide2ContentSwitch,
    offerMarketingSlide2HeadingMoveIn,
    offerMarketingSlide2ContentMoveIn,
    offerMarketingSlide4HeadingSwitch,
    offerMarketingSlide4ContentSwitch,
    offerMarketingSlide4HeadingMoveIn,
    offerMarketingSlide4ContentMoveIn,
    offerMarketingSlideAspirationalImage,
    offerMarketingSlideAboveButtonsTextMoveIn,
    offerMarketingSlideAboveButtonsTextSwitch,
    postcodeLabel
  } = useSelector(state => state.content.general);

  const { logo } = useSelector(state => state.content.images);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { programName, panelProviders, billTypes: agentBillTypes } = useSelector(state => state.config);
  const { comparison } = useSelector(state => state);

  const isBill = comparison.isBill;

  const sampleOffer = {
    hasSavingLowest: true,
    percentageBillReductionAmountGuaranteedDisplayShort: '16%',
    billAmountLowestDisplay: '$57.92',
    isBill: true
  };

  const sampleSubmission = { billDays: 94, energyTypeDisplay: 'kWh' };
  const sampleButtonAddress = '10 Sample Street';

  const bestOffer = isSample ? sampleOffer : comparison.offers[0];
  const hasSavingLowest = bestOffer && bestOffer.hasSavingLowest;
  const { occupancyTypeIsNew, propertyAddress, supplyAddress } = comparison.submission;

  const getButtonText = () => {
    if (isSample) return `View deals for ${sampleButtonAddress}`;
    if (isBill) return `View deals for ${supplyAddress.displayAddressSimple}`;

    switch (comparison.implementationCode) {
      case implementationCodes.ELECTRICITY.AU_DEFAULT:
        return `View deals for ${propertyAddress.suburb}`;

      case implementationCodes.GAS.AU_DEFAULT:
        return `View deals for ${propertyAddress.suburb}`;

      case implementationCodes.ELECTRICITY.US_DEFAULT:
        return `View deals for ${propertyAddress.postcode}`;

      case implementationCodes.HOME_LOAN.AU_DEFAULT:
        return propertyAddress.suburb ? `View deals for ${propertyAddress.suburb}` : 'View deals';

      default:
        return 'View deals';
    }
  };

  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
  const commissionType = agentServiceType.commissionType;
  const agentBillType = agentServiceType.agentBillType;

  const offerContent = (
    <OfferMarketingDisplay
      bestOffer={isSample ? sampleOffer : bestOffer}
      submission={isSample ? sampleSubmission : comparison.submission}
      implementationCode={isSample ? implementationCodes.ELECTRICITY.AU_DEFAULT : comparison.implementationCode}
      mode="OfferMarketing"
    />
  );

  const getOurRetailerPanel = () => {
    let gridSize = serviceType === billTypes.INTERNET ? 4 : 3;
    let containerClassName = `qs-marketing-info-retailer-panel-${gridSize}`;

    return (
      <>
        <Container className={containerClassName}>
          <Row>
            {panelProviders
              .filter(p => p.provider.billTypes.filter(bt => bt.id === serviceType.Id).length > 0)
              .slice(0, 16)
              .map(a => {
                return (
                  <Col xs={gridSize} key={a.provider.displayCode}>
                    <img src={`${staticContentUrl}/images/providers/${a.provider.logo}`} alt={a.provider.displayCode} />
                  </Col>
                );
              })}
          </Row>
        </Container>
      </>
    );
  };

  const closeDialog = () => {
    if (onClose) {
      onClose();
    }

    standardEvents('marketing-dialog', 'Click', `View-Deals`, googleAnalyticsClientCode);
  };

  const whySwitchWithUs = {
    backgroundImage: 'why-switch-with-us-background.png',
    heading: isBill
      ? offerMarketingSlide2HeadingBill
      : occupancyTypeIsNew
      ? offerMarketingSlide2HeadingMoveIn
      : offerMarketingSlide2HeadingSwitch,
    headingClass: 'qs-marketing-info-heading',
    content: isBill
      ? offerMarketingSlide2ContentBill
      : occupancyTypeIsNew
      ? offerMarketingSlide2ContentMoveIn
      : offerMarketingSlide2ContentSwitch,
    displayLogo: true
  };

  const neverCompareAgain = {
    backgroundImage: 'never-compare-again-background.png',
    heading: `We compare ${countryName}${countryAlpha3 === countryCodes.AUSTRALIA ? "'s" : ''} biggest retailers`,
    headingClass: 'qs-marketing-info-heading qs-marketing-info-heading-retailer-panel',
    content: getOurRetailerPanel(),
    displayLogo: false
  };

  const energyItems = [
    {
      backgroundImage: 'start-paying-less-background-2.png',
      heading: isSample
        ? 'You could have paid'
        : isBill
        ? hasSavingLowest
          ? offerMarketingSlide1HeadingBillSaving
          : offerMarketingSlide1HeadingBillNoSaving
        : comparison.implementationCode === implementationCodes.ELECTRICITY.US_DEFAULT
        ? `Our best deal for ${postcodeLabel} ${propertyAddress.postcode}`
        : `${propertyAddress.suburb} deal`,
      headingClass: 'qs-marketing-info-heading',
      content: offerContent,
      displayLogo: false
    },
    whySwitchWithUs,
    neverCompareAgain,
    {
      backgroundImage: 'what-happens-next-background.png',
      heading: occupancyTypeIsNew ? offerMarketingSlide4HeadingMoveIn : offerMarketingSlide4HeadingSwitch,
      headingClass: 'qs-marketing-info-heading',
      content: occupancyTypeIsNew ? offerMarketingSlide4ContentMoveIn : offerMarketingSlide4ContentSwitch,
      displayLogo: false
    }
  ];

  const getInternetItems = () => {
    const items = [];

    items.push(
      {
        backgroundImage: 'start-paying-less-background-2.png',
        heading: isSample
          ? 'You could have paid'
          : isBill
          ? hasSavingLowest
            ? offerMarketingSlide1HeadingBillSaving
            : offerMarketingSlide1HeadingBillNoSaving
          : `${propertyAddress.suburb} deal`,
        headingClass: 'qs-marketing-info-heading',
        content: offerContent,
        displayLogo: false
      },
      whySwitchWithUs,
      neverCompareAgain
      // {
      //   backgroundImage: 'what-happens-next-background.png',
      //   heading: occupancyTypeIsNew ? offerMarketingSlide4HeadingMoveIn : offerMarketingSlide4HeadingSwitch,
      //   headingClass: 'qs-marketing-info-heading',
      //   content: occupancyTypeIsNew ? '' : '',
      //   displayLogo: false
      // }
    );

    return items;
  };

  const getHomeLoanItems = () => {
    const hasCashback = isSample || (comparison.offers && comparison.offers.find(e => e.isCashBackBonusOffer === true));
    const isRefinance = isSample || comparison.submission.refinance;

    const items = [];

    if (isRefinance && hasCashback) {
      items.push({
        backgroundImage: 'start-paying-less-background-2.png',
        heading: 'Get up to $3,000 cashback!',
        headingClass: 'qs-marketing-info-heading',
        content:
          'Talk to one of our experts today about the current cashback offers we have available when you reduce your monthly repayments.',
        displayLogo: false
      });
    }

    items.push({
      backgroundImage: 'why-switch-with-us-background.png',
      heading: isRefinance ? 'Faster loans' : 'Submit your loan faster',
      headingClass: 'qs-marketing-info-heading',
      content:
        "Don't risk waiting months for a bank to process your loan application.  Our expert brokers can often shortcut the lines to submit your loan faster.",
      displayLogo: true
    });

    if (!isRefinance) {
      items.push({
        backgroundImage: 'start-paying-less-background-2.png',
        heading: 'Going to auction?',
        headingClass: 'qs-marketing-info-heading',
        content:
          'Our brokers know the current application SLAs for each lender, meaning they can help ensure your loan can be secured before the auction date of your dream property.',
        displayLogo: false
      });
    }

    items.push(neverCompareAgain);

    items.push({
      backgroundImage: 'what-happens-next-background.png',
      heading: 'What happens next',
      headingClass: 'qs-marketing-info-heading',
      content:
        'Our brokers make the complex, simple. They’ll help you find the loan best loan suited to your specific needs.  If you choose to proceed, they’ll engage the lender to make the application seamless and enjoyable for you.',
      displayLogo: false
    });

    return items;
  };

  const getItems = () => {
    switch (serviceType) {
      case billTypes.ELECTRICITY:
        return energyItems;
      case billTypes.GAS:
        return energyItems;
      case billTypes.HOME_LOAN:
        return getHomeLoanItems();
      case billTypes.INTERNET:
        return getInternetItems();

      default:
        return [];
    }
  };

  const onNext = () => {
    if (animating || isSample) return;

    const nextIndex = activeIndex === getItems().length - 1 ? 0 : activeIndex + 1;
    setTheNewIndex(nextIndex, 2);
  };

  const onPrevious = () => {
    if (animating || isSample) return;

    const nextIndex = activeIndex === 0 ? getItems().length - 1 : activeIndex - 1;
    setTheNewIndex(nextIndex, 3);
  };

  const setTheNewIndex = (newIndex, type) => {
    setActiveIndex(newIndex);
    resetNextCarouselAnimation();
    standardEvents(
      'marketing-dialog',
      `${type === 1 ? 'button' : type === 2 ? 'next' : 'prev'}`,
      `slide-${newIndex + 1}`,
      googleAnalyticsClientCode
    );
  };

  const resetNextCarouselAnimation = () => {
    setNextCarouselAnimateClass('');

    setTimeout(() => {
      setNextCarouselAnimateClass(carouselAnimationClasses);
    }, 500);
  };

  const slides = getItems().map((item, index) => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={`cItem-${index}`}>
        <div
          style={{ backgroundImage: `url(${staticContentUrl}/images/misc/${item.backgroundImage})` }}
          className="qs-marketing-offer-item"
        >
          {item.displayLogo && (
            <div className="qs-marketing-info-logo">
              <img src={`${staticContentUrl}${logo}`} alt={programName} />
            </div>
          )}

          <div
            className={item.headingClass}
            dangerouslySetInnerHTML={{
              __html: item.heading
            }}
          />

          {typeof item.content === 'object' && item.content !== null ? (
            item.content
          ) : (
            <div
              className="qs-marketing-info-content"
              dangerouslySetInnerHTML={{
                __html: item.content
              }}
            />
          )}
        </div>
      </CarouselItem>
    );
  });

  const ArrowButton = ({ direction }) => {
    const next = direction === buttonDirection.Next;
    const cssClass = `${
      next
        ? `carousel-control-next${nextCarouselAnimateClass ? ` ${nextCarouselAnimateClass}` : ''}`
        : 'carousel-control-prev'
    }${isSample ? ' asSample' : ''}`;

    return (
      <div className={cssClass} onClick={next ? onNext : onPrevious}>
        <span className="material-icons" aria-hidden="true">
          {next ? 'arrow_forward_ios' : 'arrow_back_ios_new'}
        </span>
        <span className="sr-only">{next ? buttonDirection.Next : buttonDirection.Previous}</span>
      </div>
    );
  };

  const getCommissionText = () => {
    let earnText;

    switch (commissionType) {
      case customerCommissionTypes.FLYBUYS:
      case customerCommissionTypes.VELOCITY:
        earnText = `Get ${agentBillType.customerCommission.toLocaleString()} ${commissionType.NamePlural}`;
        break;

      default:
        earnText = `Get a $${agentBillType.customerCommission.toLocaleString()} gift card`;
    }

    return `${earnText} when you ${occupancyTypeIsNew ? 'connect' : 'switch'} today.`;
  };

  return (
    <div className="qs-offer-marketing">
      <Carousel interval={false} activeIndex={activeIndex} next={onNext} previous={onPrevious}>
        {slides}
        <ArrowButton direction={buttonDirection.Previous} />
        <ArrowButton direction={buttonDirection.Next} />
      </Carousel>

      <div className="qs-marketing-offer-aspiration">
        <img
          src={`${staticContentUrl}${
            agentServiceType.hasCommission ? agentBillType.customerCommissionLogo : offerMarketingSlideAspirationalImage
          }`}
          alt="a"
        />
      </div>

      {((offerMarketingSlideAboveButtonsTextMoveIn && offerMarketingSlideAboveButtonsTextSwitch) ||
        agentServiceType.hasCommission) && (
        <div
          className="qs-marketing-offer-above-buttons-text"
          dangerouslySetInnerHTML={{
            __html: agentServiceType.hasCommission
              ? getCommissionText()
              : occupancyTypeIsNew
              ? offerMarketingSlideAboveButtonsTextMoveIn
              : offerMarketingSlideAboveButtonsTextSwitch
          }}
        />
      )}

      <Container className="qs-marketing-buttons-area">
        <Row>
          <Col xs={{ size: '10', offset: '1' }}>
            <Button
              disabled={isSample}
              onClick={isSample ? undefined : closeDialog}
              className={`${
                isSample
                  ? 'sm-button-primary'
                  : 'btn btn-secondary qs-marketing-offer-button-on animated infinite delay-2s pulse slow'
              }`}
            >
              <div className="qs-marketing-button-address">{getButtonText()}</div>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default OfferMarketing;
