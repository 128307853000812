import React from 'react';
import { useSelector } from 'react-redux';

import ServicesAuto from '../serviceCard/servicesAuto';

const CommonSwitchSummary = ({ serviceType, otherServices, showWhatHappensNext, showDisconnectionNotice }) => {
  const { whatHappensNext: whatHappensNextOffer } = useSelector(state => state.selectedOffer);
  const { whatHappensNextContent: whatHappensNextAutoConvert } = useSelector(state => state.comparison);

  const whatHappensNext = whatHappensNextOffer ?? whatHappensNextAutoConvert;

  const showWhatHappensNextHeading =
    showWhatHappensNext && whatHappensNext && whatHappensNext.toLowerCase().indexOf('what happens next') === -1;

  const hasWhatHappensNext = showWhatHappensNext && whatHappensNext;

  return (
    <>
      {otherServices ? (
        <div className="other-services">
          <ServicesAuto
            serviceType={serviceType}
            services={otherServices}
            heading="To save you time, we've already found our best offers for these services"
            // heading="There's no need to enter your connection details again. To save you time, we've already compared our best offers for the following services and pre-filled the application forms for you."
          />
        </div>
      ) : (
        <>{(hasWhatHappensNext || showDisconnectionNotice) && <div className="sm-div-hr" />}</>
      )}

      {hasWhatHappensNext && (
        <div className="what-happens-next">
          {showWhatHappensNextHeading && <div className="heading">What happens next?</div>}
          <div
            className="detail"
            dangerouslySetInnerHTML={{
              __html: whatHappensNext
            }}
          />
        </div>
      )}

      {showDisconnectionNotice && (
        <div className="sm-disconnection-notice">
          <i className="material-icons sm-hint-tips-icon">tips_and_updates</i>
          If you're moving from an existing property, please ensure you have contacted your existing retailer to arrange
          a disconnection of your existing services.
        </div>
      )}
    </>
  );
};

export default CommonSwitchSummary;
