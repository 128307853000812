import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button, Collapse, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import QsCheckBox from '../common/qsCheckBox';
import QsButtonGroup from '../common/qsButtonGroup';

const CommunicationPreferences = props => {
  const { updatePreferences } = props;

  const { mobilePhoneNumber, smsAuthenticationMessagingEnabled, smsGeneralMessagingEnabled } = useSelector(
    state => state.user
  );

  const { smsAuthenticationMobileNumber, smsGeneralMobileNumber } = useSelector(
    state => state.config.applicationSettings
  );

  const { smsEnabled } = useSelector(state => state.config.settings);

  const {
    marketingEnabledEmail,
    betterOffersEnabledEmail,
    surveyEnabledEmail,
    newsEnabledEmail,
    marketingEnabledSms,
    betterOffersEnabledSms,
    newsEnabledSms,
    surveyEnabledSms,
    betterOfferPercentageMinimum
  } = useSelector(state => state.user.preferences.communication);

  const [marketingEmail, setMarketingEmail] = useState(marketingEnabledEmail);
  const [betterOffersEmail, setBetterOffersEmail] = useState(betterOffersEnabledEmail);
  const [surveyEmail, setSurveyEmail] = useState(surveyEnabledEmail);
  const [newsEmail, setNewsEmail] = useState(newsEnabledEmail);
  const [marketingSms, setMarketingSms] = useState(smsEnabled ? marketingEnabledSms : null);
  const [betterOffersSms, setBetterOffersSms] = useState(smsEnabled ? betterOffersEnabledSms : null);
  const [newsSms, setNewsSms] = useState(smsEnabled ? newsEnabledSms : null);
  const [surveySms, setSurveySms] = useState(smsEnabled ? surveyEnabledSms : null);
  const [betterOfferPercentage, setBetterOfferPercentage] = useState(betterOfferPercentageMinimum);
  const [smsInfoOpen, setSmsInfoOpen] = useState(false);

  const setButtonGroup = (event, setter) => {
    let option = event.target.getAttribute('data-value');
    setter(Number(option));
  };

  const onSave = async () => {
    let data = {
      communication: {
        marketingEnabledEmail: marketingEmail,
        betterOffersEnabledEmail: betterOffersEmail,
        newsEnabledEmail: newsEmail,
        surveyEnabledEmail: surveyEmail,
        betterOfferPercentageMinimum: betterOfferPercentage
      }
    };

    if (smsEnabled) {
      data.communication.marketingEnabledSms = marketingSms;
      data.communication.betterOffersEnabledSms = betterOffersSms;
      data.communication.newsEnabledSms = newsSms;
      data.communication.surveyEnabledSms = surveySms;
    }

    updatePreferences(data);
  };

  return (
    <>
      <Container>
        <div className="qs-preferences-content-heading">Set up Communication Preferences</div>
        <div className="qs-preferences-content-intro">
          <p>Stay informed of offers that have savings for your specific usage via email and / or SMS.</p>
        </div>
        <hr />
        <Row>
          <Col>
            <AvForm onValidSubmit={event => onSave(event)}>
              <Row>
                <Col className="qs-communication-preferences-label" xs="5"></Col>
                <Col lg="1" md="2" xs="2">
                  <div
                    className={`${
                      smsEnabled && (!smsGeneralMessagingEnabled || !smsAuthenticationMessagingEnabled)
                        ? 'qs-preferences-column-heading-email'
                        : ''
                    }`}
                  >
                    Email
                  </div>
                </Col>
                {smsEnabled && (
                  <Col lg="1" md="2" xs="2">
                    <div className="qs-communication-preferences-column-heading-sms">
                      <nobr>
                        SMS{' '}
                        {(!smsGeneralMessagingEnabled || !smsAuthenticationMessagingEnabled) && (
                          <>
                            <i
                              id={'smsInfo'}
                              className="material-icons qs-info"
                              onClick={() => setSmsInfoOpen(!smsInfoOpen)}
                            >
                              info
                            </i>
                            <Popover
                              placement="auto"
                              isOpen={smsInfoOpen}
                              target={'smsInfo'}
                              onClick={() => setSmsInfoOpen(!smsInfoOpen)}
                            >
                              <PopoverHeader>
                                SMS Settings<i className="material-icons qs-close">clear</i>
                              </PopoverHeader>
                              <PopoverBody>
                                {!smsGeneralMessagingEnabled && (
                                  <div className="qs-mt-sm qs-mb-sm">
                                    You are currently unsubscribed to SMS notifications. SMS "start" from{' '}
                                    {mobilePhoneNumber} to {smsGeneralMobileNumber} to enable SMS preferences.
                                  </div>
                                )}
                                {!smsAuthenticationMessagingEnabled && (
                                  <div className="qs-mt-sm qs-mb-sm">
                                    You are currently unsubscribed to SMS authentication. You will not be able to log in
                                    via SMS codes. SMS "start" from {mobilePhoneNumber} to{' '}
                                    {smsAuthenticationMobileNumber} to enable authentication.
                                  </div>
                                )}
                              </PopoverBody>
                            </Popover>
                          </>
                        )}
                      </nobr>
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col className="qs-communication-preferences-label" xs="5">
                  General Marketing
                </Col>
                <Col lg="1" md="2" xs="2">
                  <QsCheckBox isChecked={marketingEmail} onCheck={() => setMarketingEmail(!marketingEmail)} />
                </Col>
                {smsEnabled && (
                  <Col lg="1" md="2" xs="2">
                    <QsCheckBox
                      enabled={smsGeneralMessagingEnabled}
                      isChecked={marketingSms}
                      onCheck={smsGeneralMessagingEnabled ? () => setMarketingSms(!marketingSms) : undefined}
                    />
                  </Col>
                )}
              </Row>

              <Row>
                <Col className="qs-communication-preferences-label" xs="5">
                  News & Ideas
                </Col>
                <Col lg="1" md="2" xs="2">
                  <QsCheckBox isChecked={newsEmail} onCheck={() => setNewsEmail(!newsEmail)} />
                </Col>
                {smsEnabled && (
                  <Col lg="1" md="2" xs="2">
                    <QsCheckBox
                      enabled={smsGeneralMessagingEnabled}
                      isChecked={newsSms}
                      onCheck={smsGeneralMessagingEnabled ? () => setNewsSms(!newsSms) : undefined}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col className="qs-communication-preferences-label" xs="5">
                  Better Offers
                </Col>
                <Col lg="1" md="2" xs="2">
                  <QsCheckBox isChecked={betterOffersEmail} onCheck={() => setBetterOffersEmail(!betterOffersEmail)} />
                </Col>
                {smsEnabled && (
                  <Col lg="1" md="2" xs="2">
                    <QsCheckBox
                      enabled={smsGeneralMessagingEnabled}
                      isChecked={betterOffersSms}
                      onCheck={smsGeneralMessagingEnabled ? () => setBetterOffersSms(!betterOffersSms) : undefined}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col className="qs-communication-preferences-label" xs="5">
                  Surveys
                </Col>
                <Col lg="1" md="2" xs="2">
                  <QsCheckBox isChecked={surveyEmail} onCheck={() => setSurveyEmail(!surveyEmail)} />
                </Col>
                {smsEnabled && (
                  <Col lg="1" md="2" xs="2">
                    <QsCheckBox
                      enabled={smsGeneralMessagingEnabled}
                      isChecked={surveySms}
                      onCheck={smsGeneralMessagingEnabled ? () => setSurveySms(!surveySms) : undefined}
                    />
                  </Col>
                )}
              </Row>
              <Collapse isOpen={betterOffersEmail || betterOffersSms}>
                <Row className="qs-communication-preferences-group-button">
                  <Col>
                    <div className="qs-communication-preferences-better-offers-text qs-mb-sm">
                      Notify me when there are savings of at least...
                    </div>
                    <QsButtonGroup
                      required={false}
                      id="betterOfferPercentage"
                      fieldName="Better Offer Percentage"
                      selectedValue={betterOfferPercentage}
                      onTouchButton={e => setButtonGroup(e, setBetterOfferPercentage)}
                      buttons={[
                        {
                          label: '1%',
                          value: 1
                        },
                        {
                          label: '5%',
                          value: 5
                        },
                        {
                          label: '10%',
                          value: 10
                        }
                      ]}
                    />
                  </Col>
                </Row>
              </Collapse>

              <Row className="qs-mt-lg">
                <Col sm="12" md={{ size: 4, offset: 4 }}>
                  <Button className="qs-button-submit">Save</Button>
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CommunicationPreferences;
