import React from 'react';

const YourOrderInternet = props => {
  const { productDetail, confirmDetail } = props;

  const getYourOrderSingleAddon = (addons, selection) => {
    if (!selection || !addons) return null;

    let addon = addons.find(addon => addon.id === selection);

    return (
      <tr>
        <td>{addon.name}</td>
        <td>{addon.priceType}</td>
        <td>{addon.priceDisplay}</td>
      </tr>
    );
  };

  const getYourOrderMultiAddon = (addons, selections) => {
    if (!selections || !addons) return null;

    return selections.map((selection, index) => getYourOrderSingleAddon(addons, selection));
  };

  const getCost = (type, planCosts) => {
    let cost = planCosts
      .filter(cost => cost.priceType === type)
      .map(cost => cost.price)
      .reduce((prev, curr) => prev + curr, 0);

    cost = cost + getSingleAddonCost(type, productDetail.homeLine, confirmDetail.homeLine);
    cost = cost + getSingleAddonCost(type, productDetail.modems, confirmDetail.modem);
    cost = cost + getMultiAddonCost(type, productDetail.otherAddons, confirmDetail.otherAddons);

    return cost.toLocaleString('en-AU', { style: 'currency', currency: 'AUD', minimumFractionDigits: 2 });
  };

  const getSingleAddonCost = (type, addons, selection) => {
    if (!selection || !addons) return null;

    let addon = addons.find(addon => addon.id === selection && type === addon.priceType);

    return addon ? addon.price : 0;
  };

  const getMultiAddonCost = (type, addons, selections) => {
    if (!selections || !addons) return null;

    return selections
      .map((selection, index) => getSingleAddonCost(type, addons, selection))
      .reduce((prev, curr) => prev + curr, 0);
  };

  return (
    <>
      <div className="qs-your-order">
        <div className="qs-offer-internet-addon-title">Your order</div>
        <div className="qs-offer-internet-your-order">
          <table>
            <tbody>
              {productDetail.planCost.map((cost, index) => (
                <tr>
                  <td>{cost.name}</td>
                  <td>{cost.priceType}</td>
                  <td>{cost.priceDisplay}</td>
                </tr>
              ))}
              {getYourOrderSingleAddon(productDetail.homeLine, confirmDetail.homeLine)}
              {getYourOrderSingleAddon(productDetail.modems, confirmDetail.modem)}
              {getYourOrderMultiAddon(productDetail.otherAddons, confirmDetail.otherAddons)}
            </tbody>
            <tfoot>
              <tr>
                <th>Monthly cost</th>
                <th>&nbsp;</th>
                <th>{getCost('month', productDetail.planCost)}</th>
              </tr>
              <tr>
                <th>Upfront cost</th>
                <th>&nbsp;</th>
                <th>{getCost('upfront', productDetail.planCost)}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default YourOrderInternet;
