import axios from 'axios';
import { push } from 'connected-react-router';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import {
  generalServerMessage,
  setServerMessage,
  setServerMessageClear,
  sessionExpiredServerMessage,
  displayErrorMessage
} from './serverMessageActions';
import * as serverMessageTargets from './serverMessageTargets';
import { goDashboard, goHome, setUserBillsSuccess, setUserBillSuccess } from './comparisonActions';
import { setUser } from './userActions';
import timeAxios from '../utilities/timeAxios';
import { apiTiming } from '../utilities/googleAnalyticsEvents';
import { popUp } from '../utilities/commonUtilities';
import { contextTypes, submissionTypes } from '../enums/submissionTypes';
import { openSubmitDialog } from './dialogOptionsActions';
import { billTypes } from '../enums/billTypes';
import { uploadSubTypes } from '../enums/uploadBillTypes';

// ----------------------------------------------------------------------------
// Gets a customer dashboard with an internal BSC access key and auto-opens the service card
// ----------------------------------------------------------------------------
export function openServiceCardWithKey(accessKey) {
  return dispatch => {
    dispatch(setAjaxCallStart(contextTypes.CUSTOMER_DASHBOARD));

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', 'service-cards');
    });

    axios
      .get(`/api/servicecard/open/${accessKey.id}/?key=${accessKey.key}`)
      .then(response => {
        if (response.data) {
          const data = response.data;

          if (data.messages && data.messages[0] && data.messages[0].message) {
            dispatch(customerHomeError(data.messages[0].message));
          } else {
            const services = [...data.services];

            if (data.autoOpen && data.autoOpen.addressLocked) {
              const submissionId = data.autoOpen.submissionId;
              const submission = data.services.find(e => e.submissionId === submissionId);
              const serviceType = billTypes.getById(data.autoOpen.serviceTypeId);
              const submissionType = submissionTypes.getById(data.autoOpen.submissionTypeId);

              submission.addressLocked = true;

              dispatch(openServiceCard(submissionId, submission, serviceType, submissionType));
            }

            dispatch(setUser({ ...data.user, accessId: accessKey.id, accessKey: accessKey.key, loaded: true }));
            dispatch(setUserBillsSuccess({ loaded: true, data: services }));
            dispatch(goHome());
            dispatch(setAjaxCallEnd());
          }
        } else {
          dispatch(customerHomeError());
        }
      })
      .catch(e => {
        dispatch(customerHomeError());
      });
  };
}

const openServiceCard = (submissionId, submission, serviceType, submissionType) => {
  return dispatch => {
    const backButtonVisible = false;
    const buttonText = 'Submit';

    dispatch(
      openSubmitDialog(serviceType, submissionType.uploadBillType, uploadSubTypes.NONE, {
        submissionId,
        submission: submission.estimate,
        backButtonVisible,
        buttonText
      })
    );
  };
};

// ----------------------------------------------------------------------------
// Gets a customer dashboard with an internal access key.
// ----------------------------------------------------------------------------
export function getCustomerDashboardWithKey(accessKey, launchDefaultComparisons) {
  return dispatch => {
    dispatch(setAjaxCallStart(contextTypes.CUSTOMER_DASHBOARD));

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', 'comparison');
    });

    axios
      .get(`/api/servicecard/${accessKey.id}/?key=${accessKey.key}`)
      .then(response => {
        if (response.data) {
          const data = response.data;

          if (data.messages && data.messages[0] && data.messages[0].message) {
            dispatch(customerHomeError(data.messages[0].message));
          } else {
            dispatch(setUser({ ...data.user, accessId: accessKey.id, accessKey: accessKey.key, loaded: true }));
            dispatch(setUserBillsSuccess({ loaded: true, data: [...data.services] }));
            dispatch(push(`/dashboard${!launchDefaultComparisons ? '?start=true' : ''}`));
            dispatch(setAjaxCallEnd());
          }
        } else {
          dispatch(customerHomeError());
        }
      })
      .catch(() => {
        dispatch(customerHomeError());
      });
  };
}

function customerHomeError(message) {
  return dispatch => {
    dispatch(goHome());

    dispatch(
      displayErrorMessage(
        serverMessageTargets.HOME,
        message || 'An error occured processing your request. Please try again later.',
        true
      )
    );
    dispatch(setAjaxCallEnd());
  };
}

// ----------------------------------------------------------------------------
// Get service cards using an access token issued by a known authentication provider (if available).
// ----------------------------------------------------------------------------
export function getServiceCards(accessToken) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());
    dispatch(setUserBillsSuccess({ loaded: false }));

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'home', 'getServiceCards');
    });

    const config = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      : undefined;

    const path = accessToken ? '/api/servicecard' : '/api/servicecard/empty';

    axios
      .get(path, config)
      .then(response => {
        if (response.data) {
          var data = response.data;
          dispatch(setUserBillsSuccess({ loaded: true, data: [...data] }));
        } else {
          dispatch(goHome());
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch(
            setServerMessage({
              ...error.response.data,
              scrollTo: true,
              target: serverMessageTargets.DASHBOARD
            })
          );
        }
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

// ----------------------------------------------------------------------------
// View bill with an access token issued by a known authentication provider.
// ----------------------------------------------------------------------------
export function viewBillWithToken(accessToken, id) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'dashboard', 'viewBill');
    });

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios
      .get(`/api/user/bill/view/${id}`, config)
      .then(response => {
        if (response.data) {
          if (!popUp(response.data, '_blank')) {
            dispatch(
              setServerMessage({
                code: response.status,
                messages: [{ field: '', message: 'Please enable pop-ups to view this bill.' }],
                scrollTo: true,
                target: serverMessageTargets.DASHBOARD
              })
            );
          }
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch(
            setServerMessage({
              ...error.response.data,
              scrollTo: true,
              target: serverMessageTargets.DASHBOARD
            })
          );
        }
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

// ----------------------------------------------------------------------------
// View bill with an internal access key.
// ----------------------------------------------------------------------------
export function viewBillWithKey(accessKey) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'dashboard2.0', 'viewBill');
    });

    axios
      .get(`/api/user/bill/view/key/${accessKey.id}/?key=${accessKey.key}`)
      .then(response => {
        if (response.data) {
          if (!popUp(response.data, '_blank')) {
            dispatch(
              setServerMessage({
                code: response.status,
                messages: [{ field: '', message: 'Please enable pop-ups to view this bill.' }],
                scrollTo: true,
                target: serverMessageTargets.DASHBOARD
              })
            );
          }
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch(
            setServerMessage({
              ...error.response.data,
              scrollTo: true,
              target: serverMessageTargets.DASHBOARD
            })
          );
        }
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

function resetComparison(id, errorMessages, reductionLoaded) {
  return dispatch => {
    dispatch(
      setUserBillSuccess({
        id,
        errorMessages,
        reductionLoaded,
        bestOffer: null,
        reductionPercentage: 0,
        reductionAmountAnnual: 0,
        nextCompareDateUtc: null,
        expirySeconds: 0
      })
    );
  };
}

function getReductionAmount(path, submissionId, config) {
  return dispatch => {
    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'dashboard2.0', 'getReductionAmount');
    });

    dispatch(resetComparison(submissionId, null, false));

    axios
      .get(path, config)
      .then(response => {
        if (response.data) {
          var data = response.data;

          dispatch(
            setUserBillSuccess({
              id: submissionId,
              reductionLoaded: true,
              ...data
            })
          );
        }
      })
      .catch(error => {
        let errorMessages =
          error.response && error.response.data
            ? error.response.data.messages
            : [{ field: '', message: 'Error getting comparison' }];

        dispatch(resetComparison(submissionId, errorMessages, true));
      });
  };
}

// ----------------------------------------------------------------------------
// Get reduction amount for service card with an internal access key.
// ----------------------------------------------------------------------------
export function getReductionAmountWithKey(accessKey, submissionId) {
  return getReductionAmount(`/api/compare/reduction/${accessKey.id}/?key=${accessKey.key}`, submissionId, null);
}

// ----------------------------------------------------------------------------
// Get reduction amount for service card with an access token issued by a known authentication provider.
// ----------------------------------------------------------------------------
export function getReductionAmountWithToken(accessToken, submissionId, submissionType) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  return getReductionAmount(`/api/compare/reduction/${submissionType}/${submissionId}`, submissionId, config);
}

// ----------------------------------------------------------------------------
// Delete user bill
// ----------------------------------------------------------------------------
export function deleteServiceCard(comparisonReady, userBill, isAuthenticated, getAccessToken, onDeleteComplete) {
  return dispatch => {
    const target = serverMessageTargets.ESTIMATE_DIALOG;

    if (!userBill) {
      dispatch(sessionExpiredServerMessage(target));
      return;
    }

    const accessKey = userBill.accessKey;
    const deleted = true;

    if (accessKey) {
      const access = { key: accessKey.accessKey, id: accessKey.accessId, trustLevel: accessKey.accessTrustLevel };

      dispatch(
        deleteOrRestoreServiceCardWithKey(access, deleted, comparisonReady, userBill.submissionId, onDeleteComplete)
      );
    } else if (isAuthenticated) {
      const fn = async () => {
        dispatch(
          deleteOrRestoreServiceCardWithToken(
            await getAccessToken(),
            deleted,
            comparisonReady,
            userBill.submissionId,
            submissionTypes.ESTIMATE,
            onDeleteComplete
          )
        );
      };
      fn();
    } else {
      dispatch(sessionExpiredServerMessage(target));
    }
  };
}

// ----------------------------------------------------------------------------
// Delete / restore service card with an internal access key.
// ----------------------------------------------------------------------------
export function deleteOrRestoreServiceCardWithKey(access, deleted, comparisonReady, submissionId, onComplete) {
  const action = deleted ? 'delete' : 'restore';

  return dispatch => {
    // Delete / restore only on user front-end if we have a >= low-trust access key.
    if (access.trustLevel >= 2) {
      deleteOrRestoreServiceCard(
        dispatch,
        `/api/servicecard/${action}/${access.id}/?key=${access.key}`,
        null,
        comparisonReady,
        onComplete
      );
    } else {
      onComplete({ submissionId }, comparisonReady);
    }
  };
}

// ----------------------------------------------------------------------------
// Delete / restore service card with an access token issued by a known authentication provider.
// ----------------------------------------------------------------------------
export function deleteOrRestoreServiceCardWithToken(
  accessToken,
  deleted,
  comparisonReady,
  submissionId,
  submissionType,
  onComplete
) {
  return dispatch => {
    const action = deleted ? 'delete' : 'restore';

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    deleteOrRestoreServiceCard(
      dispatch,
      `/api/servicecard/${action}/${submissionType}/${submissionId}`,
      config,
      comparisonReady,
      onComplete
    );
  };
}

function deleteOrRestoreServiceCard(dispatch, path, config, comparisonReady, onComplete) {
  dispatch(setServerMessageClear());
  dispatch(setAjaxCallStart());

  timeAxios(axios, timeInMs => {
    apiTiming(timeInMs, 'service-card', 'delete');
  });

  axios
    .delete(path, config)
    .then(response => {
      onComplete(response.data, comparisonReady);
    })
    .catch(error => {
      dispatch(generalServerMessage(error.response, serverMessageTargets.DASHBOARD, true));
    })
    .then(function() {
      dispatch(setAjaxCallEnd());
    });
}

// ----------------------------------------------------------------------------
// Refresh dashboard card.
// ----------------------------------------------------------------------------
export function refreshBill(accessToken, id, target) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'dashboard', 'refreshBill');
    });

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios
      .get(`/api/compare/refresh/${id}`, config)
      .then(response => {
        dispatch(
          setServerMessage({
            code: response.status,
            messages: [{ field: '', message: 'Comparison emailed' }],
            scrollTo: true,
            target
          })
        );
      })
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch(
            setServerMessage({
              ...error.response.data,
              scrollTo: true,
              target
            })
          );
        }
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}
