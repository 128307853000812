import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';

import BillReductionDisclaimer from '../disclaimers/billReduction';
import { submissionTypes } from '../../enums/submissionTypes';

const BillReductionDetail = ({ offer, bill, programDisplayName, submissionType }) => {
  return (
    <div>
      <Container className="qs-bill-reduction-calculation">
        {submissionType === submissionTypes.BILL && (
          <>
            {offer.percentageBillReductionAmountLowest !== offer.percentageBillReductionAmountGuaranteed && (
              <Row noGutters={true}>
                <Col xs="12" className="qs-bill-reduction-calculation-heading">
                  Guaranteed
                </Col>
              </Row>
            )}

            <Row noGutters={true}>
              <Col xs="8">Your bills total energy usage</Col>
              <Col xs={{ size: 4 }} className="qs-strong right">
                {bill.totalChargesDiscountedDisplay}
              </Col>
            </Row>
            <Row noGutters={true}>
              <Col xs="8">{offer.percentageBillReductionAmountGuaranteedDisplay}</Col>
              <Col xs={{ size: 4 }} className={`qs-strong right${offer.hasSavingGuaranteed ? ' red' : ''}`}>
                {offer.hasSavingGuaranteed ? '' : '+'}
                {offer.savingAmountGuaranteedReverseDisplay}
                <hr className="qs-tiny-hr" />
              </Col>
            </Row>
          </>
        )}
        <Row noGutters={true}>
          <Col xs="8">Total energy usage this deal</Col>
          <Col xs={{ size: 4 }} className="qs-strong right">
            {offer.billAmountGuaranteedDisplay}
          </Col>
        </Row>
      </Container>
      {offer.hasConditionalDiscounts && (
        <Container className="qs-bill-reduction-calculation">
          <Row noGutters={true}>
            <Col xs="12" className="qs-bill-reduction-calculation-heading qs-mt-md">
              With conditional discounts applied
            </Col>
          </Row>

          {submissionType === submissionTypes.BILL && (
            <>
              <Row noGutters={true}>
                <Col xs="8">Your bills total energy usage</Col>
                <Col xs={{ size: 4 }} className="qs-strong right">
                  {bill.totalChargesDiscountedDisplay}
                </Col>
              </Row>
              <Row noGutters={true}>
                <Col xs="8">{offer.percentageBillReductionAmountLowestDisplay}</Col>
                <Col xs={{ size: 4 }} className={`qs-strong right${offer.hasSavingLowest ? ' red' : ''}`}>
                  {offer.hasSavingLowest ? '' : '+'}
                  {offer.savingAmountLowestReverseDisplay}
                  <hr className="qs-tiny-hr" />
                </Col>
              </Row>
            </>
          )}

          <Row noGutters={true}>
            <Col xs="8">Total energy usage this deal</Col>
            <Col xs={{ size: 4 }} className="qs-strong right">
              {offer.billAmountLowestDisplay}
            </Col>
          </Row>
        </Container>
      )}
      <div>
        <strong>Note:</strong>
      </div>
      <ul className="sm-disclaimer-list">
        <BillReductionDisclaimer
          billType={bill.billType}
          programDisplayName={programDisplayName}
          percentagePeakDisplay={bill.percentagePeakDisplay}
          percentageOffPeakDisplay={bill.percentageOffPeakDisplay}
          billAdditionalDisclaimer={offer.billAdditionalDisclaimer}
        />
      </ul>
    </div>
  );
};

BillReductionDetail.propTypes = {
  offer: PropTypes.object.isRequired,
  bill: PropTypes.object.isRequired
};

export default BillReductionDetail;
