import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { billTypes } from '../enums/billTypes';
import { getAgentServiceType } from '../enums/customerCommissionTypes';
import { goHome } from '../actions/comparisonActions';

const PartnerScreen = props => {
  const dispatch = useDispatch();

  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const code = props.match.params.serviceType;
  const serviceType = billTypes.getByCode(code);
  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
  const partnerUrl =
    agentServiceType && agentServiceType.partner && agentServiceType.partner.enabled && agentServiceType.partner.url;

  useEffect(() => {
    if (!partnerUrl) {
      dispatch(goHome());
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Compare</title>
      </Helmet>
      {partnerUrl && (
        <div className="sm-container">
          <iframe
            title="Compare"
            className="sm-frame"
            src={partnerUrl}
            sandbox="allow-scripts allow-forms allow-same-origin"
          ></iframe>
        </div>
      )}
      <div
        className="myFinance-widget"
        data-creative-set-id="2f96356a-3104-4370-90a8-23375796a97d"
        data-campaign="chameleon-smartme-zip-detected-providers"
      ></div>
    </>
  );
};

export default PartnerScreen;
