export const idTypesEnum = {
  DRIVERS_LICENCE: { Id: 1, Name: "Driver's licence" },
  MEDICARE: { Id: 2, Name: 'Medicare card' },
  PASSPORT: { Id: 3, Name: 'Australian passport' },
  FOREIGN_PASSPORT: { Id: 4, Name: 'Foreign passport' },
  getById: id => {
    switch (id) {
      case idTypesEnum.DRIVERS_LICENCE.Id:
        return idTypesEnum.DRIVERS_LICENCE;
      case idTypesEnum.MEDICARE.Id:
        return idTypesEnum.MEDICARE;
      case idTypesEnum.PASSPORT.Id:
        return idTypesEnum.PASSPORT;
      case idTypesEnum.FOREIGN_PASSPORT.Id:
        return idTypesEnum.FOREIGN_PASSPORT;
      default:
        return null;
    }
  }
};
