import React from 'react';
import QsCheckBox from './qsCheckBox';

const CreditCheckConsent = ({ onChange, providerDisplayCode, creditCheckConsent }) => {
  return (
    <>
      <div className="mb-2">
        {providerDisplayCode} need to perform a credit check. If you don't meet the credit requirements you will be
        notified by mail.
      </div>
      <div>
        <QsCheckBox
          isChecked={creditCheckConsent}
          onCheck={e => onChange(e, { creditCheckConsent: !creditCheckConsent })}
          label={`By ticking this box you consent to ${providerDisplayCode} using your personal information above to conduct a
              credit check.`}
          validatorName="credit-check-consent"
          validationMessage="We need your consent to be able to process your applciation."
        />
      </div>
    </>
  );
};

export default CreditCheckConsent;
