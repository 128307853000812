import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '../../auth/auth0-Provider.js';

import { Button, Spinner, Collapse, Card, CardBody } from 'reactstrap';

import { getComparisonWithToken, getComparisonWithKey } from '../../actions/comparisonActions';

import { sessionExpiredServerMessage } from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';

import { billTypes } from '../../enums/billTypes';
import { serviceCardLoadingTypes } from '../../enums/serviceCardLoadingTypes';

import BillReductionDisclaimer from '../disclaimers/billReduction';
import BillDetailsDisclaimer from '../disclaimers/billDetails';
import { submissionStatuses } from '../../enums/submissionTypes.js';

const EnergyBill = props => {
  const { programDisplayName } = useSelector(state => state.config);
  const { loaded: userBillsLoaded } = useSelector(state => state.userBills);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { yourBillExcludedItemsDisclaimer, yourBillTaxDisclaimer, taxIndicator } = useSelector(
    state => state.content.general
  );

  const { serviceType, onClose, isSample, userBill } = props;

  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const submission = userBill.bill;
  const bestOffer = userBill.bestOffer;

  const {
    reductionAmountAnnual,
    reductionLoaded,
    status,
    isLinked,
    awaitingLink,
    nextCompareDate,
    accessKey,
    submissionType,
    errorMessages,
    submissionId
  } = userBill;

  const {
    billDays,
    totalUsageDisplay,
    usageDisplay,
    solarFeedInDisplay,
    supplyDisplay,
    hasBaseCharge,
    baseChargeDisplay,
    hasDeliveryCharge,
    deliveryChargeDisplay,
    totalChargesDiscountedDisplay,
    hasDiscounts,
    totalDiscountsDisplay,
    averagePricePerUnitDisplay,
    billType,
    percentagePeakDisplay,
    percentageOffPeakDisplay
  } = submission;

  const { logo: providerLogo, displayCode: providerName } = submission.provider;

  const [moreInfoOpen, setMoreInfoOpen] = useState(false);

  const converted = isLinked || awaitingLink || nextCompareDate || status === submissionStatuses.CONVERTED.Id;
  const showCompareButton = !isSample && reductionLoaded && !errorMessages && !awaitingLink ? true : false;

  const spendText = bestOffer ? (bestOffer.savingAmountToHighlight > 0 ? 'Reduced spend' : 'Additional spend') : null;
  const spendAmount = bestOffer
    ? bestOffer.savingAmountToHighlight > 0
      ? bestOffer.savingAmountToHighlightDisplay
      : bestOffer.savingAmountToHighlightReverseDisplay
    : null;

  const onCompare = () => {
    onClose();

    const options = { openMoreInfo: reductionAmountAnnual > 0 };
    const serviceType = billTypes.getByCode(billType);
    const serviceCardLoading = userBillsLoaded ? serviceCardLoadingTypes.NONE : serviceCardLoadingTypes.DEFAULT;

    if (accessKey) {
      dispatch(
        getComparisonWithKey(
          { key: accessKey.accessKey, id: accessKey.accessId },
          options,
          submissionType,
          serviceType,
          serviceCardLoading
        )
      );
    } else if (isUserAuthenticated()) {
      const fn = async () => {
        dispatch(getComparisonWithToken(await getAccessToken(), submissionId, submissionType, serviceType));
      };
      fn();
    } else {
      dispatch(sessionExpiredServerMessage(serverMessageTargets.DASHBOARD));
    }
  };

  return (
    <>
      <div className="sm-energy-bill-dialog">
        <div className="intro-summary">{`You used ${totalUsageDisplay} of ${serviceType.Name.toLowerCase()} in ${billDays} days:`}</div>

        <div className="usage-summary">
          <div className="usage-detail">
            {usageDisplay && usageDisplay.map((usage, i) => <div key={'usage' + i}>{usage.description}</div>)}

            {solarFeedInDisplay &&
              solarFeedInDisplay.map((feedIn, i) => <div key={'feedIn' + i}>{feedIn.description}</div>)}

            {supplyDisplay && supplyDisplay.map((supply, i) => <div key={'supply' + i}>{supply.description}</div>)}

            {hasDiscounts && <div>Discounts - {totalDiscountsDisplay}</div>}

            {hasBaseCharge && <div>Base charge - {baseChargeDisplay}</div>}
            {hasDeliveryCharge && <div>Delivery charges - {deliveryChargeDisplay}</div>}
          </div>

          <div>
            <img
              className="provider-logo"
              src={`${staticContentUrl}/images/providers/${providerLogo}`}
              alt={providerName}
            />
          </div>
        </div>

        <hr className="summary-divider" />

        <div className="summary-row">
          <div>Your current spend</div>
          <div>{totalChargesDiscountedDisplay}</div>
        </div>

        {!isSample && !reductionLoaded && !converted && (
          <div className="loading">
            <Spinner size="md" color="dark" />
          </div>
        )}

        {bestOffer && (
          <>
            <div className="summary-row">
              <div>
                Your possible spend
                {/* {bestOffer.hasConditionalDiscounts ? (
                  <span className="qs-small-text"> (with conditional discounts)</span>
                ) : (
                  ''
                )} */}
              </div>
              <div>{bestOffer.billAmountToHighlightDisplay}</div>
            </div>
            <div className="summary-row">
              <div>{`${spendText} (${billDays} days)`}</div>
              <div>{spendAmount}</div>
            </div>
            <div className="summary-row">
              <div>{`${spendText}`}</div>
              <div>{bestOffer.percentageBillReductionAmountToHighlightDisplayShort}</div>
            </div>
            <div className="summary-row annual-saving">
              <div>Possible Annual Saving (365 days)*</div>
              <div>${reductionAmountAnnual}</div>
            </div>
          </>
        )}

        <div className="disclaimer-more">
          <div className="disclaimer-text">* All figures are {taxIndicator}</div>
          <div className="disclaimer-more-button" onClick={() => setMoreInfoOpen(!moreInfoOpen)}>
            {moreInfoOpen ? 'Close Info' : 'More Info'}
          </div>
        </div>

        <Collapse isOpen={moreInfoOpen}>
          <Card className="disclaimer-more-detail disclaimer-text">
            <CardBody>
              <ul>
                <li>
                  Figures may differ to those on your bill as they only include energy usage. Pay-on-time and other
                  discounts have been applied to all figures.
                </li>
                {!averagePricePerUnitDisplay && billType && <BillDetailsDisclaimer submission={submission} />}
                <li>{yourBillExcludedItemsDisclaimer}</li>
                <li>{yourBillTaxDisclaimer}</li>
              </ul>
            </CardBody>
          </Card>
        </Collapse>

        {showCompareButton && (
          <div className="compare-button">
            <Button onClick={onCompare} className="sm-button-primary">
              {reductionAmountAnnual > 0 ? 'View supply and usage rates for this deal' : 'View offers'}
            </Button>
          </div>
        )}

        {!isSample && (
          <div className="note-text">
            <strong>Note:</strong>{' '}
            <ul className="sm-disclaimer-list">
              <li>
                Possible Annual Saving figure assumes that the usage in this bill is representative of your average
                usage and applies that usage to a 12 month period.
              </li>

              <li>{yourBillTaxDisclaimer}</li>
              <BillReductionDisclaimer
                billType={billType}
                programDisplayName={programDisplayName}
                percentagePeakDisplay={percentagePeakDisplay}
                percentageOffPeakDisplay={percentageOffPeakDisplay}
                billAdditionalDisclaimer={bestOffer.billAdditionalDisclaimer}
              />
            </ul>
          </div>
        )}
      </div>

      {!isSample && (
        <div className="sm-modal-footer">
          <Button onClick={onClose} className="sm-button-secondary">
            Close
          </Button>
        </div>
      )}
    </>
  );
};

export default EnergyBill;
