import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { sleep } from '../../utilities/compareUtilities';
import { useDispatch } from 'react-redux';
import { setAjaxCallEnd, setAjaxCallStart } from '../../actions/ajaxCallActions';
import { contextTypes } from '../../enums/submissionTypes';
import { billTypes } from '../../enums/billTypes';

const AllConnect = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement('script');
    dispatch(setAjaxCallStart(contextTypes.PARTNER_COMPARISON, billTypes.INTERNET));

    sleep(null, 'All-Connect', 3000).then(() => {
      script.src = 'https://static.myfinance.com/widget/inlineMedia_core.js';
      script.async = true;
      script.id = 'myFinance-widget-script';
      script.onload = () => {
        window.initializeInline({ loadInlineCss: true });
        dispatch(setAjaxCallEnd());
      };

      document.body.appendChild(script);
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>All Connect</title>
      </Helmet>

      <div
        className="myFinance-widget"
        data-creative-set-id="2f96356a-3104-4370-90a8-23375796a97d"
        data-campaign="chameleon-smartme-zip-detected-providers"
      ></div>
    </>
  );
};

export default AllConnect;
