import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function applicationReducer(state = initialState.application, action) {
  switch (action.type) {
    case types.SET_APPLICATION:
      return objectAssign({}, state, action.data);

    default:
      return state;
  }
}
