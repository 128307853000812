import React, { useState, useEffect } from 'react';
import { AvField } from 'availity-reactstrap-validation';
import SuburbSelector from './suburbSelector';

const PoBoxAddress = props => {
  const { onAddressSelected, namePrefix } = props;

  const [poBoxNumber, setPoBoxNumber] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  // --------------------------------------------------------------------------
  // Detect changes in component and fire parent onAddressSelected event.
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (onAddressSelected) {
      const suburb = selectedAddress ? selectedAddress.suburb : '';
      const suburbShort = selectedAddress ? selectedAddress.suburbShort : '';
      const state = selectedAddress ? selectedAddress.state : '';
      const postcode = selectedAddress ? selectedAddress.postcode : '';
      const country = selectedAddress ? selectedAddress.country : '';

      var newAddress = {
        poBoxNumber,
        suburb,
        suburbShort,
        state,
        postcode,
        country,
        fullAddress: `PO Box ${poBoxNumber}, ${suburb} ${state}, ${country}`,
        streetAddress: `PO Box ${poBoxNumber}`
      };

      onAddressSelected(newAddress);
    }
  }, [poBoxNumber, selectedAddress]);

  // --------------------------------------------------------------------------
  // Suburb selector change handler.
  // --------------------------------------------------------------------------
  const onSuburbSelectorChange = address => {
    setSelectedAddress(address);
  };

  return (
    <div className="sm-address-auto">
      <div className="unit-number">
        <AvField
          bsSize="sm"
          name={`${namePrefix}-poBoxNumber`}
          placeholder="Box"
          type="text"
          value={poBoxNumber}
          onChange={e => setPoBoxNumber(e.target.value === '' ? null : e.target.value)}
          validate={{
            required: { value: true, errorMessage: 'PO box number is required' },
            maxLength: { value: 10 }
          }}
        />
      </div>

      <div className="address">
        <SuburbSelector
          propertyAddress={selectedAddress}
          onChange={onSuburbSelectorChange}
          showManualPostcode={false}
          showAreasSupportedDisclaimer={false}
        />
      </div>
    </div>
  );
};

export default PoBoxAddress;
