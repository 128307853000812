const luhnCheck = val => {
  let checksum = 0; // running checksum total
  let j = 1; // takes value of 1 or 2

  // Process each digit one by one starting from the last
  for (let i = val.length - 1; i >= 0; i--) {
    let calc = 0;
    // Extract the next digit and multiply by 1 or 2 on alternative digits.
    calc = Number(val.charAt(i)) * j;

    // If the result is in two digits add 1 to the checksum total
    if (calc > 9) {
      checksum = checksum + 1;
      calc = calc - 10;
    }

    // Add the units element to the checksum total
    checksum = checksum + calc;

    // Switch the value of j
    if (j === 1) {
      j = 2;
    } else {
      j = 1;
    }
  }

  //Check if it is divisible by 10 or not.
  return checksum % 10 === 0;
};

function getCardTypeByNumber(input) {
  const jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$'); //2131, 1800, 35 (3528-3589)
  const amex_regex = new RegExp('^3[47][0-9]{0,}$'); //34, 37
  const diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'); //300-305, 309, 36, 38-39
  const visa_regex = new RegExp('^4[0-9]{0,}$'); //4
  const mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'); //2221-2720, 51-55
  const maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$'); //always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
  const discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$'); //6011, 622126-622925, 644-649, 65

  const value = input.replace(/\D/g, '');

  if (value.match(jcb_regex)) return { supported: false, name: 'JCB', logo: 'images/financial/credit-card/jcb.png' };
  if (value.match(amex_regex)) return { supported: true, name: 'Amex', logo: 'images/financial/credit-card/amex.png' };
  if (value.match(diners_regex))
    return {
      supported: false,
      name: 'Diners Club',
      logo: 'images/financial/credit-card/diners.png',
      logoHorizontal: true
    };
  if (value.match(visa_regex)) return { supported: true, name: 'Visa', logo: 'images/financial/credit-card/visa.png' };
  if (value.match(mastercard_regex))
    return { supported: true, name: 'Mastercard', logo: 'images/financial/credit-card/mastercard.png' };
  if (value.match(discover_regex))
    return {
      supported: false,
      name: 'Discover',
      logo: 'images/financial/credit-card/discover.png',
      logoHorizontal: true
    };
  if (value.match(maestro_regex) && value[0] === '5')
    return { supported: true, name: 'Mastercard', logo: 'images/financial/credit-card/mastercard.png' };
  if (value.match(maestro_regex))
    return { supported: true, name: 'Maestro', logo: 'images/financial/credit-card/maestro.png' };

  return null;
}

function getCardTypeByCode(input) {
  switch (input) {
    case 'masterCard':
      return { supported: true, name: 'Mastercard', logo: 'images/financial/credit-card/mastercard.png' };
    case 'americanExpress':
      return { supported: true, name: 'Amex', logo: 'images/financial/credit-card/amex.png' };
    case 'discover':
      return {
        supported: false,
        name: 'Discover',
        logo: 'images/financial/credit-card/discover.png',
        logoHorizontal: true
      };
    case 'visa':
      return { supported: true, name: 'Visa', logo: 'images/financial/credit-card/visa.png' };
    case 'diners':
      return {
        supported: false,
        name: 'Diners Club',
        logo: 'images/financial/credit-card/diners.png',
        logoHorizontal: true
      };
    case 'jcb':
      return { supported: false, name: 'JCB', logo: 'images/financial/credit-card/jcb.png' };
    default:
      return { supported: false, name: 'Unknown', logo: null };
  }
}

export { luhnCheck, getCardTypeByNumber, getCardTypeByCode };
