import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import InfoPopover from './infoPopover';
import InternetFeatures from './internetFeatures';
import { OutboundLink } from 'react-ga';

const OfferInternetAu = ({ offerIndex, offer, comparison, mode }) => {
  const hasDownloadSpeeds = offer.uploadSpeed && offer.downloadSpeed && offer.typicalPeakDownloadSpeed;
  const linkUrl = offer.nbnKeyFacts ? offer.nbnKeyFacts.linkUrl : null;
  const typicalPeakDownloadSpeedDescription = offer ? offer.typicalPeakDownloadSpeedDescription : null;
  const nbnKeyFactsName = offer.nbnKeyFacts ? offer.nbnKeyFacts.name : null;

  const getEveningSpeedsNbnFacts = () => {
    return (
      <>
        <div className="qs-offer-internet-speed-description">
          Typical evening speeds
          <InfoPopover
            idType="typical-evening-speeds"
            heading="Typical evening speed"
            body={typicalPeakDownloadSpeedDescription}
            action="typical-evening-speeds-popover"
            offer={offer}
          />
        </div>
        <div className="qs-offer-nbn-key-facts">
          <OutboundLink
            eventLabel={`nbn-key-facts-${offer.name}`}
            to={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {nbnKeyFactsName}
          </OutboundLink>
        </div>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }} lg={{ size: 8, order: 1 }}>
          <Container style={{ margin: 0, padding: 0 }}>
            <Row noGutters={true}>
              {offer.specialOffer && (
                <Col xs="12">
                  <div className="qs-offer-special-offer">
                    <i className="material-icons qs-offer-star">star</i>Special offer:{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: offer.specialOffer
                      }}
                    />
                  </div>
                </Col>
              )}
              {hasDownloadSpeeds && (
                <Col xs="6" lg="3">
                  <>
                    <div className="qs-offer-internet-speed-price">{offer.typicalPeakDownloadSpeed}</div>
                    <div className="qs-offer-internet-speed-price-suffix">Mbps</div>
                    {offer.billAmountLowest !== offer.billAmountGuaranteed && (
                      <div className="qs-offer-internet-regular-price">&nbsp;</div>
                    )}
                    {getEveningSpeedsNbnFacts()}
                  </>
                </Col>
              )}
              <Col xs="6" lg={hasDownloadSpeeds ? '3' : '4'}>
                <div id={`offer_pricing_${offerIndex}`}>
                  {offer.isSummaryOfferOnly && <div className="qs-offer-internet-from">from</div>}
                  <div className="qs-offer-internet-speed-price">{offer.billAmountLowestDisplay}</div>
                  <div className="qs-offer-internet-speed-price-suffix">/month </div>
                  {offer.billAmountLowest !== offer.billAmountGuaranteed && (
                    <div className="qs-offer-internet-regular-price">
                      then {offer.billAmountGuaranteedDisplay} /month
                    </div>
                  )}
                </div>
                {offer.totalMinimumCostDisplay && offer.totalMinimumCostDescription && (
                  <div className="qs-offer-internet-speed-description">
                    <span
                      className="qs-offer-internet-total-min-cost"
                      dangerouslySetInnerHTML={{
                        __html: offer.totalMinimumCostDisplay
                      }}
                    />
                    <InfoPopover
                      idType="total-minimum-cost"
                      heading="Total minimum cost"
                      body={offer.totalMinimumCostDescription}
                      bodyIsHtml={true}
                      action="total-minimum-cost-popover"
                      offer={offer}
                      additionalClass="qs-offer-internet-total-min-cost-popover"
                    />
                  </div>
                )}

                {!hasDownloadSpeeds && !offer.isSummaryOfferOnly && getEveningSpeedsNbnFacts()}
              </Col>
              <Col xs="12" lg={hasDownloadSpeeds ? '6' : '8'}>
                <InternetFeatures features={offer.features} featuresHtml={offer.featuresHtml} />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col
          xs={{ size: 12, order: 1 }}
          md={{ size: 5, order: 2 }}
          lg={{ size: 4, order: 2 }}
          className="qs-provider-logo"
        >
          <Container>
            <Row>
              <Col xs="12" md="12" style={{ marginBottom: '10px' }}>
                <img src={offer.providerLogoUrl} alt={offer.providerDisplayCode} />
                {/* {offer.indicator === 'sponsor' && <div className="qs-sponsor">Sponsored</div>} */}
              </Col>
              <Col xs="12" md="12">
                <div className="qs-summary-plan-name">{offer.name}</div>
              </Col>
              <Col xs="12" md="12">
                {offer.planDescription && <div className="qs-mb-md">{offer.planDescription}</div>}

                <div>{offer.contract} contract</div>

                {offer.connectionType && (
                  <div>
                    {offer.connectionType} connection
                    <InfoPopover
                      idType="nbn-connection"
                      heading={`${offer.connectionType} connection`}
                      body={offer.connectionTypeDescription}
                      action="nbn-connection-popover"
                      offer={offer}
                    />
                  </div>
                )}

                {offer.disclaimer && (
                  <div style={{ fontSize: '0.8em' }} className="qs-mt-lg">
                    {offer.disclaimer}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default OfferInternetAu;
