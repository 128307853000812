import React from 'react';

// Note: these are written to work with react-helmet. It may look like these can be done as function components but that does not work within a <Helmet> tag

let getGtagScript = (isLoaded, googleAdWordsId) => {
  var result =
    isLoaded && googleAdWordsId ? (
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAdWordsId}`}></script>
    ) : null;

  return result;
};

let getGtagInitialise = (isLoaded, googleAdWordsId) => {
  var result =
    isLoaded && googleAdWordsId ? (
      <script>
        {`        
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAdWordsId}');
          `}
      </script>
    ) : null;

  return result;
};

let getAdWordConversionScript = (isLoaded, googleAdWordsId, adWordLabel, transactionId) => {
  var result =
    isLoaded && googleAdWordsId && adWordLabel && transactionId ? (
      <script>
        {`gtag('event', 'conversion', { 'send_to': '${googleAdWordsId}/${adWordLabel}', 'transaction_id': '${transactionId}' });`}
      </script>
    ) : null;

  return result;
};

export { getGtagScript, getGtagInitialise, getAdWordConversionScript };
