import React from 'react';

const Ellipsis = () => {
  return (
    <div className="sm-chatbot-ellipsis">
      <div className="ellipsis dot-1" />
      <div className="ellipsis dot-2" />
      <div className="ellipsis dot-3" />
    </div>
  );
};

export default Ellipsis;
