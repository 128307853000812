import React from 'react';
import { customerCommissionTypes } from '../../enums/customerCommissionTypes';

const CustomerCommissionTerms = ({ serviceType, commissionType }) => {
  const commissionName = commissionType.Name;

  return (
    <>
      {commissionName} will be issued to the email address you used in your retailer application, between 60 and 90 days
      after your {serviceType.Name.toLowerCase()} application has been successfully approved by the retailer. To be
      eligible for the {commissionName} offer, you will need to be an active billing customer with the retailer on the
      day the {commissionName} is due to be issued. Limited numbers may apply.
      {commissionType === customerCommissionTypes.COLES_GIFT_CARD && (
        <div className="mt-2">
          {commissionName} redeemable at: Coles Supermarkets and Coles Central. This card cannot be redeemed at Coles
          Online, Coles Express or Liquorland. Conditions and exclusions apply. For full Terms of Use visit{' '}
          <a
            style={{ textDecoration: 'underline' }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://giftcards.com.au"
          >
            giftcards.com.au
          </a>{' '}
          or phone 1300 304 990.
        </div>
      )}
    </>
  );
};

export default CustomerCommissionTerms;
