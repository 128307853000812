import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import * as actions from '../../actions/confirmDetailActions';

export class Voucher extends Component {
  state = {
    qff: {
      qffNumber: null,
      firstInitial:
        this.props.comparison.submission.accountName && this.props.comparison.submission.accountName.firstName
          ? this.props.comparison.submission.accountName.firstName.charAt(0).toUpperCase()
          : null,
      surname: this.props.comparison.submission.accountName
        ? this.props.comparison.submission.accountName.surname
        : null
    }
  };

  render() {
    return (
      <Row>
        <Col xs="12" lg="6" xl="7">
          <div className="qs-voucher">
            <h2 className="qs-voucher-heading">{this.props.heading}</h2>
            <div className="qs-voucher-sub-text">{this.props.detail}</div>

            {this.props.agentCode === 'qantas' ? (
              <Container>
                <Row>
                  <Col xs="12">
                    <AvField
                      bsSize="sm"
                      placeholder="Qantas Frequent Flyer number"
                      name="qffNumber"
                      value={this.state.qff.qffNumber}
                      type="text"
                      validate={{
                        required: { value: true, errorMessage: 'Please enter your Qantas Frequent Flyer number' },
                        pattern: {
                          value: '^[0-9]{7}(?:[0-9]{3})?$',
                          errorMessage: 'Please enter your 7 or 10 digit Qantas Frequent Flyer number'
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="3">
                    <AvField
                      bsSize="sm"
                      placeholder="Initial"
                      value={this.state.qff.firstInitial}
                      name="qffFirstInitial"
                      type="text"
                      validate={{
                        pattern: { value: '/^[a-z]$/i', errorMessage: 'Please enter the first initial of you name' }
                      }}
                    />
                  </Col>
                  <Col xs="9">
                    <AvField
                      bsSize="sm"
                      placeholder="Surname"
                      name="qffSurname"
                      value={this.state.qff.surname}
                      type="text"
                      validate={{
                        minLength: { value: 1, errorMessage: 'Please enter the surname for the QFF account' },
                        maxLength: {
                          value: 50,
                          errorMessage: 'The surname you entered for the QFF account is too long'
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    Not a member?{' '}
                    <a href="https://www.qantas.com/fflyer/dyn/joinff" rel="noopener noreferrer" target="_blank">
                      Join now
                    </a>
                  </Col>
                </Row>
              </Container>
            ) : (
              ''
            )}
          </div>
        </Col>
        <Col xs="12" lg="6" xl="5">
          <div className="qs-voucher-panel">
            <img src={this.props.image} alt={this.props.alt} />
          </div>
        </Col>
      </Row>
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
}

const mapStateToProps = state => {
  return {
    comparison: state.comparison
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

Voucher.propTypes = {
  heading: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  agentCode: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  surname: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Voucher);
