import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

const LinkServiceDialogProviders = () => {
  var arr = [];
  arr.push({ name: '1st Energy', number: '1300 426 594', options: '3' });
  arr.push({ name: 'ActewAGL', number: '131 493', options: '4' });
  arr.push({ name: 'AGL', number: '131 245', options: '3,0' });
  arr.push({ name: 'Alinta Energy', number: '133 702', options: '4' });
  arr.push({ name: 'Click Energy', number: '1800 775 929', options: '2, 3' });
  arr.push({ name: 'CovaU', number: '1300 689 866', options: '5' });
  arr.push({ name: 'Discover Energy', number: '1300 946 898', options: '1, 5' });
  arr.push({ name: 'Dodo', number: '1300 739 926', options: '1' });
  arr.push({ name: 'Elysian', number: '1300 671 799', options: '5' });
  arr.push({ name: 'Energy Australia', number: '1800 440 924', options: '2,1,3,4' });
  arr.push({ name: 'Energy Locals', number: '1300 693 637', options: '2' });
  arr.push({ name: 'Future X', number: '1300 599 008', options: '2, 3' });
  arr.push({ name: 'GloBird', number: '133 456', options: '3' });
  arr.push({ name: 'Kogan energy', number: '1300 005 123', options: '3' });
  arr.push({ name: 'Lumo Energy', number: '1300 817 352', options: '3,3' });
  arr.push({ name: 'Momentum Energy', number: '1800 627 228', options: '1' });
  arr.push({ name: 'Origin Energy', number: '132 461', options: '3,5' });
  arr.push({ name: 'Powerclub', number: '1300 294 459', options: 'Direct line to customer service' });
  arr.push({ name: 'Powerdirect', number: '1300 307 966', options: '3,2,2' });
  arr.push({ name: 'Powershop', number: '1800 462 668', options: '3' });
  arr.push({ name: 'QEnergy', number: '1300 698 992', options: 'Direct line to customer service' });
  arr.push({ name: 'ReAmped energy', number: '1800 841 627', options: '2 (to request a call back)' });
  arr.push({ name: 'Red Energy', number: '1300 171 794', options: '5' });
  arr.push({ name: 'Simply Energy (VIC)', number: '138 808', options: '1,1,1,5' });
  arr.push({ name: 'Simply Energy (SA)', number: '138 808', options: '2,1,1,5' });
  arr.push({ name: 'Simply Energy (NSW)', number: '138 808', options: '3,1,1,5' });
  arr.push({ name: 'Simply Energy (QLD)', number: '138 808', options: '4,1,1,5' });
  arr.push({ name: 'Sumo (VIC)', number: '138 860', options: '2,1,2' });
  arr.push({ name: 'Sumo (NSW)', number: '138 860', options: '2,2,2' });
  arr.push({ name: 'Tango Energy (VIC)', number: '1800 010 648', options: '1,2,2' });
  arr.push({ name: 'Tango Energy (SA)', number: '1800 010 648', options: '2,2,2' });
  arr.push({ name: 'Tango Energy (Other States)', number: '1800 010 648', options: '3' });

  const [retailers, setRetailers] = useState(arr);
  const [filterText, setFilterText] = useState('');

  const onFilter = e => {
    setFilterText(e.value.toLowerCase());
    setRetailers(
      arr.filter(a => {
        return a.name.toLowerCase().indexOf(e.value) !== -1;
      })
    );
  };

  return (
    <>
      <p>A list of phone numbers and menu selections for retailers is below:</p>
      <AvForm>
        <AvField
          bsSize="sm"
          name="filter"
          placeholder="Filter"
          type="text"
          value={filterText}
          onChange={e => onFilter(e.target)}
        />
      </AvForm>
      <Table>
        <thead>
          <tr>
            <th className="qs-link-service-dialog-column-lg">Name</th>
            <th className="qs-link-service-dialog-column-lg">Number</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {retailers.map(a => {
            return (
              <tr key={a.name}>
                <td>{a.name}</td>
                <td>{a.number}</td>
                <td>{a.options}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default LinkServiceDialogProviders;
