import React from 'react';
import { FormGroup, Input, Row, Col } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { useSelector } from 'react-redux';
import YourDetails from './yourDetails';
import { preferredCallbackPeriods } from '../../enums/preferredCallbackPeriods';
import { getAddress } from '../../utilities/addressUtilities';

const OfferConfirmHomeLoanDetailsAu = ({ confirmDetail, offer, onChange }) => {
  const { emailAddress, accountVerified } = useSelector(state => state.user);
  const { mobilePhoneNumberRegex } = useSelector(state => state.config.applicationSettings.country);
  const { propertyAddress, refinance } = useSelector(state => state.comparison.submission);

  return (
    <>
      {refinance && (
        <>
          <Row className="qs-more-info-table-header">
            <Col>
              <div style={{ paddingTop: '3px' }}>Property address</div>
            </Col>
          </Row>
          <div className="sm-home-loan-property-address">
            {getAddress(propertyAddress).fullAddress || 'No address specified'}
          </div>
        </>
      )}

      <Row className="qs-more-info-table-header">
        <Col xs="12" md="6">
          Your details
        </Col>
        <Col className="d-none d-md-block" md="6">
          Preferred time of call back
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="6">
          <YourDetails
            onChange={onChange}
            details={confirmDetail}
            phoneNumberRegex={mobilePhoneNumberRegex}
            emailAddress={emailAddress}
            showMembershipId={offer.membershipId}
            showAlternatePhoneNumber={false}
            showDateOfBirth={false}
            showTitle={true}
            showEmailAddress={true}
            showPhoneNumber={true}
            lockPhoneNumber={accountVerified}
          />
        </Col>
        <Col className="d-block d-md-none qs-more-info-table-header-col" xs="12">
          <div>Preferred time of call back</div>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <AvField
              bsSize="sm"
              name="callBack"
              placeholder="Call-back"
              type="select"
              value={confirmDetail.preferredCallback}
              onChange={e => onChange(e, { preferredCallback: e.target.value })}
            >
              <option value="">Select an option</option>
              {preferredCallbackPeriods.getDefault().map(e => (
                <option key={`cbp-${e.Id}`} value={e.Id}>
                  {e.Name}
                </option>
              ))}
            </AvField>
          </FormGroup>

          <FormGroup>
            <div className="qs-more-info-table-header-col">Comments</div>
            <Input
              className="sm-home-loan-offer-comments"
              type="textarea"
              name="text"
              onChange={e => onChange(e, { comments: e.target.value })}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default OfferConfirmHomeLoanDetailsAu;
