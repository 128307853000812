import * as types from './actions';
import * as serverMessageTargets from './serverMessageTargets';
import { scroller } from 'react-scroll';

export function setServerMessageSuccess(serverMessage) {
  return { type: types.SET_SERVER_MESSAGE, serverMessage: serverMessage };
}

export function setServerMessage(serverMessage) {
  return dispatch => {
    dispatch(setServerMessageSuccess(serverMessage));
  };
}

export function setServerMessageClear(target, serverMessage) {
  return dispatch => {
    if (!serverMessage || target === serverMessage.target) {
      dispatch(setServerMessageSuccess({ code: null, scrollTo: false, messages: [], target }));
    }
  };
}

export function generalServerMessage(e, target, scrollTo) {
  return dispatch => {
    const messages =
      e && e.data && e.data.messages
        ? { ...e.data }
        : {
            messages: [
              {
                field: '',
                message: 'There was a problem with your request. Please close all browsers and start again.'
              }
            ]
          };

    dispatch(
      setServerMessageSuccess({
        ...messages,
        code: e ? e.status : 400,
        scrollTo,
        target
      })
    );

    if (scrollTo) {
      setTimeout(() => {
        scroller.scrollTo(`server-message-${target}`, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -20
        });
      }, 100);
    }
  };
}

export function scrollToError(serverMessage, target) {
  if (serverMessage.scrollTo) {
    setTimeout(() => {
      scroller.scrollTo(`server-message-${target}`, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -20
      });
      setServerMessage({ scrollTo: false, target });
    }, 100);
  }
}

export function displayErrorMessage(target, message, scrollTo) {
  return generalServerMessage(
    {
      status: 400,
      data: { messages: [{ field: '', message }] }
    },
    target,
    scrollTo
  );
}

export function displayInfoMessage(target, message, scrollTo) {
  return generalServerMessage(
    {
      status: 200,
      data: { messages: [{ field: '', message }] }
    },
    target,
    scrollTo
  );
}

// ----------------------------------------------------------------------------
// Messages
// ----------------------------------------------------------------------------
export function sessionExpiredServerMessage(target) {
  return displayErrorMessage(
    target,
    'There seems to be a problem with your session. Please close all browsers and start again.',
    true
  );
}

export function otpServerMessage(phoneNumber) {
  return displayInfoMessage(
    serverMessageTargets.OTP_DIALOG,
    `A new verification code has been sent to ${phoneNumber}.`,
    false
  );
}

export function comparisonErrorMessage(error, target) {
  let data;

  switch (error.response.status) {
    case 400:
    case 404:
      data = error.response.data;
      break;

    default:
      data = { messages: [{ field: '', message: 'Error during comparison, please try again later.' }] };
  }

  return dispatch => {
    dispatch(
      setServerMessage({
        ...data,
        code: error.response.status,
        target
      })
    );
  };
}

export function displayNoOffersMessage(target) {
  return displayInfoMessage(target, 'Sorry, we have no offers for you right now. Please try again later.', true);
}

export function comparisonUnsupportedMessage(target) {
  return displayErrorMessage(target, 'Sorry, this comparison is currently unsupported. Please try again later.', true);
}

export function verifyMessage(target) {
  return displayErrorMessage(target, "Please verify this information we've extracted from your bill.", true);
}
