import React from 'react';
import { useSelector } from 'react-redux';

import { billTypes } from '../../enums/billTypes';
import {
  customerCommissionTypes,
  getAgentServiceType,
  getCommonCommissionType
} from '../../enums/customerCommissionTypes';

import CustomerCommissionLogo from '../common/customerCommissionLogo';
import { occupancyTypes } from '../../enums/submissionTypes';

const StartHeader = ({ heading, compact, serviceType, occupancyType, showSubheading, showCommission }) => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { estimateWizardIntroSubheading } = useSelector(state => state.content.general);

  const commonCommissionType = getCommonCommissionType(agentBillTypes);
  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
  const commissionType = agentServiceType.hasCommission ? agentServiceType.commissionType : commonCommissionType;
  const hasCommission = commissionType !== customerCommissionTypes.NONE;

  const getSubheading = () => {
    const serviceTypeName = serviceType ? serviceType.Name.toLowerCase() : '';

    if (estimateWizardIntroSubheading) return estimateWizardIntroSubheading;
    if (hasCommission && occupancyType === occupancyTypes.NEW)
      return `Earn ${commissionType.NamePlural} when you connect your utilities here.`;
    if (hasCommission) return `Earn ${commissionType.NamePlural} when you switch your ${serviceTypeName} plan.`;

    return null;
  };

  return (
    <>
      <div className="sm-start-header">
        <div
          className={`heading-container${compact ? ' compact' : ''}${
            hasCommission && showCommission ? ' with-commission' : ''
          }`}
        >
          {hasCommission && showCommission && (
            <CustomerCommissionLogo
              serviceType={agentServiceType.hasCommission ? agentServiceType.serviceType : billTypes.NONE}
              showInfo
            />
          )}
          <div
            className="heading"
            dangerouslySetInnerHTML={{
              __html: heading
            }}
          />
          {showSubheading && <div className="sub-heading">{getSubheading()}</div>}
        </div>
      </div>
    </>
  );
};
export default StartHeader;
