import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { setArticles } from '../../actions/articleActions';

const ArticleSummary = () => {
  const dispatch = useDispatch();

  const { articles } = useSelector(state => state);

  useEffect(() => {
    if (!articles.isLoaded) {
      dispatch(setArticles());
    }
  }, []);

  useEffect(() => {
    if (articles.isLoaded) {
    }
  }, [articles]);

  return (
    <div className="sm-home-article-summary">
      <div className="heading">Most popular articles and guides</div>
      <div className="tagline">Read by members today</div>
      {articles && articles.isLoaded ? (
        <div className="articles">
          {articles.items
            .sort((a, b) => new Date(b.releaseDateUtc) - new Date(a.releaseDateUtc))
            .slice(0, 10)
            .map((e, i) => (
              <div className="article" key={`article_summary_${i}`}>
                <Link className="link" to={`/article/${e.slug}`}>
                  <span className="index">{(i + 1).toString().padStart(2, '0')}.</span> {e.headline}
                </Link>
              </div>
            ))}
        </div>
      ) : (
        <Spinner size="sm" color="dark" style={{ marginLeft: '10px' }} />
      )}
    </div>
  );
};

export default ArticleSummary;
