import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import ServiceIcon from '../serviceCard/serviceIcon';
import ShowFeatureCheckbox from '../common/showFeatureCheckbox';
import OrganiserName from '../common/organiserName';
import { controlTypes } from './getStarted';

import { billTypes } from '../../enums/billTypes';
import { contextTypes } from '../../enums/submissionTypes';
import { userPreferenceTypes } from '../../utilities/userPreferencesUtilities';

const WizardIntro = ({ onClose, onChangeControlType, contextType }) => {
  const { displayName, billTypes: agentBillTypes } = useSelector(state => state.config);
  const { tourGuideEnabled } = useSelector(state => state.config.settings);

  const getServiceIcon = serviceType => {
    const hasService = billTypes.hasServiceType(agentBillTypes, serviceType);
    if (hasService) {
      return <ServiceIcon serviceType={serviceType} />;
    }
  };

  const closeMode =
    contextType === contextTypes.DIRECT_SWITCH ||
    contextType === contextTypes.CUSTOMER_DASHBOARD ||
    contextType === contextTypes.CUSTOMER_SURVEY;

  // --------------------------------------------------------------------------
  // Next button click handler.
  // --------------------------------------------------------------------------
  const onNextButtonClick = () => {
    closeMode ? onClose() : onChangeControlType(controlTypes.WizardBasic);
  };

  const getDescription = () => {
    const serviceTypeOptions = { includeExternal: false };

    if (contextType) {
      return (
        <>
          Here you'll find {displayName}'s best deals for your{' '}
          {billTypes.getServicesText(agentBillTypes, null, false, serviceTypeOptions)}.
        </>
      );
    } else {
      return (
        <>
          <p>To get started, we'll ask you a few questions about your bills and your home.</p>
          <div>
            We'll use this information to find our best deals for your{' '}
            {billTypes.getServicesText(agentBillTypes, null, false, serviceTypeOptions)}.
          </div>
        </>
      );
    }
  };

  return (
    <div className="intro-wizard">
      <div className="start-heading intro-control">
        <div className="heading-text">
          Welcome to your <OrganiserName />
        </div>
      </div>

      {tourGuideEnabled && (
        <div className="intro-banner">
          <div className="tour-text">
            Access your Hints and Tips at any time for suggestions about the bills and offers in your{' '}
            <OrganiserName nameOnly />.
          </div>

          <div className="show-tour">
            <div>
              <i className="material-icons tour-button">tips_and_updates</i>
            </div>
            <div>
              <ShowFeatureCheckbox label="Show tips" featureType={userPreferenceTypes.TOURS} />
            </div>
          </div>
        </div>
      )}

      <div className="service-icons">
        {getServiceIcon(billTypes.ELECTRICITY)}
        {getServiceIcon(billTypes.GAS)}
        {getServiceIcon(billTypes.INTERNET)}
        {getServiceIcon(billTypes.HOME_LOAN)}
        {getServiceIcon(billTypes.VECHICLE_INSURANCE)}
        {getServiceIcon(billTypes.HOME_AND_CONTENTS_INSURANCE)}
        {getServiceIcon(billTypes.HEALTH_INSURANCE)}
        {getServiceIcon(billTypes.MOBILE_PHONE)}
        {getServiceIcon(billTypes.SOLAR_INSTALLATION)}
        {getServiceIcon(billTypes.SOLAR_FINANCE)}
        {getServiceIcon(billTypes.BUSINESS_LOAN)}
      </div>

      <div className="intro-description">{getDescription()}</div>

      <div className="wizard-footer">
        <div className="dialog-button">
          <Button className="sm-button-secondary medium" onClick={onNextButtonClick}>
            {closeMode ? 'Close' : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WizardIntro;
