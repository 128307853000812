import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  return (
    <div>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <h4>404 Page Not Found</h4>
      <Link to="/">Go back to home page.</Link>
    </div>
  );
};

export default NotFoundPage;
