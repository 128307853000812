import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import { useAuth0 } from '../../auth/auth0-Provider.js';

import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { navigationTypes } from '../../enums/navigationTypes';
import { authenticationTypes } from '../../enums/authenticationTypes';
import { agentOperatingModes, homePageTypes } from '../../enums/launchTypes.js';
import { uploadBillTypes, uploadSubTypes } from '../../enums/uploadBillTypes.js';
import { buttonTypes } from '../../enums/buttonTypes.js';
import { scaleTypes } from '../../enums/scaleTypes.js';
import { billTypes } from '../../enums/billTypes.js';

import AuthenticationButton from '../authentication/authenticationButton';
import RegistrationControl from '../registration/registrationControl';
import HowItWorks from '../../components/common/howItWorks';
import GetStarted from '../../components/GetStartedWizard/getStarted';
import DeletedServicesDialog from '../dialogs/deletedServicesDialog';
import GeneralDialog from '../dialogs/generalDialog';

import {
  closeDialogs,
  getStartedDialogOptions,
  howItWorksDialogOptions,
  openSubmitDialog
} from '../../actions/dialogOptionsActions';
import { navigationOptions } from '../../actions/globalOptionsActions';
import { standardEvents, getCategory } from '../../utilities/googleAnalyticsEvents';
import { agentHasEnergy, getExit } from '../../utilities/billTypeUtilities.js';
import { getSessionActivity } from '../../utilities/commonUtilities.js';
import { exitExternal } from '../../actions/sessionActivityActions.js';
import PoweredBySmartMe from '../common/poweredBySmartMe.js';
import OrganiserName from '../common/organiserName.js';

const Navigation = () => {
  const { session } = useSelector(state => state);
  const { logo } = useSelector(state => state.content.images);
  const { organiserTagline } = useSelector(state => state.content.general);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const {
    showPageMenu,
    homeLinkEnabled,
    authenticationType,
    movingHouseEnabled,
    hasEstimates,
    googleAnalyticsClientCode,
    tourGuideEnabled,
    showExitLink,
    servicePhoneNumberEnabled,
    showNavigationOrganiser,
    showNavigationOrganiserName,
    homePageType,
    operatingMode,
    exits
  } = useSelector(state => state.config.settings);

  const { isOpen: howItWorksOpen } = useSelector(state => state.dialogOptions.howItWorks);
  const { isOpen: getStartedOpen } = useSelector(state => state.dialogOptions.getStarted);
  const { billTypes: agentBillTypes, displayName } = useSelector(state => state.config);
  const { servicePhoneNumber } = useSelector(state => state.content.home);

  const phone = servicePhoneNumber ? JSON.parse(servicePhoneNumber) : null;

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [registrationDialogOpen, setRegistrationDialogOpen] = useState(false);

  const { isUserAuthenticated, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  const agentMode = agentOperatingModes.getById(operatingMode);
  const homePage = homePageTypes.getById(homePageType);
  const currentPage = navigationTypes.getCurrentPage();
  const hasTour = tourGuideEnabled && currentPage.hasTour;
  const showMovingHouse =
    movingHouseEnabled &&
    (currentPage === homePage.navigationType || currentPage === navigationTypes.DASHBOARD) &&
    agentHasEnergy(agentBillTypes);

  const showHomeLink = homeLinkEnabled && agentMode === agentOperatingModes.ACTIVE;
  const showOrganiser =
    showNavigationOrganiser && agentMode === agentOperatingModes.ACTIVE && homePage !== homePageTypes.START;
  const showMenu = showPageMenu && agentMode === agentOperatingModes.ACTIVE && homePage !== homePageTypes.START;
  const showHomeMenu = currentPage !== homePage.navigationType && homePage.navigationType !== navigationTypes.DASHBOARD;
  const showDashboardMenu = currentPage !== navigationTypes.DASHBOARD;
  const exit = getExit(null, exits, billTypes.NONE, agentBillTypes);
  const hasExitLink = showExitLink && exit;

  // ----------------------------------------------------------------------------
  // Dialog handling.
  // ----------------------------------------------------------------------------
  const onGetStartedDialogVisible = visible => {
    dispatch(getStartedDialogOptions(visible));
  };

  const onHowItWorksDialogVisible = (visible, dialogResult) => {
    dispatch(howItWorksDialogOptions(visible));

    if (dialogResult === true && hasEstimates) {
      dispatch(getStartedDialogOptions(true));
      standardEvents('How-It-Works-Dialog', 'Click', 'Get-Started', googleAnalyticsClientCode);
    } else {
      standardEvents('How-It-Works-Dialog', 'Click', visible ? 'Open' : 'Close', googleAnalyticsClientCode);
    }
  };

  const onNavigate = navigationType => {
    standardEvents('Menu', 'Click', `${navigationType.name}-from-${getCategory()}`, googleAnalyticsClientCode);

    dispatch(closeDialogs('navigation:onNavigate'));
    dispatch(navigationOptions(navigationType));
    dispatch(push(navigationType.path));
  };

  const onExit = e => {
    const sessionActivity = getSessionActivity(session);
    e.preventDefault();
    dispatch(exitExternal(exit.url, { ...sessionActivity, data: { source: 'navigation-bar' } }));
  };

  // ----------------------------------------------------------------------------
  // Setup menu items.
  // ----------------------------------------------------------------------------
  const getMenuItem = (key, title, scaleType, onClick) => {
    return { key, title, scaleType, onClick };
  };

  useEffect(() => {
    const items = [];

    if (showHomeMenu) {
      items.push(getMenuItem('home', 'Home', scaleTypes.smDown, () => onNavigate(homePage.navigationType)));
    }

    if (showDashboardMenu) {
      items.push(
        getMenuItem('dashboard', <OrganiserName nameOnly />, scaleTypes.smDown, () =>
          onNavigate(navigationTypes.DASHBOARD)
        )
      );
    }

    if (showMovingHouse) {
      items.push(
        getMenuItem('movingHouse', 'Moving House?', hasExitLink ? scaleTypes.all : scaleTypes.smDown, () => {
          dispatch(openSubmitDialog(billTypes.NONE, uploadBillTypes.MOVING_HOUSE, uploadSubTypes.NONE));
        })
      );
    }

    items.push(getMenuItem('howItWorks', 'How It Works', scaleTypes.all, () => onHowItWorksDialogVisible(true)));

    if (hasTour) {
      items.push(
        getMenuItem('tour', 'Tour', scaleTypes.all, () => {
          standardEvents('Menu', 'Click', `Tour-From-${getCategory()}`, googleAnalyticsClientCode);
          dispatch(navigationOptions(navigationTypes.TOUR));
        })
      );
    }

    items.push(
      getMenuItem('preferences', 'Preferences', scaleTypes.all, () => onNavigate(navigationTypes.PREFERENCES))
    );

    if (isAuthenticated && isUserAuthenticated()) {
      if (currentPage === navigationTypes.DASHBOARD) {
        items.push({
          key: 'deleted',
          scaleType: scaleTypes.all,
          component: <DeletedServicesDialog />
        });
      }
    } else {
      items.push(getMenuItem('registration', 'Register', scaleTypes.all, () => setRegistrationDialogOpen(true)));
    }

    if (authenticationType === authenticationTypes.DEFAULT) {
      items.push({
        key: 'authenticaton',
        scaleType: scaleTypes.all,
        component: (
          <AuthenticationButton key="nav-auth" buttonType={buttonTypes.TEXT} additionalClass="navigation-item" />
        )
      });
    }

    setMenuItems(items);
  }, [isAuthenticated, currentPage]);

  const navBar = (
    <div className="sm-navigation">
      <div className="home-page">
        {showHomeLink ? (
          <Link to={homePage.path}>
            <div className="logo">
              <img src={`${staticContentUrl}${logo}`} alt="logo" />
            </div>
          </Link>
        ) : (
          <div className="logo">
            <img src={`${staticContentUrl}${logo}`} alt="logo" />
          </div>
        )}
        {showOrganiser && (
          <div className="organiser-wrap">
            {showNavigationOrganiserName && (
              <div className="name">
                <OrganiserName nameOnly />
              </div>
            )}

            <div className="tagline">{organiserTagline ? organiserTagline : <PoweredBySmartMe noMargin />}</div>
          </div>
        )}
      </div>
      <div className="sm-navigation-right">
        <div className="main-content">
          {servicePhoneNumberEnabled && phone && (
            <div className="service-phone">
              <div className="number">{phone.number}</div>
              <div className="tagline">{phone.tagline}</div>
            </div>
          )}
          {showMenu && (
            <div className={`sm-menu${menuOpen ? ' open' : ''}`}>
              {hasExitLink && (
                <div className={`navigation-item ${scaleTypes.mdUp.className}`} onClick={e => onExit(e)}>
                  {`Return to ${displayName}`}
                </div>
              )}
              {showHomeMenu && (
                <div
                  className={`navigation-item ${scaleTypes.mdUp.className}`}
                  onClick={() => onNavigate(homePage.navigationType)}
                >
                  Home
                </div>
              )}

              {showDashboardMenu && (
                <div
                  id="organiser_menu"
                  className={`navigation-item ${scaleTypes.mdUp.className}`}
                  onClick={() => onNavigate(navigationTypes.DASHBOARD)}
                >
                  <OrganiserName nameOnly />
                </div>
              )}

              {!hasExitLink && showMovingHouse && (
                <div
                  className={`navigation-item ${scaleTypes.mdUp.className}`}
                  onClick={() => {
                    dispatch(openSubmitDialog(billTypes.NONE, uploadBillTypes.MOVING_HOUSE, uploadSubTypes.NONE));
                  }}
                >
                  Moving House
                </div>
              )}

              <Menu
                menuButton={
                  <span className="menu-top">
                    <span className={`material-icons ${scaleTypes.smDown.className}`}>menu</span>
                    <span className={`${scaleTypes.mdUp.className}`}>Menu</span>
                  </span>
                }
                onMenuChange={e => setMenuOpen(e.open)}
              >
                {menuItems &&
                  menuItems.map(item => {
                    return (
                      <MenuItem
                        className={`menu-item ${item.scaleType.className}`}
                        key={item.key}
                        onClick={item.onClick}
                      >
                        {item.component || item.title}
                      </MenuItem>
                    );
                  })}
              </Menu>
            </div>
          )}
        </div>

        {hasExitLink && (
          <div className="mobile-exit-link">
            <div className={`navigation-item ${scaleTypes.smDown.className}`} onClick={e => onExit(e)}>
              {`Return to ${displayName}`}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <Container className={`sm-container ${scaleTypes.mdDown.className}`}>{navBar}</Container>

      <GeneralDialog
        key="howItWorksDialog"
        bodyClass="no-padding"
        showFooter={false}
        dialogOpen={howItWorksOpen}
        onClose={() => onHowItWorksDialogVisible(false)}
      >
        <HowItWorks onClose={dialogResult => onHowItWorksDialogVisible(false, dialogResult)} />
      </GeneralDialog>

      <GeneralDialog
        key="registrationDialog"
        bodyClass="sm-registration-dialog"
        showFooter={false}
        heading="Registration"
        dialogOpen={registrationDialogOpen}
        onClose={() => setRegistrationDialogOpen(false)}
      >
        <RegistrationControl onClose={() => setRegistrationDialogOpen(false)} />
      </GeneralDialog>

      <GetStarted
        noButton
        key="navigationGetStartedDialog"
        isOpen={getStartedOpen}
        onClosing={() => onGetStartedDialogVisible(false)}
      />
    </>
  );
};

export default Navigation;
