import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { buttonTypes } from '../../enums/buttonTypes';

const ThrottleButton = props => {
  const { onClick, limit, buttonType, buttonText, throttledButtonText, buttonIcon, buttonKey, enabled } = props;

  const [seconds, setSeconds] = useState(0);
  const [disabled, setDisabled] = useState(enabled === false);
  const [text, setText] = useState();

  useEffect(() => {
    setText(seconds > 0 ? throttledButtonText.replace(/{seconds}/, seconds) : buttonText);

    if (seconds === 0 && enabled !== false) {
      setDisabled(false);
      return;
    }

    const interval = setInterval(() => {
      setSeconds(seconds => seconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const buttonClick = () => {
    if (onClick && !disabled) {
      setSeconds(limit);
      setDisabled(true);
      onClick();
    }
  };

  return (
    <>
      {(() => {
        switch (buttonType) {
          case buttonTypes.LINK:
            return (
              <Button className={buttonType.cssClass} onClick={buttonClick} disabled={disabled}>
                {text}
              </Button>
            );

          case buttonTypes.ICON:
            return (
              <div>
                <figure className="sm-throttle-button-container">
                  <i
                    id={`buttonIcon_${buttonKey}`}
                    className={`material-icons icon-button ${disabled ? 'disabled' : ''}`}
                    onClick={buttonClick}
                  >
                    {buttonIcon.code}
                  </i>
                  <figcaption className={`icon-button-caption ${disabled ? 'disabled' : ''}`} onClick={buttonClick}>
                    {text}
                  </figcaption>
                </figure>
              </div>
            );

          case buttonType.NONE:
            return null;

          default:
            return (
              <Button className={buttonType.cssClass} onClick={buttonClick} disabled={disabled}>
                {text}
              </Button>
            );
        }
      })()}
    </>
  );
};

export default ThrottleButton;
