import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import Dropzone from 'react-dropzone';

import Sheet from '../Sheet/Sheet';
import UploadBillAcceptedIcons from './uploadBillAcceptedIcons';

import { ReactComponent as PlusIcon } from '../icons/util/plus.svg';
import { ReactComponent as DocValidIcon } from '../icons/util/doc-valid.svg';

import { billTypes } from '../../enums/billTypes';
import { truncateWithEllipses } from '../../utilities/commonUtilities';

const SubmitBillFileUploader = ({ files, setFiles, simpleMode }) => {
  const { areasSupportedDisclaimer } = useSelector(state => state.content.general);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const serviceTypeOptions = { includeExternal: false };
  const hasElectricity = billTypes.hasServiceType(agentBillTypes, billTypes.ELECTRICITY, serviceTypeOptions);
  const hasGas = billTypes.hasServiceType(agentBillTypes, billTypes.GAS, serviceTypeOptions);
  const hasInternet = billTypes.hasServiceType(agentBillTypes, billTypes.INTERNET, serviceTypeOptions);

  const File = ({ file, removeFile }) => {
    return (
      <li className={`list-group-item document document-${file.status}`} key={`${file.path}_item`}>
        <div className="icon-wrap">
          <DocValidIcon />
        </div>
        <div className="file-content">
          <span className="font-weight-bold">{truncateWithEllipses(file.name, 60)}</span>
          <span>
            <Button
              color="link"
              onClick={e => {
                e.stopPropagation();
                removeFile(file);
              }}
            >
              Remove Document
            </Button>
          </span>
        </div>
      </li>
    );
  };

  // --------------------------------------------------------------------------
  // File drop handler.
  // --------------------------------------------------------------------------
  const onDrop = acceptedFiles => {
    const allowMultiple = false;

    acceptedFiles.forEach(file => {
      // If file already exists skip to next iteration
      if (files.some(e => e.name === file.name)) return;

      var reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        var fileDataUrl = reader.result;
        var currentFile = { name: file.name, data: fileDataUrl, status: 'valid' };

        if (allowMultiple) {
          var f = [...files];
          f.push(currentFile);
          setFiles(f);
        } else {
          setFiles([currentFile]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const missingFileValidationMessage = (
    <div className="sm-upload-missing-files-validation-message">
      <AvField
        type="text"
        name="files"
        className="sm-validator-hidden"
        validate={{
          hidden: (value, ctx, input, cb) => {
            cb(!files || files.length === 0 ? 'Please select files before submitting' : true);
          }
        }}
      />
    </div>
  );

  const fileList = (
    <ul className="list-group file-list">
      {files.map(file => (
        <File
          key={`${file.path}_container`}
          file={file}
          removeFile={() => {
            setFiles([]);
          }}
        />
      ))}
    </ul>
  );

  return (
    <>
      <div className="sm-document-uploader">
        <Dropzone
          onDrop={onDrop}
          accept={{
            'application/pdf': ['.pdf']
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              {simpleMode ? (
                <div className="simple-mode">
                  {files.length === 0 ? (
                    <Button
                      className={`sm-button-primary medium static`}
                      onClick={e => {
                        e.preventDefault();
                      }}
                    >
                      Upload your e-bill
                    </Button>
                  ) : (
                    fileList
                  )}
                </div>
              ) : (
                <div className="drop-area">
                  {files.length === 0 ? (
                    <>
                      <div className="content">
                        <Sheet
                          className="add-file-sheet"
                          bgColor="var(--secondary-background)"
                          style={{ padding: '3em 1.5em' }}
                        >
                          <PlusIcon width="64" height="64" />
                        </Sheet>

                        <div className="help-text">
                          <p>Drag and drop any of these documents here or click the + to upload:</p>
                          <ul>
                            {hasElectricity && <li>Electricity e-bill</li>}
                            {hasGas && <li>Gas e-bill</li>}
                            {hasInternet && <li>Broadband e-bill</li>}
                          </ul>
                        </div>
                      </div>
                      <UploadBillAcceptedIcons />

                      <div className="bill-disclaimer">{areasSupportedDisclaimer}</div>
                    </>
                  ) : (
                    fileList
                  )}
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </div>

      {missingFileValidationMessage}
    </>
  );
};

export default SubmitBillFileUploader;
