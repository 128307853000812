import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import TermsAndConditionsDialog from '../dialogs/termsAndConditionsDialog';
import OrganiserName from '../common/organiserName';

const RegistrationIntroduction = () => {
  const { programName, programDisplayName } = useSelector(state => state.config);

  var title = `${programDisplayName}&trade; Registration`;

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="d-none d-sm-block" dangerouslySetInnerHTML={{ __html: title }} />
          <p>
            {programName} is a free service that helps to remove bill-pain by managing your household bills in one
            central location. You'll soon be able to access your current and past bills, pay your bills by responding to
            an SMS and if you are moving house, you'll be able to move multiple services in one locaton.
          </p>
          <p>
            If you choose to have your bills delivered to your <OrganiserName /> we'll ask your new retailer to email
            your future e-bills to a {programName} email address we set up for you. We'll immediately forward all
            correspondence and bills from your retailer to your regular email address. Importantly, when communicating
            with the retailer, and when you register on their website or any apps, you'll need to use your Organiser
            email address that we'll share with them.
          </p>
          <p>
            See <TermsAndConditionsDialog buttonText="Terms and Conditions" /> for more details.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default RegistrationIntroduction;
