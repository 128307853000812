import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

const CdrSelector = ({ onBackButtonClick }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  return (
    <>
      <div className="sm-submit-bill-selector-cdr">
        <div className="sub-heading">Coming Soon</div>
        <div className="cdr-logo">
          <img src={`${staticContentUrl}/images/util/cdr-logo.png`} alt="CDR Logo" />
        </div>

        <div className="intro-text">Conveniently access your energy data through the Consumer Data Right (CDR)</div>

        <div className="sm-modal-footer">
          <Button onClick={onBackButtonClick} className="sm-button-secondary">
            Go back
          </Button>
        </div>
      </div>
    </>
  );
};

export default CdrSelector;
