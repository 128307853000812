import axios from 'axios';
import timeAxios from '../utilities/timeAxios';
import { apiTiming } from '../utilities/googleAnalyticsEvents';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import { setServerMessage } from './serverMessageActions';
import * as serverMessageTargets from './serverMessageTargets';
import { internetConnectionTypeTypes } from '../enums/submissionTypes';
import { billTypes } from '../enums/billTypes';

export const getDefaultAddressStatus = () => {
  return {
    isLoaded: false,
    isAvailable: false,
    techType: 'FTTN',
    developmentCharges: false,
    connectionType: internetConnectionTypeTypes.NBN.Id
  };
};
// --------------------------------------------------------------------------
// Get NBN status action
// --------------------------------------------------------------------------
export function getNbnStatus(data, onComplete, options) {
  return dispatch => {
    const showSpinner = !options || options.showSpinner !== false;
    const applyServerMessage = !options || options.applyServerMessage !== false;

    if (showSpinner) dispatch(setAjaxCallStart(null, billTypes.INTERNET));

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'get', 'address-status');
    });

    axios
      .post('/api/internet/address-status', data)
      .then(response => {
        if (response.data) {
          var addressStatus = response.data;

          if (!addressStatus.isAvailable) {
            addressStatus = {
              ...addressStatus,
              ...getDefaultAddressStatus()
            };
          }

          onComplete(addressStatus);

          if (applyServerMessage) {
            dispatch(
              setServerMessage({
                code: response.status,
                scrollTo: true,
                messages: [
                  {
                    field: '',
                    message: addressStatus.message,
                    isHtml: true
                  }
                ],
                target: serverMessageTargets.INTERNET_SPECIFIC
              })
            );
          }
        } else {
          onComplete(getDefaultAddressStatus());
        }
      })
      .catch(error => {
        onComplete(getDefaultAddressStatus());

        if (applyServerMessage) {
          dispatch(
            setServerMessage({
              ...error.response.data,
              scrollTo: true,
              target: serverMessageTargets.INTERNET_SPECIFIC,
              code: error.response.status
            })
          );
        }
      })
      .then(function() {
        if (showSpinner) dispatch(setAjaxCallEnd());
      });
  };
}
