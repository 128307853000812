import React from 'react';

import Autocomplete from 'react-autocomplete-select';

const SurveyBroker = ({ brokerName, onChange }) => {
  return (
    <div className="sm-agent-member">
      <Autocomplete
        itemsData={[]}
        placeholder={'Please type broker name'}
        maxOptionsLimit={10}
        searchEnabled={true}
        searchPattern={'containsString'}
        defaultInputValue={brokerName}
        getItemValue={item => {
          return item;
        }}
        onChange={value => {
          onChange(value);
        }}
        onSelect={value => {
          onChange(value);
        }}
        axiosConfig={value => ({
          url: `/api/survey/brokers/?search=${value}`,
          method: 'GET'
        })}
        inputJSX={props => <input {...props} className="qs-auto-complete form-control form-control-sm" />}
        globalStyle=".qs-auto-complete { margin-top: -20px; } .autocomplete { width:100%; }"
      />
    </div>
  );
};

export default SurveyBroker;
