import React, { useEffect } from 'react';
import { push, goBack } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { setArticles } from '../actions/articleActions';
import { Button, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getTags, getReleaseDate } from '../utilities/articleUtilities';
import { homePageTypes } from '../enums/launchTypes';
import { goHome } from '../actions/comparisonActions';

const ArticlesScreen = props => {
  const dispatch = useDispatch();

  const { articles } = useSelector(state => state);
  const { hasArticles } = useSelector(state => state.config);
  const { articlesEnabled } = useSelector(state => state.config.settings);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  const { homePageType } = useSelector(state => state.config.settings);

  const homePage = homePageTypes.getById(homePageType);

  useEffect(() => {
    if (articlesEnabled && hasArticles) {
      dispatch(setArticles());
    } else {
      dispatch(goHome());
    }
  }, []);

  const getFilteredArticles = () => {
    if (!articles.isLoaded) return articles;

    const tag = props.match.params.tag;

    return tag
      ? articles.items.filter(
          item =>
            item.tags.findIndex(aTag => {
              return aTag === tag;
            }) !== -1
        )
      : articles.items;
  };

  const filteredArticles = getFilteredArticles();

  const getHeading = item => {
    return <Link to={`/article/${item.slug}`}>{item.headline}</Link>;
  };

  return (
    <>
      <Helmet>
        <title>Latest articles</title>
      </Helmet>

      <Container>
        <Row>
          <Col xs="12">
            <hr />
          </Col>
          {articles.isLoaded ? (
            <>
              {filteredArticles.map((item, i) => (
                <React.Fragment key={`article_${i}`}>
                  {i === 0 ? (
                    <>
                      <Col xs="12" lg="6">
                        <div className="sm-article-heading-tag">{getTags(item.tags)}</div>
                        <h2 className="sm-article-heading">{getHeading(item)}</h2>
                        <div className="sm-article-main-summary">
                          {item.summary}{' '}
                          <Link to={`/article/${item.slug}`} className="sm-article-more-link">
                            more
                          </Link>
                        </div>
                        <div className="sm-article-summary-release-date">{getReleaseDate(item)}</div>
                      </Col>
                      <Col xs="12" lg="6">
                        <img
                          className="sm-article-main-summary-image"
                          src={`${staticContentUrl}${item.image}`}
                          alt={item.headline}
                        />
                      </Col>
                      <Col xs="12">
                        <hr />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs="12" lg="6">
                        <div className="sm-article-heading-tag">{getTags(item.tags)}</div>
                        <h3 className="sm-article-heading">{getHeading(item)}</h3>

                        <Container style={{ padding: '0' }}>
                          <Row noGutters={true}>
                            <Col xs="12" lg="6">
                              <div className="sm-article-summary">
                                {item.summary}{' '}
                                <Link to={`/article/${item.slug}`} className="sm-article-more-link">
                                  more
                                </Link>
                                <div className="sm-article-summary-release-date">{getReleaseDate(item)}</div>
                              </div>
                            </Col>
                            <Col xs="12" lg="6">
                              <img
                                className="sm-article-summary-image"
                                src={`${staticContentUrl}${item.image}`}
                                alt={item.headline}
                              />
                            </Col>
                            <Col xs="6" className="d-block d-lg-none">
                              <hr />
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                      {i % 2 === 0 ? (
                        <Col xs="12" className="d-none d-lg-block">
                          <hr />
                        </Col>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </Row>

        <Row className="qs-mt-lg qs-mb-lg">
          <Col sm="12" md={{ size: 4, offset: 4 }}>
            <Button
              className="sm-button-primary"
              style={{ width: '100%' }}
              onClick={() => (window.history.length > 2 ? dispatch(goBack()) : dispatch(push(homePage.path)))}
            >
              Back
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ArticlesScreen;
