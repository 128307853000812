import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

import {
  contentTypes,
  getContentCode,
  resetTermsAndConditions,
  setTermsAndConditions
} from '../../actions/contentActions';
import { postFrameDialogMessage } from '../../utilities/domUtilities';
import { pageModes } from '../../enums/launchTypes';
import { billTypes } from '../../enums/billTypes';

const TermsAndConditionsDialog = ({ onOpen, buttonText, serviceType }) => {
  const dispatch = useDispatch();

  const { pageMode, frameHeight, frameHost } = useSelector(state => state.application);
  const { document, title, versionNumber, loading, code } = useSelector(state => state.content.termsAndConditions);
  const { supplierId } = useSelector(state => state.selectedOffer);

  const [dialogOpen, setDialogOpen] = useState();

  const ref = useRef(null);

  const getContent = () => {
    switch (serviceType) {
      case billTypes.SOLAR_FINANCE:
        break;

      default:
        const currentCode = getContentCode(contentTypes.TERMS_AND_CONDITIONS, supplierId);

        if (currentCode !== code) {
          dispatch(resetTermsAndConditions());
          dispatch(setTermsAndConditions(supplierId));
        }
    }
  };

  const getTitle = () => {
    switch (serviceType) {
      case billTypes.SOLAR_FINANCE:
        return `${serviceType.Name} Terms and Conditions`;

      default:
        return title;
    }
  };

  const getDocument = () => {
    switch (serviceType) {
      case billTypes.SOLAR_FINANCE:
        return (
          <div className="mt-3">
            <p>By choosing to use a SmartMe Certified Finance Expert ("SCFE"), you agree with SmartMe that:</p>
            <ul>
              <li>
                you consent to SmartMe providing your personal information and other details to the SCFE (which may be
                provided via a mortgage broker aggregator) for the purpose of the SCFE providing you with more
                information about Home Loans and (at your election) providing you with assistance to apply for a Home
                Loan;
              </li>
              <li>a SCFE may be a third party</li>
              <li>SmartMe will have no involvement in or knowledge of the interactions between you and the SCFE. </li>
              <li>
                we will have no liability to you in relation to your dealings with the SCFE including any advice or
                recommendations they may make;
              </li>
              <li>
                each SCFE is providing credit services under their own Australian Credit Licence, or as a Credit
                Representative under an Australian Credit Licence. SCFEs are not SmartMe's Credit Representatives.
                SmartMe does not provide credit assistance;{' '}
              </li>
              <li>we may contact you to seek your response to a survey in relation to your dealings with the SCFE;</li>
              <li>
                we (or one of our related companies) may receive a commission or other payment in relation to the
                referral
              </li>
              <li>"We" and "us" means SmartMe Australia Pty Limited (ABN: 68 603 327 377)</li>
            </ul>
          </div>
        );

      default:
        return (
          <>
            <div dangerouslySetInnerHTML={{ __html: document }} />
            <div>v{versionNumber}</div>
          </>
        );
    }
  };

  useEffect(() => {
    // Load content here in framed mode as we need to establish the height of the dialog for frame resizing.
    if (pageMode === pageModes.FRAMED) {
      getContent();
    }
  }, []);

  const onDialogToggle = event => {
    // Prevent checkbox from being toggled in cases where dialog button is being used in the label
    event.stopPropagation();

    setDialogOpen(!dialogOpen);
  };

  const handleModal = () => {
    if (pageMode === pageModes.FRAMED) {
      dispatch(postFrameDialogMessage(frameHost, 'Terms and Conditions', dialogOpen, ref.current, frameHeight));
    } else if (dialogOpen) {
      getContent();
    }

    if (dialogOpen && onOpen) {
      onOpen();
    }
  };

  return (
    <>
      <Button className="sm-link-button inline" onClick={onDialogToggle}>
        {buttonText}
      </Button>

      <Modal
        onOpened={handleModal}
        onClosed={handleModal}
        toggle={onDialogToggle}
        isOpen={dialogOpen}
        size="lg"
        centered={pageMode === pageModes.DEFAULT}
      >
        <div ref={ref}>
          <ModalBody>
            {!loading && (
              <Container>
                <Row>
                  <Col xs="10" className="qs-modal-heading">
                    <h1>{getTitle()}</h1>
                  </Col>
                  <Col xs="2">
                    <div onClick={onDialogToggle} className="qs-modal-close-icon">
                      <i className="material-icons">close</i>
                    </div>
                  </Col>
                </Row>

                <Row className="qs-modal-body">
                  <Col>{getDocument()}</Col>
                </Row>
              </Container>
            )}
          </ModalBody>
          <ModalFooter className="sm-modal-footer">
            <Button onClick={onDialogToggle}>Close</Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default TermsAndConditionsDialog;
