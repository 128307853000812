import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import InfoPopover from './infoPopover';

const OfferEnergyUs = ({ offerIndex, offer, comparison }) => {
  return (
    <>
      <Row>
        <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }} lg={{ size: 8, order: 1 }}>
          <Container style={{ margin: 0, padding: 0 }}>
            <Row noGutters={true}>
              <Col xs="12"></Col>
              <div className="qs-summary-energy-plan_title">
                {offer.name} <span className="qs-normal-weight">- {offer.providerDisplayCode}</span>
              </div>
            </Row>
            <Row id={`offer_pricing_${offerIndex}`} noGutters={true}>
              <Col xs="3" md="3" lg="3">
                <div className="qs-summary-energy-us-pricing">
                  {offer.customerEnergyChargeAverage.averagePricePerKwhDisplay}c
                </div>
                <div className="qs-summary-energy-us-pricing-disclaimer">
                  per {comparison.submission.energyTypeDisplay}
                  <br />
                  at {offer.customerEnergyChargeAverage.usage} {comparison.submission.energyTypeDisplay}
                </div>
              </Col>
              <Col xs="6" md="6" lg="5">
                <div className="qs-summary-energy-us-pricing">
                  {offer.customerEnergyChargeAverage.estimatedChargeDisplay}{' '}
                  <InfoPopover
                    idType="monthly-bill-estimate"
                    heading="Monthly bill estimate"
                    body="Estimates include recurring charges excluding government fees and taxes. Estimates are based on information provided by energy providers and your usage estimates. Study information from the plan’s Electricity Facts Label and Terms of Service to confirm these estimates. Rates may vary according to your actual electricity usage."
                    action="monthly-bill-estimate-popover"
                    offer={offer}
                  />
                </div>
                <div className="qs-summary-energy-us-pricing-disclaimer">
                  monthly bill estimate <br />
                  for {offer.customerEnergyChargeAverage.usage} {comparison.submission.energyTypeDisplay}
                </div>
              </Col>
              <Col xs="3" md="3" lg="4">
                {offer.percentageBillReductionAmountGuaranteed > 0 && (
                  <>
                    <div
                      className={`qs-summary-energy-us-pricing-highlighted${
                        offer.isBestOverallOffer ? '' : ' qs-summary-energy-us-pricing-highlighted-secondary'
                      }`}
                    >
                      {offer.percentageBillReductionAmountGuaranteedDisplayShort.replace('%', '')}
                      <span style={{ fontSize: '.5em' }}>%</span>{' '}
                    </div>
                    <div
                      className={`qs-summary-energy-us-pricing-disclaimer-highlighted${
                        offer.isBestOverallOffer ? '' : ' qs-summary-energy-us-pricing-disclaimer-highlighted-secondary'
                      }`}
                    >
                      saving available
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row className="d-block d-sm-none">
              <Col xs="12">
                <div className="qs-mt-md">Term: {offer.termDisplay}</div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col
          xs={{ size: 12, order: 1 }}
          md={{ size: 5, order: 2 }}
          lg={{ size: 4, order: 2 }}
          className="qs-provider-logo"
        >
          <Container>
            <Row>
              <Col xs="12" md="12" className="qs-provider-logo-energy-us">
                <img src={offer.providerLogoUrl} alt={offer.providerDisplayCode} />
                {/* {offer.indicator === 'sponsor' && <div className="qs-sponsor">Sponsor offer</div>} */}
              </Col>
              <Col xs="12" className="d-none d-sm-block">
                <div className="qs-summary-plan-name qs-mt-lg">Term: {offer.termDisplay}</div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default OfferEnergyUs;
