import { setFrameDialog } from '../actions/applicationActions';
import { generateReferenceCode } from './commonUtilities';

export const PAGE_HEIGHT_BUFFER = 5; // additional height for setting frame height, to avoid scroll bars in the iframe
export const DIALOG_HEIGHT_BUFFER = 130; // additional height for setting frame height when dialogs are show, to avoid scroll bars in the iframe and allow for shadow styling

export const removeModalOpen = () => {
  if (document.body.classList.contains('modal-open')) document.body.classList.remove('modal-open');
};

export const applyModalOpen = () => {
  if (!document.body.classList.contains('modal-open')) document.body.classList.add('modal-open');
};

export const frameMessageTypes = {
  RESIZE: { id: 1, code: 'resize' },
  DIALOG: { id: 2, code: 'dialog' },
  SCROLL_POSITION: { id: 3, code: 'getScrollPosition' },
  SCROLL_TOP: { id: 4, code: 'scrollTop' },
  HIDE: { id: 5, code: 'hide' }
};

export const postFrameMessage = (target, type, message, privateMessage) => {
  const messageId = generateReferenceCode();

  window.parent.postMessage({ messageId, type: type.code, private: privateMessage ? true : false, ...message }, target); // '*' to send it to any origin

  return messageId;
};

export const postFrameDialogMessage = (target, dialogName, dialogOpen, modal, frameHeight) => {
  return dispatch => {
    dispatch(setFrameDialog(dialogOpen));

    postFrameMessage(target, frameMessageTypes.DIALOG, {
      name: dialogName,
      opened: dialogOpen,
      height: dialogOpen ? modal.offsetHeight + DIALOG_HEIGHT_BUFFER : frameHeight
    });
  };
};

export const postFrameScrollTopMessage = target => {
  return postFrameMessage(target, frameMessageTypes.SCROLL_TOP, {}, true);
};

export const isFramed = () => {
  return window.self !== window.top;
};
