import React, { useState } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import QsButtonGroup from '../common/qsButtonGroup';

const LifeSupportQuestions = ({ formRef, confirmDetail, onChange }) => {
  const [infoOpen, setInfoOpen] = useState(false);

  const onChangeLifeSupport = event => {
    const option = event.target.getAttribute('data-value');

    if (formRef && formRef.current) {
      let inputs = {
        hiddenLifeSupport: formRef.current._inputs['hiddenLifeSupport']
      };
      formRef.current.setTouched(Object.keys(inputs));
    }

    onChange(null, { lifeSupport: option });
  };

  const validateHiddenLifeSupport = (value, ctx, input, cb) => {
    if (value === 'false') {
      cb('Unfortunately we can not process requests requiring life support equipment');
    } else cb(true);
  };

  return (
    <>
      <div className="label">
        Does anyone residing or intending to reside at your property/premises require life support equipment?
        <i id={'lifeSupportInfo'} className="material-icons qs-info" onClick={() => setInfoOpen(!infoOpen)}>
          info
        </i>
      </div>
      <QsButtonGroup
        id="sorter"
        fieldName="Sort"
        selectedValue={confirmDetail.lifeSupport + ''}
        onTouchButton={onChangeLifeSupport}
        additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
        buttons={[
          {
            label: 'Yes',
            value: 'false'
          },
          {
            label: 'No',
            value: 'true'
          }
        ]}
      />
      <AvField
        bsSize="sm"
        type="text"
        name="hiddenLifeSupport"
        value={confirmDetail.lifeSupport + ''}
        className="sm-validator-hidden"
        validate={{ validateHiddenLifeSupport }}
      />

      <Popover placement="auto" isOpen={infoOpen} target={'lifeSupportInfo'} onClick={() => setInfoOpen(!infoOpen)}>
        <PopoverHeader>
          Why do we ask this?<i className="material-icons qs-close">clear</i>
        </PopoverHeader>
        <PopoverBody>
          <div>
            If you, or anyone at your home depends on life support, this needs to be registered on your account. If
            applicable, your energy retailer and local distributor can give you extra protections with your power
            supply. You are classified as being life support dependent if you depend on any of the following life
            support equipment:
            <br />
            <br />
            <ul>
              <li>Oxygen concentrator</li>
              <li>Intermittent peritoneal dialysis machine</li>
              <li>Kidney dialysis machine</li>
              <li>Chronic positive airways pressure respirator (CPAP)</li>
              <li>Crigler najjar syndrome phototherapy equipment</li>
              <li>Ventilator for life support</li>
              <li>
                Any other equipment (whether fuelled by electricity or gas) that a registered medical practitioner
                certifies is required for a person residing at the customer's premises for life support or otherwise
                where the customer provides a current medical certificate certifying that a person residing at the
                customer's premises has a medical condition which requires continued supply of electricity or gas.
              </li>
            </ul>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default LifeSupportQuestions;
