import moment from 'moment';
import { billTypes } from '../../enums/billTypes';
import { submissionTypes } from '../../enums/submissionTypes';
import { getAddress } from '../../utilities/addressUtilities';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import {
  submitEstimateEnergyDefault,
  submitEstimateInternetDefault,
  submitEstimateHomeLoanDefault
} from '../../actions/energyEstimateActions';
import { getNbnStatus } from '../../actions/internetActions';
import { uploadBillTypes } from '../../enums/uploadBillTypes';

const getServiceCardData = (e, agentServiceType) => {
  const serviceType = billTypes.getById(e.billTypeId);
  const isEmpty = e.status === 0;
  const isBill = e.submissionType === submissionTypes.BILL;
  const isEstimate = e.submissionType === submissionTypes.ESTIMATE;
  const isInternet = serviceType === billTypes.INTERNET;
  const isSolarFinance = serviceType === billTypes.SOLAR_FINANCE;
  const isDefaultComparison = isEstimate && e.estimate.defaultComparison;
  const submission = isEstimate ? e.estimate : e.bill;

  let buttonText;

  let notificationCount = 0;
  let reductionDescription;
  let reductionPercentage;
  let reductionAmountAnnual = isEmpty ? null : 0;
  let nextCompareDate;

  const hasProvider = !isEmpty && e.bill && e.bill.provider;

  const convertedProviderCode = e.providerCode;
  const convertedProviderDisplayCode = e.providerDisplayCode;
  const convertedProviderLogo = e.providerLogo;

  const providerLogo = hasProvider ? e.bill.provider.logo : null;
  const providerCode = hasProvider ? e.bill.provider.code : null;
  const providerDisplayCode = hasProvider ? e.bill.provider.displayCode : null;
  const billDays = hasProvider ? e.bill.billDays : 0;
  const billAmountCurrent = hasProvider
    ? e.bill.totalChargesDiscountedDisplay
    : isInternet && e.estimate
    ? e.estimate.totalChargesDiscountedDisplay
    : 0;

  const percentagePeakDisplay = hasProvider ? e.bill.percentagePeakDisplay : null;
  const percentageOffPeakDisplay = hasProvider ? e.bill.percentageOffPeakDisplay : null;

  switch (e.status) {
    case 0: // Empty
      buttonText =
        agentServiceType.hasAffiliate || agentServiceType.partner
          ? 'Get Organised'
          : serviceType.ComparisonImplemented
          ? 'Get Organised'
          : 'Coming Soon';
      break;

    case 1: // Compared
    case 3: // Cancelled
      buttonText = 'View Offers';

      break;
    case 2: // Converted
      const switched = !e.reductionLoaded || e.errorMessages || e.awaitingLink ? true : false;
      if (isSolarFinance) buttonText = `${switched ? 'New Request' : 'New Request'}`;
      else buttonText = `${switched ? 'Check Now' : 'Check Now'}`;
      break;

    default:
      break;
  }

  if (e.reductionLoaded) {
    if (isEstimate && e.bestOffer) {
      switch (serviceType) {
        case billTypes.HOME_LOAN:
          reductionDescription =
            e.reductionAmountAnnual > 0
              ? `Pay ${e.bestOffer.percentageBillReductionAmountLowestDisplayShort} less in monthly repayments`
              : 'View offers to save in less than 30 seconds';
          break;
        case billTypes.INTERNET:
          reductionDescription = 'Are you paying more than this?';
          break;
        default:
          reductionDescription = e.bestOffer.marketOfferDisclaimerHeading
            ? `Pay ${e.bestOffer.marketOfferDisclaimerHeading}`
            : 'Are you paying more than this?';
          break;
      }
    }

    if (e.reductionAmountAnnual > 0) {
      reductionPercentage = e.reductionPercentage;
      reductionAmountAnnual = e.reductionAmountAnnual;

      notificationCount = 1;
    } else {
      if (e.nextCompareDateUtc) {
        nextCompareDate = moment(moment.utc(e.nextCompareDateUtc).toDate())
          .local()
          .format('LL');
      } else if (e.reductionLoaded && !e.errorMessages && !e.awaitingLink) {
        buttonText = 'View Offers';
      }

      reductionPercentage = 0;
      reductionAmountAnnual = 0;
    }
  }

  if (isDefaultComparison) {
    buttonText = 'Continue';
  }

  return {
    id: `serviceCard_${e.submissionId ? e.submissionId : e.billTypeId}`,
    implementationCode: e.implementationCode,
    serviceType,
    convertedDateUtc: e.convertedDateUtc,
    convertedProviderCode,
    convertedProviderDisplayCode,
    convertedProviderLogo,
    providerLogo,
    providerCode,
    providerDisplayCode,
    address: e.displayAddress,
    status: e.status,
    billingType: e.billingType,
    isLinked: e.linked,
    isAwaitingLink: e.awaitingLink,
    nextCompareDate: nextCompareDate,
    isEmpty,
    isBill,
    isEstimate,
    isDefaultComparison,
    submission,
    buttonText,
    notificationCount,
    billDays,
    billAmountCurrent,
    submissionId: e.submissionId,
    submissionType: e.submissionType,
    reductionLoaded: e.reductionLoaded,
    reductionExpiry: e.reductionExpiry,
    reductionDescription,
    reductionPercentage,
    reductionAmountAnnual,
    percentagePeakDisplay,
    percentageOffPeakDisplay,
    bestOffer: e.bestOffer,
    accessKey: e.accessKey,
    errorMessages: e.errorMessages
  };
};

const getSubmission = userBill => {
  switch (userBill.submissionType) {
    case submissionTypes.BILL:
      return userBill.bill;

    case submissionTypes.ESTIMATE:
      return userBill.estimate;

    default:
      return {};
  }
};

const getNextServices = (serviceType, address, agentBillTypes, userBills) => {
  const services = [];

  const addressOptions = {
    unitNumber: true
  };

  const selectedAddress = getAddress(address, addressOptions);

  const addService = billType => {
    const serviceTypeOptions = { includeExternal: false };

    if (billType === serviceType || !billTypes.hasServiceType(agentBillTypes, billType, serviceTypeOptions)) return;

    const results = [...userBills]
      .sort((a, b) => {
        return (
          (a.submissionType === submissionTypes.BILL ? 0 : 1) - (b.submissionType === submissionTypes.BILL ? 0 : 1)
        );
      })
      .filter(e => {
        if (e.deletedDateUtc) return false;
        if (e.submissionType === submissionTypes.NONE) return false;

        const submission = getSubmission(e);

        if (!submission.propertyAddress) return false;

        const submissionAddress = getAddress(submission.propertyAddress, addressOptions);

        return selectedAddress.fullAddress === submissionAddress.fullAddress && e.billTypeId === billType.Id;
      });

    // Check if any cards in result are already in converted state.
    if (results && results.length > 0 && results.find(e => e.status !== 2)) {
      services.push(results[0]); // add first card
    }
  };

  addService(billTypes.ELECTRICITY);
  addService(billTypes.GAS);
  addService(billTypes.INTERNET);

  return services;
};

const getAutoServices = (serviceType, address, agentBillTypes, userBills) => {
  const services = [];

  if (!address.isAddress) return services;

  const addressOptions = {
    unitNumber: true
  };

  const selectedAddress = getAddress(address, addressOptions);

  const addService = billType => {
    const serviceTypeOptions = { includeExternal: false };

    if (billType === serviceType || !billTypes.hasServiceType(agentBillTypes, billType, serviceTypeOptions)) return;

    const result = userBills.filter(e => {
      if (e.deletedDateUtc) return false;
      if (e.submissionType === submissionTypes.NONE) return false;

      const submission = getSubmission(e);

      if (!submission.propertyAddress) return false;

      const submissionAddress = getAddress(submission.propertyAddress, addressOptions);

      return selectedAddress.fullAddress === submissionAddress.fullAddress && e.billTypeId === billType.Id;
    });

    if (result.length === 0) {
      services.push(billType);
    }
  };

  addService(billTypes.ELECTRICITY);
  addService(billTypes.GAS);
  addService(billTypes.INTERNET);
  addService(billTypes.HOME_LOAN);

  return services;
};

const submitInternetAutoEstimate = (selectedAddress, selectedAgent, sessionActivity, submissionCommon) => {
  return dispatch => {
    const submitEstimate = status => {
      dispatch(
        submitEstimateInternetDefault(
          selectedAddress,
          selectedAgent,
          sessionActivity,
          submissionCommon,
          null,
          status.techType,
          status.connectionType,
          status.developmentCharges,
          { showSpinner: false }
        )
      );
    };

    dispatch(
      getNbnStatus(
        {
          address: { ...selectedAddress }
        },
        submitEstimate,
        // do not set server message for auto estimates (user does not see it, and it could clear out a current server message on the dialog for the initiating comparison; e.g. "No offers found")
        { showSpinner: false, applySleep: false, applyServerMessage: false }
      )
    );
  };
};

const submitAutoEstimates = (
  serviceType,
  selectedAddress,
  selectedAgent,
  sessionActivity,
  submissionCommon,
  agentBillTypes,
  userBills,
  googleAnalyticsClientCode
) => {
  return dispatch => {
    const autoServices = getAutoServices(serviceType, selectedAddress, agentBillTypes, userBills);
    const googleAnalyticsCategory = 'Auto-Estimate';
    const googleAnalyticsLabel = `via-${serviceType.Code}`;

    const autoSubmissionCommon = {
      ...submissionCommon,
      termsAndConditions: true,
      sendComparisonEmail: false,
      sendArticlesEmail: false,
      sendSolarInstallationEmail: false
    };

    if (autoServices.length > 0) {
      autoServices.forEach(e => {
        switch (e) {
          case billTypes.ELECTRICITY:
            dispatch(
              submitEstimateEnergyDefault(
                billTypes.ELECTRICITY,
                selectedAddress,
                selectedAgent,
                sessionActivity,
                autoSubmissionCommon,
                { showSpinner: false }
              )
            );

            standardEvents(
              googleAnalyticsCategory,
              billTypes.ELECTRICITY.Code,
              googleAnalyticsLabel,
              googleAnalyticsClientCode
            );
            break;

          case billTypes.GAS:
            dispatch(
              submitEstimateEnergyDefault(
                billTypes.GAS,
                selectedAddress,
                selectedAgent,
                sessionActivity,
                autoSubmissionCommon,
                { showSpinner: false }
              )
            );

            standardEvents(
              googleAnalyticsCategory,
              billTypes.GAS.Code,
              googleAnalyticsLabel,
              googleAnalyticsClientCode
            );
            break;

          case billTypes.INTERNET:
            dispatch(submitInternetAutoEstimate(selectedAddress, selectedAgent, sessionActivity, autoSubmissionCommon));

            standardEvents(
              googleAnalyticsCategory,
              billTypes.INTERNET.Code,
              googleAnalyticsLabel,
              googleAnalyticsClientCode
            );
            break;

          case billTypes.HOME_LOAN:
            dispatch(
              submitEstimateHomeLoanDefault(
                selectedAddress,
                selectedAgent,
                sessionActivity,
                autoSubmissionCommon,
                500000,
                1000,
                { showSpinner: false }
              )
            );

            standardEvents(
              googleAnalyticsCategory,
              billTypes.HOME_LOAN.Code,
              googleAnalyticsLabel,
              googleAnalyticsClientCode
            );
            break;

          default:
            return;
        }
      });
    }
  };
};

const determineUploadBillType = (partner, affiliate, agentServiceType) => {
  if (partner) return uploadBillTypes.PARTNER;
  else if (affiliate) return uploadBillTypes.AFFILIATE;
  else {
    switch (agentServiceType.serviceType.Id) {
      case billTypes.SOLAR_FINANCE.Id:
        return uploadBillTypes.MANUAL;
      default:
        return uploadBillTypes.NONE;
    }
  }
};

export {
  getServiceCardData,
  getSubmission,
  getAutoServices,
  getNextServices,
  submitAutoEstimates,
  determineUploadBillType
};
