let getDistinctSolarFeedInRates = function(bill) {
  if (!bill.hasSolar || !bill.solarFeedIn) return null;

  return [...new Set(bill.solarFeedIn.map(x => Math.abs(x.chargeExGst * 100.0).toFixed(1) + 'c'))];
};

let getDistinctSolarFeedInRatesDisplay = function(bill) {
  var solarFeedInRates = getDistinctSolarFeedInRates(bill);
  if (solarFeedInRates == null) return null;

  var ratesForDisplay = '';
  for (var i = 0; i < solarFeedInRates.length; i++) {
    ratesForDisplay += solarFeedInRates[i];
    ratesForDisplay += i + 1 === solarFeedInRates.length ? '' : i + 2 === solarFeedInRates.length ? ' and ' : ', ';
  }
  return ratesForDisplay;
};

let hasConditionalDiscounts = function(offers) {
  let conditionalOffers = offers.reduce(
    (accumulator, currentValue) =>
      (accumulator += currentValue.billAmountLowest !== currentValue.billAmountGuaranteed ? 1 : 0),
    0
  );

  return conditionalOffers > 0;
};

export { getDistinctSolarFeedInRates, getDistinctSolarFeedInRatesDisplay, hasConditionalDiscounts };
