import { getLocalStorage, setLocalStorage } from './localStorageUtilities';

export const userPreferenceTypes = {
  NONE: { id: 0, code: 'none' },
  TOURS: { id: 1, code: 'showTours' }
};

export const getUserPreferences = () => {
  const localStorageResult = getLocalStorage('userPreferences');
  const localSettings = localStorageResult.value;

  const userPreferences = localSettings ? JSON.parse(localSettings) : { [userPreferenceTypes.TOURS.code]: true };

  return userPreferences;
};

export const setUserPreferences = userPreferences => {
  setLocalStorage('userPreferences', JSON.stringify(userPreferences));
};
