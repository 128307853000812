import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { setPrivacyPolicy, resetPrivacyPolicy, getContentCode, contentTypes } from '../../actions/contentActions';
import { postFrameDialogMessage } from '../../utilities/domUtilities';
import { pageModes } from '../../enums/launchTypes';

const PrivacyPolicyDialog = ({ dialogOpenState, onOpen, buttonClass, buttonText }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const { pageMode, frameHeight, frameHost } = useSelector(state => state.application);
  const { document, title, versionNumber, loading, code } = useSelector(state => state.content.privacyPolicy);
  const { supplierId } = useSelector(state => state.selectedOffer);

  const [dialogOpen, setDialogOpen] = useState(dialogOpenState);

  const getContent = () => {
    const currentCode = getContentCode(contentTypes.PRIVACY_POLICY, supplierId);

    if (currentCode !== code) {
      dispatch(resetPrivacyPolicy());
      dispatch(setPrivacyPolicy(supplierId));
    }
  };

  useEffect(() => {
    // Load content here in framed mode as we need to establish the height of the dialog for frame resizing.
    if (pageMode === pageModes.FRAMED) {
      getContent();
    }
  }, []);

  const onDialogToggle = event => {
    // Prevent checkbox from being toggled in cases where dialog button is being used in the label
    event.stopPropagation();

    setDialogOpen(!dialogOpen);
  };

  const handleModal = () => {
    if (pageMode === pageModes.FRAMED) {
      dispatch(postFrameDialogMessage(frameHost, 'Privacy Policy', dialogOpen, ref.current, frameHeight));
    } else if (dialogOpen) {
      getContent();
    }

    if (dialogOpen && onOpen) {
      onOpen();
    }
  };

  return (
    <>
      <Button className={`sm-link-button inline ${buttonClass}`} onClick={onDialogToggle}>
        {buttonText}
      </Button>

      <Modal
        onOpened={handleModal}
        onClosed={handleModal}
        toggle={onDialogToggle}
        isOpen={dialogOpen}
        size="lg"
        centered={pageMode === pageModes.DEFAULT}
      >
        <div ref={ref}>
          <ModalBody>
            {!loading && (
              <Container>
                <Row>
                  <Col xs="10" className="qs-modal-heading">
                    <h1>{title}</h1>
                  </Col>
                  <Col xs="2">
                    <div onClick={onDialogToggle} className="qs-modal-close-icon">
                      <i className="material-icons">close</i>
                    </div>
                  </Col>
                </Row>

                <Row className="qs-modal-body">
                  <Col xs="12">
                    <div dangerouslySetInnerHTML={{ __html: document }} />
                    <div>v{versionNumber}</div>
                  </Col>
                </Row>
              </Container>
            )}
          </ModalBody>
          <ModalFooter className="sm-modal-footer">
            <Button onClick={onDialogToggle}>Close</Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default PrivacyPolicyDialog;
