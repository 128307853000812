import axios from 'axios';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';

export function getBankBranch(bsbNumber, callBack) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    axios
      .get(`/api/info/bsb/${bsbNumber}`)
      .then(response => {
        if (response.data) {
          var result = response.data;
          callBack(result);
        }
      })
      .catch(() => {
        callBack({ success: false });
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}
