import * as types from './actions';
import axios from 'axios';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import { setSelectedAgentSuccess } from './configActions';
import { openSubmitDialog } from './dialogOptionsActions';
import { setUser } from './userActions';
import { getSubmission, submitAutoEstimates } from '../components/serviceCard/serviceCardUtilities';
import { displayErrorMessage, displayNoOffersMessage } from './serverMessageActions';
import * as serverMessageTargets from './serverMessageTargets';

import { push } from 'connected-react-router';
import timeAxios from '../utilities/timeAxios';
import { apiTiming } from '../utilities/googleAnalyticsEvents';
import { sleep } from '../utilities/compareUtilities';
import * as landingActions from '../actions/landingActions';
import { submissionTypes } from '../enums/submissionTypes';
import { contextTypes } from '../enums/submissionTypes';
import { billTypes } from '../enums/billTypes';
import { serviceCardLoadingTypes } from '../enums/serviceCardLoadingTypes';
import { homePageTypes } from '../enums/launchTypes';
import { uploadBillTypes, uploadSubTypes } from '../enums/uploadBillTypes';

export function setComparisonSuccess(comparison) {
  return { type: types.SET_COMPARISON, comparison };
}

export function setUserBillsSuccess(data) {
  return { type: types.SET_USER_BILLS, data: { ...data } };
}

export function setUserBillSuccess(data) {
  return { type: types.SET_USER_BILL, data: { ...data } };
}

export function convertUserBillSuccess(data) {
  return { type: types.CONVERT_USER_BILL, data: { ...data } };
}

export function deleteUserBillSuccess(data) {
  return { type: types.DELETE_USER_BILL, data: { ...data } };
}

export function restoreUserBillSuccess(data) {
  return { type: types.RESTORE_USER_BILL, data: { ...data } };
}

export function goDashboard() {
  return dispatch => {
    dispatch(push('/dashboard'));
  };
}

export function goHome() {
  return (dispatch, getState) => {
    const currentState = getState();
    if (currentState && currentState.config) {
      const { homePageType } = currentState.config.settings;
      const homePage = homePageTypes.getById(homePageType);
      dispatch(push(homePageType && homePage ? homePage.path : homePageTypes.DEFAULT.path));
    } else {
      dispatch(push(homePageTypes.DEFAULT.path));
    }
  };
}

export function setComparison(comparison) {
  return dispatch => {
    dispatch(setComparisonSuccess(comparison));
  };
}

// ----------------------------------------------------------------------------
// Gets a comparison with an internal access key.
// ----------------------------------------------------------------------------
export function getComparisonWithKey(
  accessKey,
  options,
  submissionType,
  serviceType,
  serviceCardLoading,
  autoEstimatesArgs,
  sessionActivity
) {
  return dispatch => {
    const start = Date.now();

    dispatch(setAjaxCallStart(contextTypes.getBy(submissionType), serviceType));

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', 'comparison');
    });

    axios
      .get(
        `/api/compare/${accessKey.id}/?key=${accessKey.key}&serviceCardLoading=${serviceCardLoading ||
          serviceCardLoadingTypes.NONE}`
      )
      .then(response => {
        if (response.data) {
          const data = response.data;

          if (data.messages && data.messages[0] && data.messages[0].message) {
            dispatch(comparisonError(serverMessageTargets.DASHBOARD, data.messages[0].message));
          } else {
            const comparison = data.comparison;
            const services = data.services;
            const user = data.user;
            const userBill = data.userBill;
            const agentMember = data.agentMember;

            sleep(start, 'Comparison').then(() => {
              dispatch(
                setComparisonSuccess({
                  ...comparison,
                  submissionId: userBill.submissionId,
                  sorting: data.sorting,
                  isLoaded: true,
                  accessId: accessKey.id,
                  accessKey: accessKey.key,
                  accessTrustLevel: data.accessKey.accessTrustLevel
                })
              );

              const openMoreInfo = options && options.openMoreInfo === true ? true : false;
              const landing = { comparison: { openMoreInfo } };
              dispatch(landingActions.setLanding(landing));

              if (options && options.openEstimateDialog && comparison.submissionType === submissionTypes.ESTIMATE) {
                dispatch(openSubmitDialog(serviceType, uploadBillTypes.MANUAL, uploadSubTypes.NONE));
              }

              if (agentMember) {
                dispatch(setSelectedAgentSuccess({ ...agentMember, isLoaded: true }));
              }

              if (user) {
                dispatch(setUser({ ...user, loaded: true }));
              }

              if (serviceCardLoading !== serviceCardLoadingTypes.NONE) {
                dispatch(setUserBillsSuccess({ loaded: true, data: [...services] }));
              }

              dispatch(setUserBill({ ...data, accessKey: { ...data.accessKey, accessKey: accessKey.key } }));

              if (comparison.submissionType === submissionTypes.ESTIMATE) {
                if (!comparison.hasOffers) {
                  dispatch(displayNoOffersMessage(serverMessageTargets.ESTIMATE_DIALOG));
                }
              }

              if (autoEstimatesArgs) {
                dispatch(initAutoEstimates(autoEstimatesArgs, data, sessionActivity));
              }

              dispatch(push('/compare'));
              dispatch(setAjaxCallEnd());
            });
          }
        } else {
          dispatch(comparisonError(serverMessageTargets.DASHBOARD));
        }
      })
      .catch(() => {
        dispatch(comparisonError(serverMessageTargets.DASHBOARD));
      });
  };
}

function comparisonError(target, message) {
  return dispatch => {
    dispatch(goHome());
    dispatch(displayErrorMessage(target, message || 'An error occured processing your comparison request', true));
    dispatch(setAjaxCallEnd());
  };
}

// ----------------------------------------------------------------------------
// Initiates auto estimates off a set of user bill(s).
// ----------------------------------------------------------------------------
export function initAutoEstimates(args, data, sessionActivity) {
  return dispatch => {
    const services = data.services;
    const user = data.user;
    const userBill = getUserBill(data);
    const submission = getSubmission(userBill);

    const agentMember = data.agentMember;
    const address = { ...submission.propertyAddress, isAddress: true };
    const submissionCommon = { emailAddress: user.emailAddress };
    const serviceType = billTypes.getById(userBill.billTypeId);

    const userBills = services ? [...services, userBill] : [userBill]; // take the full set of services if returned; otherwise, derive auto-estimates from single user bill.

    dispatch(
      submitAutoEstimates(
        serviceType,
        address,
        agentMember,
        sessionActivity,
        submissionCommon,
        args.agentBillTypes,
        userBills,
        args.googleAnalyticsClientCode
      )
    );
  };
}

// ----------------------------------------------------------------------------
// Gets a comparison with an access token issued by a known authentication provider.
// ----------------------------------------------------------------------------
export function getComparisonWithToken(accessToken, id, submissionType, serviceType) {
  return dispatch => {
    const start = Date.now();

    dispatch(setAjaxCallStart(contextTypes.getBy(submissionType), serviceType));

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', 'comparison');
    });

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios
      .get(`/api/compare/${submissionType}/${id}`, config)
      .then(response => {
        if (response.data) {
          var data = response.data;

          sleep(start, 'Comparison').then(() => {
            dispatch(
              setComparisonSuccess({
                ...data,
                isLoaded: true,
                submissionId: id
              })
            );

            const landing = { comparison: { openMoreInfo: false } };
            dispatch(landingActions.setLanding(landing));

            dispatch(push('/compare'));
            dispatch(setAjaxCallEnd());
          });
        } else {
          dispatch(goHome());
          dispatch(setAjaxCallEnd());
        }
      })
      .catch(() => {
        dispatch(goHome());
        dispatch(setAjaxCallEnd());
      });
  };
}

// --------------------------------------------------------------------------
// Get user bill
// --------------------------------------------------------------------------
export function getUserBill(data) {
  const accessKey = data.accessKey;
  const userBill = data.userBill;
  const billReduction = data.billReduction;
  const expirySeconds = data.billReduction.expirySeconds;
  const submission = data.comparison ? data.comparison.submission : null;
  const verifyData = data.verifyData;

  return {
    reductionLoaded: true,
    ...userBill,
    ...billReduction,
    reductionExpiry: new Date(new Date().getTime() + expirySeconds * 1000), // callback subscribers need this immediatley (i.e. cannot wait for Redux to be updated)
    verifyData,
    accessKey,
    occupancyPurposeId: submission ? submission.occupancyPurpose : null // need this when we invoke the comparison again.
  };
}

// --------------------------------------------------------------------------
// Set user bill action
// --------------------------------------------------------------------------
export function setUserBill(data) {
  return dispatch => {
    const userBill = getUserBill(data);
    dispatch(setUserBillSuccess(userBill));
  };
}

// --------------------------------------------------------------------------
// Set user bill action
// --------------------------------------------------------------------------
export function setUserBills(data) {
  return dispatch => {
    dispatch(setUserBillsSuccess({ loaded: true, data: [...data] }));
  };
}

// ----------------------------------------------------------------------------
// Submit affiliate referral (un-authenticated) action.
// ----------------------------------------------------------------------------
export function submitAffiliateReferralUnauthenticated(model, serviceType, onComplete) {
  return dispatch => {
    return dispatch(submitAffiliateReferral('', null, model, serviceType, onComplete));
  };
}

// ----------------------------------------------------------------------------
// Submit affiliate referral using an internal access key.
// ----------------------------------------------------------------------------
export function submitAffiliateReferralWithKey(accessKey, model, serviceType, onComplete) {
  return dispatch => {
    const path = `/${accessKey.id}/?key=${accessKey.key}`;

    return dispatch(submitAffiliateReferral(path, null, model, serviceType, onComplete));
  };
}

// ----------------------------------------------------------------------------
// Submit affiliate referral using an access token issued by a known authentication provider.
// ----------------------------------------------------------------------------
export function submitAffiliateReferralWithToken(accessToken, model, serviceType, onComplete) {
  return dispatch => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    return dispatch(submitAffiliateReferral('/auth', config, model, serviceType, onComplete));
  };
}

// ----------------------------------------------------------------------------
// Submit an affiliate referral.
// ----------------------------------------------------------------------------
function submitAffiliateReferral(path, config, model, serviceType, onComplete) {
  return dispatch => {
    const start = Date.now();

    dispatch(setAjaxCallStart(contextTypes.AFFILIATE_REFERRAL, serviceType));

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'affiliate', 'referral');
    });

    axios
      .post(`/api/affiliate/referral${path}`, model, config)
      .then(response => {
        if (response.data) {
          // Delay set to 4.5 seconds; anything over 5 second delay seems to be triggering Chrome's popup blocker.
          sleep(start, 'Affiliate Referral', 4500).then(() => {
            const data = response.data;

            if (data.messages && data.messages[0] && data.messages[0].message) {
              dispatch(comparisonError(serverMessageTargets.AFFILIATE_COMPARISON, data.messages[0].message));
            } else {
              dispatch(setAjaxCallEnd());

              if (onComplete) {
                onComplete(data);
              }
            }
          });
        } else {
          dispatch(comparisonError(serverMessageTargets.AFFILIATE_COMPARISON));
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.messages
            ? error.response.data.messages[0].message
            : null;

        dispatch(comparisonError(serverMessageTargets.AFFILIATE_COMPARISON, message));
      });
  };
}

// ----------------------------------------------------------------------------
// Submit partner comparison (un-authenticated) action.
// ----------------------------------------------------------------------------
export function submitPartnerComparisonUnauthenticated(model, serviceType, onComplete) {
  return dispatch => {
    return dispatch(submitPartnerComparison('', null, model, serviceType, onComplete));
  };
}

// ----------------------------------------------------------------------------
// Submit partner comparison using an internal access key.
// ----------------------------------------------------------------------------
export function submitPartnerComparisonWithKey(accessKey, model, serviceType, onComplete) {
  return dispatch => {
    const path = `/${accessKey.id}/?key=${accessKey.key}`;

    return dispatch(submitPartnerComparison(path, null, model, serviceType, onComplete));
  };
}

// ----------------------------------------------------------------------------
// Submit partner comparison using an access token issued by a known authentication provider.
// ----------------------------------------------------------------------------
export function submitPartnerComparisonWithToken(accessToken, model, serviceType, onComplete) {
  return dispatch => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    return dispatch(submitPartnerComparison('/auth', config, model, serviceType, onComplete));
  };
}

// ----------------------------------------------------------------------------
// Submit an partner comparison.
// ----------------------------------------------------------------------------
function submitPartnerComparison(path, config, model, serviceType, onComplete) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'partner', 'comparison');
    });

    axios
      .post(`/api/partner/comparison${path}`, model, config)
      .then(response => {
        if (response.data) {
          const data = response.data;

          if (data.messages && data.messages[0] && data.messages[0].message) {
            dispatch(comparisonError(serverMessageTargets.PARTNER_COMPARISON, data.messages[0].message));
          } else {
            dispatch(setAjaxCallEnd());

            if (onComplete) {
              onComplete(data);
            }
          }
        } else {
          dispatch(comparisonError(serverMessageTargets.PARTNER_COMPARISON));
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.messages
            ? error.response.data.messages[0].message
            : null;

        dispatch(comparisonError(serverMessageTargets.PARTNER_COMPARISON, message));
      });
  };
}
