import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import OutboundLink from './outboundLink';
import InfoPopover from './infoPopover';

const OfferMoreInfoEnergyUs = props => {
  const { offer, comparison } = props;

  return (
    <Container>
      <Row>
        <Col xs="12">
          <h2 className="qs-more-info-heading">
            {offer.name} - {offer.providerDisplayCode}
          </h2>
        </Col>
      </Row>

      {offer && (
        <Row className="qs-more-info-table-row" noGutters={true}>
          <Col xs="12" className="qs-more-info-plan-info">
            <p
              dangerouslySetInnerHTML={{
                __html: offer.info
              }}
            />
            {offer.infoSecondary && <p>{offer.infoSecondary}</p>}
          </Col>
        </Row>
      )}

      <Row noGutters={true}>
        <Col xs="12">
          <Container className="qs-container-nopadormargin">
            <Row noGutters={true} className="qs-table-header qs-table-row-energy-us">
              <Col xs="4">
                Monthly usage{' '}
                <InfoPopover
                  idType="monthly-bill-estimate-more-info"
                  heading="Monthly bill estimate"
                  body="Estimates include recurring charges excluding government fees and taxes. Estimates are based on information provided by energy providers and your usage estimates. Study information from the plan’s Electricity Facts Label and Terms of Service to confirm these estimates. Rates may vary according to your actual electricity usage."
                  action="monthly-bill-estimate-more-info-popover"
                  offer={offer}
                />
              </Col>
              {offer.energyChargeAverages.map((energyCharge, i) => (
                <Col
                  key={`energy-charge-${i}`}
                  xs="2"
                  className={`qs-more-info-energy-us-usage-cell ${
                    energyCharge.isCustomerEstimate ? 'qs-offer-energy-charge-customer' : ''
                  }`}
                >
                  {energyCharge.usage} {comparison.submission.energyTypeDisplay}
                </Col>
              ))}
            </Row>
            <Row noGutters={true} className="qs-table-row qs-table-row-energy-us">
              <Col xs="4">Estimated charges</Col>

              {offer.energyChargeAverages.map((energyCharge, i) => (
                <Col
                  key={`energy-charge-avg-${i}`}
                  className={`${energyCharge.isCustomerEstimate ? `qs-offer-energy-charge-customer` : ''}`}
                  xs="2"
                >
                  {energyCharge.estimatedChargeDisplay}
                </Col>
              ))}
            </Row>
            <Row noGutters={true} className="qs-table-row qs-table-row-energy-us">
              <Col xs="4">Average price per {comparison.submission.energyTypeDisplay}</Col>

              {offer.energyChargeAverages.map((energyCharge, i) => (
                <Col
                  key={`energy-charge-avg-kwh${i}`}
                  className={`${energyCharge.isCustomerEstimate ? 'qs-offer-energy-charge-customer' : ''}`}
                  xs="2"
                >
                  {energyCharge.averagePricePerKwhDisplay}c
                </Col>
              ))}
            </Row>
            {/* <Row>
              <Col xs={{ size: 6, offset: 6 }} className="qs-more-info-your-usage qs-mt-sm">
                Your usage: {comparison.submission.totalUsageDisplay}
              </Col>
            </Row> */}
          </Container>
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          {offer.planDocuments && offer.planDocuments.length > 0 && (
            <div>
              <div className="qs-more-info-sub-heading">Plan documents</div>
              <div className="qs-more-info-important-info">
                {offer.planDocuments.map((planDocument, i) => (
                  <div key={i}>
                    <OutboundLink
                      eventLabel={`document-${planDocument.name}`}
                      to={planDocument.linkUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {planDocument.name}
                    </OutboundLink>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Col>
      </Row>

      {offer && offer.hasTerminationFee && (
        <Row className="qs-more-info-table-row" noGutters={true}>
          <Col xs="12" className="qs-more-info-important-info">
            <div className="qs-more-info-sub-heading">Important information</div>
            <p>Term: {offer.termDisplay}</p>
            <p>
              Early Termination Fee{offer.earlyTerminationFeeIsMonthly ? ' (per month remaining on the contract)' : ''}:{' '}
              {offer.earlyTerminationFeeDisplay}
              <br />
              (Note: This fee will <b>not be charged</b> if you end your contract early because you are moving out.)
            </p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

OfferMoreInfoEnergyUs.propTypes = {
  offer: PropTypes.object.isRequired,
  pricingDocumentName: PropTypes.string.isRequired
};

export default OfferMoreInfoEnergyUs;
