import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Row, Col, Button } from 'reactstrap';
import { useAuth0 } from '../../auth/auth0-Provider.js';
import { billTypes } from '../../enums/billTypes';
import ServiceIcon from './serviceIcon';
import { restoreUserBillSuccess } from '../../actions/comparisonActions';
import {
  deleteOrRestoreServiceCardWithKey,
  deleteOrRestoreServiceCardWithToken
} from '../../actions/serviceCardActions';
import { sessionExpiredServerMessage } from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';

const ServiceCardDeleted = props => {
  const {
    providerDisplayCode,
    providerLogo,
    billTypeId,
    displayAddress,
    deletedDateUtc,
    submissionId,
    submissionType,
    accessKey
  } = props.data;

  const { useProviderLogo, useServiceLogo, useServiceIcon } = props;

  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const deletedUtc = moment.utc(deletedDateUtc);
  const nowUtc = moment();

  const deletedDate = moment(deletedUtc.toDate())
    .local()
    .format('LL');

  const deletedDays = `${90 - nowUtc.diff(deletedUtc, 'days')} day(s)`;

  const serviceType = billTypes.getById(billTypeId);

  const onRestoreComplete = data => {
    dispatch(
      restoreUserBillSuccess({
        ...data
      })
    );
  };

  const onServiceRestore = () => {
    // Determines if the comparison results are displayed and the dialog closes at the end; in this case it doesn't apply - this is a direct restore action.
    const comparisonReady = undefined;
    const deleted = false;

    if (accessKey) {
      const access = { key: accessKey.accessKey, id: accessKey.accessId, trustLevel: accessKey.accessTrustLevel };

      dispatch(deleteOrRestoreServiceCardWithKey(access, deleted, comparisonReady, submissionId, onRestoreComplete));
    } else if (isUserAuthenticated()) {
      const fn = async () => {
        dispatch(
          deleteOrRestoreServiceCardWithToken(
            await getAccessToken(),
            deleted,
            comparisonReady,
            submissionId,
            submissionType,
            onRestoreComplete
          )
        );
      };
      fn();
    } else {
      dispatch(sessionExpiredServerMessage(serverMessageTargets.RECYLCE_BIN));
    }
  };

  return (
    <div className="service-row">
      <Row>
        <Col xs="3" lg="2">
          {useProviderLogo && providerLogo ? (
            <img
              className="provider-logo"
              src={`${staticContentUrl}/images/providers/${providerLogo}`}
              alt={providerDisplayCode}
            />
          ) : useServiceLogo ? (
            <img
              className="service-logo"
              src={`${staticContentUrl}/images/services/${serviceType.Tag}.png`}
              alt={serviceType.Name}
            />
          ) : useServiceIcon ? (
            <div className="service-icon">
              <ServiceIcon serviceType={serviceType} />
            </div>
          ) : (
            <div className="provider-text">{providerDisplayCode}</div>
          )}
        </Col>
        <Col xs="4" className="service-column">
          {displayAddress}
        </Col>
        <Col lg="2" className="service-column deleted-date">
          {deletedDate}
        </Col>
        <Col xs="2" className="service-column">
          {deletedDays}
        </Col>
        <Col xs="2" className="service-column">
          <Button className="sm-link-button" onClick={onServiceRestore}>
            Restore
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              className="right-arrow"
              width="16px"
              height="16px"
              aria-hidden="true"
            >
              <path d="M16.706 8.29L10.71 2.293A1.001 1.001 0 1 0 9.293 3.71L13.583 8H2a1 1 0 1 0 0 2h11.583l-4.29 4.29a1.001 1.001 0 1 0 1.417 1.417l5.997-5.997A.997.997 0 0 0 17 9a.999.999 0 0 0-.294-.71z"></path>
            </svg>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceCardDeleted;
