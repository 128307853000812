import { uploadBillTypes } from './uploadBillTypes';

export const submissionTypes = {
  NONE: 'None',
  BILL: 'Bill',
  ESTIMATE: 'Estimate',
  getById: id => {
    switch (id) {
      case 1:
        return { id: 1, code: submissionTypes.BILL, uploadBillType: uploadBillTypes.WEBSITE };
      case 2:
        return { id: 2, code: submissionTypes.ESTIMATE, uploadBillType: uploadBillTypes.MANUAL };
      default:
        return { id: 0, code: submissionTypes.NONE, uploadBillType: uploadBillTypes.NONE };
    }
  }
};

export const billingTypes = {
  NONE: { Id: 0, Code: 'None', Name: '' },
  EMAIL_ORGANISER: { Id: 1, Code: 'Email', Name: '<Organiser>' },
  ADDRESS_CONNECTION: { Id: 2, Code: 'ConnectionAddress', Name: 'Connection address', Label: 'My connection address' },
  ADDRESS_OTHER: { Id: 3, Code: 'OtherAddress', Name: 'Other address', Label: 'Other billing address' },
  ADDRESS_POBOX: { Id: 4, Code: 'PoBoxAddress', Name: 'P.O. Box', Label: 'Post office box address' },
  EMAIL_PERSONAL: { Id: 5, Code: 'EmailPersonal', Name: 'Email address', Label: 'My email address' },
  getByCode: code => {
    switch (code) {
      case billingTypes.EMAIL_ORGANISER.Code:
        return billingTypes.EMAIL_ORGANISER;
      case billingTypes.ADDRESS_CONNECTION.Code:
        return billingTypes.ADDRESS_CONNECTION;
      case billingTypes.ADDRESS_OTHER.Code:
        return billingTypes.ADDRESS_OTHER;
      case billingTypes.ADDRESS_POBOX.Code:
        return billingTypes.ADDRESS_POBOX;
      case billingTypes.EMAIL_PERSONAL.Code:
        return billingTypes.EMAIL_PERSONAL;
      default:
        return billingTypes.NONE;
    }
  }
};

export const submissionStatuses = {
  NONE: { Id: 0, Code: 'None' },
  COMPARED: { Id: 1, Code: 'Compared' },
  CONVERTED: { Id: 2, Code: 'Converted' },
  CANCELLED: { Id: 3, Code: 'Cancelled' },
  NOT_COMPARABLE: { Id: 4, Code: 'NotComparable' },
  EXTRACT_ONLY: { Id: 5, Code: 'ExtractOnly' },
  PARTIAL_EXTRACT: { Id: 6, Code: 'PartialExtract' }
};

export const contextTypes = {
  BILL_COMPARISON: 'bill-comparison',
  ESTIMATE_COMPARISON: 'estimate-comparison',
  EMAIL_COMPARISON: 'email-comparison',
  DIRECT_SWITCH: 'direct-switch',
  CUSTOMER_DASHBOARD: 'customer-dashboard',
  CUSTOMER_SURVEY: 'customer-survey',
  AFFILIATE_REFERRAL: 'affiliate-referral',
  PARTNER_COMPARISON: 'partner-comparison',
  FRAME_PARTNER_COMPARISON: 'frame-partner-comparison',
  getBy: submissionType => {
    switch (submissionType) {
      case submissionTypes.BILL:
        return contextTypes.BILL_COMPARISON;

      case submissionTypes.ESTIMATE:
        return contextTypes.ESTIMATE_COMPARISON;

      default:
        return null;
    }
  }
};

export const occupancyTypes = {
  NONE: { Id: 0, Name: 'None' },
  NEW: { Id: 1, Name: 'New', Description: 'New connection' },
  CURRENT: { Id: 2, Name: 'Current', Description: 'Existing connection' },

  getById: id => {
    switch (id) {
      case occupancyTypes.NEW.Id:
        return occupancyTypes.NEW;
      case occupancyTypes.CURRENT.Id:
        return occupancyTypes.CURRENT;
      default:
        return occupancyTypes.NONE;
    }
  }
};

export const occupancyPurposes = {
  NONE: { Id: 0, Name: 'None' },
  RESIDENTIAL: { Id: 1, Name: 'Residential' },
  BUSINESS: { Id: 2, Name: 'Business' },

  getById: (id, defaultValue) => {
    switch (id) {
      case occupancyPurposes.RESIDENTIAL.Id:
        return occupancyPurposes.RESIDENTIAL;
      case occupancyPurposes.BUSINESS.Id:
        return occupancyPurposes.BUSINESS;
      default:
        return defaultValue ? defaultValue : occupancyPurposes.NONE;
    }
  }
};

export const directSwitchTypes = {
  NONE: 0,
  LANDING: 1,
  LAUNCH: 2,
  PROVIDER: 3
};

export const loanPropertyTypes = {
  OWNER_OCCUPIED: { value: '1', label: 'Owner occupied' },
  INVESTMENT: { value: '2', label: 'Investment' },
  getById: id => {
    switch (id) {
      case 1:
        return loanPropertyTypes.OWNER_OCCUPIED;
      case 2:
        return loanPropertyTypes.INVESTMENT;
      default:
        return null;
    }
  }
};

export const loanTypes = {
  FIXED: { value: '1', label: 'Fixed' },
  VARIABLE: { value: '2', label: 'Variable' },
  SPLIT: { value: '3', label: 'Split' },
  getById: id => {
    switch (id) {
      case 1:
        return loanTypes.FIXED;
      case 2:
        return loanTypes.VARIABLE;
      case 3:
        return loanTypes.SPLIT;
      default:
        return null;
    }
  }
};

export const repaymentTypes = {
  PRINCIPAL_INTEREST: { value: '1', label: 'Principle + int.' },
  INTEREST_ONLY: { value: '2', label: 'Interest only' },
  getById: id => {
    switch (id) {
      case 1:
        return repaymentTypes.PRINCIPAL_INTEREST;
      case 2:
        return repaymentTypes.INTEREST_ONLY;
      default:
        return null;
    }
  }
};

export const boolTypes = {
  NONE: { Id: 0, Name: 'None' },
  NO: { Id: 1, Name: 'No', AlternateName: 'No thanks' },
  YES: { Id: 2, Name: 'Yes', AlternateName: "I'm ready" }
};

export const ratingTypes = {
  NONE: { Id: 0, Name: 'None' },
  LOW: { Id: 1, Name: '1' },
  MEDIUM_LOW: { Id: 2, Name: '2' },
  MEDIUM: { Id: 3, Name: '3' },
  MEDIUM_HIGH: { Id: 4, Name: '4' },
  HIGH: { Id: 5, Name: '5' }
};

export const usageTypes = {
  LOW: { Id: 1, Name: 'Low' },
  MEDIUM: { Id: 2, Name: 'Medium' },
  HIGH: { Id: 3, Name: 'High' }
};

export const solarTypes = {
  NONE: { Id: 0, Name: 'None', Description: 'No solar' },
  STANDARD: { Id: 1, Name: 'Standard', Description: 'Standard solar' },
  PREMIUM: { Id: 2, Name: 'Premium', Description: 'Premium solar' }
};

export const internetDownloadSpeedTypes = {
  ALL: { Id: 0, Name: 'All' },
  LOW: { Id: 1, Name: 'Up to 25 mbps' },
  MEDIUM: { Id: 2, Name: '26 - 60 mpbps' },
  HIGH: { Id: 3, Name: '61+ mbps' }
};

export const internetConnectionTypeTypes = {
  UNKNOWN: { Id: 0, Name: 'Unknown' },
  NBN: { Id: 1, Name: 'NBN' },
  ADSL: { Id: 2, Name: 'ADSL' },
  CABLE: { Id: 3, Name: 'Cable' },
  HOME_INTERNET_4G: { Id: 4, Name: '4G' },
  HOME_INTERNET_5G: { Id: 5, Name: '5G' },
  OPTICOMM: { Id: 6, Name: 'Opticomm' },
  LBN: { Id: 7, Name: 'LBN' },
  MOBILE_BROADBAND: { Id: 8, Name: 'Mobile Broadband' },
  getById: id => {
    switch (id) {
      case 0:
        return internetConnectionTypeTypes.UNKNOWN;
      case 1:
        return internetConnectionTypeTypes.NBN;
      case 2:
        return internetConnectionTypeTypes.ADSL;
      case 3:
        return internetConnectionTypeTypes.CABLE;
      case 4:
        return internetConnectionTypeTypes.HOME_INTERNET_4G;
      case 5:
        return internetConnectionTypeTypes.HOME_INTERNET_5G;
      case 6:
        return internetConnectionTypeTypes.OPTICOMM;
      case 7:
        return internetConnectionTypeTypes.LBN;
      case 8:
        return internetConnectionTypeTypes.MOBILE_BROADBAND;
      default:
        return null;
    }
  }
};

export const solarInstallationEmailModes = {
  NONE: { Id: 0, Name: 'None' },
  DEFAULT: { Id: 1, Name: 'Default' },
  HOME_LOAN_BROKER: { Id: 2, Name: 'Home loan broker' }
};

export const solarInstallTypes = {
  NONE: { Id: 0, Name: 'None' },
  SOLAR: { Id: 1, Name: 'Solar' },
  SOLAR_AND_BATTERY: { Id: 2, Name: 'Solar + battery' },

  getById: (id, defaultValue) => {
    switch (id) {
      case solarInstallTypes.SOLAR.Id:
        return solarInstallTypes.SOLAR;
      case solarInstallTypes.SOLAR_AND_BATTERY.Id:
        return solarInstallTypes.SOLAR_AND_BATTERY;
      default:
        return defaultValue ? defaultValue : solarInstallTypes.NONE;
    }
  }
};
