import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function selectedOfferReducer(state = initialState.confirmDetail, action) {
  switch (action.type) {
    case types.SET_CONFIRM_DETAIL:
      return objectAssign({}, state, action.confirmDetail);

    case types.HARD_SET_CONFIRM_DETAIL:
      return {
        ...action.confirmDetail,
        title: state.title,
        firstName: state.firstName,
        surname: state.surname,
        phoneNumber: state.phoneNumber
      };

    default:
      return state;
  }
}
