import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, InputGroup, InputGroupText } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import EmailAddress from '../common/emailAddress';
import { copyTextToClipboard, getSessionActivity, truncateWithEllipses } from '../../utilities/commonUtilities';
import { directSwitchEmail } from '../../actions/energyEstimateActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import ServerMessage from '../common/serverMessage';
import { displayInfoMessage, generalServerMessage, setServerMessageClear } from '../../actions/serverMessageActions';
import { levelSettings } from '../../enums/levelSettings';

const QuickTipsDirectSwitch = ({
  serviceType,
  providerDisplayCode,
  directSwitchLaunchUrl,
  directSwitchTag,
  onInteraction
}) => {
  const dispatch = useDispatch();

  const { serverMessage, selectedAgent, session } = useSelector(state => state);

  const [emailAddress, setEmailAddress] = useState();
  const [hasValidEmailAddress, setHasValidEmailAddress] = useState(false);
  const [emailInteraction, setEmailInteraction] = useState(false);
  const [copyInteraction, setCopyInteraction] = useState(false);

  const [fromName, setFromName] = useState();
  const [message, setMessage] = useState(
    `Hi Sara, this is the new ${providerDisplayCode} plan I think we should move to. Can you please fill in the application details if you're happy to move to it?`
  );

  const url = `${directSwitchLaunchUrl}${selectedAgent && selectedAgent.code ? `&agent=${selectedAgent.code}` : ''}`;
  const { DIRECT_SWITCH_EMAIL: target } = serverMessageTargets;

  const sessionActivity = getSessionActivity(session);

  const onSend = () => {
    const model = {
      tag: directSwitchTag,
      url,
      fromName,
      emailAddress,
      message,
      serviceTypeId: serviceType.Id,
      agentCode: selectedAgent.code,
      sessionActivity
    };

    const onComplete = () => {
      const emailTag = 'EmailSend:Completed';
      const copyTag = copyInteraction ? ', CopyLink:true' : '';
      setEmailInteraction(emailTag);
      onInteraction(`${emailTag}${copyTag}`);
      dispatch(displayInfoMessage(target, `Email sent to ${truncateWithEllipses(emailAddress, 30)}`));
    };

    const onError = error => {
      const emailTag = 'EmailSend:Failed';
      const copyTag = copyInteraction ? ', CopyLink:true' : '';
      setEmailInteraction(emailTag);
      onInteraction(`${emailTag}${copyTag}`);
      dispatch(generalServerMessage(error.response, target, true));
    };

    dispatch(directSwitchEmail(model, onComplete, onError));
  };

  const onCopyLink = () => {
    const emailTag = emailInteraction || '';
    const hasEmailTag = emailInteraction ? ', ' : '';
    setCopyInteraction(true);
    onInteraction(`${emailTag}${hasEmailTag}CopyLink:true`);
    copyTextToClipboard(url);
    dispatch(displayInfoMessage(target, 'Plan link copied to your clipboard'));
  };

  useEffect(() => {
    dispatch(setServerMessageClear(target, serverMessage));
  }, []);

  return (
    <div className="sm-quick-tips-component">
      <div className="content">
        <ServerMessage serverMessage={serverMessage} target={target} paddingLevel={levelSettings.Low} />

        <div>
          <p>
            Send an invite to a friend below. They'll be directed to this application, with this {providerDisplayCode}{' '}
            plan already selected.
          </p>
        </div>

        <div className="form-content">
          <AvForm onValidSubmit={onSend}>
            <InputGroup className="email-field">
              <InputGroupText>From</InputGroupText>
              <AvField
                bsSize="sm"
                name="from"
                type="text"
                value={fromName}
                onChange={e => setFromName(e.target.value)}
                maxLength={10}
                validate={{
                  required: { value: true, errorMessage: 'From name is required' },
                  maxLength: { value: 1000, errorMessage: 'From name should be 1000 characters or less' }
                }}
              ></AvField>
            </InputGroup>
            <InputGroup className="email-field">
              <InputGroupText>Email</InputGroupText>
              <EmailAddress
                placeholder="Enter email address here"
                value={emailAddress}
                validateEmailAddress={false}
                forceRequireEmailAddress={true}
                onChange={value => setEmailAddress(value)}
                onValidate={isValid => setHasValidEmailAddress(isValid)}
              />
            </InputGroup>
            <AvField
              bsSize="sm"
              name="message"
              placeholder="Enter your message..."
              type="textarea"
              rows="4"
              value={message}
              onChange={e => setMessage(e.target.value)}
            ></AvField>
            <div className="footer">
              <div className="button-container">
                <Button
                  className="sm-button-primary medium wide no-margin"
                  disabled={!hasValidEmailAddress || !message || message.trim() === ''}
                >
                  Send email
                </Button>
                <Button className="sm-button-alternative medium copy-link" onClick={onCopyLink}>
                  <span className="material-symbols-outlined copy-icon">content_copy</span> Copy plan link
                </Button>
              </div>
            </div>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

export default QuickTipsDirectSwitch;
