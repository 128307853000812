import axios from 'axios';
import timeAxios from '../utilities/timeAxios';
import { billTypes } from '../enums/billTypes';
import { hasValidAddress } from '../utilities/addressUtilities';
import { setAjaxCallEnd, setAjaxCallStart } from './ajaxCallActions';
import { apiTiming } from '../utilities/googleAnalyticsEvents';

// --------------------------------------------------------------------------
// Get distributors action
// --------------------------------------------------------------------------
function getDistributors(data, onComplete, onError) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'get', 'distributors');
    });

    axios
      .post('/api/distributor/region', data)
      .then(response => {
        onComplete(response.data || {});
      })
      .catch(error => {
        if (onError) onError(error);
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function getPrimaryEnergyServiceType(agentBillTypes, serviceType) {
  const serviceTypeOptions = { includeExternal: false };
  const hasElectricity = billTypes.hasServiceType(agentBillTypes, billTypes.ELECTRICITY, serviceTypeOptions);
  const hasGas = billTypes.hasServiceType(agentBillTypes, billTypes.GAS, serviceTypeOptions);

  if (serviceType === billTypes.ELECTRICITY && hasElectricity) return billTypes.ELECTRICITY;
  if (serviceType === billTypes.GAS && hasGas) return billTypes.GAS;
  if (serviceType === billTypes.INTERNET && hasElectricity) return billTypes.ELECTRICITY;
  if (serviceType === billTypes.INTERNET && hasGas) return billTypes.GAS;
  if (serviceType === billTypes.NONE && hasElectricity) return billTypes.ELECTRICITY;
  if (serviceType === billTypes.NONE && hasGas) return billTypes.GAS;

  return null;
}

export function getSecondaryEnergyServiceType(agentBillTypes, serviceType) {
  const serviceTypeOptions = { includeExternal: false };
  const hasElectricity = billTypes.hasServiceType(agentBillTypes, billTypes.ELECTRICITY, serviceTypeOptions);
  const hasGas = billTypes.hasServiceType(agentBillTypes, billTypes.GAS, serviceTypeOptions);

  if (serviceType === billTypes.ELECTRICITY && hasGas) return billTypes.GAS;
  if (serviceType === billTypes.GAS && hasElectricity) return billTypes.ELECTRICITY;
  if (serviceType === billTypes.INTERNET && hasElectricity && hasGas) return billTypes.GAS;
  if (serviceType === billTypes.NONE && hasElectricity && hasGas) return billTypes.GAS;

  return null;
}

// --------------------------------------------------------------------------
// Get bill type codes needed for a distributor set.
// --------------------------------------------------------------------------
const getDistibutorSetBillTypeCodes = agentBillTypes => {
  const billTypeCodes = [];

  // Add all supported service types to get a list of distributors for each service type.
  const serviceTypes = billTypes.getServices(agentBillTypes, [billTypes.ELECTRICITY, billTypes.GAS]);

  serviceTypes.forEach(e => {
    billTypeCodes.push(e.Code);
  });

  return billTypeCodes;
};

// --------------------------------------------------------------------------
// Get distributor item from a distributor set for a specific service type.
// --------------------------------------------------------------------------
export function getDistributorItem(sets, serviceType) {
  if (!sets || !serviceType) return [];

  const item = sets[`${serviceType.Code.toLowerCase()}`];

  return item && item.length > 0 ? item : [];
}

// --------------------------------------------------------------------------
// Get region distributors.
// --------------------------------------------------------------------------
export function getRegionDistributors(address, agentBillTypes, singleServiceType, onComplete, onError) {
  return dispatch => {
    if (hasValidAddress(address)) {
      const serviceTypes = getDistibutorSetBillTypeCodes(agentBillTypes);

      dispatch(
        getDistributors(
          {
            ...address,
            serviceTypes,
            primaryServiceType: singleServiceType ? singleServiceType.Id : null // determines if a combined (and most restrictive) list of occupancy dates is returned.
          },
          data => {
            onComplete({
              success: true,
              items: data.items,
              occupancyDates: data.occupancyDates || []
            });
          },
          error => {
            if (onError) onError(error);
          }
        )
      );
    } else {
      onComplete({
        success: false,
        items: {},
        occupancyDates: []
      });
    }
  };
}

export function getDistributorDefaults(distributorCode, items, agentBillTypes, serviceType, multipleServices) {
  const primaryServiceType = getPrimaryEnergyServiceType(agentBillTypes, serviceType);
  const primaryDistributor = getDistributorItem(items, primaryServiceType);

  const result = {
    success: multipleServices || primaryDistributor.length > 0,
    primaryDistributorLength: primaryDistributor.length,
    primaryDistributor: null,
    secondaryDistributorLength: null,
    secondaryDistributor: null
  };

  if (primaryDistributor.length === 1) {
    // Set primary distributor from name or auto-set if there is only one
    result.primaryDistributor = primaryDistributor[0];
  } else if (distributorCode) {
    // When there are multiple distributors and distributor code is provided, set distributor from code.
    result.primaryDistributor = primaryDistributor.find(item => item.selectionCode === distributorCode);
  }

  if (multipleServices) {
    const secondayService = getSecondaryEnergyServiceType(agentBillTypes, serviceType);

    if (secondayService !== null) {
      const secondaryDistributor = getDistributorItem(items, secondayService);

      if (secondaryDistributor.length === 1) {
        // Auto-set secondary distributor if there is only one
        result.secondaryDistributor = secondaryDistributor[0];
      } else {
        result.secondaryDistributor = null;
      }

      result.secondaryDistributorLength = secondaryDistributor.length;
    }
  }

  return result;
}
