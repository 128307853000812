import * as types from './actions';
import axios from 'axios';
import { push } from 'connected-react-router';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import {
  generalServerMessage,
  setServerMessageClear,
  sessionExpiredServerMessage,
  otpServerMessage
} from './serverMessageActions';
import timeAxios from '../utilities/timeAxios';
import { apiTiming } from '../utilities/googleAnalyticsEvents';
import { setConfirmDetail, hardSetConfirmDetail } from './confirmDetailActions';
import { convertUserBillSuccess, setComparisonSuccess } from './comparisonActions';
import * as serverMessageTargets from './serverMessageTargets';
import { serverMessageHasField } from '../utilities/serverMessageUtilities';
import { sessionActivityTypes } from '../enums/sessionActivityTypes';

export function setAcceptedOfferSuccess(offer) {
  return { type: types.SET_SELECTED_OFFER, offer };
}

export function setDetailsOfferSuccess(offer) {
  return { type: types.SET_SELECTED_OFFER, offer };
}

export function setSelectedOfferSuccess(offer) {
  return { type: types.SET_SELECTED_OFFER, offer };
}

export function dispatchSelectedOfferSuccess(offer) {
  return dispatch => {
    dispatch(setSelectedOfferSuccess(offer));
  };
}

export function setSelectedEnergyOfferDetail(comparison, offer, meterIdentifier) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', 'product');
    });

    let path;
    let config;

    path = `/api/compare/energy/${comparison.accessId}/offer/${offer.offerId}?key=${comparison.accessKey}`;

    axios
      .post(path, { meterIdentifier }, config)
      .then(response => {
        dispatch(setSelectedOfferSuccess({ ...offer, requireOtp: false, productDetail: response.data }));
      })
      .catch(error => {
        dispatch(generalServerMessage(error.response, serverMessageTargets.COMPARE, true));
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function setSelectedOffer(serviceType, comparison, offer, accessToken, submissionId) {
  return dispatch => {
    dispatch(setServerMessageClear());

    let path;
    let config;

    // Prioritize accessKey first as even an authenticated user may have come in on a link, possibly for a different customer.
    if (comparison.accessId && comparison.accessKey) {
      path = `/api/compare/${serviceType.ApiPath}/${comparison.accessId}/offer/${offer.offerId}?key=${comparison.accessKey}`;
    } else {
      if (!accessToken) {
        dispatch(sessionExpiredServerMessage(serverMessageTargets.COMPARE));
        return;
      }

      config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };

      path = `/api/compare/${serviceType.ApiPath}/${comparison.submissionType}/${submissionId}/offer/${offer.offerId}`;
    }

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', 'product');
    });

    dispatch(setAjaxCallStart());

    axios
      .get(path, config)
      .then(response => {
        // On selecting a new product clear all previous confirmDetail input so we don't leave personal details in the browser
        // if they never switch and also we want them to re-agree any selected terms of other products
        dispatch(hardSetConfirmDetail({ entryStarted: false }));

        dispatch(setSelectedOfferSuccess({ ...offer, requireOtp: false, productDetail: response.data }));

        if (offer.twoStepConfirm) dispatch(push('/details'));
        else dispatch(push('/switch'));
      })
      .catch(error => {
        dispatch(generalServerMessage(error.response, serverMessageTargets.COMPARE, true));
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function setMoreInfo(serviceType, comparison, offer, accessToken, submissionId, onComplete) {
  return dispatch => {
    dispatch(setServerMessageClear());

    let path;
    let config;

    // Prioritize accessKey first as even an authenticated user may have come in on a link, possibly for a different customer.
    if (comparison.accessId && comparison.accessKey) {
      path = `/api/compare/${serviceType.ApiPath}/${comparison.accessId}/offer-info/${offer.offerId}?key=${comparison.accessKey}`;
    } else {
      if (!accessToken) {
        dispatch(sessionExpiredServerMessage(serverMessageTargets.COMPARE));
        return;
      }

      config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };

      path = `/api/compare/${serviceType.ApiPath}/${comparison.submissionType}/${submissionId}/offer-info/${offer.offerId}`;
    }

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', 'moreInfo');
    });

    dispatch(setAjaxCallStart());

    axios
      .get(path, config)
      .then(response => {
        // Deep clone the comparison
        const newComparison = JSON.parse(JSON.stringify(comparison));
        const comparisonOffer = newComparison.offers.find(o => {
          return o.offerId === offer.offerId;
        });

        comparisonOffer.moreInfo = response.data;

        if (onComplete) {
          onComplete(response.data);
        }

        dispatch(setComparisonSuccess(newComparison));
      })
      .catch(error => {
        dispatch(generalServerMessage(error.response, serverMessageTargets.COMPARE, true));
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function setOfflineApplication(onOfflineApplication, comparison, offer, accessToken, submissionId) {
  return dispatch => {
    let path;
    let config;

    // Prioritize accessKey first as even an authenticated user may have come in on a link, possibly for a different customer.
    if (comparison.accessId && comparison.accessKey) {
      path = `/api/compare/${comparison.accessId}/offline-apply/${offer.offerId}?key=${comparison.accessKey}`;
    } else {
      if (!accessToken) {
        dispatch(sessionExpiredServerMessage, serverMessageTargets.COMPARE);
        return;
      }

      config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };

      path = `/api/compare/submission/${submissionId}/offline-apply/${offer.offerId}`;
    }

    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', 'offlineApplication');
    });

    axios
      .get(path, config)
      .then(response => {
        onOfflineApplication(response.data, offer);
      })
      .catch(error => {
        dispatch(generalServerMessage(error.response, null, true));
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function setDetailsOffer(comparison, offer, confirmDetail, config, accessToken, submissionId) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'submit', 'details');
    });

    let path = determineApiPath(
      '/api/convert/details-submission',
      '/api/convert/details-accessKey',
      comparison,
      submissionId
    );

    let axiosConfig = getAxiosConfig(accessToken, 60 * 3 * 1000);

    axios
      .put(
        path,
        {
          ...confirmDetail,
          productId: offer.id,
          offerId: offer.offerId
        },
        axiosConfig
      )
      .then(response => {
        if (response.data) {
          var newOffer = { ...offer, detailsStatus: response.data.status, detailsStatusStamp: Date.now() };

          if (response.data.status === 0) {
            if (response.data.detail !== null) newOffer = { ...newOffer, productDetail: response.data.detail };

            dispatch(setDetailsOfferSuccess(newOffer));
            dispatch(push('/switch'));
          } else {
            dispatch(setDetailsOfferSuccess(newOffer));
          }
        }
      })
      .catch(error => {
        dispatch(generalServerMessage(error.response, null, true));
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function setAcceptedOffer(
  sessionActivity,
  serviceType,
  comparison,
  offer,
  confirmDetail,
  accessToken,
  submissionId,
  onComplete
) {
  return dispatch => {
    dispatch(setServerMessageClear());

    // Take the screenshot before the spinner starts
    const termsScreenshot = offer.captureScreenshots ? getScreenshot('review') : null;

    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'submit', 'conversion');
    });

    const path = determineApiPath(
      `/api/convert/${serviceType.ApiPath}/submission`,
      `/api/convert/${serviceType.ApiPath}/accessKey`,
      comparison,
      submissionId
    );

    const axiosConfig = getAxiosConfig(accessToken, 60 * 3 * 1000);

    const model = {
      ...confirmDetail,
      productId: offer.id,
      offerId: offer.offerId,
      sessionActivity: { ...sessionActivity, activityType: sessionActivityTypes.OFFER_ACCEPT_SUBMITTED }
    };

    let isSwitchSuccess = false;

    axios
      .put(path, model, axiosConfig)
      .then(response => {
        const responseData = response.data;

        if (responseData) {
          const userData = responseData.data;

          if (userData && userData.requireOtp) {
            dispatch(
              setAcceptedOfferSuccess({
                ...offer,
                requireOtp: true,
                saleData: responseData.saleData,
                autoCheckEmailAddress: responseData.autoCheckEmailAddress
              })
            );
          } else {
            // On switch clear confirmDetail input so we don't leave personal details in the browser
            dispatch(hardSetConfirmDetail({ entryStarted: false }));

            dispatch(
              setAcceptedOfferSuccess({
                ...offer,
                isAccepted: true,
                requireOtp: false,
                referenceNumber: responseData.referenceNumber,
                whatHappensNext: responseData.whatHappensNextContent,
                autoCheckEmailAddress: responseData.autoCheckEmailAddress
              })
            );

            if (submissionId) {
              dispatch(convertUserBillSuccess(responseData.userSubmission));
            }

            isSwitchSuccess = true;

            if (offer.captureScreenshots) {
              dispatch(sendScreenshot(comparison, offer, accessToken, submissionId, termsScreenshot));
            }

            onComplete(offer);
          }
        }
      })
      .catch(error => {
        var target;
        var scrollTo;

        if (serverMessageHasField(error.response, 'otp')) {
          target = serverMessageTargets.OTP_DIALOG;
          scrollTo = false;
        } else {
          target = null;
          scrollTo = true;
        }

        dispatch(generalServerMessage(error.response, target, scrollTo));
      })
      .then(() => {
        dispatch(setConfirmDetail({ ...confirmDetail, otp: null }));
        dispatch(setAjaxCallEnd());

        if (offer.captureScreenshots && isSwitchSuccess) {
          var confirmationScreenshot = getScreenshot('confirmation');
          dispatch(sendScreenshot(comparison, offer, accessToken, submissionId, confirmationScreenshot));
        }
      });
  };
}

export function sendOtp(sessionActivity, comparison, offer, confirmDetail, accessToken, submissionId) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'submit', 'otp');
    });

    const path = determineApiPath(
      '/api/convert/otp-submission',
      '/api/convert/otp-accessKey',
      comparison,
      submissionId
    );

    const axiosConfig = getAxiosConfig(accessToken);

    axios
      .put(
        path,
        {
          phoneNumber: confirmDetail.phoneNumber,
          emailAddress: confirmDetail.emailAddress,
          offerId: offer.offerId,
          sessionActivity: { ...sessionActivity, activityType: sessionActivityTypes.OFFER_ACCEPT_SUBMITTED }
        },
        axiosConfig
      )
      .then(() => {
        dispatch(otpServerMessage(confirmDetail.phoneNumber));
      })
      .catch(error => {
        dispatch(generalServerMessage(error.response, serverMessageTargets.OTP_DIALOG, false));
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

function getScreenshot(section) {
  // Make sure to turn off the spinner if it happens to still be up
  const page = document.documentElement.outerHTML.replace(
    'class="sm-spinner-active"',
    'class="sm-spinner-active hidden"'
  );

  return {
    width: document.body.clientWidth,
    height: document.documentElement.scrollHeight,
    section,
    page
  };
}

function sendScreenshot(comparison, offer, accessToken, submissionId, screenshot) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'submit', 'screenshot' + screenshot.section);
    });

    const path = determineApiPath(
      '/api/convert/screenshot-submission',
      '/api/convert/screenshot-accessKey',
      comparison,
      submissionId
    );

    const axiosConfig = getAxiosConfig(accessToken);

    axios
      .post(
        path,
        {
          ...screenshot,
          offerId: offer.offerId
        },
        axiosConfig
      )
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

function determineApiPath(basePathSubmission, basePathAccessKey, comparison, submissionId) {
  return comparison && comparison.accessId && comparison.accessKey
    ? `${basePathAccessKey}/${comparison.accessId}/?key=${comparison.accessKey}`
    : `${basePathSubmission}/${comparison.submissionType}/${submissionId}`;
}

function getAxiosConfig(accessToken, timeout) {
  var timeoutObj = timeout ? { timeout } : {};

  var config = accessToken
    ? {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        ...timeoutObj
      }
    : timeoutObj;

  return config;
}
