import React from 'react';
import { useSelector } from 'react-redux';

const Sheet = ({ children, className, style }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  return (
    <div
      className={`sheet ${className}`}
      style={{ backgroundImage: `url("${staticContentUrl}/images/util/sheet.svg")`, ...style }}
    >
      {children}
    </div>
  );
};

export default Sheet;
