import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  submitEstimateWithKey,
  submitEstimateWithToken,
  submitEstimateUnathenticated
} from '../../actions/energyEstimateActions';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import DatePicker from 'react-datepicker';
import QsCheckBox from '../common/qsCheckBox';
import QsButtonGroup from '../common/qsButtonGroup';
import {
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Popover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap';
import moment from 'moment';
import { useAuth0 } from '../../auth/auth0-Provider';
import AddressControl from '../common/addressControl';
import SubmitCommon from '../common/submitCommon';
import EnergyDistributor from '../common/energyDistributor';
import ExitLaunch from '../common/exitLaunch';
import PoweredBySmartMe from '../common/poweredBySmartMe';
import ServicesAuto from '../serviceCard/servicesAuto';

import { billTypes } from '../../enums/billTypes';
import { operatorModes } from '../../enums/launchTypes';
import { implementationCodes } from '../../enums/implementationCodes';
import { energyUsageTypes, getEnergyUsageTypes } from '../../enums/energyUsageTypes';
import {
  occupancyTypes,
  occupancyPurposes,
  usageTypes,
  solarTypes,
  internetConnectionTypeTypes,
  internetDownloadSpeedTypes,
  submissionTypes
} from '../../enums/submissionTypes';
import { uploadBillTypes, uploadSubTypes } from '../../enums/uploadBillTypes';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import {
  setServerMessage,
  setServerMessageClear,
  displayNoOffersMessage,
  displayErrorMessage,
  comparisonErrorMessage
} from '../../actions/serverMessageActions';
import { setUser } from '../../actions/userActions';
import { getDefaultAddressStatus, getNbnStatus } from '../../actions/internetActions';
import ServerMessage from '../common/serverMessage';

import 'react-google-places-autocomplete/dist/index.min.css';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getAddress, hasValidAddress } from '../../utilities/addressUtilities';
import { submitAutoEstimates } from '../serviceCard/serviceCardUtilities';
import { getSessionActivity } from '../../utilities/commonUtilities';
import { agentHasEnergy } from '../../utilities/billTypeUtilities';
import { addSessionActivity } from '../../actions/sessionActivityActions';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes';
import {
  getSecondaryEnergyServiceType,
  getRegionDistributors,
  getDistributorDefaults,
  getDistributorItem
} from '../../actions/distributorActions';
import OccupancyDate from '../common/occupancyDate';
import SubmitDialogBanner from './submitDialogBanner';
import BrandScroller from '../common/brandScroller';

const EnergyEstimateAu = props => {
  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { providers, internetDownloadSpeeds, internetConnectionTypes } = useSelector(
    state => state.config.referenceData
  );
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { accessKey: comparisonAccessKey, accessId: comparisonAccessId, isLoaded: comparisonLoaded } = useSelector(
    state => state.comparison
  );
  const { accessKey: userAccessKey, accessId: userAccessId } = useSelector(state => state.user);
  const { serverMessage, selectedAgent, session } = useSelector(state => state);

  const { country, hasAgents, billTypes: agentBillTypes, defaultOccupancyPurpose } = useSelector(state => state.config);
  const { googleAnalyticsClientCode, movingHouseEnabled, showPoweredBy } = useSelector(state => state.config.settings);
  const { data: userBills } = useSelector(state => state.userBills);
  const { operatorMode } = useSelector(state => state.landing.home);

  const sessionActivity = getSessionActivity(session);

  const {
    serviceType,
    launchMode,
    closeButtonText,
    confirmButtonText,
    showBackButton,
    showCancelButton,
    showCloseButton,
    userInitiated,
    submissionId,
    submission,
    onBackButtonClick,
    onUploadBillTypeSelect,
    onCancel,
    onClose,
    onSuccess,
    isVerify,
    uploadBillType,
    implementationCode
  } = props;

  const {
    billType,
    occupancyDateUncertain = false,
    occupancyType = occupancyTypes.CURRENT.Id,
    occupancyPurpose = occupancyPurposes.RESIDENTIAL.Id,
    occupancyDate,
    hasSolar,
    hasPremiumSolar,
    provider,
    distributor,
    propertyAddress,
    recentBill,
    billStartDateStandardised,
    billEndDateStandardised,
    billStartDate,
    billEndDate,
    hasDemand,
    usage,
    solarFeedIn,
    meterIdentifier,
    defaultComparison
  } = submission;

  const otherProvider = 'Other';

  const implementationEnergyUsageTypes = getEnergyUsageTypes(implementationCode);

  const primaryEstimateOptions = { showSpinner: true, applySleep: true };
  const secondaryEstimateOptions = { showSpinner: false, applySleep: false, updateComparison: false };

  const serviceTypeOptions = { includeExternal: false };

  const hasElectricity = billTypes.hasServiceType(agentBillTypes, billTypes.GAS, serviceTypeOptions);
  const hasGas = billTypes.hasServiceType(agentBillTypes, billTypes.GAS, serviceTypeOptions);
  const hasInternet = billTypes.hasServiceType(agentBillTypes, billTypes.INTERNET, serviceTypeOptions);
  const movingHouse =
    uploadBillType === uploadBillTypes.MOVING_HOUSE && movingHouseEnabled && agentHasEnergy(agentBillTypes);

  const movingHouseServiceTypes = billTypes.getServices(
    agentBillTypes,
    [billTypes.ELECTRICITY, billTypes.GAS, billTypes.INTERNET],
    serviceTypeOptions
  );

  const servicesText = billTypes.getServicesText(
    agentBillTypes,
    [billTypes.ELECTRICITY, billTypes.GAS, billTypes.INTERNET],
    serviceTypeOptions
  );

  const getPrimaryServiceType = () => {
    if (!movingHouse) return serviceType;
    if (hasElectricity) return billTypes.ELECTRICITY;
    if (hasGas) return billTypes.GAS;
    if (hasInternet) return billTypes.INTERNET;

    return billTypes.NONE;
  };

  const getSolarType = () => {
    return hasPremiumSolar ? solarTypes.PREMIUM.Id : hasSolar ? solarTypes.STANDARD.Id : solarTypes.NONE.Id;
  };

  const getProviderCode = () => {
    return provider ? provider.code : otherProvider;
  };

  const getInitialDistributorCode = () => {
    return distributor && !defaultComparison ? distributor.selectionCode : null;
  };

  const getOccupancyPurpose = () => {
    return occupancyPurpose ? occupancyPurpose : defaultOccupancyPurpose;
  };

  const getDate = date => {
    return !date ? null : moment(date).toDate();
  };

  const getInitialOccupancyDate = () => {
    return occupancyDate && !defaultComparison ? getDate(occupancyDate) : null;
  };

  const { ESTIMATE_DIALOG: target } = serverMessageTargets;

  const primaryServiceType = getPrimaryServiceType();

  const initialAddress = getAddress(propertyAddress);
  const initialOccupancyType = uploadBillType === uploadBillTypes.MOVING_HOUSE ? occupancyTypes.NEW.Id : occupancyType;

  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(true);

  const [distributorSets, setDistributorSets] = useState({});
  const [installDates, setInstallDates] = useState([]);
  const [distributorSetsLoaded, setDistributorSetsLoaded] = useState(false);
  const [selectedDistributorPrimary, setSelectedDistributorPrimary] = useState();
  const [selectedDistributorSecondary, setSelectedDistributorSecondary] = useState();
  const [providerDropDownOpen, setProviderDropdownOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedOccupancyType, setSelectedOccupancyType] = useState(initialOccupancyType);
  const [selectedOccupancyDateUncertain, setSelectedOccupancyDateUncertain] = useState(occupancyDateUncertain);
  const [selectedOccupancyPurpose, setSelectedOccupancyPurpose] = useState(occupancyPurpose);
  const [selectedOccupancyDate, setSelectedOccupancyDate] = useState(getInitialOccupancyDate());
  const [reset, setReset] = useState();
  const [selectedUsageType, setSelectedUsageType] = useState(usageTypes.MEDIUM.Id);
  const [selectedSolarType, setSelectedSolarType] = useState(getSolarType());
  const [selectedProviderId, setSelectedProviderId] = useState(getProviderCode());
  const [selectedProviderName, setSelectedProviderName] = useState();
  const [selectedProviderLogo, setSelectedProviderLogo] = useState();
  const [selectedRecentBill, setSelectedRecentBill] = useState(recentBill === true ? true : false);
  const [selectedHasDemand, setSelectedHasDemand] = useState(hasDemand ? hasDemand : false);

  const [hasChanges, setHasChanges] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submissionCommon, setSubmissionCommon] = useState({});

  const [initialComparisonEmailValue, setInitialComparisonEmailValue] = useState();
  const [initialEmailAddressValue, setInitialEmailAddressValue] = useState(null);

  const [usageEntryGuideOpen, setUsageEntryGuideOpen] = useState(false);
  const [billDateStandarisedWarning, setBillDateStandarisedWarning] = useState(
    isVerify && (!billStartDateStandardised || !billEndDateStandardised)
  );
  const [billDays, setBillDays] = useState(0);

  const [selectedConnectionType, setSelectedConnectionType] = useState(internetConnectionTypeTypes.NBN.Id);
  const [selectedDownloadSpeed, setSelectedDownloadSpeed] = useState(internetDownloadSpeedTypes.ALL.Id.toString());
  const [selectedDataUnlimited, setSelectedDataUnlimited] = useState(false);
  const [addressStatusLoading, setAddressStatusLoading] = useState(false);
  const [addressStatus, setAddressStatus] = useState(getDefaultAddressStatus());

  const getBillDate = (billDateStandarised, billDate) => {
    return billDateStandarised ? moment(billDateStandarised).toDate() : billDate ? moment(billDate).toDate() : null;
  };

  const [selectedBillStartDate, setSelectedBillStartDate] = useState(
    getBillDate(billStartDateStandardised, billStartDate)
  );
  const [selectedBillEndDate, setSelectedBillEndDate] = useState(getBillDate(billEndDateStandardised, billEndDate));

  const setBillDaysFromDates = (startDate, endDate) => {
    if (startDate && endDate) {
      let startMoment = moment(startDate);
      let endMoment = moment(endDate);

      let days = endMoment.diff(startMoment, 'days');
      if (days >= 0) setBillDays(days + 1);
      else setBillDays(0);
    }
  };

  const getInitialUsage = (recentBill, usage, solarFeedIn) => {
    if (!recentBill) return null;

    var newSelectedUsage;

    if (usage && usage.length > 0) {
      newSelectedUsage = usage.map(u => {
        return { energyUsageType: u.energyUsageType, usage: u.usage };
      });
    } else newSelectedUsage = [{ energyUsageType: energyUsageTypes.PEAK.Id, usage: null }];

    if (selectedSolarType === solarTypes.STANDARD.Id || selectedSolarType === solarTypes.PREMIUM.Id) {
      if (solarFeedIn && solarFeedIn.length > 0) {
        newSelectedUsage.push(
          ...solarFeedIn.map(u => {
            return { energyUsageType: energyUsageTypes.SOLAR_FEED_IN.Id, usage: u.usage };
          })
        );
      } else newSelectedUsage.push({ energyUsageType: energyUsageTypes.SOLAR_FEED_IN.Id, usage: null });
    }

    return newSelectedUsage;
  };

  const [selectedUsage, setSelectedUsage] = useState(getInitialUsage(recentBill, usage, solarFeedIn));

  const minBillDate = moment()
    .add(-731, 'days')
    .toDate();

  const maxBillDate = moment()
    .add(-1, 'days')
    .toDate();

  const showSolar = implementationCode === implementationCodes.ELECTRICITY.AU_DEFAULT;
  const showDemand = showSolar;
  const showAgents = !selectedAgent.isLoaded && hasAgents;

  const hasSelectedAddress = hasValidAddress(selectedAddress);

  // --------------------------------------------------------------------------
  // Submit new estimate request.
  // --------------------------------------------------------------------------
  const onSubmitEstimate = () => {
    const energyEstimate = getEnergyEstimate(billType, true);

    // If the user checked the comparison email checkbox from an initial state of false (i.e. they want an email for this comparison),
    // then fire an API call to initiate the email send (even if nothing else on the form changed; i.e. hasChanges = false)
    const sendComparisonEmailInitiated = !initialComparisonEmailValue && submissionCommon.sendComparisonEmail;

    // If a the user is on the compare page (i.e. comparison is loaded) but the access details are missing, they were cleared because of an error with the last estimate submit.
    // so we need to force an API call (even if nothing else on the form changed; i.e. hasChanges = false)
    const comparisonError = comparisonLoaded && (!comparisonAccessId || !comparisonAccessKey);

    // Check if anything has changed before making a re-estimate request.
    // User initiated considered (i.e. hasChanges = false), as user may have triggered the dialog and accepted a complete set of defaults (if possible)
    if (hasChanges || userInitiated || defaultComparison || sendComparisonEmailInitiated || comparisonError) {
      const userBill = userBills.find(e => e.submissionId === submissionId);
      const userBillAccessKey = userBill && userBill.accessKey ? userBill.accessKey.accessKey : null;
      const userBillAccessId = userBill && userBill.accessKey ? userBill.accessKey.accessId : null;

      const accessKey = userBillAccessKey || comparisonAccessKey || userAccessKey;
      const accessId = userBillAccessId || comparisonAccessId || userAccessId;

      if (movingHouse) {
        submitMovingHouseEstimates(accessKey, accessId);
      } else {
        if (accessKey && accessId) {
          onSubmitWithKey(serviceType, { estimate: energyEstimate, access: { key: accessKey, id: accessId } }, true);
        } else if (isUserAuthenticated()) {
          onSubmitWithToken(serviceType, { estimate: energyEstimate }, true);
        } else {
          onSubmitUnathenticated(serviceType, { estimate: energyEstimate }, true);
        }
      }

      standardEvents(
        movingHouse ? 'moving-house-dialog' : 'energy-estimate-dialog',
        'Click',
        'Confirm',
        googleAnalyticsClientCode
      );
    } else {
      onSuccess({
        hasOffers: true,
        estimateWasRun: false,
        userBill: {}
      });
    }
  };

  const dispatchAutoEstimates = () => {
    // For moving house, we don't want to create auto-estimates for service types already included in Moving House.
    // i.e. Electricity, Gas and Internet.
    const autoAgentBillTypes = movingHouse
      ? agentBillTypes.filter(
          item => ![billTypes.ELECTRICITY.Id, billTypes.GAS.Id, billTypes.INTERNET.Id].includes(item.billTypeId)
        )
      : agentBillTypes;

    dispatch(
      submitAutoEstimates(
        serviceType,
        selectedAddress,
        selectedAgent,
        sessionActivity,
        submissionCommon,
        autoAgentBillTypes,
        userBills,
        googleAnalyticsClientCode
      )
    );
  };

  const onSubmitWithToken = (serviceType, data, primaryService) => {
    const options = primaryService ? primaryEstimateOptions : secondaryEstimateOptions;
    const onComplete = primaryService ? estimateComplete : recordSessionActivity;
    const onError = primaryService ? estimateError : undefined;
    const fn = async () => {
      const accessToken = await getAccessToken();
      dispatch(submitEstimateWithToken(accessToken, serviceType, data, options, onComplete, onError));
    };
    fn();
  };

  const onSubmitWithKey = (serviceType, data, primaryService) => {
    const options = primaryService ? primaryEstimateOptions : secondaryEstimateOptions;
    const onComplete = primaryService ? estimateComplete : recordSessionActivity;
    const onError = primaryService ? estimateError : undefined;

    dispatch(submitEstimateWithKey(serviceType, data, options, onComplete, onError));
  };

  const onSubmitUnathenticated = (serviceType, data, primaryService) => {
    const options = primaryService ? primaryEstimateOptions : secondaryEstimateOptions;
    const onComplete = primaryService ? estimateComplete : recordSessionActivity;
    const onError = primaryService ? estimateError : undefined;
    dispatch(submitEstimateUnathenticated(serviceType, data, options, onComplete, onError));
  };

  // --------------------------------------------------------------------------
  // Estimate comparison error handler.
  // --------------------------------------------------------------------------
  const estimateError = error => {
    dispatch(comparisonErrorMessage(error, target));
  };

  // --------------------------------------------------------------------------
  // Get energy estimate model.
  // --------------------------------------------------------------------------
  const getEnergyEstimate = (serviceType, primaryService) => {
    return {
      property: {
        address: { ...selectedAddress },
        occupancyDate: selectedOccupancyDate ? moment(selectedOccupancyDate).format('YYYY-MM-DD') : null,
        occupancyType: selectedOccupancyType,
        occupancyPurpose: selectedOccupancyPurpose,
        occupancyDateUncertain: selectedOccupancyDateUncertain
      },
      ...submissionCommon,
      sessionActivity,
      agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
      currentProviderCode: selectedProviderId === otherProvider ? null : selectedProviderId,
      distributorCode: primaryService
        ? selectedDistributorPrimary
          ? selectedDistributorPrimary.selectionCode
          : null
        : selectedDistributorSecondary
        ? selectedDistributorSecondary.selectionCode
        : null,
      serviceType,
      usageLevel: selectedUsageType,
      solarLevel: selectedSolarType,
      recentBill: selectedRecentBill,
      billStartDateStandardised:
        selectedRecentBill && selectedBillStartDate ? moment(selectedBillStartDate).format('YYYY-MM-DD') : null,
      billEndDateStandardised:
        selectedRecentBill && selectedBillEndDate ? moment(selectedBillEndDate).format('YYYY-MM-DD') : null,
      usage:
        selectedRecentBill && selectedUsage
          ? selectedUsage.filter(w => w.energyUsageType !== energyUsageTypes.SOLAR_FEED_IN.Id)
          : null,
      solarFeedIn:
        selectedRecentBill && selectedUsage
          ? selectedUsage.filter(w => w.energyUsageType === energyUsageTypes.SOLAR_FEED_IN.Id)
          : null,
      hasDemand: selectedRecentBill && selectedHasDemand,
      meterIdentifier
    };
  };

  // --------------------------------------------------------------------------
  // Get internet estimate model.
  // --------------------------------------------------------------------------
  const getInternetEstimate = () => {
    return {
      address: {
        ...selectedAddress
      },
      occupancyDateUncertain: selectedOccupancyDateUncertain,
      occupancyDate: selectedOccupancyDate ? moment(selectedOccupancyDate).format('YYYY-MM-DD') : null,
      occupancyType: selectedOccupancyType,
      ...submissionCommon,
      sessionActivity,
      agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
      serviceType: billTypes.INTERNET.Code,
      connectionType: selectedConnectionType,
      techType: addressStatus.techType,
      developmentCharges: addressStatus.developmentCharges,
      currentSpend: null,
      dataUnlimited: selectedDataUnlimited,
      downloadSpeed: parseInt(selectedDownloadSpeed),
      sendSolarInstallationEmail: false // overrides submit common property
    };
  };

  // --------------------------------------------------------------------------
  // Initialize the form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    initialize();
  }, []);

  // --------------------------------------------------------------------------
  // Invalid form submit handler.
  // --------------------------------------------------------------------------
  const handleInvalidSubmit = (event, errors, values) => {
    setHasSubmitted(true);
  };

  // --------------------------------------------------------------------------
  // Submission common handlers.
  // --------------------------------------------------------------------------
  const onSubmissionCommonLoad = value => {
    if (value) {
      setInitialComparisonEmailValue(value.sendComparisonEmail);
      setInitialEmailAddressValue(value.emailAddress);
      setSubmissionCommon(value);
    }
  };

  const onSubmissionCommonChange = value => {
    setSubmissionCommon(value);
  };

  const onSelectBillStartDate = date => {
    setSelectedBillStartDate(date);
    if (billDateStandarisedWarning) setBillDateStandarisedWarning(false);

    setBillDaysFromDates(date, selectedBillEndDate);
  };

  const onSelectBillEndDate = date => {
    setSelectedBillEndDate(date);
    if (billDateStandarisedWarning) setBillDateStandarisedWarning(false);

    setBillDaysFromDates(selectedBillStartDate, date);
  };

  // --------------------------------------------------------------------------
  // Detect changes in form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    let initialOccupancyDate = getInitialOccupancyDate();
    let date1 = initialOccupancyDate ? initialOccupancyDate.toString() : null;
    let date2 = selectedOccupancyDate ? getDate(selectedOccupancyDate).toString() : null;

    let originalUsage = getInitialUsage(recentBill, usage, solarFeedIn);
    let originalBillStartDate = getBillDate(billStartDateStandardised, billStartDate);
    let originalBillEndDate = getBillDate(billEndDateStandardised, billEndDate);
    const selectedFullAddress = getAddress(selectedAddress).fullAddress;
    const propertyFullAddress = getAddress(propertyAddress).fullAddress;

    let changed =
      selectedFullAddress !== propertyFullAddress ||
      initialOccupancyType !== selectedOccupancyType ||
      occupancyDateUncertain !== selectedOccupancyDateUncertain ||
      (selectedOccupancyType === occupancyTypes.NEW.Id && date1 !== date2) ||
      (selectedOccupancyType === occupancyTypes.CURRENT.Id && getProviderCode() !== selectedProviderId) ||
      (hasSolar && !hasPremiumSolar ? true : false) !== (selectedSolarType === 1) ||
      (hasPremiumSolar ? true : false) !== (selectedSolarType === 2) ||
      usageTypes.MEDIUM.Id !== selectedUsageType ||
      occupancyPurpose !== selectedOccupancyPurpose ||
      recentBill !== selectedRecentBill ||
      (originalBillStartDate && selectedBillStartDate
        ? originalBillStartDate.getTime() !== selectedBillStartDate.getTime()
        : originalBillStartDate || selectedBillStartDate) ||
      (originalBillEndDate && selectedBillEndDate
        ? originalBillEndDate.getTime() !== selectedBillEndDate.getTime()
        : originalBillEndDate || selectedBillEndDate) ||
      (hasDemand ? hasDemand : false) !== selectedHasDemand ||
      (originalUsage && selectedUsage
        ? JSON.stringify(originalUsage) !== JSON.stringify(selectedUsage)
        : originalUsage || selectedUsage) ||
      (initialEmailAddressValue ? initialEmailAddressValue : null) !==
        (submissionCommon.emailAddress ? submissionCommon.emailAddress : null);

    if (defaultComparison) {
      const distributorItem = getDistributorItem(distributorSets, serviceType);

      if (selectedDistributorPrimary && distributorItem.length > 1) {
        changed = true;
      }
    } else {
      if (
        propertyFullAddress !== undefined &&
        (distributor ? distributor.selectionCode : null) !==
          (selectedDistributorPrimary ? selectedDistributorPrimary.selectionCode : null)
      ) {
        changed = true;
      }
    }

    if (movingHouse && hasInternet && !changed) {
      changed =
        selectedDownloadSpeed !== internetDownloadSpeedTypes.ALL.Id.toString() ||
        selectedDataUnlimited !== false ||
        addressStatus.connectionType !== selectedConnectionType;
    }

    setHasChanges(changed);
  }, [
    selectedUsageType,
    selectedOccupancyType,
    selectedOccupancyDateUncertain,
    selectedOccupancyPurpose,
    selectedOccupancyDate,
    selectedSolarType,
    selectedProviderId,
    selectedDistributorPrimary,
    selectedAddress,
    selectedRecentBill,
    selectedBillStartDate,
    selectedBillEndDate,
    selectedUsage,
    selectedHasDemand,
    submissionCommon.emailAddress,
    selectedDownloadSpeed,
    selectedDataUnlimited
  ]);

  // --------------------------------------------------------------------------
  // Initialize or reset the form.
  // --------------------------------------------------------------------------
  const initialize = reset => {
    setSelectedAddress(initialAddress);
    applyDistributors(initialAddress);

    if (reset) {
      // Clear message on only reset, as we do not want to clear out any verify messages.
      dispatch(setServerMessageClear(target, serverMessage));
      dispatch(setUser({ emailAddress: initialEmailAddressValue }));
      setReset(Date.now());
    }

    setSelectedConnectionType(internetConnectionTypeTypes.NBN.Id);
    setSelectedDownloadSpeed(internetDownloadSpeedTypes.ALL.Id.toString());
    setSelectedDataUnlimited(false);
    setAddressStatusLoading(false);
    setAddressStatus(getDefaultAddressStatus());

    setSelectedOccupancyDate(getInitialOccupancyDate());
    setSelectedOccupancyDateUncertain(occupancyDateUncertain);
    setSelectedUsageType(usageTypes.MEDIUM.Id);
    setSelectedOccupancyPurpose(getOccupancyPurpose());
    setSelectedOccupancyType(initialOccupancyType);
    setSelectedSolarType(getSolarType());

    setSelectedRecentBill(recentBill);

    let initialBillStartDate = getBillDate(billStartDateStandardised, billStartDate);
    setSelectedBillStartDate(initialBillStartDate);

    let initialBillEndDate = getBillDate(billEndDateStandardised, billEndDate);
    setSelectedBillEndDate(initialBillEndDate);

    setBillDaysFromDates(initialBillStartDate, initialBillEndDate);

    setSelectedUsage(getInitialUsage(recentBill, usage, solarFeedIn));
    setSelectedHasDemand(hasDemand ? hasDemand : false);

    onProviderChange({ currentTarget: { id: getProviderCode() } });

    setHasSubmitted(false);
    setHasChanges(false);
  };

  // --------------------------------------------------------------------------
  // Address selected handler.
  // --------------------------------------------------------------------------
  const onAddressSelected = address => {
    setSelectedAddress(address);

    dispatch(setServerMessageClear(target, serverMessage));

    applyDistributors(address, selectedDistributorPrimary ? selectedDistributorPrimary.selectionCode : null);

    if (hasInternet && movingHouse) {
      checkNbnStatus(address);
    }
  };

  // --------------------------------------------------------------------------
  // NBN address status.
  // --------------------------------------------------------------------------
  const checkNbnStatus = address => {
    if (hasValidAddress(address)) {
      const movingHouseNbnStatusOptions = { showSpinner: false, applySleep: false, applyServerMessage: false };

      setAddressStatusLoading(true);

      dispatch(
        getNbnStatus(
          {
            address: { ...address }
          },
          result => {
            setAddressStatus(result);
            setSelectedConnectionType(result.connectionType);
            setAddressStatusLoading(false);
          },
          movingHouseNbnStatusOptions
        )
      );
    }
  };

  // --------------------------------------------------------------------------

  // Get services that are supported for a selected address.
  // --------------------------------------------------------------------------
  const getSupportedServices = () => {
    const result = [];

    // Add energy service types that have distributors.
    if (getDistributorItem(distributorSets, billTypes.ELECTRICITY).length > 0) result.push(billTypes.ELECTRICITY);
    if (getDistributorItem(distributorSets, billTypes.GAS).length > 0) result.push(billTypes.GAS);

    // Add internet service type as long as the agent hasInternet and a valid address is selected.
    if (hasInternet && hasValidAddress(selectedAddress)) result.push(billTypes.INTERNET);

    return result;
  };

  // --------------------------------------------------------------------------
  // Get an ordered set of moving house services.
  // --------------------------------------------------------------------------
  const submitMovingHouseEstimates = (accessKey, accessId) => {
    const services = [];

    // Add moving house services in order.
    movingHouseServiceTypes.forEach((e, i) => {
      if (isServiceSupported(e)) {
        services.push({ serviceType: e, primaryService: i === 0 });
      }
    });

    services.forEach(e => {
      let estimate = null;
      switch (e.serviceType) {
        case billTypes.INTERNET:
          estimate = getInternetEstimate();
          break;
        default:
          estimate = { ...getEnergyEstimate(e.serviceType.Code, e.primaryService) };
      }

      if (accessKey && accessId) {
        const access = { key: accessKey, id: accessId };
        onSubmitWithKey(e.serviceType, { estimate, access }, e.primaryService);
      } else if (isUserAuthenticated()) {
        onSubmitWithToken(e.serviceType, { estimate }, e.primaryService);
      } else {
        onSubmitUnathenticated(e.serviceType, { estimate }, e.primaryService);
      }
    });
  };

  // --------------------------------------------------------------------------
  // Check if service is supported for an agent.
  // --------------------------------------------------------------------------
  const isServiceSupported = serviceType => {
    const hasService = billTypes.hasServiceType(agentBillTypes, serviceType, serviceTypeOptions);
    const supportedService = getSupportedServices().find(e => e === serviceType);

    return hasService && supportedService;
  };

  // --------------------------------------------------------------------------
  // Distributors: Apply region distributors
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (distributorSetsLoaded) applyDistributorDefaults(getInitialDistributorCode(), distributorSets);
  }, [serviceType]);

  const applyDistributorDefaults = (distributorCode, items) => {
    const result = getDistributorDefaults(distributorCode, items, agentBillTypes, primaryServiceType, movingHouse);

    if (!result.success) {
      dispatch(
        displayErrorMessage(
          target,
          `Unfortunately we currently do not provide ${primaryServiceType.Name.toLowerCase()} plans for this address.`,
          true
        )
      );
    }

    setConfirmButtonEnabled(result.success);
    setSelectedDistributorPrimary(result.primaryDistributor);
    setSelectedDistributorSecondary(result.secondaryDistributor);
  };

  const applyDistributors = address => {
    const singleServiceType = movingHouse ? null : billType;

    const onComplete = result => {
      const installDates =
        result.occupancyDates && result.occupancyDates.map(date => moment.tz(date, 'YYYY-MM-DD', 'GMT').toDate());
      setInstallDates(installDates);
      setDistributorSets(result.items);
      setDistributorSetsLoaded(result.success);

      if (result.success) {
        applyDistributorDefaults(getInitialDistributorCode(), result.items);
      } else {
        setSelectedDistributorPrimary(null);
        setSelectedDistributorSecondary(null);
      }
    };

    const onError = error => {
      dispatch(
        setServerMessage({
          ...error.response.data,
          scrollTo: true,
          target,
          code: error.response.status
        })
      );
    };

    dispatch(getRegionDistributors(address, agentBillTypes, singleServiceType, onComplete, onError));
  };

  // --------------------------------------------------------------------------
  // Current provider change handler.
  // --------------------------------------------------------------------------
  const onProviderChange = event => {
    if (event.currentTarget.id === otherProvider) {
      setSelectedProviderId(otherProvider);
      setSelectedProviderName(otherProvider);
      setSelectedProviderLogo(null);
    } else {
      let item = providers.filter(item => item.code === event.currentTarget.id)[0];
      setSelectedProviderId(item.code);
      setSelectedProviderName(item.displayCode);
      setSelectedProviderLogo(item.logo);
    }
  };

  // --------------------------------------------------------------------------
  // Providers: Get DropdownItem element.
  // --------------------------------------------------------------------------
  let currentLetter;

  const providerDropdownItems = (() => {
    let items =
      providers &&
      providers
        .filter(item => billTypes.hasServiceType(item.billTypes, primaryServiceType))
        .sort(function(a, b) {
          return a.displayCode.toLowerCase().localeCompare(b.displayCode.toLowerCase());
        })
        .map(item => {
          const firstLetter = item.displayCode.charAt(0);
          const element = (
            <DropdownItem key={item.code} id={item.code} onClick={e => onProviderChange(e)}>
              {item.displayCode}
            </DropdownItem>
          );

          if (!currentLetter || currentLetter !== firstLetter) {
            currentLetter = firstLetter;
            return (
              <React.Fragment key={firstLetter.toUpperCase()}>
                <DropdownItem header>{firstLetter.toUpperCase()}</DropdownItem>
                <DropdownItem divider /> {element}
              </React.Fragment>
            );
          } else {
            return element;
          }
        });

    items.push(
      <React.Fragment key={'__providerOther'}>
        <DropdownItem divider />
        <DropdownItem key={otherProvider} id={otherProvider} onClick={e => onProviderChange(e)}>
          {otherProvider}
        </DropdownItem>
      </React.Fragment>
    );

    return items;
  })();

  const removeRecentBillData = () => {
    setSelectedRecentBill(false);
    setSelectedBillStartDate(null);
    setSelectedBillEndDate(null);
    setSelectedHasDemand(false);
    setSelectedUsage(null);
  };

  const setOccupancyDateUncertainButtonGroup = value => {
    setSelectedOccupancyDateUncertain(value);
    standardEvents('estimate-dialog', 'occupancy-date-uncertain', value ? 'Yes' : 'No', googleAnalyticsClientCode);
  };

  const setOccupancyTypeButtonGroup = event => {
    let option = event.target.getAttribute('data-value');
    setSelectedOccupancyType(parseInt(option));

    // Remove the bill data if they are moving in
    if (selectedOccupancyType === occupancyTypes.NEW.Id) {
      removeRecentBillData();
    }

    standardEvents('estimate-dialog', 'occupancy-type', option === '1' ? 'New' : 'Existing', googleAnalyticsClientCode);
  };

  const setOccupancyPurposeButtonGroup = event => {
    let option = event.target.getAttribute('data-value');
    setSelectedOccupancyPurpose(parseInt(option));

    standardEvents(
      'estimate-dialog',
      'occupancy-purpose',
      option === '1' ? 'Residential' : 'Business',
      googleAnalyticsClientCode
    );
  };

  const setSolarTypeButtonGroup = event => {
    let option = event.target.getAttribute('data-value');
    var optionValue = parseInt(option);
    setSelectedSolarType(optionValue);

    const hasSolarSelected = optionValue === solarTypes.STANDARD.Id || optionValue === solarTypes.PREMIUM.Id;

    var newSelectedUsage = null;
    if (
      selectedRecentBill &&
      hasSolarSelected &&
      selectedUsage.findIndex(u => u.energyUsageType === energyUsageTypes.SOLAR_FEED_IN.Id) === -1
    ) {
      // Add a solar feed-in row if not one already
      newSelectedUsage = [...selectedUsage];
      newSelectedUsage.push({ energyUsageType: energyUsageTypes.SOLAR_FEED_IN.Id, usage: null });
    } else if (selectedRecentBill && optionValue === solarTypes.NONE.Id) {
      // Remove solar feed-in row if one exists
      newSelectedUsage = selectedUsage.filter(u => u.energyUsageType !== energyUsageTypes.SOLAR_FEED_IN.Id);
    }

    if (newSelectedUsage) setSelectedUsage(newSelectedUsage);

    standardEvents(
      'estimate-dialog',
      'solar-type',
      optionValue === solarTypes.NONE.Id
        ? solarTypes.NONE.Name
        : optionValue === solarTypes.STANDARD.Id
        ? solarTypes.STANDARD.Name
        : solarTypes.PREMIUM.Name,
      googleAnalyticsClientCode
    );
  };

  // --------------------------------------------------------------------------
  // Recent bill change handler.
  // --------------------------------------------------------------------------
  const onRecentBillChange = event => {
    let option = event.target.getAttribute('data-value');
    let optionValue = option === 'true';
    setSelectedRecentBill(optionValue);

    if (optionValue) setSelectedUsage(getInitialUsage(optionValue, null, null));
    else removeRecentBillData();

    standardEvents('estimate-dialog', 'recent-bill', optionValue ? 'Yes' : 'No', googleAnalyticsClientCode);
  };

  // --------------------------------------------------------------------------
  // Energy usage guide click handler.
  // --------------------------------------------------------------------------
  const onEnergyUsageGuide = isOpen => {
    setUsageEntryGuideOpen(isOpen);

    standardEvents(
      'estimate-dialog',
      'energy-usage-guide-info-popver',
      isOpen ? 'Open' : 'Close',
      googleAnalyticsClientCode
    );
  };

  // --------------------------------------------------------------------------
  // Energy usage type change handler
  // --------------------------------------------------------------------------
  const onChangeEnergyUsageType = (value, index) => {
    var newUsage = { ...selectedUsage[index] };
    newUsage.energyUsageType = parseInt(value);

    var newSelectedUsage = [...selectedUsage.slice(0, index), newUsage, ...selectedUsage.slice(index + 1)];

    setSelectedUsage(newSelectedUsage);
  };

  // --------------------------------------------------------------------------
  // Energy usage value change handler
  // --------------------------------------------------------------------------
  const onChangeUsageValue = (value, index) => {
    if (!isNaN(value) && value !== '') {
      var newUsage = { ...selectedUsage[index] };
      newUsage.usage = parseFloat(value);

      var newSelectedUsage = [...selectedUsage.slice(0, index), newUsage, ...selectedUsage.slice(index + 1)];

      setSelectedUsage(newSelectedUsage);
    }
  };

  // --------------------------------------------------------------------------
  // Energy usage delete handler
  // --------------------------------------------------------------------------
  const onDeleteUsage = index => {
    var newSelectedUsage = [...selectedUsage];
    newSelectedUsage.splice(index, 1);
    setSelectedUsage(newSelectedUsage);
  };

  // --------------------------------------------------------------------------
  // Energy usage add row handler
  // --------------------------------------------------------------------------
  const onAddUsage = () => {
    var newSelectedUsage = [...selectedUsage, { energyUsageType: energyUsageTypes.PEAK.Id, usage: null }];
    setSelectedUsage(newSelectedUsage);
  };

  // --------------------------------------------------------------------------
  // Has demand change handler.
  // --------------------------------------------------------------------------
  const onHasDemandChange = event => {
    let option = event.target.getAttribute('data-value');
    let optionValue = option === 'true';
    setSelectedHasDemand(optionValue);

    standardEvents('estimate-dialog', 'has-demand', optionValue ? 'Yes' : 'No', googleAnalyticsClientCode);
  };

  // --------------------------------------------------------------------------
  // Add session activity.
  // --------------------------------------------------------------------------
  const recordSessionActivity = (comparisonResult, userBill) => {
    const userBillServiceType = billTypes.getById(userBill.billTypeId);

    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          submissionType: submissionTypes.ESTIMATE,
          submissionId: userBill.submissionId,
          serviceTypeId: userBill.billTypeId,
          serviceType: userBillServiceType.Code,
          hasOffers: comparisonResult.hasOffers
        },
        activityType: sessionActivityTypes.COMPARISON_COMPLETED
      })
    );
  };

  // --------------------------------------------------------------------------
  // Estimate comparison complete handler.
  // --------------------------------------------------------------------------
  const estimateComplete = (comparisonResult, userBill) => {
    recordSessionActivity(comparisonResult, userBill);

    if (comparisonResult.hasOffers) {
      onSuccess({
        hasOffers: comparisonResult.hasOffers,
        estimateWasRun: true,
        userBill
      });
    } else {
      setHasChanges(false);
      dispatch(displayNoOffersMessage(target));
    }

    dispatchAutoEstimates();
  };

  const rowClass = 'sm-dialog-row';
  const fieldClass = 'sm-dialog-field-name';
  const columnSizeLg = 12;

  // --------------------------------------------------------------------------
  // Form reference.
  // --------------------------------------------------------------------------
  const formRef = ref => {
    if (ref) {
      // Pre-validate occupancy purpose so when user selects 'Business' they see the error.
      let inputs = {
        hasDemand_validation: ref._inputs['hasDemand_validation']
      };

      if (isVerify) {
        let initialUsage = getInitialUsage(recentBill, usage, solarFeedIn);
        for (var i = 0; i < initialUsage.length; i++) {
          if (initialUsage[i].usage === null) inputs[`usage_${i}`] = ref._inputs[`usage_${i}`];
        }

        inputs.billDate_validation = ref._inputs['billDate_validation'];
        inputs.currentProvider_validation = ref._inputs['currentProvider_validation'];
        inputs.distributor_validation = ref._inputs[`distributor_validation_${serviceType.Code}`];
        inputs.address_validation = ref._inputs['address_validation'];
      }

      if (submission && submission.defaultComparison) {
        inputs.distributor_validation = ref._inputs[`distributor_validation_${serviceType.Code}`];
      }

      ref.setTouched(Object.keys(inputs));
    }
  };

  return (
    <>
      <AvForm onValidSubmit={event => onSubmitEstimate(event)} onInvalidSubmit={handleInvalidSubmit} ref={formRef}>
        <Row>
          <Col>
            <ServerMessage serverMessage={serverMessage} target={target} />
          </Col>
        </Row>

        <BrandScroller serviceType={movingHouse ? billTypes.ELECTRICITY : serviceType} dialogMode />

        {movingHouse && (
          <div className="sm-moving-house-intro-text">
            <p>Get your {servicesText} connected in minutes.</p>
            <p>
              There's no need to fill in multiple application forms. Just confirm your move details below, select your
              preferred plans and we'll arrange all the connections for you. This moving service is free and super fast!
            </p>
          </div>
        )}
        {/* --- YOUR ADDRESS ------------------------------------------------------- */}
        <Row className={`${rowClass} mb-2`}>
          <Col>
            <AddressControl
              key="energyEstimateAddress"
              keySuffix="energy"
              labelText="Your address"
              country={country}
              onAddressSelected={onAddressSelected}
              autoPlaceholder={selectedAddress.fullAddress || 'Property address...'}
              autoPropertyAddress={selectedAddress}
              autoAddressRequired={true}
              manualAddressVisible={true}
              manualSuburbSelectorEnabled={true}
              showAreasSupportedDisclaimer={!hasSelectedAddress}
              showMinimalManualFields={false}
            />
          </Col>
        </Row>
        {movingHouse && (
          <div className="mt-3">
            <ServicesAuto
              selectedAddress={selectedAddress}
              noHeading={true}
              footer={getSupportedServices().length > 0 ? 'Services we can connect at your address' : null}
              serviceType={billTypes.NONE}
              services={movingHouseServiceTypes}
              isLoaded={distributorSetsLoaded}
              supportedServices={getSupportedServices()}
            />
          </div>
        )}
        {/* --- PRIMARY SERVICE DISTRIBUTOR --------------------------------------- */}
        <EnergyDistributor
          key="primaryServiceDistributor"
          showLabel={true}
          serviceType={primaryServiceType}
          distributorSets={distributorSets}
          onSelectedDistributorChanged={item => setSelectedDistributorPrimary(item)}
          distributor={selectedDistributorPrimary}
          occupancyType={selectedOccupancyType}
        />
        {/* --- SECONDARY (MOVING HOUSE) SERVICE DISTRIBUTOR ---------------------- */}
        {movingHouse && hasGas && (
          <EnergyDistributor
            key="secondaryServiceDistributor"
            showLabel={true}
            serviceType={getSecondaryEnergyServiceType(agentBillTypes, primaryServiceType)}
            distributorSets={distributorSets}
            onSelectedDistributorChanged={item => setSelectedDistributorSecondary(item)}
            distributor={selectedDistributorSecondary}
            occupancyType={selectedOccupancyType}
          />
        )}
        {/* --- ARE YOU MOVING ? -------------------------------------------------- */}
        {!movingHouse && (
          <div className={rowClass}>
            <div className={fieldClass}>Is this a new connection / move-in?</div>
            <div>
              <QsButtonGroup
                required={true}
                id="occupancyType"
                fieldName="Occupancy Type"
                additionalClass="sm-button-group"
                selectedValue={selectedOccupancyType.toString()}
                onTouchButton={setOccupancyTypeButtonGroup}
                buttons={[
                  {
                    label: 'No',
                    value: occupancyTypes.CURRENT.Id.toString()
                  },
                  {
                    label: 'Yes',
                    value: occupancyTypes.NEW.Id.toString()
                  }
                ]}
              />
            </div>
          </div>
        )}
        {/* --- CURRENT PROVIDER -------------------------------------------------- */}
        {/* No longer required but will leave the code here for a while. To reinstate just uncomment this section, everything else is still in place */}
        {/* <Collapse isOpen={selectedOccupancyType === occupancyTypes.CURRENT.Id}>
          {selectedOccupancyType && (
            <Row className={rowClass}>
              <Col className={fieldClass} lg={columnSizeLg}>
                Current provider
              </Col>

              <Col>
                {providerDropdownItems && (
                  <Dropdown
                    className="sm-dropdown"
                    isOpen={providerDropDownOpen}
                    toggle={() => setProviderDropdownOpen(prevState => !prevState)}
                  >
                    <DropdownToggle caret>{selectedProviderName || 'Please Select'}</DropdownToggle>
                    <DropdownMenu style={{ width: '100%' }}>{providerDropdownItems}</DropdownMenu>
                  </Dropdown>
                )}
                <div className="qs-estimate-dialog-validation">
                  <AvField
                    type="text"
                    name={`currentProvider_validation`}
                    value={selectedProviderId}
                    className="qs-tc-hidden"
                    validate={{
                      hidden: (value, ctx, input, cb) => {
                        if (!selectedProviderId) {
                          cb('Current Provider is required');
                        } else cb(true);
                      }
                    }}
                  />
                </div>
              </Col>
              <Col>
                {selectedProviderLogo && selectedOccupancyType === occupancyTypes.CURRENT.Id && (
                  <img
                    className="qs-estimate-dialog-logo"
                    src={`${staticContentUrl}/images/providers/${selectedProviderLogo}`}
                    alt={selectedProviderName}
                  />
                )}
              </Col>
            </Row>
          )}
        </Collapse> */}
        {/* --- OCCUPANCY DATE ---------------------------------------------------- */}
        <Collapse isOpen={selectedOccupancyType === occupancyTypes.NEW.Id}>
          {selectedOccupancyType === occupancyTypes.NEW.Id && (
            <OccupancyDate
              rowClass={rowClass}
              fieldClass={fieldClass}
              serviceType={primaryServiceType}
              selectedAddress={selectedAddress}
              distributorSets={distributorSets}
              selectedDistributorPrimary={selectedDistributorPrimary}
              installDates={installDates}
              selectedOccupancyDate={selectedOccupancyDate}
              onSelectedDateChanged={date => {
                setSelectedOccupancyDate(date);
              }}
              selectedOccupancyDateUncertain={selectedOccupancyDateUncertain}
              onOccupancyDateUncertain={setOccupancyDateUncertainButtonGroup}
              reset={reset}
            />
          )}
        </Collapse>
        {/* --- RECENT BILL ? ----------------------------------------------------- */}
        {operatorMode === operatorModes.NONE && selectedOccupancyType === occupancyTypes.CURRENT.Id && (
          <Row className={rowClass}>
            <Col className={fieldClass} lg={columnSizeLg}>
              Got a paper bill?
            </Col>
            <Col xs="12" md="6">
              <QsButtonGroup
                required={true}
                id="recentBill"
                fieldName="Recent Bill"
                additionalClass="sm-button-group"
                selectedValue={selectedRecentBill === true ? 'true' : 'false'}
                onTouchButton={e => onRecentBillChange(e)}
                buttons={[
                  {
                    label: 'No',
                    value: 'false'
                  },
                  {
                    label: 'Yes',
                    value: 'true'
                  }
                ]}
              />
            </Col>
            <Col xs="12" md="6" className="sm-estimate-upload-button">
              <Button
                disabled={!primaryServiceType.ComparisonImplemented}
                className={`sm-button-primary medium right-arrow-small`}
                onClick={() => onUploadBillTypeSelect(uploadBillTypes.WEBSITE, uploadSubTypes.ESTIMATE_UPLOAD)}
              >
                {uploadBillTypes.WEBSITE.ButtonText}
              </Button>
            </Col>
          </Row>
        )}
        {selectedOccupancyType.toString() === occupancyTypes.CURRENT.Id.toString() && selectedRecentBill && (
          <>
            {/* --- BILL DATES ---------------------------------------------------- */}
            <Row className={rowClass}>
              <Col className={fieldClass} xs="4">
                Bill start date
              </Col>
              <Col className={fieldClass} xs="4">
                Bill end date
              </Col>

              <Col className={fieldClass} xs="4">
                Supply period
              </Col>

              <Col xs="4">
                <DatePicker
                  selected={selectedBillStartDate}
                  onChange={date => {
                    onSelectBillStartDate(date);
                  }}
                  minDate={minBillDate}
                  maxDate={maxBillDate}
                  useWeekdaysShort={true}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                />
              </Col>
              <Col xs="4">
                <DatePicker
                  selected={selectedBillEndDate}
                  onChange={date => {
                    onSelectBillEndDate(date);
                  }}
                  minDate={minBillDate}
                  maxDate={maxBillDate}
                  useWeekdaysShort={true}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                />
              </Col>
              <Col xs="4" className="qs-mt-md">
                {billDays} days
              </Col>
            </Row>
            <Row>
              <Col xs="8">
                <div className="qs-estimate-dialog-validation">
                  <AvField
                    type="text"
                    name={`billDate_validation`}
                    value="notrequired"
                    className="qs-tc-hidden"
                    validate={{
                      hidden: (value, ctx, input, cb) => {
                        if (
                          selectedBillStartDate &&
                          selectedBillEndDate &&
                          selectedBillStartDate > selectedBillEndDate
                        ) {
                          cb('Bill start date must be less than or equal to the bill end date');
                        } else if (!selectedBillStartDate && !selectedBillStartDate) {
                          cb('Bill start and end date are required');
                        } else if (!selectedBillStartDate) {
                          cb('Bill start date is required');
                        } else if (!selectedBillEndDate) {
                          cb('Bill end date is required');
                        } else if (selectedBillStartDate && selectedBillStartDate < minBillDate) {
                          cb('Bill start date is too far in the past');
                        } else if (
                          selectedBillStartDate &&
                          (selectedBillStartDate < minBillDate || selectedBillStartDate > maxBillDate)
                        ) {
                          cb(
                            'Bill end date can not be in the future, adjust it back but ensure you have the same number of supply days.'
                          );
                        } else if (selectedBillEndDate && selectedBillEndDate < minBillDate) {
                          cb('Bill end date is too far in the past');
                        } else if (
                          selectedBillEndDate &&
                          (selectedBillEndDate < minBillDate || selectedBillEndDate > maxBillDate)
                        ) {
                          cb(
                            'Bill end date can not be in the future, adjust it back but ensure you have the same number of supply days.'
                          );
                        } else {
                          cb(true);
                        }
                      }
                    }}
                  />
                </div>
              </Col>
              <Col xs="4">
                {billDateStandarisedWarning && (
                  <div className="text-danger form-group">
                    <div className="is-invalid"></div>
                    <div className="invalid-feedback">
                      Please check the supply period is the same as your bill and adjust the dates if you need to
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className={rowClass}>
              <Col className={fieldClass} lg={columnSizeLg}>
                Usage ({serviceType.Unit})
                <i
                  id="energy-usage-guide"
                  className="material-icons qs-info animated heartBeat delay-1s"
                  onClick={() => onEnergyUsageGuide(!usageEntryGuideOpen)}
                >
                  info
                </i>
                <Popover
                  placement="auto"
                  modifiers={{ flip: { behavior: ['auto'] } }}
                  isOpen={usageEntryGuideOpen}
                  target="energy-usage-guide"
                  onClick={() => onEnergyUsageGuide(false)}
                >
                  <PopoverHeader>
                    {billType} usage entry guide <i className="material-icons qs-close">clear</i>
                  </PopoverHeader>
                  <PopoverBody>
                    {implementationCode === implementationCodes.ELECTRICITY.AU_DEFAULT ? (
                      <>
                        <p>
                          Please enter at least one row for all the different {serviceType.Unit} usage types on your
                          bill. Please do this even when the usage value on you bill is 0 {serviceType.Unit}. These
                          usage types are used to determine your tariff structure. If you don't include each usage type
                          we may return plans that are incorrect for the meter installed at your address.
                        </p>
                        <p>
                          <b>What is controlled load?</b>
                        </p>
                        <p>
                          Controlled load is electricity supplied to specific appliances, such as electric hot water
                          systems or slab or underfloor heating, which are often separately metered. A controlled load
                          tariff is generally a lower rate as these appliances operate during off-peak hours (usually
                          overnight).
                        </p>
                        <p>
                          If you have controlled load, it might also appear on your bill as 'dedicated circuit'. In
                          Queensland it may be called 'Tariff 31' (choose controlled Load 2) or 'Tariff 33' (choose
                          controlled Load 1), and in Tasmania it may be called 'Tariff 61' (choose controlled Load 2) or
                          'Tariff 63' (choose controlled Load 2).
                        </p>
                      </>
                    ) : (
                      <>
                        <p>Please enter all {serviceType.Unit} usage from your bill.</p>
                        <p>
                          As some retailers charge different rates (peak / off-peak) during seasonal periods of the year
                          we will use your billing period to determine an approximate percentage of use for peak and
                          off-peak.
                        </p>
                      </>
                    )}
                  </PopoverBody>
                </Popover>
              </Col>

              {selectedUsage.map((u, index) => (
                <>
                  {implementationCode === implementationCodes.ELECTRICITY.AU_DEFAULT && (
                    <Col xs="6">
                      <AvField
                        bsSize="sm"
                        type="select"
                        key={`energyUsageType-${index}`}
                        name={`energyUsageType-${index}`}
                        onChange={e => onChangeEnergyUsageType(e.target.value, index)}
                        value={u.energyUsageType}
                        validate={{
                          hidden: (value, ctx, input, cb) => {
                            if (implementationEnergyUsageTypes.findIndex(iUt => iUt.Id === u.energyUsageType) === -1) {
                              cb('Please select a valid usage type.');
                            } else cb(true);
                          }
                        }}
                      >
                        {implementationEnergyUsageTypes.map(u => (
                          <option key={`eut-${u.Id}`} value={u.Id}>
                            {u.Name}
                          </option>
                        ))}
                      </AvField>
                    </Col>
                  )}
                  <Col xs="4">
                    <AvField
                      bsSize="sm"
                      type="text"
                      key={`usage_${index}`}
                      name={`usage_${index}`}
                      placeholder={serviceType.Unit}
                      value={u.usage === null ? '' : u.usage.toString()}
                      onChange={e => onChangeUsageValue(e.target.value, index)}
                      validate={{
                        pattern: {
                          value: '^-?[0-9]{0,5}(\\.[0-9]{1,4})?$',
                          errorMessage: `Please enter a valid ${serviceType.Unit} usage total from your bill`
                        },
                        required: {
                          value: true,
                          errorMessage: `Please enter the ${serviceType.Unit} usage total from your bill`
                        }
                      }}
                    />
                  </Col>
                  <Col xs={implementationCode === implementationCodes.ELECTRICITY.AU_DEFAULT ? '2' : '8'}>
                    {index > 0 && (
                      <Button className="link-button qs-mt-sm" color="link" onClick={e => onDeleteUsage(index)}>
                        Remove
                      </Button>
                    )}
                  </Col>
                </>
              ))}
              <Col xs="12">
                <Button className="link-button qs-mb-md qs-mt-nmd" color="link" onClick={onAddUsage}>
                  Add another row
                </Button>
              </Col>
            </Row>

            {showDemand && (
              <Row className={rowClass}>
                <Col className={fieldClass} lg={columnSizeLg}>
                  Does your bill have demand charges?
                </Col>
                <Col xs="12" md="6">
                  <QsButtonGroup
                    required={true}
                    id="hasDemand"
                    fieldName="Has Demand"
                    additionalClass="sm-button-group"
                    selectedValue={selectedHasDemand.toString()}
                    onTouchButton={e => onHasDemandChange(e)}
                    buttons={[
                      {
                        label: 'No',
                        value: 'false'
                      },
                      {
                        label: 'Yes',
                        value: 'true'
                      }
                    ]}
                  />
                </Col>
                <Col xs="12" className="qs-estimate-dialog-validation">
                  <AvField
                    type="text"
                    name={`hasDemand_validation`}
                    value={selectedHasDemand.toString()}
                    className="qs-tc-hidden"
                    validate={{
                      hidden: (value, ctx, input, cb) => {
                        if (selectedHasDemand) {
                          cb('Bill compare is unavailable for bills that include demand charges at this time');
                        } else cb(true);
                      }
                    }}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        {/* --- PROPERTY TYPE ----------------------------------------------------- */}
        {!movingHouse && (
          <Row className={rowClass}>
            <Col className={fieldClass} lg={columnSizeLg}>
              Property type
            </Col>
            <Col xs="12">
              <QsButtonGroup
                required
                id="occupancyPurpose"
                fieldName="Occupancy Purpose"
                additionalClass="sm-button-group"
                selectedValue={selectedOccupancyPurpose.toString()}
                onTouchButton={setOccupancyPurposeButtonGroup}
                buttons={[
                  {
                    label: 'Residential',
                    value: occupancyPurposes.RESIDENTIAL.Id.toString(),
                    default: true
                  },
                  {
                    label: 'Business',
                    value: occupancyPurposes.BUSINESS.Id.toString(),
                    default: false
                  }
                ]}
              />
            </Col>
          </Row>
        )}
        {/* --- SOLAR ------------------------------------------------------------- */}
        {showSolar && (
          <>
            <Row className={rowClass}>
              <Col className={fieldClass} lg={columnSizeLg}>
                {selectedOccupancyType === occupancyTypes.CURRENT.Id
                  ? 'Do you have solar?'
                  : 'Does this new connection have solar?'}
              </Col>
              <Col xs="12">
                <QsButtonGroup
                  required={true}
                  id="solarType"
                  fieldName="Solar"
                  additionalClass="sm-button-group"
                  selectedValue={selectedSolarType.toString()}
                  onTouchButton={setSolarTypeButtonGroup}
                  buttons={[
                    {
                      label: 'None',
                      value: solarTypes.NONE.Id.toString()
                    },
                    {
                      label: 'Standard',
                      value: solarTypes.STANDARD.Id.toString()
                    },
                    {
                      label: 'Premium',
                      value: solarTypes.PREMIUM.Id.toString()
                    }
                  ]}
                />
              </Col>
            </Row>
          </>
        )}
        {/* --- INTERNET ---------------------------------------------------------- */}
        {movingHouse && hasInternet && (
          <>
            <Row>
              <Col className={fieldClass} lg={columnSizeLg}>
                Preferred broadband plans
              </Col>
              <Col xs="12" lg="6">
                <AvField
                  bsSize="sm"
                  type="select"
                  name="downloadSpeed"
                  placeholder=""
                  value={selectedDownloadSpeed}
                  onChange={e => setSelectedDownloadSpeed(e.target.value)}
                >
                  {internetDownloadSpeeds.map((ref, i) => (
                    <option key={`downloadSpeed-${ref.id}`} value={ref.id.toString()}>
                      {ref.description}
                    </option>
                  ))}
                </AvField>
              </Col>
              <Col xs="12" lg="6">
                <QsCheckBox
                  isChecked={selectedDataUnlimited}
                  onCheck={() => setSelectedDataUnlimited(!selectedDataUnlimited)}
                  label="Unlimited data plans only"
                />
              </Col>
            </Row>
            {!addressStatus.isAvailable && !addressStatusLoading && hasSelectedAddress && (
              <Row>
                <Col className={fieldClass} xs="12">
                  Connection type
                </Col>
                <Col xs="12" lg="6">
                  <AvField
                    bsSize="sm"
                    type="select"
                    name="connectionType"
                    placeholder=""
                    value={selectedConnectionType}
                    onChange={e => setSelectedConnectionType(parseInt(e.target.value))}
                  >
                    {internetConnectionTypes.map((ref, i) => (
                      <option key={`connectionType-${ref.id}`} value={ref.id.toString()}>
                        {ref.description}
                      </option>
                    ))}
                  </AvField>
                </Col>
              </Row>
            )}
          </>
        )}
        {/* --- EMAIL ADDRESS / AGENT --------------------------------------------- */}
        <Row className={rowClass}>
          <Col className={fieldClass} lg={columnSizeLg}>
            Email address {showAgents && '/ Agent'}
          </Col>
          <Col xs="12">
            <SubmitCommon
              hasSubmitted={hasSubmitted}
              onLoad={onSubmissionCommonLoad}
              onChange={onSubmissionCommonChange}
              refresh={reset}
              showSendComparisonEmail={true}
              showSendArticlesEmail={true}
              showSendSolarInstallationEmail={true}
              serviceType={serviceType}
              forceRequireEmailAddress={selectedOccupancyDateUncertain}
              forceRequireSendEmail={selectedOccupancyDateUncertain}
            >
              {!movingHouse && (
                <ServicesAuto
                  selectedAddress={selectedAddress}
                  serviceType={serviceType}
                  supportedServices={[...getSupportedServices(), billTypes.HOME_LOAN]}
                  isLoaded={distributorSetsLoaded}
                />
              )}
            </SubmitCommon>
          </Col>
        </Row>
        <div className="sm-modal-footer">
          {hasChanges && (
            <Button onClick={() => initialize(true)} className="sm-button-secondary">
              Reset
            </Button>
          )}
          {showBackButton && (
            <Button onClick={onBackButtonClick} className="sm-button-secondary">
              Go back
            </Button>
          )}
          {showCancelButton && (
            <Button onClick={onCancel} className=" sm-button-secondary">
              Cancel
            </Button>
          )}
          {showCloseButton && (
            <Button onClick={onClose} className="sm-button-secondary">
              {closeButtonText}
            </Button>
          )}
          <Button className="sm-button-primary right-arrow-small wide" disabled={!confirmButtonEnabled}>
            {operatorMode === operatorModes.DEFAULT ? 'Send Email' : confirmButtonText}
          </Button>
          <ExitLaunch
            launchMode={launchMode}
            serviceType={movingHouse ? billTypes.NONE : serviceType}
            source={movingHouse ? 'moving-house-dialog' : 'energy-estimate-dialog'}
          />

          {showPoweredBy && <PoweredBySmartMe />}
        </div>
      </AvForm>
    </>
  );
};

export default EnergyEstimateAu;
