import React from 'react';
import { useSelector } from 'react-redux';
import { AvField } from 'availity-reactstrap-validation';
import { anonymousModes } from '../../enums/authenticationTypes';

const EmailAddress = ({
  disabled,
  forceRequireEmailAddress,
  value,
  onChange,
  onValidate,
  placeholder,
  validateEmailAddress
}) => {
  const { anonymousMode } = useSelector(state => state.config.settings);

  const validate = validateEmailAddress === false ? false : true;

  return (
    <AvField
      bsSize="sm"
      name="emailAddress"
      className="sm-email-address"
      placeholder={
        placeholder ||
        `Email address${!forceRequireEmailAddress && anonymousMode === anonymousModes.OPTIONAL ? ' (optional)' : ''}`
      }
      disabled={disabled}
      value={value || ''}
      onChange={e => {
        onChange(e.target.value);
      }}
      validate={{
        hidden: (value, ctx, input, cb) => {
          let isValid = false;

          const hasEmailAddress = value && value.length > 0;

          if (hasEmailAddress) {
            const regex = new RegExp(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

            isValid = regex.test(value);
          } else {
            isValid = !forceRequireEmailAddress && anonymousMode === anonymousModes.OPTIONAL; // Empty email address is valid only if anonymous mode is OPTIONAL
          }

          if (validate && !isValid) {
            cb('Please enter a valid email address');
          } else {
            cb(true);
          }

          if (onValidate) {
            onValidate(isValid && hasEmailAddress);
          }
        }
      }}
    />
  );
};

export default EmailAddress;
