import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { launchDialogModes } from '../../enums/launchTypes.js';
import { uploadBillTypes, uploadSubTypes } from '../../enums/uploadBillTypes.js';
import { occupancyTypes } from '../../enums/submissionTypes.js';

import DirectSwitchErrorDialog from '../dialogs/directSwitchErrorDialog.js';
import DirectSwitchAu from '../dialogs/directSwitchAu.js';
import GeneralDialog from '../dialogs/generalDialog.js';
import SubmitBillDialogUx2 from '../dialogs/submitBillDialogUx2.js';
import SwitchedDialogContent from '../dialogs/switchedDialogContent';

import { closeDialogs, directSwitchDialogOptions, switchedDialogOptions } from '../../actions/dialogOptionsActions.js';
import { setServerMessageClear } from '../../actions/serverMessageActions.js';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import { billTypes } from '../../enums/billTypes.js';

const DialogManager = () => {
  const dispatch = useDispatch();

  const [selectedServiceType, setSelectedServiceType] = useState();
  const [selectedUploadBillType, setSelectedUploadBillType] = useState();
  const [selectedUploadSubType, setSelectedUploadSubType] = useState();
  const [selectedSubmissionId, setSelectedSubmissionId] = useState();
  const [selectedSubmission, setSelectedSubmission] = useState();
  const [showBackButton, setShowBackButton] = useState();
  const [confirmButtonText, setConfirmButtonText] = useState();

  // --------------------------------------------------------------------------
  // Dialog state
  // --------------------------------------------------------------------------
  const {
    isOpen: submitDialogOpen,
    serviceType: submitDialogServiceType,
    uploadBillType: submitDialogUploadType,
    uploadSubType: submitDialogUploadSubType,
    data: dialogData
  } = useSelector(state => state.dialogOptions.submit);

  const { isOpen: directSwitchDialogOpen } = useSelector(state => state.dialogOptions.directSwitch);
  const { isOpen: switchedDialogOpen, serviceType: switchedServiceType } = useSelector(
    state => state.dialogOptions.switched
  );
  const { showPoweredBy } = useSelector(state => state.config.settings);

  const { directSwitch, serverMessage } = useSelector(state => state);
  const { launchDialogMode } = useSelector(state => state.landing.home);
  const [submitOpen, setSubmitOpen] = useState(false);
  const [directSwitchOpen, setDirectSwitchOpen] = useState(false);
  const [switchedOpen, setSwitchedOpen] = useState(false);

  const launchMode = launchDialogMode || launchDialogModes.DEFAULT;

  // --------------------------------------------------------------------------
  // Open dialog
  // --------------------------------------------------------------------------
  const openDialog = (
    serviceType,
    uploadBillType,
    uploadSubType,
    submissionId,
    submission,
    backButtonVisible,
    buttonText
  ) => {
    setSelectedServiceType(serviceType);
    setSelectedUploadBillType(uploadBillType);
    setSelectedUploadSubType(uploadSubType);
    setSelectedSubmissionId(submissionId);
    setSelectedSubmission(submission);
    setShowBackButton(backButtonVisible);
    setConfirmButtonText(buttonText);
    setSubmitOpen(true);

    dispatch(setServerMessageClear(serverMessageTargets.SUBMIT_BILL, serverMessage));
    dispatch(setServerMessageClear(serverMessageTargets.ESTIMATE_DIALOG, serverMessage));
    dispatch(closeDialogs('dialogManager:openDialog')); // Ensure dialogs don't auto-open on browser back/forward navigation
  };

  // --------------------------------------------------------------------------
  // Close dialog
  // --------------------------------------------------------------------------
  const closeDialog = () => {
    setSelectedServiceType(null);
    setSelectedUploadBillType(null);
    setSelectedUploadSubType(null);
    setSelectedSubmissionId(null);
    setSelectedSubmission(null);
    setShowBackButton(null);
    setSubmitOpen(false);
  };

  // --------------------------------------------------------------------------
  // Open submit dialog
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (submitDialogOpen) {
      const serviceType = submitDialogServiceType;
      const submissionId = dialogData ? dialogData.submissionId : null;
      const submission = dialogData ? dialogData.submission : null;
      const backButtonVisible = dialogData ? dialogData.backButtonVisible : false;
      const confirmButtonText = dialogData ? dialogData.buttonText : 'Next';
      const uploadBillType =
        dialogData && submission && submission.occupancyType === occupancyTypes.NEW.Id
          ? uploadBillTypes.MANUAL
          : submitDialogUploadType;

      const uploadSubType = submitDialogUploadSubType;

      openDialog(
        serviceType,
        uploadBillType,
        uploadSubType,
        submissionId,
        submission,
        backButtonVisible,
        confirmButtonText
      );
    }
  }, [submitDialogOpen]);

  // --------------------------------------------------------------------------
  // Open direct switch dialog
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (directSwitchDialogOpen) {
      setDirectSwitchOpen(true);
    }
  }, [directSwitchDialogOpen]);

  // --------------------------------------------------------------------------
  // Open switched dialog
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (switchedDialogOpen) {
      setSwitchedOpen(true);
    }
  }, [switchedDialogOpen]);

  return (
    <>
      <DirectSwitchErrorDialog />

      {directSwitchOpen && (
        <GeneralDialog
          key="directSwitchDialog"
          heading={`Compare ${directSwitch.serviceType.Name} Plans`}
          showCloseIcon={false}
          showFooter={false}
          serviceType={directSwitch.serviceType}
          dialogOpen={directSwitchOpen}
          onClose={() => {
            setDirectSwitchOpen(false);
            dispatch(directSwitchDialogOptions(false, uploadSubTypes.NONE));
          }}
          launchMode={launchMode}
        >
          <DirectSwitchAu launchMode={launchMode} onClose={() => setDirectSwitchOpen(false)} />
        </GeneralDialog>
      )}

      {switchedOpen && (
        <GeneralDialog
          key="switchedDialog"
          dialogSize="md"
          closeButtonText="Close"
          confirmButtonText={null}
          bodyClass="no-padding"
          confirmButtonClass="sm-button-primary"
          dialogOpen={switchedOpen}
          onClose={() => {
            setSwitchedOpen(false);
            dispatch(switchedDialogOptions(false, billTypes.NONE));
          }}
          showPoweredBy={showPoweredBy}
        >
          <SwitchedDialogContent serviceType={switchedServiceType} getOtherServices={null} />
        </GeneralDialog>
      )}

      {submitOpen && selectedServiceType && (
        <SubmitBillDialogUx2
          enabled={true}
          showBackButton={showBackButton}
          confirmButtonText={confirmButtonText}
          serviceType={selectedServiceType}
          uploadBillType={selectedUploadBillType}
          uploadSubType={selectedUploadSubType}
          launchMode={launchDialogMode}
          submissionId={selectedSubmissionId}
          submission={selectedSubmission}
          dialogOpen={submitOpen}
          onDialogClosing={closeDialog}
        />
      )}
    </>
  );
};

export default DialogManager;
