import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AvForm } from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';

import ServerMessage from '../common/serverMessage';
import EmailAddress from '../common/emailAddress';
import { sendSolarInstallationEmail } from '../../actions/partnerActions';
import { displayInfoMessage } from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';

const SolarEmailSelector = ({ emailAddress, onEmailAddressChanged }) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { serverMessage, selectedAgent } = useSelector(state => state);

  const [hasValidEmailAddress, setHasValidEmailAddress] = useState(false);

  const target = serverMessageTargets.ESTIMATE_WIZARD_SOLAR_EMAIL;
  const hasServerMessage = serverMessage && serverMessage.code !== null && serverMessage.target === target;

  const handleSubmit = () => {
    if (formRef.current && hasValidEmailAddress) {
      formRef.current.submit();
    }
  };

  const onSendSolarEmail = () => {
    const onComplete = () => {
      dispatch(displayInfoMessage(target, 'Thank you. Please check your email inbox.'));
    };

    const onError = () => {};

    dispatch(sendSolarInstallationEmail({ emailAddress, selectedAgent }, onComplete, onError));
  };

  return (
    <div className="solar-email-selector">
      <div className="sub-heading">Enter your email address to receive information about installers in your area.</div>
      {hasServerMessage ? (
        <ServerMessage serverMessage={serverMessage} target={target} />
      ) : (
        <div>
          <div className="email-address">
            <AvForm onValidSubmit={onSendSolarEmail} ref={formRef}>
              <EmailAddress
                placeholder="Enter email address here"
                value={emailAddress}
                validateEmailAddress={false}
                forceRequireEmailAddress={true}
                onChange={value => onEmailAddressChanged(value)}
                onValidate={isValid => setHasValidEmailAddress(isValid)}
              />
            </AvForm>
            <div className="sm-estimate-wizard-button-container">
              <Button
                className="sm-button-primary go-button static medium"
                disabled={!hasValidEmailAddress}
                onClick={hasValidEmailAddress ? handleSubmit : undefined}
              >
                Send email...
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SolarEmailSelector;
