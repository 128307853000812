import * as types from './actions';
import axios from 'axios';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import { setServerMessage, setServerMessageClear, generalServerMessage } from './serverMessageActions';
import { goHome } from './comparisonActions';
import * as serverMessageTargets from './serverMessageTargets';
import { push } from 'connected-react-router';
import timeAxios from '../utilities/timeAxios';
import { apiTiming } from '../utilities/googleAnalyticsEvents';

export function setRegistrationSuccess(registration) {
  return { type: types.SET_REGISTRATION, registration };
}

export function resetRegistration() {
  return {
    type: types.SET_REGISTRATION,
    registration: {
      initComplete: false,
      registrationLinkError: false,
      emailAddress: null,
      autoCheckEmailAddress: null,
      mobilePhoneNumber: null
    }
  };
}

export function initRegistration(emailAddress, mobilePhoneNumber, onComplete, onError) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'registration', 'initRegistration');
    });

    axios
      .post(`/api/registration/init`, {
        mobilePhoneNumber,
        emailAddress
      })
      .then(response => {
        dispatch(
          setRegistrationSuccess({
            initComplete: true,
            registrationLinkError: false
          })
        );
        dispatch(
          setServerMessage({
            code: response.status,
            messages: [{ field: '', message: 'Check your email. You will shortly receive your registration link.' }],
            scrollTo: false,
            target: serverMessageTargets.REGISTRATION_INIT
          })
        );

        if (onComplete) {
          onComplete();
        }
      })
      .catch(error => {
        dispatch(
          setServerMessage({ ...error.response.data, scrollTo: true, target: serverMessageTargets.REGISTRATION_INIT })
        );

        if (onError) {
          onError();
        }
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function completeRegistration(registration) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'registration', 'completeRegistration');
    });

    axios
      .get(`/api/registration/complete/${registration.id}?key=${registration.key}`)
      .then(response => {
        if (response.data) {
          var data = response.data;

          dispatch(
            setRegistrationSuccess({
              ...data,
              registrationLinkError: false,
              registrationId: registration.id,
              registrationKey: registration.key
            })
          );
          dispatch(push('/registration'));
        } else {
          dispatch(goHome());
        }
      })
      .catch(error => {
        dispatch(
          setRegistrationSuccess({
            registrationLinkError: true
          })
        );
        dispatch(generalServerMessage(error.response, serverMessageTargets.REGISTRATION_LINK_ERROR, true));
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}
