import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { Carousel, CarouselItem, Button, Row, Col, Container } from 'reactstrap';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { billTypes } from '../../enums/billTypes';
import { submissionTypes } from '../../enums/submissionTypes';
import { buttonDirection } from '../../enums/buttonTypes';

import ServiceCardSample from '../../components/samples/serviceCardSample';
import OfferMarketing from '../../components/common/offerMarketing';
import EnergyBill from '../dialogs/energyBill';
import GeneralDialogHeader from '../dialogs/generalDialogHeader';
import GeneralDialogCloseIcon from '../dialogs/generalDialogCloseIcon';
import ServiceCardStack from '../samples/serviceCardStack';

const HowItWorks = ({ onClose }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const carouselAnimationClasses = 'animated delay-2s pulse slow repeat-10';

  const [nextCarouselAnimateClass, setNextCarouselAnimateClass] = useState(carouselAnimationClasses);

  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { logo } = useSelector(state => state.content.images);
  const { panelProviders, billTypes: agentBillTypes } = useSelector(state => state.config);
  const { organiserAgentName } = useSelector(state => state.content.general);

  const hasInsurance = billTypes.hasServiceType(agentBillTypes, [
    billTypes.VECHICLE_INSURANCE,
    billTypes.HOME_AND_CONTENTS_INSURANCE,
    billTypes.HEALTH_INSURANCE
  ]);

  const hasInternet = billTypes.hasServiceType(agentBillTypes, billTypes.INTERNET);
  const hasHomeLoan = billTypes.hasServiceType(agentBillTypes, billTypes.HOME_LOAN);

  const getRetailerPanel = () => {
    let providers = [];

    if (hasInternet) {
      const internetProviders = [
        { code: 'Tangerine', logoPath: '/images/providers/tangerine.svg' },
        { code: 'Goodtel', logoPath: '/images/providers/goodtel.svg' },
        { code: 'Optus', logoPath: '/images/providers/Optus.png' },
        { code: 'Dodo', logoPath: '/images/providers/Dodo.png' },
        { code: 'Belong', logoPath: '/images/providers/Belong.png' },
        { code: 'TPG', logoPath: '/images/providers/TPG.png' }
      ];

      providers = [...providers, ...internetProviders];
    }

    if (hasHomeLoan) {
      const financialProviders = [...panelProviders]
        .filter(p => p.provider.billTypes.filter(bt => bt.id === billTypes.HOME_LOAN.Id).length > 0)
        .sort((a, b) => {
          const s1 = a.provider.billTypes[0].sortOrder ? a.provider.billTypes[0].sortOrder : Number.MAX_SAFE_INTEGER;
          const s2 = b.provider.billTypes[0].sortOrder ? b.provider.billTypes[0].sortOrder : Number.MAX_SAFE_INTEGER;

          return s1 - s2;
        })
        .slice(0, 6)
        .map(p => {
          return { code: p.provider.displayCode, logoPath: `/images/providers/${p.provider.logo}` };
        });

      providers = [...providers, ...financialProviders];
    }

    const energyProviders = [...panelProviders]
      .filter(
        p =>
          p.provider.billTypes.filter(bt => bt.id === billTypes.ELECTRICITY.Id || bt.id === billTypes.GAS.Id).length > 0
      )
      .sort((a, b) => {
        const s1 = a.provider.billTypes[0].sortOrder ? a.provider.billTypes[0].sortOrder : Number.MAX_SAFE_INTEGER;
        const s2 = b.provider.billTypes[0].sortOrder ? b.provider.billTypes[0].sortOrder : Number.MAX_SAFE_INTEGER;

        return s1 - s2;
      })
      .slice(0, 18 - providers.length)
      .map(p => {
        return { code: p.provider.displayCode, logoPath: `/images/providers/${p.provider.logo}` };
      });

    providers = [...providers, ...energyProviders];

    return (
      <Container className="retailer-panel">
        <Row>
          {providers.map(a => {
            return (
              <Col xs="4" key={a.code}>
                <img src={`${staticContentUrl}/${a.logoPath}`} alt={a.code} />
              </Col>
            );
          })}
        </Row>
      </Container>
    );
  };

  const getDiscountPanel = () => {
    const panelDiscounters = [
      { code: 'disney-plus', name: 'Disney+', logo: 'electricity100.svg' },
      { code: 'netflix', name: 'Netflix', logo: 'gas100.svg' },
      { code: 'delonghi', name: 'DeLonghi', logo: 'home-insurance100.svg' },
      { code: 'bose', name: 'Bose', logo: 'electricity500.svg' },
      { code: 'apple', name: 'Apple', logo: 'internet500.svg' },
      { code: 'samsung', name: 'Samsung', logo: 'home-insurance1000.svg' },
      { code: 'afl', name: 'AFL', logo: 'home-loans100.svg' },
      { code: 'flight-centre', name: 'Fligh Centre', logo: 'home-loans1000.svg' },
      { code: 'kayo', name: 'Kayo', logo: 'vehicle-insurance100.svg' },
      { code: 'asics', name: 'Asics', logo: 'health100.svg' },
      { code: 'endota', name: 'Endota', logo: 'health500.svg' },
      { code: 'movie-world', name: 'Movie World', logo: 'health1000.svg' }
    ];

    return (
      <Container className={`discounter-panel${isMobile ? ' mobile' : ''}`}>
        <Row>
          {panelDiscounters.map(a => {
            return (
              <Col xs="4" key={a.code}>
                <img src={`${staticContentUrl}/images/lifestyle/logo/${a.logo}`} alt={a.name} />
              </Col>
            );
          })}
        </Row>
      </Container>
    );
  };

  const getUserBillSample = () => {
    const userBill = {
      reductionAmountAnnual: 218,
      submissionType: submissionTypes.BILL,
      bill: {
        billDays: 94,
        totalUsageDisplay: '961.11 kWh',
        usageDisplay: [
          { description: 'General usage - 244.648kWh @ 59.290c' },
          { description: 'Off-Peak - 216.898kWh @ 16.445c' },
          { description: 'Shoulder - 499.568kWh @ 25.245c' }
        ],
        supplyDisplay: [{ description: 'Supply - 94 days @ 105.600c' }],
        totalChargesDiscountedDisplay: '$350.88',
        billType: billTypes.ELECTRICITY.Code,
        provider: {
          logo: 'AGL.png',
          displayCode: 'AGL'
        }
      },
      bestOffer: {
        savingAmountGuaranteed: 56.14,
        savingAmountGuaranteedDisplay: '$56.14',
        savingAmountGuaranteedReverseDisplay: '-$56.14',
        billAmountGuaranteedDisplay: '$294.74',
        percentageBillReductionAmountGuaranteedDisplayShort: '16%'
      }
    };

    return (
      <div className="energy-bill-sample">
        <div className="energy-bill-content">
          <div className="header">
            <GeneralDialogHeader serviceType={billTypes.ELECTRICITY} showCloseIcon={false} />
          </div>
          {!isMobile && <GeneralDialogCloseIcon disabled />}
          <EnergyBill isSample={true} serviceType={billTypes.ELECTRICITY} userBill={userBill} />
        </div>
      </div>
    );
  };

  const getPayBillSample = () => {
    return (
      <div className="pay-bill-sample">
        <img
          className="pay-bill-content"
          src={`${staticContentUrl}/images/help/how-it-works10-empty.png`}
          alt="Pay bill sample"
        />
        <div className="message message1">
          Hi Claire, your EcoTel bill has arrived. To pay the full bill amount of $127.54, simply reply YES or click
          here for details.
        </div>
        <div className="message message2">Yes</div>
        <div className="message message3">Great! Your payment has been processed</div>
        <div className="message message4">Thanks</div>
      </div>
    );
  };

  const useLiveComponent = true;

  const slide2Description = `Get rewarded each time you secure a great deal for your ${billTypes.getServicesText(
    agentBillTypes
  )} bills.`;

  const slide3Description = `Start by telling your ${organiserAgentName} about your current bills. If you have an e-bill${
    hasInsurance ? ' or insurance policy' : ''
  }, simply upload it and you're ready to go.`;

  const items = [
    {
      index: 0,
      heading: 'Remove bill pain for good',
      description: `${organiserAgentName} helps you take permanent control of your household bills.`,
      image: `${staticContentUrl}/images/help/how-it-works01.jpg`,
      showLogo: true
    },
    {
      index: 1,
      heading: 'We make bills easy',
      description: 'Manage your bills in one place and receive cheaper plan recommendations on applicable services.',
      image: `${staticContentUrl}/images/help/how-it-works02.jpg`,
      showLogo: true
    },
    {
      index: 2,
      heading: 'Earn when you save',
      description: slide2Description,
      image: `${staticContentUrl}/images/help/how-it-works03.jpg`,
      showLogo: true
    },
    {
      index: 3,
      heading: 'Getting setup is easy',
      description: slide3Description,
      image: `${staticContentUrl}/images/help/how-it-works04.png`,
      content: useLiveComponent ? <ServiceCardSample serviceType={billTypes.ELECTRICITY} isEmpty={true} /> : null,
      liveComponent: useLiveComponent
    },
    {
      index: 4,
      heading: 'Secure savings in minutes',
      description: `Applicable bills are instantly compared against the market to find great deals you can easily move to using ${organiserAgentName}.`,
      image: `${staticContentUrl}/images/help/how-it-works05.png`,
      content: useLiveComponent ? (
        <div className={`offer-marketing-sample${isMobile ? ' mobile' : ''}`}>
          <div className="offer-marketing-content">
            <OfferMarketing isSample={true} serviceType={billTypes.ELECTRICITY} />
          </div>
        </div>
      ) : null,
      liveComponent: useLiveComponent
    },
    {
      index: 5,
      heading: "It's super accurate",
      description: `Throw away the messy spreadsheets. ${organiserAgentName} analyses your expenses to calculate your possible savings.`,
      image: `${staticContentUrl}/images/help/how-it-works06.png`,
      content: useLiveComponent ? getUserBillSample() : null,
      liveComponent: useLiveComponent
    },
    {
      index: 6,
      heading: "You're organised",
      description: `Once you secure each cheaper deal, you can link your ${billTypes.getServicesText(
        agentBillTypes
      )} bills to ${organiserAgentName}.`,
      image: `${staticContentUrl}/images/help/how-it-works07.png`,
      content: useLiveComponent ? <ServiceCardSample serviceType={billTypes.ELECTRICITY} /> : null,
      liveComponent: useLiveComponent
    },
    {
      index: 7,
      heading: 'Staying organised is easy',
      description: 'Any linked bills will automatically receive a regular review to ensure you STAY on great rates.',
      image: `${staticContentUrl}/images/help/how-it-works08.png`,
      content: useLiveComponent ? <ServiceCardStack /> : null,
      liveComponent: useLiveComponent
    },
    {
      index: 8,
      heading: 'We monitor the markets',
      description: `${organiserAgentName} automatically checks your bills against the latest market rates on a regular basis.  We'll alert you when a cheaper deal is available, based on your bills energy usage and rates.`,
      content: getRetailerPanel()
    },
    // {
    //   index: 9,
    //   heading: 'Pay bills with ease',
    //   description: `${organiserAgentName} payment tools will soon enable you to pay your bills in seconds.`,
    //   image: `${staticContentUrl}/images/help/how-it-works10.png`,
    //   content: useLiveComponent ? getPayBillSample() : null
    // },
    // {
    //   index: 10,
    //   heading: 'Enjoy discount offers',
    //   description: `${organiserAgentName} will soon give you access to discounts on over a hundred other household expenses.`,
    //   content: getDiscountPanel()
    // },
    {
      index: 12,
      heading: "Let's get started",
      description: `Take a few minutes now to fill out each card in your ${organiserAgentName} and get <b>permanently</b> organised.`,
      image: `${staticContentUrl}/images/help/how-it-works13.jpg`,
      logoStyle: true
    }
  ];

  const agentServiceType = getAgentServiceType(billTypes.ELECTRICITY, agentBillTypes);
  const commissionType = agentServiceType.commissionType;
  const agentBillType = agentServiceType.agentBillType;

  if (agentServiceType.hasCommission) {
    const content = (
      <img
        className="customer-commission-content"
        src={`${staticContentUrl}${agentBillType.customerCommissionLogo}`}
        alt={commissionType.Name}
      />
    );

    items.push({
      index: 11,
      heading: 'Earn when you save',
      description: `And to top it all off, you can earn ${commissionType.NamePlural} every time you lock in a saving using your ${organiserAgentName}.`,
      image: `${staticContentUrl}/images/help/how-it-works12.png`,
      content: useLiveComponent ? content : null,
      liveComponent: useLiveComponent
    });
  }

  const onNext = () => {
    if (animating === true) return;

    const nextIndex = activeIndex === items.length - 1 ? activeIndex : activeIndex + 1;
    setIndex(nextIndex, buttonDirection.Next);
  };

  const onPrevious = () => {
    if (animating === true) return;

    const nextIndex = activeIndex === 0 ? 0 : activeIndex - 1;
    setIndex(nextIndex, buttonDirection.Previous);
  };

  const onCloseInternal = () => {
    standardEvents('How-It-Works-Dialog', 'Close', `slide-${activeIndex + 1}`, googleAnalyticsClientCode);

    onClose(true);
  };

  const setIndex = (index, direction) => {
    setActiveIndex(index);
    resetNextCarouselAnimation();
    standardEvents('How-It-Works-Dialog', direction, `slide-${index + 1}`, googleAnalyticsClientCode);
  };

  const resetNextCarouselAnimation = () => {
    setNextCarouselAnimateClass('');

    setTimeout(() => {
      setNextCarouselAnimateClass(carouselAnimationClasses);
    }, 500);
  };

  const slides = items
    .sort((a, b) => {
      return a.index - b.index;
    })
    .map((item, index) => {
      return (
        <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={`c-item-${index}`}>
          <div
            style={item.image && !item.content ? { backgroundImage: `url(${item.image})` } : {}}
            className={`content${item.showLogo || item.logoStyle ? ' with-logo' : ''}${
              item.liveComponent ? ' with-live-component' : ''
            }`}
          >
            {item.content}
          </div>

          {item.showLogo && (
            <div className="hiw-logo-wrapper">
              <div className="hiw-logo">
                <img src={`${staticContentUrl}${logo}`} alt="logo" />
              </div>
            </div>
          )}

          <div className="help-item">
            <div
              className="heading"
              dangerouslySetInnerHTML={{
                __html: item.heading
              }}
            />
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: item.description
              }}
            />
          </div>
        </CarouselItem>
      );
    });

  const finalSlide = activeIndex === items.length - 1;
  const next = finalSlide ? onCloseInternal : onNext;
  const previous = onPrevious;

  return (
    <>
      <div className="sm-how-it-works">
        <Carousel interval={false} activeIndex={activeIndex} next={next} previous={previous}>
          {slides}
        </Carousel>
        <div className="help-footer">
          <div>
            <Button
              disabled={animating}
              className={`sm-button-primary ${nextCarouselAnimateClass} right-arrow-small heavy`}
              onClick={next}
            >
              {finalSlide ? 'Get started' : 'Next'}
            </Button>
          </div>
          {activeIndex > 0 ? (
            <div>
              <Button disabled={animating} className="sm-link-button" onClick={previous}>
                Back
              </Button>
            </div>
          ) : (
            <div className="spacer" />
          )}
        </div>
      </div>
    </>
  );
};
export default HowItWorks;
