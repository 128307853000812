import React from 'react';
import { useSelector } from 'react-redux';

import {
  customerCommissionTypes,
  getAgentServiceType,
  getCommonCommissionType
} from '../../enums/customerCommissionTypes';
import { billTypes } from '../../enums/billTypes';
import CustomerCommissionInfo from './customerCommissionInfo';

const CustomerCommissionLogo = ({ serviceType, showInfo }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  let commissionType;
  let commissionLogo;

  if (serviceType === billTypes.NONE) {
    commissionType = getCommonCommissionType(agentBillTypes);
    commissionLogo = commissionType.Logo;
  } else {
    const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);

    commissionType = agentServiceType.commissionType;
    commissionLogo = agentServiceType.agentBillType.customerCommissionLogo;
  }

  if (commissionType === customerCommissionTypes.NONE) return null;

  return (
    <div className="commission-container">
      <div className={`customer-commission-logo${showInfo ? ' with-info' : ''}`}>
        <img src={`${staticContentUrl}${commissionLogo}`} alt={commissionType.Name} />
      </div>

      {showInfo && <CustomerCommissionInfo commissionType={commissionType} serviceType={serviceType} />}
    </div>
  );
};

export default CustomerCommissionLogo;
