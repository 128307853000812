import React from 'react';
import { AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import QsCheckBox from './qsCheckBox';
import { useSelector } from 'react-redux';
import { orientationTypes } from '../../enums/scaleTypes';
import Conditions from './conditions';
import { conditionTypes } from '../../enums/conditionTypes';
import { concessionTypesEnum } from '../../enums/concessionTypes';

const Concession = props => {
  const { onChange, acceptConcession, concessionDisclaimer, details, setConfirmDetail, offer } = props;
  const { concessionTypes } = useSelector(state => state.config.referenceData);

  const {
    hasConcession,
    concessionType,
    concessionTypeId,
    concessionTypeIdIssued,
    concessionTypeIdExpiry,
    concessionConsent
  } = details;

  const hasConcessionConditions =
    offer &&
    offer.productDetail &&
    offer.productDetail.consentConditions &&
    offer.productDetail.consentConditions.length > 0 &&
    offer.productDetail.consentConditions.filter(condition => condition.type === conditionTypes.CONCESSION).length > 0;

  const setAllConcessionConditionsChecked = newConcessionConsent => {
    onChange(null, { concessionConsent: newConcessionConsent });
  };

  const validateConcessionType = (value, ctx, input, cb) => {
    if (hasConcession && value === '') cb('Concession type is required.');
    else cb(true);
  };

  const validateConcessionTypeId = (value, ctx, input, cb) => {
    if (hasConcession) {
      if (value !== '') {
        if (value.length < 6) {
          cb('Concession type card is too short.');
        } else if (value.length > 20) {
          cb('Concession type card is too long.');
        } else {
          var concessionTypeObject = concessionTypesEnum.getById(parseInt(concessionType));

          if (concessionTypeObject && concessionTypeObject.Validation) {
            let regex = new RegExp(concessionTypeObject.Validation);
            if (!regex.test(value)) {
              cb(concessionTypeObject.ValidationError);
            } else cb(true);
          } else cb(true);
        }
      } else {
        cb('Concession type card number is required.');
      }
    } else {
      cb(true);
    }
  };

  const validateConcessionTypeIdIssued = (value, ctx, input, cb) => {
    if (hasConcession) {
      if (value !== '') {
        var regex, concessionIssuedDisplayFormat;
        if (concessionType === '1' || concessionType === '2') {
          regex = new RegExp(
            '^(?:(?:(?:0[1-9]|1\\d|2[0-8])\\/(?:0[1-9]|1[0-2])|(?:29|30)/(?:0[13-9]|1[0-2])|31\\/(?:0[13578]|1[02]))\\/[1-9]\\d{3}|29\\/02(?:\\/[1-9]\\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00))$'
          );
          concessionIssuedDisplayFormat = 'dd/mm/yyyy';
        } else {
          regex = new RegExp('^(0?[1-9]|1[0-2])/(([0-9]{2}))$');
          concessionIssuedDisplayFormat = 'mm/yy';
        }

        if (!regex.test(value)) {
          cb(`Concession type date issued should be ${concessionIssuedDisplayFormat}.`);
        } else cb(true);
      } else {
        cb('Concession type date issued is required.');
      }
    } else {
      cb(true);
    }
  };

  const validateConcessionTypeIdExpiry = (value, ctx, input, cb) => {
    if (hasConcession) {
      if (value !== '') {
        var regex, concessionDateDisplayFormat;
        if (concessionType === '1' || concessionType === '2') {
          regex = new RegExp(
            '^(?:(?:(?:0[1-9]|1\\d|2[0-8])\\/(?:0[1-9]|1[0-2])|(?:29|30)/(?:0[13-9]|1[0-2])|31\\/(?:0[13578]|1[02]))\\/[1-9]\\d{3}|29\\/02(?:\\/[1-9]\\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00))$'
          );
          concessionDateDisplayFormat = 'dd/mm/yyyy';
        } else {
          regex = new RegExp('^(0?[1-9]|1[0-2])/(([0-9]{2}))$');
          concessionDateDisplayFormat = 'mm/yy';
        }

        if (!regex.test(value)) {
          cb(`Concession type expiry should be ${concessionDateDisplayFormat}.`);
        } else cb(true);
      } else {
        cb('Concession type expiry is required.');
      }
    } else {
      cb(true);
    }
  };

  let concessionDateDisplayFormat;
  switch (concessionType) {
    case '1':
    case '2':
      concessionDateDisplayFormat = 'dd/mm/yyyy';
      break;
    default:
      concessionDateDisplayFormat = 'mm/yy';
  }

  return (
    <>
      <QsCheckBox
        isChecked={hasConcession}
        label="I have an eligible concession card."
        onCheck={e => onChange(e, { hasConcession: !hasConcession })}
      />

      {hasConcession && (
        <>
          {acceptConcession ? (
            <div>
              <Row className="qs-more-info-table-header">
                <Col xs="12">
                  Concession details
                  <div className="qs-application-note">The card must be in the name of the applicant above.</div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <FormGroup>
                    <AvField
                      bsSize="sm"
                      type="select"
                      name="concessionType"
                      onChange={e => onChange(e, { concessionType: e.target.value })}
                      value={concessionType}
                      validate={{ concessionType: validateConcessionType.bind(this) }}
                    >
                      <option value="">Select concession type method</option>
                      {concessionTypes.map((ref, i) => (
                        <option key={`concessiontype-${ref.id}`} value={ref.id}>
                          {ref.description}
                        </option>
                      ))}
                    </AvField>
                    <AvField
                      bsSize="sm"
                      placeholder="ID"
                      name="concessionTypeId"
                      type="text"
                      value={concessionTypeId}
                      onChange={e => onChange(e, { concessionTypeId: e.target.value })}
                      validate={{ validateConcessionTypeId }}
                    />
                    <AvField
                      bsSize="sm"
                      placeholder={`Issued ${concessionDateDisplayFormat}`}
                      name="concessionTypeIdIssued"
                      type="text"
                      value={concessionTypeIdIssued}
                      onChange={e => onChange(e, { concessionTypeIdIssued: e.target.value })}
                      validate={{ validateConcessionTypeIdIssued }}
                    />
                    <AvField
                      bsSize="sm"
                      placeholder={`Expires ${concessionDateDisplayFormat}`}
                      name="concessionTypeIdExpiry"
                      type="text"
                      value={concessionTypeIdExpiry}
                      onChange={e => onChange(e, { concessionTypeIdExpiry: e.target.value })}
                      validate={{ validateConcessionTypeIdExpiry }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="concession-disclaimer mb-3">
                <div
                  className="disclaimer"
                  dangerouslySetInnerHTML={{
                    __html: concessionDisclaimer
                  }}
                />

                {hasConcessionConditions ? (
                  <Conditions
                    offer={offer}
                    confirmDetail={details}
                    setConfirmDetail={setConfirmDetail}
                    type={conditionTypes.CONCESSION}
                    orientation={orientationTypes.top}
                    setFieldOnAllConditionsChecked="concessionConsent"
                  />
                ) : (
                  <QsCheckBox
                    isChecked={concessionConsent}
                    onCheck={e => onChange(e, { concessionConsent: !concessionConsent })}
                    label="Do you understand and agree to the concession terms & conditions?"
                    validatorName="concession-disclaimer-terms"
                    validationMessage={
                      hasConcession ? 'We need your consent to be able to validate your concession card details.' : null
                    }
                  />
                )}
              </div>
            </div>
          ) : (
            <div
              className="mb-3"
              dangerouslySetInnerHTML={{
                __html: concessionDisclaimer
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default Concession;
