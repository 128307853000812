import React from 'react';

import { getIcon } from '../../utilities/submissionUtilities';

const OptionItemSelector = ({ options, selectedOption, onOptionSelected, additionalClass, useAlternateName }) => {
  const OptionItem = ({ option, selectedOption, onOptionSelected, index, additionalClass, useAlternateName }) => {
    return (
      <div
        className={`selector-item${option === selectedOption ? ' selected' : ''} ${additionalClass}`}
        onClick={() => onOptionSelected(option)}
      >
        <div className="icon">{getIcon(option, index % 2 === 0)}</div>
        <div
          className="name"
          dangerouslySetInnerHTML={{
            __html: useAlternateName ? option.AlternateName || option.alternateName : option.Name || option.name
          }}
        />
      </div>
    );
  };

  const getOptionItem = (option, index, useAlternateName) => {
    return (
      <OptionItem
        additionalClass={additionalClass}
        index={index}
        key={`optionItem${option.Id || option.code}`}
        option={option}
        selectedOption={selectedOption}
        onOptionSelected={onOptionSelected}
        useAlternateName={useAlternateName}
      />
    );
  };

  return <div className="option-selector">{options.map((e, index) => getOptionItem(e, index, useAlternateName))}</div>;
};
export default OptionItemSelector;
