import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const Menu = ({ onRestart }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <div className="sm-chatbot-menu">
      <div className="sm-service-menu">
        <Dropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
          <DropdownToggle
            className="header-button menu-button"
            tag="span"
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
          >
            <span className="material-icons">more_vert</span>
          </DropdownToggle>
          <DropdownMenu className="menu-items">
            <DropdownItem onClick={onRestart} className="menu-item">
              <div>
                <span className="material-icons menu-icon">refresh</span>
              </div>
              <div className="menu-text">Restart</div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Menu;
