import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSessionActivity } from '../../utilities/commonUtilities';
import { exitExternal } from '../../actions/sessionActivityActions';

const ExitLink = ({ showIcon, url, label, source }) => {
  const { session } = useSelector(state => state);

  const dispatch = useDispatch();

  const onExit = e => {
    const sessionActivity = getSessionActivity(session);
    e.preventDefault();
    dispatch(exitExternal(url, { ...sessionActivity, data: { source } }));
  };

  return (
    <div className="sm-exit-link limit-width" onClick={onExit}>
      {showIcon === true && <div className="material-icons icon">exit_to_app</div>}
      <div>{label}</div>
    </div>
  );
};

export default ExitLink;
