import React from 'react';

const Insights = () => {
  const items = [
    {
      title: 'Why financing your solar project could make sense:',
      content:
        'With much improved ROI payback periods, consumers are now making commercial commitments in excess of $20,000 to not only purchase solar panels, but also batteries, inverters, pool heat pumps and even cars at the same time. '
    },
    {
      title: 'Why the banks are bundling solar into home loans:',
      content:
        'Home electrification (solar) is an investment in your most valuable asset – your home. Your home is not only likely to increase in value as a result of the installation, but actually differentiate your property to others in the event of a sale.'
    },
    {
      title: 'What is a SmartMe certified finance expert?',
      content:
        'We train Australia\'s largest and most trusted mortgage brokers and asset finance companies to become "SmartMe certified solar finance experts".  We survey our customers to make sure the experience you\'re receiving is valued.'
    }
  ];
  return (
    <div className="sm-start-insights">
      {items.map((item, i) => (
        <div className="item" key={`insight-${i}`}>
          <div className="title">{item.title}</div>
          <div className="content">{item.content}</div>
        </div>
      ))}
    </div>
  );
};
export default Insights;
