import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { scroller } from 'react-scroll';
import { Button, Container, Row, Col } from 'reactstrap';

import { goHome } from '../actions/comparisonActions';
import { setLanding } from '../actions/landingActions';
import { setServerMessage } from '../actions/serverMessageActions';

import { getAdWordConversionScript } from '../utilities/googleGtagUtilities';
import { taboolaConversionScript } from '../utilities/taboolaUtilities';
import { delay } from '../utilities/commonUtilities';
import { isSummaryOffersOnly } from '../utilities/internetUtilities';
import { userPreferenceTypes, getUserPreferences } from '../utilities/userPreferencesUtilities';
import { postFrameScrollTopMessage } from '../utilities/domUtilities';

import { implementationCodes } from '../enums/implementationCodes';
import { comparisonSorting } from '../enums/comparisonSorting';
import { billTypes } from '../enums/billTypes';
import { occupancyPurposes, submissionTypes } from '../enums/submissionTypes';
import { navigationTypes } from '../enums/navigationTypes';
import { uploadBillTypes, uploadSubTypes } from '../enums/uploadBillTypes';
import { directSwitchSessionStatus } from '../enums/directSwitchSessionStatus';
import { pageModes, launchDialogModes } from '../enums/launchTypes';

import '../../node_modules/rc-drawer/assets/index.css';

import { ReactComponent as PartyIcon } from '../components/icons/util/party.svg';
import { ReactComponent as CogIcon } from '../components/icons/util/service-card-cog.svg';

import Offers from '../components/common/offers';
import ShowFeatureCheckbox from '../components/common/showFeatureCheckbox';
import TourGuide from '../components/common/tourGuide';
import CompareDrawer from '../components/common/compareDrawer';
import OrganiserName from '../components/common/organiserName';
import ServiceIcon from '../components/serviceCard/serviceIcon';
import ServicesAuto from '../components/serviceCard/servicesAuto';
import FooterDisclaimers from '../components/disclaimers/footerDisclaimers';
import SubmitBillDialogUx2 from '../components/dialogs/submitBillDialogUx2';
import DirectSwitchErrorDialog from '../components/dialogs/directSwitchErrorDialog';
import SummaryFooter from '../components/EstimateWizard/summaryFooter';

const CompareScreen = () => {
  const dispatch = useDispatch();

  const { serverMessage, comparison, config, directSwitch, landing } = useSelector(state => state);
  const { pageMode, frameHost } = useSelector(state => state.application);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { isOpen: submitDialogOpen } = useSelector(state => state.dialogOptions.submit);
  const { tourGuideEnabled } = useSelector(state => state.config.settings);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { data: userBills } = useSelector(state => state.userBills);
  const { event: navigationEvent } = useSelector(state => state.globalOptions.navigation);
  const { postcodeInitial } = useSelector(state => state.framePartner);

  const submission = comparison.submission;
  const occupancyPurpose = occupancyPurposes.getById(submission.occupancyPurpose, occupancyPurposes.RESIDENTIAL);
  const serviceType = billTypes.getByCode(submission.billType);
  const serviceTypeName = serviceType ? serviceType.Name.toLowerCase() : null;
  const offerCount = comparison.offers.length;
  const hasSaving = offerCount > 0 && comparison.offers[0].savingAmountLowestAnnual > 0;
  const implementationCode = comparison.implementationCode;
  const usageLevelDisplay = submission.usageLevelDisplay;
  const recentBill = submission.recentBill;
  const homeSizeHeading =
    implementationCode === implementationCodes.ELECTRICITY.US_DEFAULT
      ? recentBill
        ? 'Your usage'
        : usageLevelDisplay
      : 'Average home';
  const homeSizeSentence =
    implementationCode === implementationCodes.ELECTRICITY.US_DEFAULT
      ? recentBill
        ? 'your usage'
        : `a ${usageLevelDisplay ? usageLevelDisplay.toLowerCase() : ''}`
      : 'the average home';

  const userBill = userBills.find(e => e.submissionId === comparison.submissionId);

  const displayAddress = userBill ? userBill.displayAddress : null;

  const showPriceNote = false;
  // This logic was to show the price note when there were no better offers and for bills issued prior to December 2022
  // to handle price rises
  //
  // (implementationCode === implementationCodes.ELECTRICITY.AU_DEFAULT ||
  //   implementationCode === implementationCodes.GAS.AU_DEFAULT) &&
  // comparison.submissionType === submissionTypes.BILL &&
  // moment(userBill.lastBillIssueDate) < moment('1 December 2022') &&
  // !hasSaving;

  const isDirectSwitch = directSwitch.sessionStatus === directSwitchSessionStatus.READY;

  const getFeatureCheckbox = steps => {
    if (steps.length > 0) return null;

    return (
      <div className="show-tour">
        <ShowFeatureCheckbox label="Show tips" featureType={userPreferenceTypes.TOURS} />
      </div>
    );
  };

  const getHeader = () => {
    let heading = null;

    if (offerCount === 0) {
      heading = 'No offers found';
    } else {
      switch (serviceType) {
        case billTypes.HOME_LOAN:
          heading = 'Best home loan rates, by lender';
          break;

        default:
          return <SummaryFooter />;
      }
    }

    return (
      <div className="sm-compare-header">
        <ServiceIcon isAdd={false} serviceType={serviceType} />
        <span className="heading">{heading}</span>
      </div>
    );
  };

  const getFilterSorter = comparison => {
    let filter = null;
    let sort = null;

    switch (serviceType) {
      case billTypes.INTERNET:
        sort = comparison.sorting ? parseInt(comparison.sorting) : comparisonSorting.CONDITIONAL.Id;

        if (isSummaryOffersOnly(comparison.offers)) filter = null;
        else filter = { downloadSpeed: submission.downloadSpeed, dataUnlimited: submission.dataUnlimited };
        break;

      default:
        sort = comparison.sorting ? parseInt(comparison.sorting) : comparisonSorting.GUARANTEED.Id;
        filter = null;
    }

    let filterSorter = {
      filter,
      sort
    };

    return filterSorter;
  };

  const [currentComparisonId, setCurrentComparisonId] = useState(comparison ? comparison.id : null);
  const [filterSorter, setFilterSorter] = useState(getFilterSorter(comparison));
  const [steps, setSteps] = useState([]);
  const [requestStartTour, setRequestStartTour] = useState(false);
  const [queueTour, setQueueTour] = useState();
  const [queueRedirect, setQueueRedirect] = useState();

  var directSwitchDialogOpen;

  useEffect(() => {
    // Reset launchMode to restore page sections
    if (landing.home.launchDialogMode !== launchDialogModes.DEFAULT) {
      dispatch(setLanding({ ...landing, home: { ...landing.home, launchDialogMode: launchDialogModes.DEFAULT } }));
    }

    if (!comparison.isLoaded) {
      dispatch(goHome());
    }

    if (comparison.hasError) {
      dispatch(
        setServerMessage({
          code: 400,
          scrollTo: true,
          messages: [{ field: '', message: 'Error retrieving comparison, please try again later.' }]
        })
      );
    }

    if (serverMessage.scrollTo) {
      setTimeout(() => {
        scroller.scrollTo('server-message-COMPARE', { duration: 800, delay: 0, smooth: 'easeInOutQuart', offset: -20 });
        dispatch(setServerMessage({ scrollTo: false }));
      }, 100);
    }

    // Scroll to first offer for selected provider if present in the list of offers.
    if (isDirectSwitch) {
      const offer = comparison.offers.find(e => e.providerCode === directSwitch.providerCode);

      if (offer) {
        setTimeout(
          () =>
            scroller.scrollTo(`offer${offer.id}`, {
              duration: 1200,
              delay: 800,
              smooth: 'easeInOutQuart',
              offset: -20
            }),
          100
        );
      }
    }

    // ------------------------------------------------------------------------
    // Guided tour
    // ------------------------------------------------------------------------
    if (tourGuideEnabled && pageMode === pageModes.DEFAULT) {
      const _steps = [];

      const submissionType = comparison.submissionType;

      if (offerCount > 0) {
        const bestOffer = comparison.bestOffer;

        switch (serviceType) {
          case billTypes.HOME_LOAN:
            if (hasSaving) {
              _steps.push({
                selector: '#offer_pricing_0',
                content: (
                  <div className="content">
                    <div className="icon-wrap">
                      <PartyIcon />
                    </div>
                    <div className="heading">Applying is easy</div>
                    <p>Our expert brokers can help you apply for this loan in minutes.</p>
                    <p>You may also be eligible for a $3000 or $5000 cashback upon approval.</p>
                    {getFeatureCheckbox(_steps)}
                  </div>
                )
              });
            } else {
              _steps.push({
                selector: '#offer_pricing_0',
                content: (
                  <div className="content">
                    <div className="heading">No saving found</div>
                    <p>Click on the Filters & Details tab if you'd like to edit your loan preferences</p>
                    {getFeatureCheckbox(_steps)}
                  </div>
                )
              });
            }
            break;

          default:
            if (hasSaving) {
              if (bestOffer.hasMarketComparison) {
                // Step for VDO/DMO content
                switch (submissionType) {
                  case submissionTypes.BILL:
                    _steps.push({
                      selector: '#offer_disclaimer_0',
                      content: (
                        <div className="content">
                          <div className="heading">{homeSizeHeading}</div>
                          This figure confirms how well this plan suits {homeSizeSentence} in your area.
                          {getFeatureCheckbox(_steps)}
                        </div>
                      )
                    });
                    break;

                  case submissionTypes.ESTIMATE:
                    _steps.push({
                      selector: '#offer_disclaimer_0',
                      content: (
                        <div className="content">
                          <div className="heading">{homeSizeHeading}</div>
                          <p>This figure confirms how well this plan suits {homeSizeSentence} in your area.</p>
                          {getFeatureCheckbox(_steps)}
                        </div>
                      )
                    });
                    break;

                  default:
                    break;
                }
              }

              switch (submissionType) {
                case submissionTypes.BILL:
                  const percentageBillReduction = bestOffer.percentageBillReductionAmountLowestDisplayShort;
                  const offerProviderName = bestOffer.providerDisplayCode;
                  const currentProviderName = submission.provider.displayCode;
                  const offerProviderLogoUrl = `${bestOffer.providerLogoUrl}`;
                  const currentProviderLogoUrl = `${staticContentUrl}/images/providers/${submission.provider.logo}`;

                  _steps.push({
                    selector: '#offer_pricing_0',
                    content: (
                      <div className="content">
                        <div className="provider-logo-wrapper">
                          <img className="provider-logo" src={currentProviderLogoUrl} alt={currentProviderName} />
                          <span class="material-icons">arrow_circle_right</span>
                          <img className="provider-logo" src={offerProviderLogoUrl} alt={offerProviderName} />
                        </div>
                        <div className="heading">Your home</div>
                        <p>This {percentageBillReduction} saving has been calculated using your home's unique bill.</p>
                        <p>
                          {`Select any plan and we'll arrange everything with both ${currentProviderName} and your new provider.`}
                        </p>
                        {getFeatureCheckbox(_steps)}
                      </div>
                    )
                  });

                  break;

                case submissionTypes.ESTIMATE:
                  if (userBill && userBill.reductionPercentage) {
                    _steps.push({
                      selector: '#offer_pricing_0',
                      content: (
                        <div className="content">
                          <div className="icon-wrap">
                            <PartyIcon />
                          </div>
                          <p>
                            This plan has {userBill.reductionPercentage.toLocaleString()}% saving based on the plan
                            features you've selected.
                          </p>
                          {getFeatureCheckbox(_steps)}
                        </div>
                      )
                    });
                  }
                  break;

                default:
                  break;
              }
            } else {
              if (bestOffer.hasMarketComparison) {
                _steps.push({
                  selector: '#offer_disclaimer_0',
                  content: (
                    <div className="content">
                      <div className="heading">{homeSizeHeading}</div>
                      <p>This figure confirms how well this plan suits {homeSizeSentence} in your area.</p>
                      {getFeatureCheckbox(_steps)}
                    </div>
                  )
                });
              } else {
                switch (submissionType) {
                  case submissionTypes.BILL:
                    const currentProviderName = submission.provider.displayCode;

                    _steps.push({
                      selector: '#offer_pricing_0',
                      content: (
                        <div className="content">
                          <div className="heading">Great News...</div>
                          We've checked the market and it looks like you're already on great rates with{' '}
                          {currentProviderName}
                          {getFeatureCheckbox(_steps)}
                        </div>
                      )
                    });
                    break;

                  case submissionTypes.ESTIMATE:
                    let heading;
                    let content;

                    switch (serviceType) {
                      case billTypes.INTERNET:
                        heading = 'The lowest cost';
                        content = <p>This plan has the lowest cost based on the plan features you've selected.</p>;
                        break;
                      default:
                        heading = homeSizeHeading;
                        content = <p>This figure confirms how well this plan suits {homeSizeSentence} in your area.</p>;
                    }

                    _steps.push({
                      selector: '#offer_pricing_0',
                      content: (
                        <>
                          <div className="content">
                            <div className="heading">{heading}</div>
                            {content}
                            {getFeatureCheckbox(_steps)}
                          </div>
                        </>
                      )
                    });

                    break;
                  default:
                    break;
                }
              }
            }
        }

        let drawerHeading;
        let drawerContent;
        switch (submissionType) {
          case submissionTypes.BILL:
            drawerHeading = 'Bill info and tools';
            drawerContent = `Open this draw to see your ${serviceTypeName} bill info and other useful tools.`;
            break;

          case submissionTypes.ESTIMATE:
            drawerHeading = 'Property info and tools';
            drawerContent = 'Open this draw to see your property info and other useful tools.';
            break;

          default:
            drawerHeading = null;
            drawerContent = null;
        }

        _steps.push({
          selector: '#drawer_handle',
          content: (
            <div className="content">
              <div className="icon-wrap">
                <CogIcon />
              </div>
              <div className="heading">{drawerHeading}</div>
              <p>{drawerContent}</p>
            </div>
          )
        });

        _steps.push({
          selector: '#organiser_menu',
          content: (
            <>
              <div className="content">
                <div className="heading">
                  Access your <OrganiserName nameOnly /> here
                </div>
                <div>
                  {hasSaving ? (
                    <>
                      Make sure you secure your {serviceTypeName} deal on this page, then go to your{' '}
                      <OrganiserName nameOnly /> to see other offers we've found for {displayAddress}.
                    </>
                  ) : (
                    <>
                      Your current {serviceTypeName} deal looks great! Click the <OrganiserName nameOnly /> button now
                      to check out the other offers we've found for {displayAddress}.
                    </>
                  )}
                </div>
              </div>
              <div className="button-container">
                <Button className={`sm-button-primary medium`} onClick={onDashboardClick}>
                  <OrganiserName nameOnly />
                </Button>
              </div>
              <div className="mt-3">
                <ServicesAuto
                  serviceType={serviceType}
                  services={billTypes.getOtherServices(agentBillTypes, serviceType, { includeExternal: false })}
                />
              </div>
            </>
          )
        });

        setSteps(_steps);
      }

      // allow the screen to finalize scroll position.
      delay(1500).then(() => startTour());
    }
  }, [comparison, userBill]);

  // --------------------------------------------------------------------------
  // Guided tour
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (pageMode === pageModes.FRAMED) {
      postFrameScrollTopMessage(frameHost);
    }

    if (navigationEvent === navigationTypes.TOUR) {
      startTour(true);
    }
  }, [navigationEvent]);

  useEffect(() => {
    if (currentComparisonId !== comparison.id) {
      var newFilterSorter = getFilterSorter(comparison);
      setFilterSorter(newFilterSorter);
      setCurrentComparisonId(comparison.id);
    }
  }, [comparison]);

  const startTour = forceStart => {
    const userPreferences = getUserPreferences();
    const showTour =
      tourGuideEnabled &&
      pageMode === pageModes.DEFAULT &&
      (userPreferences[userPreferenceTypes.TOURS.code] === true || forceStart);

    if (showTour) {
      if (submitDialogOpen || directSwitchDialogOpen) {
        setQueueTour(true); // queue the tour to start once the estimate or direct switch error dialogs are closed.
      } else {
        // Requesting to start will start the tour once all ajax spinner calls have completed.
        setRequestStartTour(Date.now());
      }
    }
  };

  const closeTour = () => {
    setRequestStartTour(false);
  };

  const getEstimateDialog = () => {
    switch (comparison.implementationCode) {
      case implementationCodes.ELECTRICITY.US_DEFAULT:
      case implementationCodes.ELECTRICITY.AU_DEFAULT:
      case implementationCodes.GAS.AU_DEFAULT:
      case implementationCodes.INTERNET.AU_DEFAULT:
        return (
          <SubmitBillDialogUx2
            enabled={true}
            showBackButton={false}
            showCancelButton={true}
            showCloseIcon={false} // This dialog is shown as a result of an email link accessing the compare page (do not show close icon).
            confirmButtonText="Next"
            serviceType={serviceType}
            uploadBillType={uploadBillTypes.MANUAL}
            uploadSubType={uploadSubTypes.getSubType(serviceType, submission)}
            submissionId={comparison.submissionId}
            submission={submission}
            dialogOpen={submitDialogOpen}
            onDialogClosing={onDialogClosed}
          />
        );

      default:
        return null;
    }
  };

  const onTimeout = () => {
    closeTour();
  };

  const onDashboardClick = () => {
    closeTour();
    setQueueRedirect('/dashboard'); // set redirect path to navigate to after tour closes and has had an opportunity to set the scroll bar back.
  };

  const onDirectSwitchDialogOpen = () => {
    directSwitchDialogOpen = true;
  };

  const onDialogClosed = () => {
    if (queueTour) {
      setQueueTour(false);
      setRequestStartTour(Date.now());
    }
  };

  const tourClosed = () => {
    if (queueRedirect) {
      dispatch(push(queueRedirect));
    }
  };

  const openMoreInfo = () => {
    dispatch(setLanding({ ...landing, comparison: { ...landing.comparison, openMoreInfo: true } }));
  };

  return (
    <>
      <Helmet>
        <title>Compare</title>
        {getAdWordConversionScript(
          config.isLoaded,
          config.settings.googleAdWordsId,
          config.settings.googleAdWordsComparisonLabel,
          comparison.id
        )}
        {taboolaConversionScript(config.isLoaded, config.settings.taboolaId, config.settings.taboolaComparisonLabel)}
      </Helmet>
      <TourGuide
        tourId={`compare_${comparison.submissionId}`}
        steps={steps}
        requestStart={requestStartTour}
        requestClose={closeTour}
        onClose={tourClosed}
      />
      {pageMode === pageModes.DEFAULT && (
        <CompareDrawer
          animateHandle={true}
          showSortFilter={true}
          onFilterSorterChanged={setFilterSorter}
          filterSorter={filterSorter}
          submissionId={comparison.submissionId}
        />
      )}

      {getEstimateDialog()}

      <DirectSwitchErrorDialog onOpen={onDirectSwitchDialogOpen} onClose={onDialogClosed} />

      <div className={`sm-offers${pageMode === pageModes.FRAMED ? ' framed' : ''}`}>
        {showPriceNote && (
          <Container className="sm-energy-price-note">
            <Row>
              <Col>
                <div className="paragraph">
                  Your e-bill may include usage charges that don't reflect your most recent energy rate changes.
                </div>
                <div className="paragraph">
                  See{' '}
                  <Button className="sm-link-button" onClick={openMoreInfo}>
                    More info
                  </Button>{' '}
                  to compare your current rates to the rates shown.
                </div>
              </Col>
            </Row>
          </Container>
        )}

        {pageMode === pageModes.FRAMED && offerCount === 0 && (
          <div className="no-offers">
            Unfortunately we have no {occupancyPurpose.Name.toLowerCase()} {serviceType.Name.toLowerCase()} plans for{' '}
            {postcodeInitial || displayAddress || 'your postcode'} right now.
          </div>
        )}

        {pageMode === pageModes.DEFAULT && getHeader()}

        <Offers onTimeout={onTimeout} filterSorter={filterSorter} />
      </div>

      {pageMode === pageModes.FRAMED && (
        <FooterDisclaimers pageMode={pageMode} showComparisonResultsDisclaimer={true} />
      )}
    </>
  );
};

export default CompareScreen;
