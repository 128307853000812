import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const SliderImage = ({ item }) => {
  return (
    <div>
      <img src={item.url} alt={item.code} />
    </div>
  );
};

const Slider = ({ items, direction = 'right', scrollSpeed, dialogMode }) => {
  return (
    <Carousel
      // additionalTransfrom={0}
      arrows={false}
      className={`sm-carousel-slider direction-${direction}${dialogMode ? ' dialog' : ''}`}
      draggable={false}
      swipeable={false}
      showDots={false}
      containerClass="sm-carousel-container"
      itemClass="sm-carousel-item"
      autoPlay
      autoPlaySpeed={1}
      infinite={true}
      customTransition={`transform ${scrollSpeed || 2}s linear`}
      transitionDuration={2000}
      direction={direction}
      responsive={{
        desktop: {
          breakpoint: { max: 6000, min: 1024 },
          items: items.length < 7 ? items.length : 7,
          partialVisibilityGutter: 40 // this is optional if you are not using partialVisible props
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: items.length < 5 ? items.length : 6,
          partialVisibilityGutter: 30 // this is optional if you are not using partialVisible props
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: items.length < 2 ? items.length : 4,
          partialVisibilityGutter: 30 // this is optional if you are not using partialVisible props
        }
      }}
    >
      {items.map(item => (
        <SliderImage item={item} key={`provider-logo-slider-${item.code}`} />
      ))}
    </Carousel>
  );
};

export default Slider;
