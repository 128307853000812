import React from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '../../auth/auth0-Provider.js';
import { Button } from 'reactstrap';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { buttonTypes } from '../../enums/buttonTypes.js';

const AuthenticationButton = props => {
  const { onLogin, showLogin, showLogout, buttonType, additionalClass, loginButtonText, logoutButtonText } = props;

  const { isUserAuthenticated, loginWithRedirect, logout, loading } = useAuth0();

  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);

  const showLoginInternal = showLogin === undefined ? true : showLogin;
  const showLogoutInternal = showLogout === undefined ? true : showLogout;

  const cssClass = `${buttonType === buttonTypes.LINK ? 'sm-link-button' : 'sm-button-primary medium'}${
    additionalClass ? ` ${additionalClass}` : ''
  }`;

  const onLoginInternal = () => {
    standardEvents('Authentication', 'Click', 'Login', googleAnalyticsClientCode);

    if (onLogin) onLogin();

    loginWithRedirect({});
  };

  const onLogoutInternal = () => {
    standardEvents('Authentication', 'Click', 'Logout', googleAnalyticsClientCode);

    if (logout) logout({});
  };

  const getButton = () => {
    const showButton = isUserAuthenticated() ? showLogoutInternal : showLoginInternal;

    if (!showButton) return;

    const buttonText = isUserAuthenticated() ? logoutButtonText || 'Logout' : loginButtonText || 'Log in';
    const action = isUserAuthenticated() ? onLogoutInternal : onLoginInternal;

    switch (buttonType) {
      case buttonTypes.TEXT:
        return <span onClick={action}>{buttonText}</span>;

      default:
        return (
          <Button className={cssClass} onClick={action}>
            {buttonText}
          </Button>
        );
    }
  };

  return <>{!loading ? getButton() : null}</>;
};

export default AuthenticationButton;
