import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import AddressControl from '../common/addressControl';
import SubmitCommon from '../common/submitCommon';
import ServiceIcon from '../serviceCard/serviceIcon';
import { controlTypes } from './getStarted';

import { submitEstimateEnergyDefault } from '../../actions/energyEstimateActions';
import { billTypes } from '../../enums/billTypes';
import { getEmptyAddress } from '../../utilities/addressUtilities';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getSessionActivity } from '../../utilities/commonUtilities';
import OrganiserName from '../common/organiserName';

const WizardBasic = ({ onClose, onChangeControlType }) => {
  const dispatch = useDispatch();

  const { country } = useSelector(state => state.config);
  const { selectedAgent, session } = useSelector(state => state);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { loaded: userLoaded } = useSelector(state => state.user);

  const [selectedAddress, setSelectedAddress] = useState(getEmptyAddress(null));
  const [submissionCommon, setSubmissionCommon] = useState({});

  const serviceTypeOptions = { includeExternal: false };
  const hasElectricity = billTypes.hasServiceType(agentBillTypes, billTypes.ELECTRICITY, serviceTypeOptions);
  const hasGas = billTypes.hasServiceType(agentBillTypes, billTypes.GAS, serviceTypeOptions);
  const hasHomeLoan = billTypes.hasServiceType(agentBillTypes, billTypes.HOME_LOAN, serviceTypeOptions);
  const hasInternet = billTypes.hasServiceType(agentBillTypes, billTypes.INTERNET, serviceTypeOptions);

  const [energyComparisons, setEnergyComparisons] = useState([]);

  useEffect(() => {
    const completedComparisons = [];
    if (hasElectricity) completedComparisons.push({ serviceTypeId: billTypes.ELECTRICITY.Id, completed: false });
    if (hasGas) completedComparisons.push({ serviceTypeId: billTypes.GAS.Id, completed: false });

    setEnergyComparisons(completedComparisons);
  }, []);

  const servicesText = billTypes.getServicesText(
    agentBillTypes,
    [billTypes.ELECTRICITY, billTypes.GAS],
    serviceTypeOptions
  );

  const sessionActivity = getSessionActivity(session);

  const rowClass = 'sm-dialog-row';

  // --------------------------------------------------------------------------
  // Address selected handler.
  // --------------------------------------------------------------------------
  const onAddressSelected = address => {
    setSelectedAddress(address);
  };

  // --------------------------------------------------------------------------
  // Submission common handlers.
  // --------------------------------------------------------------------------
  const onSubmissionCommonLoad = value => {
    if (value) {
      setSubmissionCommon(value);
    }
  };

  const onSubmissionCommonChange = value => {
    setSubmissionCommon(value);
  };

  // --------------------------------------------------------------------------
  // Energy comparison complete handler.
  // --------------------------------------------------------------------------
  const energyComparisonCompleted = serviceType => {
    const ec = [...energyComparisons];
    const st = ec.find(e => e.serviceTypeId === serviceType.Id);

    st.completed = true;

    if (ec.every(e => e.completed === true)) {
      onNextStep();
    } else {
      setEnergyComparisons(ec);
    }
  };

  // --------------------------------------------------------------------------
  // Next button click handler.
  // --------------------------------------------------------------------------
  const onNextButtonClick = () => {
    const options = {
      showSpinner: true
    };

    if (hasElectricity) {
      dispatch(
        submitEstimateEnergyDefault(
          billTypes.ELECTRICITY,
          selectedAddress,
          selectedAgent,
          sessionActivity,
          submissionCommon,
          options,
          () => energyComparisonCompleted(billTypes.ELECTRICITY)
        )
      );

      standardEvents('Setup-Wizard-Dialog', 'Electricity', 'Submit', googleAnalyticsClientCode);
    }

    if (hasGas) {
      dispatch(
        submitEstimateEnergyDefault(
          billTypes.GAS,
          selectedAddress,
          selectedAgent,
          sessionActivity,
          submissionCommon,
          options,
          () => energyComparisonCompleted(billTypes.GAS)
        )
      );

      standardEvents('Setup-Wizard-Dialog', 'Gas', 'Submit', googleAnalyticsClientCode);
    }
  };

  // --------------------------------------------------------------------------
  // Next step click handler.
  // --------------------------------------------------------------------------
  const onNextStep = () => {
    const data = {
      ...submissionCommon,
      propertyAddress: selectedAddress,
      selectedAgent
    };

    if (hasInternet) {
      onChangeControlType(controlTypes.WizardInternet, data);
    } else if (hasHomeLoan) {
      onChangeControlType(controlTypes.WizardHomeLoan, data);
    } else {
      onClose(true);
    }
  };

  const hasElectricityAndGas = hasElectricity && hasGas;
  const heading = `${hasElectricity ? billTypes.ELECTRICITY.Name : ''}${hasElectricityAndGas ? ' and ' : ''}${
    hasGas ? billTypes.GAS.Name : ''
  }`;

  return (
    <>
      <div className="start-heading mb-2">
        <div className="service-icons">
          {hasElectricity && <ServiceIcon serviceType={billTypes.ELECTRICITY} />}
          {hasGas && <ServiceIcon serviceType={billTypes.GAS} />}
        </div>
        <div className="heading-text">{heading}</div>
      </div>
      <div className="intro-text">
        <p>
          Let's start with your {servicesText}. Simply enter your home address and we'll find our best deals for your
          home.
        </p>
      </div>

      <AvForm onValidSubmit={onNextButtonClick}>
        {/* --- ADDRESS ----------------------------------------------------- */}
        <div className={`${rowClass} mb-3`}>
          <div className="sm-get-started-dialog-address">
            <AddressControl
              key="wizardAddress"
              keySuffix="wizard"
              labelText={<>&nbsp;</>}
              country={country}
              onAddressSelected={onAddressSelected}
              autoPlaceholder={selectedAddress.fullAddress || 'Property address...'}
              autoPropertyAddress={selectedAddress}
              autoAddressRequired={true}
              manualAddressVisible={true}
              manualSuburbSelectorEnabled={true}
              showAreasSupportedDisclaimer={false}
              showMinimalManualFields={false}
            />
          </div>
        </div>

        {/* --- EMAIL ADDRESS / AGENT --------------------------------------------- */}
        <div className={`${rowClass} mt-4`}>
          {!userLoaded && (
            <div className="intro-text">
              Add your email address here if you'd like to save the deals we find for this property to your{' '}
              <OrganiserName nameOnly />
            </div>
          )}
          <div>
            <SubmitCommon
              onLoad={onSubmissionCommonLoad}
              onChange={onSubmissionCommonChange}
              showSendComparisonEmail={false}
              showSendArticlesEmail={false}
              showSendSolarInstallationEmail={false}
              serviceType={billTypes.ELECTRICITY}
              showNote={false}
            />
          </div>
        </div>

        <div className="wizard-footer">
          <div className="dialog-button">
            <Button className="sm-button-secondary medium">Next</Button>
          </div>
        </div>
      </AvForm>
    </>
  );
};

export default WizardBasic;
