let getElementOffset = function(elementId) {
  var el = document.getElementById(elementId);
  const rect = el.getBoundingClientRect();

  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
};
export { getElementOffset };
