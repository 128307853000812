import React from 'react';
import QsCheckBox from './qsCheckBox';

const Conditions = ({ offer, confirmDetail, setConfirmDetail, type, orientation, setFieldOnAllConditionsChecked }) => {
  const hasConditions =
    offer &&
    offer.productDetail &&
    offer.productDetail.consentConditions &&
    offer.productDetail.consentConditions.length > 0 &&
    offer.productDetail.consentConditions.filter(condition => !type || condition.type === type).length > 0;

  const conditions = hasConditions
    ? offer.productDetail.consentConditions.filter(condition => !type || condition.type === type)
    : null;

  const conditionIsAccepted = (id, confirmDetail) => {
    if (confirmDetail.conditions && confirmDetail.conditions.length > 0) {
      var condition = confirmDetail.conditions.find(conditionAccept => conditionAccept.conditionId === id);
      return condition ? condition.accepted : false;
    }

    return false;
  };

  const onChangeCondition = condition => {
    var newConfirmDetail = {
      ...confirmDetail
    };

    var index = newConfirmDetail.conditions.findIndex(conditionAccept => conditionAccept.conditionId === condition.id);

    var newConditionAccept = { ...newConfirmDetail.conditions[index] };
    newConditionAccept.accepted = !newConditionAccept.accepted;

    newConfirmDetail.conditions = [
      ...newConfirmDetail.conditions.slice(0, index),
      newConditionAccept,
      ...newConfirmDetail.conditions.slice(index + 1)
    ];

    if (setFieldOnAllConditionsChecked) {
      var allConditionsChecked = conditions.every(condition => conditionIsAccepted(condition.id, newConfirmDetail));
      newConfirmDetail[setFieldOnAllConditionsChecked] = allConditionsChecked;
    }

    setConfirmDetail(newConfirmDetail);
  };

  return hasConditions
    ? conditions.map((condition, i) => (
        <div key={`condField-${i}`}>
          <QsCheckBox
            orientation={orientation}
            allowLabelCheck={false}
            isChecked={conditionIsAccepted(condition.id, confirmDetail)}
            onCheck={() => onChangeCondition(condition)}
            label={<div dangerouslySetInnerHTML={{ __html: condition.description }} />}
            validatorName={`condition-term${condition.id}`}
            validationMessage={condition.consentRequired ? 'Please accept this condition' : null}
          />
        </div>
      ))
    : null;
};

export default Conditions;
