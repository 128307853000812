import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import GeneralDialog from '../dialogs/generalDialog';
import LoginDialog from '../dialogs/loginDialog';

import { useDispatch } from 'react-redux';
import { goHome } from '../../actions/comparisonActions';

const RegistrationDialog = () => {
  const dispatch = useDispatch();

  const { initComplete } = useSelector(state => state.registration);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);

  const onClose = () => {
    if (initComplete) {
      dispatch(goHome());
    } else {
      setLoginDialogOpen(false);
    }
  };

  return (
    <>
      <div className="sm-registration-footer">
        <Button className="sm-button-primary" onClick={() => setLoginDialogOpen(true)}>
          Register
        </Button>
      </div>

      <GeneralDialog key="loginDialog" dialogSize="sm" dialogOpen={loginDialogOpen} onClose={onClose}>
        <LoginDialog onClose={onClose} />
      </GeneralDialog>
    </>
  );
};

export default RegistrationDialog;
