import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store/configureStore';
import { Provider } from 'react-redux';
import { Auth0Provider } from './auth/auth0-Provider';

const onRedirectCallback = (appState, redirectUrl) => {
  var target = appState && appState.targetUrl ? appState.targetUrl : redirectUrl;
  history.push(target);
};

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <Auth0Provider redirect_uri={window.location.origin} onRedirectCallback={onRedirectCallback}>
        <ConnectedRouter history={history}>
          <div>
            <Component />
          </div>
        </ConnectedRouter>
      </Auth0Provider>
    </Provider>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

serviceWorker.unregister();
