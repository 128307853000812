import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import qs from 'query-string';

import { lowerObjectProperties } from '../utilities/commonUtilities';

import { authenticate } from '../actions/userActions';

const Init = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    const locationSearch = qs.parse(props.location.search);
    const query = lowerObjectProperties(locationSearch);
    const token = query.token;
    const module = props.match.params.module ? props.match.params.module.toLowerCase() : null;

    switch (module) {
      case 'auth':
        dispatch(authenticate(token));
        break;

      default:
        break;
    }
  }, []);

  return null;
};

export default Init;
