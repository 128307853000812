import React from 'react';
import { useSelector } from 'react-redux';

import { billTypes } from '../../enums/billTypes';
import { getAddress } from '../../utilities/addressUtilities';
import { getReferenceDataItem } from '../../utilities/commonUtilities';
import CommonSwitchSummary from './commonSwitchSummary';

const EnergySwitchSummary = ({ otherServices }) => {
  const { providerLogoUrl, providerDisplayCode, referenceNumber, name } = useSelector(state => state.selectedOffer);
  const { propertyAddress, billType, occupancyTypeIsNew } = useSelector(state => state.comparison.submission);
  const { title: titleId, firstName, surname } = useSelector(state => state.confirmDetail);
  const { titles } = useSelector(state => state.config.referenceData);

  const serviceType = billTypes.getByCode(billType);

  const title = getReferenceDataItem(titleId, titles);

  const addressOptions = {
    unitNumber: true
  };
  const fullAddress = getAddress(propertyAddress, addressOptions).fullAddress;

  return (
    <>
      <div className="intro-text">
        <div>Your {serviceType.Name.toLowerCase()} application has been submitted to</div>
        {providerDisplayCode}.
      </div>
      <div>
        <img className="provider-logo" src={providerLogoUrl} alt={providerDisplayCode} />
      </div>

      <div className="switch-details">
        <div className="detail-row">
          <div className="detail-label">Customer:</div>
          <div className="detail-value">
            {title ? title.description : null} {firstName} {surname}
          </div>
        </div>

        <div className="detail-row">
          <div className="detail-label">Reference:</div>
          <div className="detail-value">{referenceNumber}</div>
        </div>

        {fullAddress && (
          <div className="detail-row">
            <div className="detail-label">Property Address:</div>
            <div className="detail-value">{fullAddress}</div>
          </div>
        )}

        <div className="detail-row">
          <div className="detail-label">Product:</div>
          <div className="detail-value">{name}</div>
        </div>
      </div>

      <CommonSwitchSummary
        serviceType={serviceType}
        otherServices={otherServices}
        showWhatHappensNext={true}
        showDisconnectionNotice={occupancyTypeIsNew}
      />
    </>
  );
};

export default EnergySwitchSummary;
