import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import TermsAndConditionsDialog from './termsAndConditionsDialog';
import { pageModes } from '../../enums/launchTypes';
import { postFrameDialogMessage } from '../../utilities/domUtilities';

const ProgramAbstractDialog = ({ buttonText, buttonClass }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const { organiserAgentName } = useSelector(state => state.content.general);
  const { programName } = useSelector(state => state.config);
  const { pageMode, frameHeight, frameHost } = useSelector(state => state.application);

  const [dialogOpen, setDialogOpen] = useState();

  const onDialogToggle = event => {
    // Prevent checkbox from being toggled in cases where dialog button is being used in the label
    event.stopPropagation();

    setDialogOpen(!dialogOpen);
  };

  const handleModal = () => {
    if (pageMode === pageModes.FRAMED) {
      dispatch(postFrameDialogMessage(frameHost, 'Program Abstract', dialogOpen, ref.current, frameHeight));
    }
  };

  return (
    <>
      <Button className={`sm-link-button ${buttonClass}`} onClick={onDialogToggle}>
        {buttonText || 'More info'}
      </Button>

      <Modal
        onOpened={handleModal}
        onClosed={handleModal}
        toggle={onDialogToggle}
        isOpen={dialogOpen}
        size="lg"
        centered={pageMode === pageModes.DEFAULT}
      >
        <div ref={ref}>
          <ModalBody className="qs-modal-body">
            <Container>
              <Row>
                <Col xs="10" className="qs-modal-heading">
                  <h1>How {programName} works</h1>
                </Col>
                <Col xs="2">
                  <div onClick={onDialogToggle} className="qs-modal-close-icon">
                    <i className="material-icons">close</i>
                  </div>
                </Col>
              </Row>

              <Row className="qs-mt-lg">
                <Col>
                  <p>
                    {programName} is a free service that helps to remove bill-pain by managing your household bills in
                    one central location. You'll soon be able to access your current and past bills, pay your bills by
                    responding to an SMS and if you are moving house, you'll be able to move multiple services in one
                    locaton.
                  </p>
                  <p>
                    If you choose to have your bills delivered to your{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: organiserAgentName
                      }}
                    />{' '}
                    we'll automatically ask your new retailer to email your future e-bills to a {programName} email
                    address we set up for you. We'll immediately forward all correspondence and bills from your retailer
                    to your regular email address. Importantly, when communicating with the retailer, and when you
                    register on their website or any apps, you'll need to use your Organiser email address that we'll
                    shared with them.
                  </p>
                  <p>
                    See <TermsAndConditionsDialog buttonText="Terms and Conditions" /> for more details.
                  </p>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button className="medium" onClick={onDialogToggle}>
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default ProgramAbstractDialog;
