import React from 'react';
import { useSelector } from 'react-redux';

import { getAddress } from '../../utilities/addressUtilities';
import { getReferenceDataItem } from '../../utilities/commonUtilities';
import { billTypes } from '../../enums/billTypes';
import CommonSwitchSummary from './commonSwitchSummary';

const HomeLoanSwitchSummary = ({ otherServices }) => {
  const { providerLogoUrl, providerDisplayCode, referenceNumber, name } = useSelector(state => state.selectedOffer);
  const { propertyAddress, refinance } = useSelector(state => state.comparison.submission);
  const { title: titleId, firstName, surname } = useSelector(state => state.confirmDetail);
  const { titles } = useSelector(state => state.config.referenceData);

  const title = getReferenceDataItem(titleId, titles);

  const fullAddress = refinance ? getAddress(propertyAddress).fullAddress : null;
  const serviceType = billTypes.HOME_LOAN;

  return (
    <>
      <div className="intro-text">Your home loan enquiry has been passed to our team with the following details:</div>
      <div>
        <img className="provider-logo" src={providerLogoUrl} alt={providerDisplayCode} />
      </div>

      <div className="switch-details">
        <div className="detail-row">
          <div className="detail-label">Customer:</div>
          <div className="detail-value">
            {title ? title.description : null} {firstName} {surname}
          </div>
        </div>

        <div className="detail-row">
          <div className="detail-label">Reference:</div>
          <div className="detail-value">{referenceNumber}</div>
        </div>

        {fullAddress && (
          <div className="detail-row">
            <div className="detail-label">Property Address:</div>
            <div className="detail-value">{fullAddress}</div>
          </div>
        )}

        <div className="detail-row">
          <div className="detail-label">Product:</div>
          <div className="detail-value">{name}</div>
        </div>
      </div>

      <CommonSwitchSummary
        serviceType={serviceType}
        otherServices={otherServices}
        showWhatHappensNext={false}
        showDisconnectionNotice={false}
      />

      <div className="what-happens-next">
        <div className="heading">What happens next?</div>
        <div className="detail home-loan">
          <ul>
            <li>
              There are a few initial questions our home loan specialists will discuss with you to make sure we
              understand your unique needs
            </li>
            <li>You can call us now on 1300 466 356 if it's a convenient time</li>
            <li>If we don't hear from you, we'll contact you at the preferred call time you requested</li>
            <li>Just so you know it's us, our call will come from a New South Wales phone number starting with (02)</li>
          </ul>
        </div>
        <div className="centered">We look forward to helping you with your home loan inquiry!</div>
      </div>
    </>
  );
};

export default HomeLoanSwitchSummary;
