import { billTypes } from '../enums/billTypes';
import * as types from './actions';

export function setAjaxCallStartSuccess(increment, context, serviceType) {
  return { type: types.SET_AJAX_CALL_START, increment, context, serviceType };
}

export function setAjaxCallStart(context, serviceType) {
  return dispatch => {
    dispatch(setAjaxCallStartSuccess(1, context, serviceType || billTypes.NONE));
  };
}

export function setAjaxCallEndSuccess(increment) {
  return { type: types.SET_AJAX_CALL_END, increment };
}

export function setAjaxCallEnd() {
  return dispatch => {
    dispatch(setAjaxCallEndSuccess(-1));
  };
}
