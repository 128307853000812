import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import _debounce from 'lodash.debounce';

import { useAuth0 } from '../../auth/auth0-Provider';
import SubmitBillFileUploader from './submitBillFileUploader';
import ExitLaunch from '../common/exitLaunch';
import SubmitCommon from './submitCommon';
import QsButtonGroup from './qsButtonGroup';
import PoweredBySmartMe from './poweredBySmartMe';
import GeneralDialog from '../dialogs/generalDialog';
import RedirectDialog from '../dialogs/redirectDialog';
import SubmitDialogBanner from '../dialogs/submitDialogBanner';

import { addSessionActivity } from '../../actions/sessionActivityActions';
import { closeDialogs } from '../../actions/dialogOptionsActions';
import { submitBillWithToken, submitBillWithKey, submitBillUnauthenticated } from '../../actions/energyBillActions';
import {
  setServerMessageClear,
  displayNoOffersMessage,
  comparisonErrorMessage
} from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';

import { occupancyPurposes, submissionTypes } from '../../enums/submissionTypes';
import { launchDialogModes } from '../../enums/launchTypes';
import { billTypes } from '../../enums/billTypes';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes';

import { submitAutoEstimates } from '../serviceCard/serviceCardUtilities';
import { getSessionActivity } from '../../utilities/commonUtilities';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getExit } from '../../utilities/billTypeUtilities';
import BrandScroller from './brandScroller';

const SubmitBillDefaultUx2 = ({
  onSuccess,
  onEstimateButtonClick,
  onRedirectButtonClick,
  onBackButtonClick,
  showBackButton,
  confirmButtonText,
  serviceType: userServiceType,
  launchMode,
  onVerify
}) => {
  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { serverMessage, selectedAgent, session } = useSelector(state => state);
  const { accessKey, accessId } = useSelector(state => state.comparison);
  const { exitCode } = useSelector(state => state.landing.home);
  const { occupancyPurpose } = useSelector(state => state.comparison.submission);
  const { defaultOccupancyPurpose, billTypes: agentBillTypes } = useSelector(state => state.config);
  const { launchDialogRedirectEnabled, showPoweredBy, googleAnalyticsClientCode, exits } = useSelector(
    state => state.config.settings
  );
  const { data: userBills } = useSelector(state => state.userBills);

  const sessionActivity = getSessionActivity(session);

  const getOccupancyPurpose = () => {
    return occupancyPurpose ? occupancyPurpose : defaultOccupancyPurpose;
  };

  const [selectedOccupancyPurpose, setSelectedOccupancyPurpose] = useState(getOccupancyPurpose());
  const [redirectDialogOpen, setRedirectDialogOpen] = useState(false);
  const [hasOffers, setHasOffers] = useState(false);
  const [supportedBill, setSupportedBill] = useState(false);
  const [serviceType, setServiceType] = useState(userServiceType);

  const [files, setFiles] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submissionCommon, setSubmissionCommon] = useState({});

  const target = serverMessageTargets.SUBMIT_BILL;
  const hasServiceType = serviceType !== billTypes.NONE;
  const redirectHeading = hasServiceType ? serviceType.Name : 'Your bill';

  const restrictedExit =
    launchMode === launchDialogModes.RESTRICTED ? getExit(exitCode, exits, serviceType, agentBillTypes) : null;

  useEffect(() => {
    dispatch(setServerMessageClear(target, serverMessage));
  }, []);

  // --------------------------------------------------------------------------
  // Invalid form submit handler.
  // --------------------------------------------------------------------------
  const handleInvalidSubmit = () => {
    setHasSubmitted(true);
  };

  // --------------------------------------------------------------------------
  // Submission common handlers.
  // --------------------------------------------------------------------------
  const onSubmissionCommonChange = value => {
    if (value) setSubmissionCommon(value);
  };

  const setButtonGroup = (event, setter) => {
    let option = event.target.getAttribute('data-value');
    setter(parseInt(option));
  };

  // --------------------------------------------------------------------------
  // Submit bill handler.
  // --------------------------------------------------------------------------
  const onSubmitBill = useCallback(
    _debounce(
      () => {
        const bill = {
          files,
          occupancyPurpose: selectedOccupancyPurpose,
          ...submissionCommon,
          sendComparisonEmail: true, // always send an email for bill comparisons
          sessionActivity
        };

        if (accessKey && accessId) {
          const data = { bill, access: { key: accessKey, id: accessId } };
          dispatch(submitBillWithKey(data, onComplete, onError, onVerifyResponse, serviceType));
        } else if (isUserAuthenticated()) {
          const fn = async () => {
            const accessToken = await getAccessToken();
            dispatch(submitBillWithToken(accessToken, { bill }, onComplete, onError, onVerifyResponse, serviceType));
          };
          fn();
        } else {
          dispatch(submitBillUnauthenticated({ bill }, onComplete, onError, onVerifyResponse, serviceType));
        }

        standardEvents('Service-Card-Selector', serviceType.Name, 'Submit-Bill', googleAnalyticsClientCode);
      },
      2000,
      { leading: true }
    ),
    [files, submissionCommon, accessKey, accessId]
  );

  // --------------------------------------------------------------------------
  // Bill comparison verify handler.
  // --------------------------------------------------------------------------
  const onVerifyResponse = (verifyServiceType, verifyData) => {
    // If no data was extracted and we are in a restricted launch mode, show the redirect dialog.
    if (
      launchMode === launchDialogModes.RESTRICTED &&
      launchDialogRedirectEnabled &&
      verifyServiceType === billTypes.NONE &&
      !verifyData
    ) {
      setRedirectDialogOpen(true);
      setSupportedBill(false);
    } else {
      onVerify(verifyServiceType, verifyData);
    }
  };

  // --------------------------------------------------------------------------
  // Bill comparison error handler.
  // --------------------------------------------------------------------------
  const onError = error => {
    dispatch(comparisonErrorMessage(error, target));
  };

  // --------------------------------------------------------------------------
  // Add session activity.
  // --------------------------------------------------------------------------
  const recordSessionActivity = (comparisonResult, userBill) => {
    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          submissionType: submissionTypes.BILL,
          submissionId: userBill.submissionId,
          serviceType: serviceType.Code,
          hasOffers: comparisonResult.hasOffers
        },
        activityType: sessionActivityTypes.COMPARISON_COMPLETED
      })
    );
  };

  // --------------------------------------------------------------------------
  // Bill comparison complete handler.
  // --------------------------------------------------------------------------
  const onComplete = (comparisonResult, userBill) => {
    recordSessionActivity(comparisonResult, userBill);

    const billServiceType = billTypes.getById(userBill.billTypeId, billTypes.NONE);
    const address = { ...userBill.bill.propertyAddress, isAddress: true };

    setHasOffers(comparisonResult.hasOffers);
    setSupportedBill(true);

    // Service type may be different after bill has been extracted.
    setServiceType(billServiceType);

    dispatch(
      submitAutoEstimates(
        billServiceType,
        address,
        selectedAgent,
        sessionActivity,
        submissionCommon,
        agentBillTypes,
        userBills,
        googleAnalyticsClientCode
      )
    );

    if (
      launchMode === launchDialogModes.RESTRICTED &&
      launchDialogRedirectEnabled &&
      (!comparisonResult.hasOffers || !comparisonResult.hasBetterOffers)
    ) {
      setRedirectDialogOpen(true);
    } else {
      if (!comparisonResult.hasOffers) {
        dispatch(displayNoOffersMessage(target));
      } else if (onSuccess) {
        onShowOffers();
      }
    }
  };

  const onShowOffers = () => {
    dispatch(closeDialogs('submitBillDefault:onShowOffers'));
    dispatch(push('/compare'));

    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <>
      {launchDialogRedirectEnabled && redirectDialogOpen && (
        <GeneralDialog
          key="redirectDialog"
          heading={redirectHeading}
          headingCenter={true}
          showCloseIcon={false}
          showFooter={false}
          serviceType={serviceType}
          dialogOpen={redirectDialogOpen}
        >
          <RedirectDialog
            launchMode={launchMode}
            serviceType={serviceType}
            hasOffers={hasOffers}
            supportedBill={supportedBill}
            onShowOffers={onShowOffers}
            onClose={() => setRedirectDialogOpen(false)}
          />
        </GeneralDialog>
      )}

      <BrandScroller serviceType={serviceType} dialogMode />

      <div className="sm-uploader-intro">
        <SubmitDialogBanner serviceType={serviceType} />
        {hasServiceType && <div className="heading">Please upload your latest bill</div>}

        <p className="text">
          Upload your bill in PDF format. That's probably how you received it in your email inbox! We'll scan your bill
          for your unique usage and costs and use this information to check the market for a better deal.
        </p>
      </div>
      <AvForm onValidSubmit={e => onSubmitBill(e)} onInvalidSubmit={handleInvalidSubmit}>
        <SubmitBillFileUploader files={files} setFiles={setFiles} showBorder={true} />

        {restrictedExit && (
          <div className="sm-uploader-redirect-buttons">
            <Button onClick={e => onRedirectButtonClick(e, restrictedExit.url)} className="sm-button-secondary medium">
              I don't have a PDF bill handy
            </Button>

            {serviceType !== billTypes.NONE && (
              <Button onClick={onEstimateButtonClick} className="sm-button-secondary medium">
                Compare using my address
              </Button>
            )}
          </div>
        )}

        <div className="qs-submit-property-type">
          <QsButtonGroup
            required
            id="occupancyPurpose"
            fieldName="Occupancy Purpose"
            selectedValue={selectedOccupancyPurpose.toString()}
            onTouchButton={e => setButtonGroup(e, setSelectedOccupancyPurpose)}
            buttons={[
              {
                label: 'Residential bill',
                value: occupancyPurposes.RESIDENTIAL.Id.toString(),
                default: true
              },
              {
                label: 'Business bill',
                value: occupancyPurposes.BUSINESS.Id.toString(),
                default: false
              }
            ]}
          />
        </div>

        <SubmitCommon
          hasSubmitted={hasSubmitted}
          onLoad={onSubmissionCommonChange}
          onChange={onSubmissionCommonChange}
          showSendComparisonEmail={false}
          showSendArticlesEmail={true}
          showSendSolarInstallationEmail={true}
          serviceType={serviceType}
        />
        <div className="sm-modal-footer">
          {launchMode === launchDialogModes.DEFAULT && showBackButton && (
            <Button onClick={onBackButtonClick} className="sm-button-secondary">
              Go back
            </Button>
          )}

          <Button className="sm-button-primary right-arrow-small wide">{confirmButtonText}</Button>
          <ExitLaunch launchMode={launchMode} serviceType={serviceType} source="bill-dialog" />

          {showPoweredBy && <PoweredBySmartMe />}
        </div>
      </AvForm>
    </>
  );
};

export default SubmitBillDefaultUx2;
