export const authenticationTypes = {
  DEFAULT: 1,
  CTM: 2
};

export const anonymousModes = {
  NONE: 0,
  FIXED: 1,
  OPTIONAL: 2
};

export const accessKeyTypes = {
  NONE: 0,
  USER: 1, // from user redux state object
  COMPARISON: 2 // from comparison redux state object
};
