import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AvField } from 'availity-reactstrap-validation';
import { getDistributorItem } from '../../actions/distributorActions';
import QsButtonGroup from './qsButtonGroup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { hasValidAddress } from '../../utilities/addressUtilities';

const OccupancyDate = ({
  rowClass,
  fieldClass,
  serviceType,
  selectedAddress,
  distributorSets,
  selectedDistributorPrimary,
  installDates,
  selectedOccupancyDate,
  onSelectedDateChanged,
  selectedOccupancyDateUncertain,
  onOccupancyDateUncertain,
  reset
}) => {
  const { count: ajaxCount } = useSelector(state => state.ajaxCall);

  const [occupancyDateClicked, setOccupancyDateClicked] = useState(false);

  const daysToOccupancy = selectedOccupancyDate ? moment(selectedOccupancyDate).diff(moment.date, 'days') + 1 : null;
  const hasSelectedAddress = hasValidAddress(selectedAddress);

  useEffect(() => {
    setOccupancyDateClicked(false);
  }, [reset]);

  return (
    <>
      <div className={rowClass}>
        <div className={fieldClass}>Do you know your connection / move-in date yet?</div>
        <div>
          <QsButtonGroup
            required={true}
            id="occupancyDateUncertain"
            fieldName="Occupancy Date Known"
            additionalClass="sm-button-group"
            selectedValue={selectedOccupancyDateUncertain.toString()}
            onTouchButton={e => {
              onOccupancyDateUncertain(e.target.getAttribute('data-value') === 'true');
            }}
            buttons={[
              {
                label: 'Not sure',
                value: 'true'
              },
              {
                label: 'Yes',
                value: 'false'
              }
            ]}
          />
        </div>
      </div>

      <div className={rowClass}>
        <div className={fieldClass}>
          {selectedOccupancyDateUncertain ? 'Approximate connection / move-in date' : 'Connection / move-in date'}
        </div>
        <div>
          {selectedOccupancyDateUncertain && (
            <p className="qs-energy-estimate-dialog-disclaimer">
              Not 100% sure of your date yet? We'll email you our best offers 2 weeks before this date.
            </p>
          )}
        </div>
        <div onClick={installDates.length === 0 ? () => setOccupancyDateClicked(true) : undefined}>
          <DatePicker
            selected={selectedOccupancyDate}
            onChange={onSelectedDateChanged}
            disabled={installDates.length === 0}
            useWeekdaysShort={true}
            dateFormat="dd/MM/yyyy"
            includeDates={installDates}
            placeholderText="dd/mm/yyyy"
          />
        </div>
      </div>

      <div>
        {occupancyDateClicked && installDates.length === 0 && ajaxCount === 0 ? (
          <div className="sm-validator-custom">
            <span>
              {!hasSelectedAddress
                ? 'Please enter your property address first'
                : getDistributorItem(distributorSets, serviceType).length > 1 && !selectedDistributorPrimary
                ? 'Connection date will be available after you select a distributor'
                : ''}
            </span>
          </div>
        ) : (
          <div className="qs-estimate-dialog-validation">
            <AvField
              type="text"
              name={`occupancyDate_validation`}
              value={selectedOccupancyDate}
              className="qs-tc-hidden"
              validate={{
                hidden: (value, ctx, input, cb) => {
                  let message = true;

                  if (ajaxCount === 0 && installDates.length > 0) {
                    if (!selectedOccupancyDate) {
                      message = 'Please select a connection date';
                    } else if (moment(selectedOccupancyDate) < moment().add(1, 'days')) {
                      message = 'Please select a valid date in the future';
                    }
                  }

                  cb(message);
                }
              }}
            />
          </div>
        )}

        {selectedOccupancyDate && selectedOccupancyDateUncertain && daysToOccupancy > 7 && (
          <div className="sm-validator-custom dim">
            <div className="sm-estimate-dialog-close-move-in">
              <i className="material-icons sm-hint-tips-icon">tips_and_updates</i>
              If you don't lodge an application for your connection now, we'll send you our best offers 7 days before
              this date.
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OccupancyDate;
