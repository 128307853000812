import * as types from './actions';
import axios from 'axios';
import timeAxios from '../utilities/timeAxios';
import { push } from 'connected-react-router';
import { apiTiming } from '../utilities/googleAnalyticsEvents';
import { getAttemptCount } from '../utilities/commonUtilities';
import { sleep } from '../utilities/compareUtilities';
import { getServerMessageFirstError } from '../utilities/serverMessageUtilities';
import { submitAutoEstimates } from '../components/serviceCard/serviceCardUtilities';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import { setUserBill, setUserBills, getUserBill, initAutoEstimates, goHome } from './comparisonActions';
import { setSelectedAgentSuccess } from './configActions';
import { setUser } from './userActions';
import { setDirectSwitch } from './directSwitchActions';
import { setServerMessageClear } from './serverMessageActions';
import {
  contextTypes,
  occupancyTypes,
  occupancyPurposes,
  usageTypes,
  solarTypes,
  internetDownloadSpeedTypes,
  loanPropertyTypes,
  loanTypes,
  repaymentTypes,
  directSwitchTypes
} from '../enums/submissionTypes';
import { billTypes } from '../enums/billTypes';
import { serviceCardLoadingTypes } from '../enums/serviceCardLoadingTypes';
import { directSwitchSessionStatus } from '../enums/directSwitchSessionStatus';
import { getFilteredInternetOffers } from '../utilities/internetUtilities';
import { launchDirectSwitchDialog } from './landingActions';
import { addSessionActivity } from './sessionActivityActions';
import { sessionActivityTypes } from '../enums/sessionActivityTypes';

export function setComparisonSuccess(comparison) {
  return { type: types.SET_COMPARISON, comparison };
}

// --------------------------------------------------------------------------
// Initializes a direct switch landing request.
// --------------------------------------------------------------------------
export function initLandingSwitch(sessionActivity, agentCode, directSwitchArgs, autoEstimatesArgs) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'initialize', 'direct-switch-landing`');
    });

    const model = {
      directSwitchType: directSwitchTypes.LANDING,
      tag: directSwitchArgs.tag,
      suburb: directSwitchArgs.area.suburb,
      state: directSwitchArgs.area.state,
      postcode: directSwitchArgs.area.postcode,
      serviceType: directSwitchArgs.serviceType,
      providerCode: directSwitchArgs.providerCode,
      providerName: directSwitchArgs.providerName,
      productName: directSwitchArgs.productName,
      productType: directSwitchArgs.productType,
      agentCode
    };

    axios
      .post(`/api/directswitch/init`, model)
      .then(response => {
        const data = response.data;

        if (data) {
          const selectOfferId = data.offerId;
          const serviceType = billTypes.getById(data.billTypeId);
          const selectedAgent = data.agentMember;
          const selectedAddress = data.address;
          const providerCode = data.providerCode;
          const providerName = data.providerName;
          const providerLogo = data.providerLogo;
          const productName = data.productName;
          const product = data.product;
          const occupancyPurpose = data.occupancyPurpose;
          const errors = data.errors;

          const errorMessage = errors && errors.length > 0 ? errors[0] : null;

          const directSwitch = {
            directSwitchType: directSwitchTypes.LANDING,
            sessionStatus: errorMessage ? directSwitchSessionStatus.ERRORED : directSwitchSessionStatus.READY,
            errorMessage,
            serviceType,
            selectOfferId,
            providerCode,
            providerName,
            providerLogo,
            productName,
            product,
            occupancyPurpose
          };

          dispatch(setDirectSwitch(directSwitch));

          const submissionCommon = {
            emailAddress: null,
            termsAndConditions: true,
            sendComparisonEmail: false,
            sendArticlesEmail: false,
            sendSolarInstallationEmail: false
          };

          const onComplete = (comparisonResult, userBill) => {
            dispatch(
              addSessionActivity({
                ...sessionActivity,
                data: {
                  submissionType: 'Direct Switch',
                  submissionSubType: 'Landing',
                  submissionId: userBill.submissionId,
                  serviceType: serviceType.Code,
                  hasOffers: comparisonResult.hasOffers
                },
                activityType: sessionActivityTypes.COMPARISON_COMPLETED
              })
            );

            if (comparisonResult.hasOffers) {
              const userBills = [userBill]; // derive auto-estimates from single user bill.
              const address = { ...selectedAddress, isAddress: true };

              dispatch(
                submitAutoEstimates(
                  serviceType,
                  address,
                  selectedAgent,
                  sessionActivity,
                  submissionCommon,
                  autoEstimatesArgs.agentBillTypes,
                  userBills,
                  autoEstimatesArgs.googleAnalyticsClientCode
                )
              );

              dispatch(push('/compare'));
            } else {
              dispatch(goHome());
            }
          };

          const onError = error => {
            dispatch(HandleDirectSwitchError(model, error));
          };

          if (errorMessage) {
            switch (errorMessage.field) {
              // Comparison can be attempted with these errors:
              case 'BadTag':
              case 'NotFound':
              case 'MismatchState':
              case 'MismatchService':
                switch (serviceType) {
                  case billTypes.ELECTRICITY:
                  case billTypes.GAS:
                    break;

                  default:
                    throw new Error('Error initializing direct switch request for service type');
                }
                break;

              default:
                throw new Error('Error initializing direct switch request');
            }
          }

          const estimate = {
            distributorCode: data.product && data.product.distributor ? data.product.distributor.selectionCode : null,
            property: {
              address: { ...selectedAddress },
              occupancyDate: null,
              occupancyType: occupancyTypes.CURRENT.Id,
              occupancyPurpose: occupancyPurposes.RESIDENTIAL.Id
            },
            ...submissionCommon,
            sessionActivity,
            agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
            usageLevel: usageTypes.MEDIUM.Id,
            solarLevel: solarTypes.NONE.Id,
            termsAndConditions: true
          };

          dispatch(directEnergySwitch(serviceType, estimate, autoEstimatesArgs, onComplete, onError));
        }
      })
      .catch(error => {
        dispatch(HandleDirectSwitchError(model, error));
      });

    dispatch(setAjaxCallEnd());
  };
}

// --------------------------------------------------------------------------
// Initializes a direct switch launch request.
// --------------------------------------------------------------------------
export function initLaunchSwitch(agentCode, directSwitchArgs, options) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'initialize', 'direct-switch-launch`');
    });

    const model = {
      directSwitchType: directSwitchTypes.LAUNCH,
      tag: directSwitchArgs.tag,
      serviceType: directSwitchArgs.serviceType,
      providerCode: directSwitchArgs.providerCode,
      providerName: directSwitchArgs.providerName,
      productName: directSwitchArgs.productName,
      productType: directSwitchArgs.productType,
      agentCode
    };

    axios
      .post(`/api/directswitch/init`, model)
      .then(response => {
        const data = response.data;

        if (data) {
          const selectOfferId = data.offerId;
          const serviceType = billTypes.getById(data.billTypeId);
          const providerName = data.providerName;
          const providerLogo = data.providerLogo;
          const productName = data.productName;
          const product = data.product;
          const occupancyPurpose = data.occupancyPurpose;
          const errors = data.errors;

          const errorMessage = errors && errors.length > 0 ? errors[0] : null;

          const directSwitch = {
            directSwitchType: directSwitchTypes.LAUNCH,
            sessionStatus: errorMessage ? directSwitchSessionStatus.ERRORED : directSwitchSessionStatus.READY,
            errorMessage,
            serviceType,
            selectOfferId,
            providerName,
            providerLogo,
            productName,
            product,
            occupancyPurpose
          };

          dispatch(setDirectSwitch(directSwitch));
          dispatch(launchDirectSwitchDialog(options));
        }
      })
      .catch(error => {
        dispatch(HandleDirectSwitchError(model, error));
      });

    dispatch(setAjaxCallEnd());
  };
}

// --------------------------------------------------------------------------
// Initializes a direct provider launch request.
// --------------------------------------------------------------------------
export function initLaunchProvider(agentCode, directSwitchArgs, options) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'initialize', 'direct-provider-launch`');
    });

    const model = {
      directSwitchType: directSwitchTypes.PROVIDER,
      serviceType: directSwitchArgs.serviceType,
      providerCode: directSwitchArgs.providerCode,
      providerName: directSwitchArgs.providerName,
      productType: directSwitchArgs.productType,
      agentCode
    };

    axios
      .post(`/api/directswitch/provider`, model)
      .then(response => {
        const data = response.data;

        if (data) {
          const serviceType = billTypes.getById(data.billTypeId);
          const providerCode = data.providerCode;
          const providerName = data.providerName;
          const providerLogo = data.providerLogo;
          const occupancyPurpose = data.occupancyPurpose;
          const errors = data.errors;

          const errorMessage = errors && errors.length > 0 ? errors[0] : null;

          const directSwitch = {
            directSwitchType: directSwitchTypes.PROVIDER,
            sessionStatus: errorMessage ? directSwitchSessionStatus.ERRORED : directSwitchSessionStatus.READY,
            errorMessage,
            serviceType,
            providerCode,
            providerName,
            providerLogo,
            occupancyPurpose
          };

          dispatch(setDirectSwitch(directSwitch));
          dispatch(launchDirectSwitchDialog(options));
        }
      })
      .catch(error => {
        dispatch(HandleDirectSwitchError(model, error));
      });

    dispatch(setAjaxCallEnd());
  };
}

export function HandleDirectSwitchError(model, error) {
  return dispatch => {
    const errorMessage = getServerMessageFirstError(error.response);
    const serviceType = billTypes.getByCode(model.serviceType, billTypes.NONE);

    const providerName =
      model.providerName && model.providerName.length > 0 ? model.providerName.substring(0, 100) : null;

    const productName = model.productName && model.productName.length > 0 ? model.productName.substring(0, 100) : null;

    const directSwitch = {
      sessionStatus: directSwitchSessionStatus.ERRORED,
      errorMessage,
      serviceType,
      providerName,
      productName
    };

    dispatch(setDirectSwitch(directSwitch));
    dispatch(goHome());
  };
}

// --------------------------------------------------------------------------
// Initializes a direct switch email request.
// --------------------------------------------------------------------------
export function directSwitchEmail(model, onComplete, onError) {
  return dispatch => {
    dispatch(setServerMessageClear());
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'initialize', 'direct-switch-email`');
    });

    axios
      .post(`/api/directswitch/email`, model)
      .then(() => {
        onComplete();
      })
      .catch(error => {
        onError(error);
      });

    dispatch(setAjaxCallEnd());
  };
}

// --------------------------------------------------------------------------
// Performs an unathenticated energy comparison and directs to switch page with selected offer.
// --------------------------------------------------------------------------
export function directEnergySwitch(serviceType, estimate, autoEstimatesArgs, onComplete, onError) {
  return dispatch => {
    const energyEstimate = { estimate: { ...{ ...estimate, serviceType: serviceType.Code } } };
    const options = {
      showSpinner: true,
      spinnerContext: contextTypes.DIRECT_SWITCH,
      applySleep: false,
      defaultComparison: false,
      loadingType: serviceCardLoadingTypes.DEFAULT,
      autoEstimatesArgs
    };

    dispatch(submitEstimateUnathenticated(serviceType, energyEstimate, options, onComplete, onError));
  };
}

// --------------------------------------------------------------------------
// Submit default energy estimate action
// --------------------------------------------------------------------------
export function submitEstimateEnergyDefault(
  serviceType,
  selectedAddress,
  selectedAgent,
  sessionActivity,
  submissionCommon,
  submissionOptions,
  onComplete,
  onError
) {
  const estimate = {
    property: {
      address: { ...selectedAddress },
      occupancyDate: null,
      occupancyType: occupancyTypes.CURRENT.Id,
      occupancyPurpose: occupancyPurposes.RESIDENTIAL.Id
    },
    ...submissionCommon,
    sessionActivity,
    agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
    currentProviderCode: null,
    usageLevel: usageTypes.MEDIUM.Id,
    solarLevel: solarTypes.NONE.Id,
    recentBill: false,
    billStartDateStandardised: null,
    billEndDateStandardised: null,
    usage: null,
    solarFeedIn: null,
    hasDemand: false,
    meterIdentifier: null,
    defaultComparison: true
  };

  const energyEstimate = { estimate: { ...{ ...estimate, serviceType: serviceType.Code } } };
  const options = { applySleep: false, defaultComparison: true, ...submissionOptions };

  return submitEstimateUnathenticated(serviceType, energyEstimate, options, onComplete, onError);
}

// --------------------------------------------------------------------------
// Submit default internet estimate action
// --------------------------------------------------------------------------
export function submitEstimateInternetDefault(
  selectedAddress,
  selectedAgent,
  sessionActivity,
  submissionCommon,
  currentSpend,
  techType,
  connectionType,
  developmentCharges,
  submissionOptions,
  onComplete,
  onError
) {
  const estimate = {
    ...submissionCommon,
    sessionActivity,
    address: selectedAddress,
    occupancyDate: null,
    occupancyType: occupancyTypes.CURRENT.Id,
    agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
    serviceType: billTypes.INTERNET.Id,
    techType: techType,
    connectionType: connectionType,
    developmentCharges: developmentCharges,
    currentSpend,
    downloadSpeed: internetDownloadSpeedTypes.ALL.Id,
    dataUnlimited: false,
    defaultComparison: true
  };

  const internetEstimate = { estimate: { ...{ ...estimate, serviceType: billTypes.INTERNET.Code } } };
  const options = { applySleep: false, defaultComparison: true, ...submissionOptions };

  return submitEstimateUnathenticated(billTypes.INTERNET, internetEstimate, options, onComplete, onError);
}

// --------------------------------------------------------------------------
// Submit default home-loan estimate action
// --------------------------------------------------------------------------
export function submitEstimateHomeLoanDefault(
  selectedAddress,
  selectedAgent,
  sessionActivity,
  submissionCommon,
  selectedLoanAmount,
  selectedMonthlyRepayment,
  submissionOptions
) {
  const estimate = {
    propertyAddress: { ...selectedAddress },
    ...submissionCommon,
    sessionActivity,
    agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
    propertyValue: selectedLoanAmount * 1.2, // start with 20% above loan amount
    currentLoanAmount: selectedLoanAmount,
    monthlyRepayment: selectedMonthlyRepayment,
    providerCode: null,
    propertyType: loanPropertyTypes.OWNER_OCCUPIED.value,
    loanType: loanTypes.FIXED.value,
    repaymentType: repaymentTypes.PRINCIPAL_INTEREST.value,
    newLoanAmount: selectedLoanAmount,
    loanPeriod: 30,
    splitValue: null,
    refinance: true,
    serviceType: billTypes.HOME_LOAN.Id,
    defaultComparison: true
  };

  const homeLoanEstimate = { estimate: { ...{ ...estimate, serviceType: billTypes.HOME_LOAN.Code } } };
  const options = { applySleep: false, defaultComparison: true, ...submissionOptions };

  const ignoreCompleted = () => {};
  const ignoreError = () => {};

  return submitEstimateUnathenticated(billTypes.HOME_LOAN, homeLoanEstimate, options, ignoreCompleted, ignoreError);
}

// --------------------------------------------------------------------------
// Submit estimate (un-authenticated) action
// --------------------------------------------------------------------------
export function submitEstimateUnathenticated(serviceType, data, options, onComplete, onError) {
  const start = Date.now();

  const polling = (dispatch, responseData, start) => {
    dispatch(getComparisonUnauthenticated({ ...data, ...responseData }, start, options, onComplete, onError));
  };

  return submitEstimate(
    serviceType,
    `${serviceType.ApiPath}/estimate`,
    data.estimate,
    start,
    options,
    polling,
    onComplete,
    onError
  );
}

// ----------------------------------------------------------------------------
// Submit estimate using an access token issued by a known authentication provider.
// ----------------------------------------------------------------------------
export function submitEstimateWithToken(accessToken, serviceType, data, options, onComplete, onError) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  const path = `${serviceType.ApiPath}/estimate/token/submit`;
  const start = Date.now();

  const polling = (dispatch, responseData, start) => {
    dispatch(getComparisonWithToken({ ...data, ...responseData }, start, options, onComplete, onError, config));
  };

  return submitEstimate(serviceType, path, data.estimate, start, options, polling, onComplete, onError, config);
}

// --------------------------------------------------------------------------
// Submit estimate (with key) action
// --------------------------------------------------------------------------
export function submitEstimateWithKey(serviceType, data, options, onComplete, onError) {
  const accessKey = data.access.key;
  const path = `${serviceType.ApiPath}/estimate/${data.access.id}?key=${accessKey}`;
  const start = Date.now();

  const polling = (dispatch, responseData, start) => {
    // if the access key has been cleared in the response, we need to treat as unauthenticated (customer has changed from original access key)
    if (responseData.accessKey) {
      dispatch(getComparisonWithKey({ ...data, ...responseData }, start, options, onComplete, onError));
    } else {
      dispatch(getComparisonUnauthenticated({ ...data, ...responseData }, start, options, onComplete, onError));
    }
  };

  return submitEstimate(serviceType, path, data.estimate, start, options, polling, onComplete, onError);
}

function hasFilteredOffers(comparison) {
  var hasOffers = comparison.hasOffers;

  switch (comparison.submission.billType) {
    case billTypes.INTERNET.Code:
      hasOffers = hasOffers && getFilteredInternetOffers(comparison.submission, comparison.offers).length > 0;
      break;
    default:
  }

  return hasOffers;
}

function comparisonCompleted(updateComparison, data, sessionActivity, options) {
  return dispatch => {
    if (data) {
      if (updateComparison) {
        dispatch(setComparisonAccessKey(data));
        dispatch(setComparison(data));
      }

      const agentMember = data.agentMember;
      const user = data.user;

      if (agentMember) {
        dispatch(setSelectedAgentSuccess({ ...agentMember, isLoaded: true }));
      }

      if (user) {
        dispatch(setUser({ ...user, loaded: true }));
      }

      const autoEstimates =
        options &&
        options.serviceCardLoading !== serviceCardLoadingTypes.NONE &&
        options.autoEstimatesArgs &&
        data.services;

      if (autoEstimates) {
        dispatch(setUserBills(data.services));
        dispatch(initAutoEstimates(options.autoEstimatesArgs, data, sessionActivity));
      }

      dispatch(setUserBill(data));
    }
  };
}

// --------------------------------------------------------------------------
// Submit estimate action
// --------------------------------------------------------------------------
function submitEstimate(serviceType, path, estimate, start, options, polling, onComplete, onError, config) {
  return dispatch => {
    if (options.showSpinner) {
      dispatch(setAjaxCallStart(options.spinnerContext || contextTypes.ESTIMATE_COMPARISON, serviceType));
    }

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'submit', 'estimate');
    });

    axios
      .post(`/api/compare/${path}`, estimate, config)
      .then(response => {
        const data = response.data;

        if (data) {
          // Homeloans has a multi-step dialog which needs to complete before we update the comparison;
          // otherwise the comparison screen could re-render in the second step and re-init the whole screen.
          // Additional moving house comprisons should also not update comparison for the same reason.
          const optionsUpdateComparison = options.updateComparison === undefined || options.updateComparison === true;
          const forceUpdate = options.updateComparison === true; // for direct switch initiated default comparisons, we need to be able to force an update.

          // We do not want to keep updating the comparison data for results coming back for auto-estimates - otherwise the compare page is updated with the last auto-estimate to run.
          const updateComparison = forceUpdate || (!estimate.defaultComparison && optionsUpdateComparison);

          // Check if the comparison is ready, or polling is required.
          if (data.comparison) {
            const fn = () => {
              dispatch(comparisonCompleted(updateComparison, data, estimate.sessionActivity, options));
            };
            if (options.applySleep) sleep(start, 'Estimate Ready').then(fn());
            else fn();
          } else {
            polling(dispatch, response.data, start);
            return;
          }
        }

        if (options.showSpinner) {
          dispatch(setAjaxCallEnd());
        }

        if (onComplete) {
          var hasOffers = data ? hasFilteredOffers(data.comparison) : false;
          var hasBetterOffers = data && data.comparison && data.comparison.hasBetterOffers ? true : false;
          var userBill = data ? getUserBill(data) : null;
          var referenceNumber = data && data.comparison ? data.comparison.referenceNumber : null;

          onComplete({ hasOffers, hasBetterOffers, referenceNumber }, userBill);
        }
      })
      .catch(error => {
        if (onError) onError(error);

        if (options.showSpinner) {
          dispatch(setAjaxCallEnd());
        }
      });
  };
}

// --------------------------------------------------------------------------
// Get estimate comparison (with key) action
// --------------------------------------------------------------------------
function getComparisonWithKey(data, start, options, onComplete, onError) {
  const path = `${data.access.id}/${data.requestKey}?key=${data.access.key}`;
  const sessionActivity = data.sessionActivity;

  return getComparison(path, start, sessionActivity, options, onComplete, onError);
}

// --------------------------------------------------------------------------
// Get estimate comparison (with token) action
// --------------------------------------------------------------------------
function getComparisonWithToken(data, start, options, onComplete, onError, config) {
  const path = `/token?token=${data.requestKey}`;
  const sessionActivity = data.sessionActivity;

  return getComparison(path, start, sessionActivity, options, onComplete, onError, config);
}

// --------------------------------------------------------------------------
// Get estimate comparison (un-authenticated) action
// --------------------------------------------------------------------------
function getComparisonUnauthenticated(data, start, options, onComplete, onError) {
  const path = `?token=${data.requestKey}`;
  const sessionActivity = data.estimate.sessionActivity;

  return getComparison(path, start, sessionActivity, options, onComplete, onError);
}

// --------------------------------------------------------------------------
// Get estimate comparison action
// --------------------------------------------------------------------------
function getComparison(path, start, sessionActivity, options, onComplete, onError, config) {
  return dispatch => {
    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'get', 'comparison');
    });

    let timer = 0;
    let attempts = 0;

    const attemptIntervals = [3000, 1000];
    const attemptLimit = getAttemptCount(attemptIntervals, 120);

    let fn = () => {
      axios
        .get(`/api/compare/estimate/${path}`, config)
        .then(response => {
          clearTimeout(timer);
          timer = 0;

          // Homeloans has a multi-step dialog which needs to complete before we update the comparison;
          // otherwise the comparison screen could re-render in the second step and re-init the whole screen.
          // Additional moving house comprisons should also not update comparison for the same reason.
          const optionsUpdateComparison = options.updateComparison === undefined || options.updateComparison === true;
          const forceUpdate = options.updateComparison === true; // for direct switch initiated default comparisons, we need to be able to force an update.

          // We do not want to keep updating the comparison data for results coming back for auto-estimates - otherwise the compare page is updated with the last auto-estimate to run.
          const updateComparison = forceUpdate || (!options.defaultComparison && optionsUpdateComparison);

          const fn = () => {
            const data = response.data;

            dispatch(comparisonCompleted(updateComparison, data, sessionActivity, options));

            if (options.showSpinner) {
              dispatch(setAjaxCallEnd());
            }

            if (onComplete) {
              var hasOffers = data ? hasFilteredOffers(data.comparison) : false;
              var hasBetterOffers = data && data.comparison && data.comparison.hasBetterOffers ? true : false;
              var userBill = data ? getUserBill(data) : null;
              var referenceNumber = data && data.comparison ? data.comparison.referenceNumber : null;

              onComplete({ hasOffers, hasBetterOffers, referenceNumber }, userBill);
            }
          };

          if (options.applySleep) sleep(start, 'Estimate Polling').then(fn());
          else fn();
        })
        .catch(error => {
          const notReady = error.response && error.response.status === 404;

          if (notReady && attempts < attemptLimit) {
            const maxIntervals = attemptIntervals.length - 1;
            const interval = attempts <= maxIntervals ? attemptIntervals[attempts] : attemptIntervals[maxIntervals];
            attempts++;
            clearTimeout(timer);
            timer = setTimeout(fn, interval);
          } else {
            clearTimeout(timer);
            timer = 0;
            if (onError) onError(error);

            if (options.showSpinner) {
              dispatch(setAjaxCallEnd());
            }
          }
        });
    };

    fn();
  };
}

// --------------------------------------------------------------------------
// Set comparison access key action
// --------------------------------------------------------------------------
function setComparisonAccessKey(data) {
  const accessId = data.accessKey ? data.accessKey.accessId : null;
  const accessKey = data.accessKey ? data.accessKey.accessKey : null;
  const accessTrustLevel = data.accessKey ? data.accessKey.accessTrustLevel : null;

  return dispatch =>
    dispatch(
      setComparisonSuccess({
        accessId,
        accessKey,
        accessTrustLevel
      })
    );
}

// --------------------------------------------------------------------------
// Set comparison action
// --------------------------------------------------------------------------
function setComparison(data) {
  return dispatch => {
    const userBill = data.userBill;

    dispatch(
      setComparisonSuccess({
        ...data.comparison,
        submissionId: userBill.submissionId,
        sorting: data.sorting,
        isLoaded: true,
        resubmitTimestamp: Date.now() // Inidicates that the estimate dialog has been re-submitted by the user.
      })
    );
  };
}
