import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { billTypes } from '../../enums/billTypes';

import ServiceCardSample from './serviceCardSample';

const ServiceCardStack = ({ cardSize }) => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const hasElectricity = billTypes.hasServiceType(agentBillTypes, billTypes.ELECTRICITY);
  const hasGas = billTypes.hasServiceType(agentBillTypes, billTypes.GAS);
  const hasInternet = billTypes.hasServiceType(agentBillTypes, billTypes.INTERNET);
  const hasHomeLoan = billTypes.hasServiceType(agentBillTypes, billTypes.HOME_LOAN);
  const hasVehicleInsurance = billTypes.hasServiceType(agentBillTypes, billTypes.VECHICLE_INSURANCE);
  const hasHomeInsurance = billTypes.hasServiceType(agentBillTypes, billTypes.HOME_AND_CONTENTS_INSURANCE);
  const hasHealthInsurance = billTypes.hasServiceType(agentBillTypes, billTypes.HEALTH_INSURANCE);

  const sampleServices = [];
  const cardCount = 3;

  const getItem = (index, serviceType) => {
    switch (index) {
      case 0:
        sampleServices.push({ label: 'first', isEmpty: false, serviceType });
        break;
      case 1:
        sampleServices.push({ label: 'second', isEmpty: true, serviceType });
        break;
      case 2:
        sampleServices.push({ label: 'third', isEmpty: true, serviceType });
        break;
      default:
        break;
    }

    return index + 1;
  };

  for (let i = 0; i < cardCount; ) {
    if (hasElectricity && i < cardCount) i = getItem(i, billTypes.ELECTRICITY);
    if (hasGas && i < cardCount) i = getItem(i, billTypes.GAS);
    if (hasVehicleInsurance && i < cardCount) i = getItem(i, billTypes.VECHICLE_INSURANCE);
    if (hasHomeLoan && i < cardCount) i = getItem(i, billTypes.HOME_LOAN);
    if (hasInternet && i < cardCount) i = getItem(i, billTypes.INTERNET);
    if (hasHomeInsurance && i < cardCount) i = getItem(i, billTypes.HOME_AND_CONTENTS_INSURANCE);
    if (hasHealthInsurance && i < cardCount) i = getItem(i, billTypes.HEALTH_INSURANCE);
  }

  return (
    <div className={`sm-service-card-stacked${cardSize ? ` ${cardSize}` : ''} ${isMobile ? ' mobile' : ''}`}>
      {sampleServices.map(item => (
        <div key={`service_card_stacked_item_${item.label}`} className={item.label}>
          <ServiceCardSample serviceType={item.serviceType} isEmpty={item.isEmpty} cardSize={cardSize} />
        </div>
      ))}
    </div>
  );
};

export default ServiceCardStack;
