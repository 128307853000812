import React from 'react';
import OrganiserName from './organiserName';

const OrganiserDescription = ({
  providerDisplayCode,
  emailAddress,
  paragraphClassName,
  preText,
  postText,
  shortVersion
}) => {
  return (
    <>
      <p className={paragraphClassName}>
        {preText}We enable your <OrganiserName nameOnly /> by providing {providerDisplayCode} with a new '
        <OrganiserName nameOnly /> email address' which we link to your personal email address. Your bills and all other
        correspondence sent to your <OrganiserName nameOnly /> email address (from {providerDisplayCode}) will be
        instantly relayed to your personal email address
        {emailAddress ? <span style={{ fontWeight: 'bold' }}> {emailAddress}</span> : ''}. Emails you respond to will
        also be relayed back to {providerDisplayCode}, meaning you don't need to access a new inbox to use this service
        and your current bill will always be available within your <OrganiserName /> dashboard.
      </p>

      {!shortVersion && (
        <p className={paragraphClassName}>
          Importantly, when communicating with {providerDisplayCode}, and when you register on their website or any
          apps, you'll need to use your <OrganiserName nameOnly /> email address that we'll share with them.{postText}
        </p>
      )}
    </>
  );
};
export default OrganiserDescription;
