import React from 'react';
import FaqQuestion from './faqQuestion';

const FaqUs = props => {
  const { panelProviders } = props;

  return (
    <>
      <FaqQuestion
        id="retailers"
        question="Which utility providers are considered in my comparison?"
        answer={
          <>
            We consider offers from leading utility providers to find and present the products that best aligns to your
            needs. These providers include:
            <ul className="qs-mt-md">
              {panelProviders.map(a => {
                return <li key={a.provider.displayCode}>{a.provider.displayCode}</li>;
              })}
            </ul>
            Services may not be available in government regulated service markets.
          </>
        }
      />

      <FaqQuestion
        question="Are there any parts of my bill not considered in the comparison?"
        answer={
          <>
            Comparison <span className="qs-strong">does not</span> take into account concessions, payment processing
            fees, paper bill fees, green energy contributions, government rebates, guaranteed service level credits or
            any balances carried forward from previous bill, unless otherwise stated. Comparison{' '}
            <span className="qs-strong">does</span> take in to account the number of days of energy usage, the amount of
            kWh or MJ usage, the metering type of the energy consumed, usage pricing steps, solar feed-in and any
            credits applied.
          </>
        }
      />

      <FaqQuestion
        question="Is this service free for consumers to use?"
        answer="Yes, this is a completely free service for consumers."
      />

      <FaqQuestion
        question="Will a credit check show up on my credit history?"
        answer="No. SmartMe’s partner SaveOnEnergy provide a 'soft credit check' services which ensures there is no update made
        to your credit history as part of any credit check outcome."
      />

      <FaqQuestion
        question="How does SmartMe make money?"
        answer="We’re paid a commission when you choose to switch your service. This is common place throughout the industry.
        The commission we are paid by utility providers is an economical way for them to attract new customers, and in many
        cases costs less than their own advertising or sales activity."
      />

      <FaqQuestion
        question="What happens after I accept a deal?"
        answer="Once submitted, we will send your application to your chosen utility provider. They will either send you a welcome pack
        or call you if they have any questions regarding your request."
      />

      <FaqQuestion
        question="Is my information private?"
        answer="We take your privacy seriously. We only use your bill and personal information to compare your bill usage with
        other offers in the market and for the purposes of providing our services. We don't share your personal data
        with any third parties outside of what is required to deliver our services. Those 3rd parties are contractually
        obligated to ensure your data remains private at all times. Bill information we save in our own systems is
        separated from personal identification information. For more information, see our Privacy Policy on this
        website."
      />

      <FaqQuestion
        question="Is my information secure?"
        answer="SmartMe uses secure encryption and security best practice to ensure our customer's information is kept secure."
      />

      <FaqQuestion
        question="Can I opt-out of this service?"
        answer="Yes. You simply need to contact your current energy provider and change the email address your bills are sent
        to. But remember, if you do this, we will no longer be able to provide our services."
      />
    </>
  );
};
export default FaqUs;
