import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import BillReductionDetail from './billReductionDetail';
import ApproximateChargesDisclaimer from '../disclaimers/approximateCharges';
import { scroller } from 'react-scroll';
import InfoPopover from './infoPopover';
import { submissionTypes } from '../../enums/submissionTypes';
import { useSelector } from 'react-redux';

const OfferEnergyAu = ({ offer, comparison, mode, onMoreInfo, offerIndex }) => {
  const { taxIndicator } = useSelector(state => state.content.general);
  const { programDisplayName } = useSelector(state => state.config);

  const [billSavingDisplay, setBillSavingDisplay] = useState(
    offer.savingAmountLowest <= 0 ? '' : `(${offer.percentageBillReductionAmountLowestDisplay})`
  );
  const [billSavingDisplayClass, setBillSavingDisplayClass] = useState('');

  let billSavingDisplayIndex = 0;
  useEffect(() => {
    if (offer.savingAmountLowest <= 0) return;

    const billSavingTimer = setInterval(() => {
      var displayVal =
        billSavingDisplayIndex % 2 === 1
          ? `(${offer.percentageBillReductionAmountLowestDisplay})`
          : `(${offer.savingAmountLowestDisplay} less)`;

      setBillSavingDisplay(displayVal);
      setBillSavingDisplayClass('animated fadeIn slow');

      setTimeout(() => {
        setBillSavingDisplayClass('');
      }, 3000);

      billSavingDisplayIndex++;
    }, 5000);

    return () => clearInterval(billSavingTimer);
  }, []);

  const onGotoPricingDocument = (event, offer) => {
    event.preventDefault();

    onMoreInfo(event, offer, true);

    setTimeout(
      () =>
        scroller.scrollTo(`pricing-document${offer.id}`, {
          duration: 1200,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -20
        }),
      100
    );
  };

  return (
    <>
      <Row>
        <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }} lg={{ size: 8, order: 1 }}>
          <Container style={{ margin: 0, padding: 0 }}>
            <Row noGutters={true}>
              <Col xs="12" md="12">
                {offer.hasMarketComparison && (
                  <div id={`offer_disclaimer_${offerIndex}`} className="qs-offer-market-disclaimer-heading">
                    {offer.marketOfferDisclaimerHeading && (
                      <div className="qs-offer-bill-reduction-text">{offer.marketOfferDisclaimerHeading}</div>
                    )}

                    {offer.marketOfferConditionalDiscounts.hasPayOnTimeDiscount && (
                      <div className="qs-offer-bill-reduction-sub-text">
                        {offer.marketOfferConditionalDiscounts.payOnTimeDiscountDisplay}
                      </div>
                    )}

                    {offer.marketOfferConditionalDiscounts.hasDirectDebitDiscount && (
                      <div className="qs-offer-bill-reduction-sub-text">
                        {offer.marketOfferConditionalDiscounts.directDebitDiscountDisplay}
                      </div>
                    )}

                    {offer.marketOfferConditionalDiscounts.hasEBillingDiscount && (
                      <div className="qs-offer-bill-reduction-sub-text">
                        {offer.marketOfferConditionalDiscounts.eBillingDiscountDisplay}
                      </div>
                    )}

                    {offer.marketOfferConditionalDiscounts.hasMembershipDiscount && (
                      <div className="qs-offer-bill-reduction-sub-text">
                        {offer.marketOfferConditionalDiscounts.membershipDiscountDisplay}
                      </div>
                    )}

                    {offer.marketOfferConditionalDiscounts.hasOtherDiscount && (
                      <div className="qs-offer-bill-reduction-sub-text">
                        {offer.marketOfferConditionalDiscounts.otherDiscountDisplay}
                      </div>
                    )}

                    {offer.marketOfferConditionalDiscounts.hasCreditDiscount && (
                      <div className="qs-offer-bill-reduction-sub-text">
                        {offer.marketOfferConditionalDiscounts.creditDiscountDisplay}
                      </div>
                    )}

                    {offer.marketOfferDisclaimerTextOne && (
                      <div className="qs-offer-market-disclaimer">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: offer.marketOfferDisclaimerTextOne
                          }}
                        />

                        <InfoPopover
                          idType="reference-price-info"
                          heading={comparison.referencePriceName}
                          body={comparison.referencePriceDisclaimer}
                          action="reference-price-info-popover"
                          offer={offer}
                        />
                      </div>
                    )}

                    {offer.marketOfferDisclaimerTextTwo && (
                      <div className="qs-offer-market-disclaimer qs-mt-sm">{offer.marketOfferDisclaimerTextTwo}</div>
                    )}
                  </div>
                )}

                {offer.submissionType === submissionTypes.BILL && (
                  <div id={`offer_pricing_${offerIndex}`}>
                    <div className={offer.hasMarketComparison ? 'qs-offer-heading qs-mt-md' : 'qs-offer-heading'}>
                      Your approx. charges using this product based on the same {comparison.submission.billDays} day
                      period
                    </div>
                    <div className="qs-offer-saving-percentage">
                      <div className="qs-offer-bill-reduction-text">
                        {offer.billAmountLowestDisplay} <div className="qs-offer-tax-indicator">{taxIndicator}</div>{' '}
                        <div className={`qs-offer-bill-reduction-main-sub-text ${billSavingDisplayClass}`}>
                          {comparison.submissionType === submissionTypes.BILL ? billSavingDisplay : ''}
                          <InfoPopover
                            idType="bill-reduction-plan-info"
                            heading="Detail"
                            body={
                              <BillReductionDetail
                                offer={offer}
                                bill={comparison.submission}
                                programDisplayName={programDisplayName}
                                submissionType={comparison.submissionType}
                              />
                            }
                            action="bill-reduction-info-popover"
                            offer={offer}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {offer.submissionType === submissionTypes.ESTIMATE && (
                  <div id={`offer_pricing_${offerIndex}`}>
                    <Row>
                      <Col xs="4">
                        <div className={offer.hasMarketComparison ? 'qs-offer-heading qs-mt-md' : 'qs-offer-heading'}>
                          Estimated cost
                          <InfoPopover
                            idType="approx-charges-info"
                            heading="Estimated cost"
                            body={
                              <ApproximateChargesDisclaimer
                                disclaimer={offer.estimateMonthlyDisclaimer}
                                additionalDisclaimer={`This monthly estimate is calculated on the basis of ${offer.estimateDays} days.`}
                              />
                            }
                            eventName="approx-charges-info-popover"
                            offer={offer}
                          />
                        </div>
                        <div className="qs-offer-saving-percentage">
                          <div className="qs-offer-bill-reduction-text">
                            {offer.billAmountLowestDisplay}
                            <div className="qs-offer-estimate-period">/month</div>
                          </div>
                        </div>
                      </Col>
                      {offer.billAmountLowestAnnualDisplay && (
                        <Col xs="8">
                          <div className={offer.hasMarketComparison ? 'qs-offer-heading qs-mt-md' : 'qs-offer-heading'}>
                            Estimated cost
                            <InfoPopover
                              idType="approx-charges-info-annual"
                              heading="Estimated cost"
                              body={
                                <ApproximateChargesDisclaimer
                                  disclaimer={offer.estimateAnnuallyDisclaimer}
                                  additionalDisclaimer="This estimated cost represents the lowest estimated cost for the plan as provided by the retailer."
                                />
                              }
                              eventName="approx-annual-charges-info-popover"
                              offer={offer}
                            />
                          </div>
                          <div className="qs-offer-saving-percentage">
                            <div className="qs-offer-bill-reduction-text">
                              {offer.billAmountLowestAnnualDisplay}
                              <div className="qs-offer-estimate-period">/year</div>{' '}
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                )}

                {!offer.hasMarketComparison && (
                  <div
                    className="qs-summary-plan-info qs-summary-plan-info-main-area qs-mt-md"
                    dangerouslySetInnerHTML={{ __html: offer.info }}
                  />
                )}

                {mode === 2 && offer.pricingDocuments && offer.pricingDocuments.length > 0 && (
                  <div className="qs-offer-detail" style={{ marginTop: '10px' }}>
                    <a href="/" onClick={event => onGotoPricingDocument(event, offer)}>
                      {comparison.pricingDocumentNameShort}
                    </a>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </Col>
        <Col
          xs={{ size: 12, order: 1 }}
          md={{ size: 5, order: 2 }}
          lg={{ size: 4, order: 2 }}
          className="qs-provider-logo"
        >
          <Container>
            <Row>
              <Col xs="6" md="12">
                <img src={offer.providerLogoUrl} alt={offer.providerDisplayCode} />
                {/* {offer.indicator === 'sponsor' && <div className="qs-sponsor">Sponsored</div>} */}
              </Col>
              <Col xs="6" md="12">
                <div className="qs-summary-plan-name">{offer.name}</div>
              </Col>
            </Row>
          </Container>

          {offer.hasMarketComparison && (
            <div
              className="qs-summary-plan-info"
              dangerouslySetInnerHTML={{
                __html: offer.info
              }}
            />
          )}

          {offer.planTags && offer.planTags.length > 0 && (
            <div className="qs-offer-plan-tags">
              {offer.planTags.map((tag, index) => (
                <div
                  key={`${tag}-${index}`}
                  className="qs-offer-plan-tag"
                  dangerouslySetInnerHTML={{
                    __html: tag
                  }}
                />
              ))}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default OfferEnergyAu;
