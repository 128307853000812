import React from 'react';
import PropTypes from 'prop-types';
import { AvField } from 'availity-reactstrap-validation';

const QsButtonGroup = ({
  id,
  fieldName,
  onTouchButton,
  buttons,
  selectedValue,
  selectedValueSuffix,
  additionalClass,
  required,
  enabled: userEnabled,
  hasChanges
}) => {
  const enabled = userEnabled === undefined || userEnabled === true;

  let hasSelection = false;

  let theButtons = buttons.map(function(button, i) {
    const isSelected = selectedValue === button.value;

    if (isSelected) {
      hasSelection = true;
    }

    return (
      <div
        key={i}
        id={`${id}-${i}`}
        className={`qs-button-group-item${isSelected ? ' qs-button-group-item-selected' : ''}${
          enabled ? '' : ' qs-button-group-item-disabled'
        }`}
        data-value={button.value}
        data-fieldname={fieldName}
      >
        {isSelected && (
          <div className={`qs-button-group-item-icon${enabled ? '' : ' qs-button-group-item-icon-disabled'}`}>
            <i className="material-icons" data-value={button.value} data-fieldname={fieldName}>
              done
            </i>
          </div>
        )}

        <div
          data-value={button.value}
          data-fieldname={fieldName}
          className={`qs-button-group-item-text${enabled ? '' : ' qs-button-group-item-text-disabled'}`}
        >
          {button.label}
          {isSelected && selectedValueSuffix ? ' ' + selectedValueSuffix : ''}
        </div>
      </div>
    );
  });

  return (
    <>
      <div
        id={id}
        className={`qs-button-group${additionalClass ? ` ${additionalClass}` : ''}`}
        onClick={enabled ? onTouchButton : null}
      >
        {theButtons}
      </div>
      {required && (
        <div className="qs-button-group-validation">
          <AvField
            type="text"
            name={id}
            value={selectedValue}
            className="qs-tc-hidden"
            validate={{
              hidden: (value, ctx, input, cb) => {
                if (enabled && hasChanges && !hasSelection) {
                  cb(`${fieldName} is required`);
                } else cb(true);
              }
            }}
          />
        </div>
      )}
    </>
  );
};

QsButtonGroup.propTypes = {
  id: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onTouchButton: PropTypes.func.isRequired,
  selectedValue: PropTypes.any,
  selectedValueSuffix: PropTypes.string,
  additionalClass: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  )
};

export default QsButtonGroup;
