import React from 'react';
import { Row, Col } from 'reactstrap';
import QsCheckBox from '../common/qsCheckBox';

const MarketingQuestions = ({ confirmDetail, productDetail, onChange }) => {
  return (
    <>
      <div className="qs-more-info-table-header label">Marketing</div>
      <Row>
        <Col xs="12" className="qs-mb-md">
          <QsCheckBox
            isChecked={confirmDetail.marketingOptOut}
            label={productDetail.marketingOptOutDescription}
            onCheck={e => onChange(e, { marketingOptOut: !confirmDetail.marketingOptOut })}
          />
        </Col>
      </Row>
    </>
  );
};

export default MarketingQuestions;
