import React, { useState, useEffect } from 'react';
import { AvField } from 'availity-reactstrap-validation';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap';
import { occupancyTypes } from '../../enums/submissionTypes';
import { getDistributorItem } from '../../actions/distributorActions';

const EnergyDistributor = ({
  serviceType,
  distributorSets,
  onSelectedDistributorChanged,
  distributor,
  occupancyType,
  showLabel
}) => {
  const [distributorDropDownOpen, setDistributorDropdownOpen] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState(distributor);

  useEffect(() => {
    setSelectedDistributor(distributor);
  }, [distributor]);

  // --------------------------------------------------------------------------
  // Distributor change handler.
  // --------------------------------------------------------------------------
  const onDistributorChange = name => {
    const distributorItem = getDistributorItem(distributorSets, serviceType);
    const item = distributorItem.find(item => item.name === name);

    setSelectedDistributor(item);

    onSelectedDistributorChanged(item);
  };

  // --------------------------------------------------------------------------
  // Distributors: Get DropdownItem element.
  // --------------------------------------------------------------------------
  const getDistributorDropdownItems = () => {
    const distributorItem = getDistributorItem(distributorSets, serviceType);

    if (distributorItem.length > 0) {
      return distributorItem.map(item => {
        const element = (
          <DropdownItem key={item.name} id={item.name} onClick={e => onDistributorChange(e.currentTarget.id)}>
            {item.name}
          </DropdownItem>
        );

        return element;
      });
    }
  };

  const distributorDropdownItems = getDistributorDropdownItems();
  const hasMultipleDistributors = distributorDropdownItems && distributorDropdownItems.length > 1;

  const rowClass = 'sm-dialog-row';
  const fieldClass = 'sm-dialog-field-name';
  const columnSizeLg = 12;

  return hasMultipleDistributors ? (
    <Collapse key={`energyDistributor_${serviceType.Code}`} isOpen={hasMultipleDistributors}>
      <div className={rowClass}>
        {showLabel && (
          <div className={fieldClass} lg={columnSizeLg}>
            Your {serviceType.Name.toLowerCase()} distributor
          </div>
        )}
        <div>
          <Dropdown
            isOpen={distributorDropDownOpen}
            toggle={() => setDistributorDropdownOpen(prevState => !prevState)}
            className="sm-dropdown"
          >
            <DropdownToggle caret>
              {(selectedDistributor && selectedDistributor.name) || 'Please Select'}
            </DropdownToggle>
            <DropdownMenu>{distributorDropdownItems}</DropdownMenu>
          </Dropdown>

          {occupancyType === occupancyTypes.CURRENT.Id && (
            <div className="qs-energy-estimate-dialog-disclaimer mt-2">You can find this information on your bill.</div>
          )}
          <div className="qs-estimate-dialog-validation">
            <AvField
              type="text"
              name={`distributor_validation_${serviceType.Code}`}
              value={selectedDistributor ? selectedDistributor.selectionCode : null}
              className="qs-tc-hidden"
              validate={{
                hidden: (value, ctx, input, cb) => {
                  if (hasMultipleDistributors && !selectedDistributor) {
                    cb('Please select a distributor');
                  } else cb(true);
                }
              }}
            />
          </div>
        </div>
      </div>
    </Collapse>
  ) : null;
};

export default EnergyDistributor;
