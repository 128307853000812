import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QsButtonGroup from './qsButtonGroup';
import { hasConditionalDiscounts } from '../../utilities/billUtilities';
import { comparisonSorting, getComparisonSortingById } from '../../enums/comparisonSorting';

const OfferSorterEnergyAu = props => {
  const { onSort, sort, offers } = props;

  const invokeSort = event => {
    let option = event.target.getAttribute('data-value');
    let sortType = getComparisonSortingById(option);

    onSort(option, sortType.Name);
  };

  return (
    <>
      {hasConditionalDiscounts(offers) && (
        <Container>
          <Row>
            <Col className="qs-mb-lg">
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={sort}
                onTouchButton={invokeSort}
                additionalClass="qs-button-group-small"
                buttons={[
                  {
                    label: 'Sort by best guaranteed pricing',
                    value: comparisonSorting.GUARANTEED.Id.toString()
                  },
                  {
                    label: 'Sort by best overall including conditional discounts',
                    value: comparisonSorting.CONDITIONAL.Id.toString()
                  }
                ]}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

OfferSorterEnergyAu.propTypes = {
  offers: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired
};

export default OfferSorterEnergyAu;
