import React from 'react';
import PropTypes from 'prop-types';

const ApproximateChargesDisclaimer = ({ disclaimer, additionalDisclaimer }) => {
  return (
    <div>
      <p>
        {disclaimer} {additionalDisclaimer} The approximate charges include only the usage and supply charges related to
        this plan.
      </p>
      <p>
        Any other fees or charges applied by the retailer are not included in the approximate charges. This may include,
        but is not limited to, any fees associated to payment processing, connection / disconnection, dishonoured
        payments or paper bill fees. Please see the more information section for this plan to see details related to any
        additional fees.
      </p>
      <p>
        Please note: if a retailer charges connection fees they are typically only charged for moving in to a property.
      </p>
    </div>
  );
};

ApproximateChargesDisclaimer.propTypes = {
  disclaimer: PropTypes.string.isRequired
};

export default ApproximateChargesDisclaimer;
