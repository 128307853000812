import React from 'react';
import CertifiedLogo from './certifiedLogo';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { billTypes } from '../../enums/billTypes';

const Next = ({ startService }) => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const serviceTypeOptions = { includeExternal: false };
  const hasSolarFinance = billTypes.hasServiceType(agentBillTypes, billTypes.SOLAR_FINANCE, serviceTypeOptions);

  return (
    <div className="sm-start-next">
      <div className="content-wrapper">
        <div className="description">
          <div className="title">What happens next?</div>
          <div className="content">
            We'll connect you with a solar finance expert who we've trained to be able to explain your options. They'll
            call you for a no-obligation, free discussion about your finance options. If you'd like to proceed with any
            of the finance options, they'll also do the leg work for you to make applying easy!
          </div>
          {hasSolarFinance && (
            <div className="buttons">
              <Button onClick={startService} className="sm-button-primary medium">
                Continue
              </Button>
            </div>
          )}
        </div>
        <div className="certified-logo">
          <CertifiedLogo />
        </div>
      </div>
    </div>
  );
};
export default Next;
