import React from 'react';

const Rating = ({ onClick, rating }) => {
  return (
    <>
      <div className="sm-rating">
        <div className="header">
          <div className="left">Not at all satisified</div>
          <div className="right">Extremely satisified</div>
        </div>
        <div className="rating-options">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => (
            <div key={`rating-value-${value}`} className="rating-item">
              <span className="value">{value}</span>
              <div className="spacer" />

              <span className={`radio-circle-outer`} onClick={() => onClick(value)}>
                <span className={`radio-circle${rating === value ? ' selected' : ''}`} onClick={() => onClick(value)} />{' '}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Rating;
