export const SET_APPLICATION = 'SET_APPLICATION';
export const SET_SESSION = 'SET_SESSION';
export const SET_COMPARISON = 'SET_COMPARISON';
export const SET_REGISTRATION = 'SET_REGISTRATION';
export const SET_USER = 'SET_USER';
export const SET_USER_BILLS = 'SET_USER_BILLS';
export const SET_USER_BILL = 'SET_USER_BILL';
export const DELETE_USER_BILL = 'DELETE_USER_BILL';
export const RESTORE_USER_BILL = 'RESTORE_USER_BILL';
export const CONVERT_USER_BILL = 'CONVERT_USER_BILL';
export const SET_BILL_LOADING = 'SET_BILL_LOADING';
export const SET_SELECTED_OFFER = 'SET_SELECTED_OFFER';
export const SET_ACCEPTED_OFFER = 'SET_ACCEPTED_OFFER';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_DIALOG_OPTIONS = 'SET_DIALOG_OPTIONS';
export const SET_GLOBAL_OPTIONS = 'SET_GLOBAL_OPTIONS';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_SELECTED_AGENT = 'SET_SELECTED_AGENT';
export const SET_CONFIRM_DETAIL = 'SET_CONFIRM_DETAIL';
export const HARD_SET_CONFIRM_DETAIL = 'HARD_SET_CONFIRM_DETAIL';
export const SET_AJAX_CALL_START = 'SET_AJAX_CALL_START';
export const SET_AJAX_CALL_END = 'SET_AJAX_CALL_END';
export const SET_SERVER_MESSAGE = 'SET_SERVER_MESSAGE';
export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY';
export const SET_TERMS_AND_CONDITIONS = 'SET_TERMS_AND_CONDITIONS';
export const SET_CONCIERGE_TERMS_AND_CONDITIONS = 'SET_CONCIERGE_TERMS_AND_CONDITIONS';
export const SET_LANDING = 'SET_LANDING';
export const SET_DIRECT_SWICTH = 'SET_DIRECT_SWITCH';
export const SET_FRAME_PARTNER = 'SET_FRAME_PARTNER';
export const SET_SIGNALR = 'SET_SIGNALR';
export const SET_ARTICLES = 'SET_ARTICLES';
export const SET_ARTICLE = 'SET_ARTICLE';
export const SET_SURVEY = 'SET_SURVEY';
