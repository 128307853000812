import initialState from '../reducers/initialState';
import * as types from './actions';
import { directSwitchDialogOptions, openSubmitDialog } from './dialogOptionsActions';
import { setUser } from './userActions';
import { goHome, setComparison } from './comparisonActions';
import { billTypes } from '../enums/billTypes';
import { uploadSubTypes } from '../enums/uploadBillTypes';
import { operatorModes } from '../enums/launchTypes';

export function setLandingSuccess(landing) {
  return { type: types.SET_LANDING, data: { ...landing } };
}

export function setLanding(landing) {
  return dispatch => {
    dispatch(setLandingSuccess(landing));
  };
}

export function resetLanding() {
  return dispatch => {
    dispatch(setLandingSuccess(initialState.landing));
  };
}

export function launchSubmitDialog(serviceType, uploadBillType, args, options) {
  return dispatch => {
    const termsAndConditionsAccepted =
      (args.termsAndConditions &&
        (args.termsAndConditions === true || args.termsAndConditions.toLowerCase() === 'true')) ||
      options.operatorMode === operatorModes.DEFAULT
        ? true
        : false;

    const ccd = args.ccd && (args.ccd === true || args.ccd.toLowerCase() === 'true');

    const landing = {
      home: { serviceType, ...options },
      comparison: {
        emailAddress: args.emailAddress,
        firstName: args.firstName,
        surname: args.surname,
        mobilePhoneNumber: args.mobilePhoneNumber,
        operatorId: args.operatorId,
        ccd
      }
    };

    if (args.occupancyType) {
      landing.comparison.occupancyType = args.occupancyType;
    }

    dispatch(setLanding(landing));
    dispatch(openSubmitDialog(serviceType, uploadBillType, uploadSubTypes.LAUNCH));
    dispatch(applyDialogArgs({ ...args, termsAndConditionsAccepted }));
  };
}

export function launchDirectSwitchDialog(options) {
  return dispatch => {
    const landing = {
      home: { serviceType: billTypes.NONE, ...options },
      comparison: {}
    };

    dispatch(setLanding(landing));
    dispatch(directSwitchDialogOptions(true, uploadSubTypes.LAUNCH));
    dispatch(goHome());
  };
}

const applyDialogArgs = args => {
  return dispatch => {
    if (args.emailAddress) {
      dispatch(setUser({ emailAddress: args.emailAddress }));
    }

    if (args.termsAndConditionsAccepted) {
      const comparsion = { termsAndConditionsAccepted: true };

      dispatch(setComparison(comparsion));
    }

    dispatch(goHome());
  };
};
