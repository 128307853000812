import React from 'react';
import { useSelector } from 'react-redux';
import { implementationCodes } from '../../enums/implementationCodes';

const OfferMarketingDisplay = ({ bestOffer, submission, implementationCode, mode }) => {
  const offer = bestOffer || {};

  const {
    hasSavingToHighlight,
    percentageBillReductionAmountToHighlightDisplayShort,
    billAmountGuaranteedDisplay,
    billAmountLowestDisplay,
    estimateDays,
    estimateDailyUsageDisplay,
    estimateMonthlyDisclaimer,
    customerEnergyChargeAverage,
    isBill,
    specialOffer
  } = offer;

  const { billDays, energyTypeDisplay, percentagePeakDisplay, percentageOffPeakDisplay } = submission;

  const { taxIndicator } = useSelector(state => state.content.general);

  const disclaimerClass = mode === 'ServiceCard' ? 'disclaimer' : 'qs-marketing-info-offer-details-disclaimer';
  const amountClass = mode === 'ServiceCard' ? 'amount with-reduction' : 'qs-marketing-info-offer-details';
  const percentageClass = mode === 'ServiceCard' ? '' : 'qs-marketing-info-offer-details-percentage-reduction';

  const getContent = implementationCode => {
    switch (implementationCode) {
      case implementationCodes.ELECTRICITY.US_DEFAULT:
        return (
          <>
            <div className={amountClass}>{customerEnergyChargeAverage.estimatedChargeDisplay}</div>
            <div className={disclaimerClass}>
              monthly bill estimate for {customerEnergyChargeAverage.usage} {energyTypeDisplay}
              <br />({customerEnergyChargeAverage.averagePricePerKwhDisplay}c per {submission.energyTypeDisplay})
            </div>
          </>
        );
      case implementationCodes.ELECTRICITY.AU_DEFAULT:
      case implementationCodes.GAS.AU_DEFAULT:
        return (
          <>
            <div className={amountClass}>{billAmountLowestDisplay}</div>
            <div className={disclaimerClass}>
              {isBill && hasSavingToHighlight && (
                <span className={percentageClass}>({percentageBillReductionAmountToHighlightDisplayShort} less) </span>
              )}

              {isBill
                ? `in energy charges (${taxIndicator}) if you'd billed with this plan during the same ${billDays} day period${
                    implementationCode === implementationCodes.GAS.AU_DEFAULT
                      ? ` based on an estimated ${percentagePeakDisplay} peak and ${percentageOffPeakDisplay} off-peak usage`
                      : ''
                  }.`
                : estimateMonthlyDisclaimer}
            </div>
          </>
        );
      case implementationCodes.INTERNET.AU_DEFAULT:
        return (
          <>
            <div className={amountClass}>{billAmountLowestDisplay}</div>
            <div className={disclaimerClass}>
              {hasSavingToHighlight && (
                <span className={percentageClass}>({percentageBillReductionAmountToHighlightDisplayShort} less) </span>
              )}
              {`per month${
                billAmountGuaranteedDisplay !== billAmountLowestDisplay
                  ? ` (normally ${billAmountGuaranteedDisplay}).`
                  : '.'
              }`}
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: offer.specialOffer
                }}
              />
            </div>
          </>
        );
      default:
        return '';
    }
  };

  return getContent(implementationCode);
};

export default OfferMarketingDisplay;
