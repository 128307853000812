import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import moment from 'moment';

import { useAuth0 } from '../../auth/auth0-Provider';

import { uploadBillTypes } from '../../enums/uploadBillTypes';
import { billTypes } from '../../enums/billTypes';
import { buttonDirection } from '../../enums/buttonTypes';
import {
  boolTypes,
  occupancyPurposes,
  occupancyTypes,
  solarInstallationEmailModes,
  solarTypes,
  submissionTypes
} from '../../enums/submissionTypes';

import OptionItemSelector from './optionItemSelector';
import AddressSelector from './addressFullSelector';
import PostcodeSelector from './addressPostcodeSelector';
import UploadBillSelector from './uploadBillSelector';
import SolarEmailSelector from './solarEmailSelector';
import NewConnectionSelector from './newConnectionSelector';
import { getDistributorDefaults, getDistributorItem, getRegionDistributors } from '../../actions/distributorActions';
import SummaryFooter from './summaryFooter';
import Introduction from './introduction';
import { submitEstimateUnathenticated, submitEstimateWithToken } from '../../actions/energyEstimateActions';
import { addSessionActivity } from '../../actions/sessionActivityActions';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes';
import {
  comparisonErrorMessage,
  displayErrorMessage,
  setServerMessage,
  setServerMessageClear
} from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import { getSessionActivity } from '../../utilities/commonUtilities';
import { submitAutoEstimates } from '../serviceCard/serviceCardUtilities';
import ServiceIcon from '../serviceCard/serviceIcon';
import ServerMessage from '../common/serverMessage';
import { getPostcodeComparison, resetFramePartner } from '../../actions/partnerActions';
import { goHome } from '../../actions/comparisonActions';

import { pageModes } from '../../enums/launchTypes';
import { postFrameScrollTopMessage } from '../../utilities/domUtilities';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import { scaleTypes } from '../../enums/scaleTypes';
import { navigationTypes } from '../../enums/navigationTypes';
import { propertyAddressTypes } from '../../enums/propertyAddressTypes';
import { levelSettings } from '../../enums/levelSettings';
import { eventTypes, interactionSources, restartSources } from '../../enums/partnerFrameTypes';

import ComparisonEnd from './comparisonEnd';
import PoweredBySmartMe from '../common/poweredBySmartMe';
import StartHeader from './startHeader';
import { Container } from 'reactstrap';

const EstimateWizard = () => {
  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const target = serverMessageTargets.ESTIMATE_WIZARD;

  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { estimateWizardStyle } = useSelector(state => state.content.cssVariables);
  const { hasEstimates } = useSelector(state => state.config.settings);
  const {
    serviceType: partnerServiceType,
    occupancyPurpose: partnerOccupancyPurpose,
    occupancyType: partnerOccupancyType,
    postcodeInitial,
    postcodeOfferMax,
    propertyAddressType,
    skipIntroduction,
    restartSource,
    pageMode: framePartnerPageMode
  } = useSelector(state => state.framePartner);
  const { emailAddress: userEmailAddress } = useSelector(state => state.user);
  const { serverMessage, selectedAgent, session } = useSelector(state => state);
  const { googleAnalyticsClientCode, solarInstallationEmailMode, showPoweredBy } = useSelector(
    state => state.config.settings
  );
  const { data: userBills } = useSelector(state => state.userBills);
  const { submissionType } = useSelector(state => state.comparison);
  const { pageMode, frameHost, googleLoaded } = useSelector(state => state.application);

  const { logoAlternative, logo } = useSelector(state => state.content.images);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  const [interactionStarted, setInteractionStarted] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentHeading, setCurrentHeading] = useState(null);
  const [isPostcodeComparison, setIsPostcodeComparison] = useState(false); // Currently not used, but leaving for now as we are likely to need in future.
  const [selectedPostcodeAddress, setSelectedPostcodeAddress] = useState({});
  const [selectedServiceType, setSelectedServiceType] = useState(billTypes.NONE);
  const [selectedAddressFull, setSelectedAddressFull] = useState({});
  const [selectedEmailAddress, setSelectedEmailAddress] = useState(userEmailAddress);
  const [selectedOccupancyOption, setSelectedOccupancyOption] = useState();
  const [selectedOccupancyDate, setSelectedOccupancyDate] = useState(null);
  const [selectedUploadOption, setSelectedUploadOption] = useState();
  const [selectedUploadType, setSelectedUploadType] = useState();
  const [selectedSolarOption, setSelectedSolarOption] = useState();
  const [selectedOccupancyPurpose, setSelectedOccupancyPurpose] = useState(occupancyPurposes.NONE);
  const [distributorSets, setDistributorSets] = useState({});
  const [occupancyDates, setOccupancyDates] = useState([]);
  const [selectedDistributorPrimary, setSelectedDistributorPrimary] = useState();
  const [selectedDistributorSecondary, setSelectedDistributorSecondary] = useState();

  const agentSolarServiceType = getAgentServiceType(billTypes.SOLAR_INSTALLATION, agentBillTypes);
  const useSolarAffiliate = false; // Solar affiliate email feature currently turned off.
  const hasSolarAffiliate =
    useSolarAffiliate &&
    agentSolarServiceType.enabled &&
    agentSolarServiceType.hasAffiliate &&
    agentSolarServiceType.hasAffiliateSettings;

  const services = billTypes.getServices(agentBillTypes, [billTypes.ELECTRICITY, billTypes.GAS]);
  const installDates = occupancyDates && occupancyDates.map(date => moment.tz(date, 'YYYY-MM-DD', 'GMT').toDate());
  const sessionActivity = getSessionActivity(session);

  const hasPartnerOccupancyPurpose = partnerOccupancyPurpose && partnerOccupancyPurpose !== occupancyPurposes.NONE;
  const partnerNewConnection = partnerOccupancyType === occupancyTypes.NEW;

  const currentData = {
    serviceType: selectedServiceType || billTypes.NONE,
    serviceTypeName: selectedServiceType ? selectedServiceType.Name.toLowerCase() : null,
    address: selectedAddressFull,
    postcode: selectedPostcodeAddress,
    emailAddress: selectedEmailAddress,
    occupancyOption: selectedOccupancyOption || boolTypes.NONE,
    occupancyDate: selectedOccupancyDate,
    occupancyPurpose: selectedOccupancyPurpose || occupancyPurposes.NONE,
    uploadOption: selectedUploadOption || boolTypes.NONE,
    uploadType: selectedUploadType || uploadBillTypes.NONE,
    solarOption: selectedSolarOption || boolTypes.NONE,
    primaryDistributor: selectedDistributorPrimary,
    secondaryDistributor: selectedDistributorSecondary
  };

  const getPartnerServiceType = () => {
    return partnerServiceType === billTypes.NONE ? billTypes.ELECTRICITY : partnerServiceType;
  };

  // --------------------------------------------------------------------------
  // Frame partner state.
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (restartSource !== restartSources.NONE) {
      onStartAgain(restartSource);
    }
  }, [restartSource]);

  useEffect(() => {
    if (partnerServiceType && partnerServiceType !== billTypes.NONE) {
      applyServiceType(partnerServiceType);
    }
  }, [partnerServiceType]);

  useEffect(() => {
    if (partnerOccupancyPurpose && partnerOccupancyPurpose !== occupancyPurposes.NONE) {
      setSelectedOccupancyPurpose(partnerOccupancyPurpose);
    }
  }, [partnerOccupancyPurpose]);

  useEffect(() => {
    if (partnerOccupancyType && partnerOccupancyType !== occupancyTypes.NONE) {
      setSelectedOccupancyOption(partnerOccupancyType === occupancyTypes.CURRENT ? boolTypes.YES : boolTypes.NO);
    }
  }, [partnerOccupancyType]);

  useEffect(() => {
    if (skipIntroduction) {
      resetSteps(currentData, introduction.index);
    } else {
      onNext();
    }
  }, [skipIntroduction]);

  useEffect(() => {
    if (postcodeInitial) {
      const serviceType = getPartnerServiceType();
      applyServiceType(serviceType);
      getPostcodeEstimate(serviceType, postcodeInitial);
    }
  }, [postcodeInitial]);

  useEffect(() => {
    if (propertyAddressType === propertyAddressTypes.POSTCODE && !postcodeInitial && skipIntroduction) {
      applyCurrentStep(postcodeSelector, currentData);
    }
  }, [propertyAddressType]);

  const backToPlans = () => {
    dispatch(setServerMessageClear());
    dispatch(goBack());
  };

  // --------------------------------------------------------------------------
  // Wizard step control.
  // --------------------------------------------------------------------------
  const resetSteps = (data, index) => {
    setCurrentStepIndex(index);
    setCurrentHeading(introduction.getHeading(data));

    onStep(buttonDirection.Next, index, data);
  };

  const applyCurrentStep = (step, data) => {
    setCurrentStepIndex(step.index);
    setCurrentHeading(step.getHeading(data));
  };

  const onStep = (direction, index, e) => {
    const offset = direction === buttonDirection.Previous ? -1 : 1;

    const checkIndex = index + offset;
    const checkStep = steps.find(e => e.index === checkIndex);

    if (checkStep === undefined) {
      return;
    }

    const serviceTypeName = e.serviceType ? e.serviceType.Name.toLowerCase() : currentData.serviceTypeName;

    const data = {
      ...currentData,
      serviceTypeName,
      ...e
    };

    if (checkStep.entryCondition(data)) {
      applyCurrentStep(checkStep, data);

      if (data.uploadType !== uploadBillTypes.WEBSITE && checkStep.submitEstimate) {
        onSubmitEstimate(data);
      }
      return;
    }

    onStep(direction, checkIndex, data);
  };

  const initStep = buttonDirection => {
    onStep(buttonDirection, currentStepIndex, {});
  };

  const onNext = () => {
    initStep(buttonDirection.Next);
  };

  const onBack = () => {
    initStep(buttonDirection.Previous);
  };

  const recordSessionActivity = (data, activityType) => {
    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data,
        activityType: activityType || sessionActivityTypes.ESTIMATE_WIZARD
      })
    );
  };

  const recordInteractionStarted = source => {
    if (!interactionStarted) {
      recordSessionActivity({
        eventType: eventTypes.INTERACTION_STARTED.tag,
        source: source.tag
      });

      setInteractionStarted(true);
    }
  };

  // --------------------------------------------------------------------------
  // Distributors: Apply region distributors
  // --------------------------------------------------------------------------
  const applyDistributorDefaults = (address, items, serviceType) => {
    const result = getDistributorDefaults(null, items, agentBillTypes, serviceType, serviceType === billTypes.ENERGY);

    if (result.primaryDistributorLength === 0) {
      dispatch(
        displayErrorMessage(
          target,
          `Unfortunately we currently do not provide ${selectedServiceType.Name.toLowerCase()} plans for this address.`,
          true
        )
      );

      recordSessionActivity({
        eventType: eventTypes.DISTRIBUTOR_APPLIED.tag,
        status: 'no-distributors',
        serviceType: serviceType.Name,
        propertyAddress: address.fullAddress
      });
    }

    setSelectedDistributorPrimary(result.primaryDistributor);
    setSelectedDistributorSecondary(result.secondaryDistributor);
  };

  const applyDistributors = (address, serviceType) => {
    const singleServiceType = serviceType === billTypes.ENERGY ? null : serviceType;

    const onComplete = result => {
      setDistributorSets(result.items);
      setOccupancyDates(result.occupancyDates);

      if (result.success) {
        applyDistributorDefaults(address, result.items, serviceType);
      } else {
        setSelectedDistributorPrimary(null);
        setSelectedDistributorSecondary(null);
      }
    };

    const onError = error => {
      dispatch(
        setServerMessage({
          ...error.response.data,
          scrollTo: true,
          target,
          code: error.response.status
        })
      );
    };

    dispatch(getRegionDistributors(address, agentBillTypes, singleServiceType, onComplete, onError));
  };

  // --------------------------------------------------------------------------
  // Wizard step handlers
  // --------------------------------------------------------------------------
  const onStartAgain = source => {
    dispatch(setServerMessageClear(target, serverMessage));
    dispatch(goHome());

    // Use the initial sequence when starting again, unless the reset is coming from the offer button,
    // in which case start from the address step so the user can complete a real comparsion.
    if (propertyAddressType === propertyAddressTypes.POSTCODE) {
      if (source === restartSources.OFFER) {
        applyCurrentStep(addressSelector, currentData);
      } else if (!skipIntroduction) {
        resetSteps(currentData, 0);
      } else {
        applyCurrentStep(postcodeSelector, currentData);
      }
    } else {
      if (source === restartSources.CLOSE_BUTTON) {
        resetSteps(currentData, 0);
      } else {
        resetSteps(currentData, introduction.index);
      }
    }

    if (pageMode === pageModes.FRAMED) {
      dispatch(resetFramePartner(restartSources.NONE));
      postFrameScrollTopMessage(frameHost);
    }

    recordSessionActivity({
      eventType: eventTypes.START_AGAIN.tag,
      source: restartSource.tag
    });
  };

  const onPostcodeChanged = address => {
    setSelectedPostcodeAddress(address);
  };

  const onPostcodeNext = () => {
    const serviceType = selectedServiceType === billTypes.NONE ? getPartnerServiceType() : selectedServiceType;
    const postcode = selectedPostcodeAddress.postcode;

    applyServiceType(serviceType);

    recordInteractionStarted(interactionSources.POSTCODE);
    recordSessionActivity({ eventType: eventTypes.PROPERTY_POSTCODE_ENTERED.tag, postcode });

    getPostcodeEstimate(serviceType, postcode);
  };

  const onPostcodeServiceTypeSelected = serviceType => {
    applyServiceType(serviceType);
    getPostcodeEstimate(serviceType, selectedPostcodeAddress.postcode || postcodeInitial);
  };

  const onIntroductionNext = () => {
    recordInteractionStarted(interactionSources.INTRODUCTION);

    if (propertyAddressType === propertyAddressTypes.POSTCODE) {
      applyCurrentStep(postcodeSelector, currentData);
    } else {
      onNext();
    }
  };

  const onServiceTypeSelected = serviceType => {
    recordInteractionStarted(interactionSources.SERVICE_TYPE);
    recordSessionActivity({
      eventType: eventTypes.SERVICE_TYPE_SELECTED.tag,
      serviceType: serviceType.Code
    });

    applyServiceType(serviceType);

    onStep(buttonDirection.Next, currentStepIndex, {
      serviceType
    });
  };

  const applyServiceType = serviceType => {
    setSelectedServiceType(serviceType);

    const isEnergy = serviceType === billTypes.ELECTRICITY || serviceType === billTypes.GAS;

    if (
      serviceType !== billTypes.NONE &&
      serviceType !== selectedServiceType &&
      selectedAddressFull.fullAddress &&
      isEnergy
    ) {
      applyDistributors(selectedAddressFull, serviceType);
    }
  };

  const onAddressSelected = address => {
    recordInteractionStarted(interactionSources.ADDRESS);
    recordSessionActivity({
      eventType: eventTypes.PROPERTY_ADDRESS_SELECTED.tag,
      propertyAddress: address.fullAddress
    });

    setSelectedAddressFull(address);
    applyDistributors(address, selectedServiceType);
  };

  const onAddressNext = () => {
    const multipleDistributors = hasMultipleDistributors(selectedServiceType);

    recordSessionActivity({
      eventType: eventTypes.PROPERTY_ADDRESS_ENTERED.tag,
      propertyAddress: selectedAddressFull.fullAddress,
      multipleDistributors
    });

    onNext();
  };

  const onPrimaryDistributorSelected = primaryDistributor => {
    setSelectedDistributorPrimary(primaryDistributor);

    recordSessionActivity({
      eventType: eventTypes.DISTRIBUTOR_SELECTED.tag,
      code: primaryDistributor.code,
      name: primaryDistributor.name
    });

    onStep(buttonDirection.Next, currentStepIndex, {
      primaryDistributor
    });
  };

  const onOccupancyOptionSelected = occupancyOption => {
    setSelectedOccupancyOption(occupancyOption);

    onStep(buttonDirection.Next, currentStepIndex, {
      occupancyOption
    });
  };

  const onOccupancyDateSelected = occupancyDate => {
    setSelectedOccupancyDate(occupancyDate);

    onStep(buttonDirection.Next, currentStepIndex, {
      occupancyDate
    });
  };

  const onUploadOptionSelected = uploadOption => {
    const uploadType = uploadOption === boolTypes.YES ? uploadBillTypes.WEBSITE : uploadBillTypes.MANUAL;

    setSelectedUploadOption(uploadOption);
    setSelectedUploadType(uploadType);

    onStep(buttonDirection.Next, currentStepIndex, {
      uploadOption,
      uploadType
    });
  };

  const onUploadTypeSelected = uploadType => {
    setSelectedUploadType(uploadType);

    onStep(buttonDirection.Next, currentStepIndex, {
      uploadType
    });
  };

  const onSolarOptionSelected = solarOption => {
    setSelectedSolarOption(solarOption);

    onStep(buttonDirection.Next, currentStepIndex, {
      solarOption
    });
  };

  const onEmailAddressChanged = emailAddress => {
    setSelectedEmailAddress(emailAddress);
  };

  const onOccupancyPurposeSelected = occupancyPurpose => {
    setSelectedOccupancyPurpose(occupancyPurpose);

    onStep(buttonDirection.Next, currentStepIndex, {
      occupancyPurpose
    });
  };

  const onBillComplete = billServiceType => {
    const billServiceTypeName = billServiceType.Name.toLowerCase();

    const data = {
      ...currentData,
      serviceType: billServiceType,
      serviceTypeName: billServiceTypeName
    };

    applyServiceType(billServiceType);

    setIsPostcodeComparison(false);

    onFinish(data);
  };

  const onBillSkip = () => {
    onUploadOptionSelected(boolTypes.NO);
  };

  const onFinish = data => {
    applyCurrentStep(hasSolarAffiliate ? solarInstallationEmail : noSolarInstallationEmail, data);
  };

  // --------------------------------------------------------------------------
  // Get energy estimate model.
  // --------------------------------------------------------------------------
  const getEnergyEstimate = (e, primaryService) => {
    const solarType = e.solarOption === boolTypes.YES ? solarTypes.STANDARD : solarTypes.NONE;
    const occupancyType = e.occupancyOption === boolTypes.YES ? occupancyTypes.CURRENT : occupancyTypes.NEW;

    return {
      property: {
        address: { ...e.address },
        occupancyDate: e.occupancyDate ? moment(e.occupancyDate).format('YYYY-MM-DD') : null,
        occupancyType: occupancyType.Id,
        occupancyPurpose: e.occupancyPurpose.Id
      },
      emailAddress: null, // only supporting sites with anonymous modes
      termsAndConditions: true, // auto-accept terms for estimates
      sendComparisonEmail: false,
      sendArticlesEmail: false,
      sendSolarInstallationEmail: false,
      sessionActivity,
      agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
      currentProviderCode: null,
      distributorCode: primaryService
        ? selectedDistributorPrimary
          ? selectedDistributorPrimary.selectionCode
          : null
        : selectedDistributorSecondary
        ? selectedDistributorSecondary.selectionCode
        : null,
      serviceType: e.serviceType.Code,
      solarLevel: solarType.Id
    };
  };

  // --------------------------------------------------------------------------
  // Submit new estimate request (Access key requests not supported).
  // --------------------------------------------------------------------------
  const onSubmitEstimate = data => {
    dispatch(setServerMessageClear(target, serverMessage));
    dispatch(setServerMessageClear(serverMessageTargets.ESTIMATE_WIZARD_SOLAR_EMAIL, serverMessage));

    setIsPostcodeComparison(false);

    const energyEstimate = getEnergyEstimate(data, true);

    const options = { showSpinner: true, applySleep: true };

    if (isUserAuthenticated()) {
      const fn = async () => {
        const accessToken = await getAccessToken();
        dispatch(
          submitEstimateWithToken(
            accessToken,
            selectedServiceType,
            { estimate: energyEstimate },
            options,
            estimateComplete,
            estimateError
          )
        );
      };
      fn();
    } else {
      dispatch(
        submitEstimateUnathenticated(
          selectedServiceType,
          { estimate: energyEstimate },
          options,
          estimateComplete,
          estimateError
        )
      );
    }
  };

  const estimateComplete = (comparisonResult, userBill) => {
    recordSessionActivity(
      {
        submissionType: submissionTypes.ESTIMATE,
        submissionSubType: 'Wizard',
        submissionId: userBill.submissionId,
        serviceType: selectedServiceType.Code,
        hasOffers: comparisonResult.hasOffers
      },
      sessionActivityTypes.COMPARISON_COMPLETED
    );

    dispatch(
      submitAutoEstimates(
        selectedServiceType,
        selectedAddressFull,
        selectedAgent,
        sessionActivity,
        {
          emailAddress: null // only supporting sites with anonymous modes
        },
        agentBillTypes,
        userBills,
        googleAnalyticsClientCode
      )
    );

    dispatch(push('/compare'));
  };

  const estimateError = error => {
    dispatch(comparisonErrorMessage(error, target));
  };

  // --------------------------------------------------------------------------
  // Get postcode estimate
  // --------------------------------------------------------------------------
  const getPostcodeEstimate = (serviceType, postcode) => {
    const model = {
      serviceType: serviceType.Id,
      postcode,
      postcodeOfferMax,
      occupancyPurpose: partnerOccupancyPurpose.Id
    };

    dispatch(
      getPostcodeComparison(
        model,
        selectedAgent ? selectedAgent.code : null,
        onPostcodeEstimateComplete,
        onPostcodeEstimateError
      )
    );

    const serviceTypeName = serviceType.Name.toLowerCase();

    const data = {
      ...currentData,
      serviceType,
      serviceTypeName
    };

    setIsPostcodeComparison(true);

    applyCurrentStep(postcodeComparisonServiceType, data);
  };

  const onPostcodeEstimateComplete = () => {};

  const onPostcodeEstimateError = error => {
    dispatch(comparisonErrorMessage(error, target));
  };

  // --------------------------------------------------------------------------
  // Wizard Steps.
  // --------------------------------------------------------------------------
  const introduction = {
    index: 1,
    noGrow: false,
    getHeading: () => {
      return null;
    },
    component: <Introduction serviceType={selectedServiceType} onNext={onIntroductionNext} />,
    heightLevel: levelSettings.Low,
    entryCondition: () => {
      return skipIntroduction === false;
    },
    hasBack: () => {
      return false;
    },
    hasClose: () => {
      return false;
    }
  };

  const serviceTypeSelector = {
    index: 2,
    noGrow: skipIntroduction && propertyAddressType === propertyAddressTypes.ADDRESS,
    getHeader: () => {
      const hasHeader = skipIntroduction && propertyAddressType === propertyAddressTypes.ADDRESS;
      const heading = partnerNewConnection
        ? '<div>Moving House?</div>'
        : '<div>Can you save?</div><div>Compare and switch now.</div>';
      return hasHeader ? (
        <StartHeader
          heading={heading}
          serviceType={selectedServiceType}
          occupancyType={partnerOccupancyType}
          showSubheading
          showCommission
        />
      ) : null;
    },
    getHeading: () => {
      const title =
        propertyAddressType === propertyAddressTypes.ADDRESS
          ? 'Which service would you like to check?'
          : `Let's see which plans are availabile for your address`;
      return skipIntroduction && propertyAddressType === propertyAddressTypes.ADDRESS
        ? null
        : {
            title,
            scaleType: scaleTypes.all
          };
    },
    component: (
      <OptionItemSelector
        options={services}
        selectedOption={selectedServiceType}
        onOptionSelected={onServiceTypeSelected}
      />
    ),
    entryCondition: () => {
      return !partnerServiceType || partnerServiceType === billTypes.NONE;
    },
    hasBack: () => {
      return false;
    },
    hasClose: () => {
      return true;
    }
  };

  const addressSelector = {
    index: 3,
    noGrow:
      skipIntroduction && propertyAddressType === propertyAddressTypes.ADDRESS && partnerServiceType !== billTypes.NONE,
    getHeader: () => {
      const hasHeader =
        skipIntroduction &&
        propertyAddressType === propertyAddressTypes.ADDRESS &&
        partnerServiceType !== billTypes.NONE;
      const heading = '<div>Can you save?</div><div>Compare and switch now.</div>';
      return hasHeader ? (
        <StartHeader
          heading={heading}
          serviceType={selectedServiceType}
          occupancyType={partnerOccupancyType}
          showSubheading
          showCommission
        />
      ) : null;
    },
    getHeading: e => {
      const hasHeading =
        !skipIntroduction ||
        propertyAddressType === propertyAddressTypes.POSTCODE ||
        partnerServiceType === billTypes.NONE;
      return hasHeading
        ? { title: `What address are you comparing ${e.serviceTypeName} for?`, scaleType: scaleTypes.all }
        : null;
    },
    component: (
      <AddressSelector
        selectedAddress={selectedAddressFull}
        onAddressSelected={onAddressSelected}
        onNext={onAddressNext}
      />
    ),
    entryCondition: () => {
      return true;
    },
    hasBack: serviceTypeSelector.entryCondition,
    hasClose: () => {
      return !skipIntroduction || !partnerServiceType || partnerServiceType === billTypes.NONE;
    }
  };

  const getDistributorItems = serviceType => {
    const primaryServiceType = serviceType === billTypes.ENERGY ? billTypes.ELECTRICITY : serviceType;
    const secondaryServiceType = serviceType === billTypes.ENERGY ? billTypes.GAS : null;

    const primaryDistributorItem = getDistributorItem(distributorSets, primaryServiceType);
    const secondaryDistributorItem = getDistributorItem(distributorSets, secondaryServiceType);

    return {
      primaryDistributor: {
        serviceType: primaryServiceType,
        item: primaryDistributorItem,
        hasMultiple: primaryDistributorItem && primaryDistributorItem.length > 1 ? true : false
      },
      secondaryDistributor: {
        serviceType: secondaryServiceType,
        item: secondaryDistributorItem,
        hasMultiple: secondaryDistributorItem && secondaryDistributorItem.length > 1 ? true : false
      }
    };
  };

  const hasMultipleDistributors = serviceType => {
    const items = getDistributorItems(serviceType);

    return (
      (items.primaryDistributor.serviceType === serviceType && items.primaryDistributor.hasMultiple) ||
      (items.secondaryDistributor.serviceType === serviceType && items.secondaryDistributor.hasMultiple)
    );
  };

  const distributorSelector = {
    index: 4,
    getHeading: e => {
      const title = `Please select your ${e.serviceTypeName} distributor for ${
        e.address ? e.address.streetAddress : ''
      }`;

      return { title, scaleType: scaleTypes.all };
    },
    component: (
      <OptionItemSelector
        options={getDistributorItem(distributorSets, selectedServiceType)}
        selectedOption={selectedDistributorPrimary}
        onOptionSelected={onPrimaryDistributorSelected}
      />
    ),
    entryCondition: e => {
      const items = getDistributorItems(e.serviceType);

      return items.primaryDistributor.hasMultiple;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const occupancyOptionSelector = {
    index: 5,
    getHeading: e => {
      return {
        title: `Do you currently have ${e.serviceTypeName} connected at ${e.address ? e.address.streetAddress : ''}?`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <OptionItemSelector
        options={[boolTypes.YES, boolTypes.NO]}
        selectedOption={selectedOccupancyOption}
        onOptionSelected={onOccupancyOptionSelected}
      />
    ),
    entryCondition: () => {
      return !partnerNewConnection;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const newConnectionSelector = {
    index: 6,
    getHeading: e => {
      return { title: `What date would you like to connect the ${e.serviceTypeName}?`, scaleType: scaleTypes.all };
    },
    component: (
      <NewConnectionSelector
        installDates={installDates}
        selectedOccupancyDate={selectedOccupancyDate}
        onOccupancyDateSelected={onOccupancyDateSelected}
        onNext={onNext}
      />
    ),
    entryCondition: e => {
      return e.occupancyOption === boolTypes.NO;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const uploadOptionSelector = {
    index: 7,
    getHeading: () => {
      return { title: 'Do you want to compare using the data from your current e-bill?', scaleType: scaleTypes.all };
    },
    component: (
      <OptionItemSelector
        options={[boolTypes.YES, boolTypes.NO]}
        selectedOption={selectedUploadOption}
        onOptionSelected={onUploadOptionSelected}
      />
    ),
    entryCondition: e => {
      return e.occupancyOption === boolTypes.YES;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const uploadTypeSelector = {
    index: 8,
    getHeading: e => {
      return {
        title: `Tell us more about the energy usage for ${e.address ? e.address.streetAddress : ''}`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <OptionItemSelector
        options={[uploadBillTypes.WEBSITE, uploadBillTypes.MANUAL]}
        selectedOption={selectedUploadType}
        onOptionSelected={onUploadTypeSelected}
      />
    ),
    entryCondition: () => {
      return false; // currently not supporting manual upload;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const uploadBillSelector = {
    index: 9,
    noGrow: true,
    getHeading: e => {
      return {
        title: `Please upload your latest ${e.serviceTypeName} e-bill for ${e.address ? e.address.streetAddress : ''}`,
        scaleType: scaleTypes.mdUp
      };
    },
    component: (
      <UploadBillSelector
        serviceType={selectedServiceType}
        occupancyPurpose={hasPartnerOccupancyPurpose ? partnerOccupancyPurpose : occupancyPurposes.RESIDENTIAL} // default to Residential; if left as None, correct plans may not be presented.
        onSuccess={onBillComplete}
        onSkip={onBillSkip}
      />
    ),
    entryCondition: e => {
      return (
        e.uploadOption === boolTypes.YES &&
        e.occupancyOption === boolTypes.YES &&
        e.uploadType === uploadBillTypes.WEBSITE
      );
    },
    hasBack: e => {
      return e.uploadOption === boolTypes.YES;
    },
    hasClose: () => {
      return true;
    }
  };

  const uploadManualSelector = {
    index: 10,
    getHeading: e => {
      return {
        title: `Enter your latest ${e.serviceTypeName} details for ${e.address ? e.address.streetAddress : ''}`,
        scaleType: scaleTypes.all
      };
    },
    component: <UploadBillSelector serviceType={selectedServiceType} />,
    entryCondition: e => {
      return e.uploadOption === boolTypes.YES && e.uploadType === uploadBillTypes.MANUAL;
    },
    hasBack: e => {
      return e.uploadOption === boolTypes.YES;
    },
    hasClose: () => {
      return true;
    }
  };

  const solarOptionSelector = {
    index: 11,
    getHeading: e => {
      return {
        title: `Does ${e.address ? e.address.streetAddress : ''} have solar panels installed?`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <OptionItemSelector
        options={[boolTypes.YES, boolTypes.NO]}
        selectedOption={selectedSolarOption}
        onOptionSelected={onSolarOptionSelected}
      />
    ),
    entryCondition: e => {
      return (
        (e.uploadOption === boolTypes.NO || e.uploadOption === boolTypes.NONE) &&
        e.serviceType === billTypes.ELECTRICITY
      );
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const occupancyPurposeSelector = {
    index: 12,
    getHeading: e => {
      return {
        title: `Last question, what type of property is ${e.address ? e.address.streetAddress : ''}?`,
        scaleType: scaleTypes.all
      };
    },

    component: (
      <OptionItemSelector
        options={[occupancyPurposes.RESIDENTIAL, occupancyPurposes.BUSINESS]}
        selectedOption={selectedOccupancyPurpose}
        onOptionSelected={onOccupancyPurposeSelected}
      />
    ),
    entryCondition: e => {
      return !hasPartnerOccupancyPurpose && (e.uploadOption === boolTypes.NO || e.uploadOption === boolTypes.NONE);
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const solarInstallationEmail = {
    index: 13,
    noGrow: true,
    submitEstimate: true,
    getHeading: () => {
      const title = (
        <>
          <ServiceIcon serviceType={billTypes.SOLAR_INSTALLATION} />
          {solarInstallationEmailMode === solarInstallationEmailModes.HOME_LOAN_BROKER.Id
            ? 'Did you know you can use your home loan to pay for an existing or new solar installation?'
            : 'Considering a solar installation or an upgrade to your current solar setup?'}
        </>
      );

      return { title, scaleType: scaleTypes.all };
    },
    component: <SolarEmailSelector emailAddress={selectedEmailAddress} onEmailAddressChanged={onEmailAddressChanged} />,
    entryCondition: () => {
      return (
        hasSolarAffiliate &&
        (solarInstallationEmailMode === solarInstallationEmailModes.DEFAULT.Id ||
          solarInstallationEmailMode === solarInstallationEmailModes.HOME_LOAN_BROKER.Id)
      );
    },
    hasBack: () => {
      return false;
    },
    hasClose: () => {
      return true;
    }
  };

  const noSolarInstallationEmail = {
    index: 14,
    submitEstimate: true,
    getHeading: () => {
      return null;
    },
    component: <ComparisonEnd serviceType={selectedServiceType} occupancyType={partnerOccupancyType} />,
    entryCondition: () => {
      return !hasSolarAffiliate || solarInstallationEmailMode === solarInstallationEmailModes.NONE;
    },
    hasBack: () => {
      return false;
    },
    hasClose: () => {
      return true;
    }
  };

  const postcodeSelector = {
    index: 15,
    noGrow: true,
    getHeader: () => {
      const heading = partnerNewConnection
        ? 'Enter your postcode to get started.'
        : 'Enter your postcode to see if you can save';
      return (
        <StartHeader
          heading={heading}
          serviceType={selectedServiceType}
          occupancyType={partnerOccupancyType}
          showSubheading
          showCommission
        />
      );
    },
    getHeading: () => {
      return null;
    },
    component: (
      <PostcodeSelector
        selectedAddress={selectedPostcodeAddress}
        onChange={onPostcodeChanged}
        onNext={onPostcodeNext}
      />
    ),
    entryCondition: () => {
      return propertyAddressType === propertyAddressTypes.POSTCODE;
    },
    hasBack: () => {
      return false;
    },
    hasClose: () => {
      return !skipIntroduction;
    }
  };

  const postcodeComparisonServiceType = {
    index: 16,
    noGrow: true,
    getHeader: () => {
      const heading = 'Here are our cheapest plans for your postcode';
      return (
        <StartHeader
          heading={heading}
          showSubheading={false}
          showCommission={false}
          serviceType={selectedServiceType}
          occupancyType={partnerOccupancyType}
        />
      );
    },
    getHeading: () => {
      return {
        title: partnerNewConnection ? 'Select each button to show the plans' : 'Please select service to see plans',
        scaleType: scaleTypes.all
      };
    },
    component: (
      <OptionItemSelector
        options={services}
        selectedOption={selectedServiceType}
        onOptionSelected={onPostcodeServiceTypeSelected}
      />
    ),
    entryCondition: () => {
      return selectedPostcodeAddress.postcode || postcodeInitial;
    },
    hasBack: () => {
      return false;
    },
    hasClose: () => {
      return true;
    }
  };

  const steps = [
    introduction,
    postcodeSelector,
    serviceTypeSelector,
    addressSelector,
    distributorSelector,
    occupancyOptionSelector,
    newConnectionSelector,
    uploadOptionSelector,
    uploadTypeSelector,
    uploadBillSelector,
    uploadManualSelector,
    solarOptionSelector,
    occupancyPurposeSelector,
    solarInstallationEmail,
    noSolarInstallationEmail,
    postcodeComparisonServiceType
  ];

  const current = steps.find(e => e.index === currentStepIndex);
  const currentPage = navigationTypes.getCurrentPage();
  const style = estimateWizardStyle ? JSON.parse(estimateWizardStyle) : undefined;

  const showLogo = framePartnerPageMode === pageModes.DEFAULT;
  const showSummaryBackButton = currentPage === navigationTypes.SWITCH;
  const showSummaryFooter =
    submissionType &&
    submissionType !== submissionTypes.NONE &&
    (currentPage === navigationTypes.COMPARE || currentPage === navigationTypes.SWITCH);

  return current && hasEstimates && googleLoaded ? (
    <>
      {showLogo && (
        <div className="sm-estimate-wizard-logo">
          <img src={`${staticContentUrl}${logoAlternative ?? logo}`} alt="logo" />
        </div>
      )}

      <div
        className={`sm-estimate-wizard${showSummaryFooter ? ' with-footer' : ''}${
          current.heightLevel ? ` height-${current.heightLevel.tag}` : ''
        }`}
        style={style}
      >
        {current.hasClose(currentData) && (
          <button
            className={`close${showLogo ? ' with-logo' : ''}`}
            style={showLogo ? { top: '125px' } : null}
            onClick={() => onStartAgain(restartSources.CLOSE_BUTTON)}
          >
            ×
          </button>
        )}
        <div className="header">
          {current.hasBack(currentData) && (
            <div className="navigation-arrow" onClick={onBack}>
              <span className={`arrow material-icons`} aria-hidden="true">
                arrow_back_ios_new
              </span>
              Back
            </div>
          )}
        </div>
        <div className={`content${current.noGrow ? ' no-grow' : ''}`}>
          {current.getHeader ? current.getHeader() : null}
          {currentHeading && (
            <div className={`heading ${currentHeading.scaleType.className}`}>{currentHeading.title}</div>
          )}
          {current.component}
          {showPoweredBy && current.heightLevel !== levelSettings.Low && (
            <div className="powered-by-container" style={showLogo ? { top: '365px' } : null}>
              <PoweredBySmartMe noMargin textMode />
            </div>
          )}
        </div>
      </div>
      {showSummaryFooter && (
        <SummaryFooter
          onButtonClick={() => onStartAgain(restartSources.START_NEW_BUTTON)}
          showButton={true}
          buttonText="Start new comparison"
          showBackButton={showSummaryBackButton}
          onBack={backToPlans}
          postcode={selectedPostcodeAddress.postcode || postcodeInitial}
        />
      )}

      <div className={`sm-estimate-wizard-server-message${currentPage ? ` ${currentPage.pageName}-page` : ''}`}>
        <Container>
          <ServerMessage serverMessage={serverMessage} target={target} />
        </Container>
      </div>
    </>
  ) : null;
};
export default EstimateWizard;
