import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import RegistrationDialog from './registrationDialog';
import RegistrationDialogUx2 from '../registration/registrationDialogUx2';
import RegistrationSummary from './registrationSummary';
import RegistrationIntroduction from './registrationIntroduction';

const Registration = () => {
  const { name: agentName, programName } = useSelector(state => state.config);
  const { registrationComplete, registrationPresent, emailAddress, autoCheckEmailAddress } = useSelector(
    state => state.registration
  );

  return (
    <Row>
      <Helmet>
        <title>Registration</title>
      </Helmet>

      {registrationComplete || registrationPresent ? (
        <Col>
          <Container className="qs-home-container">
            <Row className="qs-mt-lg">
              <Col>
                <RegistrationSummary
                  emailAddress={emailAddress}
                  autoCheckEmailAddress={autoCheckEmailAddress}
                  agentName={agentName}
                  programName={programName}
                  registrationComplete={registrationComplete}
                  registrationPresent={registrationPresent}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      ) : (
        <Col>
          <RegistrationIntroduction />
          <RegistrationDialogUx2 />
        </Col>
      )}
    </Row>
  );
};

export default Registration;
