import React from 'react';

const CertifiedLogo = ({ certified }) => {
  return (
    <div className="sm-certified-logo">
      <div className="smart-me">
        <div className="smart">SMART</div>
        <div className="me">ME</div>
      </div>
      {certified !== false && <div className="certified">CERTIFIED</div>}
    </div>
  );
};
export default CertifiedLogo;
