import React from 'react';
import { useSelector } from 'react-redux';

const Testimonial = ({ item: { quote, memberName, memberLocation, memberSince, imagePath } }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  return (
    <>
      <div className="sm-home-testimonial">
        <div className="image">
          <img src={`${staticContentUrl}/${imagePath}`} alt="Member testimonial" />{' '}
        </div>
        <div className="content">
          <div
            className="quote"
            dangerouslySetInnerHTML={{
              __html: `“${quote}”`
            }}
          />
          <div className="member-data">
            {memberName} | {memberLocation}
          </div>
          <div className="member-data">Member since: {memberSince}</div>
        </div>
      </div>
    </>
  );
};
export default Testimonial;
