export const states = {
  AUSTRALIA: {
    VIC: { Id: 1, Code: 'VIC' },
    NSW: { Id: 2, Code: 'NSW' },
    QLD: { Id: 3, Code: 'QLD' },
    SA: { Id: 4, Code: 'SA' },
    TAS: { Id: 5, Code: 'TAS' },
    ACT: { Id: 6, Code: 'ACT' },
    NT: { Id: 7, Code: 'NT' },
    WA: { Id: 8, Code: 'WA' }
  }
};
