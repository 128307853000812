import React, { useState, useEffect } from 'react';
import { goBack } from 'connected-react-router';
import { Container, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as serverMessageTargets from '../actions/serverMessageTargets';
import { displayInfoMessage, setServerMessageClear } from '../actions/serverMessageActions';
import { goHome } from '../actions/comparisonActions';
import ServerMessage from '../components/common/serverMessage';
import OfferSwitch from '../components/common/offerSwitch';
import CompareDrawer from '../components/common/compareDrawer';
import SubmitBillDialogUx2 from '../components/dialogs/submitBillDialogUx2';
import ServiceIcon from '../components/serviceCard/serviceIcon';
import FooterDisclaimers from '../components/disclaimers/footerDisclaimers';
import { setDirectSwitch } from '../actions/directSwitchActions';
import { billTypes } from '../enums/billTypes';
import { uploadBillTypes, uploadSubTypes } from '../enums/uploadBillTypes';
import { directSwitchSessionStatus } from '../enums/directSwitchSessionStatus';
import { directSwitchTypes } from '../enums/submissionTypes';
import { pageModes } from '../enums/launchTypes';
import { postFrameScrollTopMessage } from '../utilities/domUtilities';
import SummaryFooter from '../components/EstimateWizard/summaryFooter';

const SwitchScreen = () => {
  const dispatch = useDispatch();
  const target = serverMessageTargets.SWITCH;

  const { comparison, directSwitch, serverMessage } = useSelector(state => state);
  const { pageMode, frameHost } = useSelector(state => state.application);

  const [isDirectSwitch, setIsDirectSwitch] = useState(false);
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);

  const serviceType = billTypes.getByCode(comparison.submission.billType);
  const serviceTypeName = serviceType ? serviceType.Name.toLowerCase() : null;
  const submissionId = comparison.submissionId;
  const submission = comparison.submission;

  const openDialog = () => {
    dispatch(setServerMessageClear(serverMessageTargets.SUBMIT_BILL, serverMessage));
    dispatch(setServerMessageClear(serverMessageTargets.ESTIMATE_DIALOG, serverMessage));

    setSubmitDialogOpen(true);
  };

  const onBack = () => {
    dispatch(setServerMessageClear());
    dispatch(goBack());
  };

  useEffect(() => {
    if (pageMode === pageModes.FRAMED) {
      postFrameScrollTopMessage(frameHost);
    }

    if (!comparison.isLoaded) {
      dispatch(goHome());
    }

    if (directSwitch.sessionStatus === directSwitchSessionStatus.READY) {
      if (directSwitch.directSwitchType === directSwitchTypes.LANDING) {
        dispatch(
          displayInfoMessage(
            target,
            <div>
              <b>NOTE:</b> You are switching your {serviceTypeName} plan for an existing {serviceTypeName} connection at
              this address. To setup a new connection click{' '}
              <Button className="sm-link-button inline" color="link" onClick={openDialog}>
                here
              </Button>
              .
            </div>,
            false
          )
        );
      }

      setIsDirectSwitch(true);

      // Set session status so user is not forced into initial offer again.
      const model = {
        ...directSwitch,
        sessionStatus: directSwitchSessionStatus.COMPLETED_SUCCESSFULLY
      };

      dispatch(setDirectSwitch(model));
    }
  }, []);

  const getHeader = () => {
    switch (serviceType) {
      case billTypes.HOME_LOAN:
        return (
          <div className="sm-compare-header">
            <ServiceIcon className="icon" isAdd={false} serviceType={serviceType} />
            <span className="heading">Your details</span>
          </div>
        );

      default:
        return <SummaryFooter onBack={onBack} showBackButton={true} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Switch</title>
      </Helmet>
      {pageMode === pageModes.DEFAULT && <CompareDrawer showSortFilter={false} submissionId={submissionId} />}
      <div className={`sm-offer-selected${pageMode === pageModes.FRAMED ? ' framed' : ''}`}>
        <Container>
          <ServerMessage serverMessage={serverMessage} target={target} />
        </Container>

        {pageMode === pageModes.DEFAULT && getHeader()}

        <OfferSwitch directSwitch={isDirectSwitch} onBack={onBack} />
      </div>

      {pageMode === pageModes.FRAMED && <FooterDisclaimers pageMode={pageMode} />}

      <SubmitBillDialogUx2
        enabled={true}
        showBackButton={false}
        confirmButtonText="Update"
        serviceType={serviceType}
        uploadBillType={uploadBillTypes.MANUAL}
        uploadSubType={uploadSubTypes.getSubType(serviceType, submission)}
        submissionId={submissionId}
        submission={submission}
        dialogOpen={submitDialogOpen}
        onDialogClosing={() => {
          setSubmitDialogOpen(false);
        }}
      />
    </>
  );
};

export default SwitchScreen;
