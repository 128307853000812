import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AvField } from 'availity-reactstrap-validation';

import Autocomplete from 'react-autocomplete-select';
import { setAgentMemberByName, clearAgentMember } from '../../actions/configActions';
import { getSessionActivity } from '../../utilities/commonUtilities';

const SubmitAgentMember = ({ hasSubmitted, agentMemberName, onChange, style }) => {
  const { agentMemberPlaceholder, agentMemberInvalid } = useSelector(state => state.content.general);
  const { selectedAgent, session } = useSelector(state => state);

  const [agentInput, setAgentInput] = useState(selectedAgent.name ? selectedAgent.name : '');
  const [agentInputIsValid, setAgentInputIsValid] = useState(true);

  const dispatch = useDispatch();

  // --------------------------------------------------------------------------
  // Agent Member validation handler.
  // --------------------------------------------------------------------------
  const validateAgentMember = (value, ctx, input, cb) => {
    const isValid = agentMemberName && agentMemberName === agentInput;

    if (hasSubmitted) setAgentInputIsValid(isValid);

    cb(isValid ? true : agentMemberInvalid);
  };

  // --------------------------------------------------------------------------
  // Agent member change event handler.
  // --------------------------------------------------------------------------
  const onAgentMemberChange = name => {
    onChange(name);

    const sessionActivity = getSessionActivity(session);

    dispatch(setAgentMemberByName(sessionActivity, name));
  };

  const onInputAgentChange = agentInput => {
    if (!agentInput) {
      onChange(null);
      dispatch(clearAgentMember());
    }

    setAgentInput(agentInput);
  };

  return (
    <div className="sm-agent-member" style={style}>
      <Autocomplete
        itemsData={[]}
        placeholder={agentMemberPlaceholder}
        maxOptionsLimit={10}
        searchEnabled={true}
        searchPattern={'containsString'}
        defaultInputValue={agentMemberName}
        getItemValue={item => {
          return item.name;
        }}
        onChange={agentInput => {
          onInputAgentChange(agentInput);
        }}
        onSelect={value => {
          onAgentMemberChange(value);
        }}
        axiosConfig={agentInput => ({
          url: `/api/config/members/?search=${agentInput}`,
          method: 'GET'
        })}
        inputJSX={props => (
          <input
            {...props}
            className={`qs-auto-complete form-control form-control-sm ${
              agentInputIsValid ? '' : ' av-invalid is-invalid'
            }`}
          />
        )}
        globalStyle=".qs-auto-complete { margin-top: -20px; } .autocomplete { width:100%; }"
      />
      <AvField type="text" name="agentValidation" className="sm-validator-hidden" validate={{ validateAgentMember }} />
    </div>
  );
};

export default SubmitAgentMember;
