import React from 'react';
import PropTypes from 'prop-types';

const BillDetailsDisclaimer = ({ submission }) => {
  return (
    <li>
      {`The after discounts amount shown is for your ${submission.billType.toLowerCase()} usage`}
      {submission.hasSolar ? ', supply and solar feed-in' : ' and supply'} only.
    </li>
  );
};

BillDetailsDisclaimer.propTypes = {
  submission: PropTypes.object.isRequired
};

export default BillDetailsDisclaimer;
