import React from 'react';
import { useSelector } from 'react-redux';

import Premise from '../common/premise';
import CreditCheckConsent from '../common/creditCheckConsent';
import Concession from '../common/concession';
import LifeSupportQuestions from './lifeSupportQuestions';
import EnergyAdditionalQuestions from './energyAdditionalQuestions';

import { occupancyPurposes } from '../../enums/submissionTypes';
import MarketingQuestions from './marketingQuestions';

const OtherSection = ({ formRef, confirmDetail, offer, onChange, showLifeSupport, isEnergy, setConfirmDetail }) => {
  const { occupancyPurpose } = useSelector(state => state.comparison.submission);

  const isResidential = occupancyPurpose === occupancyPurposes.RESIDENTIAL.Id;
  const showCreditCheckConsent = offer.creditCheckConsentRequired;

  const productDetail = offer.productDetail;
  const acceptConcession = offer.acceptConcession;
  const providerDisplayCode = offer.providerDisplayCode;

  const showPremiseType = productDetail && (productDetail.requirePremiseType || productDetail.showPremiseType);
  const showPrimaryResidence = productDetail && productDetail.requirePrimaryResidence;
  const showResidencePeriod = productDetail && productDetail.requireResidencePeriod;
  const showPremise = showPrimaryResidence || showPremiseType;
  const showMarketingOptOut = productDetail && productDetail.requireMarketingOptOut;

  const concessionDisclaimer = productDetail ? productDetail.concessionDisclaimer : '';

  return (
    <>
      {showMarketingOptOut && (
        <MarketingQuestions confirmDetail={confirmDetail} productDetail={productDetail} onChange={onChange} />
      )}

      {isEnergy && (
        <EnergyAdditionalQuestions
          confirmDetail={confirmDetail}
          offer={offer}
          onChange={onChange}
          setConfirmDetail={setConfirmDetail}
        />
      )}

      {showPremise && (
        <Premise
          onChange={onChange}
          showPrimaryResidence={showPrimaryResidence}
          showResidencePeriod={showResidencePeriod}
          showPremiseType={showPremiseType}
          details={confirmDetail}
        />
      )}

      {showCreditCheckConsent && (
        <CreditCheckConsent
          onChange={onChange}
          providerDisplayCode={providerDisplayCode}
          creditCheckConsent={confirmDetail.creditCheckConsent}
        />
      )}

      {showLifeSupport && <LifeSupportQuestions formRef={formRef} confirmDetail={confirmDetail} onChange={onChange} />}

      {isResidential && (
        <Concession
          onChange={onChange}
          acceptConcession={acceptConcession}
          concessionDisclaimer={concessionDisclaimer}
          details={confirmDetail}
          setConfirmDetail={setConfirmDetail}
          offer={offer}
        />
      )}
    </>
  );
};

export default OtherSection;
