import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import application from './applicationReducer';
import session from './sessionReducer';
import ajaxCall from './ajaxCallReducer';
import serverMessage from './serverMessageReducer';
import comparison from './comparisonReducer';
import selectedOffer from './selectedOfferReducer';
import config from './configReducer';
import confirmDetail from './confirmDetailReducer';
import content from './contentReducer';
import registration from './registrationReducer';
import user from './userReducer';
import userBills from './userBillsReducer';
import selectedAgent from './selectedAgentReducer';
import landing from './landingReducer';
import directSwitch from './directSwitchReducer';
import dialogOptions from './dialogOptionsReducer';
import globalOptions from './globalOptionsReducer';
import framePartner from './framePartnerReducer';
import signalr from './signalrReducer';
import articles from './articlesReducer';
import survey from './surveyReducer';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    application,
    session,
    ajaxCall,
    serverMessage,
    comparison,
    registration,
    user,
    userBills,
    selectedOffer,
    config,
    confirmDetail,
    content,
    selectedAgent,
    landing,
    directSwitch,
    dialogOptions,
    globalOptions,
    framePartner,
    signalr,
    articles,
    survey
  });

export default rootReducer;
