import React from 'react';

// Note: these are written to work with react-helmet. It may look like these can be done as function components but that does not work within a <Helmet> tag

let taboolaConversionScript = (isLoaded, taboolaId, taboolaLabel) => {
  var result =
    isLoaded && taboolaId && taboolaLabel ? (
      <script>{`_tfa.push({notify: 'event', name: '${taboolaLabel}', id: ${taboolaId}});`}</script>
    ) : null;

  return result;
};

export { taboolaConversionScript };
