import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function contentReducer(state = initialState.content, action) {
  switch (action.type) {
    case types.SET_PRIVACY_POLICY:
      return objectAssign({}, state, action.data);

    case types.SET_TERMS_AND_CONDITIONS:
      return objectAssign({}, state, action.data);

    case types.SET_CONCIERGE_TERMS_AND_CONDITIONS:
      return objectAssign({}, state, action.data);

    case types.SET_CONTENT:
      return objectAssign({}, state, action.data);

    default:
      return state;
  }
}
