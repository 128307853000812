import React from 'react';
import { useSelector } from 'react-redux';

import { occupancyTypes } from '../../enums/submissionTypes';
import { navigationTypes } from '../../enums/navigationTypes';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';
import CustomerCommissionLogo from '../common/customerCommissionLogo';
import ServiceIcon from '../serviceCard/serviceIcon';

const ComparisonEnd = ({ serviceType, occupancyType }) => {
  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { uxSwitchVersion } = useSelector(state => state.config.settings);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);

  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
  const hasCommission = agentServiceType.hasCommission;
  const currentPage = navigationTypes.getCurrentPage();

  const getIcon = () => {
    if (hasCommission) return <CustomerCommissionLogo serviceType={serviceType} showInfo />;
    return <ServiceIcon serviceType={serviceType} />;
  };

  const getHeading = () => {
    const occupancyTypeName = occupancyType === occupancyTypes.NEW ? 'connect' : 'check';
    if (currentPage === navigationTypes.COMPARE)
      return `Let's ${occupancyTypeName} your ${serviceType.Name.toLowerCase()} first...`;
    if (currentPage === navigationTypes.SWITCH && uxSwitchVersion === 2) return 'Got a question?';
    if (currentPage === navigationTypes.SWITCH) return "You're almost there!";
    return null;
  };

  const getSubheading = () => {
    if (currentPage === navigationTypes.COMPARE) {
      return "Simply select your preferred plan below and we'll arrange everything for you";
    }

    if (currentPage === navigationTypes.SWITCH) {
      if (uxSwitchVersion === 2)
        return (
          <div className="quick-tips-container">
            <div className="tip-icon">
              <img src={`${staticContentUrl}/images/misc/quick-tips.png`} alt="quick-tips" />
            </div>
            <div className="tip-text">Click on the Quick Tips icon in your application form to see if we can help</div>
          </div>
        );

      return 'Go ahead and complete your application below';
    }

    return null;
  };

  return (
    <div className="comparison-end">
      <div className="icon">{getIcon()}</div>
      <div className="heading">{getHeading()}</div>
      <div className="sub-heading">{getSubheading()}</div>
    </div>
  );
};
export default ComparisonEnd;
