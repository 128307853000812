import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { useAuth0 } from '../../auth/auth0-Provider.js';
import QsButtonGroup from '../common/qsButtonGroup';
import QsCheckBox from '../common/qsCheckBox';
import OrganiserName from '../common/organiserName.js';

import { getServiceCards } from '../../actions/serviceCardActions.js';
import { conciergePreferencesSelectMeterId } from '../../actions/globalOptionsActions';
import { setServerMessageClear } from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';

import ConciergeTermsAndConditionsDialog from '../dialogs/conciergeTermsAndConditionsDialog';
import ConciergeProvidersDialog from '../dialogs/conciergeProvidersDialog';
import GeneralDialog from '../dialogs/generalDialog';

const ConciergePreferences = props => {
  const { updatePreferences } = props;

  const { loaded: userBillsLoaded, data: userBillsData } = useSelector(state => state.userBills);
  const { meterPreferences: initialPreferences } = useSelector(state => state.user.preferences.concierge);
  const { programDisplayName, panelProviders } = useSelector(state => state.config);
  const { versionNumber } = useSelector(state => state.content.conciergeTermsAndConditions);
  const { selectMeterId } = useSelector(state => state.globalOptions.concierge);

  const createMeterPreferences = (isNew, enabled) => {
    return {
      isNew,
      enabled,
      betterOfferPercentageMinimum: '5',
      includeExitFeePlans: null,
      includePayOnTimePlans: null,
      includeInferiorSolarPlans: null,
      switchFrequency: '12',
      excludedProviderCodes: [],
      emailNotification: false,
      smsNotification: false
    };
  };

  const [hasChanges, setHasChanges] = useState(false);
  const [disableConciergeDialogTrigger, setDisableConciergeDialogTrigger] = useState(false);
  const [convertedUserBills, setConvertedUserBills] = useState([]);
  const [selectedSupplyAddress, setSelectedSupplyAddress] = useState({});
  const [localPreferences, setLocalPreferences] = useState({ ...initialPreferences }); // local copy of the user's meter preferences.
  const [selectedPreferences, setSelectedPreferences] = useState(createMeterPreferences(true, false)); // the currently selected user's meter preference set.
  const [acceptContact, setAcceptContact] = useState(false);
  const [acceptAuthorized, setAcceptAuthorized] = useState(false);
  const [acceptConciergeTerms, setAcceptConciergeTerms] = useState(false);

  const dispatch = useDispatch();
  const { getAccessToken } = useAuth0();

  const hasConvertedUserBills = convertedUserBills.length > 0;
  const defaultMeterPreferences = createMeterPreferences(true, hasConvertedUserBills);

  // --------------------------------------------------------------------------
  // Initialize form when user bill data is loaded
  // --------------------------------------------------------------------------
  useEffect(() => {
    const fn = async () => {
      if (!userBillsLoaded) {
        dispatch(getServiceCards(await getAccessToken()));
      } else if (userBillsData.length > 0) {
        initialize();
      }
    };
    fn();
  }, [userBillsLoaded]);

  // --------------------------------------------------------------------------
  // Reset meter preferences when reloading initialPreferences after Save().
  // --------------------------------------------------------------------------
  useEffect(() => {
    onReset();
  }, [initialPreferences]);

  // --------------------------------------------------------------------------
  // Initialize form on load.
  // Needs to be declared after above effect that calls onReset().
  // --------------------------------------------------------------------------
  useEffect(() => {
    initialize();
  }, []);

  const initialize = () => {
    const convertedUserBills = userBillsData.filter(b => b.linked || b.awaitingLink);

    if (convertedUserBills.length > 0) {
      const userBill = convertedUserBills[0];

      setConvertedUserBills(convertedUserBills);

      supplyAddressChange(convertedUserBills, selectMeterId || userBill.lastBillMeterId);
    }
  };

  // --------------------------------------------------------------------------
  // Detect changes in form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    let preferences = initialPreferences[selectedSupplyAddress.lastBillMeterId];
    if (!preferences) preferences = defaultMeterPreferences;

    let changed =
      selectedPreferences.betterOfferPercentageMinimum !== preferences.betterOfferPercentageMinimum ||
      getButtonGroupValue(selectedPreferences.includeExitFeePlans) !==
        getButtonGroupValue(preferences.includeExitFeePlans) ||
      getButtonGroupValue(selectedPreferences.includePayOnTimePlans) !==
        getButtonGroupValue(preferences.includePayOnTimePlans) ||
      getButtonGroupValue(selectedPreferences.includeInferiorSolarPlans) !==
        getButtonGroupValue(preferences.includeInferiorSolarPlans) ||
      selectedPreferences.switchFrequency !== preferences.switchFrequency ||
      selectedPreferences.smsNotification !== preferences.smsNotification ||
      selectedPreferences.emailNotification !== preferences.emailNotification ||
      !exclusionsMatch(selectedPreferences.excludedProviderCodes, preferences.excludedProviderCodes);

    setHasChanges(changed);
  }, [
    selectedPreferences.betterOfferPercentageMinimum,
    selectedPreferences.includeExitFeePlans,
    selectedPreferences.includePayOnTimePlans,
    selectedPreferences.includeInferiorSolarPlans,
    selectedPreferences.switchFrequency,
    selectedPreferences.smsNotification,
    selectedPreferences.emailNotification,
    selectedPreferences.excludedProviderCodes
  ]);

  // --------------------------------------------------------------------------
  // Determine if provider exclusions have been modified.
  // --------------------------------------------------------------------------
  const exclusionsMatch = (modified, existing) => {
    const providers = panelProviders.filter(
      p => p.provider.billTypes.filter(bt => bt.id === selectedSupplyAddress.billTypeId).length > 0
    );

    let matched = true;

    providers.forEach(element => {
      const mp = modified.find(p => p === element.provider.code);
      const ep = existing.find(p => p === element.provider.code);

      if (mp !== ep) {
        matched = false;
      }
    });

    return matched;
  };

  // --------------------------------------------------------------------------
  // Handle supply address changes.
  // --------------------------------------------------------------------------
  const supplyAddressChange = (convertedUserBills, meterId) => {
    if (!meterId) {
      setSelectedPreferences(createMeterPreferences(true, false));
      setSelectedSupplyAddress({});
      dispatch(conciergePreferencesSelectMeterId(null));
      return;
    }

    let currentMeterPreferences = meterId ? localPreferences[meterId] : defaultMeterPreferences; // Find the local meter preferences for the selected meterId.

    // Create missing meter preferences if required.
    if (currentMeterPreferences === undefined) {
      currentMeterPreferences = createMeterPreferences(true, true);
    }

    updateLocalPreferences(meterId, currentMeterPreferences);

    const currentUserBill = convertedUserBills.find(d => d.lastBillMeterId === meterId); // Find the converted bill for the selected meterId.

    setSelectedSupplyAddress(currentUserBill);

    dispatch(conciergePreferencesSelectMeterId(currentUserBill.lastBillMeterId));
  };

  // --------------------------------------------------------------------------
  // Supply address dropdown changed.
  // --------------------------------------------------------------------------
  const onSupplyAddressChanged = meterId => {
    supplyAddressChange(convertedUserBills, meterId);
    dispatch(setServerMessageClear(serverMessageTargets.PREFERENCES));
  };

  // --------------------------------------------------------------------------
  // Excluded providers update callback.
  // --------------------------------------------------------------------------
  const onExcludedProvidersChanged = providerCodes => {
    updateLocalPreferenceSetting({ excludedProviderCodes: providerCodes });
  };

  // --------------------------------------------------------------------------
  // Enable / Disable button clicked.
  // --------------------------------------------------------------------------
  const onEnableChanged = enabled => {
    setDisableConciergeDialogTrigger(!enabled);

    if (enabled) {
      dispatch(setServerMessageClear(serverMessageTargets.PREFERENCES));
      updateLocalPreferences(selectedSupplyAddress.lastBillMeterId, createMeterPreferences(true, true));
    }
  };

  const updateLocalPreferenceSetting = setting => {
    const ump = {
      ...selectedPreferences,
      ...setting
    };

    updateLocalPreferences(selectedSupplyAddress.lastBillMeterId, ump);
  };

  const updateLocalPreferences = (meterId, preferences) => {
    const ump = { ...localPreferences };

    ump[meterId] = preferences;

    setLocalPreferences(ump);
    setSelectedPreferences(preferences);
  };

  // --------------------------------------------------------------------------
  // Save current user meter preferences.
  // --------------------------------------------------------------------------
  const onSave = async () => {
    // Create an object with the current user meter preferences. We are saving preferences per meter, one at a time.
    const meterPreferences = {};

    meterPreferences[selectedSupplyAddress.lastBillMeterId] = selectedPreferences;

    let data = {
      concierge: {
        meterPreferences,
        acceptContact,
        acceptAuthorized,
        acceptConciergeTerms,
        versionNumber
      }
    };

    updatePreferences(data, onPreferencesSaved);
  };

  const onPreferencesSaved = () => {
    setHasChanges(false);
  };

  const onDisable = async () => {
    // Create an object with the current user meter preferences. We are saving preferences per meter, one at a time.
    const meterPreferences = {};

    meterPreferences[selectedSupplyAddress.lastBillMeterId] = { ...createMeterPreferences(false, false) };

    let data = {
      concierge: {
        meterPreferences
      }
    };

    setAcceptContact(false);
    setAcceptAuthorized(false);
    setAcceptConciergeTerms(false);

    updatePreferences(data, onPreferencesSaved);
  };

  // --------------------------------------------------------------------------
  // Reset current user meter preferences to initial state.
  // --------------------------------------------------------------------------
  const onReset = () => {
    let preferences = initialPreferences[selectedSupplyAddress.lastBillMeterId];
    if (!preferences) preferences = defaultMeterPreferences;

    updateLocalPreferences(selectedSupplyAddress.lastBillMeterId, preferences);
  };

  const switchMonths = [12, 15, 18, 21, 24, 30, 36];

  const renderLabel = label => {
    return (
      <Col
        className={`qs-preferences-content-label${selectedPreferences.enabled === false ? ' disabled' : ''}`}
        xs="12"
        lg="5"
      >
        {label}
      </Col>
    );
  };

  const getButtonGroupValue = value => {
    return value === true || value === 'true' ? 'true' : value === false || value === 'false' ? 'false' : null;
  };

  const showDialog = (heading, message, onClose) => {
    return (
      <GeneralDialog
        confirmButtonText="Yes"
        closeButtonText="No"
        dialogOpen={true}
        showCloseIcon={false}
        onClose={onClose}
      >
        <Container>
          <Row>
            <Col className="qs-modal-heading">
              <h1>{heading}</h1>
            </Col>
          </Row>

          <Row>
            <Col className="qs-general-dialog-message">{message}</Col>
          </Row>
        </Container>
      </GeneralDialog>
    );
  };

  return (
    <>
      <Container className="sm-container">
        <div className="qs-preferences-content-heading">Set up Concierge Preferences</div>
        <div className="qs-preferences-content-intro">
          <p>
            Take complete control of your bills permanently by setting up your Concierge preferences here. You can
            change these at any time through the 'preferences' section in your <OrganiserName nameOnly /> dashboard.
          </p>
          <p>
            When Concierge finds a deal that matches your preferences (below) we'll inform you via email and/or SMS that
            you will be moved to that new deal in 3 days time. If you do not wish to move to the new deal, you can
            simply respond 'NO' to the email and/or SMS and you’ll remain on your current plan.
          </p>
          <p>
            Please set up you Concierge preferences for each household service below. Concierge will only inform you of
            a new deal when ALL of your preferences are achieved.
          </p>
        </div>
        <hr />

        {userBillsLoaded && convertedUserBills.length === 0 && (
          <div className="concierge-preferences-empty-text">
            Looks like you have not switched any services yet. Services switched using {programDisplayName} will appear
            here.
          </div>
        )}

        {!userBillsLoaded && <div className="concierge-preferences-loading-text">Loading your preferences...</div>}

        {userBillsLoaded && (
          <>
            {disableConciergeDialogTrigger &&
              !selectedPreferences.isNew &&
              showDialog(
                'Disable Concierge?',
                `You are about to disable Concierge for ${selectedSupplyAddress.lastBillMeterAddress}. Are you sure?`,
                result => {
                  if (result) {
                    onDisable();
                  }
                  setDisableConciergeDialogTrigger(false);
                }
              )}

            <AvForm onValidSubmit={event => onSave(event)}>
              <Row>
                <Col className="qs-preferences-content-label" xs="12" lg="5">
                  For property address:
                </Col>
                {hasChanges && (
                  <Button
                    className="qs-button-submit-secondary qs-concierge-preferences-undo-button"
                    onClick={e => onReset(e)}
                  >
                    Undo changes
                  </Button>
                )}
                <Col>
                  <AvField
                    disabled={hasChanges}
                    bsSize="sm"
                    type="select"
                    name="meter"
                    onChange={e => onSupplyAddressChanged(e.target.value)}
                    value={selectedSupplyAddress ? selectedSupplyAddress.lastBillMeterId : null}
                  >
                    <option key="idtype-default" value="">
                      Please select supply address
                    </option>
                    {convertedUserBills.map((ref, i) => (
                      <option key={`idtype-${ref.lastBillMeterId}`} value={ref.lastBillMeterId}>
                        {ref.lastBillMeterAddress} ({ref.billType})
                      </option>
                    ))}
                  </AvField>

                  {selectedPreferences.isNew ? (
                    <div className="qs-concierge-preferences-new-text">
                      You have not set up Concierge preferences for this address yet.
                    </div>
                  ) : (
                    <Button
                      className="qs-button-submit-secondary qs-concierge-preferences-enable-button"
                      onClick={() => onEnableChanged(!selectedPreferences.enabled)}
                    >
                      {`${selectedPreferences.enabled ? 'Disable' : 'Enable'} Concierge for this address`}
                    </Button>
                  )}
                </Col>
              </Row>
              <hr />

              <Row className="qs-concierge-preferences-reduction-percentage">
                {renderLabel('The new deal achieves a bill reduction of at least')}
                <Col xs="6" sm="8" md="6">
                  <FormGroup>
                    <InputGroup>
                      <AvField
                        disabled={selectedPreferences.enabled === false}
                        className={`qs-concierge-preferences-field${selectedPreferences.enabled ? '' : ' disabled'}`}
                        name={'billReductionAvailable'}
                        type="text"
                        bsSize="sm"
                        value={selectedPreferences.betterOfferPercentageMinimum.toString()}
                        onChange={e => {
                          updateLocalPreferenceSetting({
                            betterOfferPercentageMinimum: e.target.value === '' ? 0 : e.target.value
                          });
                        }}
                        validate={{
                          min: { value: 0, errorMessage: 'Please enter a value >= 0' },
                          max: { value: 100, errorMessage: 'Please enter a value <= 100' },
                          maxLength: { value: 5 },
                          number: true,
                          required: { value: true, errorMessage: 'Required' }
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          className={`qs-concierge-preferences-percentage${
                            selectedPreferences.enabled ? '' : ' disabled'
                          }`}
                        >
                          %
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                {renderLabel('The new deal can have exit fees')}
                <Col className="qs-concierge-preferences-button-group">
                  <QsButtonGroup
                    enabled={selectedPreferences.enabled === true}
                    hasChanges={hasChanges}
                    required={true}
                    fieldName="Include exit fee plans setting"
                    id="includeExitFeePlans"
                    selectedValue={getButtonGroupValue(selectedPreferences.includeExitFeePlans)}
                    onTouchButton={e =>
                      updateLocalPreferenceSetting({
                        includeExitFeePlans: e.target.getAttribute('data-value')
                      })
                    }
                    additionalClass="qs-concierge-preferences-button-group"
                    buttons={[
                      {
                        label: 'Yes',
                        value: 'true'
                      },
                      {
                        label: 'No',
                        value: 'false'
                      }
                    ]}
                  />
                </Col>
              </Row>

              <Row>
                {renderLabel('The new deal can have pay-on-time discounts')}
                <Col className="qs-concierge-preferences-button-group">
                  <QsButtonGroup
                    enabled={selectedPreferences.enabled === true}
                    required={true}
                    hasChanges={hasChanges}
                    fieldName="Include pay-on-time setting"
                    id="includePayOnTimePlans"
                    selectedValue={getButtonGroupValue(selectedPreferences.includePayOnTimePlans)}
                    onTouchButton={e =>
                      updateLocalPreferenceSetting({
                        includePayOnTimePlans: e.target.getAttribute('data-value')
                      })
                    }
                    additionalClass="qs-concierge-preferences-button-group"
                    buttons={[
                      {
                        label: 'Yes',
                        value: 'true'
                      },
                      {
                        label: 'No',
                        value: 'false'
                      }
                    ]}
                  />
                </Col>
              </Row>

              <Row>
                {renderLabel('The new deal has solar credits the same or higher than my current plan')}
                <Col className="qs-concierge-preferences-button-group">
                  <QsButtonGroup
                    enabled={selectedPreferences.enabled === true}
                    required={true}
                    hasChanges={hasChanges}
                    fieldName="Solar plan setting"
                    id="solarPlanMatchEarnings"
                    selectedValue={getButtonGroupValue(selectedPreferences.includeInferiorSolarPlans)}
                    onTouchButton={e =>
                      updateLocalPreferenceSetting({
                        includeInferiorSolarPlans: e.target.getAttribute('data-value')
                      })
                    }
                    additionalClass="qs-concierge-preferences-button-group"
                    buttons={[
                      {
                        label: 'Yes',
                        value: 'false'
                      },
                      {
                        label: 'No',
                        value: 'true'
                      }
                    ]}
                  />
                </Col>
              </Row>

              <Row>
                {renderLabel("Don't switch me more often than every")}
                <Col>
                  <AvField
                    disabled={!selectedPreferences.enabled}
                    className={`qs-concierge-preferences-field${selectedPreferences.enabled ? '' : ' disabled'}`}
                    bsSize="sm"
                    type="select"
                    name="switchFrequency"
                    onChange={e => {
                      updateLocalPreferenceSetting({
                        switchFrequency: e.target.value
                      });
                    }}
                    value={selectedPreferences.switchFrequency}
                  >
                    {switchMonths.map((ref, i) => (
                      <option key={`idtype-${ref}`} value={ref}>
                        {`${ref} months`}
                      </option>
                    ))}
                  </AvField>
                </Col>
              </Row>

              <Row className="qs-mb-md">
                {renderLabel("Energy companies I don't want to consider")}
                <Col>
                  <ConciergeProvidersDialog
                    billTypeId={selectedSupplyAddress.billTypeId}
                    enabled={selectedPreferences.enabled}
                    providerCodes={selectedPreferences.excludedProviderCodes}
                    updateExcludedProviders={onExcludedProvidersChanged}
                  />
                </Col>
              </Row>

              <Row>
                {renderLabel('Provide me 3 days notice that a switch will occur by')}
                <div className="qs-concierge-preferences-notification">
                  <Col>
                    <QsCheckBox
                      label="Email"
                      enabled={selectedPreferences.enabled}
                      isChecked={selectedPreferences.emailNotification}
                      onCheck={() =>
                        updateLocalPreferenceSetting({
                          emailNotification: !selectedPreferences.emailNotification
                        })
                      }
                    />
                    <QsCheckBox
                      label="SMS"
                      enabled={selectedPreferences.enabled}
                      isChecked={selectedPreferences.smsNotification}
                      onCheck={() =>
                        updateLocalPreferenceSetting({
                          smsNotification: !selectedPreferences.smsNotification
                        })
                      }
                    />
                  </Col>
                </div>
              </Row>

              <div className="qs-concierge-preferences-terms">
                <hr />
                <Row className="qs-concierge-preferences-term-item">
                  <Col xs="10" sm="9" md="10" xl="11" className="qs-concierge-preferences-term-text">
                    I understand that I will be informed about a switch using the contact details provided above, and
                    can opt-out of any deal offered by Concierge
                  </Col>
                  <Col>
                    <QsCheckBox
                      isChecked={acceptContact}
                      enabled={selectedPreferences.enabled}
                      onCheck={() => setAcceptContact(!acceptContact)}
                    />
                  </Col>
                </Row>

                <Row className="qs-concierge-preferences-term-item">
                  <Col xs="10" sm="9" md="10" xl="11" className="qs-concierge-preferences-term-text">
                    I am authorised to manage and change this utility service at this address
                  </Col>
                  <Col>
                    <QsCheckBox
                      isChecked={acceptAuthorized}
                      enabled={selectedPreferences.enabled}
                      onCheck={() => setAcceptAuthorized(!acceptAuthorized)}
                    />
                  </Col>
                </Row>

                <Row className="qs-concierge-preferences-term-item">
                  <Col xs="10" sm="9" md="10" xl="11" className="qs-concierge-preferences-term-text">
                    I've read and agree to the Concierge <ConciergeTermsAndConditionsDialog />
                  </Col>
                  <Col>
                    <QsCheckBox
                      isChecked={acceptConciergeTerms}
                      enabled={selectedPreferences.enabled}
                      onCheck={() => setAcceptConciergeTerms(!acceptConciergeTerms)}
                    />
                  </Col>
                </Row>

                <div className="qs-concierge-preferences-term-error">
                  <AvField
                    bsSize="sm"
                    type="text"
                    name="hiddenTc"
                    className="qs-tc-hidden "
                    validate={{
                      hidden: (value, ctx, input, cb) => {
                        cb(
                          selectedPreferences.enabled &&
                            hasChanges &&
                            (!acceptContact || !acceptAuthorized || !acceptConciergeTerms)
                            ? 'Please accept all terms and conditions'
                            : true
                        );
                      }
                    }}
                  />
                </div>
              </div>

              {hasConvertedUserBills && (
                <div className="sm-preferences-save-button">
                  <Button disabled={!selectedPreferences.enabled || !hasChanges} className="sm-button-primary">
                    Save
                  </Button>
                </div>
              )}
            </AvForm>
          </>
        )}
      </Container>
    </>
  );
};

export default ConciergePreferences;
