import * as types from './actions';
import axios from 'axios';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import { push } from 'connected-react-router';
import { goHome } from './comparisonActions';

export function setArticlesSuccess(articles) {
  return { type: types.SET_ARTICLES, articles };
}

export function setArticles() {
  return dispatch => {
    dispatch(setAjaxCallStart());

    axios
      .get('/api/info/articles')
      .then(response => {
        var data = response.data;

        if (data) {
          dispatch(setArticlesSuccess({ isLoaded: true, ...data }));
        } else {
          dispatch(goHome());
        }
      })
      .catch(() => {
        dispatch(goHome());
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function getArticle(slug, onSuccess) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    axios
      .get(`/api/info/articles/${slug}`)
      .then(response => {
        var data = response.data;

        if (data) {
          onSuccess(data);
        } else {
          dispatch(push('/articles'));
        }
      })
      .catch(() => {
        dispatch(push('/articles'));
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}
