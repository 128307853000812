import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _debounce from 'lodash.debounce';
import { push } from 'connected-react-router';
import { AvForm } from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';

import { useAuth0 } from '../../auth/auth0-Provider';

import SubmitBillFileUploader from '../common/submitBillFileUploader';
import SubmitCommon from '../common/submitCommon';
import { submitAutoEstimates } from '../serviceCard/serviceCardUtilities';

import { getSessionActivity } from '../../utilities/commonUtilities';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';

import * as serverMessageTargets from '../../actions/serverMessageTargets';
import {
  comparisonErrorMessage,
  displayErrorMessage,
  displayNoOffersMessage,
  setServerMessageClear
} from '../../actions/serverMessageActions';
import { submitBillUnauthenticated, submitBillWithKey, submitBillWithToken } from '../../actions/energyBillActions';
import { addSessionActivity } from '../../actions/sessionActivityActions';

import { submissionTypes } from '../../enums/submissionTypes';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes';
import { billTypes } from '../../enums/billTypes';
import ServerMessage from '../common/serverMessage';

const UploadBillSelector = ({ serviceType, occupancyPurpose, onSuccess, onSkip }) => {
  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { serverMessage, selectedAgent, session } = useSelector(state => state);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);
  const { data: userBills } = useSelector(state => state.userBills);
  const { accessKey, accessId } = useSelector(state => state.comparison);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);

  const [files, setFiles] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submissionCommon, setSubmissionCommon] = useState({});

  const sessionActivity = getSessionActivity(session);

  const target = serverMessageTargets.ESTIMATE_WIZARD_BILL_UPLOAD;
  const hasServerMessage = serverMessage && serverMessage.code !== null;

  const valid = files && files.length > 0 && submissionCommon.termsAndConditions;

  // --------------------------------------------------------------------------
  // Invalid form submit handler.
  // --------------------------------------------------------------------------
  const handleInvalidSubmit = () => {
    setHasSubmitted(true);
  };

  // --------------------------------------------------------------------------
  // Submission common handlers.
  // --------------------------------------------------------------------------
  const onSubmissionCommonChange = value => {
    if (value) setSubmissionCommon(value);
  };

  // --------------------------------------------------------------------------
  // Submit bill handler.
  // --------------------------------------------------------------------------
  const onSubmitBill = useCallback(
    _debounce(
      () => {
        dispatch(setServerMessageClear(target, serverMessage));

        const bill = {
          files,
          occupancyPurpose: occupancyPurpose.Id,
          ...submissionCommon,
          emailAddress: null, // only supporting sites with anonymous modes
          sendComparisonEmail: false,
          sendArticlesEmail: false,
          sendSolarInstallationEmail: false,
          sessionActivity
        };

        if (accessKey && accessId) {
          const data = { bill, access: { key: accessKey, id: accessId } };
          dispatch(submitBillWithKey(data, onComplete, onError, onVerifyResponse, serviceType));
        } else if (isUserAuthenticated()) {
          const fn = async () => {
            const accessToken = await getAccessToken();
            dispatch(submitBillWithToken(accessToken, { bill }, onComplete, onError, onVerifyResponse, serviceType));
          };
          fn();
        } else {
          dispatch(submitBillUnauthenticated({ bill }, onComplete, onError, onVerifyResponse, serviceType));
        }

        standardEvents('Upload-Bill-Selector', serviceType.Name, 'Submit-Bill', googleAnalyticsClientCode);
      },
      2000,
      { leading: true }
    ),
    [files, submissionCommon, accessKey, accessId]
  );

  // --------------------------------------------------------------------------
  // Bill comparison verify handler.
  // --------------------------------------------------------------------------
  const onVerifyResponse = () => {
    dispatch(
      displayErrorMessage(
        target,
        "Sorry, we were unable to upload your bill. Please press 'Skip' to enter your property details.",
        true
      )
    );
  };

  // --------------------------------------------------------------------------
  // Bill comparison error handler.
  // --------------------------------------------------------------------------
  const onError = error => {
    dispatch(comparisonErrorMessage(error, target));
  };

  // --------------------------------------------------------------------------
  // Add session activity.
  // --------------------------------------------------------------------------
  const recordSessionActivity = (comparisonResult, userBill) => {
    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          submissionType: submissionTypes.BILL,
          submissionSubType: 'Wizard',
          submissionId: userBill.submissionId,
          serviceType: serviceType.Code,
          hasOffers: comparisonResult.hasOffers
        },
        activityType: sessionActivityTypes.COMPARISON_COMPLETED
      })
    );
  };

  // --------------------------------------------------------------------------
  // Bill comparison complete handler.
  // --------------------------------------------------------------------------
  const onComplete = (comparisonResult, userBill) => {
    recordSessionActivity(comparisonResult, userBill);

    const billServiceType = billTypes.getById(userBill.billTypeId, billTypes.NONE);
    const selectedAddress = { ...userBill.bill.propertyAddress, isAddress: true };

    dispatch(
      submitAutoEstimates(
        billServiceType,
        selectedAddress,
        selectedAgent,
        sessionActivity,
        {
          emailAddress: null // only supporting sites with anonymous modes
        },
        agentBillTypes,
        userBills,
        googleAnalyticsClientCode
      )
    );

    if (!comparisonResult.hasOffers) {
      dispatch(displayNoOffersMessage(target));
    } else {
      if (onSuccess) {
        // Service type may be different after bill has been extracted.
        onSuccess(billServiceType);
      }

      dispatch(push('/compare'));
    }
  };

  return (
    <div className="upload-bill-selector">
      <AvForm onValidSubmit={e => onSubmitBill(e)} onInvalidSubmit={handleInvalidSubmit}>
        {hasServerMessage ? (
          <ServerMessage serverMessage={serverMessage} target={target} />
        ) : (
          <SubmitBillFileUploader files={files} setFiles={setFiles} simpleMode={true} />
        )}
        <SubmitCommon
          hasSubmitted={hasSubmitted}
          onLoad={onSubmissionCommonChange}
          onChange={onSubmissionCommonChange}
          hideEmailAddress={true}
          showSendComparisonEmail={false}
          showSendArticlesEmail={false}
          showSendSolarInstallationEmail={false}
          serviceType={serviceType}
        />
        <div className="footer">
          <div className="buttons">
            <div>
              <Button className="sm-button-primary medium static" disabled={!valid}>
                Next
              </Button>
            </div>

            <div className="skip-button-container">
              <div className="skip-wrapper" onClick={onSkip}>
                <div className="navigation-arrow">
                  Skip
                  <div className="arrow material-icons" aria-hidden="true">
                    arrow_forward_ios
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AvForm>
    </div>
  );
};
export default UploadBillSelector;
