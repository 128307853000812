import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  submitEstimateWithToken,
  submitEstimateWithKey,
  submitEstimateUnathenticated
} from '../../actions/energyEstimateActions';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Row, Col, Button } from 'reactstrap';
import { useAuth0 } from '../../auth/auth0-Provider';
import SubmitCommon from '../common/submitCommon';
import ExitLaunch from '../common/exitLaunch';
import ServicesAuto from '../serviceCard/servicesAuto';
import { submissionTypes } from '../../enums/submissionTypes';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import {
  setServerMessageClear,
  displayNoOffersMessage,
  comparisonErrorMessage
} from '../../actions/serverMessageActions';
import { setUser } from '../../actions/userActions';
import ServerMessage from '../common/serverMessage';

import 'react-google-places-autocomplete/dist/index.min.css';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getAddress, hasValidAddress } from '../../utilities/addressUtilities';
import { submitAutoEstimates } from '../serviceCard/serviceCardUtilities';
import AddressControl from '../common/addressControl';
import YourDetails from '../common/yourDetails';
import { getSessionActivity } from '../../utilities/commonUtilities';
import { addSessionActivity } from '../../actions/sessionActivityActions';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes';
import PoweredBySmartMe from '../common/poweredBySmartMe';
import BrandScroller from '../common/brandScroller';
import { billTypes } from '../../enums/billTypes';
import { agentOperatingModes } from '../../enums/launchTypes';
import SolarFinanceWizard from '../solarFinanceWizard/solarFinanceWizard';
import { preferredCallbackPeriods } from '../../enums/preferredCallbackPeriods';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const SolarFinanceAu = props => {
  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { accessKey: comparisonAccessKey, accessId: comparisonAccessId, isLoaded: comparisonLoaded } = useSelector(
    state => state.comparison
  );
  const {
    accessKey: userAccessKey,
    accessId: userAccessId,
    firstName,
    surname,
    mobilePhoneNumberNational
  } = useSelector(state => state.user);

  const { serverMessage, selectedAgent, session } = useSelector(state => state);
  const { billTypes: agentBillTypes, country } = useSelector(state => state.config);
  const { googleAnalyticsClientCode, showPoweredBy } = useSelector(state => state.config.settings);
  const { mobilePhoneNumberRegex } = useSelector(state => state.config.applicationSettings.country);
  const { data: userBills } = useSelector(state => state.userBills);
  const { operatingMode } = useSelector(state => state.config.settings);
  const { emailAddress: userEmailAddress } = useSelector(state => state.user);

  const agentMode = agentOperatingModes.getById(operatingMode);

  const sessionActivity = getSessionActivity(session);

  const {
    serviceType,
    launchMode,
    closeButtonText,
    confirmButtonText,
    showBackButton,
    showCancelButton,
    showCloseButton,
    addressLocked,
    userInitiated,
    submissionId,
    submission,
    onBackButtonClick,
    onCancel,
    onClose,
    onSuccess
  } = props;

  const {
    billType,
    propertyAddress,
    solarInstallType,
    homeLoan,
    greenLoan,
    estimatedCost,
    ready,
    notReady,
    defaultComparison,
    emailAddress
  } = submission;

  const detailsAlreadyLoaded = userAccessId !== null && hasValidAddress(propertyAddress);

  const getFullAddress = address => {
    return hasValidAddress(address)
      ? `${address.unitNumber} ${address.streetNumber} ${address.route} ${address.suburb} ${address.state}, ${address.country}`
      : '';
  };

  const { ESTIMATE_DIALOG: target, INTERNET_SPECIFIC: internetTarget } = serverMessageTargets;

  const initialAddress = getAddress(propertyAddress);
  const [wizardComplete, setWizardComplete] = useState(false);
  const [wizardKey, setWizardKey] = useState(0);

  const [preferredCallbackPeriod, setPreferredCallbackPeriod] = useState(preferredCallbackPeriods.ANYTIME.Id);
  const [preferredCallbackDate, setPreferredCallbackDate] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(initialAddress);
  const [selectedSolarInstallType, setSelectedSolarInstallType] = useState(solarInstallType);
  const [selectedHomeLoan, setSelectedHomeLoan] = useState(homeLoan);
  const [selectedGreenLoan, setSelectedGreenLoan] = useState(greenLoan);
  const [selectedEstimatedCost, setSelectedEstimatedCost] = useState(estimatedCost);
  const [selectedReady, setSelectedReady] = useState(ready);
  const [selectedNotReady, setSelectedNotReady] = useState(notReady);
  const [submitNoLongerRequired, setSubmitNoLongerRequired] = useState(false);

  const [yourDetails, setYourDetails] = useState({
    firstName,
    surname,
    phoneNumber: mobilePhoneNumberNational
  });

  const [hasChanges, setHasChanges] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submissionCommon, setSubmissionCommon] = useState({});
  const [reset, setReset] = useState();
  const [initialEmailAddressValue, setInitialEmailAddressValue] = useState(null);

  // --------------------------------------------------------------------------
  // Submit new estimate request.
  // --------------------------------------------------------------------------
  const onSubmitEstimate = () => {
    const estimate = getEstimate();

    // Check if anything has changed before making a re-estimate request.
    // User initiated considered (i.e. hasChanges = false), as user may have triggered the dialog and accepted a complete set of defaults (if possible)
    if (hasChanges || userInitiated || defaultComparison || isComparisonError()) {
      const accessKey = getAccessKey();

      const options = { showSpinner: true, applySleep: true };

      if (accessKey.key && accessKey.id) {
        onSubmitWithKey(
          serviceType,
          { estimate, access: { key: accessKey.key, id: accessKey.id } },
          onComplete,
          options
        );
      } else if (isUserAuthenticated()) {
        onSubmitWithToken(serviceType, { estimate }, onComplete, options);
      } else {
        onSubmitUnathenticated(serviceType, { estimate }, onComplete, options);
      }

      standardEvents('solar-finance-dialog', 'Click', 'Confirm', googleAnalyticsClientCode);
    } else {
      onSuccess({
        hasOffers: true,
        estimateWasRun: false,
        userBill: {}
      });
    }
  };

  const onSubmitNotReadyEstimate = () => {
    const estimate = getEstimate();
    const accessKey = getAccessKey();

    const options = {
      showSpinner: false,
      applySleep: false
    };

    if (accessKey.key && accessKey.id) {
      onSubmitWithKey(
        serviceType,
        { estimate, access: { key: accessKey.key, id: accessKey.id } },
        onCompleteNotReady,
        options
      );
    } else if (isUserAuthenticated()) {
      onSubmitWithToken(serviceType, { estimate }, onCompleteNotReady, options);
    } else {
      onSubmitUnathenticated(serviceType, { estimate }, onCompleteNotReady, options);
    }
  };

  const isComparisonError = () => {
    // If a the user is on the compare page (i.e. comparison is loaded) but the access details are missing, they were cleared because of an error with the last estimate submit.
    // so we need to force an API call (even if nothing else on the form changed; i.e. hasChanges = false)
    return comparisonLoaded && (!comparisonAccessId || !comparisonAccessKey);
  };

  const getEstimate = () => {
    return {
      address: {
        ...selectedAddress
      },
      ...submissionCommon,
      sessionActivity,
      agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
      serviceType: billType,
      solarInstallType: selectedSolarInstallType,
      homeLoan: selectedHomeLoan,
      greenLoan: selectedGreenLoan,
      estimatedCost: selectedEstimatedCost,
      ready: selectedReady,
      notReady: selectedNotReady,
      ...yourDetails,
      preferredCallbackPeriod,
      preferredCallbackDate: preferredCallbackDate ? moment(preferredCallbackDate).format('YYYY-MM-DD') : null
    };
  };

  const getAccessKey = () => {
    const userBill = userBills.find(e => e.submissionId === submissionId);
    const userBillAccessKey = userBill && userBill.accessKey ? userBill.accessKey.accessKey : null;
    const userBillAccessId = userBill && userBill.accessKey ? userBill.accessKey.accessId : null;

    const accessKey = userBillAccessKey || comparisonAccessKey || userAccessKey;
    const accessId = userBillAccessId || comparisonAccessId || userAccessId;

    return { key: accessKey, id: accessId };
  };

  const dispatchAutoEstimates = () => {
    const address = { ...selectedAddress, isAddress: true };

    dispatch(
      submitAutoEstimates(
        serviceType,
        address,
        selectedAgent,
        sessionActivity,
        submissionCommon,
        agentBillTypes,
        userBills,
        googleAnalyticsClientCode
      )
    );
  };

  const onSubmitWithToken = (serviceType, data, onCompletion, options) => {
    const fn = async () => {
      const accessToken = await getAccessToken();
      dispatch(submitEstimateWithToken(accessToken, serviceType, data, options, onCompletion, onError));
    };
    fn();
  };

  const onSubmitWithKey = (serviceType, data, onCompletion, options) => {
    dispatch(submitEstimateWithKey(serviceType, data, options, onCompletion, onError));
  };

  const onSubmitUnathenticated = (serviceType, data, onCompletion, options) => {
    dispatch(submitEstimateUnathenticated(serviceType, data, options, onCompletion, onError));
  };

  // --------------------------------------------------------------------------
  // Estimate comparison error handler.
  // --------------------------------------------------------------------------
  const onError = error => {
    dispatch(comparisonErrorMessage(error, target));
  };

  // --------------------------------------------------------------------------
  // Initialize the form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    initialize();
  }, []);

  // --------------------------------------------------------------------------
  // Invalid form submit handler.
  // --------------------------------------------------------------------------
  const handleInvalidSubmit = (event, errors, values) => {
    setHasSubmitted(true);
  };

  // --------------------------------------------------------------------------
  // Submission common handlers.
  // --------------------------------------------------------------------------
  const onSubmissionCommonLoad = value => {
    if (value) {
      setInitialEmailAddressValue(value.emailAddress);
      setSubmissionCommon(value);
    }
  };

  const onSubmissionCommonChange = value => {
    setSubmissionCommon(value);
  };

  const onYourDetailsChange = (e, data) => {
    setYourDetails({ ...yourDetails, ...data });
  };

  useEffect(() => {
    if (submitNoLongerRequired) {
      setSubmitNoLongerRequired(false);

      onSubmitNotReadyEstimate();
    }
  }, [submitNoLongerRequired]);

  const onWizardComplete = data => {
    setWizardComplete(true);

    setSelectedHomeLoan(data.homeLoan);
    setSelectedGreenLoan(data.greenLoan);
    setSelectedEstimatedCost(data.estimatedCost);
    setSelectedReady(data.ready);
    setSelectedNotReady(data.notReady);

    if (!data.ready && detailsAlreadyLoaded) {
      setSubmissionCommon({
        emailAddress: userEmailAddress,
        termsAndConditions: true,
        sendComparisonEmail: false,
        sendArticlesEmail: false,
        sendSolarInstallationEmail: false
      });

      // We are going to submit and leave as not converted and not ask further questions
      setSubmitNoLongerRequired(true);
    }
  };

  // --------------------------------------------------------------------------
  // Detect changes in form.
  // --------------------------------------------------------------------------
  useEffect(() => {
    let changed =
      getFullAddress(selectedAddress) !== getFullAddress(propertyAddress) ||
      solarInstallType !== selectedSolarInstallType ||
      estimatedCost !== selectedEstimatedCost ||
      homeLoan !== selectedHomeLoan ||
      greenLoan !== selectedGreenLoan ||
      ready !== selectedReady ||
      notReady !== selectedNotReady ||
      // this has to be a truthy != not strict !== comparison as submissionCommon.emailAddress may be undefined not null
      // eslint-disable-next-line eqeqeq
      initialEmailAddressValue != submissionCommon.emailAddress ||
      firstName !== yourDetails.firstName ||
      surname !== yourDetails.surname ||
      mobilePhoneNumberNational !== yourDetails.phoneNumber;

    setHasChanges(changed);
  }, [
    selectedAddress,
    selectedSolarInstallType,
    selectedHomeLoan,
    selectedGreenLoan,
    selectedEstimatedCost,
    selectedReady,
    selectedNotReady,
    submissionCommon.emailAddress
  ]);

  // --------------------------------------------------------------------------
  // Initialize or reset the form
  // --------------------------------------------------------------------------
  const initialize = reset => {
    dispatch(setServerMessageClear(target, serverMessage));
    dispatch(setServerMessageClear(internetTarget, serverMessage));

    if (reset) {
      setSelectedAddress(initialAddress);
      dispatch(setUser({ emailAddress: initialEmailAddressValue }));
      setReset(Date.now());
    }

    setSelectedSolarInstallType(solarInstallType);
    setSelectedHomeLoan(null);
    setSelectedGreenLoan(null);
    setSelectedEstimatedCost(estimatedCost);
    setSelectedReady(null);
    setSelectedNotReady(null);

    setPreferredCallbackDate(null);
    setPreferredCallbackPeriod(preferredCallbackPeriods.ANYTIME.Id);

    setHasSubmitted(false);
    setHasChanges(false);

    setWizardComplete(false);
    if (reset) setWizardKey(wizardKey + 1);
  };

  // --------------------------------------------------------------------------
  // Address selector change handler.
  // --------------------------------------------------------------------------
  const onAddressSelected = address => {
    setSelectedAddress(address);
    dispatch(setServerMessageClear(target, serverMessage));
  };

  // --------------------------------------------------------------------------
  // Add session activity.
  // --------------------------------------------------------------------------
  const recordSessionActivity = (comparisonResult, userBill) => {
    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          submissionType: submissionTypes.ESTIMATE,
          submissionId: userBill.submissionId,
          serviceType: serviceType.Code,
          hasOffers: comparisonResult.hasOffers
        },
        activityType: sessionActivityTypes.COMPARISON_COMPLETED
      })
    );
  };

  // --------------------------------------------------------------------------
  // Estimate comparison complete handler.
  // --------------------------------------------------------------------------
  const onComplete = (comparisonResult, userBill) => {
    recordSessionActivity(comparisonResult, userBill);

    onSuccess({
      hasOffers: comparisonResult.hasOffers,
      estimateWasRun: true,
      userBill,
      referenceNumber: comparisonResult.referenceNumber
    });

    if (comparisonResult.referenceNumber) {
      setHasChanges(false);
    } else if (!comparisonResult.hasOffers) {
      setHasChanges(false);
      dispatch(displayNoOffersMessage(target));
    }

    if (agentMode === agentOperatingModes.ACTIVE) {
      dispatchAutoEstimates();
    }
  };

  const onCompleteNotReady = (comparisonResult, userBill) => {
    // Do nothing for now
  };

  const isBusinessDay = date => {
    const day = date.getDay();
    // Check if the day is not Saturday (6) or Sunday (0)
    return day !== 0 && day !== 6;
  };

  const filterNextTenBusinessDays = date => {
    const today = moment().startOf('day');

    const diff = moment(date)
      .startOf('day')
      .diff(today, 'days');
    return isBusinessDay(date) && diff >= 0 && diff <= 14;
  };

  const rowClass = 'sm-dialog-row';
  const fieldClass = 'sm-dialog-field-name';
  const columnSizeLg = 12;

  return (
    <>
      <AvForm onValidSubmit={event => onSubmitEstimate(event)} onInvalidSubmit={handleInvalidSubmit}>
        <Row>
          <Col>
            <ServerMessage key={target} serverMessage={serverMessage} target={target} />
            <ServerMessage key={internetTarget} serverMessage={serverMessage} target={internetTarget} />
          </Col>
        </Row>

        <SolarFinanceWizard
          key={wizardKey}
          onWizardComplete={onWizardComplete}
          detailsAlreadyLoaded={detailsAlreadyLoaded}
        />

        {/* --- YOUR ADDRESS ------------------------------------------------------- */}
        {wizardComplete && selectedReady && (
          <>
            {!detailsAlreadyLoaded && (
              <Row className={rowClass}>
                <Col>
                  <AddressControl
                    key="solarFinanceEstimateAddress"
                    keySuffix="solarFinance"
                    labelText="Your address"
                    country={country}
                    onAddressSelected={onAddressSelected}
                    autoPlaceholder={selectedAddress.fullAddress || 'Property address...'}
                    autoPropertyAddress={selectedAddress}
                    autoAddressRequired={true}
                    manualAddressVisible={true}
                    manualSuburbSelectorEnabled={true}
                    showAreasSupportedDisclaimer={false}
                    showMinimalManualFields={true}
                    readOnly={addressLocked}
                  />
                </Col>
              </Row>
            )}

            <Row className={rowClass}>
              <Col className={fieldClass} lg={columnSizeLg}>
                Preferred broker contact time (optional)
              </Col>
              <Col>
                <DatePicker
                  selected={preferredCallbackDate}
                  onChange={date => {
                    setPreferredCallbackDate(date);
                  }}
                  useWeekdaysShort={true}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  filterDate={filterNextTenBusinessDays}
                />
              </Col>
              <Col>
                <AvField
                  bsSize="sm"
                  name="callBack"
                  placeholder="Call-back"
                  type="select"
                  value={preferredCallbackPeriod}
                  onChange={e => setPreferredCallbackPeriod(e.target.value)}
                >
                  <option value="">Select an option</option>
                  {preferredCallbackPeriods.getDefault().map(e => (
                    <option key={`cbp-${e.Id}`} value={e.Id}>
                      {e.Name}
                    </option>
                  ))}
                </AvField>
              </Col>
            </Row>

            {!detailsAlreadyLoaded && (
              <>
                <Row className={rowClass}>
                  <Col className={fieldClass} lg={columnSizeLg}>
                    Your details
                  </Col>
                  <Col>
                    <YourDetails
                      onChange={onYourDetailsChange}
                      details={yourDetails}
                      phoneNumberRegex={mobilePhoneNumberRegex}
                      emailAddress={emailAddress}
                      accountVerified={false}
                      showMembershipId={false}
                      showAlternatePhoneNumber={false}
                      showDateOfBirth={false}
                      showTitle={false}
                      showEmailAddress={false}
                      showPhoneNumber={true}
                      lockPhoneNumber={!!mobilePhoneNumberNational}
                    />
                  </Col>
                </Row>
              </>
            )}

            {/* --- EMAIL ADDRESS / AGENT --------------------------------------------- */}
            <Row className={rowClass}>
              <Col xs="12">
                <SubmitCommon
                  hasSubmitted={hasSubmitted}
                  onLoad={onSubmissionCommonLoad}
                  onChange={onSubmissionCommonChange}
                  refresh={reset}
                  showSendComparisonEmail={false}
                  showSendArticlesEmail={true}
                  showSendSolarInstallationEmail={true}
                  serviceType={serviceType}
                  forceRequireEmailAddress={true}
                  forceRequireSendEmail={true}
                  showNote={false}
                  hideEmailAddress={detailsAlreadyLoaded}
                >
                  {agentMode === agentOperatingModes.ACTIVE && (
                    <ServicesAuto selectedAddress={selectedAddress} serviceType={serviceType} />
                  )}
                </SubmitCommon>
              </Col>
            </Row>

            <div className="sm-modal-footer">
              {hasChanges && (
                <Button onClick={() => initialize(true)} className="sm-button-secondary">
                  Reset
                </Button>
              )}
              {showBackButton && (
                <Button onClick={onBackButtonClick} className="sm-button-secondary">
                  Go back
                </Button>
              )}
              {showCancelButton && (
                <Button onClick={onCancel} className=" sm-button-secondary">
                  Cancel
                </Button>
              )}
              {showCloseButton && (
                <Button onClick={onClose} className="sm-button-secondary">
                  {closeButtonText}
                </Button>
              )}
              <Button className="sm-button-primary right-arrow-small wide">{confirmButtonText}</Button>

              <ExitLaunch launchMode={launchMode} serviceType={serviceType} source="internet-dialog" />
              {showPoweredBy && <PoweredBySmartMe />}
            </div>
          </>
        )}

        {wizardComplete && !selectedReady && (
          <div style={{ textAlign: 'center' }}>
            <Button onClick={() => initialize(true)} className="sm-button-primary static medium nav-button">
              Reset
            </Button>
          </div>
        )}

        <BrandScroller serviceType={billTypes.SOLAR_FINANCE} dialogMode />
      </AvForm>
    </>
  );
};

export default SolarFinanceAu;
