import { billTypes } from './billTypes';
import { countryCodes } from './countryCodes';

export const implementationCodes = {
  ELECTRICITY: {
    AU_DEFAULT: 'AuElectricityDefault',
    US_DEFAULT: 'UsElectricityDefault'
  },
  GAS: {
    AU_DEFAULT: 'AuGasDefault'
  },
  INTERNET: {
    AU_DEFAULT: 'AuInternetDefault'
  },
  HOME_LOAN: {
    AU_DEFAULT: 'AuHomeLoanDefault'
  },
  VECHICLE_INSURANCE: {
    AU_DEFAULT: 'AuCarInsuranceDefault'
  },
  HOME_AND_CONTENTS_INSURANCE: {
    AU_DEFAULT: 'AuHomeAndContentsInsuranceDefault'
  },
  HEALTH_INSURANCE: {
    AU_DEFAULT: 'AuHealthInsuranceDefault'
  },
  MOBILE_PHONE: {
    AU_DEFAULT: 'AuMobilePhoneDefault'
  },
  SOLAR_INSTALLATION: {
    AU_DEFAULT: 'AuSolarInstallationDefault'
  },
  SOLAR_FINANCE: {
    AU_DEFAULT: 'AuSolarFinanceDefault'
  },
  BUSINESS_LOAN: {
    AU_DEFAULT: 'AuBusinessLoanDefault'
  },
  get: (countryId, billTypeId) => {
    switch (billTypeId) {
      case billTypes.ELECTRICITY.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.ELECTRICITY.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return implementationCodes.ELECTRICITY.US_DEFAULT;
          default:
            return null;
        }

      case billTypes.GAS.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.GAS.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return null;
          default:
            return null;
        }

      case billTypes.INTERNET.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.INTERNET.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return null;
          default:
            return null;
        }

      case billTypes.HOME_LOAN.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.HOME_LOAN.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return null;
          default:
            return null;
        }

      case billTypes.VECHICLE_INSURANCE.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.VECHICLE_INSURANCE.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return null;
          default:
            return null;
        }

      case billTypes.HOME_AND_CONTENTS_INSURANCE.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.HOME_AND_CONTENTS_INSURANCE.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return null;
          default:
            return null;
        }

      case billTypes.HEALTH_INSURANCE.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.HEALTH_INSURANCE.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return null;
          default:
            return null;
        }

      case billTypes.MOBILE_PHONE.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.MOBILE_PHONE.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return null;
          default:
            return null;
        }

      case billTypes.SOLAR_INSTALLATION.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.SOLAR_INSTALLATION.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return null;
          default:
            return null;
        }

      case billTypes.SOLAR_FINANCE.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.SOLAR_FINANCE.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return null;
          default:
            return null;
        }

      case billTypes.BUSINESS_LOAN.Id:
        switch (countryId) {
          case countryCodes.AUSTRALIA:
            return implementationCodes.BUSINESS_LOAN.AU_DEFAULT;
          case countryCodes.UNITED_STATES:
            return null;
          default:
            return null;
        }

      default:
        return null;
    }
  },
  getServiceType: implementationCode => {
    switch (implementationCode) {
      case implementationCodes.ELECTRICITY.AU_DEFAULT:
      case implementationCodes.ELECTRICITY.US_DEFAULT:
        return billTypes.ELECTRICITY;

      case implementationCodes.GAS.AU_DEFAULT:
        return billTypes.GAS;

      case implementationCodes.INTERNET.AU_DEFAULT:
        return billTypes.INTERNET;
      case implementationCodes.HOME_LOAN.AU_DEFAULT:
        return billTypes.HOME_LOAN;
      case implementationCodes.VECHICLE_INSURANCE.AU_DEFAULT:
        return billTypes.VECHICLE_INSURANCE;
      case implementationCodes.HOME_AND_CONTENTS_INSURANCE.AU_DEFAULT:
        return billTypes.HOME_AND_CONTENTS_INSURANCE;
      case implementationCodes.HEALTH_INSURANCE.AU_DEFAULT:
        return billTypes.HEALTH_INSURANCE;
      case implementationCodes.MOBILE_PHONE.AU_DEFAULT:
        return billTypes.MOBILE_PHONE;
      case implementationCodes.SOLAR_INSTALLATION.AU_DEFAULT:
        return billTypes.SOLAR_INSTALLATION;
      case implementationCodes.SOLAR_FINANCE.AU_DEFAULT:
        return billTypes.SOLAR_FINANCE;
      case implementationCodes.BUSINESS_LOAN.AU_DEFAULT:
        return billTypes.BUSINESS_LOAN;

      default:
        return billTypes.NONE;
    }
  }
};
