import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../store/configureStore';
import { completeRegistration } from '../../actions/registrationActions';
import { setServerMessageClear } from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import qs from 'query-string';
import { Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import ServerMessage from '../common/serverMessage';
import RegistrationDialogUx2 from '../registration/registrationDialogUx2';
import { homePageTypes } from '../../enums/launchTypes';

const Register = props => {
  const dispatch = useDispatch();

  const { registrationLinkError } = useSelector(state => state.registration);
  const { homePageType } = useSelector(state => state.config.settings);
  const { serverMessage } = useSelector(state => state);

  const [loaded, setLoaded] = useState(false);

  const homePage = homePageTypes.getById(homePageType);

  const { REGISTRATION_LINK_ERROR: target } = serverMessageTargets;

  useEffect(() => {
    dispatch(setServerMessageClear(target, serverMessage));

    var queryKey = qs.parse(window.location.search).key;
    var queryId = props.match.params.id;

    if (queryKey && queryId && !loaded) {
      dispatch(completeRegistration({ key: queryKey, id: queryId }));
      setLoaded(true);
    } else if (!queryKey || !queryId) {
      history.push(homePage.path);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{registrationLinkError ? 'Link Expired' : 'Loading...'}</title>
      </Helmet>
      <Row>
        <Col xs="12">
          <h1 className="d-none d-sm-block">
            {registrationLinkError ? 'Your link has expired' : 'Completing your registration'}
          </h1>

          {registrationLinkError ? (
            <ServerMessage serverMessage={serverMessage} target={target} />
          ) : (
            <h2 className="qs-loading-heading">Loading...</h2>
          )}

          <RegistrationDialogUx2 />
        </Col>
      </Row>
    </>
  );
};

export default Register;
