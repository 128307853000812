import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';

import { lowerObjectProperties } from '../utilities/commonUtilities';
import { agentHasEnergy } from '../utilities/billTypeUtilities';

import { billTypes } from '../enums/billTypes';
import { uploadBillTypes } from '../enums/uploadBillTypes';
import { occupancyTypes } from '../enums/submissionTypes';
import { launchDialogModes, operatorModes } from '../enums/launchTypes';

import { goHome } from '../actions/comparisonActions';
import { launchSubmitDialog } from '../actions/landingActions';
import { initLaunchProvider, initLaunchSwitch } from '../actions/energyEstimateActions';
import { initEstimateWizard, initFramePartner } from '../actions/partnerActions';
import { push } from 'connected-react-router';
import { getAgentServiceType } from '../enums/customerCommissionTypes';

const Launch = props => {
  const dispatch = useDispatch();

  const { googleLoaded } = useSelector(state => state.application);
  const { launchDialogMode: launchDialogModeId, movingHouseEnabled, operatorModeEnabled } = useSelector(
    state => state.config.settings
  );
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const allowMovingHouse = movingHouseEnabled && agentHasEnergy(agentBillTypes);
  const launchDialogMode = launchDialogModes.getById(launchDialogModeId);

  useEffect(() => {
    const locationSearch = qs.parse(props.location.search);
    const query = lowerObjectProperties(locationSearch);
    const serviceTypeCode = query.servicetype ? query.servicetype.toLowerCase() : null;
    const exitCode = query.exit ? query.exit.toLowerCase() : null;
    const module = props.match.params.module ? props.match.params.module.toLowerCase() : null;
    const tag = props.match.params.tag;
    const operatorMode = operatorModeEnabled ? operatorModes.getByTag(tag) : operatorModes.NONE;
    const operatorId = operatorModeEnabled ? query.opid : null;
    const serviceType = billTypes.getByCode(serviceTypeCode, billTypes.NONE);

    const affiliateRequest = module === uploadBillTypes.AFFILIATE.Name.toLowerCase();
    const partnerRequest = module === uploadBillTypes.PARTNER.Name.toLowerCase();

    const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
    const affiliate = agentServiceType ? agentServiceType.affiliate : null;
    const partner = agentServiceType ? agentServiceType.partner : null;

    const serviceTypeOptions = {
      includeExternal: affiliateRequest || partnerRequest
    };

    const hasServiceType =
      billTypes.hasServiceType(agentBillTypes, serviceType, serviceTypeOptions) || serviceType === billTypes.NONE;

    const allowServiceType = hasServiceType && (!affiliateRequest || affiliate) && (!partnerRequest || partner);

    const estimateArgs = ['iframe', 'estimate-wizard'].includes(module)
      ? {
          serviceType: locationSearch.st,
          occupancyType: locationSearch.ot,
          productType: locationSearch.pt,
          skipIntroduction: locationSearch.si,
          propertyAddressType: locationSearch.pat,
          postcodeInitial: locationSearch.pci,
          postcodeOfferMax: locationSearch.pcom
        }
      : null;

    switch (module) {
      case 'bill':
        if (allowServiceType) {
          dispatch(
            launchSubmitDialog(
              serviceType,
              uploadBillTypes.WEBSITE,
              {
                emailAddress: query.emailaddress,
                agent: query.agent,
                termsAndConditions: query.termsandconditions,
                operatorId
              },
              { launchDialogMode, operatorMode, exitCode }
            )
          );
        } else {
          dispatch(goHome());
        }

        break;

      case 'estimate':
        if (allowServiceType) {
          if (googleLoaded) {
            dispatch(
              launchSubmitDialog(
                serviceType === billTypes.NONE ? billTypes.ELECTRICITY : serviceType, // default to electricity for estimate dialog only.
                uploadBillTypes.MANUAL,
                {
                  emailAddress: query.emailaddress,
                  agent: query.agent,
                  occupancyType: query.occupancytype === '1' ? occupancyTypes.NEW.Id : occupancyTypes.CURRENT.Id,
                  termsAndConditions: query.termsandconditions,
                  operatorId
                },
                { launchDialogMode, operatorMode, exitCode }
              )
            );
          }
        } else {
          dispatch(goHome());
        }

        break;

      case 'moving':
        if (allowMovingHouse) {
          if (googleLoaded) {
            dispatch(
              launchSubmitDialog(
                billTypes.NONE,
                uploadBillTypes.MOVING_HOUSE,
                {
                  emailAddress: query.emailaddress,
                  agent: query.agent,
                  termsAndConditions: query.termsandconditions,
                  operatorId
                },
                { launchDialogMode, operatorMode, exitCode }
              )
            );
          }
        } else {
          dispatch(goHome());
        }

        break;

      case 'switch':
        if (googleLoaded) {
          const directSwitchArgs = {
            tag,
            area: {},
            serviceType: locationSearch.st,
            providerCode: locationSearch.rc,
            providerName: locationSearch.rn,
            productName: locationSearch.pn,
            productType: locationSearch.pt
          };

          dispatch(
            initLaunchSwitch(locationSearch.agent, directSwitchArgs, {
              launchDialogMode,
              operatorMode: operatorModes.NONE
            })
          );
          break;
        }
        break;

      case 'provider':
        if (googleLoaded) {
          const directSwitchArgs = {
            serviceType: locationSearch.st,
            providerCode: locationSearch.rc,
            providerName: locationSearch.rn,
            productType: locationSearch.pt
          };

          dispatch(
            initLaunchProvider(locationSearch.agent, directSwitchArgs, {
              launchDialogMode,
              operatorMode: operatorModes.NONE
            })
          );
          break;
        }
        break;

      case 'iframe':
        // Do not wait for Google to load as we need to set page-mode immediately; Only init once while Google is not loaded.
        if (!googleLoaded) {
          const frameArgs = { ...estimateArgs, frameType: locationSearch.ft };

          dispatch(initFramePartner(locationSearch.host, locationSearch.referrer, locationSearch.agent, frameArgs));
          break;
        }
        break;

      case 'estimate-wizard':
        if (!googleLoaded) {
          dispatch(initEstimateWizard(locationSearch.agent, estimateArgs));
        }

        break;

      case 'affiliate':
        if (allowServiceType) {
          dispatch(
            launchSubmitDialog(
              serviceType,
              uploadBillTypes.AFFILIATE,
              {
                emailAddress: query.emailaddress,
                firstName: query.firstname,
                surname: query.surname,
                mobilePhoneNumber: query.mobilephonenumber,
                agent: query.agent,
                ccd: query.ccd,
                termsAndConditions: query.termsandconditions,
                operatorId
              },
              { launchDialogMode, operatorMode, exitCode }
            )
          );
        } else {
          dispatch(goHome());
        }
        break;
      default:
        break;
    }
  }, [googleLoaded]);

  return null;
};

export default Launch;
