import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { billTypes } from '../../enums/billTypes';
import { billingTypes, submissionStatuses } from '../../enums/submissionTypes';

import GeneralDialog from '../dialogs/generalDialog';
import OrganiserName from '../common/organiserName';

const ServiceCardMenu = ({
  reductionLoaded,
  subtitle,
  submissionId,
  serviceType,
  status,
  billingType,
  isSample,
  isEmpty,
  isAdd,
  isAwaitingLink,
  isBill,
  isEstimate,
  pdfFileMissing,
  onDelete,
  onEdit,
  onDetails,
  onStart,
  onViewBill,
  onRefresh
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const isInternet = serviceType === billTypes.INTERNET;

  // Treat converted user bills as switched if they have a billingType of anything but auto-check-email, i.e. 'organiser'
  const switched =
    status === submissionStatuses.CONVERTED.Id &&
    billingType !== billingTypes.NONE.Id &&
    billingType !== billingTypes.EMAIL_ORGANISER.Id;

  const deleteMenuEnabled = !isEmpty;

  const editMenuVisible = isEstimate && !isAwaitingLink && !switched;
  const editMenuEnabled = isEstimate;

  const detailsMenuVisible = isBill && !isInternet; //|| isHomeLoan;
  const detailsMenuEnabled = serviceType.ComparisonImplemented;

  const startMenuVisible = isEmpty;
  const startMenuEnabled = isEmpty;

  const viewBillMenuVisible = isBill;
  const viewBillMenuEnabled = !pdfFileMissing;

  const refreshMenuVisible = !isEmpty && !isAdd && !isAwaitingLink && !switched;
  const refreshMenuEnabled = serviceType.ComparisonImplemented && reductionLoaded && (!pdfFileMissing || isEstimate);

  const newMenuVisible = !isEmpty && !isAdd;
  const newMenuEnabled = serviceType.ComparisonImplemented;

  const showDialog = () => {
    return (
      <GeneralDialog
        key={`deleteCardConfirmation_${submissionId}`}
        confirmButtonText="Confirm"
        closeButtonText="Cancel"
        showCloseIcon={false}
        dialogOpen={confirmDeleteDialog}
        onClose={result => {
          if (result) {
            onDelete();
          }
          setConfirmDeleteDialog(false);
        }}
      >
        <div className="sm-delete-confirmation">
          <h1>Delete Card</h1>
          {status === submissionStatuses.CONVERTED.Id && (
            <div className="warning-text">
              WARNING: This service is linked to your <OrganiserName nameOnly />
            </div>
          )}
          <div>
            You are about to delete this {serviceType.Name.toLowerCase()} card for {subtitle} from your{' '}
            <OrganiserName nameOnly />.
          </div>
          <div className="mt-2">Are you sure you want to continue?</div>
        </div>
      </GeneralDialog>
    );
  };

  return (
    <div className="service-menu">
      <Dropdown disabled={isSample} isOpen={dropdownOpen} toggle={toggle} size="sm">
        <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
          <i className={`material-icons menu-icon${isSample ? ' disabled' : ''}`}>menu</i>
        </DropdownToggle>
        <DropdownMenu className="menu-items">
          <DropdownItem
            disabled={!deleteMenuEnabled}
            onClick={() => setConfirmDeleteDialog(true)}
            className="menu-item"
          >
            <div>
              <i className="material-icons">auto_delete</i>
            </div>
            <div className="menu-text">Delete</div>
            {confirmDeleteDialog && showDialog()}
          </DropdownItem>
          <DropdownItem divider />
          {editMenuVisible && (
            <>
              <DropdownItem disabled={!editMenuEnabled} onClick={onEdit} className="menu-item">
                <div>
                  <i className="material-icons">edit_note</i>
                </div>
                <div className="menu-text">Edit</div>
              </DropdownItem>
              <DropdownItem divider />
            </>
          )}
          {detailsMenuVisible && (
            <>
              <DropdownItem disabled={!detailsMenuEnabled} onClick={onDetails} className="menu-item">
                <div>
                  <i className="material-icons">subject</i>
                </div>
                <div className="menu-text">View Details</div>
              </DropdownItem>
              <DropdownItem divider />
            </>
          )}
          {viewBillMenuVisible && (
            <>
              <DropdownItem disabled={!viewBillMenuEnabled} onClick={onViewBill} className="menu-item">
                <div>
                  <i className="material-icons">picture_as_pdf</i>
                </div>
                <div className="menu-text">View Bill</div>
              </DropdownItem>
              <DropdownItem divider />
            </>
          )}
          {refreshMenuVisible && (
            <>
              <DropdownItem disabled={!refreshMenuEnabled} onClick={onRefresh} className="menu-item">
                <div>
                  <i className="material-icons">refresh</i>
                </div>
                <div className="menu-text">Refresh</div>
              </DropdownItem>
              <DropdownItem divider />
            </>
          )}
          {startMenuVisible && (
            <DropdownItem disabled={!startMenuEnabled} onClick={onStart} className="menu-item">
              <div>
                <i className="material-icons">double_arrow</i>
              </div>
              <div className="menu-text">Get Organised</div>
            </DropdownItem>
          )}
          {newMenuVisible && (
            <>
              <DropdownItem disabled={!newMenuEnabled} onClick={onStart} className="menu-item">
                <div>
                  <i className="material-icons">add_circle_outline</i>
                </div>
                <div className="menu-text">Add another</div>
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default ServiceCardMenu;
