import * as types from './actions';
import axios from 'axios';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import { setSelectedAgentSuccess } from './configActions';
import { setUser } from './userActions';
import { displayErrorMessage } from './serverMessageActions';
import * as serverMessageTargets from './serverMessageTargets';

import { push } from 'connected-react-router';
import timeAxios from '../utilities/timeAxios';
import { apiTiming } from '../utilities/googleAnalyticsEvents';
import { sleep } from '../utilities/compareUtilities';
import { contextTypes } from '../enums/submissionTypes';

export function setSurveySuccess(survey) {
  return { type: types.SET_SURVEY, survey };
}

export function setSurvey(survey) {
  return dispatch => {
    dispatch(setSurveySuccess(survey));
  };
}

// ----------------------------------------------------------------------------
// Gets a survey with an internal access key.
// ----------------------------------------------------------------------------
export function getSurveyWithKey(accessKey, surveyId) {
  return dispatch => {
    const start = Date.now();

    dispatch(setAjaxCallStart(contextTypes.CUSTOMER_SURVEY));

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', 'survey');
    });

    axios
      .get(`/api/survey/${accessKey.id}/?key=${accessKey.key}&sid=${surveyId}`)
      .then(response => {
        if (response.data) {
          const data = response.data;

          if (data.messages && data.messages[0] && data.messages[0].message) {
            dispatch(surveyError(serverMessageTargets.SURVEY, data.messages[0].message));
          } else {
            const user = data.user;
            const agentMember = data.agentMember;

            sleep(start, 'Survey').then(() => {
              dispatch(
                setSurveySuccess({
                  serviceTypeId: data.serviceTypeId,
                  settings: data.survey,
                  isLoaded: true,
                  accessKey,
                  accessTrustLevel: data.accessKey.accessTrustLevel
                })
              );

              if (agentMember) {
                dispatch(setSelectedAgentSuccess({ ...agentMember, isLoaded: true }));
              }

              if (user) {
                dispatch(setUser({ ...user, loaded: true }));
              }

              dispatch(push('/survey'));
              dispatch(setAjaxCallEnd());
            });
          }
        } else {
          dispatch(surveyError(serverMessageTargets.SURVEY));
        }
      })
      .catch(() => {
        dispatch(
          setSurveySuccess({
            isLoaded: false,
            hasError: true
          })
        );

        dispatch(push('/survey'));
        dispatch(setAjaxCallEnd());
      });
  };
}

// ----------------------------------------------------------------------------
// Submit survey.
// ----------------------------------------------------------------------------
export function submitSurvey(model, serviceType, accessKey, onComplete) {
  return dispatch => {
    dispatch(setAjaxCallStart(contextTypes.SURVEY, serviceType));

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'survey', 'submit');
    });

    axios
      .post(`/api/survey/${serviceType.ApiPath}/${accessKey.id}/?key=${accessKey.key}`, model, null)
      .then(response => {
        if (response.status === 200) {
          dispatch(setSurveySuccess({ completed: true }));
          dispatch(setAjaxCallEnd());

          if (onComplete) {
            onComplete();
          }
        } else {
          dispatch(surveyError(serverMessageTargets.SURVEY));
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.messages
            ? error.response.data.messages[0].message
            : null;

        dispatch(surveyError(serverMessageTargets.SURVEY, message));
      });
  };
}

function surveyError(target, message) {
  return dispatch => {
    dispatch(
      displayErrorMessage(
        target,
        message || 'An error occured processing your survey request, please try again later.',
        true
      )
    );

    dispatch(setAjaxCallEnd());
  };
}
