import React from 'react';

const ProviderTermsAndConditions = ({ offer, subTitle, title }) => {
  const hasDisplayConditions =
    offer &&
    offer.productDetail &&
    offer.productDetail.displayConditions &&
    offer.productDetail.displayConditions.length > 0;

  const headingClass = 'qs-more-info-heading' + (hasDisplayConditions || subTitle ? '' : ' qs-mb-md');

  return (
    <div>
      <h2 className={headingClass}>{title}</h2>
      {subTitle && <p>{subTitle}</p>}
      {hasDisplayConditions && (
        <div className="qs-terms-area">
          {offer.productDetail.displayConditions.map((condition, i) => (
            <div key={`cond-${i}`} dangerouslySetInnerHTML={{ __html: condition.description }} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProviderTermsAndConditions;
