import React from 'react';
import { isMobile } from 'react-device-detect';

import { submissionTypes } from '../../enums/submissionTypes';

import ServiceCard from '../../components/serviceCard/serviceCard';

const ServiceCardSample = ({ serviceType, cardSize, isEmpty }) => {
  const service = {
    isSample: true,
    isEmpty,
    serviceType,
    billType: serviceType.Name,
    billTypeId: serviceType.Id,
    status: isEmpty ? 0 : 1,
    submissionType: isEmpty ? 'None' : submissionTypes.BILL,
    isBill: isEmpty ? false : true,
    buttonText: isEmpty ? 'Get organised' : 'View Offers'
  };

  if (!isEmpty) {
    service.implementationCode = 'AuElectricityDefault';
    service.providerCode = 'AGL';
    service.providerDisplayCode = 'AGL';
    service.providerLogo = 'AGL.png';
    service.lastBillIssueDate = '29 May 2019';
    service.lastBillReceivedDateUtc = '2021-05-27';
    service.address = '10 Sample Street';
    service.reductionLoaded = true;
    service.reductionPercentage = 16;
    service.reductionAmountAnnual = 218;
    service.billAmountCurrent = '$350.88';
    service.nextCompareDateUtc = null;
    service.reductionExpiry = '2099-12-31';
    service.billDays = 94;
    service.bestOffer = {
      savingAmountGuaranteed: 56.14,
      savingAmountGuaranteedDisplay: '$56.14',
      savingAmountGuaranteedReverseDisplay: '-$56.14',
      billAmountGuaranteedDisplay: '$294.74',
      percentageBillReductionAmountGuaranteedDisplayShort: '16%'
    };

    service.notificationCount = 1;
    service.bill = {
      totalChargesDiscountedDisplay: '$350.88',
      provider: {
        code: 'AGL',
        displayCode: 'AGL',
        logo: 'AGL.png'
      }
    };
  }

  return (
    <div className={`sm-service-card-sample${cardSize ? ` ${cardSize}` : ''}${isMobile ? ' mobile' : ''}`}>
      <ServiceCard service={service} />
    </div>
  );
};

export default ServiceCardSample;
