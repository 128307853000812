import React from 'react';

const FaqQuestion = props => {
  const { question, answer, id } = props;

  return (
    <>
      <h2 className="sm-faq-sub-heading" id={id}>
        {question}
      </h2>
      <div className="sm-faq-paragraph">{answer}</div>
    </>
  );
};
export default FaqQuestion;
