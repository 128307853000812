import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import GeneralDialog from './generalDialog';
import ServiceCardDeleted from '../serviceCard/serviceCardDeleted';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';

const DeletedServicesDialog = () => {
  const { data: userBills } = useSelector(state => state.userBills);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);

  const [dialogOpen, setDialogOpen] = useState(false);

  const deletedServices = userBills
    .filter(s => s.deletedDateUtc)
    .sort((a, b) => {
      return new Date(a.deletedDateUtc) - new Date(b.deletedDateUtc);
    });

  const onSetDialogState = isOpen => {
    setDialogOpen(isOpen);
    standardEvents('Menu', 'Click', `Deleted-Items-${isOpen ? 'Open' : 'Close'}`, googleAnalyticsClientCode);
  };

  const isEmpty = deletedServices.length === 0;

  return (
    <>
      <div className={`recycle-bin${isEmpty ? ' empty' : ''}`} onClick={() => !isEmpty && onSetDialogState(true)}>
        Deleted
        {!isEmpty && <span className="alert-bubble">{deletedServices.length}</span>}
      </div>
      <GeneralDialog
        heading="Deleted bills"
        closeButtonText="Close"
        dialogOpen={dialogOpen}
        dialogSize="lg"
        onClose={() => onSetDialogState(false)}
      >
        <div className="sm-service-card-deleted-container">
          <Row className="header">
            <Col className="column provider" xs="3" lg="2">
              <span>Bill Type</span>
            </Col>
            <Col className="column" xs="4">
              Address / Suburb
            </Col>
            <Col className="d-none d-lg-block column" lg="2">
              Deleted on
            </Col>
            <Col className="column" xs="3">
              Removed in
            </Col>
          </Row>
          {!isEmpty &&
            deletedServices.map(item => {
              return (
                <ServiceCardDeleted
                  useProviderLogo={false}
                  useServiceLogo={false}
                  useServiceIcon={true}
                  data={item}
                  key={`service_card_deleted_${item.submissionId}`}
                />
              );
            })}
        </div>
        {isEmpty && <div className="sm-service-card-deleted-empty">Your bin is empty</div>}
      </GeneralDialog>
    </>
  );
};

export default DeletedServicesDialog;
