import React from 'react';

const InternetFeatures = props => {
  const { features, featuresHtml } = props;

  return (
    <>
      {features && features.length > 0 ? (
        <div className="qs-offer-internet-features">
          {features.map((feature, i) => (
            <div className="qs-offer-internet-feature" key={`offerFeature=${i}`}>
              <i className="material-icons qs-done" style={{ maxHeight: '20px' }}>
                done
              </i>
              <div
                dangerouslySetInnerHTML={{
                  __html: feature
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div
          className="qs-offer-internet-features"
          dangerouslySetInnerHTML={{
            __html: featuresHtml
          }}
        />
      )}
    </>
  );
};

export default InternetFeatures;
