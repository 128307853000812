import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { buttonDirection } from '../../enums/buttonTypes';
import OptionItemSelector from '../EstimateWizard/optionItemSelector';
import { boolTypes } from '../../enums/submissionTypes';
import { scaleTypes } from '../../enums/scaleTypes';
import { Button } from 'reactstrap';
import PoweredBySmartMe from '../common/poweredBySmartMe';
import Slider from '../common/slider';
import { addSessionActivity } from '../../actions/sessionActivityActions';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes';
import { getSessionActivity } from '../../utilities/commonUtilities';

const SolarFinanceWizard = ({ onWizardComplete, detailsAlreadyLoaded }) => {
  const { googleLoaded } = useSelector(state => state.application);
  const { hasEstimates } = useSelector(state => state.config.settings);
  const { showPoweredBy } = useSelector(state => state.config.settings);
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { logo } = useSelector(state => state.content.images);
  const { session } = useSelector(state => state);

  const [currentHeading, setCurrentHeading] = useState(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const [selectedHomeLoanOption, setSelectedHomeLoanOption] = useState();
  //const [selectedGreenLoanOption, setSelectedGreenLoanOption] = useState();
  const [selectedReadyOption, setSelectedReadyOption] = useState();
  const [selectedNotReadyOption, setSelectedNotReadyOption] = useState();
  const [selectedEstimatedCost, setSelectedEstimatedCost] = useState(null);
  //const [selectedLikelihoodOption, setSelectedLikelihoodOption] = useState();

  const [assetFinanceDisclaimer, setAssetFinanceDisclaimer] = useState(false);
  const [homeLoanDisclaimer, setHomeLoanDisclaimer] = useState(false);

  const estimatedCostLabel = 'Estimated Cost';

  const dispatch = useDispatch();

  const sessionActivity = getSessionActivity(session);

  const currentData = {
    homeLoanOption: selectedHomeLoanOption || null,
    //greenLoanOption: selectedGreenLoanOption || null,
    estimatedCost: selectedEstimatedCost,
    //likelihoodOption: selectedLikelihoodOption || null,
    readyOption: selectedReadyOption || null,
    notReadyOption: selectedNotReadyOption || null
  };

  const { firstName } = useSelector(state => state.user);

  const onHomeLoanOptionSelected = homeLoanOption => {
    setSelectedHomeLoanOption(homeLoanOption);

    onStep(buttonDirection.Next, currentStepIndex, {
      homeLoanOption
    });
  };

  // const onGreenLoanOptionSelected = greenLoanOption => {
  //   setSelectedGreenLoanOption(greenLoanOption);

  //   onStep(buttonDirection.Next, currentStepIndex, {
  //     greenLoanOption
  //   });
  // };

  const onReadyOptionSelected = readyOption => {
    setSelectedReadyOption(readyOption);

    onStep(buttonDirection.Next, currentStepIndex, {
      readyOption
    });
  };

  const onNotReadyOptionSelected = notReadyOption => {
    setSelectedNotReadyOption(notReadyOption);

    onStep(buttonDirection.Next, currentStepIndex, {
      notReadyOption
    });
  };

  // const onLikeliehoodOptionSelected = likelihoodOption => {
  //   setSelectedLikelihoodOption(likelihoodOption);

  //   onStep(buttonDirection.Next, currentStepIndex, {
  //     likelihoodOption
  //   });
  // };

  // --------------------------------------------------------------------------
  // Wizard step control.
  // --------------------------------------------------------------------------
  useEffect(() => {
    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          detailsAlreadyLoaded
        },
        activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_INTRODUCTON
      })
    );
  }, []);

  const applyCurrentStep = (step, data) => {
    setCurrentStepIndex(step.index);
    setCurrentHeading(step.getHeading(data));

    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          detailsAlreadyLoaded,
          selectedHomeLoanOption,
          //selectedGreenLoanOption,
          selectedEstimatedCost,
          //selectedLikelihoodOption,
          selectedReadyOption,
          selectedNotReadyOption
        },
        activityType: step.activityType
      })
    );
  };

  const onStep = (direction, index, stepData) => {
    const offset = direction === buttonDirection.Previous ? -1 : 1;

    const checkIndex = index + offset;
    const checkStep = steps.find(e => e.index === checkIndex);

    if (checkStep === undefined) {
      return;
    }

    const data = {
      homeLoan: stepData.homeLoanOption
        ? stepData.homeLoanOption === boolTypes.YES
        : currentData.homeLoanOption === boolTypes.YES,
      //greenLoan: currentData.greenLoanOption === boolTypes.YES,
      ready: stepData.readyOption ? stepData.readyOption === boolTypes.YES : currentData.readyOption === boolTypes.YES,
      estimatedCost: currentData.estimatedCost,
      notReady: stepData.notReadyOption
        ? stepData.notReadyOption.Id
        : currentData.notReadyOption
        ? currentData.notReadyOption.Id
        : null,
      // likelihood: stepData.likelihoodOption
      //   ? stepData.likelihoodOption.Id
      //   : currentData.likelihoodOption
      //   ? currentData.likelihoodOption.Id
      //   : null,
      ...currentData,
      ...stepData
    };

    if (
      (direction === buttonDirection.Next && checkStep.nextEntryCondition(data)) ||
      (direction === buttonDirection.Previous && checkStep.previousEntryCondition(data))
    ) {
      applyCurrentStep(checkStep, data);

      if (checkStep.wizardComplete) {
        onWizardComplete(data);
      }
      return;
    }

    onStep(direction, checkIndex, data);
  };

  const initStep = buttonDirection => {
    onStep(buttonDirection, currentStepIndex, {});
  };

  const onNext = () => {
    initStep(buttonDirection.Next);
  };

  const onBack = () => {
    initStep(buttonDirection.Previous);
  };

  // --------------------------------------------------------------------------
  // Wizard Steps.
  // --------------------------------------------------------------------------
  const introduction = {
    index: 0,
    wizardComplete: false,
    activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_INTRODUCTON,
    getHeading: () => {
      return null;
    },
    component: (
      <div className="introduction">
        <div className="sm-estimate-wizard-logo">
          <img src={`${staticContentUrl}${logo}`} alt="logo" />
        </div>
        <div className="intro-text">
          {firstName && <p>Hi {firstName},</p>}
          <p>Thanks for confirming your interest in solar finance.</p>
          <p>
            Please answer a few questions to get started. Our finance experts will ensure you get a sharp rate, help you
            avoid misleading 0% finance offers and ensure funding doesn't delay your solar project.
          </p>
        </div>
      </div>
    ),
    nextEntryCondition: () => {
      return true;
    },
    previousEntryCondition: () => {
      return true;
    },
    onNextButtonText: 'Continue',
    onNextFunc: onNext,
    hasNext: () => {
      return true;
    },
    hasBack: () => {
      return false;
    },
    hasClose: () => {
      return false;
    }
  };

  const homeLoanOptionSelector = {
    index: 1,
    wizardComplete: false,
    activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_HOME_LOAN,
    getHeading: e => {
      return {
        title: `Interested in bundling your solar project into your home loan?`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <OptionItemSelector
        options={[boolTypes.YES, boolTypes.NO]}
        selectedOption={selectedHomeLoanOption}
        onOptionSelected={onHomeLoanOptionSelected}
      />
    ),
    explainer: (
      <>
        Increasing your home loan by $10,000 adds approximately $66 to your monthly repayments.
        <br />
        <br />
        If our experts find you a 0.26% interest rate reduction, you’ll also save $66 each month.
        {homeLoanDisclaimer ? (
          <>
            <br />
            <br />
            <div style={{ fontSize: '0.8em' }}>
              ^ based on an interest rate of 6.24% with a comparison rate of 6.31% * based on a current a current loan
              balance of $400,000 over 25 years with an interest rate of 6.50% and a new rate of 6.24% with a $410,000
              loan on a 25 year term.
            </div>
          </>
        ) : (
          <>
            <br />
            <br />
            <Button className="sm-link-button" onClick={() => setHomeLoanDisclaimer(true)}>
              See disclaimer
            </Button>
          </>
        )}
      </>
    ),
    nextEntryCondition: () => {
      return true;
    },
    previousEntryCondition: () => {
      return true;
    },
    hasNext: () => {
      return false;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  // const greenLoanOptionSelector = {
  //   index: 2,
  //   wizardComplete: false,
  //   activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_GREEN_LOAN,
  //   getHeading: e => {
  //     return {
  //       title: `Is a green loan important to you?`,
  //       scaleType: scaleTypes.all
  //     };
  //   },
  //   component: (
  //     <OptionItemSelector
  //       options={[boolTypes.YES, boolTypes.NO]}
  //       selectedOption={selectedGreenLoanOption}
  //       onOptionSelected={onGreenLoanOptionSelected}
  //     />
  //   ),
  //   explainer:
  //     'Green loans may provide discounts for energy efficient homes. Comparison rates should be carefully considered when choosing these. Our experts can provide you with green loan options over the phone.',
  //   nextEntryCondition: data => {
  //     return data.homeLoanOption === boolTypes.YES;
  //   },
  //   previousEntryCondition: data => {
  //     return data.homeLoan;
  //   },
  //   hasNext: () => {
  //     return false;
  //   },
  //   hasBack: () => {
  //     return true;
  //   },
  //   hasClose: () => {
  //     return true;
  //   }
  // };

  const estimatedCostSelector = {
    index: 2,
    wizardComplete: false,
    activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_ESTIMATED_COST,
    getHeading: e => {
      return {
        title: `What's the estimated cost of your<br/>solar project?`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <div style={{ width: '100%' }}>
        <Slider
          allowModeChange
          fieldName={estimatedCostLabel}
          value={selectedEstimatedCost}
          onFinalChange={value => setSelectedEstimatedCost(value)}
          min={0}
          max={30000}
          step={250}
          labelPrefix="$"
          minValueLabel="Not sure"
          maxValueSuffix="+"
        />
      </div>
    ),
    explainer: (
      <div className="qs-mt-nlg">
        {selectedHomeLoanOption === boolTypes.NO ? (
          <>
            We can also connect you to a personal finance expert with options from 30+ lenders.
            <br />
            <br />
            <div className="sm-rates">
              <div className="rate-item highlight">6.29%</div>
              <div className="rate-item highlight">
                7.41%<sup>^</sup>
              </div>
              <div className="rate-item">Rates from</div>
              <div className="rate-item">Comparison rate</div>
            </div>{' '}
            .{' '}
            {assetFinanceDisclaimer ? (
              <>
                <br />
                <br />
                <div style={{ fontSize: '0.8em' }}>
                  ^ The comparison rate shown is for a secured loan amount of $30,000 over a term of 5 years based on
                  monthly repayments. WARNING: This comparison rate is true only for the examples given. Different
                  amounts and terms will result in different comparison rates. Costs such as redraw fees or early
                  repayment fees, and cost savings such as fee waivers, are not included in the comparison rate but may
                  influence the cost of the loan. Any calculations or estimations do not constitute an offer of credit
                  or a formal credit quote and is only a calculation of what you may be able to achieve based on the
                  information you have entered. It does not take into account suitable product features or loan product
                  types. Rates + repayments shown are based on user inputted data. All applications for credit must be
                  verified prior to the formal assessment process. All applications for credit approval are subject to
                  lender credit approval. Approval is not guaranteed.
                </div>
              </>
            ) : (
              <>
                <br />
                <Button className="sm-link-button" onClick={() => setAssetFinanceDisclaimer(true)}>
                  See disclaimer
                </Button>
              </>
            )}
          </>
        ) : (
          <>The cost of your solar installation may influence which finance type is best suited for you.</>
        )}
      </div>
    ),
    nextEntryCondition: data => true,
    previousEntryCondition: data => true,
    onNextButtonText: 'Next',
    onNextFunc: onNext,
    hasNext: () => {
      return true;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const readyOptionSelector = {
    index: 3,
    wizardComplete: false,
    activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_READY,
    getHeading: e => {
      return {
        title: `Are you ready to speak to an expert?`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <OptionItemSelector
        options={[boolTypes.YES, boolTypes.NO]}
        selectedOption={selectedReadyOption}
        onOptionSelected={onReadyOptionSelected}
      />
    ),
    explainer: 'We’re impartial, trustworthy and work for you!',
    nextEntryCondition: data => {
      return true;
    },
    previousEntryCondition: data => {
      return true;
    },
    hasNext: () => {
      return false;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const whyNotOptionSelector = {
    index: 4,
    wizardComplete: false,
    activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_NOT_READY,
    getHeading: e => {
      return {
        title: `No problem, please tell us a little more<div class="qs-mb-md"/>`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <OptionItemSelector
        additionalClass="longtext"
        options={[
          { Id: 1, Name: "I'm waiting for quotes first" },
          { Id: 4, Name: 'My installer has organised finance' },
          { Id: 2, Name: "I'm no longer interested" }
        ]}
        selectedOption={selectedNotReadyOption}
        onOptionSelected={onNotReadyOptionSelected}
      />
    ),
    explainer: '',
    nextEntryCondition: data => {
      return data.readyOption === boolTypes.NO;
    },
    previousEntryCondition: data => {
      return !data.ready;
    },
    hasNext: () => {
      return false;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const waitingforQuotesSelector = {
    index: 5,
    wizardComplete: false,
    activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_WAITING_FOR_QUOTES,
    getHeading: e => {
      return {
        title: `Why not get pre-approved at<br/>the same time?`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <OptionItemSelector
        additionalClass="longtext"
        useAlternateName={true}
        options={[boolTypes.YES, boolTypes.NO]}
        selectedOption={selectedReadyOption}
        onOptionSelected={onReadyOptionSelected}
      />
    ),
    explainer: (
      <>
        We can arrange pre-approval over the phone, meaning you can be confident that your solar project is funded and
        ready to go.
        <br />
        <br />
        Install quotes usually take 6-7 days to arrive. Our experts usually arrange finance in 1-4 days.
      </>
    ),
    explainerFirst: true,
    nextEntryCondition: data => {
      return !data.ready && data.notReady === 1; // Waiting for quotes
    },
    previousEntryCondition: data => {
      return !data.ready && data.notReady === 1;
    },
    hasNext: () => {
      return false;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const installerOrganisingSelector = {
    index: 6,
    wizardComplete: false,
    activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_INSTALLER_ORGANISING,
    getHeading: e => {
      return {
        title: `We'll make sure you get the most competitive rates available`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <OptionItemSelector
        additionalClass="longtext"
        useAlternateName={true}
        options={[boolTypes.YES, boolTypes.NO]}
        selectedOption={selectedReadyOption}
        onOptionSelected={onReadyOptionSelected}
      />
    ),
    explainer: (
      <>
        Be careful when asking solar installers to arrange finance for your hardware. They may receive kick-backs and
        other benefits from lenders, resulting in a bias towards certain finance products.
        <br />
        <br />
        We're happy to provide you with a second opinion. We'll check 30+ different lenders to find you a great rate
        that you can compare to your installers rate.
      </>
    ),
    explainerFirst: true,
    nextEntryCondition: data => {
      return !data.ready && data.notReady === 4; // Installer organising
    },
    previousEntryCondition: data => {
      return !data.ready && data.notReady === 4;
    },
    hasNext: () => {
      return false;
    },
    hasBack: () => {
      return true;
    },
    hasClose: () => {
      return true;
    }
  };

  const complete = {
    index: 7,
    wizardComplete: true,
    additionalClass: 'complete',
    activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_COMPLETE,
    getHeading: e => {
      return {
        title: `Last question${firstName ? ` ${firstName}` : ''}`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <div style={{ textAlign: 'center' }}>
        <p>Based on your responses we can now pass your information to the best suited solar finance expert.</p>
        <p>Please select the best time to contact you to discuss your options.</p>
      </div>
    ),
    nextEntryCondition: data => {
      return data.readyOption === boolTypes.YES;
    },
    previousEntryCondition: data => {
      return true;
    },
    hasNext: () => {
      return false;
    },
    hasBack: () => {
      return false;
    },
    hasClose: () => {
      return true;
    }
  };

  const completeNotReady = {
    index: 8,
    wizardComplete: true,
    additionalClass: 'complete',
    activityType: sessionActivityTypes.SOLAR_FINANCE_WIZARD_COMPLETE,
    getHeading: e => {
      return {
        title: `No problem${firstName ? ` ${firstName}` : ''}`,
        scaleType: scaleTypes.all
      };
    },
    component: (
      <div style={{ textAlign: 'center' }}>
        <p>
          We won't connect you to a solar finance expert.
          <br />
          <br />
          When you're ready to speak to a finance expert, please feel free to come back.
        </p>
      </div>
    ),
    nextEntryCondition: data => {
      return !data.ready;
    },
    previousEntryCondition: data => {
      return !data.ready;
    },
    hasNext: () => {
      return false;
    },
    hasBack: () => {
      return false;
    },
    hasClose: () => {
      return true;
    }
  };

  const steps = [
    introduction,
    homeLoanOptionSelector,
    //greenLoanOptionSelector,
    estimatedCostSelector,
    readyOptionSelector,
    whyNotOptionSelector,
    waitingforQuotesSelector,
    installerOrganisingSelector,
    complete,
    completeNotReady
  ];

  const current = steps.find(e => e.index === currentStepIndex);

  return current && hasEstimates && googleLoaded ? (
    <>
      <div className="sm-dialog-wizard">
        <div className={`content${current.additionalClass ? ` ${current.additionalClass}` : ''}`}>
          {currentHeading && <div className="heading" dangerouslySetInnerHTML={{ __html: currentHeading.title }} />}
          {current.explainerFirst && current.explainer && <div className="explainer qs-mb-lg">{current.explainer}</div>}
          {current.component}
          {showPoweredBy && (
            <div className="powered-by-container">
              <PoweredBySmartMe noMargin textMode />
            </div>
          )}
        </div>
        {!current.explainerFirst && current.explainer && <div className="explainer">{current.explainer}</div>}

        <div className="navigation">
          {current.hasBack(currentData) && (
            <Button className="sm-button-primary static medium nav-button" onClick={onBack}>
              <span className="button-text">Back</span>
            </Button>
          )}
          {current.hasNext(currentData) && (
            <Button className="sm-button-primary static medium nav-button" onClick={current.onNextFunc}>
              <span className="button-text">{current.onNextButtonText}</span>
            </Button>
          )}
        </div>
      </div>
    </>
  ) : null;
};
export default SolarFinanceWizard;
