import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { setPrivacyPolicy, resetPrivacyPolicy } from '../actions/contentActions';
import { agentOperatingModes, homePageTypes } from '../enums/launchTypes';

const PrivacyPolicy = props => {
  const dispatch = useDispatch();

  const { document, title, versionNumber, loading } = useSelector(state => state.content.privacyPolicy);
  const { homePageType, operatingMode } = useSelector(state => state.config.settings);

  const { supplierId } = useSelector(state => state.selectedOffer);

  const homePage = homePageTypes.getById(homePageType);
  const agentMode = agentOperatingModes.getById(operatingMode);

  useEffect(() => {
    dispatch(resetPrivacyPolicy());
    dispatch(setPrivacyPolicy(supplierId));
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <Container>
        <Row>
          {!loading && (
            <Col xs="12">
              <h1 className="d-none d-sm-block">{title}</h1>
              <div className="qs-privacy-body" dangerouslySetInnerHTML={{ __html: document }} />
              <div className="qs-privacy-version-number">v{versionNumber}</div>
              {agentMode === agentOperatingModes.ACTIVE && (
                <Row className="qs-mt-lg">
                  <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <Button
                      className="qs-button-submit"
                      onClick={() =>
                        window.history.length > 2 ? props.history.goBack() : props.history.push(homePage.path)
                      }
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
