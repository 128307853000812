import * as types from './actions';
import axios from 'axios';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import { goHome } from './comparisonActions';
import timeAxios from '../utilities/timeAxios';
import { apiTiming } from '../utilities/googleAnalyticsEvents';
import { navigationTypes } from '../enums/navigationTypes';

export function getContentCode(contentType, supplierId) {
  const currentPage = navigationTypes.getCurrentPage();
  const supplierIdExtension = currentPage === navigationTypes.SWITCH && supplierId ? `/${supplierId}` : '';
  return `${contentType.code}${supplierIdExtension}`;
}

export const contentTypes = {
  NONE: { id: 0, name: null },
  TERMS_AND_CONDITIONS: { id: 1, code: 'termsAndConditions' },
  PRIVACY_POLICY: { id: 2, code: 'privacyPolicy' },
  CONCIERGE_TERMS_AND_CONDITIONS: { id: 3, code: 'conciergeTermsAndConditions' },
  getByCode: code => {
    switch (code) {
      case contentTypes.TERMS_AND_CONDITIONS.code:
        return contentTypes.TERMS_AND_CONDITIONS;
      case contentTypes.PRIVACY_POLICY.code:
        return contentTypes.PRIVACY_POLICY;
      case contentTypes.CONCIERGE_TERMS_AND_CONDITIONS.code:
        return contentTypes.CONCIERGE_TERMS_AND_CONDITIONS;
      default:
        return contentTypes.NONE;
    }
  }
};

export function setPrivacyPolicy(supplierId) {
  const code = getContentCode(contentTypes.PRIVACY_POLICY, supplierId);

  return setContent(code, data => {
    return { type: types.SET_PRIVACY_POLICY, data: { privacyPolicy: { ...data } } };
  });
}

export function setTermsAndConditions(supplierId) {
  const code = getContentCode(contentTypes.TERMS_AND_CONDITIONS, supplierId);

  return setContent(code, data => {
    return { type: types.SET_TERMS_AND_CONDITIONS, data: { termsAndConditions: { ...data } } };
  });
}

export function setConciergeTermsAndConditions(supplierId) {
  const code = getContentCode(contentTypes.CONCIERGE_TERMS_AND_CONDITIONS, supplierId);

  return setContent(code, data => {
    return { type: types.SET_CONCIERGE_TERMS_AND_CONDITIONS, data: { conciergeTermsAndConditions: data } };
  });
}

export function resetPrivacyPolicy() {
  return {
    type: types.SET_PRIVACY_POLICY,
    data: { privacyPolicy: { title: '', versionNumber: 0, document: '', loading: true } }
  };
}

export function resetTermsAndConditions() {
  return {
    type: types.SET_TERMS_AND_CONDITIONS,
    data: { termsAndConditions: { title: '', versionNumber: 0, document: '', loading: true } }
  };
}

export function resetConciergeTermsAndConditions() {
  return {
    type: types.SET_CONCIERGE_TERMS_AND_CONDITIONS,
    data: { conciergeTermsAndConditions: { title: '', versionNumber: 0, document: '', loading: true } }
  };
}

export function getItemContent(content, organiserAgentName, commissionType) {
  const earnRegex = /\{hasCommission:(.*\s*,?)\}/;

  if (!content) return null;

  return content
    .replace('{organiserAgentName}', organiserAgentName)
    .replace('{commissionTypeNamePlural}', commissionType ? commissionType.NamePlural : '')
    .replace(commissionType ? earnRegex : earnRegex, commissionType ? '$1' : '');
}

const setContent = (code, callback) => {
  return dispatch => {
    dispatch(setAjaxCallStart());

    timeAxios(axios, timeInMs => {
      apiTiming(timeInMs, 'load', code);
    });

    axios
      .get(`/api/info/${code}`)
      .then(response => {
        var data = response.data;

        if (data) {
          dispatch(callback({ loading: false, code, ...data }));
        } else {
          dispatch(goHome());
        }
      })
      .catch(() => {
        dispatch(goHome());
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
};
