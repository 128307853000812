import React from 'react';
import { useSelector } from 'react-redux';
import QsButtonGroup from '../common/qsButtonGroup';
import { occupancyTypes, submissionTypes } from '../../enums/submissionTypes';
import { AvField } from 'availity-reactstrap-validation';
import { FormGroup } from 'reactstrap';
import Conditions from '../common/conditions';
import { conditionTypes } from '../../enums/conditionTypes';
import { orientationTypes } from '../../enums/scaleTypes';

const EnergyAdditionalQuestions = ({ confirmDetail, offer, onChange, setConfirmDetail }) => {
  const { submissionType } = useSelector(state => state.comparison);
  const { occupancyType } = useSelector(state => state.comparison.submission);

  const onChangeWorksInProgress = e => {
    const option = e.target.getAttribute('data-value');
    onChange(null, { worksInProgress: option });
  };

  const onChangeSiteAccessIssues = e => {
    const option = e.target.getAttribute('data-value');
    onChange(null, { siteAccessIssues: option });
  };

  const onChangeSiteHazardIssues = e => {
    const option = e.target.getAttribute('data-value');
    onChange(null, { siteHazardIssues: option });
  };

  const onChangePowerOn = e => {
    const option = e.target.getAttribute('data-value');
    onChange(null, { powerOn: option });
  };

  const onChangeAccessConsent = e => {
    const option = e.target.getAttribute('data-value');
    onChange(null, { accessConsent: option });
  };

  const validateAccessConsent = (value, ctx, input, cb) => {
    if (value !== 'false' && value !== 'true') {
      cb('Please select yes or no');
    } else if (value !== 'true') {
      cb('We require your consent to continue with the application');
    } else cb(true);
  };

  const validateWorksInProgress = (value, ctx, input, cb) => {
    if (value !== 'false' && value !== 'true') {
      cb('Please select yes or no');
    } else cb(true);
  };

  const validateSiteAccessIssues = (value, ctx, input, cb) => {
    if (value !== 'false' && value !== 'true') {
      cb('Please select yes or no');
    } else cb(true);
  };

  const validateSiteHazardIssues = (value, ctx, input, cb) => {
    if (value !== 'false' && value !== 'true') {
      cb('Please select yes or no');
    } else cb(true);
  };

  const productDetail = offer.productDetail;

  const isNewOccupancy = submissionType === submissionTypes.ESTIMATE && occupancyType === occupancyTypes.NEW.Id;

  const showWorksInProgress = productDetail && productDetail.requireWorksInProgress;
  const showWorksInProgressDetail = showWorksInProgress && productDetail.requireWorksInProgressDetail;
  const showSiteAccessIssues = productDetail && productDetail.requireSiteAccessIssues;
  const showSiteAccessIssuesDetail = showSiteAccessIssues && productDetail.requireSiteAccessIssuesDetail;
  const showSiteHazardIssues = productDetail && productDetail.requireSiteHazardIssues;
  const showSiteHazardIssuesDetail = showSiteHazardIssues && productDetail.requireSiteHazardIssuesDetail;
  const showPowerOn = productDetail && productDetail.requirePowerOn;
  const showAccessConsent = productDetail && productDetail.requireAccessConsent;

  const vseNote =
    productDetail && productDetail.vse && confirmDetail.vseOption
      ? productDetail.vse.options.find(o => {
          return o.value === confirmDetail.vseOption;
        }).note
      : null;

  return (
    <>
      {(showWorksInProgress || showSiteAccessIssues || showSiteHazardIssues || showPowerOn || showAccessConsent) && (
        <div className="mb-3">
          {showWorksInProgress && (
            <>
              <div className="label">{productDetail.worksInProgressDescription}</div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.worksInProgress}
                onTouchButton={onChangeWorksInProgress}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ]}
              />

              <AvField
                bsSize="sm"
                type="text"
                name="hiddenWorksInProgress"
                value={confirmDetail.worksInProgress + ''}
                className="qs-tc-hidden"
                validate={{ validateWorksInProgress }}
              />

              {showWorksInProgressDetail && confirmDetail.worksInProgress === 'true' && (
                <>
                  <FormGroup className="qs-mt-md">
                    <AvField
                      bsSize="sm"
                      name="worksInProgressDetail"
                      placeholder="Tell us more..."
                      type="textarea"
                      value={confirmDetail.worksInProgressDetail}
                      onChange={e => onChange(e, { worksInProgressDetail: e.target.value })}
                      validate={{
                        required: { value: true, errorMessage: 'Please provide details' },
                        minLength: { value: 3, errorMessage: 'Your details are not the required length' },
                        maxLength: { value: 250, errorMessage: 'Your details should be 250 characters or less' }
                      }}
                    />
                  </FormGroup>
                </>
              )}
            </>
          )}

          {showSiteHazardIssues && (
            <>
              <div className="label">{productDetail.siteHazardIssuesDescription}</div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.siteHazardIssues}
                onTouchButton={onChangeSiteHazardIssues}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ]}
              />

              <AvField
                bsSize="sm"
                type="text"
                name="hiddenSiteHazardIssues"
                value={confirmDetail.siteHazardIssues + ''}
                className="qs-tc-hidden"
                validate={{ validateSiteHazardIssues }}
              />

              {showSiteHazardIssuesDetail && confirmDetail.siteHazardIssues === 'true' && (
                <>
                  <FormGroup className="qs-mt-md">
                    <AvField
                      bsSize="sm"
                      name="siteHazardIssuesDetail"
                      placeholder="Tell us more..."
                      type="textarea"
                      value={confirmDetail.siteHazardIssuesDetail}
                      onChange={e => onChange(e, { siteHazardIssuesDetail: e.target.value })}
                      validate={{
                        required: { value: true, errorMessage: 'Please provide details' },
                        minLength: { value: 3, errorMessage: 'Your details are not the required length' },
                        maxLength: { value: 250, errorMessage: 'Your details should be 250 characters or less' }
                      }}
                    />
                  </FormGroup>
                </>
              )}
            </>
          )}

          {showSiteAccessIssues && (
            <>
              <div className="label">{productDetail.siteAccessIssuesDescription}</div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.siteAccessIssues}
                onTouchButton={onChangeSiteAccessIssues}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'Yes',
                    value: 'false'
                  },
                  {
                    label: 'No',
                    value: 'true'
                  }
                ]}
              />

              <AvField
                bsSize="sm"
                type="text"
                name="hiddenSiteAccessIssues"
                value={confirmDetail.siteAccessIssues + ''}
                className="qs-tc-hidden"
                validate={{ validateSiteAccessIssues }}
              />

              {showSiteAccessIssuesDetail && confirmDetail.siteAccessIssues === 'true' && (
                <>
                  <FormGroup className="qs-mt-md">
                    <AvField
                      bsSize="sm"
                      name="siteAccessIssuesDetail"
                      placeholder="Tell us more..."
                      type="textarea"
                      value={confirmDetail.siteAccessIssuesDetail}
                      onChange={e => onChange(e, { siteAccessIssuesDetail: e.target.value })}
                      validate={{
                        required: { value: true, errorMessage: 'Please provide details' },
                        minLength: { value: 3, errorMessage: 'Your details are not the required length' },
                        maxLength: { value: 250, errorMessage: 'Your details should be 250 characters or less' }
                      }}
                    />
                  </FormGroup>
                </>
              )}
            </>
          )}

          {showPowerOn && (
            <>
              <div className="label">Is the power on at this property?</div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.powerOn}
                onTouchButton={onChangePowerOn}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ]}
              />

              <div
                className="qs-mt-md"
                dangerouslySetInnerHTML={{
                  __html:
                    confirmDetail.powerOn === 'true'
                      ? productDetail.powerOnYesDescription
                      : productDetail.powerOnNoDescription
                }}
              ></div>

              {confirmDetail.powerOn === 'false' && productDetail.vse && (
                <>
                  <div className="label">{productDetail.vse.introduction}</div>
                  <FormGroup>
                    <AvField
                      bsSize="sm"
                      name="vseOption"
                      placeholder="VseOption"
                      type="select"
                      value={confirmDetail.vseOption}
                      onChange={e => onChange(e, { vseOption: e.target.value })}
                      validate={{
                        required: { value: true, errorMessage: 'Please select a timeslot' }
                      }}
                    >
                      <option value="">Select a timeslot</option>
                      {productDetail.vse.options.map((ref, i) => (
                        <option key={`vseOption-${ref.id}`} value={ref.value}>
                          {ref.description}
                        </option>
                      ))}
                    </AvField>
                  </FormGroup>
                  {vseNote && <div>{vseNote}</div>}
                </>
              )}
            </>
          )}

          {showAccessConsent && (
            <>
              <div className="label">{productDetail.accessConsentDescription}</div>
              <QsButtonGroup
                id="sorter"
                fieldName="Sort"
                selectedValue={confirmDetail.accessConsent}
                onTouchButton={onChangeAccessConsent}
                additionalClass="qs-button-group-field-entry qs-button-group-half-on-large"
                buttons={[
                  {
                    label: 'Yes',
                    value: 'true'
                  },
                  {
                    label: 'No',
                    value: 'false'
                  }
                ]}
              />

              <AvField
                bsSize="sm"
                type="text"
                name="hiddenAccessConsent"
                value={confirmDetail.accessConsent + ''}
                className="qs-tc-hidden"
                validate={{ validateAccessConsent }}
              />
            </>
          )}
        </div>
      )}

      {isNewOccupancy && offer.productDetail.moveInDisclaimer && (
        <>
          <div className="qs-more-info-table-header">Moving in</div>
          <div
            dangerouslySetInnerHTML={{
              __html: offer.productDetail.moveInDisclaimer
            }}
          />

          <Conditions
            offer={offer}
            confirmDetail={confirmDetail}
            setConfirmDetail={setConfirmDetail}
            type={conditionTypes.MOVE_IN}
            orientation={orientationTypes.left}
          />
        </>
      )}
    </>
  );
};

export default EnergyAdditionalQuestions;
