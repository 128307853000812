import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const getTags = tags => {
  return tags.map((tag, i) => (
    <>
      {i === 0 ? '' : ', '} <Link to={`/articles/${tag}`}>{tag}</Link>
    </>
  ));
};

const getReleaseDate = item => {
  return moment(moment.utc(item.releaseDateUtc).toDate())
    .local()
    .format('Do MMM, YYYY');
};

export { getTags, getReleaseDate };
