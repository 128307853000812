import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Helmet } from 'react-helmet';
import CommunicationPreferences from '../components/user/communicationPreferences';
import ConciergePreferences from '../components/user/conciergePreferences';
import { useAuth0 } from '../auth/auth0-Provider.js';
import { updatePreferencesWithToken, updatePreferencesWithKey } from '../actions/userActions';
import { sessionExpiredServerMessage } from '../actions/serverMessageActions';
import { scrollToError } from '../actions/serverMessageActions';
import * as serverMessageTargets from '../actions/serverMessageTargets';
import ServerMessage from '../components/common/serverMessage';

import classnames from 'classnames';

const Preferences = () => {
  const { loaded: userLoaded } = useSelector(state => state.user);

  const { conciergeEnabled } = useSelector(state => state.config.settings);

  const [activeTab, setActiveTab] = useState(conciergeEnabled ? '1' : '2');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { serverMessage } = useSelector(state => state);

  const { getAccessToken, getAccessKey, isUserAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  const { PREFERENCES: target } = serverMessageTargets;

  useEffect(() => {
    scrollToError(serverMessage, target);
  }, [serverMessage]);

  const onSave = async (data, onComplete) => {
    const accessKey = getAccessKey();

    // Prioritize accessKey first as even an authenticated user may have come in on a link, possibly for a different customer.
    if (accessKey) {
      dispatch(updatePreferencesWithKey(accessKey, data, onComplete));
    } else if (isUserAuthenticated()) {
      dispatch(updatePreferencesWithToken(await getAccessToken(), data, onComplete));
    } else {
      dispatch(sessionExpiredServerMessage(serverMessageTargets.PREFERENCES));
    }
  };

  return (
    <>
      <Helmet>
        <title>Preferences</title>
      </Helmet>

      <ServerMessage serverMessage={serverMessage} target={target} />

      <div>
        <h1>Your Preferences</h1>
      </div>

      <div className="sm-container sm-preferences">
        {userLoaded && (
          <div>
            <Nav tabs>
              {conciergeEnabled && (
                <NavItem>
                  <NavLink
                    className={`${classnames({ active: activeTab === '1' })} qs-preferences-tab-label`}
                    onClick={() => {
                      toggle('1');
                    }}
                  >
                    Concierge
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={`${classnames({ active: activeTab === '2' })} qs-preferences-tab-label`}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Communication
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="qs-mt-lg">
              {conciergeEnabled && (
                <TabPane tabId="1">
                  <ConciergePreferences updatePreferences={onSave} />
                </TabPane>
              )}
              <TabPane tabId="2">
                <CommunicationPreferences updatePreferences={onSave} />
              </TabPane>
            </TabContent>
          </div>
        )}
      </div>
    </>
  );
};

export default Preferences;
