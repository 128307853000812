import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function signalrReducer(state = initialState.signalr, action) {
  switch (action.type) {
    case types.SET_SIGNALR:
      return objectAssign({}, state, action.data);

    default:
      return state;
  }
}
