import { countryCodes } from '../enums/countryCodes';
import { states } from '../enums/states';
import { submissionTypes } from '../enums/submissionTypes';

const getEmptyAddress = unitNumber => {
  return {
    unitNumber: unitNumber || '',
    streetNumber: null,
    route: null,
    routeShort: null,
    suburb: null,
    suburbShort: null,
    state: null,
    postcode: null,
    country: null,
    fullAddress: null,
    isAddress: false
  };
};

const getEmptySuburbAddress = () => {
  return {
    suburb: null,
    suburbShort: null,
    state: null,
    postcode: null,
    requiresManualPostcode: false,
    country: null,
    fullAddress: null
  };
};

const getEmptyPostcodeAddress = () => {
  return {
    postcode: ''
  };
};

const getSubmissionDisplayAddress = (submissionType, submission) => {
  switch (submissionType) {
    case submissionTypes.BILL:
      return submission.supplyAddress.displayAddress;

    case submissionTypes.ESTIMATE:
      const options = {
        unitNumber: true
      };

      return getAddress(submission.propertyAddress, options).fullAddress;

    default:
      return null;
  }
};

const getAddress = (propertyAddress, options) => {
  const unitNumberPrefix =
    options && options.unitNumber === true && propertyAddress && propertyAddress.unitNumber
      ? `${propertyAddress.unitNumber} / `
      : '';

  return hasValidAddress(propertyAddress)
    ? {
        ...propertyAddress,
        fullAddress: `${unitNumberPrefix}${propertyAddress.streetNumber} ${propertyAddress.route}, ${propertyAddress.suburb} ${propertyAddress.state} ${propertyAddress.postcode}, ${propertyAddress.country}`,
        streetAddress: `${unitNumberPrefix}${propertyAddress.streetNumber} ${propertyAddress.route}`
      }
    : {};
};

const getSuburbAddress = propertyAddress => {
  return hasValidSuburbAddress(propertyAddress)
    ? {
        ...propertyAddress,
        fullAddress: `${propertyAddress.suburb} ${propertyAddress.state}, ${propertyAddress.country}`
      }
    : getEmptySuburbAddress();
};

const hasValidAddress = address => {
  return address &&
    address.streetNumber &&
    address.route &&
    address.suburb &&
    address.postcode &&
    address.state &&
    address.country
    ? true
    : false;
};

const hasValidSuburbAddress = address => {
  return address && address.suburb && address.state && address.country ? true : false;
};

const hasValidPostcodeAddress = address => {
  return address && address.postcode ? true : false;
};

const getStateFromPostcode = (postcode, countryAlpha3) => {
  switch (countryAlpha3) {
    case countryCodes.AUSTRALIA:
      return getAustralianState(postcode);
    default:
      return null;
  }
};

const getAustralianState = postcode => {
  postcode = postcode.padStart(4, '0');

  switch (postcode.substring(0, 1)) {
    case '0':
      switch (postcode.substring(0, 2)) {
        case '02':
          return states.AUSTRALIA.NSW.Code;
        case '08':
        case '09':
          return states.AUSTRALIA.NT.Code;
        default:
          return null;
      }
    case '1':
    case '2':
      if (between(postcode, '2600', '2618') || postcode === '2620' || between(postcode, '2900', '2920'))
        return states.AUSTRALIA.ACT.Code;

      return states.AUSTRALIA.NSW.Code;
    case '3':
    case '8':
      return states.AUSTRALIA.VIC.Code;
    case '4':
    case '9':
      return states.AUSTRALIA.QLD.Code;
    case '5':
      return states.AUSTRALIA.SA.Code;
    case '6':
      return states.AUSTRALIA.WA.Code;
    case '7':
      return states.AUSTRALIA.TAS.Code;
    default:
      return null;
  }
};

const between = (value, from, to) => {
  return value.localeCompare(from) >= 0 && value.localeCompare(to) <= 0;
};

export {
  getAddress,
  getSuburbAddress,
  getStateFromPostcode,
  getEmptyAddress,
  getEmptySuburbAddress,
  getEmptyPostcodeAddress,
  getSubmissionDisplayAddress,
  hasValidAddress,
  hasValidSuburbAddress,
  hasValidPostcodeAddress
};
