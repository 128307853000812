import React from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { getTrackerNames } from '../../utilities/googleAnalyticsEvents';

const OutboundLink = props => {
  const { eventLabel, to, target, rel, children } = props;
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);

  return (
    <ReactGA.OutboundLink
      eventLabel={eventLabel}
      to={to}
      target={target}
      rel={rel}
      trackerNames={getTrackerNames(googleAnalyticsClientCode)}
    >
      {children}
    </ReactGA.OutboundLink>
  );
};

export default OutboundLink;
