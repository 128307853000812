import React, { useRef } from 'react';
import { Button } from 'reactstrap';

import { ReactComponent as MailIcon } from '../icons/util/mail.svg';
import { copyValueToClipboard } from '../../utilities/commonUtilities';

const EmailForwardSummaryCard = ({ receiveBillEmailAddress }) => {
  const contentElement = useRef(null);

  return (
    <>
      <div className="sm-email-forward-summary-card">
        <div className="icon-wrap">
          <MailIcon className="icon" width="40" />
        </div>
        <div className="inner-content">
          <input
            type="text"
            ref={contentElement}
            readOnly
            className="form-control-plaintext"
            value={receiveBillEmailAddress}
          />
          <div>
            <Button className="sm-link-button large" onClick={() => copyValueToClipboard(contentElement.current)}>
              Copy to clipboard
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailForwardSummaryCard;
