import { billTypes } from './billTypes';

export const uploadBillTypes = {
  NONE: { Id: 0 },
  WEBSITE: {
    Id: 1,
    Name: 'Website',
    ButtonText: 'Upload e-bill',
    Description: 'Upload your latest e-bill',
    GaName: 'Upload-Bill'
  },
  EMAIL: {
    Id: 2,
    Name: 'Email',
    ButtonText: 'Email e-bill',
    Description: 'Email us your latest e-bill',
    GaName: 'Email-Bill'
  },
  MANUAL: {
    Id: 4,
    Name: 'Manual',
    ButtonText: 'Enter address',
    Description: 'Enter your property address',
    GaName: 'Estimate'
  },
  DETAILS: {
    Id: 5,
    Name: 'Details',
    ButtonText: 'Details',
    Description: 'View Details',
    GaName: 'View-Details'
  },
  AFFILIATE: {
    Id: 6,
    Name: 'Affiliate'
  },
  PARTNER: {
    Id: 7,
    Name: 'Partner'
  },
  MOVING_HOUSE: {
    Id: 8,
    Name: 'Moving House',
    ButtonText: 'Moving House?',
    Description: 'Are you moving?',
    GaName: 'Moving-House'
  },
  CDR: {
    Id: 9,
    Name: 'Consume Data Right',
    ButtonText: 'Get bill data',
    Description: 'Access your bill data via CDR',
    GaName: 'Consumer-Data-Right'
  }
};

export const uploadSubTypes = {
  NONE: { Id: 0 },
  REFINANCE: {
    Id: 1,
    Name: 'Refinance',
    ButtonText: 'Refinance',
    Description: 'I have an existing loan'
  },
  NEW_LOAN: {
    Id: 2,
    Name: 'New Loan',
    ButtonText: 'New Loan',
    Description: "I'm considering a new loan"
  },
  DEFAULT_COMPARISON: {
    Id: 3,
    Name: 'Default Comparison',
    ButtonText: 'View offers',
    Description: 'Go to comparison'
  },
  ESTIMATE_UPLOAD: {
    Id: 4,
    Name: 'Estimate Upload'
  },
  BILL_UPLOAD_ESTIMATE: {
    Id: 5,
    Name: 'Bill Upload Estimate'
  },
  LAUNCH: {
    Id: 6,
    Name: 'Launch'
  },

  getSubType: (serviceType, estimate) => {
    switch (serviceType) {
      case billTypes.HOME_LOAN:
        return estimate && estimate.refinance ? uploadSubTypes.REFINANCE : uploadSubTypes.NEW_LOAN;

      default:
        return uploadSubTypes.NONE;
    }
  }
};

export function getById(id) {
  switch (id) {
    case uploadBillTypes.WEBSITE.Id:
      return uploadBillTypes.WEBSITE;
    case uploadBillTypes.EMAIL.Id:
      return uploadBillTypes.EMAIL;
    case uploadBillTypes.MANUAL.Id:
      return uploadBillTypes.MANUAL;
    case uploadBillTypes.DETAILS.Id:
      return uploadBillTypes.DETAILS;
    default:
      return uploadBillTypes.NONE;
  }
}
