import * as types from './actions';
import axios from 'axios';
import { setAjaxCallStart, setAjaxCallEnd } from './ajaxCallActions';
import { goHome } from './comparisonActions';
import qs from 'query-string';

export function setConfigSuccess(config) {
  return { type: types.SET_CONFIG, config };
}

export function setContentSuccess(content) {
  return { type: types.SET_CONTENT, data: { ...content } };
}

export function setSessionSuccess(session) {
  return { type: types.SET_SESSION, data: { ...session } };
}

export function setSelectedAgentSuccess(agent) {
  return { type: types.SET_SELECTED_AGENT, data: { ...agent } };
}

export function setConfig(browserId) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    const model = {
      path: window.location.pathname,
      query: window.location.search,
      browserId
    };

    axios
      .post('/api/config', model)
      .then(response => {
        if (response.data) {
          if (response.data.config.redirectBaseUrl) {
            const redirectAgentMemberCode = response.data.config.redirectAgentMemberCode;

            let addAgent = '';
            if (redirectAgentMemberCode) {
              const locationSearch = qs.parse(window.location.search);
              const agent = locationSearch.agent;

              if (model.query) {
                if (!agent) addAgent = `&agent=${redirectAgentMemberCode}`;
              } else addAgent = `?agent=${redirectAgentMemberCode}`;
            }

            window.location.href = `${response.data.config.redirectBaseUrl}${model.path}${model.query}${addAgent}`;
          }

          dispatch(
            setConfigSuccess({
              ...response.data.config,
              applicationSettings: { ...response.data.applicationSettings },
              isLoaded: true
            })
          );

          if (response.data.agentMember) {
            dispatch(setSelectedAgentSuccess({ ...response.data.agentMember, isLoaded: true }));
          }

          dispatch(setContentSuccess({ ...response.data.content }));
          dispatch(setSessionSuccess({ ...response.data.session }));
        } else {
          dispatch(goHome());
        }
      })
      .catch(() => {
        dispatch(goHome());
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}

export function clearAgentMember() {
  return dispatch => {
    dispatch(
      setSelectedAgentSuccess({
        code: null,
        name: null,
        receiveBillEmailAddress: null,
        isLoaded: false
      })
    );
  };
}

export function setAgentMemberByName(sessionActivity, agentName) {
  return dispatch => {
    dispatch(setAjaxCallStart());

    axios
      .post(`/api/config/members/name/${agentName}`, sessionActivity)
      .then(response => {
        if (response.data) {
          dispatch(
            setSelectedAgentSuccess({
              ...response.data
            })
          );
        } else {
          dispatch(clearAgentMember());
        }
      })
      .catch(() => {
        dispatch(clearAgentMember());
      })
      .then(function() {
        dispatch(setAjaxCallEnd());
      });
  };
}
