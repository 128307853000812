import environmentConfiguration from '../store/environmentConfig';

const copyValueToClipboard = e => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', e.value);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    if (typeof e.select === 'function') e.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    }
  }
};

const copyTextToClipboard = value => {
  navigator.clipboard.writeText(value).catch(error => {
    console.warn('Failed to copy text:', error);
  });
};

function generateReferenceCode() {
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let code = '';

  for (let i = 0; i < 32; i++) {
    let randomIndex = Math.floor(Math.random() * chars.length);
    code += chars.charAt(randomIndex);
  }

  return code;
}

function toTitleCase(value) {
  return value.replace(/\w\S*/g, function(e) {
    return e.charAt(0).toUpperCase() + e.substr(1).toLowerCase();
  });
}

const truncateWithEllipses = (text, max) => {
  if (!text || text.trim().length === 0) return '';
  if (text.length <= max) return text;
  return `${text.substr(0, max - 3)}...`;
};

const delay = time => {
  return new Promise(resolve => setTimeout(resolve, time));
};

const newTab = function(path) {
  const proxy = environmentConfiguration.proxy || '';
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = proxy + path;
  tempLink.setAttribute('target', '_blank');
  tempLink.setAttribute('rel', 'noopener noreferrer');
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

const popUp = function(path, target, w, h) {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  const proxy = environmentConfiguration.proxy || '';
  const href = proxy + path;

  const features = target ? `width=${w / systemZoom},height=${h / systemZoom},top=${top},left=${left}` : null;

  const win = window.open(href, target, features);

  return !(!win || win.closed || typeof win.closed === undefined);
};

const lowerObjectProperties = value => {
  if (!value) return value;

  const keys = Object.keys(value);
  const o = {};

  let key;
  let keyLength = keys.length;

  while (keyLength--) {
    key = keys[keyLength];
    o[key.toLowerCase()] = value[key];
  }

  return o;
};

const getAttemptCount = (attemptIntervals, maxAttemptTime) => {
  const maxIntervals = attemptIntervals.length - 1;
  const specifiedSeconds = attemptIntervals.slice(0, -1).reduce((a, b) => a + b, 0) / 1000; // get sum of all but last element in array
  const timeLeft = maxAttemptTime - specifiedSeconds; // work out how many seconds unspecified intervals would take up
  const attemptCount = attemptIntervals.length - 1 + timeLeft / (attemptIntervals[maxIntervals] / 1000); // determine the number of attempts required

  return Math.ceil(attemptCount);
};

const isEmptyObject = obj => {
  return Object.keys(obj).length === 0;
};

const getSessionActivity = session => {
  return {
    path: window.location.pathname,
    query: window.location.search,
    browserId: session.browserId,
    sessionId: session.sessionId,
    startId: session.startId,
    primaryId: session.primaryId
  };
};

const getReferenceDataItem = (id, referenceData) => {
  return id && referenceData
    ? referenceData.find(item => {
        return item.id === parseInt(id);
      })
    : null;
};

export {
  copyValueToClipboard,
  copyTextToClipboard,
  generateReferenceCode,
  toTitleCase,
  truncateWithEllipses,
  delay,
  newTab,
  popUp,
  lowerObjectProperties,
  getAttemptCount,
  isEmptyObject,
  getSessionActivity,
  getReferenceDataItem
};
