import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { AvForm } from 'availity-reactstrap-validation';

import { useAuth0 } from '../../auth/auth0-Provider.js';

import ServerMessage from '../common/serverMessage';
import HomeLoanCurrentAu from './homeLoanCurrentAu';
import HomeLoanNewAu from './homeLoanNewAu';

import { submissionTypes } from '../../enums/submissionTypes';
import { uploadSubTypes } from '../../enums/uploadBillTypes';
import { billTypes } from '../../enums/billTypes';

import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { getEmptyAddress, getAddress } from '../../utilities/addressUtilities';
import { submitAutoEstimates } from '../serviceCard/serviceCardUtilities';

import {
  submitEstimateWithToken,
  submitEstimateWithKey,
  submitEstimateUnathenticated
} from '../../actions/energyEstimateActions';
import { getComparisonWithToken, getComparisonWithKey } from '../../actions/comparisonActions';
import {
  setServerMessageClear,
  displayNoOffersMessage,
  sessionExpiredServerMessage,
  comparisonErrorMessage
} from '../../actions/serverMessageActions';
import { deleteUserBillSuccess } from '../../actions/comparisonActions';
import { deleteServiceCard } from '../../actions/serviceCardActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';

import { loanTypes, repaymentTypes, loanPropertyTypes } from '../../enums/submissionTypes';
import { serviceCardLoadingTypes } from '../../enums/serviceCardLoadingTypes';
import { getSessionActivity } from '../../utilities/commonUtilities';
import { addSessionActivity } from '../../actions/sessionActivityActions.js';
import { sessionActivityTypes } from '../../enums/sessionActivityTypes.js';
import BrandScroller from '../common/brandScroller.js';

const HomeLoanAu = ({ selectedUploadSubType, launchMode, submissionId, onSuccess, onBack }) => {
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { data: userBills, loaded: userBillsLoaded } = useSelector(state => state.userBills);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { serverMessage, selectedAgent, session } = useSelector(state => state);
  const {
    accessKey: comparisonAccessKey,
    accessId: comparisonAccessId,
    submissionId: comparisonSubmissionId
  } = useSelector(state => state.comparison);
  const { accessKey: userAccessKey, accessId: userAccessId } = useSelector(state => state.user);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const sessionActivity = getSessionActivity(session);

  const getCurrentLoanSettings = () => {
    if (userBill) {
      return {
        propertyAddress: getAddress(userBill.estimate.propertyAddress),
        propertyValue: userBill.estimate.propertyValue,
        currentLoanAmount: userBill.estimate.currentLoanAmount,
        monthlyRepayment: userBill.estimate.monthlyRepayment,
        providerCode: userBill.estimate.providerCode
      };
    } else {
      return {
        propertyAddress: getEmptyAddress(null),
        propertyValue: 600000,
        currentLoanAmount: 480000,
        monthlyRepayment: 1000,
        providerCode: null
      };
    }
  };

  const getNewLoanSettings = () => {
    if (userBill) {
      return {
        propertyType: userBill.estimate.propertyType.toString(),
        loanType: userBill.estimate.loanType.toString(),
        repaymentType: userBill.estimate.repaymentType.toString(),
        newLoanAmount: userBill.estimate.newLoanAmount,
        loanPeriod: userBill.estimate.loanPeriod,
        splitValue: userBill.estimate.splitValue
      };
    } else {
      return {
        propertyType: loanPropertyTypes.OWNER_OCCUPIED.value,
        loanType: loanTypes.FIXED.value,
        repaymentType: repaymentTypes.PRINCIPAL_INTEREST.value,
        newLoanAmount: 480000,
        loanPeriod: 30,
        splitValue: 1
      };
    }
  };

  const getUserBill = id => {
    return userBills.find(e => e.submissionId !== null && e.submissionId === id);
  };

  const [completedNotification, setCompletedNotification] = useState();
  const [userBill, setUserBill] = useState(getUserBill(submissionId));
  const [step, setStep] = useState(selectedUploadSubType === uploadSubTypes.NEW_LOAN ? 1 : 0);
  const [currentHomeLoanData, setCurrentHomeLoanData] = useState(getCurrentLoanSettings());
  const [newHomeLoanData, setNewHomeLoanData] = useState(getNewLoanSettings());
  const [propertyValueInitialized, setPropertyValueInitialized] = useState(submissionId ? false : true);
  const [form, setForm] = useState();

  const dispatch = useDispatch();

  const { ESTIMATE_DIALOG: target } = serverMessageTargets;

  useEffect(() => {
    dispatch(setServerMessageClear(target, serverMessage));
  }, []);

  const nextStep = hasOffers => {
    if (hasOffers) {
      if (step === 0) {
        setStep(step + 1); // Navigate to 'new loan' settings if we are on 'current loan' settings.
      }
      if (step === 1) {
        onSuccess(); // Allows parent to close dialog.
        dispatch(push('/compare'));
      }
    } else {
      dispatch(displayNoOffersMessage(target));
    }
  };

  // --------------------------------------------------------------------------
  // Next button click
  // --------------------------------------------------------------------------
  const onNext = (currentData, hasChanges) => {
    setCurrentHomeLoanData(currentData);

    if (propertyValueInitialized) {
      setPropertyValueInitialized(false);
      setNewHomeLoanData({ ...newHomeLoanData, newLoanAmount: currentData.currentLoanAmount });
    }

    const termsAndConditionsAccepted = currentData.termsAndConditions;

    // If terms and conditions have not been accepted, hasChanges may be false, so submit form to trigger validation.
    // If we don't have a user bill or the user bill has passed its reduction expiry, or data has changed, call for a new estimate.
    if (
      !termsAndConditionsAccepted ||
      hasChanges ||
      !userBill ||
      (userBill.reductionLoaded && userBill.reductionExpiry < new Date())
    ) {
      form.submit();
    } else {
      nextStep(userBill.bestOffer);
    }
  };

  // --------------------------------------------------------------------------
  // Get offers button click
  // --------------------------------------------------------------------------
  const onRecalculate = newData => {
    setNewHomeLoanData(newData);
    form.submit();
  };

  // --------------------------------------------------------------------------
  // Previous button click
  // --------------------------------------------------------------------------
  const onPrevious = newData => {
    switch (selectedUploadSubType) {
      case uploadSubTypes.REFINANCE:
        if (newData !== null) {
          setNewHomeLoanData(newData);
          //setCurrentHomeLoanData({ ...currentHomeLoanData, loanAmount: newData.loanAmount });
        }
        setStep(step - 1);
        break;

      case uploadSubTypes.NEW_LOAN:
        onBack();
        break;

      default:
        break;
    }
  };

  // --------------------------------------------------------------------------
  // Get comparison handler.
  // --------------------------------------------------------------------------
  const getComparison = termsAndConditionsAccepted => {
    // If terms and conditions have not been accepted, submit form to trigger validation.
    if (!termsAndConditionsAccepted) {
      form.submit();
      return;
    }

    // If we already have a comparison for this home loan and it's reductionExpiry is still current just navigate to compare screen (no point starting another comparison API call).
    if (comparisonSubmissionId === userBill.submissionId && userBill.reductionExpiry > new Date()) {
      onSuccess(); // Allows parent to close dialog.
      dispatch(push('/compare'));
      return;
    }

    const accessKey = userBill.accessKey;
    const serviceCardLoading = userBillsLoaded ? serviceCardLoadingTypes.NONE : serviceCardLoadingTypes.DEFAULT;

    if (accessKey) {
      onSuccess(); // Allows parent to close dialog.
      dispatch(
        getComparisonWithKey(
          { key: accessKey.accessKey, id: accessKey.accessId },
          null,
          submissionTypes.ESTIMATE,
          billTypes.HOME_LOAN,
          serviceCardLoading
        )
      );
    } else if (isUserAuthenticated()) {
      onSuccess(); // Allows parent to close dialog.
      const fn = async () => {
        dispatch(
          getComparisonWithToken(await getAccessToken(), submissionId, submissionTypes.ESTIMATE, billTypes.HOME_LOAN)
        );
      };
      fn();
    } else {
      dispatch(sessionExpiredServerMessage(target));
    }
  };

  // --------------------------------------------------------------------------
  // Estimate comparison error handler.
  // --------------------------------------------------------------------------
  const onError = error => {
    dispatch(comparisonErrorMessage(error, target));
  };

  // --------------------------------------------------------------------------
  // Add session activity.
  // --------------------------------------------------------------------------
  const recordSessionActivity = (comparisonResult, userBill) => {
    dispatch(
      addSessionActivity({
        ...sessionActivity,
        data: {
          submissionType: submissionTypes.ESTIMATE,
          submissionId: userBill.submissionId,
          serviceType: billTypes.HOME_LOAN.Code,
          hasOffers: comparisonResult.hasOffers
        },
        activityType: sessionActivityTypes.COMPARISON_COMPLETED
      })
    );
  };

  // ----------------------------------------------------------------------------
  // Estimate complete handler
  // ----------------------------------------------------------------------------
  const onComplete = (comparisonResult, newBill) => {
    recordSessionActivity(comparisonResult, newBill);

    // There will not be a userBill if this is a new estimate (e.g. via 'Add Another' or 'Add New')
    // Ensure that this is a new estimate before deleting. It may be an anonymous estimate that is just being
    // run under a real email address now; we do not want to delete that. It will have the same submissionId in that case.
    if (newBill && userBill && newBill.submissionId !== userBill.submissionId) {
      // Determines if the comparison results are displayed and the dialog closes at the end; in this case it doesn't apply - nextStep knows internally if it should do this.
      const comparisonReady = undefined;

      // A new estimate has completed - delete the current one.
      dispatch(deleteServiceCard(comparisonReady, userBill, isUserAuthenticated(), getAccessToken, onDeleteComplete));
    }

    if (selectedUploadSubType === uploadSubTypes.REFINANCE) {
      dispatchAutoEstimates();
    }

    setUserBill(newBill);
    nextStep(comparisonResult.hasOffers);
    setCompletedNotification(Date.now()); // notify child component.
  };

  // ----------------------------------------------------------------------------
  // Delete complete
  // ----------------------------------------------------------------------------
  const onDeleteComplete = data => {
    dispatch(
      deleteUserBillSuccess({
        ...data
      })
    );
  };

  // --------------------------------------------------------------------------
  // Submit new estimate request.
  // --------------------------------------------------------------------------
  const onSubmitEstimate = () => {
    const estimate = {
      ...currentHomeLoanData,
      ...newHomeLoanData,
      refinance: selectedUploadSubType === uploadSubTypes.REFINANCE,
      serviceType: billTypes.HOME_LOAN.Id,
      sessionActivity
    };

    const hasChanges = false;
    const userInitiated = true;
    const defaultComparison = userBill && userBill.estimate.defaultComparison;

    // If the user checked the comparison email checkbox from an initial state of false (i.e. they want an email for this comparison),
    // then fire an API call to initiate the email send (even if nothing else on the form changed; i.e. hasChanges = false)
    const sendComparisonEmailInitiated = false; //!initialComparisonEmailValue && submissionCommon.sendComparisonEmail;

    // Check if anything has changed before making a re-estimate request.
    // User initiated considered (i.e. hasChanges = false), as user may have triggered the dialog and accepted a complete set of defaults (if possible)
    if (hasChanges || userInitiated || defaultComparison || sendComparisonEmailInitiated) {
      const options = { showSpinner: true, applySleep: true, updateComparison: step === 1 };

      const userBillAccessKey = userBill && userBill.accessKey ? userBill.accessKey.accessKey : null;
      const userBillAccessId = userBill && userBill.accessKey ? userBill.accessKey.accessId : null;

      const accessKey = userBillAccessKey || comparisonAccessKey || userAccessKey;
      const accessId = userBillAccessId || comparisonAccessId || userAccessId;

      if (accessKey && accessId) {
        const data = { estimate, access: { key: accessKey, id: accessId } };
        dispatch(submitEstimateWithKey(billTypes.HOME_LOAN, data, options, onComplete, onError));
      } else if (isUserAuthenticated()) {
        const fn = async () => {
          const accessToken = await getAccessToken();
          dispatch(
            submitEstimateWithToken(accessToken, billTypes.HOME_LOAN, { estimate }, options, onComplete, onError)
          );
        };
        fn();
      } else {
        dispatch(submitEstimateUnathenticated(billTypes.HOME_LOAN, { estimate }, options, onComplete, onError));
      }

      standardEvents('home-loan-dialog', 'Click', 'Confirm', googleAnalyticsClientCode);
    } else {
      // NOTE: homeloans has a viewOffers button rather than an Update button, so if dosen't use this flow.
      // if (!hasOffers) return;
      // onSuccess(true, false);
    }
  };

  const dispatchAutoEstimates = () => {
    const selectedAddress = currentHomeLoanData.propertyAddress;
    const submissionCommon = { emailAddress: currentHomeLoanData.emailAddress };

    dispatch(
      submitAutoEstimates(
        billTypes.HOME_LOAN,
        selectedAddress,
        selectedAgent,
        sessionActivity,
        submissionCommon,
        agentBillTypes,
        userBills,
        googleAnalyticsClientCode
      )
    );
  };

  const getStep = () => {
    switch (step) {
      case 0:
        return (
          <HomeLoanCurrentAu
            currentHomeLoanData={currentHomeLoanData}
            userBill={userBill}
            launchMode={launchMode}
            onBack={onBack}
            onNext={onNext}
            completedNotification={completedNotification}
          />
        );

      case 1:
        return (
          <HomeLoanNewAu
            newHomeLoanData={newHomeLoanData}
            userBill={userBill}
            uploadSubType={selectedUploadSubType}
            launchMode={launchMode}
            onPrevious={onPrevious}
            onRecalculate={onRecalculate}
            onViewOffers={getComparison}
            completedNotification={completedNotification}
          />
        );

      default:
        return null;
    }
  };

  return (
    <AvForm ref={e => setForm(e)} onValidSubmit={onSubmitEstimate}>
      <div>
        <ServerMessage serverMessage={serverMessage} target={target} />
      </div>
      <BrandScroller serviceType={billTypes.HOME_LOAN} dialogMode />
      {getStep()}
    </AvForm>
  );
};

export default HomeLoanAu;
