import React from 'react';
import { useSelector } from 'react-redux';
import { getItemContent } from '../../actions/contentActions';

import GetStarted from '../GetStartedWizard/getStarted';

const Introduction = () => {
  const { hasEstimates } = useSelector(state => state.config.settings);
  const { organiserAgentName } = useSelector(state => state.content.general);
  const { introductionHeading, introductionContent } = useSelector(state => state.content.home);

  return (
    <>
      <div className="sm-home-introduction">
        <div className="heading">{introductionHeading}</div>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: getItemContent(introductionContent, organiserAgentName)
          }}
        />

        <div className="buttons">{hasEstimates && <GetStarted key="homeIntroductionGetStartedDialog" />}</div>
      </div>
    </>
  );
};
export default Introduction;
