import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AvField } from 'availity-reactstrap-validation';

import { Row, Col, Collapse, Spinner, FormGroup } from 'reactstrap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { getAddressComponent } from '../../utilities/googleAddressUtilities';
import { getStateFromPostcode, getEmptySuburbAddress, hasValidSuburbAddress } from '../../utilities/addressUtilities';

const SuburbSelector = ({
  onChange: onChangeParent,
  showManualPostcode,
  showAreasSupportedDisclaimer,
  propertyAddress,
  enabled: userEnabled
}) => {
  const enabled = userEnabled !== false;

  const { country } = useSelector(state => state.config);
  const { postcodeRegex } = useSelector(state => state.config.applicationSettings.country);
  const { areasSupportedDisclaimer } = useSelector(state => state.content.general);

  const getFullSuburbAddress = address => {
    return hasValidSuburbAddress(address) ? `${address.suburb} ${address.state}, ${address.country}` : '';
  };

  const address = hasValidSuburbAddress(propertyAddress) ? propertyAddress : getEmptySuburbAddress();

  // --------------------------------------------------------------------------
  // Initialize the component.
  // --------------------------------------------------------------------------
  useEffect(() => {
    onChange({
      ...address,
      fullAddress: getFullSuburbAddress(address)
    });
  }, []);

  // --------------------------------------------------------------------------
  // Main change handler.
  // --------------------------------------------------------------------------
  const onChange = suburbStatePostcode => {
    if (onChangeParent) onChangeParent(suburbStatePostcode);
  };

  // --------------------------------------------------------------------------
  // Postcode change handler.
  // --------------------------------------------------------------------------
  const onChangePostcode = postcode => {
    const regex = RegExp(postcodeRegex);

    if (regex.test(postcode) && getStateFromPostcode(postcode, country.alpha3)) {
      onChange({ ...address, postcode });
    }
  };

  // --------------------------------------------------------------------------
  // GooglePlacesAutocomplete change handler.
  // --------------------------------------------------------------------------
  const onInputValueChange = () => {
    if (address && address.fullAddress !== '') {
      const emptyAddress = getEmptySuburbAddress();

      onChange(emptyAddress);
    }
  };

  // --------------------------------------------------------------------------
  // GooglePlacesAutocomplete select handler.
  // --------------------------------------------------------------------------
  const applyAddress = (description, terms, placeId) => {
    if (placeId) {
      geocodeByPlaceId(placeId)
        .then(results => {
          var place = results[0];
          var suburb = getAddressComponent(place.address_components, 'sub_locality', true);
          if (!suburb) suburb = getAddressComponent(place.address_components, 'locality', true);
          var suburbShort = getAddressComponent(place.address_components, 'sub_locality', false);
          if (!suburbShort) suburbShort = getAddressComponent(place.address_components, 'locality', false);
          var state = getAddressComponent(place.address_components, 'administrative_area_level_1', false);
          var postcode = getAddressComponent(place.address_components, 'postal_code', true);

          var userAddress = {
            suburb,
            suburbShort,
            state,
            postcode,
            requiresManualPostcode: !postcode,
            country: country.name,
            fullAddress: description
          };

          onChange(userAddress);
        })
        .catch(() => {
          console.log('error geocoding');
        });
    }
  };

  return (
    <>
      <div className="qs-mb-lg">
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            types: ['(cities)'],
            componentRestrictions: {
              country: [country.alpha2.toLowerCase()]
            }
          }}
          disabled={!enabled}
          placeholder="Type your suburb here"
          loader={<Spinner size="sm" color="success" className="sm-address-loader" />}
          initialValue={address.fullAddress}
          onSelect={({ description, terms, place_id }) => applyAddress(description, terms, place_id)}
          renderInput={props => (
            <FormGroup>
              <input
                {...props}
                autoComplete="new-suburb" // Turns off google autofill
                className="form-control form-control-sm"
                onChange={ev => {
                  props.onChange(ev);
                  onInputValueChange(ev);
                }}
              />
            </FormGroup>
          )}
          renderSuggestions={(active, suggestions, onSelectSuggestion) => (
            <div className="qs-suggestions-container">
              {suggestions.map((suggestion, index) => (
                <div
                  className={active === index ? 'qs-suggestion-active' : 'qs-suggestion'}
                  onClick={event => onSelectSuggestion(suggestion, event)}
                  key={`qs-suburb-${index}`}
                >
                  {suggestion.description}
                </div>
              ))}
            </div>
          )}
        />
        {showAreasSupportedDisclaimer && (
          <div className="qs-energy-estimate-dialog-disclaimer">{areasSupportedDisclaimer}</div>
        )}
      </div>
      <div className="qs-home-loan-address-validation">
        <AvField
          type="text"
          name={`address_validation`}
          value={address.fullAddress}
          className="sm-validator-hidden"
          validate={{
            hidden: (value, ctx, input, cb) => {
              if (!value || value.length === 0) {
                cb('Please enter and select a suburb');
              } else cb(true);
            }
          }}
        />
      </div>
      <Collapse isOpen={showManualPostcode && address.requiresManualPostcode}>
        <Row className="qs-home-loan-dialog-row">
          <Col xs="6" lg="3">
            <AvField
              type="text"
              name="postcode"
              style={{ fontSize: '0.9em' }}
              placeholder="Postcode..."
              value={address.postcode === null ? '' : address.postcode}
              onChange={e => onChangePostcode(e.target.value)}
              validate={{
                pattern: {
                  value: postcodeRegex,
                  errorMessage: `Please enter a valid postcode for ${country.name}`
                },
                required: { value: true, errorMessage: 'Please enter a postcode' },
                postcode: (value, ctx, input, cb) => {
                  const regex = RegExp(postcodeRegex);

                  if (regex.test(value) && getStateFromPostcode(value, country.alpha3) !== address.state) {
                    cb('Postcode does not match the selected state');
                  } else {
                    cb(true);
                  }
                }
              }}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
};

export default SuburbSelector;
