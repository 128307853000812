import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { AvForm } from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';

import AddressControl from '../common/addressControl';
import { hasValidAddress } from '../../utilities/addressUtilities';

const AddressFullSelector = ({ selectedAddress, onAddressSelected, onNext }) => {
  const { country } = useSelector(state => state.config);

  const formRef = useRef(null);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const hasSelectedAddress = hasValidAddress(selectedAddress);

  return (
    <AvForm ref={formRef} onValidSubmit={onNext}>
      <div className="address-selector">
        <div className="address">
          <AddressControl
            key="estimateWizardAddress"
            keySuffix="estimateWizard"
            labelText={null}
            country={country}
            onAddressSelected={onAddressSelected}
            autoPlaceholder={selectedAddress.fullAddress || 'Enter an address here'}
            autoPropertyAddress={selectedAddress}
            autoAddressRequired={false}
            manualAddressVisible={false}
            manualSuburbSelectorEnabled={false}
            showAreasSupportedDisclaimer={false}
            showMinimalManualFields={false}
          />
        </div>

        <div className="sm-estimate-wizard-button-container">
          <Button
            className="sm-button-primary go-button static medium"
            disabled={!hasSelectedAddress}
            onClick={hasSelectedAddress ? handleSubmit : undefined}
          >
            Next
          </Button>
        </div>
      </div>
    </AvForm>
  );
};
export default AddressFullSelector;
