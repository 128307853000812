import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { billTypes } from '../../enums/billTypes';
import { getAdWordConversionScript } from '../../utilities/googleGtagUtilities';
import { taboolaConversionScript } from '../../utilities/taboolaUtilities';

import EnergySwitchSummary from '../switch/energySwitchSummary';
import InternetSwitchSummary from '../../components/switch/internetSwitchSummary';
import HomeLoanSwitchSummary from '../../components/switch/homeLoanSwitchSummary';
import SolarFinanceSwitchSummary from '../../components/switch/solarFinanceSwitchSummary';

import GeneralDialogHeader from './generalDialogHeader';

const SwitchedDialogContent = ({ serviceType, getOtherServices }) => {
  const { occupancyTypeIsNew } = useSelector(state => state.comparison.submission);
  const { config } = useSelector(state => state);
  const { referenceNumber } = useSelector(state => state.selectedOffer);

  const applicationType = occupancyTypeIsNew ? 'Connection' : 'Switch';
  const otherServices = getOtherServices ? getOtherServices() : null;

  const getSwitchedSummary = () => {
    switch (serviceType) {
      case billTypes.HOME_LOAN:
        return <HomeLoanSwitchSummary otherServices={otherServices} />;

      case billTypes.INTERNET:
        return <InternetSwitchSummary otherServices={otherServices} />;

      case billTypes.SOLAR_FINANCE:
        return <SolarFinanceSwitchSummary otherServices={otherServices} />;

      default:
        return <EnergySwitchSummary otherServices={otherServices} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>{applicationType} complete</title>

        {getAdWordConversionScript(
          config.isLoaded,
          config.settings.googleAdWordsId,
          config.settings.googleAdWordsConversionLabel,
          referenceNumber
        )}

        {taboolaConversionScript(config.isLoaded, config.settings.taboolaId, config.settings.taboolaConversionLabel)}
      </Helmet>
      <GeneralDialogHeader serviceType={serviceType} showCloseIcon={false} />
      <Container className="sm-switched-summary">{getSwitchedSummary()}</Container>
    </>
  );
};

export default SwitchedDialogContent;
