import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Container } from 'reactstrap';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { ReactComponent as Step1 } from '../icons/organiser-info/step1.svg';
import { ReactComponent as Step2 } from '../icons/organiser-info/step2.svg';
import { ReactComponent as Step3 } from '../icons/organiser-info/step3.svg';
import OrganiserDescription from './organiserDescription';
import OrganiserName from './organiserName';

const OrganiserInfo = ({ onClose }) => {
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { autoCheckEmailDomain } = useSelector(state => state.config.applicationSettings);
  const { emailAddress, autoCheckEmailAddress } = useSelector(state => state.user);
  const { providerDisplayCode } = useSelector(state => state.selectedOffer);
  const { organiserAgentName } = useSelector(state => state.content.general);

  const buttonText = 'Close';

  const description1 = `${providerDisplayCode} will send all email correspondence to your new ${organiserAgentName} email address:`;
  const description2 = `Your e-bills will be automatically added to your ${organiserAgentName}`;
  const description3 = `All ${providerDisplayCode} emails will be instantly relayed to your personal email address:`;

  const emailAddressDisplay = emailAddress && autoCheckEmailAddress ? emailAddress : 'j.smith@gmail.com';
  const autocheckEmailAddressDisplay =
    emailAddress && autoCheckEmailAddress ? autoCheckEmailAddress : `j.smith@${autoCheckEmailDomain}`;

  const closeDialog = () => {
    if (onClose) {
      onClose();
    }

    standardEvents('organiser-info-dialog', 'Click', buttonText, googleAnalyticsClientCode);
  };

  return (
    <div className="sm-organiser-info">
      <div className="heading">
        How your <OrganiserName /> works
      </div>

      <Container className="desktop">
        <Row className="step-image" noGutters={true}>
          <Col xs="4">
            <div className="image">
              <Step1 height="48" />
            </div>
          </Col>
          <Col xs="4">
            <div className="image">
              <Step2 height="64" />
            </div>
          </Col>
          <Col xs="4">
            <Step3 height="64" />
          </Col>
        </Row>
        <Row className="step-heading">
          <Col xs="4">Step 1</Col>
          <Col xs="4">Step 2</Col>
          <Col xs="4">Step 3</Col>
        </Row>
        <Row className="step-description">
          <Col xs="4">
            <span
              dangerouslySetInnerHTML={{
                __html: description1
              }}
            />
            <div className="step-label">{autocheckEmailAddressDisplay}</div>
          </Col>
          <Col xs="4">
            <span
              dangerouslySetInnerHTML={{
                __html: description2
              }}
            />
          </Col>
          <Col xs="4">
            {description3}
            <div className="step-label">{emailAddressDisplay}</div>
          </Col>
        </Row>
      </Container>

      <div className="mobile">
        <div className="step">
          <div className="step-image">
            <Step1 height="64" />
          </div>
          <div className="step-heading">Step 1</div>
          <div
            className="step-description"
            dangerouslySetInnerHTML={{
              __html: description1
            }}
          />
          <div className="step-label">{autocheckEmailAddressDisplay}</div>
        </div>

        <div className="step">
          <div className="step-image">
            <Step2 height="96" />
          </div>
          <div className="step-heading">Step 2</div>
          <div
            className="step-description"
            dangerouslySetInnerHTML={{
              __html: description2
            }}
          />
        </div>

        <div className="step">
          <div className="step-image">
            <Step3 height="96" />
          </div>
          <div className="step-heading">Step 3</div>
          <div className="step-description">{description3}</div>
          <div className="step-label">{emailAddress}</div>
        </div>
      </div>

      <div className="footer">
        <div className="step-heading">Additional information</div>
        <OrganiserDescription providerDisplayCode={providerDisplayCode} paragraphClassName="more-detail" />
        <Button onClick={closeDialog} className="sm-button-primary medium">
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
export default OrganiserInfo;
