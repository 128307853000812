import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '../auth/auth0-Provider';

import { accessKeyTypes, authenticationTypes } from '../enums/authenticationTypes';

const SecureRoute = ({ allowAccessKey, component: Component, path, roles, scopes, ...rest }) => {
  const {
    isAuthenticated: auth0Authenticated,
    loading: auth0Loading,
    loginWithRedirect,
    userHasRole,
    userHasScopes,
    getAccessToken,
    getAccessKey
  } = useAuth0();

  const { authenticationType } = useSelector(state => state.config.settings);

  const accessKey = getAccessKey();

  const validAccessKey =
    allowAccessKey === true &&
    accessKey &&
    (accessKey.accessKeyType !== accessKeyTypes.COMPARISON || accessKey.trustLevel > 1);

  const hasRole = r => {
    switch (authenticationType) {
      case authenticationTypes.CTM:
        return getAccessToken() ? true : false;

      default:
        return auth0Loading || userHasRole(r);
    }
  };

  const hasScopes = s => {
    switch (authenticationType) {
      case authenticationTypes.CTM:
        return getAccessToken() ? true : false;

      default:
        return auth0Loading || userHasScopes(s);
    }
  };

  const isAuthenticated = () => {
    switch (authenticationType) {
      case authenticationTypes.CTM:
        return getAccessToken() ? true : false;

      default:
        return auth0Authenticated === true || validAccessKey;
    }
  };

  useEffect(() => {
    const fn = async () => {
      switch (authenticationType) {
        case authenticationTypes.CTM:
          return true;

        default:
          if (!validAccessKey && !auth0Loading && !auth0Authenticated) {
            await loginWithRedirect({
              appState: { targetUrl: path }
            });
          }
      }
    };
    fn();
  }, [auth0Authenticated, validAccessKey]);

  if (!hasRole(roles)) {
    return <Redirect to="/" />;
  }

  if (!hasScopes(scopes)) {
    return <Redirect to="/" />;
  }

  const render = props => (isAuthenticated() ? <Component {...props} /> : null);

  return <Route path={path} render={render} {...rest} />;
};

export default SecureRoute;
