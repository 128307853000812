import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import YourDetails from '../common/yourDetails';
import { getSubmissionDisplayAddress } from '../../utilities/addressUtilities';
import BillDisplayAddress from '../common/billDisplayAddress';
import AddressControl from '../common/addressControl';

const MyDetailsSection = ({ confirmDetail, offer, onChange }) => {
  const { submissionType, submission } = useSelector(state => state.comparison);
  const { emailAddress, accountVerified } = useSelector(state => state.user);
  const { country } = useSelector(state => state.config);
  const { mobilePhoneNumberRegex, homePhoneNumberRegex } = useSelector(
    state => state.config.applicationSettings.country
  );

  const onSupplyAddressSelected = address => {
    onChange(null, { supplyAddress: address });
  };

  const productDetail = offer.productDetail;
  const displayAddress = getSubmissionDisplayAddress(submissionType, submission);

  return (
    <>
      <div className="label">Connection address</div>
      {displayAddress ? (
        <BillDisplayAddress displayAddress={displayAddress} />
      ) : (
        <AddressControl
          key="supplyAddress"
          keySuffix="supply"
          labelText="Supply address"
          country={country}
          onAddressSelected={onSupplyAddressSelected}
          autoPlaceholder="Property address..."
          autoPropertyAddress={null}
          autoAddressRequired={true}
          manualAddressVisible={true}
          manualSuburbSelectorEnabled={true}
          showAreasSupportedDisclaimer={false}
          large={!isMobile}
          showMinimalManualFields={false}
        />
      )}

      <div className="label">Your details</div>
      <YourDetails
        onChange={onChange}
        details={confirmDetail}
        phoneNumberRegex={mobilePhoneNumberRegex}
        emailAddress={emailAddress}
        showMembershipId={offer.membershipId}
        showAlternatePhoneNumber={productDetail.showAlternatePhoneNumber}
        alternatePhoneNumberRegex={`(${mobilePhoneNumberRegex}|${homePhoneNumberRegex})`}
        showDateOfBirth={productDetail.showDateOfBirth}
        showTitle={true}
        showEmailAddress={true}
        showPhoneNumber={true}
        lockPhoneNumber={accountVerified}
      />
    </>
  );
};

export default MyDetailsSection;
