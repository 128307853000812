import React, { useEffect, useState } from 'react';
import { push, goBack } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import { Button, Container, Row, Col } from 'reactstrap';

import { getArticle } from '../actions/articleActions';
import { openSubmitDialog } from '../actions/dialogOptionsActions';
import { goDashboard, goHome } from '../actions/comparisonActions';

import { billTypes } from '../enums/billTypes';
import { homePageTypes } from '../enums/launchTypes';
import { uploadBillTypes, uploadSubTypes } from '../enums/uploadBillTypes';
import { occupancyTypes } from '../enums/submissionTypes';

import { getAgentBillType, agentHasBillType } from '../utilities/billTypeUtilities';
import { getTags, getReleaseDate } from '../utilities/articleUtilities';
import { standardEvents } from '../utilities/googleAnalyticsEvents';
import OrganiserName from '../components/common/organiserName';

const ArticleScreen = props => {
  const dispatch = useDispatch();

  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { googleAnalyticsClientCode, homePageType, articlesEnabled } = useSelector(state => state.config.settings);
  const { billTypes: agentBillTypes, hasArticles } = useSelector(state => state.config);

  const homePage = homePageTypes.getById(homePageType);

  const [article, setArticle] = useState(null);
  const [articleServiceType, setArticleServiceType] = useState(null);
  const [articleAgentBillType, setArticleAgentBillType] = useState(null);
  const [articleHasServiceCard, setArticleHasServiceCard] = useState(false);
  const [showMoreArticles, setShowMoreArticles] = useState(false);

  useEffect(() => {
    if (articlesEnabled && hasArticles) {
      const slug = props.match.params.slug;
      const locationSearch = qs.parse(props.location.search);

      const from = locationSearch.from;
      if (from) setShowMoreArticles(true);

      dispatch(getArticle(slug, onArticleLoad));
    } else {
      dispatch(goHome());
    }
  }, []);

  const onArticleLoad = article => {
    setArticle(article);
    const serviceType = article && article.billType ? billTypes.getByCode(article.billType, billTypes.NONE) : null;

    setArticleServiceType(serviceType);
    setArticleAgentBillType(getAgentBillType(agentBillTypes, serviceType.Id));
    setArticleHasServiceCard(agentHasBillType(agentBillTypes, serviceType.Id));
  };

  const openServiceCardDialog = () => {
    const agentBillType = getAgentBillType(agentBillTypes, articleServiceType.Id);

    standardEvents('article', 'open-service-type', articleServiceType.Name, googleAnalyticsClientCode);

    if (agentBillType.affiliate) {
      dispatch(openSubmitDialog(articleServiceType, uploadBillTypes.AFFILIATE, uploadSubTypes.NONE));
    } else {
      const serviceType = articleServiceType === billTypes.NONE ? billTypes.ELECTRICITY : articleServiceType; // default to electricity for estimate dialog only.
      dispatch(
        openSubmitDialog(serviceType, uploadBillTypes.MANUAL, uploadSubTypes.NONE, {
          occupancyType: occupancyTypes.CURRENT.Id,
          buttonText: 'Next'
        })
      );
    }

    dispatch(goDashboard());
  };

  return (
    <>
      <Container>
        <Row>
          <Col xs="12">
            <hr />
          </Col>

          {article ? (
            <>
              <Col xs="12">
                <Helmet>
                  <title>{article.headline}</title>
                </Helmet>
                <Button
                  className="sm-article-more-button sm-button-secondary small"
                  onClick={() => dispatch(push('/articles'))}
                >
                  More articles
                </Button>
                <div className="sm-article-heading-tag">{getTags(article.tags)}</div>

                <h2 className="sm-article-heading full">{article.headline}</h2>

                <div className="sm-article-summary-release-date full">{getReleaseDate(article)}</div>
                <hr />
                <img className="sm-article-image" src={`${staticContentUrl}${article.image}`} alt={article.headline} />

                <div
                  className="sm-article-body"
                  dangerouslySetInnerHTML={{
                    __html: article.body
                  }}
                />
                {article.bodyCustomerCommission && (
                  <div
                    className="sm-article-body"
                    dangerouslySetInnerHTML={{
                      __html: article.bodyCustomerCommission
                    }}
                  />
                )}

                <hr />
              </Col>
            </>
          ) : (
            <Col xs="12">
              <div>Loading...</div>
            </Col>
          )}
        </Row>

        <Row className="qs-mt-lg">
          <Col xs="12" md={{ size: 4, offset: 4 }}>
            <div className="sm-article-customer-commission-image">
              {article &&
                article.highlightCustomerCommission &&
                articleAgentBillType &&
                articleAgentBillType.customerCommissionLogo && (
                  <img
                    src={`${staticContentUrl}${articleAgentBillType.customerCommissionLogo}`}
                    alt={articleAgentBillType.customerCommissionFormatted}
                  />
                )}
            </div>

            {articleHasServiceCard ? (
              <Button className="sm-button-primary" style={{ width: '100%' }} onClick={openServiceCardDialog}>
                {articleAgentBillType.affiliate && articleAgentBillType.affiliate.exitButtonText
                  ? articleAgentBillType.affiliate.exitButtonText
                  : `Get started with ${articleServiceType.Name}`}
              </Button>
            ) : (
              <Button
                className="sm-button-primary"
                style={{ width: '100%' }}
                onClick={() => dispatch(push('/dashboard'))}
              >
                View my <OrganiserName nameOnly />
              </Button>
            )}
          </Col>

          <Col xs="12" md={{ size: 2, offset: showMoreArticles ? 4 : 5 }}>
            <Button
              className="sm-link-button medium qs-mt-md"
              style={{ width: '100%' }}
              onClick={() => (window.history.length > 2 ? dispatch(goBack()) : dispatch(push(homePage.path)))}
            >
              Go back
            </Button>
          </Col>

          {showMoreArticles && (
            <Col xs="12" md={{ size: 2 }}>
              <Button
                className="sm-link-button medium qs-mt-md"
                style={{ width: '100%' }}
                onClick={() => dispatch(push('/articles'))}
              >
                More articles
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default ArticleScreen;
