import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _debounce from 'lodash.debounce';

import { useAuth0 } from '../../auth/auth0-Provider';
import Offer from './offer';
import OfferAccept from '../OfferAcceptance/offerAccept';
import IdleOfferTimeout from '../common/idleOfferTimeout';

import * as serverMessageTargets from '../../actions/serverMessageTargets';
import { sessionExpiredServerMessage } from '../../actions/serverMessageActions';
import { setAcceptedOffer } from '../../actions/selectedOfferActions';
import { goHome, getComparisonWithToken, getComparisonWithKey, goDashboard } from '../../actions/comparisonActions';
import { openSubmitDialog } from '../../actions/dialogOptionsActions';
import { resetFramePartner } from '../../actions/partnerActions';

import { onComparisonTimeout } from '../../utilities/compareUtilities';
import { getSessionActivity } from '../../utilities/commonUtilities';
import { getAddress } from '../../utilities/addressUtilities';
import { getNextServices } from '../serviceCard/serviceCardUtilities';
import { postFrameScrollTopMessage } from '../../utilities/domUtilities';
import { offerEvents, pageChanged, standardEvents } from '../../utilities/googleAnalyticsEvents';

import { billTypes } from '../../enums/billTypes';
import { serviceCardLoadingTypes } from '../../enums/serviceCardLoadingTypes';
import { submissionTypes } from '../../enums/submissionTypes';
import { pageModes } from '../../enums/launchTypes';
import { uploadBillTypes, uploadSubTypes } from '../../enums/uploadBillTypes';
import { restartSources } from '../../enums/partnerFrameTypes';

import GeneralDialog from '../dialogs/generalDialog';
import SwitchedDialogContent from '../dialogs/switchedDialogContent';
import IdleTimerDialog from '../dialogs/idleTimerDialog';

import { facebookPageChanged } from '../../utilities/facebookUtilities';

const OfferSwitch = ({ isDirectSwitch, onBack }) => {
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { selectedOffer, user, comparison, confirmDetail, session } = useSelector(state => state);
  const { switchButtonText } = useSelector(state => state.content.general);
  const { googleAnalyticsClientCode, uxSwitchVersion, uxSwitchInternetVersion, showPoweredBy } = useSelector(
    state => state.config.settings
  );
  const { loaded: userBillsLoaded, data: userBills } = useSelector(state => state.userBills);
  const { settings: configSettings, billTypes: agentBillTypes } = useSelector(state => state.config);
  const { pageMode, frameHost } = useSelector(state => state.application);

  const serviceCardLoading = !userBillsLoaded ? serviceCardLoadingTypes.DEFAULT : serviceCardLoadingTypes.NONE;

  const dispatch = useDispatch();

  const target = serverMessageTargets.COMPARE;

  const [switchedDialogOpen, setSwitchedDialogOpen] = useState(false);
  const [switchedDialogCloseButtonText, setSwitchedDialogCloseButtonText] = useState();
  const [switchedDialogConfirmButtonText, setSwitchedDialogConfirmButtonText] = useState();

  const sessionActivity = getSessionActivity(session);

  const serviceType = billTypes.getByCode(comparison.submission.billType);

  const onComplete = offer => {
    const nextUserBill = getNextUserBill();

    if (nextUserBill) {
      const nextServiceType = billTypes.getById(nextUserBill.billTypeId);
      setSwitchedDialogConfirmButtonText(`Continue with ${nextServiceType.Name}`);
      setSwitchedDialogCloseButtonText('No thanks');
    } else {
      setSwitchedDialogCloseButtonText('Done');
    }

    setSwitchedDialogOpen(true);

    offerEvents('switched-dialog', offer, googleAnalyticsClientCode);
    pageChanged('/switched', '', 'Switched', configSettings, false);
    facebookPageChanged(configSettings.facebookPixelId, window, '/switched');
  };

  const getNextUserBill = () => {
    const otherUserBills = getOtherUserBills();

    return otherUserBills.length > 0 ? otherUserBills[0] : null;
  };

  const getOtherUserBills = () => {
    const addressOptions = {
      unitNumber: true
    };

    const switchedAddress = getAddress(comparison.submission.propertyAddress, addressOptions);
    const otherUserBills = getNextServices(serviceType, switchedAddress, agentBillTypes, userBills);

    return otherUserBills;
  };

  const getOtherServices = () => {
    const otherUserBills = getOtherUserBills();

    const services = [];

    otherUserBills.forEach(e => {
      services.push(billTypes.getById(e.billTypeId));
    });

    return services;
  };

  const getComparison = ({ submissionType, submissionId, accessKey, billTypeId, estimate }) => {
    const nextServiceType = billTypes.getById(billTypeId);

    // Auto show estimate dialog if the next service is a default estimate submission.
    if (submissionType === submissionTypes.ESTIMATE && estimate.defaultComparison) {
      dispatch(openSubmitDialog(nextServiceType, uploadBillTypes.NONE, uploadSubTypes.NONE));
    }

    const serviceCardLoading = userBillsLoaded ? serviceCardLoadingTypes.NONE : serviceCardLoadingTypes.DEFAULT;

    if (accessKey) {
      dispatch(
        getComparisonWithKey(
          { key: accessKey.accessKey, id: accessKey.accessId },
          null,
          submissionType,
          nextServiceType,
          serviceCardLoading
        )
      );
    } else if (isUserAuthenticated()) {
      const fn = async () => {
        dispatch(getComparisonWithToken(await getAccessToken(), submissionId, submissionType, serviceType));
      };
      fn();
    } else {
      dispatch(sessionExpiredServerMessage(serverMessageTargets.HOME));
      goHome();
    }
  };

  const onSelectOffer = useCallback(
    _debounce(
      async (offer, submissionId) => {
        let accessToken = isUserAuthenticated() ? await getAccessToken() : null;

        if (comparison.accessKey || isUserAuthenticated()) {
          dispatch(
            setAcceptedOffer(
              sessionActivity,
              serviceType,
              comparison,
              offer,
              confirmDetail,
              accessToken,
              submissionId,
              onComplete
            )
          );
        } else {
          dispatch(sessionExpiredServerMessage(target));
        }

        offerEvents('accept-offer', offer, googleAnalyticsClientCode);
      },
      2000,
      { leading: true }
    ),
    [confirmDetail, comparison]
  );

  const onTimeoutClosed = () => {
    const accessToken = isUserAuthenticated() ? getAccessToken : null;
    dispatch(onComparisonTimeout(serviceCardLoading, accessToken, comparison));
  };

  const onSwitchedClosed = result => {
    if (!result) {
      setSwitchedDialogOpen(false);
      dispatch(goDashboard());

      if (pageMode === pageModes.FRAMED) {
        standardEvents('energy-estimate-wizard', 'click', 'switched-dialog-closed', googleAnalyticsClientCode);
        dispatch(resetFramePartner(restartSources.SWITCH));
        postFrameScrollTopMessage(frameHost);
      }
    } else {
      const nextUserBill = getNextUserBill();

      if (nextUserBill) {
        getComparison(nextUserBill);
      }
    }
  };

  const getSwitchComponent = () => {
    const useEnergyUx2 =
      uxSwitchVersion === 2 && (serviceType === billTypes.ELECTRICITY || serviceType === billTypes.GAS);
    const useInternetUx2 = uxSwitchInternetVersion === 2 && serviceType === billTypes.INTERNET;

    if (useEnergyUx2 || useInternetUx2) {
      return (
        <OfferAccept
          serviceType={serviceType}
          offer={selectedOffer}
          onSubmit={onSelectOffer}
          primaryButtonText={switchButtonText}
          isDirectSwitch={isDirectSwitch}
        />
      );
    }

    return (
      <Offer
        mode={0}
        offer={selectedOffer}
        counter={1}
        onSelectOffer={onSelectOffer}
        moreInfoOpen={true}
        allowToggle={false}
        moreInfoOpenText="Back"
        moreInfoCloseText="Back"
        primaryButtonText={switchButtonText}
        onMoreInfo={onBack}
        allowOpenCloseInfo={true}
        emailAddress={user.emailAddress}
        initialDelay={1}
        isDirectSwitch={isDirectSwitch}
      />
    );
  };

  return (
    <>
      <div className="sm-offer-accept">{getSwitchComponent()}</div>

      <IdleTimerDialog timeout={3600000} closeButtonText="Start again" onClose={onTimeoutClosed}>
        <IdleOfferTimeout message="Your session has expired. As plans can change and you have been idle for more than 1 hour, we need to refresh your comparison." />
      </IdleTimerDialog>

      <GeneralDialog
        key="switchedDialog"
        dialogSize="md"
        closeButtonText={switchedDialogCloseButtonText}
        confirmButtonText={switchedDialogConfirmButtonText}
        bodyClass="no-padding"
        confirmButtonClass="sm-button-primary"
        dialogOpen={switchedDialogOpen}
        onClose={onSwitchedClosed}
        showPoweredBy={showPoweredBy}
      >
        <SwitchedDialogContent serviceType={serviceType} getOtherServices={getOtherServices} />
      </GeneralDialog>
    </>
  );
};

export default OfferSwitch;
