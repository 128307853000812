import * as types from '../actions/actions';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function registrationReducer(state = initialState.registration, action) {
  switch (action.type) {
    case types.SET_REGISTRATION:
      return objectAssign({}, state, action.registration);

    default:
      return state;
  }
}
