import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import { setDirectSwitch } from '../../actions/directSwitchActions';
import GeneralDialog from './generalDialog';
import { directSwitchSessionStatus } from '../../enums/directSwitchSessionStatus';

const DirectSwitchErrorDialog = ({ onOpen, onClose }) => {
  const dispatch = useDispatch();

  const { directSwitch, comparison } = useSelector(state => state);

  const providerName = directSwitch.providerName;
  const postcode =
    comparison && comparison.submission && comparison.submission.propertyAddress
      ? comparison.submission.propertyAddress.postcode
      : null;

  const [errorField, setErrorField] = useState();
  const [directSwitchErrorDialogOpen, setDirectSwitchErrorDialogOpen] = useState(false);

  const getErrorContent = () => {
    if (errorField) {
      // If a comparsion has not been loaded don't use error fields that came back on server.
      // This could occur if a comparison was attempted, but failed on the server.
      // e.g. invalid postcode was requested in direct switch request.
      const error = comparison.isLoaded === true ? errorField : '';

      switch (error) {
        // Comparison can be attempted with these errors:
        case 'BadTag':
        case 'NotFound':
        case 'MismatchState':
        case 'MismatchService':
          return (
            <>
              <div className="heading">
                <div>Plan no longer available</div>
              </div>
              <div className="description">
                <p>Unfortunately we're unable to assist with this {providerName} application.</p>
                <p>
                  Please select from one of these alternative products{postcode ? ` for postcode ${postcode}` : null}.
                </p>
              </div>
            </>
          );

        default:
          // Comparison cannot be completed with these errors: [ 'InvalidAgentMember', 'InvalidService', 'InvalidRegion' ]
          return (
            <>
              <div className="heading">
                <div>This {providerName} plan may have been recently updated.</div>
              </div>
              <div className="description">
                <p>Please enter your property address to update to the latest plan information.</p>
                <p>If the plan is no longer available we'll confirm alternative plans for your address.</p>
              </div>
            </>
          );
      }
    }
  };

  // ----------------------------------------------------------------------------
  // Handle direct-switch request error.
  // ----------------------------------------------------------------------------
  useEffect(() => {
    const hasError = directSwitch.errorMessage && directSwitch.sessionStatus === directSwitchSessionStatus.ERRORED;

    if (hasError) {
      setErrorField(directSwitch.errorMessage.field || 'Unknown error'); // Default error field if not supplied by server so we can show the error dialog content

      // Set session status to COMPLETED_WITH_ERROR.
      const model = {
        ...directSwitch,
        sessionStatus: directSwitchSessionStatus.COMPLETED_WITH_ERROR
      };

      dispatch(setDirectSwitch(model));

      setDirectSwitchErrorDialogOpen(true);

      if (onOpen) {
        onOpen();
      }
    }
  }, []);

  const onDialogClose = () => {
    setDirectSwitchErrorDialogOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <GeneralDialog
        key="directSwitch"
        dialogSize="md"
        showCloseIcon={false}
        dialogOpen={directSwitchErrorDialogOpen}
        onClose={onDialogClose}
      >
        <div className="sm-direct-switch-error-dialog">
          {getErrorContent()}

          <div className="dialog-button">
            <Button className="sm-button-secondary medium" onClick={onDialogClose}>
              Continue
            </Button>
          </div>
        </div>
      </GeneralDialog>
    </>
  );
};

export default DirectSwitchErrorDialog;
