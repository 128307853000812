export const sessionActivityTypes = {
  NONE: 0,
  HOME: 1,
  LANDING: 2,
  LAUNCH: 3,
  DIRECT_SWITCH: 4,
  CONVERSION: 5,
  AGENT_MEMBER_CHANGED: 6,
  AFFILIATE_REFERRAL: 7,
  EXIT_EXTERNAL: 8,
  COMPARISON_COMPLETED: 9,
  PARTNER_COMPARISON: 10,
  PARTNER_IFRAME: 11,
  ESTIMATE_WIZARD: 12,
  OFFER_SELECTED: 13,
  OFFER_ACCEPT_NEXT: 14,
  OFFER_ACCEPT_QUICK_TIP: 15,
  OFFER_ACCEPT_SUBMITTED: 16,
  SOLAR_FINANCE_WIZARD_INTRODUCTON: 17,
  SOLAR_FINANCE_WIZARD_HOME_LOAN: 18,
  SOLAR_FINANCE_WIZARD_GREEN_LOAN: 19,
  SOLAR_FINANCE_WIZARD_ESTIMATED_COST: 20,
  SOLAR_FINANCE_WIZARD_COMPLETE: 21,
  SOLAR_FINANCE_WIZARD_READY: 22,
  SOLAR_FINANCE_WIZARD_NOT_READY: 23,
  SOLAR_FINANCE_WIZARD_WAITING_FOR_QUOTES: 24,
  SOLAR_FINANCE_WIZARD_INSTALLER_ORGANISING: 25
};
