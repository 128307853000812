import * as types from './actions';

export function setGlobalOptionsSuccess(data) {
  return { type: types.SET_GLOBAL_OPTIONS, data };
}

export function setGlobalOptions(options) {
  return dispatch => {
    dispatch(setGlobalOptionsSuccess({ ...options }));
  };
}

// --------------------------------------------------------------------------
// Concierge
// --------------------------------------------------------------------------
export function conciergePreferencesSelectMeterId(meterId) {
  return dispatch => {
    dispatch(setGlobalOptions({ concierge: { selectMeterId: meterId } }));
  };
}

// --------------------------------------------------------------------------
// ChatBot
// --------------------------------------------------------------------------
export function chatBotOptions(visible, pinned) {
  return dispatch => {
    dispatch(setGlobalOptions({ chatBot: { isOpen: visible, isPinned: pinned } }));
  };
}

// --------------------------------------------------------------------------
// Navigation Options
// --------------------------------------------------------------------------
export function navigationOptions(navigationType) {
  return dispatch => {
    dispatch(
      setGlobalOptions({
        navigation: {
          event: navigationType
        }
      })
    );
  };
}
