import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '../../auth/auth0-Provider.js';
import { Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import CustomerCommissionLogo from '../common/customerCommissionLogo';
import AgreeTerms from '../common/agreeTerms';
import QsCheckBox from '../common/qsCheckBox';
import ServerMessage from '../common/serverMessage';
import YourDetails from '../common/yourDetails';
import SubmitAgentMember from '../common/submitAgentMember.js';

import { getSessionActivity, popUp } from '../../utilities/commonUtilities';
import { setServerMessageClear, displayInfoMessage } from '../../actions/serverMessageActions';
import {
  submitAffiliateReferralUnauthenticated,
  submitAffiliateReferralWithKey,
  submitAffiliateReferralWithToken
} from '../../actions/comparisonActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';

const AffiliateReferral = ({ agentServiceType, onClose }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { serverMessage, selectedAgent, session } = useSelector(state => state);
  const { emailAddress } = useSelector(state => state.user);
  const { mobilePhoneNumberRegex } = useSelector(state => state.config.applicationSettings.country);
  const { hasAgents } = useSelector(state => state.config);
  const { comparison } = useSelector(state => state.landing);

  const { getAccessToken, getAccessKey, isUserAuthenticated } = useAuth0();

  const [termsAndConditions, setTermsAndConditions] = useState(comparison.termsAndConditionsAccepted === true);
  const [agentMemberName, setAgentMemberName] = useState(selectedAgent.name ? selectedAgent.name : '');
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const affiliate = agentServiceType.affiliate;
  const affiliateLogo = affiliate ? affiliate.affiliateLogo : null;
  const customerCommission = agentServiceType.agentBillType.customerCommission;
  const commissionType = agentServiceType.commissionType;

  const affiliateSettings = agentServiceType.affiliateSettings;

  const [selectedCollectCustomerDetails, setSelectedCollectCustomerDetails] = useState(
    affiliateSettings.collectCustomerDetailsDefault === true || comparison.ccd === true
  );
  const [yourDetails, setYourDetails] = useState({
    firstName: comparison.firstName,
    surname: comparison.surname,
    phoneNumber: comparison.mobilePhoneNumber,
    emailAddress
  });

  const target = serverMessageTargets.AFFILIATE_COMPARISON;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setServerMessageClear(target, serverMessage));
  }, []);

  const onComplete = data => {
    const exitUrl = data.exitUrl;

    if (!popUp(exitUrl)) {
      dispatch(
        displayInfoMessage(
          target,
          `Please press the "${affiliate.exitButtonText}" button again. If the problem persists, please unblock pop-ups in your browser to continue.`
        )
      );
    } else {
      onClose();
    }
  };

  const viewOffers = async () => {
    dispatch(setServerMessageClear(target, serverMessage));

    const sessionActivity = getSessionActivity(session);

    const model = {
      agentMemberExternalId: selectedAgent ? selectedAgent.code : null,
      serviceType: agentServiceType.serviceType.Id,
      sessionActivity,
      emailAddress: yourDetails.emailAddress ? yourDetails.emailAddress : emailAddress,
      termsAndConditions,
      firstName: selectedCollectCustomerDetails ? yourDetails.firstName : null,
      surname: selectedCollectCustomerDetails ? yourDetails.surname : null,
      mobilePhoneNumber: selectedCollectCustomerDetails ? yourDetails.phoneNumber : null,
      collectCustomerDetails: selectedCollectCustomerDetails
    };

    const accessKey = getAccessKey();
    const serviceType = agentServiceType.serviceType;

    // Prioritize accessKey first as even an authenticated user may have come in on a link, possibly for a different customer.
    if (accessKey) {
      dispatch(submitAffiliateReferralWithKey(accessKey, model, serviceType, onComplete));
    } else if (isUserAuthenticated()) {
      dispatch(submitAffiliateReferralWithToken(await getAccessToken(), model, serviceType, onComplete));
    } else {
      dispatch(submitAffiliateReferralUnauthenticated(model, serviceType, onComplete));
    }
  };

  const onYourDetailsChange = (e, data) => {
    setYourDetails({ ...yourDetails, ...data });
  };

  // --------------------------------------------------------------------------
  // Invalid form submit handler.
  // --------------------------------------------------------------------------
  const handleInvalidSubmit = () => {
    setHasSubmitted(true);
  };

  return (
    <div className="sm-service-card-external">
      <ServerMessage serverMessage={serverMessage} target={target} />

      <div className={affiliateSettings.additionalLogo ? 'sm-image-grid' : ''}>
        {affiliateLogo && (
          <div className="logo">
            <img alt="Logo" src={`${staticContentUrl}${affiliateLogo}`} />
          </div>
        )}

        {affiliateSettings.additionalLogo && (
          <div className="logo">
            <img alt="Logo" src={`${staticContentUrl}${affiliateSettings.additionalLogo}`} />
          </div>
        )}
      </div>

      {affiliate && (
        <div className="description">
          <div>
            {affiliateSettings.leadin && <p>{affiliateSettings.leadin}</p>}
            {affiliate.exitDescription}
            {agentServiceType.hasCommission && (
              <>
                {' '}
                and {commissionType.Leadin.toLowerCase()}{' '}
                <b>
                  {commissionType.Denomination}
                  {customerCommission.toLocaleString()}
                </b>{' '}
                {commissionType.Name}
              </>
            )}
            .
          </div>
        </div>
      )}

      <CustomerCommissionLogo serviceType={agentServiceType.serviceType} />

      {affiliateSettings.introduction && (
        <div className="sm-affiliate-introduction">{affiliateSettings.introduction}</div>
      )}

      <hr className="summary-divider" />

      <AvForm onValidSubmit={viewOffers} onInvalidSubmit={handleInvalidSubmit}>
        {affiliateSettings.collectCustomerDetails && affiliateSettings.collectCustomerDetails !== 'None' && (
          <div className="terms" style={{ marginBottom: '10px' }}>
            <QsCheckBox
              isChecked={selectedCollectCustomerDetails}
              onCheck={() => setSelectedCollectCustomerDetails(!selectedCollectCustomerDetails)}
              label={affiliateSettings.collectCustomerDetailsDescription}
            />
          </div>
        )}

        {selectedCollectCustomerDetails && (
          <>
            <div style={{ marginTop: '10px', textAlign: 'left' }}>
              <YourDetails
                onChange={onYourDetailsChange}
                details={yourDetails}
                phoneNumberRegex={mobilePhoneNumberRegex}
                emailAddress={emailAddress}
                showMembershipId={false}
                showAlternatePhoneNumber={false}
                showDateOfBirth={false}
                showTitle={false}
                showEmailAddress={affiliateSettings.collectCustomerDetails === 'All'}
                showPhoneNumber={affiliateSettings.collectCustomerDetails === 'All'}
                lockPhoneNumber={false}
              />
            </div>
          </>
        )}

        {!selectedAgent.isLoaded && hasAgents && (
          <SubmitAgentMember
            style={{ marginTop: '20px' }}
            hasSubmitted={hasSubmitted}
            agentMemberName={agentMemberName}
            onChange={value => setAgentMemberName(value)}
          />
        )}

        <div className="terms">
          <QsCheckBox
            isChecked={termsAndConditions}
            onCheck={() => setTermsAndConditions(!termsAndConditions)}
            label={<AgreeTerms />}
            validatorName="affiliate-referral-terms"
            validationMessage="Please accept the terms and conditions"
          />
        </div>
        <div className="button-container">
          <Button className="sm-button-primary medium right-arrow-small">{affiliate.exitButtonText}</Button>
        </div>
      </AvForm>
    </div>
  );
};

export default AffiliateReferral;
