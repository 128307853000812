export const propertyAddressTypes = {
  NONE: { id: 0 },
  ADDRESS: { id: 1, name: 'Address' },
  POSTCODE: { id: 2, name: 'Postcode' },
  getById: id => {
    switch (id) {
      case propertyAddressTypes.ADDRESS.id:
        return propertyAddressTypes.ADDRESS;
      case propertyAddressTypes.POSTCODE.id:
        return propertyAddressTypes.POSTCODE;
      default:
        return propertyAddressTypes.NONE;
    }
  }
};
