import React from 'react';
import { Helmet } from 'react-helmet';
import { useAuth0 } from '../../auth/auth0-Provider.js';
import qs from 'query-string';

const Authentication = props => {
  const { loginWithRedirect } = useAuth0();

  var mobilePhoneNumber = qs.parse(props.location.search).login;

  loginWithRedirect({ mobilePhoneNumber });

  return (
    <>
      <Helmet>
        <title>Login...</title>
      </Helmet>
      <div>
        <h1 className="d-none d-sm-block">Redirecting to login</h1>
        <h2 className="qs-loading-heading">Please wait...</h2>
      </div>
    </>
  );
};

export default Authentication;
