import { billTypes } from '../enums/billTypes';
import { navigationTypes } from '../enums/navigationTypes';
import { directSwitchSessionStatus } from '../enums/directSwitchSessionStatus';
import { pageModes, launchDialogModes, operatorModes } from '../enums/launchTypes';
import { directSwitchTypes, occupancyPurposes } from '../enums/submissionTypes';
import { propertyAddressTypes } from '../enums/propertyAddressTypes';
import { partnerFrameTypes, restartSources } from '../enums/partnerFrameTypes';
import { uploadBillTypes, uploadSubTypes } from '../enums/uploadBillTypes';

export default {
  application: {
    googleLoaded: false,
    pageMode: pageModes.NONE,
    debug: false,
    frameHost: null,
    frameDialog: false
  },
  session: {},
  ajaxCall: {
    count: 0,
    context: null
  },
  serverMessage: {
    code: null,
    messages: [],
    scrollTo: false
  },
  comparison: {
    submissionId: '',
    implementationCode: null,
    isLoaded: false,
    accessId: null,
    accessKey: null,
    accessTrustLevel: 0,
    termsAndConditionsAccepted: false,
    sendComparisonEmail: true,
    sendArticlesEmail: false,
    sendSolarInstallationEmail: false,
    submission: {},
    offers: []
  },
  registration: {
    initComplete: false,
    registrationComplete: false,
    registrationPresent: false,
    registrationLinkError: false,
    emailAddress: null,
    autoCheckEmailAddress: null,
    mobilePhoneNumber: null
  },
  user: {
    loaded: false,
    accessId: null,
    accessKey: null,
    title: null,
    firstName: null,
    surname: null,
    emailAddress: null,
    autoCheckEmailAddress: null,
    mobilePhoneNumber: null,
    externalUserId: null,
    preferences: {
      communication: {},
      concierge: {}
    }
  },
  userBills: {
    loaded: false,
    data: []
  },
  selectedOffer: {
    isAccepted: false,
    requireOtp: false,
    supplyCharges: [],
    usageCharges: []
  },
  confirmDetail: {
    entryStarted: false
  },
  config: {
    isLoaded: false,
    code: '',
    receiveBillEmailAddress: '',
    referenceData: {
      titles: [],
      idTypes: [],
      concessionTypes: []
    },
    billTypes: [],
    panelProviders: [],
    applicationSettings: {
      country: {}
    },
    settings: {}
  },
  content: {
    privacyPolicy: {
      title: '',
      versionNumber: 0,
      document: '',
      loading: false,
      code: ''
    },
    termsAndConditions: {
      title: '',
      versionNumber: 0,
      document: '',
      loading: false,
      code: ''
    },
    conciergeTermsAndConditions: {
      title: '',
      versionNumber: 0,
      document: '',
      loading: false,
      code: ''
    },
    general: {},
    home: {},
    start: {},
    cssVariables: {},
    images: {}
  },
  selectedAgent: {
    isLoaded: false
  },
  landing: {
    comparison: {
      openMoreInfo: false
    },
    home: {
      launchDialogMode: launchDialogModes.DEFAULT,
      operatorMode: operatorModes.NONE
    }
  },
  directSwitch: {
    directSwitchType: directSwitchTypes.NONE,
    sessionStatus: directSwitchSessionStatus.NONE,
    selectOfferId: null,
    product: null,
    serviceType: billTypes.NONE,
    providerCode: null,
    providerName: null,
    productName: null,
    occupancyPurpose: occupancyPurposes.NONE,
    errorMessage: null
  },
  dialogOptions: {
    closeSource: null,
    idleTimer: {
      isOpen: false
    },
    howItWorks: {
      isOpen: false
    },
    getStarted: {
      isOpen: false
    },
    directSwitch: {
      isOpen: false
    },
    switched: {
      isOpen: false
    },
    submit: {
      isOpen: false,
      serviceType: billTypes.NONE,
      uploadBillType: uploadBillTypes.NONE,
      uploadSubType: uploadSubTypes.NONE,
      data: null
    }
  },
  globalOptions: {
    concierge: {
      selectMeterId: null
    },
    navigation: {
      event: navigationTypes.NONE
    },
    chatBot: {
      isOpen: false,
      isPinned: false
    }
  },
  framePartner: {
    skipIntroduction: false,
    serviceType: billTypes.NONE,
    occupancyPurpose: occupancyPurposes.NONE,
    postcodeInitial: null,
    postcodeOfferMax: null,
    propertyAddressType: propertyAddressTypes.NONE,
    frameType: partnerFrameTypes.NONE,
    restartSource: restartSources.NONE
  },
  signalr: {
    connectionStatus: null,
    connectionId: null
  },
  articles: {
    isLoaded: false,
    items: []
  },
  survey: {
    isLoaded: false
  }
};
