import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import OutboundLink from './outboundLink';
import { setMoreInfo } from '../../actions/selectedOfferActions';
import { billTypes } from '../../enums/billTypes';

const OfferMoreInfoInternetAu = ({ offer, comparison, preload }) => {
  const dispatch = useDispatch();

  const moreInfoHeading = `${offer.providerDisplayCode} - ${offer.name}`;

  const [moreInfo, setMoreInfoData] = useState(offer.moreInfo);

  useEffect(() => {
    if (preload && !offer.moreInfo) {
      dispatch(
        setMoreInfo(billTypes.INTERNET, comparison, offer, null, null, data => {
          setMoreInfoData(data);
        })
      );
    } else {
      setMoreInfoData(offer.moreInfo);
    }
  }, [offer.moreInfo]);

  return (
    <Container>
      <Row>
        <Col xs="12">
          <h2 className="qs-more-info-heading" dangerouslySetInnerHTML={{ __html: moreInfoHeading }} />
        </Col>
      </Row>

      <Row noGutters={true}>
        <Col xs="12">
          <div className="qs-more-info-sub-heading">Plan information</div>
          <table className="qs-offer-info-table">
            <tbody>
              <tr>
                <td>Contract length</td>
                <td>{offer.contract}</td>
              </tr>
              <tr>
                <td>Technology</td>
                <td>{offer.technology}</td>
              </tr>
              <tr>
                <td>Connection type</td>
                <td>{offer.connectionType}</td>
              </tr>
              {offer.downloadSpeed && (
                <tr>
                  <td>Download speed</td>
                  <td>{offer.downloadSpeed} mbps</td>
                </tr>
              )}
              {offer.uploadSpeed && (
                <tr>
                  <td>Upload speed</td>
                  <td>{offer.uploadSpeed} mbps</td>
                </tr>
              )}
              {offer.satelliteInclusion && offer.satelliteInclusion !== 'N/A' && (
                <tr>
                  <td>Satellite inclusion</td>
                  <td dangerouslySetInnerHTML={{ __html: offer.satelliteInclusion }} />
                </tr>
              )}
              <tr>
                <td>Speed description</td>
                <td dangerouslySetInnerHTML={{ __html: offer.speedDescription }} />
              </tr>
              <tr>
                <td>Data limit</td>
                <td>{offer.dataLimit}</td>
              </tr>
              <tr>
                <td>Typical peak time download speeds</td>
                <td>{offer.typicalPeakDownloadSpeedDisplay}</td>
              </tr>
              {moreInfo && (
                <tr>
                  <td>Additional plan information</td>
                  <td dangerouslySetInnerHTML={{ __html: moreInfo.additionalPlanInformation }} />
                </tr>
              )}
            </tbody>
          </table>

          <div className="qs-more-info-sub-heading">Data</div>
          <table className="qs-offer-info-table">
            <tbody>
              <tr>
                <td>Total data allowance</td>
                <td>{offer.dataLimit}</td>
              </tr>
              {moreInfo && (
                <>
                  <tr>
                    <td>Off peak data</td>
                    <td>{moreInfo.dataOffPeak}</td>
                  </tr>
                  <tr>
                    <td>Peak data</td>
                    <td>{moreInfo.dataPeak}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>

          <div className="qs-more-info-important-info">
            {moreInfo && moreInfo.fees && moreInfo.fees.length > 0 && (
              <table className="qs-fees-table">
                <thead>
                  <tr>
                    <th className="qs-fees-table-fees">Fees</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {moreInfo.fees.map((fee, index) => (
                    <tr key={`fee-${index}`}>
                      <td>{fee.title}</td>
                      <td dangerouslySetInnerHTML={{ __html: fee.description }} />
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {moreInfo && moreInfo.includedModem && (
              <>
                <div className="qs-more-info-sub-heading">Included modem</div>
                <table className="qs-offer-info-table">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{moreInfo.includedModem.name}</td>
                    </tr>
                    <tr>
                      <td>{moreInfo.includedModem.fee.title}</td>
                      <td>{moreInfo.includedModem.fee.description}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td dangerouslySetInnerHTML={{ __html: moreInfo.includedModem.description }} />
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            {moreInfo && moreInfo.includedHomeCallingPlan && (
              <>
                <div className="qs-more-info-sub-heading">Included home calling plan</div>
                <table className="qs-offer-info-table qs-mb-lg">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{moreInfo.includedHomeCallingPlan.name}</td>
                    </tr>
                    <tr>
                      <td>{moreInfo.includedHomeCallingPlan.fee.title}</td>
                      <td>{moreInfo.includedHomeCallingPlan.fee.description}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td dangerouslySetInnerHTML={{ __html: moreInfo.includedHomeCallingPlan.description }} />
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            {moreInfo && moreInfo.includedOtherAddon && (
              <>
                <div className="qs-more-info-sub-heading">Included other addon</div>
                <table className="qs-offer-info-table">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{moreInfo.includedOtherAddon.name}</td>
                    </tr>
                    <tr>
                      <td>{moreInfo.includedOtherAddon.fee.title}</td>
                      <td>{moreInfo.includedOtherAddon.fee.description}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td dangerouslySetInnerHTML={{ __html: moreInfo.includedOtherAddon.description }} />
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            {moreInfo && moreInfo.criticalInformationSummary && (
              <div className="qs-mt-md qs-mb-lg">
                <OutboundLink
                  eventLabel={`critical-info-summary-${offer.name}`}
                  to={moreInfo.criticalInformationSummary.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {moreInfo.criticalInformationSummary.name}
                </OutboundLink>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

OfferMoreInfoInternetAu.propTypes = {
  offer: PropTypes.object.isRequired,
  comparison: PropTypes.object.isRequired
};

export default OfferMoreInfoInternetAu;
