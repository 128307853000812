import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '../../auth/auth0-Provider.js';
import { Label, Input, Button, Progress } from 'reactstrap';
import { deleteUserBillSuccess } from '../../actions/comparisonActions';
import { getComparisonWithToken, getComparisonWithKey } from '../../actions/comparisonActions';
import { billingTypes, occupancyTypes, submissionStatuses } from '../../enums/submissionTypes';
import {
  viewBillWithKey,
  deleteOrRestoreServiceCardWithKey,
  deleteOrRestoreServiceCardWithToken,
  getReductionAmountWithToken,
  getReductionAmountWithKey,
  viewBillWithToken
} from '../../actions/serviceCardActions';

import { sessionExpiredServerMessage } from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';

import { billTypes } from '../../enums/billTypes';
import { serviceCardLoadingTypes } from '../../enums/serviceCardLoadingTypes';
import { uploadBillTypes, uploadSubTypes } from '../../enums/uploadBillTypes';
import { implementationCodes } from '../../enums/implementationCodes.js';
import { getAgentServiceType } from '../../enums/customerCommissionTypes';

import ServiceCardContent from './serviceCardContent';
import ServiceCardHead from './serviceCardHead';
import OfferMarketingDisplay from '../common/offerMarketingDisplay';
import CustomerCommissionLogo from '../common/customerCommissionLogo';

import { ReactComponent as TickCircle } from '../icons/util/service-card-tick-circle.svg';
import { ReactComponent as House } from '../icons/util/service-card-house.svg';
import { ReactComponent as Location } from '../icons/util/service-card-location.svg';
import { ReactComponent as Cog } from '../icons/util/service-card-cog.svg';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { determineUploadBillType } from './serviceCardUtilities';
import moment from 'moment';

const ServiceCard = props => {
  const {
    implementationCode,
    id,
    serviceType,
    providerDisplayCode,
    convertedProviderDisplayCode,
    address,
    isLinked,
    isAwaitingLink,
    nextCompareDate,
    convertedDateUtc,
    status,
    billingType,
    isSample,
    isEmpty,
    isAdd,
    isBill,
    isEstimate,
    isDefaultComparison,
    submission,
    buttonText,
    notificationCount,
    billDays,
    billAmountCurrent,
    reductionLoaded,
    bestOffer,
    reductionPercentage,
    reductionAmountAnnual,
    reductionExpiry,
    submissionId,
    submissionType,
    accessKey,
    errorMessages,
    providerLogo,
    percentagePeakDisplay,
    percentageOffPeakDisplay
  } = props.service;

  const { openDialog } = props;

  const dispatch = useDispatch();
  const { getAccessToken, isUserAuthenticated } = useAuth0();

  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { billTypes: agentBillTypes, country } = useSelector(state => state.config);
  const { googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { loaded: userBillsLoaded } = useSelector(state => state.userBills);
  const { taxIndicator } = useSelector(state => state.content.general);

  const buttonClassName = 'sm-button-primary right-arrow';
  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);

  const [selectedServiceType, setSelectedServiceType] = useState(serviceType);
  const [selectedAgentServiceType, setSelectedAgentServiceType] = useState(agentServiceType);
  const [selectedImplementationCode, setSelectedImplementationCode] = useState(implementationCode);
  const [selectedService, setSelectedService] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const isHomeLoan = serviceType === billTypes.HOME_LOAN;
  const isInternet = serviceType === billTypes.INTERNET;
  const isSolarFinance = serviceType === billTypes.SOLAR_FINANCE;
  const hasCommission = selectedAgentServiceType.hasCommission;
  const affiliate = selectedAgentServiceType ? selectedAgentServiceType.affiliate : null;
  const partner = selectedAgentServiceType ? selectedAgentServiceType.partner : null;

  const getUploadBillType = () => {
    if (!serviceType.ComparisonImplemented) return uploadBillTypes.NONE;
    if (isBill) return uploadBillTypes.WEBSITE;
    if (isEstimate) return uploadBillTypes.MANUAL;

    return uploadBillTypes.NONE;
  };

  const getUploadSubType = () => {
    if (isAdd || isEmpty) return null;

    return uploadSubTypes.getSubType(serviceType, submission);
  };

  // Treat converted user bills as switched if they have a billingType of anything but auto-check-email, i.e. 'organiser'
  const switched =
    status === submissionStatuses.CONVERTED.Id &&
    billingType !== billingTypes.NONE.Id &&
    billingType !== billingTypes.EMAIL_ORGANISER.Id;

  const userCardButtonDisabled = errorMessages || isAwaitingLink ? true : false;
  const systemCardButtonDisabled = selectedServiceType.Id === 0 || isSample;

  const isLoading = !isAdd && !isEmpty && !isAwaitingLink && !reductionLoaded;

  const loadingTimer = isLoading
    ? setTimeout(() => {
        if (loadingProgress < 95) {
          setLoadingProgress(loadingProgress + 1);
        }
      }, 200)
    : null;

  useEffect(() => {
    if (!isLoading) {
      clearTimeout(loadingTimer);
    }
  }, [isAwaitingLink || reductionLoaded]);

  const getStatusCode = () => {
    if (isEmpty) return 'empty';
    if (isAdd) return 'add';
    if (isAwaitingLink) return 'awaiting-link';
    if (isLinked) return 'linked'; // prioritise linked over switched as customer could supply their autocheck email to a provider, after initially switching with their personal email address.
    if (switched) return 'switched';

    if (!reductionLoaded) return null;

    return 'unlinked';
  };

  const getStatusIcon = () => {
    if (isEmpty) return hasCommission ? null : <House className="icon" height="35" />;
    if (isAdd) return <House className="icon" height="35" />;
    if (isAwaitingLink) return null;
    if (nextCompareDate) return null;

    if (!reductionLoaded) return null;
    if (isHomeLoan && (!submission || !submission.propertyAddress || !submission.propertyAddress.suburbShort))
      return null;
    if (isEstimate && reductionAmountAnnual <= 0) return <Location className="icon" height="40" />;
    if (isHomeLoan) return null;
    if (errorMessages) return null;
    if (reductionAmountAnnual <= 0) return <TickCircle className="icon" height="35" />;

    return null;
  };

  const getStatusLeadText = () => {
    if (isEmpty) return { text: hasCommission ? null : "We'll check the market..." };
    if (isAdd) return { text: 'Use this card to add a service for another address' };
    if (isAwaitingLink) return { text: 'Awaiting first bill' };
    if (switched && isSolarFinance) return { text: 'Lodged' };
    if (switched) return { text: 'Switched' };
    if (nextCompareDate) return { text: 'Next market check due' };

    if (!reductionLoaded && isBill) return { text: `Comparing your ${providerDisplayCode} bill with latest offers...` };
    if (!reductionLoaded && isHomeLoan)
      return {
        text: `Checking the market for latest home loan offers${
          submission && submission.propertyAddress && submission.propertyAddress.suburbShort
            ? ` in ${submission.propertyAddress.suburbShort}`
            : ''
        }`
      };
    if (!reductionLoaded && isEstimate) return { text: `Checking the market for latest offers in ${address}` };

    if (isEstimate && reductionAmountAnnual <= 0) {
      return {
        text: bestOffer ? (address ? 'This is our best deal for' : 'This is our best deal') : 'No deals found for'
      };
    }

    if (reductionAmountAnnual <= 0) return { text: "You're organised" };
    if (reductionAmountAnnual > 0) return { text: 'Annual saving', disclaimerSymbol: '†' };

    if (errorMessages && errorMessages[0].field === 'ComparisonNotImplemented') return { text: 'Coming soon...' };
    if (errorMessages && isBill) return { text: 'Error retrieving your bill' };
    if (errorMessages && isEstimate) return { text: 'Error retrieving your estimate' };

    return null;
  };

  const getStatusTagline = () => {
    if (isEmpty)
      return hasCommission
        ? { text: null }
        : {
            text: 'Confirmed savings will be displayed on this card and added to your total annual savings.'
          };

    if (isAdd) return { text: null };

    if (isAwaitingLink || switched) {
      const convertedUtc = moment.utc(convertedDateUtc);
      const convertedDate = moment(convertedUtc.toDate())
        .local()
        .format('LL');

      if (isSolarFinance) return { text: `You lodged your request on ${convertedDate}.` };

      return { text: `You switched to ${convertedProviderDisplayCode} on ${convertedDate}.` };
    }

    if (nextCompareDate) return { text: nextCompareDate };

    if (!reductionLoaded) return { text: null };
    if (isEstimate)
      return {
        text:
          reductionAmountAnnual <= 0 &&
          submission &&
          submission.propertyAddress &&
          submission.propertyAddress.suburbShort
            ? submission.propertyAddress.suburbShort
            : null
      };

    return { text: null };
  };

  const statusLeadText = getStatusLeadText();
  const statusTagline = getStatusTagline();
  const statusCode = getStatusCode(); // To accomodate the styles

  const getComparison = () => {
    const serviceCardLoading = userBillsLoaded ? serviceCardLoadingTypes.NONE : serviceCardLoadingTypes.DEFAULT;

    if (accessKey) {
      dispatch(
        getComparisonWithKey(
          { key: accessKey.accessKey, id: accessKey.accessId },
          null,
          submissionType,
          serviceType,
          serviceCardLoading
        )
      );
    } else if (isUserAuthenticated()) {
      const fn = async () => {
        dispatch(getComparisonWithToken(await getAccessToken(), submissionId, submissionType, serviceType));
      };
      fn();
    } else {
      dispatch(sessionExpiredServerMessage(serverMessageTargets.DASHBOARD));
    }
  };

  const initReductionAmount = async () => {
    // Do not attempt to get a reduction for empty / add cards or cards that are awaiting link.
    if (isEmpty || isAdd || isAwaitingLink || switched) return;

    if (accessKey) {
      dispatch(getReductionAmountWithKey({ key: accessKey.accessKey, id: accessKey.accessId }, submissionId));
    } else if (isUserAuthenticated()) {
      dispatch(getReductionAmountWithToken(await getAccessToken(), submissionId, submissionType));
    } else {
      dispatch(sessionExpiredServerMessage(serverMessageTargets.DASHBOARD));
    }
  };

  useEffect(() => {
    if (!reductionLoaded || !reductionExpiry || reductionExpiry < new Date()) {
      initReductionAmount();
    }
  }, []);

  useEffect(() => {
    if (!reductionLoaded) onServiceRefreshInternal(true);
  }, [reductionLoaded]);

  const hasReduction = statusCode === 'unlinked' && reductionPercentage > 0;

  const getHeadingText = () => {
    if (errorMessages) return null;
    if (isEstimate && status === submissionStatuses.CONVERTED.Id) return null; // converted estimate
    if (isHomeLoan) return 'Monthly Repayment:';
    if (isEstimate || hasReduction) return 'Your possible spend:';

    return 'Your current spend:';
  };

  const getBillAmountDisplay = () => {
    if (errorMessages) return null;
    if (isHomeLoan && bestOffer) return `$${bestOffer.monthlyRepayment.toLocaleString()}`;
    if (isEstimate && status === submissionStatuses.CONVERTED.Id) return null; // converted estimate

    if (isEstimate || isInternet)
      return (
        <OfferMarketingDisplay
          bestOffer={bestOffer}
          submission={submission}
          implementationCode={selectedImplementationCode}
          mode="ServiceCard"
        />
      );

    if (hasReduction) return bestOffer.billAmountToHighlightDisplay;

    return billAmountCurrent;
  };

  const getDisclaimer = () => {
    if (errorMessages) return null;
    if (isHomeLoan) return null;
    if (isEstimate && status === submissionStatuses.CONVERTED.Id) return null; // converted estimate
    if (isEstimate || isInternet) return null; // disclaimer is part of "bill amount display"
    if (hasReduction)
      return `in energy changes (${taxIndicator}) if you'd billed with this plan during the same ${billDays} day period${
        selectedImplementationCode === implementationCodes.GAS.AU_DEFAULT
          ? ` based on an estimated ${percentagePeakDisplay} peak and ${percentageOffPeakDisplay} off-peak usage`
          : ''
      }.`;

    return `in energy changes (${taxIndicator}) over a ${billDays} day period.`;
  };

  const getEmptyHeader = () => {
    const summaryLogo =
      partner && partner.partnerLogo
        ? partner.partnerLogo
        : affiliate && affiliate.affiliateLogo
        ? affiliate.affiliateLogo
        : selectedAgentServiceType.agentBillType.emptySummaryLogo;
    const emptySummaryText = selectedAgentServiceType.agentBillType.emptySummaryText;

    if (summaryLogo || emptySummaryText) {
      return (
        <div className="empty-summary">
          {summaryLogo && <img alt="Summary logo" src={`${staticContentUrl}${summaryLogo}`} />}

          <div className="tagline">{emptySummaryText || serviceType.AddText}</div>
        </div>
      );
    }

    return <div className="heading">{serviceType.AddText}</div>;
  };

  const onServiceChanged = e => {
    const id = parseInt(e.target.value);
    const serviceType = billTypes.getById(id);
    const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
    const implementationCode = implementationCodes.get(country.alpha3, serviceType.Id);

    setSelectedService(e.target.value);
    setSelectedServiceType(serviceType);
    setSelectedAgentServiceType(agentServiceType);
    setSelectedImplementationCode(implementationCode);
  };

  const onDeleteComplete = data => {
    dispatch(
      deleteUserBillSuccess({
        ...data
      })
    );
  };

  const onServiceDelete = () => {
    standardEvents('Service-Card', serviceType.Name, 'Delete', googleAnalyticsClientCode);

    const deleted = true;

    // Determines if the comparison results are displayed and the dialog closes at the end; in this case it doesn't apply - this is a direct delete action.
    const comparisonReady = undefined;

    if (accessKey) {
      const access = { key: accessKey.accessKey, id: accessKey.accessId, trustLevel: accessKey.accessTrustLevel };

      dispatch(deleteOrRestoreServiceCardWithKey(access, deleted, comparisonReady, submissionId, onDeleteComplete));
    } else if (isUserAuthenticated()) {
      const fn = async () => {
        dispatch(
          deleteOrRestoreServiceCardWithToken(
            await getAccessToken(),
            deleted,
            comparisonReady,
            submissionId,
            submissionType,
            onDeleteComplete
          )
        );
      };
      fn();
    } else {
      dispatch(sessionExpiredServerMessage(serverMessageTargets.DASHBOARD));
    }
  };

  const onServiceDetails = () => {
    standardEvents('Service-Card', serviceType.Name, 'Details', googleAnalyticsClientCode);

    const backButtonVisible = false;
    const confirmButtonText = null;

    openDialog(
      selectedServiceType,
      uploadBillTypes.DETAILS,
      getUploadSubType(),
      submissionId,
      submission,
      backButtonVisible,
      confirmButtonText
    );
  };

  const onViewBill = async () => {
    standardEvents('Service-Card', serviceType.Name, 'View-Bill', googleAnalyticsClientCode);

    if (accessKey) {
      dispatch(viewBillWithKey({ key: accessKey.accessKey, id: accessKey.accessId }));
    } else if (isUserAuthenticated()) {
      dispatch(viewBillWithToken(await getAccessToken(), submissionId));
    } else {
      dispatch(sessionExpiredServerMessage(serverMessageTargets.DASHBOARD));
    }
  };

  const onServiceRefreshInternal = () => {
    setLoadingProgress(0);
    initReductionAmount();
  };

  const onServiceRefresh = () => {
    standardEvents('Service-Card', serviceType.Name, 'Refresh', googleAnalyticsClientCode);

    onServiceRefreshInternal();
  };

  const onServiceStart = () => {
    standardEvents('Service-Card', serviceType.Name, 'Start', googleAnalyticsClientCode);

    const submissionId = null;
    const submission = null;
    const backButtonVisible = serviceType !== billTypes.SOLAR_FINANCE;
    const confirmButtonText = serviceType === billTypes.SOLAR_FINANCE ? 'Submit' : 'Next';
    const uploadBillType = determineUploadBillType(partner, affiliate, agentServiceType);

    openDialog(
      selectedServiceType,
      uploadBillType,
      uploadSubTypes.NONE,
      submissionId,
      submission,
      backButtonVisible,
      confirmButtonText
    );
  };

  const onServiceContinue = () => {
    const backButtonVisible = true;
    const confirmButtonText = 'Continue';

    standardEvents('Service-Card', serviceType.Name, 'Continue', googleAnalyticsClientCode);

    let uploadBillType =
      submission && submission.occupancyType === occupancyTypes.NEW.Id ? uploadBillTypes.MANUAL : uploadBillTypes.NONE;

    openDialog(
      selectedServiceType,
      uploadBillType,
      uploadSubTypes.NONE,
      submissionId,
      submission,
      backButtonVisible,
      confirmButtonText
    );
  };

  const onServiceEdit = () => {
    standardEvents('Service-Card', serviceType.Name, 'Edit', googleAnalyticsClientCode);

    const backButtonVisible = false;
    const confirmButtonText = 'Update';

    openDialog(
      selectedServiceType,
      getUploadBillType(),
      getUploadSubType(),
      submissionId,
      submission,
      backButtonVisible,
      confirmButtonText
    );
  };

  const getButtonAction = () => {
    if (isSample) return undefined;
    if (isEmpty || isAdd) return onServiceStart;
    if (isDefaultComparison && !isHomeLoan) return onServiceContinue;
    if (isDefaultComparison && isHomeLoan) return onServiceEdit;

    return getComparison;
  };

  const buttonDisabled = isSample || isEmpty || isAdd ? systemCardButtonDisabled : userCardButtonDisabled;

  const pdfFileMissing = (errorMessages && errorMessages[0].field === 'PdfFileMissing') || !providerLogo;

  return (
    <div
      id={id}
      key={`serviceCard_${submissionId}`}
      className={`service-card status-${statusCode} service-type-${selectedServiceType.Tag}${
        reductionAmountAnnual > 0 ? ' has-saving' : ''
      }${isEstimate && !isInternet ? ' is-estimate' : isBill ? ' is-bill' : ''}`}
    >
      <ServiceCardHead
        title={serviceType.Name}
        serviceType={selectedServiceType}
        pdfFileMissing={pdfFileMissing}
        subtitle={address}
        status={status}
        notificationCount={notificationCount}
        submissionId={submissionId}
        isAdd={isAdd}
        isEmpty={isEmpty}
        isAwaitingLink={isAwaitingLink}
        isSample={isSample}
        isBill={isBill}
        isEstimate={isEstimate}
        reductionLoaded={reductionLoaded}
        onDelete={onServiceDelete}
        onEdit={onServiceEdit}
        onDetails={onServiceDetails}
        onStart={onServiceStart}
        onRefresh={onServiceRefresh}
        onViewBill={onViewBill}
      />
      <div className={`service-card-content ${hasCommission ? ' has-customer-commission' : ''}`}>
        <ServiceCardContent
          {...props.service}
          onStart={onServiceStart}
          isDefaultComparison={isDefaultComparison}
          onBillSheetClick={isSample ? undefined : onViewBill}
        />
      </div>
      <div className="service-card-summary">
        {isAwaitingLink || reductionLoaded || switched ? (
          <>
            <div>
              <div className="heading">{getHeadingText()} </div>
              <div className={`amount${hasReduction ? ' with-reduction' : ''}`}>{getBillAmountDisplay()}</div>
            </div>

            <div className="disclaimer">{getDisclaimer()}</div>
          </>
        ) : isEmpty ? (
          <div className="status-empty">{getEmptyHeader()}</div>
        ) : isAdd ? (
          <div className="status-add">
            <Label for="serviceSelect">Select a service:</Label>
            <Input
              type="select"
              name="serviceSelect"
              className="dropdown"
              onChange={onServiceChanged}
              value={selectedService}
            >
              <option value="0" disabled>
                Select a service
              </option>
              {agentBillTypes.map(
                e =>
                  e.enabled && (
                    <option key={`serviceSelect${e.id}`} value={e.id}>
                      {e.name}
                    </option>
                  )
              )}
            </Input>
          </div>
        ) : (
          <div>
            <Cog className="loading-icon" height="50" width="50" />
            <Progress className="loading-progress" value={loadingProgress} max="100" />
          </div>
        )}

        <Button disabled={buttonDisabled} onClick={getButtonAction()} className={buttonClassName}>
          {buttonText}
        </Button>
      </div>
      <div className="service-card-bottom">
        <div className="icon">{getStatusIcon()}</div>
        <h5 className="lead-heading">
          {statusLeadText.text}
          {statusLeadText.disclaimerSymbol && (
            <span className="disclaimer-symbol">{statusLeadText.disclaimerSymbol}</span>
          )}
        </h5>
        {reductionAmountAnnual > 0 && <div className="savings">${reductionAmountAnnual.toLocaleString()}</div>}
        <div className={`tagline${nextCompareDate && !isAwaitingLink ? ' status-linked' : ''}`}>
          {statusTagline.text}
          {statusTagline.disclaimerSymbol && (
            <span className="disclaimer-symbol">{statusTagline.disclaimerSymbol}</span>
          )}
        </div>

        {isEmpty && hasCommission && <CustomerCommissionLogo serviceType={serviceType} />}
      </div>
    </div>
  );
};

export default ServiceCard;
