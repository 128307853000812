import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AvField } from 'availity-reactstrap-validation';

import { getEmptyPostcodeAddress, hasValidPostcodeAddress } from '../../utilities/addressUtilities';

const PostcodeSelector = ({
  onChange: onChangeParent,
  showAreasSupportedDisclaimer,
  validate,
  propertyAddress,
  className,
  noPlaceholder,
  placeholder
}) => {
  const { postcodeRegex } = useSelector(state => state.config.applicationSettings.country);
  const { areasSupportedDisclaimer, postcodeLabel, postcodeText } = useSelector(state => state.content.general);

  const address = hasValidPostcodeAddress(propertyAddress) ? propertyAddress : getEmptyPostcodeAddress();

  // --------------------------------------------------------------------------
  // Initialize the component.
  // --------------------------------------------------------------------------
  useEffect(() => {
    onChange({
      ...address
    });
  }, []);

  // --------------------------------------------------------------------------
  // Main change handler.
  // --------------------------------------------------------------------------
  const onChange = address => {
    if (onChangeParent) onChangeParent(address);
  };

  // --------------------------------------------------------------------------
  // Postcode change handler.
  // --------------------------------------------------------------------------
  const onChangePostcode = postcode => {
    const regex = RegExp(postcodeRegex);

    const address = regex.test(postcode) ? { postcode } : getEmptyPostcodeAddress();

    onChange(address);
  };

  return (
    <>
      <AvField
        type="text"
        name="postcode"
        placeholder={placeholder ? placeholder : noPlaceholder ? '' : postcodeLabel}
        className={className}
        value={address.postcode}
        onChange={e => onChangePostcode(e.target.value)}
        validate={
          validate
            ? {
                pattern: {
                  value: postcodeRegex,
                  errorMessage: `Please enter a valid ${postcodeText}`
                },
                required: { value: true, errorMessage: `Please enter a ${postcodeText}` }
              }
            : null
        }
      />
      {showAreasSupportedDisclaimer && (
        <div className="qs-energy-estimate-dialog-disclaimer">{areasSupportedDisclaimer}</div>
      )}
    </>
  );
};

export default PostcodeSelector;
