import { billTypes } from '../enums/billTypes';
import { getAgentServiceType } from '../enums/customerCommissionTypes';

const agentHasBillType = (agentBillTypes, billType) => {
  var agentBillType = getAgentBillType(agentBillTypes, billType);

  return agentBillType && agentBillType.id !== 0;
};

const agentHasEnergy = agentBillTypes => {
  return (
    agentHasBillType(agentBillTypes, billTypes.GAS.Id) || agentHasBillType(agentBillTypes, billTypes.ELECTRICITY.Id)
  );
};

const getAgentBillType = (agentBillTypes, billType) => {
  return agentBillTypes.find(bt => bt.billType === billType && bt.enabled);
};

const getExit = (exitCode, exits, serviceType, agentBillTypes) => {
  const exitSetting = exits && exits.length > 0 ? exits.find(e => e.code === exitCode && e.url) : null;
  const exitDefault = exits && exits.length > 0 ? exits.find(e => e.code === '' && e.url) : null;

  if (exitCode && exitSetting) return exitSetting;

  const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);

  return agentServiceType && agentServiceType.externalExitUrl ? { url: agentServiceType.externalExitUrl } : exitDefault;
};

export { agentHasBillType, agentHasEnergy, getAgentBillType, getExit };
