import React from 'react';
import { useSelector } from 'react-redux';
import ExitLink from './exitLink';
import { getExit } from '../../utilities/billTypeUtilities';
import { launchDialogModes } from '../../enums/launchTypes';

const ExitLaunch = ({ launchMode, serviceType, source }) => {
  const { displayName } = useSelector(state => state.config);
  const { exitCode } = useSelector(state => state.landing.home);
  const { exits } = useSelector(state => state.config.settings);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const exit =
    launchMode === launchDialogModes.RESTRICTED ? getExit(exitCode, exits, serviceType, agentBillTypes) : null;

  return exit ? (
    <div className="sm-exit-launch">
      <ExitLink url={exit.url} label={`Back to ${displayName}`} source={`launch-${source}`} showIcon />
    </div>
  ) : null;
};

export default ExitLaunch;
