import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'reactstrap';
import { scroller } from 'react-scroll';
import ServiceCard from './serviceCard/serviceCard';
import ServiceBarButton from './serviceCard/serviceBarButton';

import ServerMessage from './common/serverMessage';
import * as serverMessageTargets from '../actions/serverMessageTargets';
import { scrollToError } from '../actions/serverMessageActions';
import { openSubmitDialog } from '../actions/dialogOptionsActions';

import { billTypes } from '../enums/billTypes';
import { uploadSubTypes } from '../enums/uploadBillTypes';
import { launchDialogModes } from '../enums/launchTypes';
import { getAgentServiceType } from '../enums/customerCommissionTypes';
import DialogManager from './layout/dialogManager';
import { determineUploadBillType } from '../components/serviceCard/serviceCardUtilities';

const Dashboard = ({
  serviceCards,
  serviceSummaries,
  totalSavings,
  totalCommission,
  userBillsLoaded,
  onTourStarted,
  onTourClosed
}) => {
  const dispatch = useDispatch();

  const { serverMessage } = useSelector(state => state);
  const { launchDialogMode } = useSelector(state => state.landing.home);
  const { billTypes: agentBillTypes, isLoaded: configLoaded } = useSelector(state => state.config);

  const { DASHBOARD: target } = serverMessageTargets;
  const hasSaving = totalSavings > 0;
  const isDefaultLaunchMode = launchDialogMode === launchDialogModes.DEFAULT;

  const [hasStuck, setHasStuck] = useState(false);
  const [tourStarted, setTourStarted] = useState(false);

  // --------------------------------------------------------------------------
  // Scrolling
  // --------------------------------------------------------------------------
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const serviceBar = document.getElementById('service-bar');
    const rect = serviceBar.getBoundingClientRect();
    setHasStuck(rect.top <= 20 && !hasStuck);
  };

  const scrollTop = () => {
    scroller.scrollTo('top-root', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  const scrollToService = serviceType => {
    if (!serviceType) return;

    const serviceBar = document.getElementById('service-bar');
    const rect = serviceBar.getBoundingClientRect();

    const elements = document.querySelectorAll(
      `.service-card:not(.compact).service-type-${serviceType.Tag}:not(.compact)`
    );

    const target = elements[0];

    const offset = rect.bottom - serviceBar.offsetHeight - rect.bottom - 17;

    scroller.scrollTo(target.id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset
    });
  };

  // --------------------------------------------------------------------------
  // Start service from service bar.
  // --------------------------------------------------------------------------
  const startService = serviceType => {
    const agentServiceType = getAgentServiceType(serviceType, agentBillTypes);
    const affiliate = agentServiceType ? agentServiceType.affiliate : null;
    const partner = agentServiceType ? agentServiceType.partner : null;

    const submissionId = null;
    const submission = null;
    const backButtonVisible = serviceType !== billTypes.SOLAR_FINANCE;
    const confirmButtonText = serviceType === billTypes.SOLAR_FINANCE ? 'Submit' : 'Next';
    const uploadBillType = determineUploadBillType(partner, affiliate, agentServiceType);

    openDialog(
      serviceType,
      uploadBillType,
      uploadSubTypes.NONE,
      submissionId,
      submission,
      backButtonVisible,
      confirmButtonText
    );
  };

  const onServiceStart = (serviceType, isEmpty) => {
    scrollToService(serviceType);

    if (isEmpty) {
      setTimeout(() => {
        startService(serviceType);
      }, 100);
    }
  };

  // --------------------------------------------------------------------------
  // Open dialog
  // --------------------------------------------------------------------------
  const openDialog = (
    serviceType,
    uploadBillType,
    uploadSubType,
    submissionId,
    submission,
    backButtonVisible,
    buttonText
  ) => {
    dispatch(
      openSubmitDialog(serviceType, uploadBillType, uploadSubType, {
        submissionId,
        submission,
        backButtonVisible,
        buttonText
      })
    );
  };

  // --------------------------------------------------------------------------
  // Apply server message
  // --------------------------------------------------------------------------
  useEffect(() => {
    scrollToError(serverMessage, target);
  }, [serverMessage]);

  // --------------------------------------------------------------------------
  // Tour guide events
  // --------------------------------------------------------------------------
  useEffect(() => {
    setTourStarted(true);
  }, [onTourStarted]);

  useEffect(() => {
    setTourStarted(false);
  }, [onTourClosed]);

  return (
    <>
      {configLoaded && isDefaultLaunchMode && (
        <>
          {userBillsLoaded && (
            <>
              <div id="service-bar" className={`${tourStarted ? 'stickyoff' : 'sticky'}`}>
                {hasStuck && (
                  <div className="service-bar-top material-icons animated fadeInDown" onClick={scrollTop}>
                    <span>expand_less</span>
                  </div>
                )}
                {!hasStuck && (
                  <div className="sm-service-card-banner">
                    {hasSaving && (
                      <div className="savings">
                        <div id="banner_savings_value">
                          Total Annual Savings: <span className="amount">{`$${totalSavings.toLocaleString()}`}</span>{' '}
                          <span className="disclaimer-symbol">†</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className={`sm-service-card-list compact${hasStuck ? ' stuck' : ''}`}>
                  {serviceSummaries.map(summary =>
                    summary.serviceType.Id === billTypes.NONE.Id ? null : (
                      <ServiceBarButton
                        key={summary.id}
                        service={summary}
                        onServiceStart={onServiceStart}
                        iconMode={hasStuck}
                      />
                    )
                  )}
                </div>
              </div>
            </>
          )}
          <div className="sm-main">
            <Container className="sm-container">
              <ServerMessage serverMessage={serverMessage} target={target} />

              {userBillsLoaded ? (
                <>
                  <div className="sm-service-card-list">
                    {serviceCards.map(service => (
                      <ServiceCard key={service.id} service={service} openDialog={openDialog} />
                    ))}
                  </div>
                </>
              ) : (
                <h1>Preparing to check your bills against the market...</h1>
              )}
            </Container>
          </div>
        </>
      )}

      <DialogManager />
    </>
  );
};

export default Dashboard;
