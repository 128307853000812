import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { initRegistration } from '../../actions/registrationActions';
import { setServerMessageClear } from '../../actions/serverMessageActions';
import * as serverMessageTargets from '../../actions/serverMessageTargets';
import ServerMessage from '../common/serverMessage';
import EmailAddress from '../common/emailAddress';

const RegistrationControl = ({ onClose, onComplete }) => {
  const { name: countryName } = useSelector(state => state.config.country);
  const { mobilePhoneNumberRegex } = useSelector(state => state.config.applicationSettings.country);
  const { emailAddress: registrationEmailAddress, mobilePhoneNumber: registrationMobilePhoneNumber } = useSelector(
    state => state.registration
  );
  const { serverMessage } = useSelector(state => state);

  const [emailAddress, setEmailAddress] = useState(registrationEmailAddress);
  const [mobilePhoneNumber, setmobilePhoneNumber] = useState(registrationMobilePhoneNumber);
  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const { REGISTRATION_INIT: target } = serverMessageTargets;

  useEffect(() => {
    dispatch(setServerMessageClear(target, serverMessage));
  }, []);

  const onRegisterSubmit = () => {
    dispatch(initRegistration(emailAddress, mobilePhoneNumber, registrationComplete));
  };

  const registrationComplete = () => {
    setSubmitted(true);

    if (onComplete) {
      onComplete();
    }
  };

  return (
    <AvForm onValidSubmit={onRegisterSubmit}>
      {serverMessage.messages.length > 0 && (
        <ServerMessage serverMessage={serverMessage} target={target} showCloseIcon={false} />
      )}
      {!submitted && (
        <>
          <div>
            <EmailAddress
              disabled={submitted}
              value={emailAddress}
              onChange={value => setEmailAddress(value)}
              forceRequireEmailAddress={true}
            />
          </div>
          <div>
            <AvField
              bsSize="sm"
              name="phoneNumber"
              placeholder="Mobile phone number"
              type="text"
              disabled={submitted}
              value={mobilePhoneNumber}
              onChange={e => setmobilePhoneNumber(e.target.value)}
              validate={{
                required: { value: true, errorMessage: 'Mobile phone number is required' },
                pattern: {
                  value: mobilePhoneNumberRegex,
                  errorMessage: `Please enter a valid  mobile phone number for ${countryName} (no spaces or hyphens)`
                }
              }}
            />
          </div>
          <div className="dialog-button">
            <Button className="sm-button-secondary medium" disabled={submitted}>
              Register
            </Button>
          </div>
        </>
      )}

      <div className="dialog-button">
        {onClose && submitted && (
          <Button className="sm-button-secondary medium" onClick={onClose}>
            Close
          </Button>
        )}
      </div>
    </AvForm>
  );
};

export default RegistrationControl;
