import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import OrganiserName from '../common/organiserName';
import GeneralDialog from '../../components/dialogs/generalDialog';
import RegistrationControl from '../registration/registrationControl';
import WizardIntro from './wizardIntroControl';
import WizardBasic from './wizardBasicControl';
import WizardHomeLoan from './wizardHomeLoanControl';
import WizardInternet from './wizardInternetControl';
import AuthenticationButton from '../authentication/authenticationButton';
import { standardEvents } from '../../utilities/googleAnalyticsEvents';
import { billTypes } from '../../enums/billTypes';
import { buttonTypes } from '../../enums/buttonTypes';
import { goDashboard } from '../../actions/comparisonActions';

export const controlTypes = {
  Default: { id: 0, name: 'Default', showCloseIcon: false, dialogSize: 'sm' },
  WizardIntro: { id: 1, name: 'Intro', showCloseIcon: false, dialogSize: 'md' },
  WizardBasic: { id: 2, name: 'Basic', showCloseIcon: true, dialogSize: 'md' },
  WizardHomeLoan: { id: 3, name: 'Home Loan', showCloseIcon: true, dialogSize: 'md' },
  WizardInternet: { id: 4, name: 'Internet', showCloseIcon: true, dialogSize: 'md' },
  Registration: { id: 999, name: 'Registration', showCloseIcon: true, dialogSize: 'sm' }
};

const GetStarted = ({ noButton, contextType, isOpen, onClosing }) => {
  const dispatch = useDispatch();
  const { hasEstimates, googleAnalyticsClientCode } = useSelector(state => state.config.settings);
  const { billTypes: agentBillTypes } = useSelector(state => state.config);

  const buttonAnimateClass = 'animated pulse-heavy repeat-1';
  const startAt = controlTypes.WizardBasic;

  const [dialogOpen, setDialogOpen] = useState(isOpen);
  const [buttonClass, setButtonClass] = useState();

  const [dialogSize, setDialogSize] = useState(startAt.dialogSize);
  const [showCloseIcon, setShowCloseIcon] = useState(startAt.showCloseIcon);
  const [controlTypeId, setControlTypeId] = useState(startAt.id);
  const [controlData, setControlData] = useState({});

  const serviceTypeOptions = { includeAffiliate: false };
  const hasEnergy = billTypes.hasServiceType(agentBillTypes, [billTypes.ELECTRICITY, billTypes.GAS], serviceTypeOptions)
    ? 1
    : 0;
  const hasInternet = billTypes.hasServiceType(agentBillTypes, billTypes.INTERNET, serviceTypeOptions) ? 1 : 0;
  const hasHomeLoan = billTypes.hasServiceType(agentBillTypes, billTypes.HOME_LOAN, serviceTypeOptions) ? 1 : 0;

  const onDialogVisible = visible => {
    if (visible) {
      setShowCloseIcon(startAt.showCloseIcon);
      setDialogSize(startAt.dialogSize);
      changeControlType(startAt, {});
    }

    setDialogOpen(visible);
    setButtonClass(visible ? null : buttonAnimateClass);

    standardEvents('Setup-Wizard-Dialog', visible ? 'Open' : 'Close', 'Click', googleAnalyticsClientCode);
  };

  const changeControlType = (controlType, controlData) => {
    setControlTypeId(controlType.id);
    setControlData(controlData);
    setShowCloseIcon(controlType.showCloseIcon);
    setDialogSize(controlType.dialogSize);

    standardEvents('Setup-Wizard-Dialog', controlType.name.replace(' ', '-'), 'Show', googleAnalyticsClientCode);
  };

  const onClose = dialogResult => {
    onDialogVisible(false);

    // Navigate to the dashboard if it's the final step of the wizard, or the close icon was clicked on a further wizard step.
    const dashboardRedirect =
      dialogResult === true || [controlTypes.WizardInternet.id, controlTypes.WizardHomeLoan.id].includes(controlTypeId);

    if (onClosing) {
      onClosing();
    }

    if (dashboardRedirect) {
      dispatch(goDashboard());
    }
  };

  const onLogin = () => {
    standardEvents('Setup-Wizard-Dialog', 'Login', 'Click', googleAnalyticsClientCode);
  };

  const onContinueAsGuest = () => {
    if (hasEstimates) {
      changeControlType(controlTypes.WizardIntro);
    } else onClose();

    standardEvents('Setup-Wizard-Dialog', 'Continue-As-Guest', 'Click', googleAnalyticsClientCode);
  };

  const getBadge = id => {
    const stepCount = hasEnergy + hasInternet + hasHomeLoan;
    let step;

    switch (id) {
      case controlTypes.WizardBasic.id:
        step = 1;
        break;

      case controlTypes.WizardInternet.id:
        step = hasEnergy ? 2 : 1;
        break;

      case controlTypes.WizardHomeLoan.id:
        step = hasEnergy ? (hasInternet ? 3 : 2) : hasInternet ? 2 : 1;
        break;

      default:
        return null;
    }

    return (
      <div className="wizard-badge">
        {step} of {stepCount}
      </div>
    );
  };

  const getCurrentControl = id => {
    switch (id) {
      case controlTypes.Registration.id:
        return (
          <>
            <div className="start-heading mb-3">Not yet registered?</div>
            <RegistrationControl />
          </>
        );

      case controlTypes.WizardIntro.id:
        return <WizardIntro onClose={onClose} onChangeControlType={changeControlType} contextType={contextType} />;

      case controlTypes.WizardBasic.id:
        return <WizardBasic onClose={onClose} onChangeControlType={changeControlType} />;

      case controlTypes.WizardHomeLoan.id:
        return <WizardHomeLoan onClose={onClose} onChangeControlType={changeControlType} data={controlData} />;

      case controlTypes.WizardInternet.id:
        return <WizardInternet onClose={onClose} onChangeControlType={changeControlType} data={controlData} />;

      default:
        return (
          <>
            <div className="start-heading">
              Welcome to the <OrganiserName />
            </div>
            <div className="dialog-button centered welcome">
              <AuthenticationButton buttonType={buttonTypes.BUTTON} showLogout={false} onLogin={onLogin} />
            </div>
            <div className="dialog-button centered welcome">
              <Button
                className="sm-button-secondary medium"
                onClick={() => changeControlType(controlTypes.Registration)}
              >
                Register
              </Button>
            </div>
            <div className="divider"></div>
            <div className="dialog-button centered welcome">
              <Button className="sm-button-secondary medium" onClick={() => onContinueAsGuest()}>
                Continue as guest
              </Button>
            </div>
          </>
        );
    }
  };

  useEffect(() => {
    setDialogOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      {!noButton && (
        <Button className={`sm-button-primary medium ${buttonClass}`} onClick={() => onDialogVisible(true)}>
          Get Started
        </Button>
      )}
      {dialogOpen && (
        <GeneralDialog
          bodyClass="no-padding"
          key="getStartedDialog"
          showFooter={false}
          showCloseIcon={showCloseIcon}
          dialogOpen={dialogOpen}
          onClose={onClose}
          dialogSize={dialogSize}
        >
          <div
            className={`sm-get-started-dialog${controlTypeId === controlTypes.WizardIntro.id ? ' intro-control' : ''}`}
          >
            {getBadge(controlTypeId)}
            {getCurrentControl(controlTypeId)}
          </div>
        </GeneralDialog>
      )}
    </>
  );
};

export default GetStarted;
