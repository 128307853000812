import React from 'react';
import { useSelector } from 'react-redux';

import { billTypes } from '../../enums/billTypes';
import { getAddress } from '../../utilities/addressUtilities';
import CommonSwitchSummary from './commonSwitchSummary';

const SolarFinanceSwitchSummary = ({ otherServices }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { referenceNumber, convertedSupplier } = useSelector(state => state.comparison);
  const { displayName, contact, phoneNumber, officeHours, website, logo } = convertedSupplier ?? {};
  const { propertyAddress, billType } = useSelector(state => state.comparison.submission);

  const serviceType = billTypes.getByCode(billType);

  const addressOptions = {
    unitNumber: true
  };

  const fullAddress = getAddress(propertyAddress, addressOptions).fullAddress;

  const getDetailRow = (value, label) => {
    if (value) {
      return (
        <div className="detail-row">
          <div className="detail-label">{label}:</div>
          <div className="detail-value">{value}</div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="intro-text">
        <div>
          Based on the information you've provided, we've passed on your contact details to the following solar finance
          expert. They will be in contact with you to discuss your options.
        </div>
      </div>

      <div className="switch-details">
        {logo && (
          <div className="supplier-logo">
            <img src={`${staticContentUrl}${logo}`} alt="logo" />
          </div>
        )}
        {getDetailRow(displayName, 'Finance Expert')}
        {getDetailRow(contact, 'Contact Name')}
        {getDetailRow(officeHours, 'Office Hours')}
        {getDetailRow(website, 'Website')}
        {getDetailRow(referenceNumber, 'Reference')}
        {getDetailRow(fullAddress, 'Property Address')}

        {phoneNumber && (
          <div className="supplier-contact">
            {displayName && (
              <div>
                If you'd prefer to contact {displayName} instead of waiting for a call, you can do so by calling{' '}
                {phoneNumber}
              </div>
            )}
          </div>
        )}
      </div>

      <CommonSwitchSummary
        serviceType={serviceType}
        otherServices={otherServices}
        showWhatHappensNext={true}
        showDisconnectionNotice={false}
      />
    </>
  );
};

export default SolarFinanceSwitchSummary;
