import React, { useState } from 'react';
import QsCheckBox from './qsCheckBox';
import PropTypes from 'prop-types';
import { getUserPreferences, setUserPreferences } from '../../utilities/userPreferencesUtilities';

const ShowFeatureCheckbox = ({ featureType, label }) => {
  const userPreferences = getUserPreferences();
  const [featureEnabled, setFeatureEnabled] = useState(userPreferences[featureType.code]);

  const onClick = () => {
    userPreferences[featureType.code] = !featureEnabled;
    setFeatureEnabled(!featureEnabled);
    setUserPreferences(userPreferences);
  };

  return <QsCheckBox isChecked={featureEnabled} onCheck={onClick} label={label} />;
};

ShowFeatureCheckbox.propTypes = {
  featureType: PropTypes.object.isRequired
};

export default ShowFeatureCheckbox;
