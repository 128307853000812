import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { v4 as uuidv4 } from 'uuid';
import EstimateWizard from './components/EstimateWizard/estimateWizard';
import NavigationV2 from './components/layout/navigation';
import FooterV2 from './components/layout/footer-v2';
import MainV2 from './components/layout/main-v2';
import cssVars from 'css-vars-ponyfill';
import * as applicationActions from './actions/applicationActions';
import * as configActions from './actions/configActions';
import * as signalrActions from './actions/signalrActions';
import { Helmet } from 'react-helmet';
import ContextSpinner from './components/common/contextSpinner';
import ScrollToTopOnNav from './ScrollToTop';
import { pageChangedFromCurrent } from './utilities/googleAnalyticsEvents';
import { isEmptyObject } from './utilities/commonUtilities';
import { getGtagScript, getGtagInitialise } from './utilities/googleGtagUtilities';
import { getLocalStorage, setLocalStorage } from './utilities/localStorageUtilities';

// import { HubConnectionBuilder } from '@microsoft/signalr';
// import * as signalr from '@microsoft/signalr';
import environmentConfiguration from './store/environmentConfig';
import { environmentNames } from './enums/environmentNames';
import { agentOperatingModes, homePageTypes, launchDialogModes, pageModes } from './enums/launchTypes';
import { partnerFrameTypes } from './enums/partnerFrameTypes';
import { getFacebookPixelScript, getFacebookPixelNoScript, facebookPageChanged } from './utilities/facebookUtilities';

import ChatBot from './components/ChatBot/chatBot';
import { PAGE_HEIGHT_BUFFER, frameMessageTypes, isFramed, postFrameMessage } from './utilities/domUtilities';

class App extends Component {
  state = {
    gaInitialised: false,
    gtmInitialised: false,
    lastPageView: ''
  };

  constructor(props) {
    super(props);
    this.divRef = React.createRef();
  }

  GOOGLE_MAP_SCRIPT = 'google-map-script';

  componentDidMount = () => {
    // const cnn = new HubConnectionBuilder()
    //   .withUrl('http://scanner/hubs/redux')
    //   .withAutomaticReconnect()
    //   .build();

    // cnn
    //   .start()
    //   .then(result => {
    //     this.props.signalrActions.setSignalr({
    //       connectionStatus: signalr.HubConnectionState.Connected,
    //       connectionId: cnn.connectionId // Shouldnt be storing connectionid - use Context.ConnectionId on the server? can we get Context from an API Controller??
    //     });

    //     cnn.on('UpdateRedux', message => {
    //       console.log('UPDATING REDUX MESSAGE' + JSON.stringify(message));
    //       this.props.signalrActions.recieveMessage(message);
    //     });

    //     //cnn.onClose

    //     // NOTE: If initial connection does not succeed, it will not jump into a reconnection attempt...
    //     //   New-connection i.e. the client is connecting for the first time
    //     //   Re-connection i.e. the client is reconnecting to the same server
    //     // If the original server dies and new one spins up, user needs to hit F5; SignalR has sticky connections - could affect scaling?

    //     cnn.onreconnecting(error => {
    //       console.log(`Connection lost due to error "${error}". Reconnecting...`);
    //       this.props.signalrActions.setSignalr({
    //         connectionStatus: signalr.HubConnectionState.Reconnecting
    //         //connectionId: cnn.connectionId
    //       });
    //     });

    //     cnn.onreconnected(connectionId => {
    //       console.log(`Connection reastablished. Connected with connectionId "${connectionId}".`);
    //       this.props.signalrActions.setSignalr({
    //         connectionStatus: signalr.HubConnectionState.Connected,
    //         connectionId: cnn.connectionId
    //       });
    //     });
    //   })
    //   .catch(e => console.log('CONNECTION ERROR:', e));

    // Get / add browserId
    const localStorageResult = getLocalStorage('browserId');
    const browserId = localStorageResult.value || uuidv4();
    setLocalStorage('browserId', browserId);

    this.props.configActions.setConfig(browserId);

    // Reset tour tracker
    setLocalStorage('tourTracker', JSON.stringify([]));

    // Setup resize observer
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { isLoaded: configLoaded } = this.props.config;
        const { launchDialogMode } = this.props.landing.home;
        const { pageMode, frameHost } = this.props.application;
        const framedMode = launchDialogMode === launchDialogModes.DEFAULT && pageMode === pageModes.FRAMED;

        if (configLoaded && framedMode && frameHost && entry.target && entry.target.id === 'top-root') {
          const height = parseInt(document.body.scrollHeight, 10) + PAGE_HEIGHT_BUFFER;
          this.props.applicationActions.setFrameHeight(height);

          if (height > 50) {
            postFrameMessage(frameHost, frameMessageTypes.RESIZE, { height }, true);
          }
        }
      }
    });

    if (this.divRef.current) {
      observer.observe(this.divRef.current);
    }

    this.observer = observer;
  };

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  componentDidUpdate(prevProps) {
    const currentPageView = this.getPageViewFromLocation();

    if (currentPageView !== this.state.lastPageView && this.props.config.settings) {
      this.onRouteChanged(this.props.config.settings);
      this.setState({ lastPageView: currentPageView });
    }
  }

  getPageViewFromLocation() {
    return window.location.pathname + ':' + window.location.search + ':' + window.location.hash;
  }

  onRouteChanged(settings) {
    pageChangedFromCurrent(settings, this.state.gtmInitialised);
    facebookPageChanged(settings.facebookPixelId, window);
  }

  onScriptAdded({ scriptTags }) {
    if (scriptTags && scriptTags.length > 0) {
      const scriptTag = scriptTags.filter(script => script.id === this.GOOGLE_MAP_SCRIPT);

      if (scriptTag && scriptTag.length > 0) {
        scriptTag[0].onload = () => {
          const { pageMode } = this.props.application;
          this.props.applicationActions.googleLoaded(pageMode);
        };
      }
    }
  }

  render() {
    cssVars({
      variables: {
        ...this.props.content.cssVariables
      }
    });

    const { pageMode, debug } = this.props.application;
    const { launchDialogMode } = this.props.landing.home;
    const { isLoaded: configLoaded } = this.props.config;
    const {
      chatbotEnabled,
      googleAdWordsId,
      facebookPixelId,
      showPageFooter,
      operatingMode,
      homePageType
    } = this.props.config.settings;
    const { staticContentUrl, googlePlacesApiKey } = this.props.config.applicationSettings;
    const { favicon } = this.props.content.general;

    const agentMode = agentOperatingModes.getById(operatingMode);
    const homePage = homePageTypes.getById(homePageType);
    const showFooter = showPageFooter && agentMode === agentOperatingModes.ACTIVE && homePage !== homePageTypes.START;
    const defaultMode = launchDialogMode === launchDialogModes.DEFAULT && pageMode === pageModes.DEFAULT && !isFramed();
    const framedMode = launchDialogMode === launchDialogModes.DEFAULT && pageMode === pageModes.FRAMED;
    const frameType = this.props.framePartner.frameType;
    const hidden = this.props.framePartner.hidden;

    const favIcon = `${staticContentUrl}/images/logos/${favicon}`;
    const navigation =
      configLoaded && defaultMode ? (
        <NavigationV2 />
      ) : framedMode && !hidden ? (
        frameType === partnerFrameTypes.ENERGY_ESTIMATE ? (
          <EstimateWizard />
        ) : frameType === partnerFrameTypes.MOVING_HOME ? (
          <EstimateWizard />
        ) : null
      ) : null;
    const main = configLoaded ? <MainV2 /> : null;
    const footer = configLoaded && defaultMode && showFooter ? <FooterV2 /> : null;

    const tokenexScript =
      environmentConfiguration.environmentName === environmentNames.PRODUCTION
        ? 'https://htp.tokenex.com/iframe/iframe-v3.min.js'
        : 'https://test-htp.tokenex.com/iframe/iframe-v3.min.js';

    return (
      <div ref={this.divRef} id="top-root">
        {configLoaded && (
          <Helmet onChangeClientState={(state, tags) => this.onScriptAdded(tags)}>
            <link rel="icon" href={favIcon} />
            <link rel="shortcut icon" type="image/x-icon" href={favIcon} />
            <script
              id={this.GOOGLE_MAP_SCRIPT}
              src={`https://maps.googleapis.com/maps/api/js?key=${googlePlacesApiKey}&libraries=places`}
            />
            <script src={tokenexScript}></script>
            {getGtagScript(configLoaded, googleAdWordsId)}
            {getGtagInitialise(configLoaded, googleAdWordsId)}
            {getFacebookPixelScript(configLoaded, facebookPixelId)}
            {getFacebookPixelNoScript(configLoaded, facebookPixelId)}
          </Helmet>
        )}

        <ScrollToTopOnNav />

        <div id="app-root">
          {navigation}
          {main}
          {defaultMode && footer}
        </div>

        <ContextSpinner />
        {configLoaded && chatbotEnabled && defaultMode && <ChatBot />}
      </div>
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.config.isLoaded) {
      cssVars({
        variables: {
          ...nextProps.content.cssVariables
        }
      });

      const tags = {};

      if (
        !prevState.gtmInitialised &&
        nextProps.config.tagManagerCode &&
        (!nextProps.config.settings.tagManagerRequiresExternalUserId || nextProps.user.externalUserId)
      ) {
        const tagManagerArgs = {
          gtmId: nextProps.config.tagManagerCode,
          events: nextProps.config.settings.tagManagerRequiresExternalUserId
            ? { accounts: { userID: nextProps.user.externalUserId } }
            : undefined,
          dataLayerName: nextProps.config.settings.tagManagerDataLayerName
            ? nextProps.config.settings.tagManagerDataLayerName
            : undefined,
          auth: nextProps.config.settings.tagManagerAuth ? nextProps.config.settings.tagManagerAuth : undefined,
          preview: nextProps.config.settings.tagManagerPreview ? nextProps.config.settings.tagManagerPreview : undefined
        };

        TagManager.initialize(tagManagerArgs);

        tags.gtmInitialised = true;
      }

      if (!prevState.gaInitialised) {
        ReactGA.initialize(nextProps.config.analyticsCode, {
          debug: false,
          alwaysSendToDefaultTracker: true
        });

        if (nextProps.config.settings.googleAnalyticsClientCode) {
          ReactGA.addTrackers(
            [
              {
                trackingId: nextProps.config.settings.googleAnalyticsClientCode,
                gaOptions: {
                  name: 'agent'
                }
              }
            ],
            { debug: false, alwaysSendToDefaultTracker: true }
          );
        }

        tags.gaInitialised = true;
      }

      if (!isEmptyObject(tags)) return tags;
    }

    return null;
  }
}

const mapStateToProps = state => {
  return {
    config: state.config,
    user: state.user,
    session: state.session,
    content: state.content,
    landing: state.landing,
    application: state.application,
    framePartner: state.framePartner
  };
};

function mapDispatchToProps(dispatch) {
  return {
    applicationActions: bindActionCreators(applicationActions, dispatch),
    configActions: bindActionCreators(configActions, dispatch),
    signalrActions: bindActionCreators(signalrActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
